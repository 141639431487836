import React, { Component }                from 'react'
import withStyles from 'styles'
import Tooltip from '@material-ui/core/Tooltip'
import { formatLocaleDateTime } from 'utils'

export class LivenessIndicator extends Component{

  render = () =>
    <Tooltip
      title={`Last communicated at ${this.props.lastHeartbeatAt ? formatLocaleDateTime(this.props.lastHeartbeatAt) : 'unknown'}`}
      placement='bottom-end'
      enterDelay={300}
    >
      <div style={{...this.props.style}} className={this.props.classes('livenessIndicator', {alive: this.props.live})}>
      </div>
    </Tooltip>


}

const styles = {
  livenessIndicator: {
    cursor: 'pointer',
    width: "15px",
    height: "15px",
    boxShadow: "inset 0 0 5px -1px rgba(0, 0, 0, 0.4)",
    borderRadius: "15px",
    background: "#e7e7e7",
    position: "relative",
    left: "-35px",
    display: "block",
    marginBottom: "-15px"
  },
  alive: {
    background: "#97e117",
  }
}
export default withStyles(styles)(LivenessIndicator)