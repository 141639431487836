import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { UserRoleActions, OrganisationActions, RegistrationActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import { PermissionsTable } from 'components'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { compose, errorStringsFromError } from 'utils'
import withStyles from 'styles'
import Chip from '@material-ui/core/Chip'
import BusinessIcon from '@material-ui/icons/Business'
import RegistrationIcon from '@material-ui/icons/Assignment'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeleteIcon from '@material-ui/icons/Delete'

export class Show extends Component{

  constructor(props){
    super(props)
    OrganisationActions.bindActions(this, 'organisations')
    RegistrationActions.bindActions(this, 'registrations')
    SnackbarActions.bindActions(this, 'snackbar')
    UserRoleActions.bindActions(this)
    this.state = {
      scopes: {
        organisation: null,
        registration: null,
      }
    }
  }

  async dependsOn(){
    const { data: userRole} = await this.actions.show(this.id, {
      include: `registrationPermissions.registrations,registrationPermissions.organisations,
                organisationPermissions.organisations,systemPermission,organisation`
    })
    return this.actions.organisations.getPermissionNames(userRole.organisation.id)
  }

  dependenciesMet(){
    return !!this.userRole.id
  }

  get id(){
    return this.props.match.params.id
  }

  get selectedTab(){
    return this.props.match.params.tab || 'system'
  }

  get userRole(){
    return this.props.userRole
  }

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this user role? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.id})
      this.props.history.goBack()
      this.actions.snackbar.show('User role deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete user role.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  handleTabChange = (event, tab) =>
    this.props.history.push(`/user_roles/${this.id}/${tab}`)

  renderSuggestionLabel = ({type, name}) =>
    <p>{
      type === 'organisations' ?
        <BusinessIcon/> :
        <RegistrationIcon/>
      }
      {name}
    </p>


  renderScopeChips = ({organisations=[],registrations=[]}={}) => {
    return organisations.concat(registrations).map((entity, idx) =>
      <Chip label={this.renderSuggestionLabel(entity)} key={idx}/>
    )
  }

  renderPermissionColumn = (title, {type, id, ...permissions}) => {
    const scope = type.replace(/Permissions?/,'')
    return this.props.permissionNames[scope] && <PermissionsTable
      className={this.props.classes.permissionsTable}
      title={title}
      rowNames={this.props.permissionNames[scope]}
      colNames={[]}
      columns={[permissions]}
    />
  }


  renderScopedPermissionColumn = (title, type) => {
    const permissions = this.userRole[`${type}Permissions`]
    return this.props.permissionNames[type] && <PermissionsTable
      className={this.props.classes.permissionsTable}
      title={title}
      rowNames={this.props.permissionNames[type]}
      colNames={permissions.map(this.renderScopeChips)}
      columns={permissions}
    />
  }


  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>User Role - {this.userRole.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Fragment>
                  <Link to={`/user_roles/${this.userRole.id}/edit`}>
                    <IconButton>
                      <EditIcon/>
                    </IconButton>
                  </Link>
                  <IconButton onClick={this.handleDelete}>
                    <DeleteIcon/>
                  </IconButton>
                </Fragment>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(this.userRole.name)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Tabs onChange={this.handleTabChange} value={this.selectedTab}>
          <Tab value='system'       label="Users" />
          <Tab value='organisation' label="Organisations" />
          <Tab value='registration' label="Registrations"/>
        </Tabs>
        <div className=''>
          {
            this.selectedTab === 'system' && this.userRole.systemPermission &&
            this.renderPermissionColumn('System Permissions', this.userRole.systemPermission)
          }
          {
            this.selectedTab === 'organisation' && !!this.userRole.organisationPermissions.length &&
            this.renderScopedPermissionColumn('Organisation Permissions', 'organisation')
          }
          {
            this.selectedTab === 'registration' && !!this.userRole.registrationPermissions.length &&
            this.renderScopedPermissionColumn('Registration Permissions', 'registration')
          }
        </div>
        <div style={{clear: 'both'}}/>
        <br/>
      </CardContent>
    </Card>
}


const styles = {
  permissionsTable: {
    width: '33%',
    float: 'left',
    '@media(max-width: 680px)': {
      width: '100%',
      float: 'none'
    }
  }
}

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withStyles(styles),
  connect(({userRoles, organisations: { permissionNames}}) => ({...userRoles, permissionNames}))
)(Show)