import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import jwt_decode from 'jwt-decode'
import { ResetActions } from '.'
import { TokenStore } from 'services'
import { history, decodeJSONApiResponse } from 'utils'
import { SessionStatus } from '../constants'

export const SECOND                  = 1000
export const MINUTE                  = 60 * SECOND
export const INACTIVITY_PERIOD       = 5  * MINUTE
export const REFRESH_TOKEN_THRESHOLD = 10 * SECOND

export class TokenActionSet extends ActionSet{

  static initialState = {
    currentUser: {},
    loginState: SessionStatus.UNKNOWN,
    needChangePassword: false,
    errors: {}
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  decodeToken = ({result: { data: { auth: token, needChangePassword } }}) => {
    const { user, exp } = jwt_decode(token)
    return { currentUser: decodeJSONApiResponse(user).data, exp, needChangePassword }
  }

  get timestamp(){
    return + new Date()
  }

  get inactive(){
    return parseInt(this.lastActivity, 10) < (this.timestamp - INACTIVITY_PERIOD)
  }

  static registerActivity(creator, reducer){
    creator(() => dispatch => this.lastActivity = this.timestamp)
    reducer({})
  }

  static startInactivityTimeout(creator, reducer, constants){
    creator(() => (dispatch, getState) => {
      const { tokens: { exp } } = getState()
      const inactivityCheckTime = (exp * SECOND) - REFRESH_TOKEN_THRESHOLD
      clearInterval(this.onExpiryInterval)
      this.onExpiryInterval = setInterval(() => {
        if(this.timestamp > inactivityCheckTime){
          clearInterval(this.onExpiryInterval)
          dispatch( this.inactive ? this.timedOut() : this.verify())
        }
      }, SECOND)
      dispatch({
        type: constants.ACTION
      })
    })
    reducer({})
  }

  static timedOut(creator, reducer, constants){
    creator(() => (dispatch) => {
      clearInterval(this.onExpiryInterval)
      dispatch(this.saveWindowLocation())
      history.push('/inactive', {})
      dispatch({
        type: constants.ACTION
      })
    })

    reducer({})
  }

  static unexpirePassword(creator, reducer, constants){
    creator(() => dispatch => {
      dispatch({
        type: constants.ACTION
      })
    })
    reducer({
      [constants.ACTION]: state => ({...state, needChangePassword: false})
    })
  }

  static verify(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(initial => dispatch => {
      if(initial){
        dispatch(this.saveWindowLocation())
      }
      return dispatch({
        type: constants.ACTION,
        promise: API.Tokens.refresh(TokenStore.refresh)
      }).then(() => {
        dispatch(this.startInactivityTimeout())
      }).catch(() => { /* no-op to prevent console error */ })
    })

    reducer({
      [constants.REQUEST]: (state, payload) => {
        return { ...state  }
      },
      [constants.SUCCESS]: (state, payload) => {
        return { ...state, loginState: SessionStatus.AUTHENTICATED, ...this.decodeToken(payload) }
      },
      [constants.FAILURE]: (state, payload) => {
        return { ...state, loginState: SessionStatus.UNAUTHENTICATED }
      }
    })
  }

  static create(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => dispatch => {
      return dispatch({
        type: constants.ACTION,
        promise: API.Tokens.create(credentials)
      }).then(() => {
        dispatch(this.registerActivity())
        dispatch(this.startInactivityTimeout())
        dispatch(ResetActions.resetState())
      })
    })

    reducer({
      [constants.REQUEST]: (state, payload) => {
        return { ...state, errors: {...state.errors, create: null} }
      },
      [constants.SUCCESS]: (state, payload) => {
        return { ...state, loginState: SessionStatus.AUTHENTICATED, ...this.decodeToken(payload) }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        return { ...state, loginState: SessionStatus.UNAUTHENTICATED, errors: {...state.errors, create: error}  }
      }
    })
  }

  static refresh(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((saveLocation=true) => dispatch => {
      if(saveLocation) dispatch(this.saveWindowLocation())
      return dispatch({
        type: constants.ACTION,
        promise: API.Tokens.refresh(TokenStore.refresh)
      }).then(() => {
        dispatch(this.registerActivity())
        dispatch(this.startInactivityTimeout())
      }).catch(() => { /* no-op to prevent console error */ })
    })

    reducer({
    })
  }

  static destroy(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(() => dispatch => {
      dispatch(this.clearSavedWindowLocation())
      return dispatch({
        type: constants.ACTION,
        promise: API.Tokens.destroy()
      }).then(() => {
        dispatch(ResetActions.resetState())
      })
    })

    reducer({
      [constants.SUCCESS]: (state, payload) => {
        return { ...state, currentUser: {}, loginState: SessionStatus.UNAUTHENTICATED }
      }
    })
  }


  static forgot(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: constants.ACTION,
        promise: API.Tokens.forgot(credentials)
      }
    })

    reducer({
      [constants.SUCCESS]: (state, payload) => {
        return { ...state }
      }
    })
  }


  static reset(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: constants.ACTION,
        promise: API.Tokens.reset(credentials)
      }
    })

    reducer({
      [constants.REQUEST]: (state, payload) => {
        return { ...state, errors: {...state.errors, reset: null} }
      },
      [constants.FAILURE]: (state, { error }) => {
        return { ...state, errors: {...state.errors, reset: error} }
      }
    })
  }

  static unlock(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: constants.ACTION,
        promise: API.Tokens.unlock(credentials)
      }
    })

    reducer({
      [constants.REQUEST]: (state, payload) => {
        return { ...state, errors: {...state.errors, reset: null} }
      },
      [constants.FAILURE]: (state, { error }) => {
        return { ...state, errors: {...state.errors, reset: error} }
      }
    })
  }

  static acknowledgeNotification(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: constants.ACTION,
        promise: API.Tokens.acknowledgeNotification(credentials)
      }
    })

    reducer({
      [constants.REQUEST]: (state, payload) => {
        return { ...state, errors: {...state.errors, acknowledge: null} }
      },
      [constants.SUCCESS]: (state, {result: {data}}) => {
        return {...state, acknowledgedNotification: data}
      },
      [constants.FAILURE]: (state, { error }) => {
        return { ...state, errors: {...state.errors, acknowledge: error} }
      }
    })
  }

  static update(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: constants.ACTION,
        promise: API.Users.update(credentials)
      }
    })

    reducer({
      [constants.REQUEST]: (state, payload) => {
        return { ...state, errors: {...state.errors, reset: null} }
      },
      [constants.FAILURE]: (state, { error }) => {
        return { ...state, errors: {...state.errors, reset: error} }
      }
    })
  }

  static resendConfirmation(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.RESEND_CONFIRMATION,
        promise: API.Tokens.resendConfirmation(credentials)
      }
    })

    reducer({
      [this.RESEND_CONFIRMATION_SUCCESS]: (state, payload) => {
        return { ...state }
      }
    })
  }

  static confirm(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: this.CONFIRM,
        promise: API.Tokens.confirm(credentials)
      }
    })

    reducer({
      [this.CONFIRM_SUCCESS]: (state, payload) => {
        return { ...state, errors: {...state.errors, confirm: null} }
      },
      [this.CONFIRM_FAILURE]: (state, { error }) => {
        return { ...state, errors: {...state.errors, confirm: error} }
      }
    })
  }

  static acceptInvite(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(credentials => {
      return {
        type: constants.ACTION,
        promise: API.Tokens.acceptInvite(credentials)
      }
    })

    reducer({
      [constants.REQUEST]: (state, payload) => {
        return { ...state, errors: {...state.errors, acceptInvite: null} }
      },
      [constants.FAILURE]: (state, { error }) => {
        return { ...state, errors: {...state.errors, acceptInvite: error} }
      }
    })
  }

  static saveWindowLocation(creator, reducer, constants){
    creator(credentials => {
      return {
        type: constants.ACTION,
        payload: window.location.pathname
      }
    })

    reducer({
      [constants.ACTION]: (state, savedLocation) => {
        return { ...state, savedLocation }
      }
    })
  }

  static clearSavedWindowLocation(creator, reducer, constants){
    creator(credentials => {
      return {
        type: constants.ACTION
      }
    })

    reducer({
      [constants.ACTION]: (state) => {
        return { ...state, savedLocation: null }
      }
    })
  }

}

export default new TokenActionSet()