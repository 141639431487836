import React, { Component, Fragment } from 'react'
import { connect }          from 'react-redux'
import {
  UserRoleActions, UserActions,
  OrganisationRoleActions, OrganisationActions,
  RegistrationActions
} from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, PermissionsRow }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { compose } from 'utils'
import withStyles from 'styles'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from 'react-router-dom'
import ExpandIcon from '@material-ui/icons/KeyboardArrowDown'
import CollapseIcon from '@material-ui/icons/KeyboardArrowUp'
import { MultiAutoSuggest } from 'components'
import BusinessIcon from '@material-ui/icons/Business'
import RegistrationIcon from '@material-ui/icons/Assignment'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

const PERMISSION_SCOPES = {
  system: {
    hasMany: false,
    scopes: []
  },
  organisation: {
    hasMany: true,
    scopes: ['organisations']
  },
  registration:{
    hasMany: true,
    scopes: ['registrations', 'organisations']
  }
}

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    UserRoleActions.bindActions(this)
    OrganisationRoleActions.bindActions(this, 'organisationRoles')
    OrganisationActions.bindActions(this, 'organisations')
    RegistrationActions.bindActions(this, 'registrations')
    UserActions.bindActions(this, 'users')
    this.onSaveRedirect = (() => {
      switch(true){
      case this.userRoleMode: return `/users/${this.userId}`
      default: return `/organisations/${this.organisationId}/users`
      }
    })()
  }

  state = {
    formAttributes: {},
    expansions: {},
    toggles: {}
  }

  dependsOn(){
    const actions = [
      this.userRoleMode && this.actions.users.role(this.userId, {
        include: 'registrationPermissions.organisations,registrationPermissions.registrations,organisationPermissions.organisations,systemPermission,organisation'
      }),
      (!this.userRoleMode && this.editMode) ? this.actions.show(this.objectId, {
        include: 'registrationPermissions.organisations,registrationPermissions.registrations,organisationPermissions.organisations,systemPermission,organisation'
      }):
      this.actions.set({})
    ]
    return Promise.all(actions)
  }

  async componentDidMount(){
    await this.actions.organisations.show(this.organisationId, {fields: {organisations: 'name'}})
    await this.actions.organisations.getPermissionNames(this.organisationId)
    if(this.userRoleMode){
      if(!this.props.userRole.registrationPermissions.length){
        this.buildInitialStateAttributes()
      }else{
        this.setInitialStateAttributes()
      }
    }
    else if(this.editMode){
      this.setInitialStateAttributes()
    }
    else{
      this.buildInitialStateAttributes()
    }
  }

  buildInitialStateAttributes = () => {
    Object.keys(PERMISSION_SCOPES).reduce(
      (callback, type) => () => this.handleNewPermission(type, callback)(), undefined
    )()
  }
  setInitialStateAttributes = () => {
    const formAttributes = Object.entries(this.props.userRole).reduce((agg, [key, value]) => {
      if(key.match(/Permissions?/)){
        agg[`${key}Attributes`] = value
      }
      return agg
    }, {})
    this.setState({formAttributes})
  }

  get userId(){
    return this.props.match.params.user_id
  }

  get userRoleMode(){
    return !!this.userId
  }

  get selectedTab(){
    return this.props.match.params.tab || 'system'
  }

  get formObject(){
    return {organisationId: this.organisationId, ...this.props.userRole, ...this.state.formAttributes}
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.userRole.organisation && this.props.userRole.organisation.id)
  }

  handleTabChange = (event, tab) => {
    switch(true){
    case this.userRoleMode:
      return this.props.history.push(`/users/${this.userId}/user_role/edit/${tab}`)
    case this.editMode :
      return this.props.history.push(`/user_roles/${this.formObject.id}/edit/${tab}`)
    default:
      return this.props.history.push(`/organisations/${this.organisationId}/user_roles/new/${tab}`)
    }
  }

  handleNewPermission = (type, callback) => () => {
    const permissionScope = PERMISSION_SCOPES[type]
    const permissionTypeString = `${type}Permission${permissionScope.hasMany ? 's' : ''}`
    const attributeName   = `${permissionTypeString}Attributes`
    let   permissions     = [...(this.state.formAttributes[attributeName] || [])]
    const newPermission   = this.props.permissionNames[type].reduce((agg, permission) => ({
        ...agg,
        type: permissionTypeString,
        [permission]: false}
      ), {
        ...(permissionScope.scopes.indexOf('organisations') !== -1 && {
          organisations: [{type: 'organisations', id: this.organisationId, name: this.props.organisationName}],
          organisationIds: [this.organisationId],
        }),
        ...(permissionScope.scopes.indexOf('registrations') !== -1 && {
          registrations: [],
          registrationIds: []
        })
      }
    )
    if(permissionScope.hasMany)
      permissions.push(newPermission)
    else
      permissions = newPermission
    this.setState({
      expansions: {
        ...this.state.expansions,
        [`${attributeName}${permissions.length - 1}`]: true,
      },
      formAttributes: {
        ...this.state.formAttributes,
        [attributeName]: permissions
      }
    }, callback)
  }

  renderPermissions = type => {
    const permissionScope = PERMISSION_SCOPES[type]
    const attributeName   = `${type}Permission${permissionScope.hasMany ? 's' : ''}Attributes`
    const permissions     = this.state.formAttributes[attributeName]
    switch(true){
    case !permissions:
      return false
    case Array.isArray(permissions):
      return (
        <Fragment>
          {permissions.map((permission, idx) =>
            this.renderPermissionSection(type, permissionScope, permission, attributeName, idx)
          )}
          <Fab variant='fab' onClick={this.handleNewPermission(type)}>
            <AddIcon/>
          </Fab>
        </Fragment>
      )
    default:
      return (
        <Fragment>
          {this.renderPermissionSection(type, permissionScope, permissions, attributeName)}
        </Fragment>
      )
    }
  }

  handleScopeChange = (attributeName, idx, scopeTypes) => ({target: { value: scopes}}) =>{
    const nextState = {...this.state}
    scopeTypes.forEach(scopeType => {
      nextState.formAttributes[attributeName][idx][`${scopeType}`] = []
      nextState.formAttributes[attributeName][idx][`${scopeType.replace(/s$/,'')}Ids`] = []
    })
    scopes.forEach(item => {
      nextState.formAttributes[attributeName][idx][`${item.type}`].push(item)
      nextState.formAttributes[attributeName][idx][`${item.type.replace(/s$/,'')}Ids`].push(item.id)
    })
    this.setState(nextState)
  }

  handlePermissionDelete = (attributeName, idx) => () => {
    const attributeList = [...this.state.formAttributes[attributeName]]
    const item = attributeList[idx]
    attributeList[idx] = {...item, _destroy: true, [`${item.type.replace(/s$/,'')}Ids`]: undefined}
    this.setState({
      formAttributes: {
        ...this.state.formAttributes,
        [attributeName]: attributeList
      }
    })
  }

  handleToggleExpansion = (attributeName, idx) => () =>
    this.setState({
      expansions: {
        ...this.state.expansions,
        [`${attributeName}${idx}`]: !this.isSectionExpanded(attributeName, idx)
      }
    })

  isSectionExpanded = (attributeName, idx) =>
    this.state.expansions[`${attributeName}${idx}`] !== undefined ?
      this.state.expansions[`${attributeName}${idx}`] :
      idx === 0


  toggleAll = (attributeName, idx, values) => () => {
    const off = !this.state.toggles[`${attributeName}${idx}`]
    const existing = this.state.formAttributes[attributeName]
    let updated
    if(Array.isArray(existing)){
      updated = [...existing]
      updated.splice(idx, 1, values.reduce((agg, val) => ({...agg, [val]: off}),{...existing[idx]}))
    }else{
      updated = values.reduce((agg, val) => ({...agg, [val]: off}), {...existing})
    }
    this.setState({
      toggles: {
        ...this.state.toggles,
        [`${attributeName}${idx}`]: off
      },
      formAttributes: {
        ...this.state.formAttributes,
        [attributeName]: updated
      }
    })
  }

  handleSuggestionsFetchRequested = (scopes, currentSelection) => async (text, callback) => {
    let suggestions = []
    const promises    = []
    if(scopes.indexOf('registrations') !== -1){
      promises.push(this.actions.registrations
        .index({filter: { name: text}})
        .then(({data}) => {
          suggestions = suggestions.concat(data)
        })
      )
    }
    if(scopes.indexOf('organisations') !== -1){
      promises.push(this.actions.organisations
        .index({filter: { name: text}})
        .then(({data}) => {
          suggestions = suggestions.concat(data)
        })
      )
    }
    await Promise.all(promises)
    callback(suggestions.filter(suggestion => !currentSelection.find(current => current.id === suggestion.id && current.type === suggestion.type)))
  }

  getPermissionScope = (scopes, permission) =>
    scopes.reduce((agg, scope) => agg.concat(permission[scope]),[])

  renderSuggestionLabel = ({type, name}) =>
    <p>{
      type === 'organisations' ?
        <BusinessIcon className={this.props.classes.suggestionIcon}/> :
        <RegistrationIcon className={this.props.classes.suggestionIcon}/>
      }
      {name}
    </p>

  renderPermissionSection = (type, {scopes}, permission, attributeName, idx) => {
    if(permission._destroy)
      return false
    const expanded = !scopes.length || this.isSectionExpanded(attributeName, idx)
    const permissionScope = this.getPermissionScope(scopes, permission)
    return (
      <div className={this.props.classes.permissionsSection} key={idx}>
          {
            !!scopes.length &&
            <Fragment>
              <MultiAutoSuggest
                label='Scope'
                showClearAll={false}
                labelProvider={this.renderSuggestionLabel}
                className={this.props.classes.scopeSelect}
                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested(scopes, permissionScope)}
                value={permissionScope}
                onChange={this.handleScopeChange(attributeName, idx, scopes)}
              />
              <div className={this.props.classes.scopeControl}>
                <IconButton className={this.props.classes.scopeIconButton} onClick={this.handleToggleExpansion(attributeName, idx)}>
                  {expanded ? <CollapseIcon/> : <ExpandIcon/>}
                </IconButton>
                <IconButton onClick={this.handlePermissionDelete(attributeName, idx)} className={this.props.classes.scopeIconButton}>
                  <DeleteIcon/>
                </IconButton>
              </div>
            </Fragment>
          }
        {
          expanded &&
          <Fragment>
            <button type='button' className={this.props.classes.toggleLink} onClick={this.toggleAll(attributeName, idx, this.props.permissionNames[type])}>Toggle All</button>
            <PermissionsRow
              key={idx}
              member={`${attributeName}${idx !== undefined ? `.${idx}` : ''}`}
              value={permission}
              values={this.props.permissionNames[type]}
            />
          </Fragment>
        }
        <hr/>
      </div>
    )
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit User Role - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
          <br/>
          <Typography className={this.props.classes.permissionsTitle} variant='h6'>Permissions:</Typography>
          <Tabs value={this.selectedTab} onChange={this.handleTabChange}>
            <Tab value='system' label='System'/>
            <Tab value='organisation' label='Organisation'/>
            <Tab value='registration' label='Registration'/>
          </Tabs>
          <br/>
          {this.selectedTab === 'system' && this.renderPermissions('system')}
          {this.selectedTab === 'organisation' && this.renderPermissions('organisation')}
          {this.selectedTab === 'registration' && this.renderPermissions('registration')}
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  permissionsSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  toggleLink: {
    flex: 0,
    fontSize: 12,
    fontWeight: 'bold',
    alignSelf: 'flex-end',
    border: 'none',
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'right',
    marginTop: 30,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  scopeControl: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: 1
  },
  scopeSelect: {
    marginTop: -25,
    flex: 1,
    width: '200'
  },
  scopeIconButton: {
    marginTop: 10,
    flex: 0
  },
  suggestionIcon: {
    margin: '0 5px -5px 0'
  },
  permissionsTitle: {
    marginTop: 20
  },
  card: {
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  withRouter,
  connect(({
    userRoles,
    organisations: {
      permissionNames,
      organisation: { name: organisationName}
    }
  }) => ({...userRoles, permissionNames, organisationName })),
)(Form)