import React, { Component, Fragment } from 'react'
import { connect }          from 'react-redux'
import { ParentEventActions, RegistrationActions, ProgrammeActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import withStyles from 'styles'
import { compose, uuid, errorStringsFromError, roundDateOrMoment, Availability } from 'utils'
import ParentEventParams from './ParentEventParams'
import EventRegistrationSelection from './EventRegistrationSelection'
import ChildEventsTable from './ChildEventsTable'
import OffersTable from './OffersTable'
import moment from 'moment'
import { withPermissions } from 'containers/shared'
import { LoaderOverlay, EventStatusChip, AvailabilitiesSummary } from 'components'
import { connectQueryString } from 'containers/shared'
import TradingPeriod from 'constants/TradingPeriod'

export class Form extends InstanceFormMixin(Component){

  static requiredPermissions = ({ programme }) => {
    return programme.organisation ?
      {
        organisation: {
          [programme.organisation.id]: ['createEvents']
        }
      }:
      {}
  }

  constructor(props){
    super(props)
    this.relationshipAttributes = ['region', 'gxps']
    ParentEventActions.bindActions(this)
    ProgrammeActions.bindActions(this, 'programmes')
    RegistrationActions.bindActions(this, 'registrations')

    this.state = {
      ...this.state,
      busy: false,
      eventParams:   { collapsed: false },
      childEvents:   { collapsed: false },
      registrations: { collapsed: false },
      childEventSelection: {},
      formAttributes: {
        ...this.state.formAttributes,
        childEventsAttributes: []
      }
    }
  }

  dependsOn(){
    const actions = [
      this.editMode ? this.reload() : this.setDefaults()
    ]
    return Promise.all(actions)
  }

  dependenciesMet(){
    return super.dependenciesMet() && this.props.programme.id
  }

  loadProgramme = async () => {
    return await this.actions.programmes.show(this.programmeId, {
      include: 'organisation,availabilities',
      fields: {
        programmes: 'name,type,organisation,minimumLeadTime',
        organisation: 'name'
      }
    })
  }

  setDefaults = async () => {
    await this.actions.loadDefaults()

    const {data: {minimumLeadTime}} = await this.loadProgramme()
    let { offerWindowBuffer } = this.props.defaults

    // offerWindowMinimumLength | offerWindowBuffer | minimumLeadTime | start | 2h | end
    let offset = Math.max(minimumLeadTime + 60, 3 * 60)
    if(this.isPriceResponsive) {
      offset = offset + offerWindowBuffer
    }

    const start = roundDateOrMoment(moment().add(offset, 'minutes'), TradingPeriod.length.inMinutes)
    const end = moment(start).add(2, 'hours')

    let offerWindowEnd = null
    if(this.isPriceResponsive) {
      offerWindowEnd = moment(start).subtract(minimumLeadTime + offerWindowBuffer, 'minutes')
    }

    this.actions.set({
      status: 'new', minimumLeadTime,
      start: start.toDate(),
      end: end.toDate(),
      ...offerWindowEnd && {offerWindowEnd: offerWindowEnd.toDate()},
      childEvents: []
    })
  }

  get saveEndpointOptions() {
    return {
      include: 'programme,gxps,region,childEvents,childEvents.registration,childEvents.registration.sites,childEvents.registration.organisation,childEvents.offer',
      fields: {
        parentEvents: 'start,end,targetKw,mwhPrice,status,offerWindowStart,offerWindowEnd,childEvents,programme,minimumLeadTime,status,allowedEvents,drLevel',
        childEvents: 'status,targetKw,offeredAutoDRKw,mwhPrice,registration,offer,accepted,minimumLeadTime',
        registrations: 'name,sites,organisation,minimumLeadTime,'+
                       'fixedPrice,availabilityFee,prepurchasedHours,indicativePrice,availableDR,weeklyAvailabilityBitmask',
        sites: 'kw_amount',
        gxps: 'code',
      }
    }
  }

  loadEvent = async (id) => {
    await this.actions.show(id, this.saveEndpointOptions)
  }

  reload = async (id = this.objectId) => {
    if(!id) {
      return
    }
    await this.actions.loadDefaults()
    await this.loadEvent(id)
    if(!this.props.programme.id){
      await this.loadProgramme()
    }
    if(this._isMounted){
      this.setState({
        formAttributes: {
          ...this.state.formAttributes,
          childEventsAttributes: this.derivedChildEventsAttributes,
        }
      })
    }
  }

  handleRequestRefreshEvent = async () => {
    await this.actions.refresh(this.objectId, this.saveEndpointOptions)
    if(this._isMounted){
      let fetchedChildEvents = this.parentEvent.childEvents.reduce(
        (byId, ce) => {
          return {...byId, [ce.id]: ce}
        }, {})
      let newState = {
        formAttributes: {
          ...this.state.formAttributes,
          childEventsAttributes: this.state.formAttributes.childEventsAttributes.map((evtAttributes) => {
            if(fetchedChildEvents[evtAttributes.id]) {
              return {...evtAttributes, offer: fetchedChildEvents[evtAttributes.id].offer, offeredAutoDRKw: fetchedChildEvents[evtAttributes.id].offeredAutoDRKw}
            } else {
              return {...evtAttributes}
            }
          })
        }
      }
      this.setState(newState)
    }
  }

  handleDeleteEvent = async () => {
    await Promise.resolve()
    if(!global.confirm("Are you sure you want to delete this event? This action cannot be undone.")){
      return
    }
    try{
      await this.actions.destroy({id: this.parentEvent.id})
      this.props.history.goBack()
      this.actions.snackbar.show('Event deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete event.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  showChildEvent = (childEvent) => {
    this.props.history.push(`/child_events/${childEvent.id}`)
  }

  componentDidMount = () => {
    this._isMounted = true
    const childEventsAttributes = this.derivedChildEventsAttributes
    this.setState({
      formAttributes: {
        ...this.state.formAttributes,
        childEventsAttributes: childEventsAttributes
      },
      childEventSelection: childEventsAttributes.reduce((agg, {uuid}) => ({...agg, [uuid]: true}), {})
    }, () => {
      this.loadProgramme()
      this.loadRegistrations()
    })
  }

  componentWillUnmount(){
    this._isMounted = false
  }

  afterSave = () => {
    this.setState({
      formAttributes: {
        childEventsAttributes: this.derivedChildEventsAttributes
      }
    })
  }

  get derivedChildEventsAttributes() {
    const derivedChildEvents = (this.parentEvent.childEvents || []).map(({id, registration, targetKw, offeredAutoDRKw, mwhPrice, accepted, status, minimumLeadTime, offer, drLevel}) => ({
      id,
      accepted,
      status,
      minimumLeadTime,
      uuid: uuid(),
      registrationId: registration.id,
      registration,
      targetKw,
      offeredAutoDRKw,
      mwhPrice,
      offer,
      drLevel
    }))
    return derivedChildEvents
  }

  loadRegistrations = () => {
    return this.actions.registrations.index({
      page: this.props.page,
      include: 'sites,organisation,substation',
      filter: {
        programme: this.programmeId,
        active: true,
        ...this.props.filter,
        ...this.formObject.region && {region: this.formObject.region.name},
        ...this.gxps.length       && {gxp: this.gxps.map(({code}) => code)},
        ...this.formObject.start  && {activeDate: this.formObject.start}
      },
      fields: {
        registrations: 'name,status,sites,substation,organisation,minimumLeadTime,'+
                       'fixedPrice,availabilityFee,prepurchasedHours,indicativePrice,availableDR,weeklyAvailabilityBitmask',
        sites: 'kw_amount',
        substation: 'kw_amount',
        organisations: 'name'
      }
    })
  }

  get programme(){
    return this.props.programme || {}
  }

  get isAutoDRProgramme(){
    return !!Object.keys(this.props.programme.signalMappings).length
  }

  get formObject(){
    const form = {programmeId: this.programmeId, ...this.props.parentEvent, ...this.state.formAttributes}
    if(['new', 'draft'].includes(this.parentEvent.status) && this.isPriceResponsive) {
      form.offerWindowStart = moment().toJSON()
    }
    return form
  }

  get parentEvent(){
    return this.props.parentEvent
  }

  get allowedEvents(){
    return  (this.parentEvent.status ===  'new') ? [
      ...( this.isPriceResponsive ? ['open_offer_window'] : []),
      ...(!this.isPriceResponsive) ? ['schedule'] : []
    ] : (this.parentEvent.allowedEvents || [])
  }

  get programmeId(){
    return this.props.match.params.programmeId || (this.parentEvent.programme && this.parentEvent.programme.id)
  }

  get gxps(){
    return this.formObject.gxps || []
  }

  get availableRegistrations(){
    return this.props.registrations
  }

  get drLevel(){
    return this.formObject.drLevel || null
  }

  get childEvents(){
    return this.formObject.childEvents || []
  }

  get childEventsAttributes(){
    return this.formObject.childEventsAttributes || []
  }

  get isPriceResponsive(){
    return this.programme.type === 'PriceResponsiveProgramme'
  }

  get isNonPriceResponsive(){
    return this.programme.type === 'NonPriceResponsiveProgramme'
  }

  get eventDuration(){
    return moment(this.formObject.end).diff(moment(this.formObject.start), 'minutes')/60
  }

  get canDeleteEvent(){
    return ['draft', 'submitted'].includes(this.parentEvent.status)
  }

  handleSave = (args, someOptions = {}) =>{
    const allUuids = (this.state.formAttributes.childEventsAttributes || []).filter(ce => !ce._destroy).map(({uuid}) => uuid)
    const missing  = allUuids.filter(uuid => !this.state.childEventSelection[uuid])
    const present  = allUuids.filter(uuid => this.state.childEventSelection[uuid])
    const options = { afterSave: this.afterSave, onSaveRedirect: this.onSaveRedirect, ...someOptions }

    if(present.length === 0){
      global.alert(`You must make a selection of at least one registration to save an event`)
      return false
    }

    if(missing.length >= 1){
      if(global.confirm('There are some unselected registrations in the child events table. These will be removed from the event. Are you sure you wish to proceed?')){
        this.handleDeleteUnselectedChildEvents(this.state.childEventSelection, () => {
          args = typeof args === 'function' ? args() : args
          this.save(args, options)
        })
      }
    }else{
      args = typeof args === 'function' ? args() : args
      this.save(args, options)
    }
  }

  onSaveRedirect = ({data: {id}}) => `/parent_events/${id}/edit`

  isRegistrationSelected = ({id: needleId}) => {
    return this.activeChildEvents.some(({registration: { id: haystackId}}) => `${needleId}` === `${haystackId}`)
  }

  handleFormObjectChange(formAttributes){
    let callback
    if(
      formAttributes.gxps !== this.state.formAttributes.gxps ||
      formAttributes.region !== this.state.formAttributes.region ||
      formAttributes.start !== this.state.formAttributes.start
    ){
      callback = this.loadRegistrations
    }

    super.handleFormObjectChange(formAttributes, callback)
  }

  handleAcceptAllChildEvents = (accepted) => {
    this.setState({
      formAttributes: {
        ...this.state.formAttributes,
        childEventsAttributes: this.state.formAttributes.childEventsAttributes.map((evAttributes) => {
          if(evAttributes.status === 'ineligible' || evAttributes.status === 'declined') {
            return {...evAttributes, accepted: false}
          }
          if(this.parentEvent.status === 'offer_window_closed' && accepted) {
            return {...evAttributes, accepted: accepted && evAttributes.offer.status === 'opted_in'}
          }
          return {...evAttributes, accepted}
        })
      }
    })
  }

  handleRegistrationsFilterChanged = async rawFilter => {
    const filter = {...rawFilter, tags: {}}
    const tags = rawFilter.tags || []
    tags.forEach(({value, tagType: { name }}) => {
      if(!filter.tags[name]) filter.tags[name] = []
      filter.tags[name].push(value)
    })
    await this.props.onFilterChange(filter)
    this.loadRegistrations()
  }

  handleAddSelectedRegistrations = selection => {
    Object.entries(selection)
          .filter(([id, value]) => !!value)
          .map(([id]) => this.availableRegistrations.find(({id: regId}) => `${regId}` === `${id}`))
          .reduce((agg, registration) => (registration && !this.isRegistrationSelected(registration)) ? this.handleAddRegistration(registration, agg) : agg, next => next ? next() : false)()
  }

  handleRegistrationsPageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadRegistrations()
  }

  handleDeleteUnselectedChildEvents = (selection, callback) => {
    this.activeChildEvents.filter(ce => !selection[ce.uuid]).reduce(
      (cb, ce) => this.handleDeleteChildEvent(ce, cb),
      next => next ? next() : (callback && callback())
    )()
  }

  handleDeleteChildEvent = (childEventAttributes, callback) => () => {
    const childEventsAttributes = childEventAttributes.new ?
      this.childEventsAttributes.filter(cea => childEventAttributes.registration.id !== cea.registrationId) :
      this.childEventsAttributes.map(cea =>
        (childEventAttributes.registration.id === cea.registrationId) ?
          {id: childEventAttributes.id, registration: childEventAttributes.registration, registrationId: childEventAttributes.registration.id, _destroy: true} :
          cea
      )
    const childEventSelection = {
      ...this.state.childEventSelection,
      [childEventAttributes.uuid]: undefined
    }
    this.setState({
      childEventSelection,
      formAttributes: {
        ...this.state.formAttributes,
        childEventsAttributes
      }
    }, callback)
  }

  handleAddRegistration = (registration, callback) => () => {
    const id = uuid()
    let targetKw = registration.sites.reduce((agg, val) => agg + val.kwAmount, 0)
    if(registration.substation)
      targetKw += registration.substation.kwAmount
    const newChildEvent = {
      id: (this.parentEvent.childEvents.find(ce => `${ce.registration.id}` === `${registration.id}`) || {}).id,
      uuid: id,
      registrationId: registration.id,
      registration,
      targetKw: targetKw,
      mwhPrice: this.isPriceResponsive ? undefined : registration.fixedPrice
    }
    const index = this.state.formAttributes.childEventsAttributes.findIndex(({registrationId}) => `${registrationId}` === `${registration.id}`)
    const childEventsAttributes = [...this.state.formAttributes.childEventsAttributes]
    if(index === -1){
      childEventsAttributes.push(newChildEvent)
    }else{
      childEventsAttributes[index] = newChildEvent
    }
    const childEventSelection = {
      ...this.state.childEventSelection,
      [id]: true
    }
    this.setState({
      childEventSelection,
      formAttributes: {
        ...this.state.formAttributes,
        childEventsAttributes
      }
    }, callback)
  }

  handleChildEventSelectionChanged = childEventSelection => {
    this.setState({childEventSelection})
  }

  handleToggleCollapse = section => () => {
    this.setState({
      [section]: {
        ...this.state[section],
        collapsed: !this.state[section].collapsed
      }
    })
  }

  handleTriggerEvent = (eventType, label, actionMessage) => async () => {
    await Promise.resolve()
    const messages = [`This will ${actionMessage}. This action cannot be undone, are you sure you want to proceed?`]
    const { offerWindowMinimumLength } = this.props.defaults

    if(eventType === 'open_offer_window') {
      let offerWindowLeftDuration = (moment(this.formObject.offerWindowEnd) - moment()) / 60000
      if(offerWindowLeftDuration < offerWindowMinimumLength){
        messages.push(`Note: This event's offer window will have less than ${offerWindowMinimumLength} minutes being open.`)
      }
    }

    if(!
      global.confirm(messages.join('\n\n'))
    ) return

      if(eventType === 'cancel') {
        let id = this.objectId
        await this.actions.event({id}, eventType)
        await this.reload(id)
        return
      }

      const saveAction = () => this.editMode ?
        this.actions.update(this.attributesToSave, this.saveEndpointOptions) :
        this.actions.create(this.attributesToSave, this.saveEndpointOptions)

      await this.handleSave((async () => {
        const result = await saveAction()
        const {data: { id }} = result
        await this.setState({formAttributes: {childEventsAttributes: this.derivedChildEventsAttributes}})

        try {
          await this.actions.event({id}, eventType)
        } catch(error) {
          this.props.history.push(`/parent_events/${id}/edit`)
          await this.reload(id)
          let childEventsAttributes = this.derivedChildEventsAttributes
          this.setState({
            formAttributes: { childEventsAttributes },
            childEventSelection: childEventsAttributes.reduce((agg, {uuid}) => ({...agg, [uuid]: true}), {})
          })
          throw error
        }
        await this.reload(id)

        return result
      }))
  }

  get actionButtonProps(){
    const { status } = this.parentEvent
    const canSchedule = (!this.isPriceResponsive) || (status !== 'new' && status !== 'draft')

    const buttonProps = [
      {event: 'cancel', label: 'Cancel'},
      ...( this.isPriceResponsive ? [{event: 'open_offer_window', label: 'Submit', color: 'secondary'}] : []),
      ...( canSchedule           ? [{event: 'schedule', label: 'Schedule', color: 'secondary'}] : []),
      {event: 'close_offer_window', label: 'Close', actionMessage: 'close the offer window', color: 'secondary'},
    ]

    const activeButtons = buttonProps.filter(({event}) => this.allowedEvents.includes(event))
    return activeButtons
  }

  get activeChildEvents(){
    return this.formObject.childEventsAttributes
              .map((ce, index) => ({...ce, index}))
              .filter(event => !event._destroy)
  }

  renderFormBody = () => {
    switch(this.parentEvent.status){
    case 'new':
    case 'draft':
      return this.renderDraftEventForm()
    case 'submitted':
    case 'offer_window_open':
      return this.renderOfferWindow()
    case 'offer_window_closed':
      return this.renderOfferSelection()
    case 'cancelled':
      return this.renderCancelledEvent()
    default:
      return this.renderScheduledEvent()
    }
  }

  renderDraftEventForm = () => {
    const eventAvailability = Availability.fromEvent(this.formObject)
    return (
      <Fragment>
        <ParentEventParams
          context={this.formObject}
          errorContext={this.errorContext}
          onChange={this.handleFormObjectChange}
          isPriceResponsive={this.isPriceResponsive}
          isAutoDRProgramme={this.isAutoDRProgramme}
          collapsed={this.state.eventParams.collapsed}
          gxps={this.gxps}
          region={this.formObject.region}
          onCollapse={this.handleToggleCollapse('eventParams')}
          defaults={this.props.defaults}
        />
        <div className={this.props.classes.childEvents}>
          <EventRegistrationSelection
            availableRegistrations={this.availableRegistrations}
            onFilterChanged={this.handleRegistrationsFilterChanged}
            onPageSelected={this.handleRegistrationsPageSelected}
            filter={this.props.filter}
            page={this.props.page}
            totalPages={this.props.totalPages}
            collapsed={this.state.registrations.collapsed}
            hasSelectedRegistrations={this.haveSelectedRegistrations}
            hasSelectedAllRegistrations={this.allRegistrationsSelected}
            onSelectAll={this.handleSelectAllRegistrations}
            onAddSelected={this.handleAddSelectedRegistrations}
            isRegistrationSelected={this.isRegistrationSelected}
            onSelectRegistration={this.handleSelectRegistration}
            onAddRegistration={this.handleAddRegistration}
            onCollapse={this.handleToggleCollapse('registrations')}
            eventAvailability={eventAvailability}
            showAvailabilityWarning={this.isPriceResponsive}
          />
          <ChildEventsTable
            collapsed={this.state.childEvents.collapsed}
            childEvents={this.activeChildEvents}
            isPriceResponsive={this.isPriceResponsive}
            isAutoDRProgramme={this.isAutoDRProgramme}
            errorRenderer={this.errorFor}
            onDeleteChildEvent={this.handleDeleteChildEvent}
            drLevel={this.drLevel}
            onDeleteSelectedChildEvents={this.handleDeleteUnselectedChildEvents}
            defaultMwhPrice={this.formObject.mwhPrice}
            context={this.formObject}
            errorContext={this.errorContext}
            onChange={this.handleFormObjectChange}
            eventDuration={this.eventDuration}
            errors={this.props.errors}
            selected={this.state.childEventSelection}
            onSelectionChange={this.handleChildEventSelectionChanged}
            eventAvailability={eventAvailability}
            showAvailabilityWarning={this.isPriceResponsive}
          />
        </div>
      </Fragment>
    )
  }

  renderOfferWindow = () => {
    return (
      <Fragment>
        <ParentEventParams
          context={this.formObject}
          errorContext={this.errorContext}
          onChange={this.handleFormObjectChange}
          isPriceResponsive={this.isPriceResponsive}
          isAutoDRProgramme={this.isAutoDRProgramme}
          collapsed={this.state.eventParams.collapsed}
          gxps={this.gxps}
          region={this.formObject.region}
          onCollapse={this.handleToggleCollapse('eventParams')}
          defaults={this.props.defaults}
        />
        <OffersTable
          collapsed={this.state.childEvents.collapsed}
          childEvents={this.activeChildEvents}
          errorRenderer={this.errorFor}
          defaultMwhPrice={this.formObject.mwhPrice}
          context={this.formObject}
          errorContext={this.errorContext}
          eventDuration={this.eventDuration}
          isAutoDRProgramme={this.isAutoDRProgramme}
          onRequestRefreshOffers={this.handleRequestRefreshEvent}
          onChange={this.handleFormObjectChange}
          onAcceptAll={this.handleAcceptAllChildEvents}
          parentEventStatus={this.parentEvent.status}
          minimumLeadTime={this.parentEvent.minimumLeadTime}
          targetKw={this.parentEvent.targetKw}
          eventStart={this.parentEvent.start}
          errors={this.props.errors}
          onShowChildEvent={this.showChildEvent}
        />
      </Fragment>
    )
  }

  renderOfferSelection = () => {
    return (
      <Fragment>
        <ParentEventParams
          context={this.formObject}
          errorContext={this.errorContext}
          onChange={this.handleFormObjectChange}
          isPriceResponsive={this.isPriceResponsive}
          isAutoDRProgramme={this.isAutoDRProgramme}
          collapsed={this.state.eventParams.collapsed}
          gxps={this.gxps}
          region={this.formObject.region}
          onCollapse={this.handleToggleCollapse('eventParams')}
          defaults={this.props.defaults}
        />
        <OffersTable
          collapsed={this.state.childEvents.collapsed}
          childEvents={this.activeChildEvents}
          errorRenderer={this.errorFor}
          defaultMwhPrice={this.formObject.mwhPrice}
          context={this.formObject}
          errorContext={this.errorContext}
          eventDuration={this.eventDuration}
          isAutoDRProgramme={this.isAutoDRProgramme}
          onRequestRefreshOffers={this.handleRequestRefreshEvent}
          onChange={this.handleFormObjectChange}
          onAcceptAll={this.handleAcceptAllChildEvents}
          parentEventStatus={this.parentEvent.status}
          minimumLeadTime={this.parentEvent.minimumLeadTime}
          targetKw={this.parentEvent.targetKw}
          eventStart={this.parentEvent.start}
          errors={this.props.errors}
          onShowChildEvent={this.showChildEvent}
        />
      </Fragment>
    )
  }

  renderCancelledEvent = () => {
    return (
      <Fragment>
        <ParentEventParams
          context={this.formObject}
          errorContext={this.errorContext}
          onChange={this.handleFormObjectChange}
          isPriceResponsive={this.isPriceResponsive}
          isAutoDRProgramme={this.isAutoDRProgramme}
          collapsed={this.state.eventParams.collapsed}
          gxps={this.gxps}
          region={this.formObject.region}
          onCollapse={this.handleToggleCollapse('eventParams')}
          defaults={this.props.defaults}
        />
        <OffersTable
          collapsed={this.state.childEvents.collapsed}
          childEvents={this.activeChildEvents}
          errorRenderer={this.errorFor}
          defaultMwhPrice={this.formObject.mwhPrice}
          context={this.formObject}
          errorContext={this.errorContext}
          eventDuration={this.eventDuration}
          isAutoDRProgramme={this.isAutoDRProgramme}
          onRequestRefreshOffers={this.handleRequestRefreshEvent}
          onChange={this.handleFormObjectChange}
          onAcceptAll={this.handleAcceptAllChildEvents}

          parentEventStatus={this.parentEvent.status}
          minimumLeadTime={this.parentEvent.minimumLeadTime}
          targetKw={this.parentEvent.targetKw}
          eventStart={this.parentEvent.start}
          errors={this.props.errors}

          isPriceResponsive={this.isPriceResponsive}
          selectionDisabled={true}
          onShowChildEvent={this.showChildEvent}
        />
      </Fragment>
    )
  }

  renderScheduledEvent = () => {
    return (
      <Fragment>
        <ParentEventParams
          context={this.formObject}
          errorContext={this.errorContext}
          onChange={this.handleFormObjectChange}
          isPriceResponsive={this.isPriceResponsive}
          isAutoDRProgramme={this.isAutoDRProgramme}
          collapsed={this.state.eventParams.collapsed}
          gxps={this.gxps}
          region={this.formObject.region}
          onCollapse={this.handleToggleCollapse('eventParams')}
          defaults={this.props.defaults}
        />
        <OffersTable
          collapsed={this.state.childEvents.collapsed}
          childEvents={this.activeChildEvents}
          errorRenderer={this.errorFor}
          defaultMwhPrice={this.formObject.mwhPrice}
          context={this.formObject}
          errorContext={this.errorContext}
          eventDuration={this.eventDuration}
          isAutoDRProgramme={this.isAutoDRProgramme}
          onRequestRefreshOffers={this.handleRequestRefreshEvent}
          onChange={this.handleFormObjectChange}
          onAcceptAll={this.handleAcceptAllChildEvents}

          parentEventStatus={this.parentEvent.status}
          minimumLeadTime={this.parentEvent.minimumLeadTime}
          targetKw={this.parentEvent.targetKw}
          eventStart={this.parentEvent.start}
          errors={this.props.errors}

          isPriceResponsive={this.isPriceResponsive}
          selectionDisabled={true}
          onShowChildEvent={this.showChildEvent}
        />
      </Fragment>
    )
  }

  render = () => {
    return (
      <Fragment>
        { this.state.busy && <LoaderOverlay/> }
        <Card className={this.props.classes.card}>
          <div className={this.props.classes.formHeader}>
            <div className={this.props.classes.headlines}>
              <Typography className={this.props.classes.headline} variant='h5'>{this.props.parentEvent.status === 'draft' && "Edit"} Event for {this.programme.name}
                &emsp;<EventStatusChip status={this.parentEvent.status}/>
              </Typography>
              <Typography variant='body2'>
                <strong>From&nbsp;</strong>
                {moment(this.formObject.start).format('MMM Do YY, h:mm:ss a')}&emsp;
                <strong>To&nbsp;</strong>
                {moment(this.formObject.end).format('MMM Do YY, h:mm:ss a')}
              </Typography>
            </div>
            <div className={this.props.classes.availabilities}>
              <AvailabilitiesSummary availabilities={this.props.programme.availabilities}/>
            </div>
          </div>

          {this.renderErrorMessages()}
            <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.handleSave}>
              <CardContent className={this.props.classes.cardContent}>
                {this.renderFormBody()}
              </CardContent>
            <CardActions className={this.props.classes.cardActions}>
              {
                this.canDeleteEvent &&
                <Button color='secondary' variant='contained' onClick={this.handleDeleteEvent}>Delete</Button>
              }
              {
                this.actionButtonProps.map(({event, label, actionMessage, color}) =>
                  <Button key={label}
                    className={this.props.classes.btn}
                    variant='contained'
                    color={color}
                    onClick={this.handleTriggerEvent(event, label, actionMessage || `${label.toLowerCase()} the event`)}
                  >
                    {label}
                  </Button>
                )
              }
              <Button color='primary' variant='contained' onClick={this.handleSave}>Save</Button>
            </CardActions>
          </FormContext>
        </Card>
      </Fragment>
    )
  }
}

const styles = theme => ({
  headline: {
    maxWidth: '100%'
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%'
  },
  headlines: {
    flex: 1
  },
  availabilities: {
    flex: 0
  },
  cardActions: {
    justifyContent: 'flex-end'
  },
  childEvents: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative'
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 70px)',
    padding: 20,
    maxWidth: theme.viewAreaMaxWidth,
    boxSizing: 'content-box',
  },
  cardContent: {
    paddingLeft: 0,
    paddingRight: 0
  }
})

export default compose(
  Dependent({loader: true}),
  connectQueryString('registrations'),
  withPermissions(Form.requiredPermissions, {
    checkPermissions: (permissions, {programme}) => {
      return !programme.organisation ||
             !permissions.organisation ||
             permissions.organisation[programme.organisation.id].createEvents
    }
  }),
  withStyles(styles),
  connect(
    ({ parentEvents, registrations: { registrations, page, totalPages }, programmes: { programme } }) => ({
      ...parentEvents,
      programme,
      registrations,
      page,
      totalPages
    })
  )

)(Form)
