import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Offers = ProtectedJsonAPI.extend(
  '/offers',
  {
    // create:  endpoint => endpoint.method('post'),
    index:   endpoint => endpoint,
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    destroy: endpoint => endpoint.method('delete').path(({id}) => id),
    show:    endpoint => endpoint.path(({id}) => id),
    versions:endpoint => endpoint.path(({id}) => `${id}/changes`)
  }
)