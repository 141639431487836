import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { SessionStatus } from '../../constants'
import { TokenActions } from 'actionsets'
import * as Containers from 'containers'
import { withRouter } from 'react-router-dom'
import { throttle } from 'utils'
import { Routes, Nav } from '.'
import CssBaseline from '@material-ui/core/CssBaseline'
import withStyles from 'styles'
import { compose } from 'utils'

export class App extends Component{

  static activityEventTypes = ['mousemove', 'click', 'keydown', 'resize']

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    this.actions.registerActivity()
    this.actions.verify(true)
  }

  componentDidMount = () => {
    const container = document.getElementById('root')
    App.activityEventTypes.forEach(type => container.addEventListener(type, this.registerActivity))
  }

  componentWillUnmount = () => {
    const container = document.getElementById('root')
    App.activityEventTypes.forEach(type => container.removeEventListener(type, this.registerActivity))
  }

  renderRoutes = () => {
    switch(this.props.loginState){
    case SessionStatus.UNKNOWN:         { return <Routes.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Routes.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Routes.Unauthenticated/> }
    default: { return false }
    }
  }

  renderNav = () => {
    switch(this.props.loginState){
    case SessionStatus.UNKNOWN:         { return <Nav.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Nav.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Nav.Unauthenticated/> }
    default: { return false }
    }
  }

  registerActivity = throttle(() => {
    this.actions.registerActivity()
  })

  render = () =>
    <Fragment>
      <CssBaseline />
      {this.renderNav()}
      {this.renderRoutes()}
      <Containers.Shared.Snackbar/>
    </Fragment>
}

const styles = {
  logo: {
    position: 'fixed',
    right: 25,
    bottom: 25,
    width: 90,
    zIndex: -1
  }
}

export default compose(
  connect(({tokens}) => tokens),
  withRouter,
  withStyles(styles)
)(App)