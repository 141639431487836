import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { humanize, compose } from 'utils'
import withStyles from 'styles'
import IconBlock from '@material-ui/icons/Remove'
import IconTick from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export class PermissionssTable extends Component{

  static propTypes = {
    rowNames: PropTypes.array.isRequired,
    colNames: PropTypes.array.isRequired,
    columns:  PropTypes.array.isRequired,
  }
  render = () =>
    <div  className={this.props.className}>
      <Table className={this.props.classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{width: 0}}>{this.props.title}</TableCell>
            {
              this.props.colNames.map((columnName, i) =>
                <TableCell  style={{width: 0}} key={i}>{columnName}</TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            this.props.rowNames.map((rowName, i) =>
              <TableRow className={this.props.classes.permissionsRow} key={i}>
                <TableCell className={this.props.classes('permissionsCell')}>{humanize(rowName)}</TableCell>
                {
                  this.props.columns.map((column, i) =>
                    <TableCell className={this.props.classes('permissionsCell', 'checkmarkCell', `${!!column[rowName]}`)} key={i}>
                      {
                        !!column[rowName] ?
                          <IconTick/> :
                          <IconBlock/>
                      }
                    </TableCell>
                  )
                }
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </div>

}

const styles = {
  table: {
    width: 0
  },
  permissionsRow: {
    height: 'auto'
  },
  permissionsCell: {
    '&:first-child': {
      minWidth: "162px",
      fontSize: "12px",
      lineHeight: "1",
      padding: "0",
      textAlign: "right",
      paddingRight: "10px",
    }
  },
  checkmarkCell: {
    padding: '0 !important',
    minWidth: 100
  },
  false: {
    color: 'white',
    background: '#A9A9A9',
    textAlign: 'center'
  },
  true: {
    color: 'white',
    background: '#9cd79c',
    textAlign: 'center',
    '&:hover': {
      background: '#57da57'
    }
  }
}
export default compose(
  withStyles(styles)
)(PermissionssTable)