import React, { Component } from 'react'
import PropTypes from 'prop-types'
import L from 'leaflet'
import withStyles from 'styles'
import "leaflet/dist/leaflet.css";
import { flatten } from 'utils'
import * as API from 'api'

import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

const MAX_GXPS = 250 /* Also pay attention to the max pagination size on the active GXPs endpoint */

export const Segments = [
  {id: 'gz',      selectable: true, name: 'Grid Zone',                 match: (featureProps, segment) => featureProps.id === parseInt(segment.group.substr(2))},
  {id: 'island',  selectable: true, name: 'Islands',                   match: (featureProps, segment) => featureProps.id === segment.group.toLowerCase()},
  {id: 'line_co', selectable: true, name: 'Line Companies',            match: (featureProps, segment) => featureProps.name === segment.group},
  {id: 'nrr',     selectable: true, name: 'Network Reporting Regions', match: (featureProps, segment) => featureProps.name === segment.group },
  {id: 'gxp',     selectable: false, name: 'GXP',                      match: (featureProps, segment) => featureProps.id === segment.group },
]

Segments.forEach(seg => Segments[seg.id] = seg)

const geojsonMarkerOptions = {
    radius: 6,
    color: "#FFF",
    weight: 2,
    opacity: 1,
    fillOpacity: 0.8
};

export class LoadMap extends Component{

  static propTypes = {
    showGXPs: PropTypes.bool,
    segmentBy: PropTypes.string
  }

  state = { activeGxps: {} }

  componentDidMount = async () => {
    const { data } = await API.Gxps.index({options: { filter: { active: true }, page: { size: MAX_GXPS }, fields: { gxps: 'code'}}})
    this.setState({activeGxps: data.reduce((agg, gxp) => ({...agg, [gxp.code]: true}), {}) })
  }

  componentDidUpdate = prevProps => {
    if(
      prevProps.segmentBy !== this.props.segmentBy ||
      prevProps.groupedAvailabilities[this.props.segmentBy] !== this.props.groupedAvailabilities[this.props.segmentBy] ||
      prevProps.hideZeroLoadFeatures !== this.props.hideZeroLoadFeatures
    ){
      this.handleSetGeoJSON(this.props.segmentBy)
    }
    if(
      prevProps.showGXPs !== this.props.showGXPs ||
      prevProps.groupedAvailabilities.gxp !== this.props.groupedAvailabilities.gxp ||
      prevProps.hideZeroLoadFeatures !== this.props.hideZeroLoadFeatures
    ){
      this.handleToggleShowGXPs(this.props.showGXPs)
      this.info.update()
    }
  }

  componentWillUnmount = () => {
    global.clearInterval(this.resizeInterval)
  }

  colorForMW = mw => {
    const range = 220
    const rangeOffset = 70
    const mwRange = 100000
    const pos = parseInt(rangeOffset + ((Math.min(mw, mwRange) / mwRange) * range), 10)
    return `hsl(${pos}, 50%, 55%)`
  }

  buildInfo = () => {
    this.info = L.control();
    const div = L.DomUtil.create('div', this.props.classes.info); // create a div with a class "info"

    this.info.onAdd = (map) => {
      this.info.update();
      return div;
    };

    // method that we will use to update the control based on feature properties passed
    this.info.update = (propsList, segmentBy) =>  {
      let html = '<h4>Capacity</h4>'
      let availabilities = [];
      let totalKw = 0
      if(!propsList){
        availabilities = (this.props.groupedAvailabilities.gxp || []);
        if(!availabilities.reduce)
          availabilities = []
        totalKw = availabilities.reduce((mem, availability) =>
          mem +
          availability.nonPriceResponsiveCommitted +
          availability.nonPriceResponsiveNotCommitted +
          availability.priceResponsiveCommitted +
          availability.priceResponsiveNotCommitted, 0
        )
      }
      else{
        propsList.forEach(props => {
          if(segmentBy === 'gxp') {
            html += `<b>${props.name} (${props.id})</b><br/>`
          } else {
            html += `<b>${props.name}</b><br/>`
          }
          let availability = this.handleGetAvailabilitiesForSegment({properties: props}, segmentBy)
          if(availability) {
            availabilities.push(availability)
            totalKw += availability.priceResponsiveCommitted +
                    availability.priceResponsiveNotCommitted +
                    availability.nonPriceResponsiveCommitted +
                    availability.nonPriceResponsiveNotCommitted
          }
        })
      }

      if(availabilities.length > 0){
        let sites = flatten(availabilities.map(availability => availability.sites))
        html += '<table>'
        if(!!propsList) {
          sites.sort((a,b) => b.kw_amount - a.kw_amount).slice(0,5).forEach(site => {
            html += `<tr><td><small><i>${site.name}</i></small></td><td><b>${(site.kw_amount/1000).toFixed(3)}</b> MW</td></tr>`
          })
          if(sites.length > 5){
            html += `<tr><td colspan=2><small><i>and ${sites.length - 5} more</i></small></td></tr>`
          }
        }
        if(totalKw){
          html += `<tr><td><b>Total</b></td><td><b>${(totalKw/1000).toFixed(3)}</b> MW</td></tr>`
        }
        html += '</table>'
      }
      if(!propsList){
        html += '<br/><small>Hover over the map for details</small>'
      }
      div.innerHTML = html
      if(div.parentElement){
        let parentWidth = div.parentElement.parentElement.offsetWidth
        if(!propsList && parentWidth < 450){
          div.style.display = 'none'
        }else{
          div.style.display = 'block'
        }
      }
    };

    return this.info
  }

  buildLegend = () => {
    const legend = L.control({position: 'bottomright'});
    legend.onAdd = map => {

      var div = L.DomUtil.create('div', 'info legend'),
          grades = [0, 20, 40, 60, 80];
      div.classList.add(this.props.classes.legend)

      // loop through our density intervals and generate a label with a colored square for each interval
      for (var i = 0; i < grades.length; i++) {
          div.innerHTML +=
              '<i style="background:' + this.colorForMW(grades[i] * 1000 + 1) + '"></i> ' +
              grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
      }

      return div;
    }
    return legend
  }

  onEachFeature = segmentBy => (feature, layer) =>{
    layer.on({
      mouseover: this.highlightFeature(segmentBy),
      mouseout:  this.resetHighlight(segmentBy),
      click:     this.zoomToFeature
    });
  }

  zoomToFeature = e => {
    e.target.getBounds && this.loadMap.fitBounds(e.target.getBounds())
  }

  resetHighlight = segmentBy =>  e => {
    const layer = e.layer || e.target
    if(!layer.getAllChildMarkers){
      segmentBy !== 'gxp' && this.geoJSON && this.geoJSON.resetStyle(layer)
      segmentBy === 'gxp' && this.GXPGeoJSON && this.GXPGeoJSON.resetStyle(layer)
    }
    this.info.update()
  }

  highlightFeature = segmentBy => e => {
    const layer = e.layer || e.target
    const featureProps = layer.getAllChildMarkers ?
      layer.getAllChildMarkers().map(({feature: { properties }}) => properties) :
      [layer.feature.properties]
    this.info.update(featureProps, segmentBy)
    if(!layer.getAllChildMarkers){
      layer.setStyle({
          weight: 3,
          color: 'white',
          dashArray: '',
          fillOpacity: 0.7
      })
    }
  }

  groups = (segmentBy) => {
    return this.props.groupedAvailabilities[segmentBy] || []
  }

  handleSetRef = loadMap => {
    if(loadMap && !this.loadMap){
      this.loadMap = L.map(loadMap, {
        maxBounds: L.latLngBounds(
          L.latLng(-48.28664, 165.77557),
          L.latLng(-32.28664, 181.77557)
        ),
        minZoom: 5,
        scrollWheelZoom: this.props.scrollWheelZoom
      }).setView([-41.28664, 174.77557], 6);
      this.resizeInterval = global.setInterval(() => {this.loadMap.invalidateSize()}, 1000)
      const CartoDB_Positron = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 19,
      });

      CartoDB_Positron.addTo(this.loadMap)
      this.loadMap.fitBounds(L.latLngBounds(
        L.latLng(-48.28664, 165.77557),
        L.latLng(-32.28664, 181.77557)
      ))
      this.buildLegend().addTo(this.loadMap)
      this.buildInfo().addTo(this.loadMap)
      if(this.props.segmentBy){
        this.handleSetGeoJSON(this.props.segmentBy)
      }
      this.handleToggleShowGXPs(this.props.showGXPs)
    }
  }

  handleGetAvailabilitiesForSegment = ({properties}, segmentBy=this.props.segmentBy) => {
    if(!segmentBy) return []
    return this.groups(segmentBy).find(gr => Segments[segmentBy].match(properties, gr))
  }

  handleGetTotalMWForSegment = ({properties}, segmentBy=this.props.segmentBy) => {
    const availability = this.handleGetAvailabilitiesForSegment({properties}, segmentBy)
    if(!availability)
      return 0
    return availability.nonPriceResponsiveCommitted +
    availability.nonPriceResponsiveNotCommitted +
    availability.priceResponsiveCommitted +
    availability.priceResponsiveNotCommitted
  }

  handleToggleShowGXPs = showGXPs => {
    if(this.markersGroup){
      this.loadMap.removeLayer(this.markersGroup)
    }
    if(showGXPs){
      import(`./data/gxp_geo.json`).then(geoJSON => {
        let markersGroup = this.markersGroup = L.markerClusterGroup({
          maxClusterRadius: 0,
          iconCreateFunction: cluster => {
            const totalMW = cluster.getAllChildMarkers().map(cm =>
              this.handleGetTotalMWForSegment(cm.feature, 'gxp')
            ).reduce((a,b) => a + b)
            return L.divIcon({
              className: this.props.classes.cluster,
              html: '<div style="background-color: '+this.colorForMW(totalMW)+';">' + cluster.getChildCount() + '</div>'
            });
          }
        })
        this.GXPGeoJSON = L.geoJSON(geoJSON, {
          "weight": 1,
          style: feature => {
            return {
              weight: 2,
              opacity: 1,
              fillOpacity: 0.7,
              color: '#EFEFEF',
              fillColor: this.colorForMW(this.handleGetTotalMWForSegment(feature,'gxp'))
            }
          },
          filter: (feature, layer) => {
            if(!this.props.showGXPs){
              return false
            }
            if(this.props.hideZeroLoadFeatures){
              return !!this.handleGetTotalMWForSegment(feature,'gxp')
            }
            if(!this.state.activeGxps[feature.properties.id])
              return false
            return true
          },
          "pointToLayer": function (feature, latlng) {
            return L.circleMarker(latlng, geojsonMarkerOptions)
          }
        })

        this.markersGroup.on({
          mouseover: this.highlightFeature('gxp'),
          mouseout: this.resetHighlight('gxp'),
          clustermouseover: this.highlightFeature('gxp'),
          clustermouseout:  this.resetHighlight('gxp'),
        })
        markersGroup.addLayer(this.GXPGeoJSON)
        this.loadMap.addLayer(this.markersGroup)
      })
    }
  }

  handleSetGeoJSON = async geoJSONName => {
    const currentGeoJSON = this.geoJSON
    if(geoJSONName){
      const geoJSONData = await import(`./data/${geoJSONName}_geo.json`)
      if(this.geoJSON){
        Object.values(this.geoJSON._layers).forEach(k => this.loadMap.removeLayer(k))
      }
      this.geoJSON = L.geoJSON(geoJSONData, {
        "weight": 1,
        filter: (feature, layer) => {
          if(this.props.hideZeroLoadFeatures){
            return !!this.handleGetTotalMWForSegment(feature,this.props.segmentBy)
          }
          return true
        },
        style: feature => ({
          weight: 2,
          opacity: 1,
          fillOpacity: 0.7,
          color: 'white',
          dashArray: '3',
          fillColor: this.colorForMW(this.handleGetTotalMWForSegment(feature))
        }),
        onEachFeature: this.onEachFeature(this.props.segmentBy)
      })
      this.geoJSON.addTo(this.loadMap)
    }
    if(currentGeoJSON){
      Object.values(currentGeoJSON._layers).forEach(k => this.loadMap.removeLayer(k))
    }
  }

  render = () =>
    <div className={this.props.classes.loadMap} ref={this.handleSetRef}/>

}

const styles = theme => ({
  info: {
    padding: "6px 8px",
    font: "14px/16px Arial, Helvetica, sans-serif",
    background: 'rgba(6, 41, 60, 0.97)',
    border: '1px solid #a2d8f1',
    boxShadow: '0px 20px 80px 20px rgba(0,0,0,0.15)',
    borderRadius: "2px",
    color: "rgba(255,255,255,0.8)",
    '& h4': {
      margin: "0 0 5px",
      color: "#FFF",
    },
    '& table': {
      tableLayout: 'fixed',
      overflow: 'hidden'
    },
    '& table tr > td:first-child': {
      textAlign: 'left'
    },
    '& table tr > td:last-child': {
      textAlign: 'right',
      minWidth: 90
    }
  },
  cluster: {
    width: "15px !important",
    height: "15px !important",
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 11,
    borderRadius: 15,
    overflow: 'hidden',
    border: '2px solid white'
  },
  legend: {
    lineHeight: "18px",
    color: "rgba(255,255,255,0.6)",
    "& i": {
      width: "18px",
      height: "18px",
      float: "left",
      clear: 'left',
      marginRight: "8px",
      opacity: "0.7",
    }
  },
  loadMap: {
    flex: 1,
    minHeight: 450,
    margin: -15,
    minWidth: 300,
    background: '#052535',
    '& .leaflet-tile-pane': {
      filter: 'invert(100%) sepia(100%) saturate(967%) hue-rotate(168deg) brightness(95%)'
    }
  }
})
export default withStyles(styles)(LoadMap)
