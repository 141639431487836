import React, { Component, Fragment } from 'react'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { humanize, compose } from 'utils'

const applianceProps = ['louver','swing','mode','fan','power']

export class AmbiStateWidget extends Component{
  chooseIcon = (field, value) => {
    const images = {
      'louver': {
        'Oscillate': 'louver_auto.png',
        default: 'louver_off.png'
      },
      'mode': {
        'Cool': 'mode_cool.png',
        'Heat': 'mode_heat.png',
        default: 'mode_auto.png'
      },
      'fan': {
        'Low': 'fan1.gif',
        'Med-Low': 'fan2.gif',
        'High': 'fan4.gif',
        default: 'fan3.gif'
      },
      'power': {
        'On': 'power_on.png',
        default: 'power_off.png'
      }
    }
    return images[field] && (images[field][value] || images[field].default)
  }

  renderTH = data => {
    if(data && (data.temperature || data.humidity)) {
      const temp = data.temperature && `${data.temperature}℃`
      const humd = data.humidity && `${data.humidity}% RH`
      return temp && humd ? `${temp} | ${humd}` : temp || humd
    }
  }

  renderApplianceStateCell = (field,value) => {
    const icon = this.chooseIcon(field, value)
    if(icon) {
      return <TableCell key={field}>
        <img className={this.props.classes.alignedImg} src={`/ambi_icons/${icon}`} alt=""/>
        <span className={this.props.classes.imgAligned}>{value}</span>
      </TableCell>
    } else {
      return <TableCell key={field}>{value}</TableCell>
    }
  }

  renderApplianceState = () => <Fragment>
    {this.props.appliance_state.temperature &&
      <Fragment>
        <Typography variant="h3" align="center">
          {`${this.props.appliance_state.temperature}℃`}
        </Typography>
        <Typography variant="subtitle2" align="center">
          Current Setpoint
        </Typography>
      </Fragment>
    }
    { applianceProps.filter(p => this.props.appliance_state[p]).length > 0 &&
      <Table>
        <TableHead>
          <TableRow>
            {applianceProps.filter(p => this.props.appliance_state[p]).map(p => <TableCell key={p}>{humanize(p)}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {applianceProps.filter(p => this.props.appliance_state[p]).map(p => this.renderApplianceStateCell(p,this.props.appliance_state[p]))}
          </TableRow>
        </TableBody>
      </Table>
    }
  </Fragment>

  render = () => <div className={this.props.className}>
    {(th => th &&
      <div className={this.props.classes.weatherContainer}>
        <img src="/ambi_icons/weather.png" className={this.props.classes.weatherIcon} alt=""/>
        <div className={this.props.classes.weatherLabel}>Local Weather</div>
        <div className={this.props.classes.weatherInfo}>{th}</div>
      </div>
    )(this.renderTH(this.props.weather))}
    {this.props.appliance_state && this.renderApplianceState()}
    {(th => th &&
      <Typography variant="body1" paragraph={true}>
        Space Temperature / Humidity - {th}
      </Typography>
    )(this.renderTH(this.props.sensors))}
  </div>
}

const styles = theme => ({
  weatherContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 100%'
  },
  weatherIcon: {
    gridColumn: 1,
    gridRow: "1 / 3"
  },
  weatherLabel: {
    gridColumn: 2,
    gridRow: 1
  },
  weatherInfo: {
    gridColumn: 2,
    gridRow: 2
  },
  imgAligned: {
    verticalAlign: "middle"
  },
  alignedImg: {
    verticalAlign: "middle",
    maxWidth: 25,
    maxHeight: 25,
    width: "auto",
    height: "auto"
  }
})

export default compose(
  withStyles(styles)
)(AmbiStateWidget)