import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { GxpActions, RegionActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, Tagger, AutoSuggest, LabeledSelect }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import { compose, Authorization } from 'utils'
import withStyles from 'styles'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    this.state = { regionNames: {} }
    this.relationshipAttributes = ['region']
    GxpActions.bindActions(this)
    RegionActions.bindActions(this, 'regions')
  }

  static getDerivedStateFromProps(props, prevState) {
    return {
      ...prevState,
      regionNames: props.regions.reduce((acc,{id, name}) => ({...acc, [id]:name}), prevState.regionNames)
    }
  }

  dependsOn(){
    const item = this.editMode ?
      this.actions.show(this.objectId, {include: "region"}) :
      this.actions.set({})
    const regions = this.actions.regions.index()
    return Promise.all([regions, item])
  }

  getRegionSuggestions = async (text, callback) => {
    const {data} = await this.actions.regions.index(text ? {filter: {name: text}} : undefined)
    callback(data.map(r => r.id))
  }

  participantCodeSuggestions = (text_, callback) => {
    const candidates = this.props.gxp.participantCodeCandidates || []
    const text = text_.toUpperCase()
    if(text) {
      callback([text, ...candidates.filter(c_ => {
        const c = c_.toUpperCase()
        return text !== c && c.includes(text)
      })])
    } else {
      callback(candidates)
    }
  }

  get formObject(){
    return {flowDirection: 'X-I', ...this.props.gxp, ...this.state.formAttributes}
  }

  get regionId(){
    return this.props.match.params.regionId || (this.props.gxp.region && this.props.gxp.region.id) || ""
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit GXP - {this.formObject.code}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent className={this.props.classes.cardContent}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6' className={this.props.classes.headerFields}>
              Fields
            </Typography>
            <TextField fullWidth disabled member='name'/>
            <TextField fullWidth disabled member='code'/>
            <AutoSuggest fullWidth label='Region' member='region.id'
              onSuggestionsFetchRequested={this.getRegionSuggestions}
              labelProvider={id => this.state.regionNames[id]}
            />
            <LabeledSelect
              required fullWidth member='flowDirection'
              options={{X: 'X', I: 'I', 'X-I': 'X-I'}}
            />
            {(this.props.gxp.participantCodeCandidates || []).length > 1 &&
              <AutoSuggest fullWidth label='Participant Code' member='participantCode'
                onSuggestionsFetchRequested={this.participantCodeSuggestions}
                alwaysRenderSuggestions
              />
            }
          </div>
          {
            Authorization.systemPermissions.writeTags &&
            <div className={this.props.classes.tags}>
              <Typography variant='h6'>
                Tags
              </Typography>
              <Tagger canEditRestricted includeRestricted member='tags'/>
            </div>
          }
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    padding: 20,
    margin: '0 auto',
    '& > form': {
      width: '100%'
    }
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    minWidth: 300,
    marginBottom:10,
    '& > div': {
      flex: '1 1 auto'
    }
  },
  tags: {
    flex: 1,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({gxps, regions: { regions }}) => ({...gxps, regions})),
)(Form)