import React, { Component, Fragment, Children } from 'react'
import PropTypes from 'prop-types'
import { FormContext, MultiAutoSuggest } from 'components'
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TagIcon from '@material-ui/icons/LocalOffer';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import * as API from 'api'
import withStyles from 'styles'
import { compose, flatten, Authorization } from 'utils'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

export class TaggedSearchField extends Component{

  static propTypes = {
    onChange: PropTypes.func,
    context: PropTypes.object,
    enableTags: PropTypes.bool,
    classes: PropTypes.func,
    filter: PropTypes.object,
    textFieldName: PropTypes.string
  }

  static defaultProps = {
    classes: () => {},
    enableTags: true,
    enableFilters: true,
    textFieldName: 'name',
    context: {},
    defaultFilter: {},
    filter: {}
  }

  state = {
    showTags: false,
    showFilters: false
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(!prevState.showTags && Object.entries(nextProps.context.tags || {}).length > 0){
      return {...prevState, showTags: true}
    }
    return null;
  }

  get hasAdvancedFilters(){
    return !!Children.count(this.props.children) && this.props.enableFilters !== false
  }

  get tags(){
    return Object.entries(this.props.context.tags || {}).reduce((agg, [tagType, values]) => {
      flatten([values]).forEach(value => agg.push({value, tagType: { name: tagType}}))
      return agg
    },[])
  }

  get formContext(){
    return {...this.props.defaultFilter, ...this.props.context, tags: this.tags }
  }

  get shouldShowTags(){
    return (
      this.state.showTags &&
      this.canShowTags
    )
  }

  get canShowTags(){
    return (
      this.props.enableTags &&
      Authorization.systemPermissions.readTags
    )
  }

  toggleShowTags = () => {
    if(this.state.showTags){
      this.props.onChange({...this.props.context, tags: []})
      this.setState({showTags: false})
    }else{
      this.setState({showTags: true})
    }
  }

  toggleShowFilters = () => {
    this.setState({showFilters: !this.state.showFilters})
  }

  handleSuggestionsFetchRequested = async (value, callback) => {
    const { data: values } = await API.TagValues.index({
      options: {
        page: { size: 50, number: 1 },
        filter: { value, ...this.props.filter },
        include: 'tagType'
      }
    })
    callback(values.filter(v => !this.tags.some(t => t.value === v.value && t.tagType.name === v.tagType.name)).slice(0, 5))
  }

  renderTagLabel = ({value, tagType: { name }}) =>
    <span><strong>{name}</strong>: {value}</span>

  render = () =>
    <FormContext onChange={this.props.onChange} context={this.formContext}>
      <div className={this.props.classes.wrapper}>
        <FormControl>
          <InputLabel htmlFor="tagged-search-field-name">Name</InputLabel>
          <Input
            id="tagged-search-field-name"
            member={this.props.textFieldName}
            onClick={event => event.stopPropagation()}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{color: "#777"}}/>
              </InputAdornment>
            }
            endAdornment={
              <Fragment>
                {
                  this.hasAdvancedFilters &&
                  <InputAdornment position="end">
                    <IconButton onMouseDown={this.preventDefault} onClick={this.toggleShowFilters} color='primary' aria-label="Toggle Filters" style={{margin: -6}}>
                      <FilterIcon/>
                    </IconButton>
                  </InputAdornment>
                }
                {
                  this.canShowTags &&
                  <InputAdornment position="end">
                    <IconButton onMouseDown={this.preventDefault} onClick={this.toggleShowTags} color={this.state.showTags ? 'secondary' : 'primary'} aria-label="Toggle Tags" style={{margin: -6}}>
                      <TagIcon/>
                    </IconButton>
                  </InputAdornment>
                }
              </Fragment>
            }
          />
        </FormControl>
        {
          this.shouldShowTags &&
          <Fragment>
            <MultiAutoSuggest
              classNames={{inputContainer: this.props.classes.inputContainer}}
              id="tagged-search-field-tags"
              labelProvider={this.renderTagLabel}
              member='tags'
              debounceWait={200}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            />
          </Fragment>
        }
      </div>
      <Dialog open={this.state.showFilters} onClose={this.toggleShowFilters}>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          {this.props.children}
        </DialogContent>
      </Dialog>
    </FormContext>


}

const styles = theme => ({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& > div': {
      flex: '1 0 250px'
    },
  }
})
export default compose(
  withStyles(styles)
)(TaggedSearchField)