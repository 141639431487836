import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import {
  EnhancedTable, EnhancedTableHead, FormContext
} from 'components'
import { compose, formatLocaleNumeric, Availability } from 'utils'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TableRow from '@material-ui/core/TableRow'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'
import { ErrorBanner }     from 'components'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Tooltip from '@material-ui/core/Tooltip'


export class ChildEventsTable extends Component{

  static propTypes = {
    context: PropTypes.object,
    errorContext: PropTypes.object,
    onChange: PropTypes.func,
    collapsed: PropTypes.bool,
    childEvents: PropTypes.array,
    isPriceResponsive: PropTypes.bool,
    errorRenderer: PropTypes.func,
    onDeleteChildEvent: PropTypes.func,
    onDeleteSelectedChildEvents: PropTypes.func,
    defaultMwhPrice: PropTypes.number,
    errors: PropTypes.object.isRequired,
    selected: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func,
    eventAvailability: PropTypes.object.isRequired,
    showAvailabilityWarning: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    errorRenderer: () => {},
    onDeleteChildEvent: () => {},
    showAvailabilityWarning: false,
  }

  constructor(props){
    super(props)
     this.state = {
      collapsed: false,
      orderBy: 'registration.name',
      orderDir: 'asc',
      rowsPerPage: 10,
      page: 0
    }
  }

  handleDeleteSelectedChildEvents = () => {
    this.props.onDeleteSelectedChildEvents(this.props.selected)
  }

  handleSelectChildEvent = (childEvent, setTo=undefined) => () => {
    this.props.onSelectionChange({
      ...this.props.selected,
      [childEvent.uuid]: setTo !== undefined ? setTo : !this.props.selected[childEvent.uuid]
    })
  }

  handleSelectAllChildEvents  = () => {
    const selected = !this.allChildEventsSelected
    const childEventSelections = this.props.childEvents.reduce((agg, ce) => ({
      ...agg, [ce.uuid]: selected
    }), {})
    this.props.onSelectionChange(childEventSelections)
  }

  handleChangePage = page => {
    this.setState({page})
  }

  handleChangeRowsPerPage = ({target: { value: rowsPerPage}}) => {
    this.setState({rowsPerPage})
  }

  handleRequestSort =  (event, orderBy) => {
    const order = (this.state.orderBy === orderBy && this.state.orderDir === 'desc') ?
      'asc' :
      'desc'
    this.setState({orderBy,orderDir: order})
  }

  get allChildEventsSelected(){
    return this.props.childEvents.every(({uuid}) => this.props.selected[uuid])
  }

  get selectedCount(){
    return Object.values(this.props.selected).filter(v => !!v).length
  }

  get availableAutoDR(){
    const autoDDValue = this.props.childEvents.filter(ce => this.props.selected[ce.uuid])
                                  .map(ce => this.availableDRForChildEvent(ce))
                                  .reduce((agg, kw) => agg + kw || 0, 0) / 1000.0
    return formatLocaleNumeric(autoDDValue)
  }

  availableDRForChildEvent = (ce) => {
    let drLevel = this.props.isPriceResponsive ? ce.drLevel : this.props.drLevel
    try{ return parseFloat(ce.registration.availableDR[drLevel].kw || 0) }
    catch{ return 0 }
  }

  get selectedMw(){
    return formatLocaleNumeric(this.props.childEvents.filter(ce => this.props.selected[ce.uuid])
                                 .reduce((agg, {targetKw}) => agg + targetKw || 0, 0) / 1000.0 )

  }

  get selectedCost(){
    if(!this.props.eventDuration || this.props.eventDuration < 0)
      return 0
    return formatLocaleNumeric(this.props.childEvents.filter(ce => this.props.selected[ce.uuid]).reduce((agg, {mwhPrice, targetKw, registration}) => {
      const price = mwhPrice || Math.min(registration.indicativePrice, this.props.defaultMwhPrice || registration.indicativePrice)
      return agg + price * (targetKw / 1000.0) * this.props.eventDuration
    }, 0))
  }

  get childEvents(){
    return this.props.childEvents || []
  }

  renderStatistics = () =>
    <Table className={this.props.classes('statsSection', {hasSelection: !!this.selectedCount})}>
      <TableBody>
        <TableRow className={this.props.classes.statsItem}>
          <TableCell variant='head'>Selected MW:</TableCell>
          <TableCell className={this.props.classes.statsValue}>{this.selectedMw}MW</TableCell>
          {
            this.props.isAutoDRProgramme &&
            <Fragment>
              <TableCell variant='head'>Auto DR</TableCell>
              <TableCell className={this.props.classes.statsValue}>{this.availableAutoDR}MW</TableCell>
            </Fragment>
          }
          <TableCell variant='head'>Total Cost:</TableCell>
          <TableCell className={this.props.classes.statsValue}>${this.selectedCost}</TableCell>
        </TableRow>
      </TableBody>
    </Table>

  get refinedChildEvents(){
    const asc = this.state.orderDir === 'asc'

    const extractSortAttribute = element => {
      try{
        const parts = this.state.orderBy.split('.')
        return parts.reduce((elm, part) => elm[part], element)
      }catch(err){
        return null
      }
    }

    const augmentedChildEvents = this.props.childEvents.map((childEvent) => {
      const { weeklyAvailabilityBitmask } = childEvent.registration
      const registrationAvailability = Availability.fromString(weeklyAvailabilityBitmask)
      const eventAvailability = this.props.eventAvailability
      const doesNotCoverEvent = (!!eventAvailability) && !registrationAvailability.covers(eventAvailability)

      return {...childEvent, doesNotCoverEvent}
    })

    return augmentedChildEvents.sort((eventA,eventB) => {
      const [sortAttributeA, sortAttributeB] = [eventA, eventB].map(extractSortAttribute)
      if(sortAttributeA < sortAttributeB) return asc ? -1 : 1
      if(sortAttributeB < sortAttributeA) return asc ?  1 : -1
      return 0
    }).slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    )
  }

  renderErrorBanner = () => {
    const eventError = this.props.errors.event
    const errorLines = []

    if(eventError) {
      let {title, message, meta: {childEvents = []}} = eventError
      let overlappingIds = childEvents.map((id) => `${id}`)
      let overlappingEvents = this.childEvents.filter(({id, accepted}) => overlappingIds.indexOf(id) !== -1)
      let overlappingRegistrations = overlappingEvents.map(({registration: {name}}) => name)

      if(overlappingRegistrations.length !== 0){
        errorLines.push(`${title}: ${message}. Check registrations: ${overlappingRegistrations.join(', ')}`)
      }
    }

    if(errorLines.length === 0) {
      return
    }

    return (
      <ErrorBanner> { errorLines } </ErrorBanner>
    )
  }

  renderChildEventRow = (childEvent) => {
    const {
      id,
      registration: {
        name: regName,
        id: regId,
        organisation: {
          name: orgName,
          id: orgId
        },
        fixedPrice,
        availabilityFee,
        prepurchasedHours,
        indicativePrice
      },
      index,
      targetKw,
      mwhPrice,
      doesNotCoverEvent
    } = childEvent
    const isChecked  = !!this.props.selected[childEvent.uuid]
    const inputProps = {inputProps: { style: { textAlign: 'center', width: 75} }}

    const errors = {
      mwhPrice: this.props.errorRenderer(`childEvents[${index}].mwhPrice`),
      targetKw: this.props.errorRenderer(`childEvents[${index}].targetKw`)
    }

    const hasMwhPrice = mwhPrice || mwhPrice === 0 || Number.isNaN(mwhPrice) || mwhPrice === ''
    const priceValue = hasMwhPrice ? mwhPrice : (Math.min(this.props.defaultMwhPrice || indicativePrice, indicativePrice) || 0)

    return (
      <TableRow key={`${id}-${index}`}>
        <TableCell>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox checked={isChecked} onClick={this.handleSelectChildEvent(childEvent)}/>
              }
            />
          </FormControl>
        </TableCell>
        {
          this.props.showAvailabilityWarning &&
          <TableCell>
            {
              (doesNotCoverEvent) &&
              <div style={{paddingTop: 12}}>
                <Tooltip title="Availabilities in this registration do not cover event's window.">
                  <AccessTimeIcon color="error"/>
                </Tooltip>
              </div>
            }
          </TableCell>
        }
        <TableCell>
          <Link className={this.props.classes.tableCellLink} target="_blank" to={`/organisations/${orgId}`}>
            {orgName}
          </Link>
        </TableCell>
        <TableCell>
          <Link className={this.props.classes.tableCellLink} target="_blank" to={`/registrations/${regId}`}>
            {regName}
          </Link>
        </TableCell>
        {
            this.props.isPriceResponsive ?
            <Fragment>
              <TableCell>
                ${indicativePrice}
              </TableCell>
              {
                this.props.isAutoDRProgramme ?
                  <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='targetKw'>{targetKw}</TableCell> :
                  <TableCell>
                    <TextField
                      {...inputProps}
                      helperText={errors.targetKw}
                      error={!!errors.targetKw}
                      type='number'
                      label='Target kW'
                      member={`childEventsAttributes[${index}].targetKw`}
                      InputProps={
                        {
                          endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                        }
                      }
                    />
                  </TableCell>
              }
              <TableCell>
                <TextField
                  {...inputProps}
                  helperText={errors.mwhPrice}
                  error={!!errors.mwhPrice}
                  type='number'
                  label='MWh Price'
                  member={`childEventsAttributes[${index}].mwhPrice`}
                  value={priceValue}
                  InputProps={
                    {
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">/MWh</InputAdornment>
                    }
                  }
                />
              </TableCell>
            </Fragment> :
            <Fragment>
              <TableCell>${fixedPrice}</TableCell>
              <TableCell>${availabilityFee}</TableCell>
              <TableCell>{prepurchasedHours} Hours</TableCell>
              <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='targetKw'>{targetKw}</TableCell>
            </Fragment>
          }
        {
          this.props.isAutoDRProgramme &&
          <TableCell>{this.availableDRForChildEvent(childEvent)}</TableCell>
        }
        <TableCell>
          <IconButton onClick={this.props.onDeleteChildEvent(childEvent)}>
            <DeleteIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }

  render = () =>
    <section className={this.props.classes.registrationConfigSection}>
      { this.renderErrorBanner() }
      <FormContext context={this.props.context} errorContext={this.props.errorContext} onChange={this.props.onChange}>
        {
          !this.props.collapsed &&
          <EnhancedTable
            className={this.props.classes.offersTable}
            numSelected={this.selectedCount}
            deleteTooltip={'Remove Unselected'}
            onDeleteSelected={this.handleDeleteSelectedChildEvents}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            count={this.props.childEvents.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            toolbarContent={
              this.selectedCount > 0 && this.renderStatistics()
            }
          >
            <EnhancedTableHead
              title='Child Events'
              onRequestSort={this.handleRequestSort}
              onSelectAllClick={this.handleSelectAllChildEvents}
              orderBy={this.state.orderBy}
              order={this.state.orderDir}
              onDeleteSelected={this.handleDeleteSelectedChildEvents}
              numSelected={this.selectedCount}
              rowCount={this.props.childEvents.length}
            >
              {
                this.props.showAvailabilityWarning &&
                <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='doesNotCoverEvent'>
                  Covered
                </TableCell>
              }
              <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='registration.organisation.name'>
                Organisation
              </TableCell>
              <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='registration.name'>
                Registration
              </TableCell>
              {
                this.props.isPriceResponsive ?
                <Fragment>
                  <TableCell id='registration.indicativePrice'>
                    Indicative Price
                  </TableCell>
                  <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='targetKw'  >
                    Target kW
                  </TableCell>
                  <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='mwhPrice' >
                    MWh Price
                  </TableCell>
                </Fragment> :
                <Fragment>
                  <TableCell id='registration.fixedPrice'>
                    Fixed Price
                  </TableCell>
                  <TableCell id='registration.availabilityFee'>
                    Availability Fee
                  </TableCell>
                  <TableCell id='registration.prepurchasedHours'>
                    Prepurchased Hours
                  </TableCell>
                  <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='targetKw'  >
                    Target kW
                  </TableCell>
                </Fragment>
              }
              {
                this.props.isAutoDRProgramme &&
                <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='availableAutoDR'  >
                  Available Auto DR kW
                </TableCell>
              }
              <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='actions' disablePadding>
              </TableCell>
            </EnhancedTableHead>
            <TableBody>
              {this.refinedChildEvents.map(this.renderChildEventRow)}
            </TableBody>
          </EnhancedTable>
        }
      </FormContext>
    </section>

}

const styles = theme => ({
  tableHeaderCell: {
    float: 'left'
  },
  tableCellLink: {
    '&:hover':{
      textDecoration: 'underline'
    }
  },
  registrationConfigSection: {
    position: 'relative',
    flex: '2 0 0%',
    minHeight: 100,
    minWidth: 300,
    '& > div': {
      overflow: 'hidden'
    }
  },
  hasSelection: {

  },
  statsSection: {
    flex: '1 1 100%',
    flexWrap: 'wrap',
    color: 'black',
    textAlign: 'right',
    '& td': {
      borderBottom: 'none !important'
    }
  },
  statsItem: {
    flex: 1
  },
  statsValue: {
    color: "#eaeaea",
    fontSize: "1.3em"
  }
})

export default compose(
  withStyles(styles)
)(ChildEventsTable)