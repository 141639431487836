import React, { Component, Fragment } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Label, Text, Tooltip } from 'recharts'
import { compose } from 'utils'
import withStyles from 'styles'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

export class AvailableDRByLoadType extends Component {

  static propTypes = {
    availableKwByLoadType: PropTypes.object
  }

  get data() {
    const unit = (this.totalKw < 1000) ? 1 : 1000
    return Object.keys(this.props.availableKwByLoadType).map(k => {
      return { name: k, value: (this.props.availableKwByLoadType[k] / unit)}
    })
  }

  get totalKw() {
    return Object.values(this.props.availableKwByLoadType).reduce((acc,v) => acc + Number(v,0))
  }

  renderPieLabel = props => {
    return props.payload.value.toFixed(1)
  }

  renderTotal = props => {
    const {viewBox: {cx, cy}} = props
    const totalKw = this.totalKw
    const totalStr = totalKw < 1000 ? `${totalKw} kW` : `${(totalKw / 1000).toFixed(1)} MW`
    return <Fragment>
      <Text x={cx} y={cy - 6} textAnchor="middle" verticalAnchor="end" fill="#FFF">Total available</Text>
      <Text x={cx} y={cy + 6} textAnchor="middle" verticalAnchor="start" fill="rgba(255,255,255,0.8)">{
        totalStr
      }</Text>
    </Fragment>
  }

  render = () => {
    const loadTypeCount = Object.keys(this.props.availableKwByLoadType || {}).length
    if( loadTypeCount === 0) {
      return null
    }
    var colorInterpolator = d3.scaleSequential(d3.interpolateRainbow).domain([0, loadTypeCount])
    return (
        <ResponsiveContainer width='100%' aspect={1} minWidth={350}>
          <PieChart>
            <Legend />
            <Pie
              dataKey="value"
              label={this.renderPieLabel}
              labelLine={false}
              outerRadius={85}
              innerRadius={50}
              data={this.data}>
              <Label position="center"
                content={this.renderTotal}
              />
              {
                Object.keys(this.props.availableKwByLoadType).map((loadTye, index) => {
                  return <Cell key={loadTye} fill={colorInterpolator(index)}/>
                })
              }
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
    )
  }
}

const styles = theme => ({})

export default compose(
  withStyles(styles)
)(AvailableDRByLoadType)
