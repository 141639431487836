import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TokenActions } from 'actionsets'
import { FormContext, CenteredCard, ErrorBanner, GradedPassword } from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'
import { errorStringsFromError } from 'utils'
import { PasswordPageTitles } from '../../constants'

const ResetPage = (pathName, withID = false) => class extends Component {

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
  }

  get token(){
    return window.location.search.replace(/^.*?token=([^$&]+).*/,'$1')
  }

  pageTitle = PasswordPageTitles[pathName.toUpperCase()] || "Reset your password"

  submit = async () => {
    const { acceptPassword, ...state } = this.state
    if(acceptPassword) {
      await this.actions[pathName]({...state, ...(withID ?
        { id: this.props.currentUser.id } :
        { token: this.token }
      )})
      await this.actions.verify()
      this.props.history.push('/')
    }
  }

  errorFor = key => {
    try{
      return this.props.errors.reset.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard>
      <FormContext context={this.state} errorContext={{
        password:             this.errorFor('password'),
        passwordConfirmation: this.errorFor('passwordConfirmation'),
      }} onChange={state => this.setState(state)}
        onSubmit={this.submit}
      >
        <CardContent>
          <Typography variant='h6'>{this.pageTitle}</Typography>
          {this.props.errors.reset && <ErrorBanner>{errorStringsFromError(this.props.errors.reset)}</ErrorBanner>}
          <FormGroup>
            { pathName === 'update' &&
              <TextField fullWidth autoFocus member='oldPassword' type='password' label='Current Password'/>
            }
            <GradedPassword fullWidth member='password' onAcceptanceChange={acceptPassword => this.setState({acceptPassword})} type='password'/>
            <TextField fullWidth member='passwordConfirmation' type='password'/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button variant='contained' color='secondary' fullWidth type="submit" disabled={!(this.state && this.state.acceptPassword)}>Submit</Button>
        </CardActions>
      </FormContext>
    </CenteredCard>
}

export const Reset = ResetPage('reset')
export const Unlock = ResetPage('unlock')
export const Update = ResetPage('update', true)
export default connect(({tokens}) => tokens)(Reset)