import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, flatten, errorStringsFromError, roundDateOrMoment, deparallelize } from 'utils'
import withStyles from 'styles'
import { VerificationMethodActions } from 'actionsets'
import { FormContext, DateTimePicker, MultiAutoSuggest, LabeledSelect, Loader, ErrorBanner } from 'components'
import { connectQueryString, withPermissions } from 'containers/shared'
import moment from 'moment'
import * as API from 'api'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import TabIcon from '@material-ui/icons/ExpandLess'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import BasisDaySelector from './BasisDaySelector'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import { TokenStore } from 'services'
import qs from 'qs'
import TradingPeriod from 'constants/TradingPeriod'
import { LineChart } from 'components/charts'
import Dygraph from 'dygraphs'

const days = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'holiday' ];

export class BaselineTester extends Component {

  static requiredPermissions = ({organisationId}) => ({
    system: ['readNetworkTopology'],
    organisation: {
      ...organisationId && {[organisationId] : ['writeVerificationMethods']}
    }
  })

  get organisationId(){
    return this.props.organisationId
  }

  constructor(props) {
    super(props)
    VerificationMethodActions.bindActions(this, 'verificationMethods')
    this.state = { opacity: {}, filtersCollapsed: false, chartData: { data: [[0,0]], labels: ['','']}}
  }

  componentDidMount =  async () =>{
    await this.loadVerificationMethods()
    if(this.currentVerificationMethod.id){
      const selectedMethod = this.currentVerificationMethod
      this.props.onFilterChange({
        ...this.props.filter,
        saaEnabled:           String(parseInt(selectedMethod.tradingPeriodsBefore || selectedMethod.tradingPeriodsSkip) > 0),
        thresholdRuleEnabled: String(parseFloat(selectedMethod.basisThreshold) > 0),
        tradingPeriodsBefore: selectedMethod.tradingPeriodsBefore,
        tradingPeriodsSkip:   selectedMethod.tradingPeriodsSkip,
        lookBackWindow:       selectedMethod.lookBackWindow,
        smallLoadThreshold:   selectedMethod.smallLoadThreshold,
        basisThreshold:       selectedMethod.basisThreshold,
        name:                 selectedMethod.name,
        groups: this.buildGroups(selectedMethod),
        organisations: selectedMethod.organisations || []
      }, this.loadBaseline)
    }
    else{
      this.loadBaseline()
    }
  }

  handleChartDblClick = function(tester, event, g, context){
    this.zoomRequested = false
    event.preventDefault()
    const { chartData, rmsd, mae } = tester.buildChartData(tester.state.actuals, tester.state.baseline)
    tester.setState({rmsd, mae, chartData: { data: chartData, labels: ['Date', 'Actual', 'Baseline']}})
    tester.setState(
      {highlighted: undefined},
      () => tester.chartRef.updateOptions({
        file: tester.state.chartData.data
      })
    )
  }.bind(Dygraph, this)

  handleChartClick = function (event, g, context) {
    this.zoomRequested = false
    event.preventDefault()
  }.bind(Dygraph)

  handleChartMouseDown = function(event, g, context){
    context.initializeMouseDown(event, g, context);
    this.zoomRequested = true
  }.bind(Dygraph)

  handleChartMouseMove = function(event, g, context){
    if(this.zoomRequested){
      this.zoomRequested = false
      this.startZoom(event, g, context)
    }
    if(context.isZooming){
      this.moveZoom(event, g, context)
    }
  }.bind(Dygraph)

  handleChartMouseUp = function(tester, event, g, context){
    if(context.isZooming){
      g.clearZoomRect_();
      context.isZooming = false;
      const [fromZoom, toZoom] = [context.dragStartX, context.dragEndX].sort((x,y) => x - y).map(x => roundDateOrMoment(new Date(g.toDataXCoord(x)), TradingPeriod.length.inMinutes, false))
      tester.handleSimulateEvent(fromZoom, toZoom)
      tester.setState({
        highlighted: {
          x1: +fromZoom,
          x2: +toZoom
        }},
        () => tester.chartRef.updateOptions({
          file: tester.state.chartData.data
        })
      )
    }
  }.bind(Dygraph, this)

  handleUnderlayCallback = (canvas, area, g) => {
    canvas.fillStyle = "rgba(255, 255, 102, 0.4)";
    if(this.state.highlighted){
      const x1 = g.toDomXCoord(this.state.highlighted.x1)
      const x2 = g.toDomXCoord(this.state.highlighted.x2)
      canvas.fillRect(
        x1,
        0,
        x2 - x1,
        1000
      )
    }
  }

  handleSimulateEvent = async (fromZoom, toZoom) => {
    if(!((this.props.filter.sites || []).length + (this.props.filter.registrations || []).length))
      return
    try{
      this.setState({baselineCalculationError: undefined})
      const { data: result } = await API.VerificationMethods.baseline({
        ...this.props.filter,
        from: fromZoom.toString(),
        to: toZoom.toString()
      })
      const startIndex = 2 * (fromZoom.diff(moment(this.props.filter.from), 'minutes') / 60)
      const endIndex   = startIndex + (2 * toZoom.diff(fromZoom, 'minutes') / 60)
      const splicedActuals = [...this.state.actuals]
      const splicedBaseline = [...this.state.baseline]
      splicedActuals.splice(startIndex,  endIndex - startIndex, ...result.actuals)
      splicedBaseline.splice(startIndex, endIndex - startIndex, ...result.baseline)
      const { chartData: splicedChartData } = this.buildChartData(splicedActuals, splicedBaseline)
      this.setState({chartData: { data: splicedChartData, labels: ['Date', 'Actual', 'Baseline']}, rmsd: this.rmsd(result.actuals, result.baseline), mae: this.mae(result.actuals, result.baseline)})
      return result
    }catch(err){
      this.setState({baselineCalculationError: err})
    }finally{
      this.chartRef.resize()
    }
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.filter.verificationMethodId !== prevProps.filter.verificationMethodId){
      const selectedMethod = this.currentVerificationMethod
      this.props.onFilterChange({
        ...this.props.filter,
        saaEnabled:           String(parseInt(selectedMethod.tradingPeriodsBefore || selectedMethod.tradingPeriodsSkip) > 0),
        thresholdRuleEnabled: String(parseFloat(selectedMethod.basisThreshold) > 0),
        tradingPeriodsBefore: selectedMethod.tradingPeriodsBefore,
        tradingPeriodsSkip:   selectedMethod.tradingPeriodsSkip,
        lookBackWindow:       selectedMethod.lookBackWindow,
        smallLoadThreshold:   selectedMethod.smallLoadThreshold,
        basisThreshold:       selectedMethod.basisThreshold,
        name: selectedMethod.name,
        groups: this.buildGroups(selectedMethod),
        organisations: selectedMethod.organisations || []
      }, this.loadBaseline)
    }
  }

  buildGroups = verificationMethod => {
    const groups = {}
    for(var i = 1; i <= 8; i++){
      days.forEach((day, dayIndex) => {/* eslint-disable-line */
        if(verificationMethod[`${day}Group`] === i){
          groups[`group${i}`] = groups[`group${i}`] || {
            index: i,
            days: [],
            included: verificationMethod[`group${i}IncludedDays`],
            count:    verificationMethod[`group${i}LookbackDays`]
          }
          groups[`group${i}`].days.push(dayIndex)
        }
      })
    }
    return groups
  }

  loadVerificationMethods  = () => {
    return this.actions.verificationMethods.index({
      filter: { methodType: 'saa'},
      include: 'organisations',
      pageSize: 200
    })
  }

  buildComparisonFileURL = () => {
    const params = {
      ...(this.state.highlighted ? {
        from: moment(this.state.highlighted.x1).toString(),
        to: moment(this.state.highlighted.x2).toString(),
      } : {
        from: this.props.filter.from,
        to: this.props.filter.to,
        periodsPerChunk: this.periodsPerChunk,
      }),
      sites: this.props.filter.sites,
      registrations: this.props.filter.registrations,
      verification_method_ids: this.state.selectedVerificationMethods,
      Authorization: TokenStore.auth
    }
    const queryString = qs.stringify(params, { arrayFormat: 'brackets' })
    return `/api/verification_methods/comparison?${queryString}`
  }

  avg  = list => list.reduce((agg, val) => agg + val, 0) / list.length

  rmsd = (actuals, baseline) => {
    const data  = new Array(baseline.length).fill(null)
    const indices = data.map((_,idx) => actuals[idx] && baseline[idx] ? idx : null).filter(idx => idx)
    return indices.length > 0 ?
      Math.sqrt(this.avg(indices.map((idx, _) => Math.pow(actuals[idx] - baseline[idx], 2)))).toFixed(2) :
      null
  }

  mae = (actuals, baseline) => {
    const data  = new Array(baseline.length).fill(null)
    const indices = data.map((_,idx) => actuals[idx] && baseline[idx] ? idx : null).filter(idx => idx)
    return indices.length > 0 ?
      this.avg(indices.map((idx, _) => Math.abs(actuals[idx] - baseline[idx]))).toFixed(2) :
      null
  }

  buildChartData = (actuals, baseline) => {
    const count = baseline.length
    if(!count || count <= 0)
      return {data: [[0,0]], labels: ['Date', '']}
    const data  = new Array(count).fill(null)
    const date = new Date(this.props.filter.from)
    const rmsd = this.rmsd(actuals, baseline)
    const mae  = this.mae(actuals, baseline)
    const chartData = data.map((_, idx) => {
      return [
        new Date(date.getTime() + idx * TradingPeriod.length.inMilliseconds),
        parseFloat((actuals[idx] || 0).toFixed(3)) + 1e-10,
        parseFloat((baseline[idx] || 0).toFixed(3)) + 1e-10,
      ]
    })
    return { chartData, rmsd, mae }
  }

  loadBaseline = deparallelize(async () => {
    if(!((this.props.filter.sites || []).length + (this.props.filter.registrations || []).length))
      return
    this.setState({baselineCalculationError: undefined, loadingBaseline: true})
    try{
      const {verificationMethodId, ...filter} = this.props.filter
      const { data: { actuals, baseline } } = await API.VerificationMethods.baseline({
        periodsPerChunk: this.periodsPerChunk,
        ...filter
      })
      const { chartData, rmsd, mae } = this.buildChartData(actuals, baseline)
      this.setState({rmsd, mae, actuals, baseline, chartData: { data: chartData, labels: ['Date', 'Actual', 'Baseline']}})
    }catch(baselineCalculationError){
      this.setState({baselineCalculationError})
    }finally{
      this.chartRef.resize()
      this.setState({loadingBaseline: false})
    }
  })

  get verificationMethods(){
    return this.props.verificationMethods
  }

  get currentVerificationMethod(){
    return this.verificationMethods.find(vm => `${vm.id}` === `${this.props.filter.verificationMethodId}`) || {}
  }

  get filter(){
    return Object.entries(this.props.filter || {})
                 .reduce((agg, [key, value]) => ({...agg, [key]: this.parseFilter(key, value)}), {})
  }

  get errors(){
    let errors = [...this.state.baselineCalculationError ? [this.state.baselineCalculationError] : []];
    ['index', 'update', 'create', 'destroy'].forEach(action => {
      if(this.props.errors[action]){
        errors = errors.concat(this.props.errors[action])
      }
    })
    return errors
  }

  get organisationPermissions(){
    return ((this.props.permissions || {}).organisation || {})[this.organisationId] || {}
  }

  get periodsPerChunk(){
    const tpDiff = moment(this.props.filter.to).diff(this.props.filter.from, 'm') / TradingPeriod.length.inMinutes
    return Math.min(Math.max(2, parseInt(tpDiff / 48)), 46)
  }

  parseFilter(key, value){
    switch(key){
    case 'from':
    case 'to':
      return roundDateOrMoment(moment(value), TradingPeriod.length.inMinutes).toDate()
    case 'registrations':
    case 'sites':
      return flatten([value])
    default:
      return value
    }
  }
  get verificationMethodAttributes(){
    const { name, tradingPeriodsSkip, tradingPeriodsBefore, lookBackWindow, smallLoadThreshold, basisThreshold, groups, organisations } = this.props.filter
    const attributes = {
      name,
      ...this.currentVerificationMethod.id ? {id: this.currentVerificationMethod.id} : {},
      methodType: 'saa',
      tradingPeriodsBefore: this.saaDisabled ? null : tradingPeriodsBefore,
      tradingPeriodsSkip: this.saaDisabled ? null : tradingPeriodsSkip,
      basisThreshold: this.thresholdRuleDisabled ? null : basisThreshold,
      smallLoadThreshold: this.thresholdRuleDisabled ? null : smallLoadThreshold,
      lookBackWindow,
      organisationIds: (organisations || []).map(({id}) => id),
      ...Object.entries(groups).reduce((agg, [groupName, group]) => {
        const groupId = groupName.match(/\d+/)[0]
        agg[`group_${groupId}LookbackDays`] = group.count
        agg[`group_${groupId}IncludedDays`] = group.included
        group.days.forEach(day => {
          agg[`${days[parseInt(day, 10)]}Group`] = groupId
        })
        return agg
      },{})
    }
    return attributes
  }

  handleSaveBaseline = (create) => async () => {
    this.setState({baselineCalculationError: undefined})
    const { data: { id: verificationMethodId }} = await (create ?
          this.actions.verificationMethods.create(this.verificationMethodAttributes) :
          this.actions.verificationMethods.update(this.verificationMethodAttributes))
    await this.loadVerificationMethods()
    await this.props.onFilterChange({...this.props.filter, verificationMethodId })
  }

  handleDeleteVerificationMethod = async () => {
    if(!global.confirm("Are you sure you want to delete this verification method? This action cannot be undone.")){
      return
    }
    this.setState({baselineCalculationError: undefined})
    await this.actions.verificationMethods.destroy(this.currentVerificationMethod)
    await this.loadVerificationMethods()
    await this.props.onFilterChange({...this.props.filter, verificationMethodId: undefined})
  }

  handleDownloadComparisonFile = event => {
    if(this.state.selectedVerificationMethods.length){
      event.preventDefault()
      event.stopPropagation()
      window.open(this.buildComparisonFileURL())
    }
    this.handleHideDownloadComparisonDialog()
  }

  handleShowDownloadComparisonDialog = () => {
    this.setState({
      downloadComparisonDialogOpen: true,
      selectedVerificationMethods: []
    })
  }

  handleHideDownloadComparisonDialog = () => {
    this.setState({
      downloadComparisonDialogOpen: false
    })

  }

  handleSetSelectedVerificationMethods = ({ target: { value: selectedVerificationMethods}}) => {
    this.setState({selectedVerificationMethods})
  }
  handleFilterChange = ({from, to, lookBackWindow, tradingPeriodsBefore, tradingPeriodsSkip, smallLoadThreshold, ...others}) => {
    if(from && typeof from === 'object') from = from.toISOString()
    if(to   && typeof to === 'object')     to = to.toISOString()
    const filter = {
      from: from && from,
      to: to && to,
      lookBackWindow: Math.max(0,lookBackWindow),
      tradingPeriodsBefore: Math.max(0,tradingPeriodsBefore),
      tradingPeriodsSkip: Math.max(0,tradingPeriodsSkip),
      smallLoadThreshold: Math.max(0,smallLoadThreshold),
      ...others
    }
    this.props.onFilterChange(filter, () => {
      this.loadBaseline()
    })
  }

  handleRegistrationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: registrations } = await API.Registrations.index(params)
    callback(registrations.filter(({id}) => !(this.filter.registrations || []).map(({id}) => `${id}`).includes(`${id}`)).slice(0, 5))
  }

  handleSitesFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: sites } = await API.Sites.index(params)
    callback(sites.filter(({id}) => !(this.filter.sites || []).map(({id}) => `${id}`).includes(`${id}`)).slice(0, 5))
  }

  handleOrganisationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: organisations } = await API.Organisations.index(params)
    callback(organisations.filter(({id}) => !(this.filter.organisations || []).map(({id}) => `${id}`).includes(`${id}`)).slice(0, 5))

  }

  handleCollapse = name => () => {
    this.props.onFilterChange({...this.props.filter, [`${name}Collapsed`]:this.props.filter[`${name}Collapsed`] !== 'true'},
      () => this.chartRef.resize()
    )
  }

  traverse = (multiplier, unit) => () => {
    const filter = {...this.filter}
    filter.from = moment(filter.from).add(multiplier,unit)
    filter.to = moment(filter.to).add(multiplier,unit)
    this.handleFilterChange(filter)
  }

  get timeSpan(){
    return moment(this.filter.to).diff(this.filter.from)
  }

  get spansMoreThanWeek(){
    return this.timeSpan >= moment.duration(1, 'week')
  }

  get thresholdRuleDisabled(){
    return this.props.filter.thresholdRuleEnabled === 'false'
  }

  get saaDisabled(){
    return this.props.filter.saaEnabled === 'false'
  }

  renderErrorMetrics = () =>
    <div className={this.props.classes.errors}>
      {
        this.state.rmsd &&
        <div className={this.props.classes.errorBox}>
          <div className={this.props.classes.errorTitle}>RMSD</div>
          <div className={this.props.classes.errorValue}>{this.state.rmsd}kWh</div>
        </div>
      }
      {
        this.state.mae &&
        <div className={this.props.classes.errorBox}>
          <div className={this.props.classes.errorTitle}>MAE</div>
          <div className={this.props.classes.errorValue}>{this.state.mae}kWh</div>
        </div>
      }
    </div>

  renderFilters = () =>
    <section  className={this.props.classes('filters', {collapsed: this.props.filter.filtersCollapsed === 'true'})}>
      <IconButton className={this.props.classes.collapseButton} onClick={this.handleCollapse('filters')}>
        <TabIcon/>
      </IconButton>
      <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
        <section className={this.props.classes.filterSection}>
          <Typography variant='h6'>Test Period</Typography>
          <IconButton onClick={this.traverse(-1, this.spansMoreThanWeek ? 'week' : 'day')}>
            <ChevronLeft/>
          </IconButton>
          <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('meterDataFilter', 'dateFilter')} member='from'/>
          <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('meterDataFilter', 'dateFilter')} member='to'/>
          <IconButton onClick={this.traverse(1, this.spansMoreThanWeek ? 'week' : 'day')}>
            <ChevronRight/>
          </IconButton>
        </section>
        <section className={this.props.classes.filterSection}>
          <Typography variant='h6'>Target</Typography>
          <MultiAutoSuggest
            classNames={{container: this.props.classes.meterDataFilter, input: this.props.classes.meterDataFilterInput}}
            member='sites'
            labelProvider={({name}) => name}
            valueProvider={({id, name}) => ({id, name})}
            onSuggestionsFetchRequested={this.handleSitesFetchRequested}
          />
          <MultiAutoSuggest
            classNames={{container: this.props.classes.meterDataFilter, input: this.props.classes.meterDataFilterInput}}
            member='registrations'
            labelProvider={({name}) => name}
            valueProvider={({id, name}) => ({id, name})}
            onSuggestionsFetchRequested={this.handleRegistrationsFetchRequested}
          />
        </section>
        <br/>
        <section className={this.props.classes.filterSection}>
          <Typography variant='h6'>Verification Method</Typography>
          <LabeledSelect fullWidth label='Verification Method' member='verificationMethodId'>
            <MenuItem value={'new'}>New</MenuItem>
            {
              this.verificationMethods.map(verificationMethod =>
                <MenuItem key={verificationMethod.id} value={verificationMethod.id}>{verificationMethod.name}</MenuItem>
              )
            }
          </LabeledSelect>
          {
            this.currentVerificationMethod.id &&
            <Fragment>
              <Tooltip title="Delete Verification Method">
                <IconButton aria-label="Delete" onClick={this.handleDeleteVerificationMethod}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </Fragment>
          }
           <Tooltip title="Download Comparison File">
            <IconButton onClick={this.handleShowDownloadComparisonDialog}>
              <CloudDownloadIcon/>
            </IconButton>
          </Tooltip>
        </section>
        {
          /**
          Basis Days – Groupings of Day Types (1-8 day types including public holidays)
          Basis Days - (0-10)
          Basis Days Exclusions (4/5 basis Days for Day Type 1, and say 2/3 Basis days for Day type 3 (Sundays/Holidays)
           */
        }
        {
          this.organisationPermissions.writeVerificationMethods ?
            <Fragment>
              <section className={this.props.classes.filterSection}>
                <Typography variant='h6'>Basis Days</Typography>
                <BasisDaySelector member='groups'/>
              </section>
              <section className={this.props.classes.filterSection}>
                <div className={this.props.classes.switchAlignment}>
                  <Typography variant='h6'>Threshold Rule</Typography>
                  <Switch checked={!this.thresholdRuleDisabled} member='thresholdRuleEnabled' className={this.props.classes.filterToggle}/>
                </div>
                  <TextField disabled={this.thresholdRuleDisabled} member='basisThreshold' type='number' label='Threshold %'/>
                  <TextField disabled={this.thresholdRuleDisabled} member='smallLoadThreshold' type='number' label='Small load threshold'/>
                <Typography variant='h6'>Look back window</Typography>
                <TextField member='lookBackWindow' type='number' label='Days'/>
              </section>
              <section className={this.props.classes.filterSection}>
                <div className={this.props.classes.switchAlignment}>
                  <Typography variant='h6'>SAA</Typography>
                  <Switch checked={!this.saaDisabled} member='saaEnabled' className={this.props.classes.filterToggle}/>
                </div>
                <div style={{display: 'flex', flex: '1 1 100%', flexDirection: 'column'}}>
                  <TextField key={this.saaDisabled ? undefined : 'tradingPeriodsSkip'} inputProps={{min: 0}} disabled={this.saaDisabled} member={this.saaDisabled ? undefined : 'tradingPeriodsSkip'} type='number' label='Periods to skip'/>
                  <TextField key={this.saaDisabled ? undefined : 'tradingPeriodsBefore'} inputProps={{min: 1}} disabled={this.saaDisabled} member={this.saaDisabled ? undefined : 'tradingPeriodsBefore'} type='number' label='Periods to average'/>
                </div>
              </section>
            </Fragment> :

            <section className={this.props.classes.filterSection}>
              {this.renderErrorMetrics()}
            </section>
        }
      </FormContext>
    </section>

  render = () =>
    <div className={this.props.classes.wrapper}>
      {
        this.errors.length ?
        <ErrorBanner>
          {errorStringsFromError(this.errors)}
        </ErrorBanner> :
        false
      }
      {this.renderFilters()}
      {
        this.props.requests.length > 0 && !this.chartRef?
          <Loader/> :
          <Fragment>
            {
              this.state.loadingBaseline &&
              <div style={{
                height: 0,
                overflow: 'visible',
                position: 'relative',
                size: 100,
                top: 20,
                zIndex: 10
              }}>
                <Loader size={150}/>
              </div>
            }
            <LineChart
              chartRef={ref => {
                console.log('Setting chart ref', ref)
                this.chartRef = ref
              }}
              chartData={this.state.chartData}
              rangeSelectorPlotFillColor='rgba(170, 170, 190, 0.7)'
              rangeSelectorPlotFillGradientColor='rgba(170, 170, 190, 0.2)'
              underlayCallback={this.handleUnderlayCallback}
              interactionModel={{
                mousedown : this.handleChartMouseDown,
                mousemove : this.handleChartMouseMove,
                mouseup : this.handleChartMouseUp,
                click: this.handleChartClick,
                dblclick: this.handleChartDblClick
              }}
              className={this.props.classes.lineChart}
            />
          </Fragment>
      }

      {
        this.organisationPermissions.writeVerificationMethods &&
        <Fragment>
          <IconButton className={this.props.classes.saveToggleButton} onClick={this.handleCollapse('save')}
                      style={this.props.filter.saveCollapsed === 'true' ? {transform: 'rotate(180deg)'} : {}}>
            <TabIcon/>
          </IconButton>
          {
            this.props.filter.saveCollapsed !== 'true' &&
            <div className={this.props.classes.saveBar}>
              {this.renderErrorMetrics()}
              <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
                <section className={this.props.classes.saveSection}>
                  <Typography variant='h6'>Save As</Typography>
                  <TextField member='name' style={{paddingTop: '2px'}}/>
                  <MultiAutoSuggest
                    classNames={{container: this.props.classes.meterDataFilter, input: this.props.classes.meterDataFilterInput}}
                    member='organisations'
                    label='Restrict to Organisations'
                    expandsUpwards
                    labelProvider={({name}) => name}
                    valueProvider={({id, name}) => ({id, name})}
                    onSuggestionsFetchRequested={this.handleOrganisationsFetchRequested}
                    style={{alignSelf: 'center'}}
                  />
                  <div className={this.props.classes.baselineSaveButtons}>
                    {
                      (!this.currentVerificationMethod.id || this.currentVerificationMethod.name !== this.props.filter.name) &&
                      <Button onClick={this.handleSaveBaseline(true)} color='secondary' variant='contained' type='submit'>Create</Button>
                    }
                    {
                      (this.currentVerificationMethod.id ) &&
                      <Button onClick={this.handleSaveBaseline(false)} color='primary' variant='contained' type='submit'>Update</Button>
                    }
                  </div>
                </section>
              </FormContext>
            </div>
          }
        </Fragment>
      }
      <Dialog open={!!this.state.downloadComparisonDialogOpen} classes={{paperScrollPaper: this.props.classes.comparisonSelector}}>
        <DialogTitle>Download Comparison File</DialogTitle>
        <DialogContent classes={{root: this.props.classes.comparisonSelector}}>
          <Typography variant='body1'>
            Which verification methods would you like to compare?
          </Typography>
          <MultiAutoSuggest
           member='verificationMethodId'
           onChange={this.handleSetSelectedVerificationMethods}
           value={this.state.selectedVerificationMethods}
           fullWidth
           label='Verification Methods'
           labelProvider={id => (this.verificationMethods.find(({id: id_}) => id === id_) || {}).name}
           onSuggestionsFetchRequested={(text,callback) => callback(this.verificationMethods.filter(({name}) => name.includes(text)).map(({id}) => id).filter(id => !this.state.selectedVerificationMethods.includes(id)))}
           />
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={this.handleHideDownloadComparisonDialog}>Cancel</Button>
          <Button color='secondary' onClick={this.handleDownloadComparisonFile}>Download</Button>
        </DialogActions>
      </Dialog>
    </div>
}

const styles = theme => ({
  baselineSaveButtons: {
    marginTop: 5,
    flex: 1,
    display: 'flex',
    '& > button': {
      flex: 1
    }
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  errorTitle: {
    fontSize: '1rem'
  },
  errorValue: {
    fontSize: '2.5rem',
    textAlign: 'center'
  },
  errors: {
    maxWidth: 350,
    display: 'flex',
    marginLeft: '10%',
    alignSelf: 'center',
  },
  '@media(max-width: 600px)': {
    errorValue: {
      fontSize: '1.5rem !important'
    }
  },
  filterToggleButton:{
    position: 'absolute',
    right: 10,
    color: theme.palette.primary.main
  },
  saveToggleButton: {
    position: 'relative',
    color: theme.palette.primary.main,
    alignSelf: 'end',
    transition: 'transform 500ms',
  },
  filterToggle: {
    margin: '-5px -15px'
  },
  meterDataFilter: {},
  filters: {
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: "rgba(0, 27, 38, 0.5)",
    '& $meterDataFilter': {
      margin: 5
    },
  },
  saveBar:{
    background: "rgba(0, 27, 38, 0.5)",
    width: '100%',
    display: 'flex',
    flex: '0 1 auto'
  },
  saveSection: {
    padding: 25,
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 10,
    border: 'none',
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      marginLeft: 'auto',
    },
    '& > div': {
      width: '100%',
      margin: '5px',
      flex: '1 0 calc(50% - 80px)',
      '@media(max-width: 600px)': {
        flex: '1',
      },
    },
    '& > h6': {
      flex: '0 0 100%',
      '@media(max-width: 600px)': {
        flex: 0,
      },
    },
    '& > :last-child': {
      alignSelf: 'end'
    },
  },
  charts: {
    flex: 1,
    height: 0,
    backgroundColor: '#3b3b3b',
    boxShadow: 'inset 1px 1px 5px 7px rgba(0, 0, 0, 0.2)',
    color: 'white',
    overflow: 'hidden',
    '@media(max-width: 600px)': {
      height: 600
    },
  },
  lineChart: {
    borderBottom: '5px solid transparent',
    minHeight: '200px',
  },
  legend: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    background: 'black',
    borderRadius: 15,
    color: 'white',
    padding: 10,
    zIndex: 100
  },
  filterSection: {
    flex: '1 1 350px',
    padding: '15px 20px 15px 10px',
    display: 'flex',
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    minHeight: 90,
    '& > h6': {
      fontSize: '1rem',
      flex: '0 0 100%',
      marginBottom: '0',
      marginTop: '5px',
      paddingBottom: '5px',
    },
    '& > button': {
      width: 24,
      height: 24,
      marginTop: 13,
    },
    '& > div': {
      width: '100%',
      margin: '0 10px 0 0',
      flex: '1 0 calc(50% - 80px)',
    },
    '& > $switchAlignment': {
      display: 'flex',
      flex: '0 1 100%',
      flexWrap: 'noWrap',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& > $filterToggle': {
      flex: '0 1 40px',
      marginRight: '-25px',
    },
  },
  dateFilter: {
    paddingTop: 2
  },
  baselineFilter: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 230px)',
    '@media(max-width: 600px)': {
      height: 'auto'
    }
  },
  switchAlignment: {
    '& > h6': {
      fontSize: '1rem',
      width: '100%',
      alignContent: 'right',
      marginBottom: '0',
    },
  },
  collapsed: {
    '& > *': {
      display: 'none'
    },

    '&::before': {
      content: "'Filters'",
      lineHeight: '48px',
      color: 'white',
      fontWeight: 900,
      fontSize: '1rem',
      paddingLeft: 10,
      borderLeft: `3px solid ${theme.palette.secondary.light}`,
      display: 'block',
    },

    '& $collapseButton': {
      transform: 'rotate(180deg)',
      display: 'block'
    }
  },
  collapseButton: {
    color: 'white',
    fontSize: 11,
    position: 'absolute',
    zIndex: 100,
    top: 0,
    right: 0,
    transition: 'transform 500ms',
  },
  comparisonSelector: {
    overflowY: 'visible'
  },
})

export default compose(
  withStyles(styles),
  withPermissions(BaselineTester.requiredPermissions),
  connect(({verificationMethods, tokens: {currentUser: {organisation: {id: organisationId}}}}, {filter}) =>  ({
    ...verificationMethods,
    organisationId,
    filter: {
      from: moment().startOf('day').toISOString(),
      to: moment().endOf('day').toISOString(),
      saaEnabled: true,
      tradingPeriodsBefore: 6,
      tradingPeriodsSkip: 2,
      lookBackWindow: 45,
      smallLoadThreshold: 0,
      basisThreshold: 0.25,
      groups: { group1: { index: 1, days: [0,1,2,3,4,5,6,7], count: 5, included: 4}},
      ...filter
    }
  })),
  connectQueryString('baseline'),
)(BaselineTester)
