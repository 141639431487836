import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose,  } from 'utils'
import withStyles from 'styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Events, Registrations, MissingData } from './'

export class Dashboard extends Component{

  constructor(props) {
    super(props)
    this.state = {
      tabHistoryLocations: {}
    }
  }

  get selectedTab(){
    return this.props.match.params.tab || 'event_reports'
  }

  handleTabChange = (event, tab) => {
    const history = this.props.history
    const {pathname, search} = history.location
    this.setState({tabHistoryLocations: {...this.state.tabHistoryLocations, [this.selectedTab]: {pathname, search}}})

    const nextLocation = this.state.tabHistoryLocations[tab] || `/reports/${tab}`
    this.props.history.push(nextLocation)
  }

  render = () =>
    <Card className={this.props.classes.root}>
      <CardContent className={this.props.classes.cardContent}>
        <Typography variant='h4'>Reports</Typography>
        <Tabs value={this.selectedTab} onChange={this.handleTabChange}>
          <Tab value='event_reports' label='Event Reports'/>
          <Tab value='registration_reports' label='Registration Reports'/>
          <Tab value='missing_meter_data' label='Missing Meter Data'/>
        </Tabs>
        {this.selectedTab === 'event_reports' && <Events />}
        {this.selectedTab === 'registration_reports' && <Registrations />}
        {this.selectedTab === 'missing_meter_data' && <MissingData />}
      </CardContent>
    </Card>
}

const styles = theme => ({
  cardContent: {
    paddingBottom: 0
  },
  root: {
    width: '100%'
  }
})

export default compose(
  withStyles(styles),
  connect(() => ({})),
)(Dashboard)