export function basicComparison(a, b) {
  if(a < b ) return -1
  if(a > b ) return  1
  return 0
}

export function shallowEqual(a,b) {
  if(a && b && typeof a === 'object' && typeof b === 'object') {
    return [...new Set([...Object.keys(a), ...Object.keys(b)])].every(k => a[k] === b[k])
  }
  return a === b
}

export function deepEqual(a,b) {
  if(a && b) {
    if(Array.isArray(a) && Array.isArray(b)) {
      if(a.length !== b.length) {
        return false
      }
      return a.every((e,i) => deepEqual(e,b[i]))
    } else if(typeof a === 'object' && typeof b === 'object') {
      return [...new Set([...Object.keys(a), ...Object.keys(b)])].every(k => deepEqual(a[k],b[k]))
    }
  }
  return a === b
}