import React, { Component, Fragment } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Label, Text } from 'recharts'
import { DeviceActions } from 'actionsets'
import WidgetContainer from './WidgetContainer'
import DeviceChartColors from 'constants/DeviceChartColors'
import AutoDRTypes from 'constants/AutoDRTypes'
import { connect } from 'react-redux'
import { compose, underscore } from 'utils'
import withStyles from 'styles'
import {alpha} from '@material-ui/core/styles/colorManipulator'

const fixName = deviceType => {
  const processedType = underscore((/(.*)::Device/.exec(deviceType) || [])[1] || deviceType)
  return AutoDRTypes[underscore(processedType)] || processedType
}

export class DeviceChartWidget extends Component {
  constructor(props) {
    super(props)
    DeviceActions.bindActions(this)
  }

  componentDidMount = () => this.actions.stats()

  renderPieLabel = props => {
    const { cx, cy, midAngle, innerRadius, outerRadius } = props
    const radius = (innerRadius + outerRadius) / 2
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180)
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180) + 5
    return <text x={x} y={y} fill="white" textAnchor='middle'>{props.payload.value}</text>
  }

  renderTypeLabel = props => {
    const {viewBox: {cx, cy}} = props
    const totalStr = Object.values(this.props.stats.tally_by_type).reduce((sum, value) => sum + value, 0)
    return <Fragment>
      <Text x={cx} y={cy - 3} textAnchor="middle" verticalAnchor="end" fill="#FFF">Devices</Text>
      <Text x={cx} y={cy + 3} textAnchor="middle" verticalAnchor="start" fill="rgba(255,255,255,0.8)">{
        totalStr
      }</Text>
    </Fragment>
  }

  render = () =>
    Object.keys(this.props.stats.tally_by_type || {}).length !== 0 &&
    <WidgetContainer className={this.props.classes.widgetContainer}>
      <ResponsiveContainer width='100%' height='100%'>
        <PieChart>
          <Legend />
          <Pie
            dataKey="value"
            stroke='#0b374f'
            strokeWidth={2}
            startAngle={0}
            endAngle={-360}
            label={this.renderPieLabel}
            labelLine={false}
            outerRadius={95}
            innerRadius={55}
            data={Object.keys(this.props.stats.tally_by_type).map(k => ({name: fixName(k),value:this.props.stats.tally_by_type[k]}))}>
            <Label position="center"
              content={this.renderTypeLabel}
            />
            {
              Object.keys(this.props.stats.tally_by_type).map(k =>
                <Cell key={k} fill={alpha(DeviceChartColors[k] || "#6660FF", 0.85)}/>
              )
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </WidgetContainer>
}

const styles = theme => ({
  widgetContainer: {
    margin: '5px 0'
  }
})

export default compose(
  connect(({devices:{stats}}) => ({stats})),
  withStyles(styles)
)(DeviceChartWidget)
