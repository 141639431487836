import React, { Component } from 'react'
import { KeyboardDateTimePicker as DTPicker } from '@material-ui/pickers'
import { roundDateOrMoment } from 'utils'
import DateTimePickerToolbar from './DateTimePickerToolbar'

export class DateTimePicker extends Component {

  static defaultProps = {
    minutesStep: 1,
    customToolbar: true
  }

  constructor(props) {
    super(props)
    this.state = { key: 0 }
  }

  handleChange = date => {
    const value = date ? this.round(date, this.props.minutesStep).format('YYYY-MM-DDTHH:mm:ssZ') : date
    this.props.onChange({
      target: { value }
    })
  }

  round = (date, nearest) => {
    return roundDateOrMoment(date, nearest)
  }

  get value(){
    return this.props.value ? new Date(this.props.value) : null
  }

  render = () => {
    const { minutesStep, customToolbar, ...props } = this.props
    return (
        <DTPicker
          minutesStep={minutesStep}
          ampm={false}
          allowKeyboardControl={false}
          format="DD/MM/YY HH:mm"
          placeholder="DD/MM/YY HH:mm"
          {...props}
          onChange={this.handleChange}
          value={this.value}
          {...customToolbar && {ToolbarComponent: DateTimePickerToolbar }}
        />
    )
  }
}

export default DateTimePicker
