import React, { Component } from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import withStyles from 'styles'
import { compose } from 'utils'

class EnhancedTableToolbar extends Component{

  static defaultProps = {
    selectedLabel: 'selected',
  }

  render = () => {
    const { numSelected, classes } = this.props

    return (
      <Toolbar
        className={classes('root',this.props.className, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} {this.props.selectedLabel}
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {this.props.title}
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.toolbarContent}>
          {this.props.children}
        </div>
        { this.props.onDeleteSelected &&
          <div className={classes.actions}>
            {
              numSelected > 0 &&
              <Tooltip title={this.props.deleteTooltip || "Delete"}>
                <IconButton aria-label="Delete" onClick={this.props.onDeleteSelected}>
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            }
          </div>
        }
      </Toolbar>
    )
  }
}

const styles = theme => ({
  root: {
    paddingRight: theme.spacing(1)
  },
  toolbarContent: {
    flex: '0 0.1 500px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: 'white',
          backgroundColor: theme.palette.primary.main,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.main,
        },
  spacer: {
    flex: '1 5 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

export default compose(
  withStyles(styles)
)(EnhancedTableToolbar)