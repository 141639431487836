import React, { Component, Fragment } from 'react'
import { compose } from 'utils'
import withStyles from 'styles'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { flatten } from 'utils'

export class MeterDataImportResultDialog extends Component  {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    errors: PropTypes.object,
    importResults: PropTypes.object,
  }

  handleCloseResultDialog = () => {
    this.props.onClose()
  }

  renderImportResults = () => {
    const {importedRows, warnings = []} = this.props.importResults

    return (
      <Fragment>
        <DialogContentText>{`Successfully imported ${importedRows} rows !`}</DialogContentText>
        <div>
          {
            warnings.map((warning, index) => <DialogContentText key={index}>{warning}</DialogContentText>)
          }
        </div>
      </Fragment>
    )
  }

  render = () => {
    const errMessages = []

    if(this.props.errors) {
      let { code, title, message, meta = {} } = this.props.errors
      errMessages.push(`${title}: ${message}`)
      switch(code){
        case 'INVALID_METER_DATA_HEADER':
          errMessages.push(...flatten(Object.entries(meta).map(([key, value]) => {
            return `${key}: ${value}`
          })))
          break
        default:
          errMessages.push(...flatten(Object.entries(meta).map(([key, value]) => {
            return value
          })))
      }

    }

    return (
      <Dialog open={this.props.open} onClose={this.handleCloseResultDialog}>
        <DialogTitle>Meter data import results</DialogTitle>
        <DialogContent>
          <div>
          {
            errMessages.map((errMsg, index) => <DialogContentText key={index}>{errMsg}</DialogContentText>)
          }
          </div>
          { (errMessages.length === 0 && this.props.importResults) && this.renderImportResults() }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseResultDialog} color="primary" autoFocus>Dismiss</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = theme => ({
})

export default compose(
  withStyles(styles),
)(MeterDataImportResultDialog)
