import React, { Component } from 'react'
import WidgetContainer from './WidgetContainer'
import { compose, formatLocaleNumeric } from 'utils'
import { connect } from 'react-redux'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import PastYearIcon from '@material-ui/icons/CalendarToday'
import AllTimeIcon from '@material-ui/icons/AccessTime'
import { palette } from 'styles/theme'
import {alpha, lighten} from '@material-ui/core/styles/colorManipulator'

export class PerformanceWidget extends Component{

  state = {
    mode: 'pastYear'
  }

  handleChangeMode = mode => () => {
    this.setState({mode})
  }

  get performance(){
    return this.props.averagePerformance[this.state.mode]
  }

  render = () => {
    const data = [{value: this.performance, name: 'test'}, {value: 100 - this.performance, name: 'best'}]
    return (
      <WidgetContainer className={this.props.classes.widgetContainer}>
        <div className={this.props.classes.chart}>
          <ResponsiveContainer width='100%' height='100%'>
            <PieChart>
              <Pie
                dataKey='value'
                data={data}
                innerRadius={'65%'}
                outerRadius={'100%'}
                startAngle={0}
                endAngle={-360}
                stroke='#0b374f'
                strokeWidth={2}
              >
                <Cell fill={this.performance > 60 ? alpha(palette.secondary.main, 0.85) : alpha(palette.primary.main, 0.85)}/>
                <Cell fill='#0b374f'/>
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <p className={this.props.classes.performancePercentage}>
            {formatLocaleNumeric(this.performance.toFixed(0))}%
          </p>
        </div>
        <small style={{marginTop: 18}}>
          Average performance for Demand Response events {this.state.mode === 'pastYear' ? 'in the past year' : 'across all time'}
        </small>
        <div className={this.props.classes.toggleButtons}>
          <Tooltip title="Past Year">
            <Button className={this.props.classes({disabled: this.state.mode === 'pastYear'})} onClick={this.handleChangeMode('pastYear')}>
              <PastYearIcon/>
            </Button>
          </Tooltip>
          <Tooltip title="All Time">
            <Button className={this.props.classes({disabled: this.state.mode === 'allTime'})} onClick={this.handleChangeMode('allTime')}>
              <AllTimeIcon/>
            </Button>
          </Tooltip>
        </div>
      </WidgetContainer>
    )
  }
}

const styles = theme => ({
  toggleButtons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    lineHeight: '0px',
    '& > button': {
      color: '#000',
      padding: 0,
      minWidth: 30,
      minHeight: 23,
      background: theme.palette.primary.light,
      borderRadius: 0,
      '& svg': {
        fontSize: 15
      },
      '&:hover':{
        background: lighten(theme.palette.primary.light, 0.25),
      },
      '&$disabled': {
        background: lighten(theme.palette.primary.light, 0.5)
      },
    }
  },
  disabled: {},
  performancePercentage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    paddingLeft: 10,
    transform: 'translate(-50%, -50%)',
    fontSize: '2.2rem',
    margin: 0,
  },
  widgetContainer: {
    maxHeight: 450,
    paddingBottom: 25,
    width: '100%',
    margin: '5px 0'
  },
  chart: {
    position: 'relative',
    flex: 1,
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%'
  }
})

export default compose(
  withStyles(styles),
  connect(),
)(PerformanceWidget)

