import React, { Component } from 'react'
import withStyles from 'styles'
import { compose } from 'utils'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import {theme} from 'styles'

export class WidgetContainer extends Component{

  static propTypes = {
    classes: PropTypes.func,
  }

  static defaultProps = {
    minHeight: 200
  }

  state = {
    renderFix: true
  }

  componentDidMount() {
    this.startRenderFix()
  }

  componentWillUnmount() {
    this.cancelRenderFix()
  }

  startRenderFix = () => {
    this.renderTimer = window.setTimeout(() => {
      this.setState({renderFix: !this.state.renderFix})
    }, 5000)
  }

  cancelRenderFix = () => {
    window.clearTimeout(this.renderTimer)
  }

  render = () =>
    <Paper className={this.props.classes('widgetContainer', 'widgetContainerBorder', this.props.width, this.props.className)} style={{minHeight: this.props.minHeight}}>
      <MuiThemeProvider theme={widgetTheme}>
        {this.props.children}
      </MuiThemeProvider>
      {this.state.renderFix && <div />}
    </Paper>

}

const styles = ({palette}) => ({
  widgetContainerBorder: {
    border: `1px solid ${palette.primary.light}`,
    borderTop: `6px solid ${palette.primary.light}`,
  },
  widgetContainer: {
    color: 'white',
    maxWidth: 'calc(100vw - 20px)',
    padding: 15,
    margin: 5,
    position: 'relative',
    boxShadow: '2px 3px 18px -6px #989898',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textAlign: 'center',
    '& h2': {
      textAlign: 'center'
    },
    flex: '1 1 auto'
  },
  xl: {
    width: 'calc(100% - 10px)',
    '@media(min-width: 1024px)': {
      width: 'calc(66.66666% - 10px)',
    },
    '@media(min-width: 2100px)': {
      width: 'calc(50% - 10px)',
    },
  },
  lg: {
    width: 'calc(100% - 10px)',
    '@media(min-width: 1024px)': {
      width: 'calc(66.66666% - 10px)',
    },
    '@media(min-width: 1600px)': {
      width: 'calc(50% - 10px)',
    },
    '@media(min-width: 2100px)': {
      width: 'calc(37.5% - 10px)',
    },
  },
  md: {
    width: 'calc(100% - 10px)',
    '@media(min-width: 768px)': {
      width: 'calc(50% - 10px)',
    },
    '@media(min-width: 1024px)': {
      width: 'calc(33.33333% - 10px)',
    },
    '@media(min-width: 2100px)': {
      width: 'calc(25% - 10px)',
    },
  },
  sm: {
    width: 'calc(100% - 10px)',
    '@media(min-width: 768px)': {
      width: 'calc(50% - 10px)',
    },
    '@media(min-width: 1024px)': {
      width: 'calc(33.33333% - 10px)',
    },
    '@media(min-width: 1600px)': {
      width: 'calc(16.66666% - 10px)',
    },
    '@media(min-width: 2100px)': {
      width: 'calc(12.5% - 10px)',
    },
  },
})

const widgetTheme = {
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiCard: {
      ...theme.overrides.MuiCard,
      root: {
        ...theme.overrides.MuiCard.root,
        background: 'rgba(0, 27, 38, 0.5)',
        borderTop: '2px solid rgba(150, 254, 172, 0.1)',
        border: '2px solid rgba(150, 254, 172, 0.1)',
        boxShadow: 'none',
        margin: 0,
      }
    }
  }
}

export default compose(
  withStyles(styles)
)(WidgetContainer)