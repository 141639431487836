import React, { Component } from 'react'
import {
  NotificationWidget,
  UpcomingEventsWidget,
  ParticipantWidgets,
  AvailabilityForecastWidget,
  LoadMapWidget,
  DeviceChartWidget,
  TasksWidget, CapacityWidget, VerticalGroup
} from './widgets'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import { compose, Authorization } from 'utils'
import { withPermissions } from 'containers/shared'
import { connect } from 'react-redux'

export class Dashboard extends Component{

  static propTypes = {
    classes: PropTypes.func
  }

  static requiredPermissions = ({match: { params: { id }}}) => ({
    organisation: {
      [Authorization.organisationId]: [
        'readSites','writeSites',
        'readDevices','writeDevices',
        'readSubstations','writeSubstations',
        'createRegistrations','accessRcpd', 'accessEm6',
        'writeProgrammes', 'readProgrammes',
      ]
    }
  })

  get permissions(){
    return this.props.permissions  ? this.props.permissions.organisation[Authorization.organisationId] : {}
  }

  render = () =>
    <div className={this.props.classes.dashboard}>
      <div className={this.props.classes.dashboardInner}>
        <UpcomingEventsWidget/>
        <VerticalGroup>
          <DeviceChartWidget />
          <CapacityWidget />
        </VerticalGroup>
        <ParticipantWidgets/>
        <TasksWidget/>
        <LoadMapWidget/>
        <NotificationWidget/>
        {this.permissions.writeProgrammes && <AvailabilityForecastWidget/>}
      </div>
    </div>

}

const styles = () => ({
  dashboard: {
    background: 'linear-gradient(to bottom, #118FCB 64px, #062D44 100%)',
    backgroundAttachment: 'fixed',
    padding: '0 5px 5px',
    flex: '1 1 auto',
  },
  dashboardInner: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  }
})

export default compose(
  withStyles(styles),
  withPermissions(Dashboard.requiredPermissions),
  connect(),
)(Dashboard)
