import React, { Component } from 'react'
import { compose } from 'utils'
import withStyles from 'styles'

export class VerticalGroup extends Component{

  render = () =>
      <div className={this.props.classes.container}>{this.props.children}</div>

}

const styles = {
  container: {
    width: 'calc(100% - 10px)',
    '@media(min-width: 768px)': {
      width: 'calc(50% - 10px)',
    },
    '@media(min-width: 1024px)': {
      width: 'calc(33.33333% - 10px)',
    },
    '@media(min-width: 1600px)': {
      width: 'calc(16.66666% - 10px)',
    },
    '@media(min-width: 2100px)': {
      width: 'calc(12.5% - 10px)',
    },

    margin: '0 5px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    minHeight: 600,

    '& > div': {
      height: '50%'
    }
  }
}

export default compose(
  withStyles(styles),
)(VerticalGroup)
