import React, { Component } from 'react'
import WidgetContainer from './WidgetContainer'
import withStyles from 'styles'
import { compose, roundDateOrMoment, flatten } from 'utils'
import { connect} from 'react-redux'
import { FormContext, DateTimePicker, MultiAutoSuggest } from 'components'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Authorization } from 'utils'
import moment from 'moment'
import { connectQueryString } from 'containers/shared'
import * as API from 'api'
import Typography from '@material-ui/core/Typography'
import TradingPeriod from 'constants/TradingPeriod'
import { BarChart } from 'components/charts'

export class AvailabilityForecastWidget extends Component{

  componentDidMount = () => {
    this.loadAvailabilities()
    setTimeout(() => this.chartRef && this.chartRef.resize(), 2000)
  }

  state = { }

  get tags(){
    return flatten(Object.entries(this.props.filter.tags || {}).map(([type, values]) =>
      flatten([values]).map(value => ({
        value,
        tagType: {
          name: type
        }
      }))
    ))
  }

  loadAvailabilities = async () => {
    const { data: availabilityForecast } = await API.Registrations.availabilityForecast({options: {
        filter: this.props.filter
      }
    })
    this.setState({
      chartData: {
        labels: ['Date', 'Price Responsive', 'Price Responsive (Committed)', 'Non Price Responsive', 'Non Price Responsive (Committed)'],
        data: this.buildChartData(availabilityForecast)
      }
    })
  }

  buildChartData = (availabilityForecast) => {
    const count = availabilityForecast.length
    if(!count || count <= 0)
      return [[moment(this.props.filter.from.to).toDate(), 0, 0, 0, 0]]
    const data  = new Array(count).fill({})
    const date = new Date(this.props.filter.from)
    const chartData = data.map((_, idx) => {
      return [
        new Date(date.getTime() + idx * TradingPeriod.length.inMilliseconds),
        availabilityForecast[idx].priceResponsiveNotCommitted,
        availabilityForecast[idx].priceResponsiveCommitted,
        availabilityForecast[idx].nonPriceResponsiveNotCommitted,
        availabilityForecast[idx].nonPriceResponsiveCommitted,
      ]
    })
    return chartData
  }

  handleFilterChange = ({from, to, ...others}) => {
    if(from && typeof from === 'object') from = from.toISOString()
    if(to && typeof to === 'object')     to = to.toISOString()
    const filter = {from: from && from, to: to && to, ...others}
    this.props.onFilterChange(filter, this.loadAvailabilities)
  }

  handleTagsChanged = ({target: { value }}) => {
    const tags = {};
    (value || []).forEach(({value, tagType: { name }}) => {
      if(!tags[name]) tags[name] = []
      tags[name].push(value)
    })
    this.props.onFilterChange({...this.props.filter, tags }, this.loadAvailabilities)
  }

  handleRegistrationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: registrations } = await API.Registrations.index(params)
    callback(registrations.map(({name}) => name).filter(name => !(this.filter.registration || []).includes(name)).slice(0, 5))
  }

  handleProgrammesFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: programmes } = await API.Programmes.index(params)
    callback(programmes.map(({name}) => name).filter(name => !(this.filter.programmeName || []).includes(name)).slice(0, 5))
  }

  handleOrganisationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: organisations } = await API.Organisations.index(params)
    callback(organisations.map(({name}) => name).filter(name => !(this.filter.organisation || []).includes(name)).slice(0, 5))
  }

  handleTagsFetchRequested = async (value, callback) => {
    const { data: values } = await API.TagValues.index({
      options: {
        page: { size: 50, number: 1 },
        filter: { value, ...this.props.filter },
        include: 'tagType'
      }
    })
    callback(values.filter(v => !((this.filter.tags || {})[v.tagType.name] || []).includes(v.value)).slice(0, 5))
  }

  renderTagLabel = ({value, tagType: { name }}) =>
    <span><strong>{name}</strong>: {value}</span>

  get filter(){
    return Object.entries(this.props.filter || {})
                 .reduce((agg, [key, value]) => ({...agg, [key]: this.parseFilter(key, value)}), {})
  }

  parseFilter(key, value){
    switch(key){
    case 'from':
    case 'to':
      return roundDateOrMoment(moment(value), TradingPeriod.length.inMinutes).toDate()
    case 'programmeName':
    case 'registration':
    case 'organisation':
    case 'name':
      return flatten([value])
    default:
      return value
    }
  }

  traverse = (multiplier, unit) => () => {
    const filter = {...this.filter}
    filter.from = moment(filter.from).add(multiplier,unit)
    filter.to   = moment(filter.to).add(multiplier,unit)
    this.handleFilterChange(filter)
  }

  renderFilters = () =>
    <section className={this.props.classes.filters}>
      <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
        <IconButton onClick={this.traverse(-1, 'day')} style={{marginLeft: -48}}>
          <ChevronLeft/>
        </IconButton>
        <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('reportsFilter', 'dateFilter')} member='from'/>
        <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('reportsFilter', 'dateFilter')} member='to'/>
        <IconButton onClick={this.traverse(1, 'day')}>
          <ChevronRight/>
        </IconButton>
         <MultiAutoSuggest
            classes={{wrapper: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='registration'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleRegistrationsFetchRequested}
          />
          <MultiAutoSuggest
            classes={{wrapper: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='programmeName'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleProgrammesFetchRequested}
          />
          <MultiAutoSuggest
            classes={{wrapper: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='organisation'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleOrganisationsFetchRequested}
          />
          {
            Authorization.systemPermissions.readTags &&
            <MultiAutoSuggest
              classes={{wrapper: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
              labelProvider={this.renderTagLabel}
              debounceWait={200}
              label='Tags'
              onChange={this.handleTagsChanged}
              value={this.tags}
              onSuggestionsFetchRequested={this.handleTagsFetchRequested}
            />
          }
      </FormContext>
    </section>

  render = () =>
    <WidgetContainer className={this.props.classes.widgetContainer} width='xl'>
      <div className={this.props.classes.header}>
        <Typography variant='h6' style={{marginTop: 15}}>Availability Forecast</Typography>
        {this.renderFilters()}
      </div>
      <BarChart
        chartRef={ref => this.chartRef = ref}
        chartData={this.state.chartData}
        className={this.props.classes.charts}
        axes={{
          y: {
            axisLabelFormatter: function(v) {
              return parseFloat((v / 1000).toFixed(2), 10) + '&nbsp;MW';  // controls formatting in the legend/mouseover
            },
            valueFormatter: function(v) {
              return parseFloat((v/ 1000).toFixed(2), 10) + '&nbsp;MW';  // controls formatting in the legend/mouseover
            }
          }
        }}
        colors={[
          "#13a7ec","#00629a","#66ff66", "#009933"
        ]}
        stackedGraph
        series={{
          "Price Responsive (Committed)": { fillGraph: true},
          "Price Responsive": { fillGraph: false},
        }}
      />
    </WidgetContainer>
}

const styles = theme => ({
  widgetContainer:{
    padding: 0
  },
  header: {
    boxShadow: '0px 20px 80px 20px rgba(0,0,0,0.2)'
  },
  charts: {
    flex: 1,
    height: 0,
    minHeight: 300,
    backgroundColor: 'rgba(0, 19, 27, 0.6)',
    borderBottom: '10px solid rgba(0, 0, 0, 0)',
    borderLeft: '10px solid rgba(0, 0, 0, 0)',
    padding: '0',
    color: 'white',
    overflow: 'hidden',
    '@media(max-width: 600px)': {
      height: 600
    }
  },
  filters: {
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    borderTop: '1px solid rgba(150, 254, 172, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: 'rgba(0, 27, 38, 0.5)',
    padding: '10px 10px 10px 48px',
    alignItems: 'self-end',
    '& $reportsFilter': {
      margin: 5
    },
    '& > button': {
      marginTop: 13
    }
  },
  dateRange: {
    display: 'flex',
    flex: '2 0 auto',
    alignItems: 'center',
    borderLeft: '1px solid white',
    borderRight: '1px solid white',
    overflow: 'hidden'
  },
  dateFilter: {
    paddingTop: 2
  },
  reportsFilter: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350,
  },
})

export default compose(
  connect((_, {filter}) => ({
    filter: {
      from: roundDateOrMoment(moment(), TradingPeriod.length.inMinutes).toISOString(),
      to: roundDateOrMoment(moment().add(1, 'day'), TradingPeriod.length.inMinutes).toISOString(),
      ...filter
    }
  })),
  withStyles(styles),
  connectQueryString('availabilityForecast'),
)(AvailabilityForecastWidget)