import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'

export class SnackbarActionSet extends ActionSet{

  static initialState = {
    message: ''
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static getPermissions(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(permissionsQuery => async dispatch => {
      return dispatch({
        type: constants.ACTION,
        promise: API.Permissions.permissionSet(permissionsQuery)
      })
    })

    reducer({
      [constants.SUCCESS]: (state, permissions) => {
        return {...state, permissions}
      }
    })
  }
}

export default new SnackbarActionSet()