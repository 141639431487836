import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dependent from 'containers/shared/Dependent'
import withStyles from 'styles'
import CardContent from '@material-ui/core/CardContent'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { compose } from 'utils'
import { withPermissions } from 'containers/shared'
import HolidayZones from './HolidayZones'
import List from './List'

export class Dashboard extends Component {
  dependsOn() {
    return true
  }

  dependenciesMet() {
    return true
  }

  static requiredPermissions = () => ({
    system: ['readCalendars','writeCalendars']
  })

  get selectedTab() {
    return (this.props.permissions.system.writeCalendars && this.props.match.params.tab) || "list"
  }

  onHandleTabChange = (event, tab) =>
    this.props.history.push(`/calendars/${tab}`)

  renderActiveTab = () => this.selectedTab === 'holiday_zones' ?
    <HolidayZones {...this.props} /> :
    <List {...this.props} />

  render = () =>
    <div className={this.props.classes.wrapper}>
      <CardContent>
        <Typography variant='h4'>Calendar</Typography>
        { this.props.permissions.system.writeCalendars &&
          <Tabs value={this.selectedTab} onChange={this.onHandleTabChange}>
            <Tab value="list" label="List" />
            <Tab value="holiday_zones" label="Holiday regions" />
          </Tabs>
        }
        <div className={this.props.classes.container}>
          {this.renderActiveTab()}
        </div>
      </CardContent>
    </div>
}

const styles = theme => ({
  wrapper: {
    // borderBottom: "1px solid #efefef"
  },
  container: {
    flex: 1,
    overflow: 'auto'
  },
  table: {
    marginTop: 25,
    borderTop: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    background: 'white'
  },
  head: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: "#FAFAFA",
    borderLeft: "1px solid #DEDEDE",
  }
})
export default compose(
  Dependent({loader: true}),
  withPermissions(Dashboard.requiredPermissions),
  connect(() => ({})),
  withStyles(styles)
)(Dashboard)