import React, { Component } from 'react'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import withStyles from 'styles'
import { compose } from 'utils'
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'

class EnhancedTable extends Component {

  static defaultProps = {
    rowsPerPage: 5,
    page: 0,
    count: 0,
    displayToolbar: true,
  }

  render() {
    const { classes, count, rowsPerPage, page } = this.props
    return (
      <Paper className={classes('root', this.props.className)}>
        { this.props.displayToolbar &&
          <EnhancedTableToolbar
            onDeleteSelected={this.props.onDeleteSelected}
            title={this.props.title}
            numSelected={this.props.numSelected}
            selectedLabel={this.props.selectedLabel}
            deleteTooltip={this.props.deleteTooltip || 'Delete'}
          >
            {this.props.toolbarContent || false}
          </EnhancedTableToolbar>
        }
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {this.props.children}
          </Table>
        </div>
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10,25,50]}
          page={page}
          backIconButtonProps={{'aria-label': 'Previous Page'}}
          nextIconButtonProps={{'aria-label': 'Next Page'}}
          onChangePage={(_, page) => this.props.onChangePage(page)}
          onChangeRowsPerPage={this.props.onChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

const styles = {
  tableWrapper: {
    overflowX: 'auto',
    fontSize: 10,
    '& td, & th': {
      width: 0,
      padding: 5
    }
  }
}

export default compose(
  withStyles(styles)
)(EnhancedTable)