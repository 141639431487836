import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'

export class MeterDataActionSet extends ActionSet{

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static initialState = {
    totalPages:  1,
    sites: [],
    gxps: [],
    substations: [],
    sums: {
      gxps: [],
      sites: [],
      substations: []
    },
    requests: [],
    errors:{}
  }

  static index(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({page: targetPage, params={}, fields=null, include=null, filter=null, order=null, pageSize=undefined }={}) => (dispatch, getState) => {
      let page = targetPage === undefined ? getState().meterData.page : targetPage
      return dispatch({
        type: constants.ACTION,
        promise: API.MeterData.index({...params, options: {page: { number: page, size: pageSize }, ...order && {order}, ...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
        payload: { type: params.type }
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, requestPayload: { type }, result: { data, meta: { total_pages: totalPages } } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, [type]: data, totalPages }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }


  static sum(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({page: targetPage, params={}, fields=null, include=null, filter=null, order=null, pageSize=15 }={}) => (dispatch, getState) => {
      let page = targetPage === undefined ? getState().meterData.page : targetPage
      return dispatch({
        type: constants.ACTION,
        promise: API.MeterData.sum({...params, options: {page: { number: page, size: pageSize }, ...order && {order}, ...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
        payload: { type: params.type }
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, requestPayload: { type }, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, sums: { ...state.sums, [type]: data }}
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }

  static download(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({page: targetPage, params={}, fields=null, include=null, filter=null, order=null, pageSize=15 }={}) => (dispatch, getState) => {
      let page = targetPage === undefined ? getState().meterData.page : targetPage
      return dispatch({
        type: constants.ACTION,
        promise: API.MeterData.download({...params, options: {page: { number: page, size: pageSize }, ...order && {order}, ...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
      })
    })

    reducer({})
  }

}

export default new MeterDataActionSet()