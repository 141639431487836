import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'
import { decodeJSONApiResponse } from 'utils'

export class CalendarActionSet extends CrudActionSetBase('calendar'){
  static initialState = {
    holidayMapping: [],
    names: {}
  }

  static getHolidayZones(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator(() => (dispatch, getState) => dispatch({
      type: constants.ACTION,
      promise: API.Calendars.getHolidayZones()
    }))

    reducer({
      [constants.REQUEST]: (state, request) => ({...state, requests: [...state.requests, request], errors: this.initialState.errors}),
      [constants.SUCCESS]: (state, {request, result: {meta: {mapping}, included}}) => {
        const requests = state.requests.filter(existingRequest => (existingRequest.request !== request))
        const names = included.reduce((acc,ref) => (
          {...acc, [ref.type]: [...(acc[ref.type] || []), decodeJSONApiResponse({data: ref, included}).data]}
        ), {})
        return {...state, requests, holidayMapping: mapping, names }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest.request !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }

  static saveHolidayZones(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator(patch => (dispatch, getState) => dispatch({
      type: constants.ACTION,
      promise: API.Calendars.saveHolidayZones(patch)
    }))

    reducer({
      [constants.REQUEST]: (state, request) => ({...state, requests: [...state.requests, request], errors: this.initialState.errors}),
      [constants.SUCCESS]: (state, {request, result}) => {
        const requests = state.requests.filter(existingRequest => (existingRequest.request !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest.request !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new CalendarActionSet()