import React, { Component } from 'react'
import { flatten } from 'utils'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'

const colors = [
  "#e6194b",
  "#3cb44b",
  "orange",
  "#0082c8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#d2f53c",
  "#008080",
  "#aa6e28",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1"
]


export class BasisDaySelector extends Component{

  static propTypes = {

  }

  static defaultGroups = {
    group1: {
      index: 1,
      days: [0,1,2,3,4,5,6,7],
      count: 5,
      included: 4
    }
  }

  get groups(){
    return this.props.value || BasisDaySelector.defaultGroups
  }

  get days(){
    return [
      {index: 0, name: 'Sunday'},
      {index: 1, name: 'Monday'},
      {index: 2, name: 'Tuesday'},
      {index: 3, name: 'Wednesday'},
      {index: 4, name: 'Thursday'},
      {index: 5, name: 'Friday'},
      {index: 6, name: 'Saturday'},
      {index: 7, name: 'Holiday'},
    ]
  }

  groupForDayIndex = dayIndex => {
    try{
      const group = Object.values(this.groups).find(group => flatten([group.days]).map(day => String(day)).includes(String(dayIndex)))
      return group
    }catch(err){
      return BasisDaySelector.defaultGroups.group1
    }
  }

   groupForIndex = groupIndex => {
    try{
      return this.groups[`group${groupIndex}`] || BasisDaySelector.defaultGroups.group1
    }catch(err){
      return BasisDaySelector.defaultGroups.group1
    }
  }

  handleSwapGroup = dayIndex => () => {
    const nextGroups   = {...this.groups}
    const currentGroup = this.groupForDayIndex(dayIndex)
    const nextIndex    = (currentGroup.index % 8) + 1
    const nextGroup    = nextGroups[`group${nextIndex}`] = nextGroups[`group${nextIndex}`] || {...BasisDaySelector.defaultGroups.group1, index: nextIndex, days: [] }
    nextGroup.days.push(dayIndex)
    currentGroup.days = currentGroup.days.filter(d => `${d}` !== `${dayIndex}`)
    if(currentGroup.days.length === 0)
      delete nextGroups[`group${currentGroup.index}`]
    this.props.onChange({target: { value: nextGroups }})
  }

  handleIncrementBasisCount = group => () => {
    const groupIndex = `group${group.index}`
    this.props.onChange({target: { value: {
      ...this.groups,
      [groupIndex]: {
        ...this.groups[groupIndex],
        count: (this.groups[groupIndex].count % 10) + 1,
        included: Math.min((this.groups[groupIndex].count % 10) + 1, this.groups[groupIndex].included)
      }
    }}})
  }

  handleIncrementIncludedCount = group => () => {
    const groupIndex = `group${group.index}`
    this.props.onChange({target: { value: {
      ...this.groups,
      [groupIndex]: {
        ...this.groups[groupIndex],
        included: (this.groups[groupIndex].included % this.groups[groupIndex].count) + 1
      }
    }}})
  }


  render = () =>
    <div className={this.props.classes.wrapper}>
      {this.days.map(({index: dayIndex, name}) =>
        <div key={dayIndex} className={this.props.classes.dayBlock}>
          <div className={this.props.classes.dayTitle}>
            {name.substr(0,3)}
          </div>
          <Button style={{background: colors[this.groupForDayIndex(dayIndex).index]}} className={this.props.classes.dayGroup} onClick={this.handleSwapGroup(dayIndex)}>
            {this.groupForDayIndex(dayIndex).index}
          </Button>
        </div>
      )}
      <div style={{clear: 'both'}}/>
      <Typography variant='h6' style={{ paddingTop: '15px', marginBottom: 5}}>Groups</Typography>
      <Table className={this.props.classes.table}>

        <TableBody>
          <TableRow>
            <TableCell style={{width: 75}}>{''}</TableCell>
            {
              Object.values(this.groups).map(group =>
                <TableCell key={group.index} className={this.props.classes.buttonCell} style={{color: 'white', background: colors[group.index]}}>
                  G{group.index}
                </TableCell>
              )
            }
            </TableRow>
            <TableRow>
              <TableCell className={this.props.classes.thead}>Days</TableCell>
                {
                  Object.values(this.groups).map(group =>
                    <TableCell key={group.index} className={this.props.classes.buttonCell}>
                      <Button onClick={this.handleIncrementBasisCount(group)}>
                        {group.count}
                      </Button>
                    </TableCell>
                  )
                }
            </TableRow>
            <TableRow>
              <TableCell className={this.props.classes.thead}>Included</TableCell>
                {
                  Object.values(this.groups).map(group =>
                    <TableCell key={group.index}  className={this.props.classes.buttonCell}>
                      <Button onClick={this.handleIncrementIncludedCount(group)}>
                        {group.included}
                      </Button>
                    </TableCell>
                  )
                }
            </TableRow>
          </TableBody>
      </Table>
    </div>
}

const styles = theme => ({
  thead: {
    background: theme.palette.primary.main,
    color: 'white',
    padding: '0 5px',
  },
  table: {
    tableLayout: 'fixed',
    width: 0,
  },
  dayBlock: {
    float: 'left',
    fontSize: 14,
    '@media(max-width: 700px)': {
      '& > button': {
        fontSize: 11
      }
    },
    '@media(max-width: 600px)': {
      '& > button': {
        fontSize: 10
      }
    }
  },
  groupBlock: {
    float: 'left'
  },
  dayTitle: {
    background: theme.palette.primary.main,
    color: 'white',
    padding: 5,
    fontSize: 14,
    '@media(max-width: 700px)': {
      '& > button': {
        fontSize: 11
      }
    },
    '@media(max-width: 600px)': {
      '& > button': {
        fontSize: 10
      }
    },
    textAlign: 'center'
  },
  dayGroup: {
    borderRadius: 0,
    color: 'white',
    minWidth: 40,
    '@media(max-width: 700px)': {
      '& > button': {
        fontSize: 35
      },
      '& > h6': {
        paddingBottom: '5px'
      }
    }
  },
  buttonCell: {
    width: 25,
    '& > button': {
      padding: 5,
      fontSize: 13,
      minWidth: 0
    },
    '@media(max-width: 700px)': {
      '& > button': {
        fontSize: 11
      }
    },
    '@media(max-width: 600px)': {
      '& > button': {
        fontSize: 10
      }
    }
  },
  wrapper: {
    '& > h6': {
      fontSize: '1rem',
    }
  }
})
export default withStyles(styles)(BasisDaySelector)
