import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { OrganisationRoleActions, OrganisationActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, PermissionsRow }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { compose } from 'utils'
import withStyles from 'styles'
import { humanize } from 'utils'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    OrganisationRoleActions.bindActions(this)
    OrganisationActions.bindActions(this, 'organisations')
  }

  state = {
    toggles: {},
    formAttributes: {}
  }

  dependsOn(){
    const actions = [
      this.editMode ? this.actions.show(this.objectId, {
        include: 'registrationPermission,organisationPermission,systemPermission,organisation'
      }) :this.actions.set({})
    ]
    return Promise.all(actions)
  }

  componentDidMount = async () => {
    this.actions.organisations.getPermissionNames(this.organisationId)
  }

  get permissions(){
    return Object.entries(this.props.permissionNames).filter(([key, value]) => Array.isArray(value))
  }

  get formObject(){
    return {organisationId: this.organisationId, ...this.props.organisationRole, ...this.state.formAttributes}
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.organisationRole && this.props.organisationRole.organisation.id)
  }

  toggleAll = (type, values) => () => {
    const off = !this.state.toggles[type]
    this.setState({
      toggles: {
        ...this.state.toggles,
        [type]: off
      },
      formAttributes: {
        ...this.state.formAttributes,
        [`${type}_permission_attributes`]: values.reduce((agg, value) => {
          agg[value] = off
          return agg
        }, {})
      }
    })
  }

  getPermissionValueFor = type => {
    const fromServer = this.props.organisationRole[`${type}Permission`]
    return this.state.formAttributes[`${type}_permission_attributes`] || fromServer
  }

  renderPermissionTypeRow = ([type, values]) => {
    const attributeName = `${type}_permission_attributes`
    return (
      <div>
        <Typography variant='subtitle1'>
          {humanize(type)} Permissions
          <button type='button' className={this.props.classes.toggleLink} onClick={this.toggleAll(type, values)}>Toggle All</button>>
        </Typography>

        <PermissionsRow member={attributeName} value={this.getPermissionValueFor(type)} values={values}/>
      </div>
    )
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Organisation Role - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
        {
          (this.props.permissionNames && this.permissions.map(this.renderPermissionTypeRow))
        }
      </FormContext>
    </Card>
}

const styles = theme => ({
  toggleLink: {
    float: 'right',
    fontSize: 11,
    border: 'none',
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({organisationRoles, organisations: { permissionNames}}) => ({...organisationRoles, permissionNames})),
)(Form)