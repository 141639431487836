import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'

export class CBLActionSet extends ActionSet{

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static initialState = {
    cbl: {
      complete: false,
      failureReasons: [],
      sites: [],
      results: {}
    },
    requests: [],
    errors: {}
  }

  static show(creator, reducer, constants){
    constants.defineRequestConstants()
    creator(({id}, {fields=null, include=null }={}) => {
      return {
        type: constants.ACTION,
        promise: API.ChildEvents.showCBL({id, options: { ...fields && {fields}, ...include && {include} }})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, cbl: this.initialState.cbl, requests: [...state.requests, request ], errors: {...state.errors, show: null, update: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, cbl: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, show: error }
        return {...state, requests, errors }
      }
    })
  }

  static download(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({page: targetPage, id, fields=null, include=null, filter=null, order=null, pageSize=15 }={}) => (dispatch, getState) => {
      let page = targetPage === undefined ? getState().meterData.page : targetPage
      return dispatch({
        type: constants.ACTION,
        promise: API.ChildEvents.downloadCBL({id, options: {page: { number: page, size: pageSize }, ...order && {order}, ...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
      })
    })

    reducer({})
  }

}
export default new CBLActionSet()