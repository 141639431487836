import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Gxps = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({regionId}) => regionId ? `regions/${regionId}/gxps` : 'gxps'),
    create:  endpoint => endpoint.method('post').path(({regionId}) => `regions/${regionId}/gxps`),
    update:  endpoint => endpoint.method('put').path(({id}) => `gxps/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `gxps/${id}`),
    show:    endpoint => endpoint.path(({id}) => `gxps/${id}`),
  }
)