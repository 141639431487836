import React, { Component } from 'react'
import withStyles from 'styles'
import Fab from '@material-ui/core/Fab'

export class FABAbsolute extends Component{

  static defaultProps = {
    color: 'primary'
  }

  render = () =>
    <Fab disabled={this.props.disabled} color={this.props.color} className={this.props.classes('fab', this.props.className)} onClick={this.props.onClick}>
      {this.props.children}
    </Fab>
}

const styles = theme => ({
  fab: {
    position: 'absolute',
    bottom: -38,
    right: 28,
    zIndex: 1,
    marginBottom: 10
  },
})

export default withStyles(styles)(FABAbsolute)