import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import WidgetContainer from './WidgetContainer'
import { Organisation } from 'containers'
import * as API from 'api'

export class OrganisationWidget extends Component{

  static propTypes = {

  }

  state = {}

  componentDidMount = async () => {
    const { data: organisation } = await API.Organisations.show({id: 1, options: { include: 'roles,primaryContact,secondaryContact'}})
    this.setState({organisation})
  }

  render = () =>
     <WidgetContainer width='md'>
      <div>
        <Typography variant='h6'>My Organisation</Typography>
        {this.state.organisation && <Organisation.Show organisation={this.state.organisation}/>}
      </div>
    </WidgetContainer>

}

export default OrganisationWidget