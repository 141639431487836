import React, { Component } from 'react'
import qs from "qs";
import { withRouter } from 'react-router-dom'

class QueryStringProvider extends Component {

  serializeUrlState = (state) => {
    const sharedUrlState = qs.parse(window.location.search.slice(1), { allowDots: true })
    const qsObject = {...state.filter} || {}
    Object.keys(qsObject).forEach((key) => {
      if (qsObject[key] === '' || qsObject === undefined || (this.props.filter || {})[key] !== undefined)
        delete qsObject[key]
    })
    if (state.page > 1)
      qsObject.page = state.page
    if (state.order)
      qsObject.order = state.order
    sharedUrlState[this.props.nameSpace] = qsObject
    return qs.stringify(sharedUrlState, {arrayFormat: 'index', allowDots: true})
  }

  updateUrlState = (state, callback) => {
    this.props.history.replace(window.location.pathname + '?' + this.serializeUrlState(state))
    this.forceUpdate(callback)
  }

  get urlState() {
    if (window.location.search) {
      const {page, order, ...filter} = qs.parse(window.location.search.slice(1), { allowDots: true })[this.props.nameSpace] || {}
      return {
        page: Number(page) || 1,
        order: order,
        filter: filter
      }
    }
    return {page: 1, filter: {}, order: null}
  }

  handleOrderChange = (order, callback) => {
    this.updateUrlState({...this.urlState, order, page: 1}, callback)
  }

  handleFilterChange = (filter, callback) => {
    this.updateUrlState({...this.urlState, filter, page: 1}, callback)
  }

  handlePageChange = (page, callback) => {
    this.updateUrlState({...this.urlState, page}, callback)
  }

  render = () => {
    const { type: Type, ...props } = this.props
    const { order, page, filter } = this.urlState
    return (
      <Type
        {...props}
        order={order}
        page={page}
        filter={{...this.props.filter, ...filter}}
        onOrderChange={this.handleOrderChange}
        onFilterChange={this.handleFilterChange}
        onPageChange={this.handlePageChange}
      />
    )
  }
}

const QueryStringComponent = withRouter(QueryStringProvider)
export default nameSpace => Type => props =>
  <QueryStringComponent type={Type} nameSpace={nameSpace} {...props}/>