import React, { Component, Fragment } from 'react'
import { connect }          from 'react-redux'
import { DeviceActions, SiteActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, LabeledSelect, Tagger, AutoSuggest }      from 'components'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, Authorization } from 'utils'
import withStyles from 'styles'
import * as API from 'api'
import InputAdornment from '@material-ui/core/InputAdornment'
import HelpIcon from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    DeviceActions.bindActions(this)
    SiteActions.bindActions(this, 'sites')
    this.relationshipAttributes = ['site']
    this.state = {
      ...this.state,
      deviceSignals: []
    }
  }

  componentDidMount = () => {
    this.handleLoadRequiredSignals()
  }

  componentDidUpdate = (lastProps, lastState) => {
    if(
        (this.formObject.type !== (lastState.formAttributes.type || this.props.device.type)) ||
        (this.formObject.type === "FuturePoint::Device" && (this.formObject.dredsClass !== (lastState.formAttributes.dredsClass || this.props.device.dredsClass)))
    ){
        this.handleLoadRequiredSignals()
    }
  }

  handleLoadRequiredSignals = async () => {
    const { data: deviceSignals } = await API.DeviceSignals.index({
      options: {
        filter: {
          type: this.formObject.type,
          ...this.formObject.type === 'FuturePoint::Device' && {device_class: this.formObject.dredsClass}
        }
      }
    })
    this.setState({deviceSignals})
  }

  static dredsClasses = {
    air_conditioner: "Air Conditioners and Space Heaters",
    strip_heaters: "Strip Heaters/Baseboard Heaters",
    water_heaters: "Water Heaters",
    pool_pumps: "Pool Pumps/Spas",
    smart_appliances: "Smart Appliances",
    irrigation_pumps: "Irrigation Pumps",
    managed_commercial_industrial: "Managed Commercial & Industrial (C&I) loads",
    simple_misc: "Simple misc. (Residential On/Off) loads",
    exterior_lighting: "Exterior Lighting",
    electric_vehicle: "Electric Vehicle",
    generation_systems: "Generation Systems",
  }

  dependsOn(){
    return this.editMode ?
      this.actions.show(this.objectId, {include: 'site'}) :
      this.actions.set()
  }

  get formObject(){
    return {siteId: this.siteId, status: 'active', type: 'Oadr::Device', ...this.props.device, ...this.state.formAttributes}
  }

  get siteId(){
    return this.props.match.params.siteId || (this.props.device.site && this.props.device.site.id)
  }

  get isOpenAdrDevice(){
    return this.formObject.type === 'Oadr::Device'
  }

  get isAmbiDevice() {
    return this.formObject.type === 'Ambi::Device'
  }

  get isDredsDevice(){
    return this.formObject.type === 'FuturePoint::Device'
  }

  onSaveRedirect = ({data: {id} = {}} = {}) => {
    if(this.siteId) {
      return `/sites/${this.siteId}`
    } else {
      return `/devices/${id}`
    }
  }

  get saveEndpointOptions() {
    return {
      include: 'site'
    }
  }

  handleSiteSuggestionsFetchRequested  = async (text, callback) => {
    const { data: suggestions } = await this.actions.sites.index({
      filter: {name: text}
    })
    callback(suggestions)
  }

  renderDeviceSignalSelect = (deviceSignal, i) =>
    <TextField
      type='number'
      InputProps={
        {
          endAdornment: <InputAdornment position="end">kW</InputAdornment>,
        }
      }
      label={`DRM${deviceSignal.value}`}
      key={i}
      member={`signalCapacities.${deviceSignal.value}`}
      errorMember={`signalCapacities`}
      required
      fullWidth
    />

  renderDredsClassMenuItem = ([value, label]) =>
    <MenuItem
      key={String(value)}
      value={value}
      >
      {label}
    </MenuItem>

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Device - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent className={this.props.classes.cardContent}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6' className={this.props.classes.headerFields}>
              Fields
            </Typography>
            <TextField fullWidth member="name" required/>
            {
              !this.siteId &&
              <AutoSuggest
                label='Site'
                fullWidth
                member='site'
                errorMember='site'
                required
                onSuggestionsFetchRequested={this.handleSiteSuggestionsFetchRequested}
                labelProvider={site => site.name}
              />
            }
            <LabeledSelect
              required
              fullWidth
              member='type'
              options={{'Oadr::Device': 'OpenADR', 'FuturePoint::Device': 'DREDs', 'Ambi::Device': 'Ambi Climate'}}
              disabled={this.editMode}
            />
            {
              this.isOpenAdrDevice &&
              <Fragment>
                <TextField fullWidth member='venId' label="VEN ID" placeholder="Leave blank to autogenerate"/>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                <FormControlLabel
                  control={
                    <FormContext onChange={this.handleFormObjectChange} context={this.formObject}>
                      <Checkbox member="useOadrCreateReport" type='checkbox'/>
                    </FormContext>
                  }
                  label="Use oadrCreateReport instead of oadrRegisteredReport"
                />
                  <Tooltip title="Some VENs do not correctly process oadrRegisteredReport/oadr:oadrReportRequest embeded into the VTN's response to oadrRegisterReport. The VEN is expecting a separate oadrCreateReport.">
                  <HelpIcon/>
                  </Tooltip>
                </div>
                <TextField
                  type='number'
                  InputProps={
                    {
                      endAdornment: <InputAdornment position="end">minutes</InputAdornment>,
                    }
                  }
                  label='Event notification interval'
                  member="eventNotificationIntervalMinutes"
                  required
                  fullWidth
                />
              </Fragment>
            }
            {
              this.isAmbiDevice &&
              <Fragment>
                 <TextField fullWidth member='ambiId' label="Ambi ID" />
              </Fragment>
            }
            {
              this.isDredsDevice &&
              <Fragment>
                <TextField fullWidth member="dredsDeviceId" label="DREDs Device ID"/>
                <LabeledSelect member="dredsClass" label="DREDs Class" >
                  {Object.entries(Form.dredsClasses).map(this.renderDredsClassMenuItem)}
                </LabeledSelect>
              </Fragment>
            }
            <LabeledSelect
              required fullWidth
              member='status'
              options={{active: 'Active', inactive: 'Inactive'}}
            />
            {this.state.deviceSignals.map(this.renderDeviceSignalSelect)}
          </div>
          <div className={this.props.classes.tags}>
            {
              Authorization.systemPermissions.writeTags &&
              <Fragment>
                <Typography variant='h6'>
                  Tags
                </Typography>
                <Tagger member='tags'/>
              </Fragment>
            }
          </div>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  headerFields: {
    color: 'white',
    flex: '1 0 100%',
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    padding: 20,
    margin: '0 auto',
    '& > form': {
      width: '100%'
    }
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    minWidth: 300,
    marginBottom:10,
    '& > div': {
      flex: '1 1 auto'
    }
  },
  tags: {
    flex: 1,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({devices}) => devices),
)(Form)
