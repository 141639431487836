import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Reports = ProtectedJsonAPI.extend(
  '/reports',
  {
    show:           endpoint => endpoint.path(({type}) => type),
    downloadReport: endpoint => endpoint.method('post').useForm(true).path(({type}) => `/${type}/download`).paramsHandler(params => ({...params, Authorization: global.localStorage.auth})),
    parentEvents:   endpoint => endpoint.path(() => '/parent_events'),
    missingData:    endpoint => endpoint.path(() => '/missing_meter_data'),
    missingMeterDataForLocation: endpoint => endpoint.path(({meterableType, meterableId}) => `/missing_meter_data/${meterableType}/${meterableId}`),
  }
)