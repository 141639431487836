import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class RegistrationActionSet extends CrudActionSetBase('registration'){

  static event(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({id}, event) => {
      return {
        type: constants.ACTION,
        promise: API.Registrations.event({id, name: event})
      }
    })

    reducer({
      [constants.SUCCESS]: (state) => {
        return { ...state, errors: { ...state.errors, event: null} }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        return { ...state, errors: { ...state.errors, event: error} }
      }
    })
  }

}
export default new RegistrationActionSet()