import React, { Component } from 'react'
import { connect } from 'react-redux'
import { OrganisationRoleActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoleIcon from '@material-ui/icons/AccountBox'
import { compose, errorStringsFromError } from 'utils'
import { withRouter } from 'react-router-dom'
import { FABAbsolute } from 'components'

export class List extends Component{

  constructor(props){
    super(props)
    OrganisationRoleActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadOrganisationRoles()
  }

  loadOrganisationRoles = () => {
    return this.actions.index({
      params: { organisationId: this.organisationId },
      page: this.state.page,
      pageSize: 6,
      fields: {organisation_roles: 'name'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showOrganisationRole = id => () => {
    this.props.history.push(`/organisation_roles/${id}`)
  }

  editOrganisationRole = id => event => {
    this.props.history.push(`/organisation_roles/${id}/edit`)
    event.stopPropagation()
  }

  deleteOrganisationRole = id => event => {
    this.actions.destroy({id})
                .then(this.loadOrganisationRoles)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadOrganisationRoles)

  get organisationId(){
    return this.props.organisationId || this.props.match.params.id
  }

  get organisationRoles(){
    return this.props.organisationRoles
  }

  get organisationPermissions(){
    return this.props.organisationPermissions || {}
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderOrganisationRoleListItem = ({id, name}) =>
    <ListItem button onClick={this.showOrganisationRole(id)} key={id}>
      <ListItemIcon>
        <RoleIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <MuiList dense>
          {this.organisationRoles.map(this.renderOrganisationRoleListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        {
          this.organisationPermissions.writeOrganisation &&
          <FABAbsolute color='primary' onClick={() => this.props.history.push(`/organisations/${this.organisationId}/organisation_roles/new`)}>
            <AddIcon/>
          </FABAbsolute>
        }
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  withRouter,
  connect(({organisationRoles}) => organisationRoles)
)(List)