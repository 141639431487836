import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { humanize } from 'utils'
import Chip from '@material-ui/core/Chip'
import EventStatusColors from 'constants/EventStatusColors'

export class EventStatusChip extends Component{

  static propTypes = {
    status: PropTypes.string,
    className: PropTypes.string
  }

  render = () =>
    <Chip style={EventStatusColors[this.props.status]} label={
      <span>{this.props.status && humanize(this.props.status)}</span>
    } className={this.props.className}/>

}

export default EventStatusChip