import React from 'react'
import withStyles from 'styles'

const styles = theme => ({
  base: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: theme.palette.error.main
  },
  marked: {
    backgroundColor: '#90a3b1'
  }
})

const DoneMarker = ({marked, classes}) =>
  <div className={ classes(
      'base',
      {
        'marked': !!marked
      }
    )}
  />

export default withStyles(styles)(DoneMarker)