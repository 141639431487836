import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class TagTypeActionSet extends CrudActionSetBase('tagType'){
  static bulkSave(creator, reducer, constants){
    constants.defineRequestConstants()
    creator(tagTypes => {
      return {
        type: constants.ACTION,
        promise: API.TagTypes.bulkSave({attributes: tagTypes})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, bulkSave: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data: tagTypes } }) => {
        const requests = state.requests.slice()
        requests.splice(requests.indexOf(request, 1))
        return {...state, requests, tagTypes }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.slice()
        requests.splice(requests.indexOf(request, 1))
        const errors = {...state.errors, bulkSave: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new TagTypeActionSet()