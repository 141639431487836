import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CalendarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { withPermissions } from 'containers/shared'
import { capitalize, compose } from 'utils'
import ZoneSelector from './ZoneSelector'

export class Show extends Component{

  constructor(props){
    super(props)
    CalendarActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id, {include: "scopes"})
  }

  dependenciesMet(){
    return !!this.calendar.id
  }

  get id(){
    return this.props.match.params.id
  }

  get calendar(){
    return this.props.calendar
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Calendar - {this.calendar.name}</Typography>
        <Table>
          <TableBody>
            {this.props.permissions.system.writeCalendars &&
              <TableRow>
                <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
                <TableCell>
                  <Link to={`/calendars/${this.calendar.id}/edit`}>
                    <IconButton>
                      <EditIcon/>
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(this.calendar.name)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Date</TableCell>
              <TableCell>{String(this.calendar.date)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Type</TableCell>
              <TableCell>{capitalize(String(this.calendar.eventType))}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Scope</TableCell>
              <TableCell><ZoneSelector readOnly={true} value={this.calendar.scopes}/></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
}


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(() => ({system: ['readCalendars','writeCalendars']})),
  connect(({calendars}) => calendars),
)(Show)