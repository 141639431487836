import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TokenActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import { FormContext, CenteredCard, ErrorBanner } from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'

export class SignIn extends Component{

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  signIn = async () => {
    try{
      await this.actions.create(this.state)
      const location = (this.props.savedLocation && !this.props.savedLocation.match(/^\/?tokens\//)) ?
        this.props.savedLocation :
        '/'
      this.props.history.push(location)
      this.actions.snackbar.show('Signed in succesfully')
    }catch(err){
      this.actions.snackbar.show('Signed in failed')
    }
  }

   errorFor = key => {
    try{
      return this.props.errors.create.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard>
      <FormContext
        context={this.state} errorContext={{email: this.errorFor('email'), password: this.errorFor('password')}} onChange={state => this.setState(state)} onSubmit={this.signIn}>
        <CardContent>
          <Typography variant='h6'>Sign in</Typography>
          {this.props.errors.create && <ErrorBanner>{this.props.errors.create.title}</ErrorBanner>}
          <FormGroup>
            <TextField member='email'   type='text'/>
            <TextField member='password'type='password'/>
              <FormControlLabel
                control={
                  <FormContext context={this.state} onChange={state => this.setState(state)}>
                  <Checkbox member="rememberMe" value="checkedA"/>
                  </FormContext>
                }
                label="Remember Me"
              />
          </FormGroup>
        </CardContent>
        <CardActions>
          <Button fullWidth variant='contained' color='primary' type='submit'>Sign In</Button>
        </CardActions>
        <Link className='link-small' to='/tokens/forgot'>Forgot password</Link>
      </FormContext>
    </CenteredCard>
}

export default connect(({tokens}) => tokens)(SignIn)