import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import withStyles from 'styles'

export class CenteredCard extends Component{

  static defaultProps = {
    centerVertically: true
  }

  render = () =>
    <section className={this.props.classes('wrapper', {centerVertically: this.props.centerVertically})}>
      <Card className={this.props.classes.card}>
        {this.props.children}
      </Card>
    </section>

}

const styles = ({palette}) => ({
  wrapper: {
    display: 'flex'
  },
  centerVertically: {
    alignItems: 'center',
    minHeight: '100vh',
    flex: 1,
  },
  card: {
    flexDirection: 'column',
    flex: '0 0 auto',
    minWidth: 320,
    textAlign: 'center',
    '& h6': {
      maxWidth: 'calc(100vw - 50px)'
    },
    borderTop: `6px solid ${palette.primary.light}`
  }
})

export default withStyles(styles)(CenteredCard)