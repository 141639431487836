import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const TagTypes = ProtectedJsonAPI.extend(
  '/tag_types',
  {
    index:    endpoint => endpoint,
    create:   endpoint => endpoint.method('post'),
    bulkSave: endpoint => endpoint.method('post').path('/bulk_save'),
    update:   endpoint => endpoint.method('put').path(({id}) => id),
    destroy:  endpoint => endpoint.method('delete').path(({id}) => id),
    show:     endpoint => endpoint.path(({id}) => id)
  }
)