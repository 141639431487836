import React, { Children, Fragment } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import { flatten } from 'utils'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  get flattenedChildren(){
    return flatten(Children.map(this.props.children, child =>
      child.type === React.Fragment ? Children.toArray(child.props.children) : child
    )).map((child, key) => React.cloneElement(child, {key}))
  }

  renderColumnHead = (column)  => {
    const { order, orderBy } = this.props

    if(this.props.onRequestSort) {
      return (
        <Tooltip
          title="Sort"
          placement={column.numeric ? 'bottom-end' : 'bottom-start'}
          enterDelay={300}
        >
          <TableSortLabel
            active={orderBy === column.id}
            direction={order}
            onClick={this.createSortHandler(column.id)}
          >
            {column.children}
          </TableSortLabel>
        </Tooltip>
      )
    }

    return (
      <Fragment>{column.children}</Fragment>
    )
  }

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props

    return (
      <TableHead>
        <TableRow>
          { onSelectAllClick &&
          <TableCell>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={numSelected === rowCount && rowCount > 0}
                    onChange={onSelectAllClick}
                  />
                }
              />
            </FormControl>
          </TableCell>
          }
          {
            this.flattenedChildren.map(({props: column}) =>
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                { this.renderColumnHead(column) }
              </TableCell>
              , this)
          }
        </TableRow>
      </TableHead>
      )
  }
}
