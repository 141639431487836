import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactAutosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import { debounce, uuid } from 'utils'

export class AutoSuggest extends Component {

  static defaultProps = {
    inputProps: {},
    onSuggestionsFetchRequested: () => {},
    labelProvider: item => `${item}`,
    classNames: {},
    showLoader: false,
    value: ''
  }

  constructor(props) {
    super(props)
    this.uniqueId = `mas-${uuid()}`
    this.state = {
      query: '',
      suggestions: [],
      loading: 0
    }
  }

  static getDerivedStateFromProps(newProps, prevState){
    const newQuery = newProps.value ? newProps.labelProvider(newProps.value) : ''
    if(newProps.value !== prevState.currentValue){
      return { query: newQuery, currentValue: newProps.value }
    }

    return null
  }

  handleHighlight = ({suggestion}) => {
    this.setState({highlightedSuggestion: suggestion})
  }

  handleBlur = (event, {highlightedSuggestion}) => {
    const bestSuggestion = highlightedSuggestion || this.state.highlighedSuggestion || this.state.suggestions[0]
    if(bestSuggestion){
      this.setState({suggestions: [], query: this.props.labelProvider(bestSuggestion)})
      this.props.onChange({target: { value: bestSuggestion}})
    }else{
      this.setState({query: ''})
    }
  }

  handleChange = (event, { newValue }) => {
    this.setState({
      query: newValue,
      suggestions: newValue === '' ? [] : this.state.suggestions
    })
    if(!newValue){
      this.finishLoad(0)
      this.props.onChange({target: { value: null}})
    }
  }

  handleSuggestionSelected = (event, {suggestion}) => {
    this.props.onChange({target: { value: suggestion}})
    this.setState({suggestions: []})
    this.finishLoad(0)
  }

  finishLoad = (loadCounter=this.state.loading - 1) =>
    setTimeout(() => this.setState({loading: Math.max(0, loadCounter)}), 100)

  onSuggestionsFetchRequested = debounce(({ value }) => {
    this.props.onSuggestionsFetchRequested(value, (newValues) => {
        this.finishLoad()
        this.setState({
          suggestions: newValues
        })
      },
      this.finishLoad
    )
    this.setState({loading: this.state.loading + 1})
  }, 300)

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []})
  }

  getSectionSuggestions = ({suggestions, className}) =>
    suggestions.map(s => ({...s, className}))

  renderSuggestion = (suggestion, { query, isHighlighted })  => {
    const label = this.props.labelProvider(suggestion)
    const matches = match(label, query)
    const parts   = parse(label, matches)

    return (
      <MenuItem className={this.props.classes('suggestedItem', this.props.classNames.suggestedItem)} selected={isHighlighted}>
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={index} className={this.props.classes.match}>
                {part.text}
              </span>
            ) : (
              <strong key={index}>
                {part.text}
              </strong>
            )
          })}
        </div>
      </MenuItem>
    )
  }

  renderSuggestionsContainer = ({ containerProps, children }) =>
    <div {...containerProps} className={this.props.classes('container', this.props.classNames.suggestionsContainer)}>
      {children}
      {(this.state.loading && this.props.showLoader) ? <LinearProgress /> : false}
    </div>

  renderSectionTitle = ({title}) =>
    <Typography variant='subtitle1'>{title}</Typography>

  renderInput = ({ref, classNames, ...props}) => {
    const autoCompleteSink = {opacity: 0, float: 'left', border: 'none', height: '0', width: '0'}
    return (
      <Fragment>
        <input type="password" name='autoCompleteSink' autoComplete='new-password' tabIndex='-1' style={autoCompleteSink} />
        <TextField
          InputProps={{
            name: this.uniqueId,
            autoComplete: this.uniqueId
          }}
          {...props}
        />
      </Fragment>
    )
  }

  render = () => {
    const {className, classNames, showLoader, classes, labelProvider, onSuggestionsFetchRequested, shouldRenderSuggestions, alwaysRenderSuggestions, onChange, multiSection, fullWidth, ...inputProps } = this.props
    return (
      <ReactAutosuggest
        theme={{container: this.props.classes(className, {autoSuggestFullWidth: fullWidth})}}
        renderInputComponent={this.renderInput}
        inputProps={{
          ...inputProps,
          name: this.uniqueId,
          autoComplete: this.uniqueId,
          value: this.state.query,
          onBlur: this.handleBlur,
          onChange: this.handleChange,
          fullWidth
        }}
        onBlur={this.handleBlur}
        suggestions={this.state.suggestions}
        shouldRenderSuggestions={shouldRenderSuggestions}
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        onSuggestionHighlighted={this.handleHighlight}
        highlightFirstSuggestion={true}
        getSectionSuggestions={this.getSectionSuggestions}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={this.props.labelProvider}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        renderSectionTitle={this.renderSectionTitle}
        multiSection={multiSection}
        containerProps={{className}}
      />
    )
  }
}

const styles = theme => ({
  match: {
    color: theme.palette.secondary.main,
    fontWeight: 700
  },
  container: {
    flex: 1,
    position: 'relative',
    zIndex: 100,
    height: 0,
    maxHeight: 0,
    overflow: 'visible',
    '& ul': {
      listStyle: 'none',
      padding: 0
    }
  },
  suggestedItem: {
    color: "#333",
    backgroundColor: "#dedede",
    fontWeight: '400',
    '&:hover': {
      color: 'white',
      backgroundColor: `${theme.palette.primary.main} !important`,
      '& $match': {
        color: 'yellow',
        fontWeight: 700
      }
    }
  },
  suggestionsContainer: {
    '& ul': {
      padding: 0,
      listStyle: 'none',
      backgroundColor: theme.palette.primary.dark,
    }
  },
  autoSuggestFullWidth: {
    width: '100%'
  },
})

const AutoSuggestWithStyles = withStyles(styles)(AutoSuggest)
AutoSuggestWithStyles.propTypes = {
  error: PropTypes.string,
  helperText: PropTypes.string
}
export default AutoSuggestWithStyles