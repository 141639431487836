import React, { Component } from 'react'
import { DatePicker as DPicker } from '@material-ui/pickers'

export class DatePicker extends Component{

  constructor(props){
    super(props)
    this.state = { key: 0 }
  }

  handleChange = date => {
    this.props.onChange({
      target: {
        value: date ? date.format('YYYY-MM-DDThh:mm:ssZ') : date
      }
    })
    this.setState({key: (this.state.key + 1)})
  }

  get value(){
    return this.props.value ? new Date(this.props.value) : null
  }

  render = () => {
    const format = this.props.monthOnly ? "MM/YYYY" : "DD/MM/YYYY"
    const placeholder = this.props.monthOnly ? "MM/YYYY" : "DD/MM/YYYY"
    const views = this.props.monthOnly ? ["year", "month"] : null
    const mask= this.props.monthOnly ? [/\d/,/\d/, '/', /\d/,/\d/,/\d/,/\d/] : [/\d/,/\d/, '/', /\d/,/\d/, '/', /\d/,/\d/,/\d/,/\d/]

    return(
      <DPicker
        format={format}
        {...this.props}
        onChange={this.handleChange}
        value={this.value}
        key={this.state.key}
        placeholder={placeholder}
        {...views && {views}}
        mask={mask}
      />
    )
  }
}

export default DatePicker
