import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import WidgetContainer from './WidgetContainer'
import { Registration, Site, Substation, Device } from 'containers'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import withStyles from 'styles'
import { compose, Authorization } from 'utils'
import RegistrationIcon from '@material-ui/icons/AssignmentTurnedIn'
import SiteIcon from '@material-ui/icons/LocationOn'
import SubstationIcon from '@material-ui/icons/BrokenImage'
import DeviceIcon from '@material-ui/icons/SettingsRemote'
import { capitalize } from 'utils'
import { connectQueryString } from 'containers/shared'

export class RegistrationsWidget extends Component{

  get permissions(){
    return (this.props.permissions && this.props.permissions.organisation[this.organisationId]) || {}
  }

  canReadTab = tabName => {
    switch(tabName){
    case 'substations': return this.permissions.readSubstations
    case 'sites': return this.permissions.readSites
    case 'devices': return this.permissions.readDevices
    default: return true
    }
  }

  get selectedTab(){
    const tabName = (this.props.filter || {}).tab || 'registrations'
    return this.canReadTab(tabName) ? tabName: undefined
  }

  handleTabChange = (event, tab) => {
    this.props.onFilterChange({tab})
  }

  get organisationId(){
    return this.props.organisationId || Authorization.organisationId
  }

  render = () =>
    <WidgetContainer minHeight={637} width='md'>
      <div className={this.props.classes.content}>
        <div className={this.props.classes.body}>
          <Typography variant='h6'>{capitalize(this.selectedTab)}</Typography>
          {this.selectedTab === 'registrations' && <Registration.List permissions={this.props.permissions} organisationId={this.organisationId}/>}
          {this.selectedTab === 'sites'         && <Site.List         permissions={this.props.permissions} organisationId={this.organisationId}/>}
          {this.selectedTab === 'devices'       && <Device.List       permissions={this.props.permissions} organisationId={this.organisationId}/>}
          {this.selectedTab === 'substations'   && <Substation.List   permissions={this.props.permissions} organisationId={this.organisationId}/>}
        </div>
        <BottomNavigation
          value={this.selectedTab}
          onChange={this.handleTabChange}
          className={this.props.classes.bottomNavigation}
          showLabels
        >
          {this.canReadTab('registrations') && <BottomNavigationAction className={this.props.classes.bottomNavAction} value="registrations" label="Registrations" icon={<RegistrationIcon />} />}
          {this.canReadTab('sites')         && <BottomNavigationAction className={this.props.classes.bottomNavAction} value="sites"         label="Sites" icon={<SiteIcon />} />}
          {this.canReadTab('devices')   &&     <BottomNavigationAction className={this.props.classes.bottomNavAction} value="devices"       label="Devices" icon={<DeviceIcon />} />}
          {this.canReadTab('substations')   && <BottomNavigationAction className={this.props.classes.bottomNavAction} value="substations"   label="Substations" icon={<SubstationIcon />} />}
        </BottomNavigation>
      </div>
    </WidgetContainer>

}

const styles = {
  bottomNavAction: {
    maxWidth: 'none'
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexBasis: 'auto'
  },
  body: {
    flex: 1,
    flexBasis: 'auto'
  },
  bottomNavigation: {
    marginTop: 24,
    padding: '0 20px',
    background: 'transparent'
  }
}
export default compose(
  withStyles(styles),
  connectQueryString('registrationsDash')
)(RegistrationsWidget)