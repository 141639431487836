import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubstationActions, LoadTypeActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { compose, errorStringsFromError, formatLocaleNumeric, Authorization } from 'utils'
import withStyles from 'styles'
import { MultiAutoSuggest } from 'components'
import Chip from '@material-ui/core/Chip'
import { withPermissions } from 'containers/shared'
import DeleteIcon from '@material-ui/icons/Delete'
import { AvailabilitiesSummary } from 'components'

export class Show extends Component{

  static requiredPermissions = ({substation}) => {
    return {
      system: ['readNetworkTopology'],
      organisation: {
        ...substation && substation.programmes && substation.programmes.reduce((agg, {organisation: { id }}) => ({...agg, [id]: ['writeProgrammes']}) ,{}),
        ...substation && substation.organisation && {[substation.organisation.id]: ['writeSubstations','writeProgrammes']},
      }
    }
  }

  constructor(props){
    super(props)
    SubstationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    LoadTypeActions.bindActions(this, 'loadTypes')
  }

  dependsOn(){
    return Promise.all([
      this.actions.loadTypes.index(),
      this.actions.show(this.id, {
        include:
          'meterOwner,distributor,retailer,flowDirection,' +
          'verificationMethod,gxp,organisation,' +
          'programmes.organisation,registrations,availabilities'
      })
    ])
  }

  dependenciesMet(){
    return !!this.substation.id
  }

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this substation? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.id})
      this.props.history.goBack()
      this.actions.snackbar.show('Substation deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete substation.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.match.params.id !== this.id) {
      this.props.onReloadDependent()
    }
  }

  get id(){
    return this.props.match.params.id
  }

  get substation(){
    return this.props.substation
  }

  get organisationId(){
    return (this.substation.organisation || {}).id
  }

  get permissions(){
    return this.organisationPermissions[this.organisationId] || {}
  }

  get organisationPermissions(){
    return ((this.props.permissions || {}).organisation || {})
  }

  get systemPermissions(){
    return ((this.props.permissions || {}).system || {})
  }

  get canWriteSubstation(){
    return this.permissions.writeSubstations && this.substationIsInEditableState
  }

  // We can edit a substation either if we are an admin for all programmes OR
  // the substation is not active or submitted for any programmes yet
  get substationIsInEditableState(){
    return Object.entries(this.organisationPermissions).every(([orgId, permissions]) => permissions.writeProgrammes) ||
          (this.substation.registrations || []).every(({status}) => status !== 'active' && status !== 'submitted')
  }


  renderLoadTypeRow = loadType =>
    !!this.substation.loads[loadType] && (
      <Typography variant='body1' className={this.props.classes.load}>
        <span className={this.props.classes.loadTypeKey}>{loadType}:</span>&emsp;
        {formatLocaleNumeric(this.substation.loads[loadType])}kW
      </Typography>
    )

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <Typography variant='h4'>Substation - {this.substation.name}</Typography>
        <section className={this.props.classes.substationSection}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6'>
              Fields
            </Typography>
            {
              this.canWriteSubstation &&
              <span>
                Actions:
                <Link to={`/substations/${this.substation.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
                <IconButton onClick={this.handleDelete}>
                  <DeleteIcon/>
                </IconButton>
              </span>
            }
            <div className={this.props.classes.tables}>
              <Table className={this.props.classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Name</TableCell>
                    <TableCell>{String(this.substation.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>kW Amount</TableCell>
                    <TableCell>{formatLocaleNumeric(this.substation.kwAmount)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table className={this.props.classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>GXP</TableCell>
                    <TableCell>
                      {
                        this.systemPermissions.readNetworkTopology ?
                          <Link to={`/gxps/${this.substation.gxp.id}`}>
                            {String(this.substation.gxp.code)}
                          </Link> :
                          String(this.substation.gxp.code)
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Organisation</TableCell>
                    <TableCell>{String(this.substation.organisation.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Meter Owner</TableCell>
                    <TableCell>{String(this.substation.meterOwner.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Distributor</TableCell>
                    <TableCell>{String(this.substation.distributor.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Verification method</TableCell>
                    <TableCell>{String(this.substation.verificationMethod.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Status</TableCell>
                    <TableCell>{String(this.substation.status)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Flow direction</TableCell>
                    <TableCell>{String(this.substation.flowDirection)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Programmes</TableCell>
                    <TableCell>{this.substation.programmes.map(({id, name}) => <Chip  key={id} label={name} onClick={() => this.props.history.push(`/programmes/${id}`)} />)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Availabilities</TableCell>
                    <TableCell><AvailabilitiesSummary availabilities={this.props.substation.availabilities}/></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
          {
            Authorization.systemPermissions.readTags &&
            <div className={this.props.classes.tags}>
              <Typography variant='h6'>
                Load Types
              </Typography>
              {this.props.loadTypes.map(this.renderLoadTypeRow)}
              <br/>
              <Typography variant='h6'>
                Tags
              </Typography>
              {
                Object.entries(this.substation.tags).map(([type, values], tIdx) =>
                  <MultiAutoSuggest
                    readOnly
                    showClearAll={false}
                    key={tIdx}
                    label={type}
                    value={values}
                    labelProvider={value => value}
                  />
                )
              }
            </div>
          }
        </section>
      </CardContent>
    </Card>
}


const styles = theme => ({
  load: {
    fontSize: "13px",
    padding: "10px 0",
    borderBottom: "1px solid silver",
    color: "white",
  },
  loadTypeKey:{
    fontWeight: 'bold'
  },
  tables: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    },
    '& td': {
      background: 'rgba(255,255,255,0.03)',
      padding: '10px 15px',
      '&:last-child': {
        borderRight: '1px solid rgba(255,255,255,0.05)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(255,255,255,0.05)'
      },
      borderTop: '1px solid rgba(255,255,255,0.05)',
      borderBottom: '1px solid rgba(255,255,255,0.05)'
    },
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: 'rgba(255,255,255,0.06) !important',
    borderRight: '1px solid rgba(255,255,255,0.02)'
  },
  substationSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: '2 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: "10px 10px 0 0",
    minWidth: 300,
    marginBottom: 10,
    color: 'white'
  },
  tags: {
    flex: '1 1 0%',
    marginBottom: 10,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  }
})

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(Show.requiredPermissions),
  withStyles(styles),
  connect(({substations, loadTypes: { loadTypes }}) => ({...substations, loadTypes: loadTypes.map(({name}) => name)}))
)(Show)