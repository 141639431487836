import CrudActionSetBase from './CrudActionSetBase'
import { LoginFailures as LoginFailuresAPI } from 'api'
import moment from 'moment'

export class LoginFailureActionSet extends CrudActionSetBase('loginFailure'){
  static initialState = {
      duration: {
        beginning: moment().startOf('month').subtract(1, 'months').toDate(),
        ending: moment().endOf('month').toDate()
      }
  }

  static setDuration(creator, reducer, constants) {
    creator(({beginning,ending} = {}) => (dispatch, getState) => {
      const {beginning: oldBeginning, ending: oldEnding} = getState().loginFailures.duration
      dispatch({
        type: constants.ACTION,
        payload: {beginning: beginning || oldBeginning, ending: ending || oldEnding}
      })
    })
    reducer({
      [constants.ACTION]: (state, payload) => ({...state, duration: payload})
    })
  }

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({page: targetPage, fields=null, include=null, duration=null }={}) => (dispatch, getState) => {
      let page = targetPage === undefined ? getState().loginFailures.page : targetPage
      return dispatch({
        type: constants.ACTION,
        promise: LoginFailuresAPI.index({options: {page: { number: page }, ...fields && {fields}, ...include && {include}, ...duration && {duration} }}),
        payload: { page }
      })
    })

    reducer({
      [constants.REQUEST]: (state, { requestPayload: { page }, request }) => {
        return {...state, page, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: { totalPages }} }) => {
        const requests = state.requests.slice()
        requests.splice(requests.indexOf(request, 1))
        return {...state, requests, loginFailures: data, totalPages }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.slice()
        requests.splice(requests.indexOf(request, 1))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new LoginFailureActionSet()
