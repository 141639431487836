import React, { Component, Fragment } from 'react'
import { compose, humanize, userFriendlyDate } from 'utils'
import withStyles from 'styles'
import { withRouter } from 'react-router-dom'
import * as API from 'api'
import { connectQueryString } from 'containers/shared'
import { FormContext, DateTimePicker } from 'components'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import { connect } from 'react-redux'
import { ScatterChart,  Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts'
import Dependent            from 'containers/shared/Dependent'
import TradingPeriod from 'constants/TradingPeriod'

export class ReportIntervals extends Component{

  dependsOn(){
  }

  dependenciesMet(){
    return true
  }

  componentDidMount = async () => {
    this.search()
  }

  state = {intervals: [], reportDescription: {}}

  search = async () => {
    const { data: intervals } = await API.ReportIntervals.index({
      rid: this.rid,
      options: {
        include: 'reportDescription,reportDescription.emixUnit',
        filter: this.props.filter
      }
    })
    this.setState({intervals})
  }

  get filter(){
    return this.props.filter
  }

  get rid(){
    return this.props.match.params.rid
  }

  get reportDescription(){
    return this.state.intervals.length ? this.state.intervals[0].reportDescription : {}
  }

  get emixUnit(){
    return this.reportDescription.emixUnit || {}
  }

  get reportType(){
    return this.reportDescription.reportType
  }

  traverse = (multiplier, unit) => () => {
    const filter = {...this.filter}
    filter.from = moment(filter.from).add(multiplier,unit)
    filter.to = moment(filter.to).add(multiplier,unit)
    this.handleFilterChange(filter)
  }

  handleFilterChange = ({from, to, ...others}) => {
    if(from && typeof from === 'object') from = from.toISOString()
    if(to && typeof to === 'object')     to = to.toISOString()
    const filter = {from: from && from, to: to && to, ...others}
    this.props.onFilterChange(filter, this.search)
  }

  get timeSpan(){
    return moment(this.filter.to).diff(this.filter.from)
  }

  get spansMoreThanWeek(){
    return this.timeSpan >= moment.duration(1, 'week')
  }

  get chartData(){
    return this.state.intervals.sort(({dtstart: a}, {dtstart: b}) => { if(moment(a) > moment(b)) return -1; if (moment(b) > moment(a)) return 1; return 0}).map(({value, online, dtstart}, index) => ({
      x: moment(dtstart).diff(this.filter.from),
      y: value || (online ? 1 : 0)
    }))
  }

  get discrete(){
    return this.reportDescription.reportType === 'x-resourceStatus'
  }

  timeTickFormatter = val =>
    userFriendlyDate(moment(this.filter.from).add(val, 'milliseconds'))

  tooltipFormatter = (value, name) =>{
    if(name === 'Time')
      return this.timeTickFormatter(value)
    if(this.discrete)
      return value === 1 ? 'online' : 'offline'
    return value
  }

  yFormatter = val => {
    if(!this.discrete)
      return val
    switch(val){
    case 0: return 'offline'
    case 1: return 'online'
    default: return ''
    }
  }
  render = () =>
    <div className={this.props.classes.intervalsChart}>
      {
        <Fragment>
          <div className={this.props.classes.filters}>
            <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
              <IconButton onClick={this.traverse(-1, this.spansMoreThanWeek ? 'week' : 'day')}>
                <ChevronLeft/>
              </IconButton>
              <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('meterDataFilter', 'dateFilter')} member='from'/>
              <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('meterDataFilter', 'dateFilter')} member='to'/>
              <IconButton onClick={this.traverse(+1, this.spansMoreThanWeek ? 'week' : 'day')}>
                <ChevronRight/>
              </IconButton>
            </FormContext>
          </div>
          <div className={this.props.classes.charts}>
            <ResponsiveContainer  width='100%' height='100%'>
              <ScatterChart margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <CartesianGrid vertical={false}/>
                <XAxis type="number" dataKey={'x'} name='Time' tickFormatter={this.timeTickFormatter}  labelFormatter={this.timeTickFormatter}/>
                <YAxis tickLine={!this.discrete} tickFormatter={this.yFormatter} domain={[this.discrete ? -0.5 : 'auto', this.discrete ? 1.5 : 'auto']}
                      dataKey={'y'} name={humanize(this.emixUnit.itemDescription || this.reportType)} unit={this.emixUnit.itemUnits}/>
                <Tooltip formatter={this.tooltipFormatter} cursor={{strokeDasharray: '3 3'}}/>
                <Legend/>
                <Scatter name={humanize(this.emixUnit.itemDescription || this.reportType || '...')} data={this.chartData} fill='#8884d8' line shape="circle"/>
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </Fragment>
      }
    </div>
}


const styles = theme => ({
  intervalsChart: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    height: 'calc(100vh - 180px)',
    '@media(max-width: 600px)': {
      height: 'auto'
    }
  },
  table: {
    flex: 1,
    minWidth: '100%',
    border: '1px solid silver',
    background: 'white',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    }
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: theme.palette.primary.main,
    color: "#FAFAFA",
    borderLeft: "1px solid #DEDEDE",
  },
   filters: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px 10px',
    alignItems: 'baseline',
    '& $meterDataFilter': {
      margin: 5
    }
  },
  charts: {
    flex: 1,
    height: 0,
    overflow: 'hidden',
    '@media(max-width: 600px)': {
      height: 600
    }
  },
  dateFilter: {
    paddingTop: 2
  },
  meterDataFilter: {
    flex: '1 0 250px'
  },
})


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withStyles(styles),
  connect((state, {filter}) =>  ({
    filter: {
      from: moment().add(-1, 'week').toISOString(),
      to: moment().toISOString(),
      ...filter
    }
  })),
  connectQueryString('reportIntervals'),
  withRouter
)(ReportIntervals)