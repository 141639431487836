import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const UserNotifications = ProtectedJsonAPI.extend(
  '/user_notifications',
  {
    index:   endpoint => endpoint,
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    show:    endpoint => endpoint.path(({id}) => id),
    bulkSave: endpoint => endpoint.method('post').path('bulk_save')
  }
)