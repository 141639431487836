import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ChildEventActions, SnackbarActions, OfferActions } from 'actionsets'
import { ChildEvents as ChildEventsAPI } from 'api'
import { Pagination, ErrorBanner, EventStatusChip } from 'components'
import { compose, errorStringsFromError, formatLocaleDateTime, formatLocaleNumeric } from 'utils'
import { withRouter } from 'react-router-dom'
import CollapsibleTable, { TableHead, TableBody, TableCell, TableRow, CollapsedContext } from 'components/CollapsibleTable';
import withStyles from 'styles'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { OfferInput } from 'components'
import BCTIIcon from '@material-ui/icons/AttachMoney';
import moment from 'moment'

const inactiveStates = ['cancelled', 'declined', 'withdrawn', 'ineligible']
export class List extends Component{

  constructor(props){
    super(props)
    ChildEventActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    OfferActions.bindActions(this, 'offers')
  }

  state = {
    page: 1
  }

  static defaultProps = {
    order: '-start',
    showPagination: true
  }

  loadChildEvents = () => {
    return this.actions.index({
      page: this.state.page,
      pageSize: this.props.pageSize,
      filter: {...this.props.filter},
      order: this.props.order || List.defaultProps.order,
      fields: {
        childEvents: 'targetKw,mwhPrice,programme,parentEvent,registration,offer,optedIn,status,lastPayment',
        programmes: 'name,type',
        payments: 'performancePercentage,bcti,settlementAmount',
        parentEvents: 'start,end,offerWindowStart,offerWindowEnd',
        registrations: 'name',
        offers: 'offerKw,offerPrice,status',
        bctis: 'invoiceUrlPath',
        autoDREvents: 'estimatedKwAmount,device'
      },
      include: 'programme,parentEvent,registration,offer,lastPayment,lastPayment.bcti,devices,offer.autoDREvents,offer.autoDREvents.deviceSignal,offer.autoDREvents.device'
    })
  }

  componentDidMount(){
    this.loadChildEvents()
  }

  componentDidUpdate(prevProps){
    if(
      prevProps.filter.from !== this.props.filter.from ||
      prevProps.filter.to !== this.props.filter.to ||
      prevProps.filter.status !== this.props.filter.status ||
      prevProps.filter.programme !== this.props.filter.programme ||
      prevProps.filter.organisation !== this.props.filter.organisation ||
      prevProps.filter.registration !== this.props.filter.registration
    ){
      this.loadChildEvents()
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadChildEvents)

  showChildEvent = id => () => {
    this.props.history.push(`/child_events/${id}`)
  }

  get programmeId(){
    return this.props.match.params.id
  }

  get programme(){
    return this.props.programme || {}
  }

  get childEvents(){
    return this.props.childEvents
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    return errors
  }

  eventValue(childEvent){
    const {
      offer,
      optedIn,
      mwhPrice,
      targetKw,
      status,
      parentEvent: {
        start,
        end
      }
    } = childEvent
    const { settlementAmount } = (childEvent.lastPayment || {})
    const eventLength = moment(end).diff(moment(start), 'minutes')/60
    if(inactiveStates.indexOf(status) !== -1)
      return 0
    if(settlementAmount)
      return settlementAmount
    if(offer && optedIn)
      return offer.offerPrice * (offer.offerKw / 1000.0) * eventLength
    return mwhPrice * (targetKw / 1000.0) * eventLength
  }

  static statusesWhichRequireAttention = ['scheduled','active','completed','cancelled']

  childEventRequiresAttention = ({optedIn, status}) => optedIn && List.statusesWhichRequireAttention.indexOf(status) !== -1

  handleShowOfferInput = offeredChildEvent => async event => {
    event.preventDefault()
    event.stopPropagation()
    const {
      data: {
        registration: { targetedDevices },
        offer: { autoDREvents }
      }
    } = await ChildEventsAPI.show({
      id: offeredChildEvent.id,
      options: {
        include: 'registration,registration.targetedDevices,registration.targetedDevices.deviceSignals,offer,offer.autoDREvents,offer.autoDREvents.deviceSignal,offer.autoDREvents.device',
        fields: {
          childEvents: 'registration,offer',
          registrations: 'targetedDevices',
          offers: 'autoDREvents',
          autoDREvents: 'estimatedKwAmount,device'
        }
      }
    })
    this.setState({offeredChildEvent: {
      ...offeredChildEvent,
      registration: {
        ...offeredChildEvent.registration,
        targetedDevices
      },
      offer: {
        ...offeredChildEvent.offer,
        autoDREvents
      }
    }})
  }

  handleHideOfferInput = () =>
    this.setState({offeredChildEvent: undefined})

  handleUpdateOffer = async ({target: { value: offer }}) => {
    try{
      await this.actions.offers.update(offer, { include: 'autoDREvents,autoDREvents.device,autoDREvents.deviceSignal' })
      this.actions.snackbar.show("Offer updated")
      this.setState({offeredChildEvent: undefined})
    }catch(err){
      this.actions.snackbar.show("Failed to update offer: "+err.message)
      throw(err)
    }
    finally{
      this.loadChildEvents()
    }
  }

  handleDownloadFile = url => event => {
    event.preventDefault()
    event.stopPropagation()
    window.open(url)
  }


  renderChildEventListItem = childEvent => {
    const {
      id, targetKw, mwhPrice, optedIn, offer, status,
      registration,
      parentEvent: {
        start, end
      },
      programme
    } = childEvent
    const { name: registrationName } = registration
    const { name: programmeName, type } = programme

    const { performancePercentage, bcti } = (childEvent.lastPayment || {})
    const requiresAttention = this.childEventRequiresAttention(childEvent)
    let offerStatusIcon = <ThumbDownIcon color='white'/>

    if(status === 'withdrawn' || status === 'ineligible') {
      offerStatusIcon = <CloseIcon color='error'/>
    } else {
      switch(offer && offer.status) {
        case 'opted_in':
          offerStatusIcon = <ThumbUpIcon color='secondary'/>
          break
        case 'opted_out':
          offerStatusIcon = <ThumbDownIcon color='error'/>
          break
        default:
          offerStatusIcon = <ThumbsUpDownIcon/>
      }
    }

    return (
      <TableRow className={this.props.classes('pointer', { requiresAttention  })} onClick={this.showChildEvent(id)} key={id}>
        <CollapsedContext.Consumer>
          {
            ({collapsed}) => collapsed ?
              <Fragment>
                <TableCell mobileHeading><div className={this.props.classes.thumbsIcon}></div>{ `Event for ${registrationName} in ${programmeName}`}</TableCell>
              </Fragment> :
              <Fragment>
                <TableCell>{registrationName}</TableCell>
                <TableCell>{programmeName}</TableCell>
              </Fragment>
          }
        </CollapsedContext.Consumer>
        <TableCell mobileLabel='Event Start'>{formatLocaleDateTime(start)}</TableCell>
        <TableCell mobileLabel='Event End'>{formatLocaleDateTime(end)}</TableCell>
        <TableCell mobileLabel='Event Value'>
          {
            (inactiveStates.indexOf(status) !== -1) ?
              '--' :
              `$${formatLocaleNumeric(this.eventValue(childEvent))}`
          }
        </TableCell>
        <TableCell mobileLabel='Performance(%)'>
          {performancePercentage ? `${performancePercentage.toFixed(2)}%` : '--'}
        </TableCell>
        <TableCell mobileHeading><EventStatusChip status={status}/></TableCell>
        <TableCell>
          {
            type === 'PriceResponsiveProgramme' &&
            <IconButton onClick={this.handleShowOfferInput({id, targetKw, mwhPrice, optedIn, status, parentEvent: { start, end  }, offer, registration, programme})}>
              {offerStatusIcon}
            </IconButton>
          }
          {
            (bcti || {}).invoiceUrlPath &&
            <IconButton onClick={this.handleDownloadFile((bcti || {}).invoiceUrlPath)}>
              <BCTIIcon/>
            </IconButton>
          }
        </TableCell>
      </TableRow>
    )
  }

  renderOfferInputDialog = () => {
    if(!this.state.offeredChildEvent) {
      return false
    }

    const { status } = this.state.offeredChildEvent
    const withdrawn = !!(status === 'withdrawn')
    const ineligible = !!(status === 'ineligible')

    return (
      <Dialog open={true} onClose={this.handleHideOfferInput}>
        <DialogTitle>Offer</DialogTitle>
        <DialogContent className={this.props.classes.dialogPaper}>
          <OfferInput
            onChange={this.handleUpdateOffer}
            offer={this.state.offeredChildEvent.offer}
            childEvent={this.state.offeredChildEvent}
            eventStart={this.state.offeredChildEvent.parentEvent.start}
            eventEnd={this.state.offeredChildEvent.parentEvent.end}
            locked={this.state.offeredChildEvent.status !== 'offer_window_open' || withdrawn || ineligible}
          />
        </DialogContent>
      </Dialog>
    )
  }
  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <div>
      {this.renderErrorMessages()}
      <CollapsibleTable breakpoint={775} className={this.props.classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Registration</TableCell>
            <TableCell>Programme</TableCell>
            <TableCell>Event Start</TableCell>
            <TableCell>Event End</TableCell>
            <TableCell>Event Value</TableCell>
            <TableCell>Performance</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.childEvents.map(this.renderChildEventListItem)}
        </TableBody>
      </CollapsibleTable>
      {this.renderOfferInputDialog()}
      {
        this.props.showPagination &&
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      }
    </div>
}

const styles = {
  dialogPaper: {
    padding: '0 5px 5px'
  },
  requiresAttention: {
  },
  thumbsIcon: {
    position: 'relative',
    display: 'inline-block',
    top: 10,
    left: 10
  },
  pointer: {
    cursor: 'pointer',
    '&:hover':{
      background: 'rgba(0, 0, 0, 0.1)'
    },
    '&$requiresAttention': {
      background: 'rgba(255, 170, 0, 0.2)',
      '&:hover':{
        background: 'rgba(255, 199, 86, 0.2)'
      },
    }
  }
}
export default compose(
  withRouter,
  withStyles(styles),
  connect(({childEvents, programmes: { programme }}) => ({...childEvents, programme }))
)(List)
