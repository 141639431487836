import React, { Component } from 'react'
import WidgetContainer from './WidgetContainer'
import { compose } from 'utils'
import { connect } from 'react-redux'
import withStyles from 'styles'

export class Em6Widget extends Component{

  render = () => {
    return (
      <WidgetContainer className={this.props.classes.widgetContainer} width='md'>
        <iframe title="em6" src="https://www.em6live.co.nz/NZRegionalPricesWidget.aspx" width="372" height="526"/>
      </WidgetContainer>
    )
  }
}

const styles = {
  widgetContainer: {
    textAlign: 'center',
    minHeight: 540,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    '& > iframe': {
      width: 372,
      minHeight: 526,
      margin: 0,
      border: 'none',
      '& div#overviewPrice': {
        background: 'transparent'
      }
    }
  }
}
export default compose(
  connect(),
  withStyles(styles)
)(Em6Widget)