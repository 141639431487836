import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ParentEventActions, SnackbarActions, ProgrammeActions } from 'actionsets'
import { Pagination, ErrorBanner, EventStatusChip, FABAbsolute } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import { compose, errorStringsFromError, formatLocaleDateTime, formatLocaleNumeric } from 'utils'
import { withRouter } from 'react-router-dom'
import CollapsibleTable, { TableHead, TableBody, TableCell, TableRow, CollapsedContext } from 'components/CollapsibleTable';
import withStyles from 'styles'
import { Link } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export class List extends Component{

  constructor(props){
    super(props)
    ProgrammeActions.bindActions(this, 'programmes')
    ParentEventActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  static defaultProps = {
    order: "-start",
    showPagination: true
  }

  dependsOn(){
    return [
      this.loadParentEvents(),
      this.loadProgrammes()
    ]
  }

  loadProgrammes = () => {
    this.actions.programmes.index({
      fields: { programme: 'name'},
      filter: { withCreateEventPermissions: true },
      pageSize: 50
    })
  }

  loadParentEvents = () => {
    return this.actions.index({
      params: {
        ...this.programmeId && {programmeId: this.programmeId }
      },
      filter: {...this.props.filter},
      order: this.props.order || List.defaultProps.order,
      page: this.state.page,
      pageSize: this.props.pageSize,
      fields: { programme: 'name,active'},
      include: 'programme'
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps.filter !== this.props.filter){
      this.loadParentEvents()
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadParentEvents)

  handleNewEvent = ({currentTarget}) => {
    if(this.programmeId){
      this.actions.showNew(this.programmeId)
    }else{
      this.setState({programmeMenuAnchor: currentTarget})
    }
  }

  handleCloseProgrammeMenu = () => {
    this.setState({programmeMenuAnchor: null})
  }

  showParentEvent = id => () => {
    this.actions.showEdit(id)
  }

  deleteParentEvent = id => event => {
    this.actions.destroy({id})
                .then(this.loadParentEvents)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get programmeId(){
    return this.props.match.params.id
  }

  get programme(){
    return this.props.programme || {}
  }

  get parentEvents(){
    return this.props.parentEvents
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  get isPriceResponsive(){
    return this.programmeId && this.programme.type === 'PriceResponsiveProgramme'
  }

  get isNonPriceResponsive(){
    return this.programme.type === 'NonPriceResponsiveProgramme'
  }

  renderParentEventListItem = ({id, start, end, targetKw, status, mwhPrice, offerWindowStart, offerWindowEnd, minimumLeadTime, scheduledKw, programme: {id: programmeId, name: programmeName}}) =>
    <TableRow className={this.props.classes.pointer} onClick={this.showParentEvent(id)} key={id}>
      <CollapsedContext.Consumer>
        {
          ({collapsed}) => collapsed ?
            <Fragment>
              <TableCell mobileHeading>{ `Event for  ${programmeName}`}</TableCell>
            </Fragment> :
            <Fragment>
              <TableCell><Link to={`/programmes/${programmeId}`}>{programmeName}</Link></TableCell>
            </Fragment>
        }
      </CollapsedContext.Consumer>
      <TableCell mobileLabel='Event Start'>{formatLocaleDateTime(start)}</TableCell>
      <TableCell mobileLabel='Event End'>{formatLocaleDateTime(end)}</TableCell>
      <TableCell mobileLabel='Target'>{formatLocaleNumeric(targetKw/1000.0)}MW</TableCell>
      <TableCell mobileLabel='Scheduled'>{formatLocaleNumeric(scheduledKw/1000.0)}MW</TableCell>
      <TableCell mobileHeading><EventStatusChip status={status}/></TableCell>
    </TableRow>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSelectProgrammeMenu = () =>
    <Menu
      id="select-programme-menu"
      anchorEl={this.state.programmeMenuAnchor}
      open={Boolean(this.state.programmeMenuAnchor)}
      onClose={this.handleCloseProgrammeMenu}
    >
      {
        this.props.programmes.map(({id, name}) =>
          <MenuItem key={id} onClick={() => this.actions.showNew(id)}>{name}</MenuItem>
        )
      }
    </Menu>

  render = () =>
      <div>
        {this.renderErrorMessages()}
        <CollapsibleTable breakpoint={600} className={this.props.classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Programme</TableCell>
              <TableCell>Event Start</TableCell>
              <TableCell>Event End</TableCell>
              <TableCell>Target MW</TableCell>
              <TableCell>Scheduled MW</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.parentEvents.map(this.renderParentEventListItem)}
          </TableBody>
        </CollapsibleTable>
        {
          this.props.showPagination &&
          <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        }
        {
          (this.programme.active || !this.programme.id) &&
          <FABAbsolute color='primary' onClick={this.handleNewEvent}>
            <AddIcon/>
          </FABAbsolute>
        }
        {this.renderSelectProgrammeMenu()}
      </div>
}

const styles = {
  pointer: {
    cursor: 'pointer',
    '&:hover':{
      background: 'rgba(0, 0, 0, 0.1)'
    }
  },
}
export default compose(
  Dependent({loader: true}),
  withRouter,
  withStyles(styles),
  connect(({parentEvents, programmes: { programmes }}) => ({...parentEvents, programmes }))
)(List)