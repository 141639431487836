import moment from 'moment'

const SECONDS_IN_PERIOD = 30 * 60
const PERIODS_IN_DAY    = 48
const WEEKLY_PERIODS    = 7 * PERIODS_IN_DAY

export class Availability {
  constructor(weeklyBitmask) {
    if(weeklyBitmask.length !== WEEKLY_PERIODS) {
      throw new Error( `Bitmask has to be of size ${WEEKLY_PERIODS}`)
    }
    this.weeklyBitmask = weeklyBitmask
  }

  covers = (availability) => {
    return !this.weeklyBitmask.some((bit, index) => (!bit && availability.weeklyBitmask[index]))
  }
}

Availability.fromEvent = function(event) {
  const bitmask = Array(WEEKLY_PERIODS).fill(0)

  const { start, end } = event
  const startDate = moment(start)
  const endDate = moment(end)

  const dayIndex = startDate.day()
  const fromOffset = startDate.diff(moment(startDate).startOf('day'), 'seconds') / SECONDS_IN_PERIOD
  const toOffset = moment(endDate).diff(moment(endDate).startOf('day'), 'seconds') / SECONDS_IN_PERIOD

  if(fromOffset <= toOffset) {
    let spliceMask = Array(toOffset - fromOffset).fill(1)
    bitmask.splice((dayIndex * PERIODS_IN_DAY) + fromOffset, (toOffset - fromOffset),  ...spliceMask)
  }

  return new Availability(bitmask)
}

Availability.fromString = function(aStringBitmask) {
  const bitmask = aStringBitmask.split('').map((bit) => 1 * bit)
  return new Availability(bitmask)
}