import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'utils'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import withStyles from 'styles'
import Uploads from './Uploads'
import Readings from './Readings'
import BaselineTester from './BaselineTester'
import { withPermissions } from 'containers/shared'

export class Dashboard extends Component {

  static requiredPermissions = ({organisationId}) => ({
    system: ['readNetworkTopology'],
    organisation: {
      ...organisationId && {[organisationId] : ['readSubstations', 'readVerificationMethods']}
    }
  })

  get selectedTab(){
    return this.props.match.params.tab || 'sites'
  }

  get systemPermissions(){
    return this.props.permissions.system || {}
  }

  get organisationPermissions(){
    return ((this.props.permissions || {}).organisation || {})[this.organisationId] || {}
  }

  get organisationId(){
    return this.props.organisationId
  }

  handleTabChange = (event, tab) => {
    this.props.history.push(`/meter_data/${tab}`)
  }

  renderSiteReadingsTab = () => {
    return <Readings readingType='sites'/>
  }

  renderSubstationReadingsTab = () => {
    return <Readings readingType='substations'/>
  }

  renderGxpReadingsTab = () => {
    return <Readings readingType='gxps'/>
  }

  renderBaselineTesterTab = () => {
    return <BaselineTester/>
  }

  renderUploadsTab = () => {
    return <Uploads/>
  }

  render = () => {
    return (
      <Card className={this.props.classes.card}>
        <CardContent className={this.props.classes.cardContent}>
          <Typography variant='h4'>Meter data</Typography>
          <Tabs value={this.selectedTab} onChange={this.handleTabChange}>
            <Tab value='sites' label='Sites'/>
            {this.organisationPermissions.readSubstations && <Tab value='substations' label='Substations'/>}
            {this.systemPermissions.readNetworkTopology && <Tab value='gxps' label='GXPs'/>}
            {this.organisationPermissions.readVerificationMethods && <Tab value='baseline_tester' label='Baseline Tester'/>}
            <Tab value='uploads' label='Uploads'/>
          </Tabs>
          {this.selectedTab === 'sites' && this.renderSiteReadingsTab()}
          {this.organisationPermissions.readSubstations && this.selectedTab === 'substations' && this.renderSubstationReadingsTab()}
          {this.organisationPermissions.readVerificationMethods && this.selectedTab === 'baseline_tester' && this.renderBaselineTesterTab()}
          {this.systemPermissions.readNetworkTopology && this.selectedTab === 'gxps' && this.renderGxpReadingsTab()}
          {this.selectedTab === 'uploads' && this.renderUploadsTab()}
        </CardContent>
      </Card>
    )
  }
}

const styles = theme => ({
  card: {
    width: 'calc(100% - 10px)'
  }
})

export default compose(
  withPermissions(Dashboard.requiredPermissions),
  withStyles(styles),
  connect(({tokens: {currentUser: {organisation: {id: organisationId}}}}) => {return {organisationId}}),
)(Dashboard)