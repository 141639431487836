import { APIResource } from 'services'

export const OpenADRCertTest = new APIResource(
  '',
  {
    prompt: endpoint => endpoint.path('/prompt').method('post'),
    ven: endpoint => endpoint.path(id => id ? `/ven/${id}` : '/ven' ),
    zapVen: endpoint => endpoint.path(({ven_id}) => ven_id ? `/zapVen/${ven_id}` : 'zapVen' ).method('post'),
    provisionVen: endpoint => endpoint.path(({ven_id}) => ven_id ? `/provisionVen/${ven_id}` : 'provisionVen' ).method('post'),
    register20aVen: endpoint => endpoint.path('/register20aVen').method('post')
  },
  {
    apiBase: '/OpenADR2/Simple/2.0b'
  }
)