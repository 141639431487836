import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ChildEventActions, OfferActions, SnackbarActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { compose, userFriendlyDate, humanize, compact } from 'utils'
import withStyles from 'styles'
import moment from 'moment'
import { OfferInput, EventStatusChip, EventCblChart } from 'components'
import { WidgetContainer } from 'containers/landing/widgets'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StartIcon from '@material-ui/icons/DateRange'
import EndIcon from '@material-ui/icons/Today'
import ProgrammeIcon from '@material-ui/icons/List'
import ProgrammeNameIcon from '@material-ui/icons/DragHandle'
import RegistrationIcon from '@material-ui/icons/VerifiedUser'
import SiteIcon from '@material-ui/icons/LocationOn'
import SettlementWidget from './SettlementWidget'
import { MeterDataImportResultDialog } from 'containers/meter_data'
import * as API from 'api'
import PerformanceIcon from '@material-ui/icons/AvTimer'

export class Show extends Component{

  constructor(props){
    super(props)
    ChildEventActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    OfferActions.bindActions(this, 'offers')

    this.state = {
      importResultDialog: {open: false}
    }
  }

  dependsOn(){
    return this.loadChildEvent()
  }

  dependenciesMet(){
    return !!this.childEvent.id
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.match.params.id !== this.id) {
      this.loadChildEvent()
      this.loadCBLResults()
    }
  }

  componentDidMount = () => {
    this.loadCBLResults()
  }

  loadCBLResults = async () => {
    let cblOk = false
    try{
      if(['meter_data_submitted','payment_pending','payment_complete','withdrawn'].includes(this.childEvent.status)){
        const { data: results } = await API.ChildEvents.showCBL({id: this.id})
        cblOk = results.ok
        if(cblOk){
          this.setState({
            eventDayStart: moment(this.eventStart).startOf('day').toDate(),
            highlightRange: results.tradingPeriods.reduce(({min, max}, {period}) => ({
                min: Math.min(...compact([min, period])),
                max: Math.max(...compact([max, period]))
              }), {}
            ),
            baseline: results.baselineComplete,
            actual: results.measurementComplete
          })
        }
      }
    }
    finally{
      if(!cblOk){
        this.setState({baseline: undefined, actual: undefined, highlightRange: undefined, eventDayStart: undefined})
      }
    }
  }

  loadChildEvent = async () => {
    await this.actions.show(this.id, {
      include: 'parentEvent,parentEvent.organisation,programme,registration.sites.gxp,registration.organisation,offer,registration.targetedDevices,registration.targetedDevices.deviceSignals,offer.autoDREvents,offer.autoDREvents.deviceSignal,offer.autoDREvents.device',
      fields: {
        childEvents: 'targetKw,mwhPrice,status,programme,parentEvent,registration,offer,optedIn,offerKw,offerPrice',
        programmes: 'name,type',
        parentEvents: 'start,end,offerWindowStart,offerWindowEnd,status,organisation',
        registrations: 'name,sites,organisation,minimumLeadTime,indicativePrice,fixedPrice,availabilityFee,prepurchasedHours,targetedDevices',
        offers: 'offerKw,nonAutoDRKw,offerPrice,status,drLevel,autoDREvents',
        organisations: 'name',
        gxps: 'name',
        autoDREvents: 'estimatedKwAmount,device'
      }
    })
  }

  get id(){
    return this.props.match.params.id
  }

  get eventStart(){
    return this.parentEvent.start
  }

  get eventEnd(){
    return this.parentEvent.end
  }

  get offerWindowStart(){
    return this.parentEvent.offerWindowStart
  }

  get offerWindowEnd(){
    return this.parentEvent.offerWindowEnd
  }

  get childEvent(){
    return this.props.childEvent
  }

  get parentEvent(){
    return this.props.childEvent.parentEvent
  }

  get programme(){
    return this.props.childEvent.programme
  }

  get offer(){
    return this.props.childEvent.offer
  }

  get registration(){
    return this.props.childEvent.registration
  }

  get isPriceResponsive(){
    return this.programme.type === 'PriceResponsiveProgramme'
  }

  get isCompleted(){
    return this.parentEvent.status === 'completed'
  }

  get shouldShowSettlement(){
    return this.isCompleted
  }

  handleUpdateOffer = async ({target: { value: offer }}) => {
    try{
      await this.actions.offers.update(offer, { include: 'autoDREvents,autoDREvents.device,autoDREvents.deviceSignal' })
      this.actions.snackbar.show("Offer updated")
    }catch(err){
      this.actions.snackbar.show("Failed to update offer "+err.message)
      throw(err)
    }finally{
      await this.loadChildEvent()
    }
  }

  handleWithdrawEvent = async () => {
    const { id } = this.childEvent
    try{
      await this.actions.event({id}, 'withdraw')
      await this.loadChildEvent()
    }catch(err){
      this.actions.snackbar.show(`Failed to withdraw event.`)
      throw(err)
    }
  }

  handleMeterDataUploaded = async (importResults) => {
    this.setState({
      importResultDialog: {
        open: true, onClose: () => this.loadChildEvent(),
        importResults
      }
    })
  }

  handleMeterDataUploadFailed = async (err) => {
    this.setState({
      importResultDialog: {
        open: true, onClose: null,
        err
      }
    })
  }

  handlePaymentsUpdated = async () => {
    this.setState({paymentsUpdated: true})
    await this.loadChildEvent()
    this.setState({paymentsUpdated: false})
  }

  handleCloseResultDialog = () => {
    const { onClose } = this.state.importResultDialog
    this.setState({importResultDialog: {open: false}})
    if(onClose) {
      onClose()
    }
  }

  renderImportResultDialog = () => {
    const { importResults, err } = this.state.importResultDialog
    const { open } = this.state.importResultDialog
    return <MeterDataImportResultDialog onClose={this.handleCloseResultDialog} open={!!open}
          importResults={ importResults } errors={ err } />
  }

  renderOffer = () => {
    const { status } = this.childEvent
    const withdrawn = !!(status === 'withdrawn')
    const ineligible = !!(status === 'ineligible')

    return (
      <WidgetContainer className={this.props.classes('widgetContainer', 'offerInputWidget')}>
        <Typography variant='h6'>Offer</Typography>
        <OfferInput
          onChange={this.handleUpdateOffer}
          eventStart={this.parentEvent.start}
          eventEnd={this.parentEvent.end}
          childEvent={this.childEvent}
          offer={this.offer}
          locked={ this.parentEvent.status !== 'offer_window_open' || withdrawn|| ineligible}
        />
      </WidgetContainer>
    )
  }

  renderParentEvent = () =>
    <WidgetContainer className={this.props.classes.widgetContainer} minHeight={280} >
      <List className={this.props.classes.detailsList}>
        <ListItem>
          <ListItemText primary={<Typography variant='h6'>Event</Typography>}/>
          <ListItemText primary={<EventStatusChip status={this.parentEvent.status}/>} />
        </ListItem>
        <ListItem>
          <div className={this.props.classes.iconTextPair}>
            <ListItemIcon>
              <StartIcon/>
            </ListItemIcon>
            <ListItemText primary={userFriendlyDate(this.parentEvent.start)} secondary="Event Start" />
          </div>
          <div className={this.props.classes.iconTextPair}>
            <ListItemIcon>
              <EndIcon/>
            </ListItemIcon>
            <ListItemText primary={userFriendlyDate(this.parentEvent.end)}   secondary="Event End" />
          </div>
        </ListItem>
        {
          this.isPriceResponsive &&
          <ListItem>
            <div className={this.props.classes.iconTextPair}>
              <ListItemIcon>
                <StartIcon/>
              </ListItemIcon>
              <ListItemText primary={userFriendlyDate(this.parentEvent.offerWindowStart)} secondary="Offer Window Start" />
            </div>
            <div className={this.props.classes.iconTextPair}>
              <ListItemIcon>
                <EndIcon/>
              </ListItemIcon>
              <ListItemText primary={userFriendlyDate(this.parentEvent.offerWindowEnd)}   secondary="Offer Window End" />
            </div>
          </ListItem>
        }
      </List>
    </WidgetContainer>

  renderProgramme = () =>
    <WidgetContainer className={this.props.classes.widgetContainer} minHeight={0}>
      <Typography variant='h6'>Programme</Typography>
      <List className={this.props.classes.detailsList}>
        <ListItem>
          <div className={this.props.classes.iconTextPair}>
            <ListItemIcon>
              <ProgrammeIcon/>
            </ListItemIcon>
            <ListItemText primary={this.programme.name} secondary="Name" />
          </div>
          <div className={this.props.classes.iconTextPair}>
            <ListItemIcon>
              <ProgrammeNameIcon/>
            </ListItemIcon>
            <ListItemText primary={humanize(this.programme.type)}   secondary="Type" />
          </div>
        </ListItem>
      </List>
    </WidgetContainer>

  renderSiteItem = ({id, name, gxp: { name: gxpName }}, idx) =>
    <ListItem button onClick={() => this.props.history.push(`/sites/${id}`)} key={idx}>
      <ListItemIcon>
        <SiteIcon/>
      </ListItemIcon>
      <ListItemText primary={`${name}(${gxpName})`}/>
    </ListItem>

  renderCblChart = () => (this.state.baseline && this.state.baseline.length) ?
    <WidgetContainer className={this.props.classes.widgetContainer}>
      <Typography variant='h6'><PerformanceIcon/>&emsp;Performance</Typography>
      <EventCblChart startTime={this.state.eventDayStart} highlightRange={this.state.highlightRange} baseline={this.state.baseline} actual={this.state.actual}/>
    </WidgetContainer>:
    false


  renderRegistration = () =>
    <WidgetContainer className={this.props.classes.widgetContainer}>
      <Typography variant='h6'><RegistrationIcon style={{margin: '0 10px 3px 0'}}/>Registration</Typography>
      <List className={this.props.classes.detailsList}>
        <ListItem button onClick={() => this.props.history.push(`/registrations/${this.registration.id}`)}>
          <ListItemText primary={this.registration.name} secondary="Name"/>
        </ListItem>
        <ListItem button onClick={() => this.props.history.push(`/organisations/${this.registration.organisation.id}`)}>
          <ListItemText primary={this.registration.organisation.name} secondary="Organisation"/>
        </ListItem>
        { this.isPriceResponsive ? this.renderPriceResponsiveFields() : this.renderNonPriceResponsiveFields() }
        <ListItem>
          <List className={this.props.classes.sublist}>
            <ListItemText primary='Sites'/>
            {this.registration.sites.map(this.renderSiteItem)}
          </List>
        </ListItem>
      </List>
    </WidgetContainer>

  renderPriceResponsiveFields = () => false

  renderNonPriceResponsiveFields = () =>
    <Fragment>
      <ListItem>
        <ListItemText primary={`$${String(this.registration.fixedPrice)}/MWh`} secondary="Fixed Price"/>
      </ListItem>
      {this.registration.availabilityFee &&
      <ListItem>
        <ListItemText primary={`$${String(this.registration.availabilityFee)}`} secondary="Availability Fee"/>
      </ListItem>
      }
      {this.registration.prepurchasedHours &&
      <ListItem>
        <ListItemText primary={`${String(this.registration.prepurchasedHours)}`} secondary="Prepurchased Hours"/>
      </ListItem>
      }
    </Fragment>

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <Typography variant='h4'>
          Event for {this.registration.name}
          &emsp;<EventStatusChip status={this.childEvent.status} className={this.props.classes.status}/>
        </Typography>
        <Typography variant='body1'>
         {this.programme.name}
        </Typography>
        <Typography variant='body1'>
          {moment(this.eventStart).format('MMM Do YY, h:mm:ss a')}
          &emsp;—&emsp;
          {moment(this.eventEnd).format('MMM Do YY, h:mm:ss a')}
        </Typography>
        <section className={this.props.classes.childEventSection}>
          <div className={this.props.classes.columnSubSection}>
            {this.renderCblChart()}
            {this.renderRegistration()}
          </div>
          {
            (this.shouldShowSettlement || this.isPriceResponsive) &&
            <div className={this.props.classes.columnSubSection}>
              {
                this.shouldShowSettlement &&
                <SettlementWidget
                  key='settlement'
                  childEvent={this.childEvent}
                  onWithdraw={this.handleWithdrawEvent}
                  onMeterDataUploaded={this.handleMeterDataUploaded}
                  onMeterDataUploadFailed={this.handleMeterDataUploadFailed}
                  onPaymentsUpdated={this.handlePaymentsUpdated}
                />
              }
              {this.isPriceResponsive && this.renderOffer()}
            </div>
          }
        </section>
        { this.renderImportResultDialog() }
      </CardContent>
    </Card>
}


const styles = theme => ({
  widgetContainer: {
    flex: '1 1 auto'
  },
  offerInputWidget: {
    padding: '15px 5px',
    overflow: 'hidden'
  },
  columnSubSection: {
    flex: '2',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    minWidth: 310
  },
  iconTextPair: {
    flex: '1 1 50%',
    minWidth: 200,
    display: 'flex'
  },
  detailsList: {
    '& li': {
      flexWrap: 'wrap'
    }
  },
  childEventSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    flexWrap: 'wrap-reverse',
    flexDirection: 'row-reverse',
    flex: '1'
  },
})

export default compose(
  Dependent({loader: true, clearOnLoad: false}),
  connect(({childEvents}) => childEvents),
  withStyles(styles)
)(Show)