import React, { Component, Fragment }      from 'react'
import Button                              from '@material-ui/core/Button'
import IconButton                          from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dependent                           from 'containers/shared/Dependent'
import InstanceFormMixin                   from 'containers/shared/InstanceFormMixin'
import TextField                           from '@material-ui/core/TextField'
import Typography                          from '@material-ui/core/Typography'
import InputAdornment                      from '@material-ui/core/InputAdornment'
import FormControl                         from '@material-ui/core/FormControl'
import Table                               from '@material-ui/core/Table'
import TableRow                            from '@material-ui/core/TableRow'
import TableCell                           from '@material-ui/core/TableCell'
import AddIcon                             from '@material-ui/icons/Add'
import RemoveIcon                          from '@material-ui/icons/Remove'
import withStyles                          from 'styles'
import { compose, Authorization,
         roundDateOrMoment }               from 'utils'
import { connect }                         from 'react-redux'
import { DateTimePicker, FormContext, Tagger,
         LabeledSelect, AutoSuggest }      from 'components'
import moment from 'moment'
import { SiteActions, GxpActions,
         VerificationMethodActions,
         LoadTypeActions }                 from 'actionsets'
import * as API from 'api'
import { Availabilities } from 'components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    this.relationshipAttributes = ['gxp', 'retailer', 'distributor', 'meterOwner', 'verificationMethod', 'availabilities', 'meters']
    SiteActions.bindActions(this)
    GxpActions.bindActions(this, 'gxps')
    VerificationMethodActions.bindActions(this, 'verificationMethods')
    LoadTypeActions.bindActions(this, 'loadTypes')
    this.state = {
      hasAvailabilities: null,
      availabilitiesUpdates: null
    }
  }

  dependsOn(){
    const required = [
      this.actions.loadTypes.index()
    ]
    required.push(this.editMode ?
      this.actions.show(this.objectId, {include: 'gxp,distributor,meterOwner,verificationMethod,retailer,flowDirection,organisation,availabilities,consumerAuthorisationCode,consumerNo,customerName,meters'}) :
      this.actions.set()
    )
    return Promise.all(required)
  }

  componentDidMount = () => {
    this.actions.verificationMethods.index({
      pageSize: 300,
      page: 0,
      filter: { applicableTo: this.organisationId  },
      fields: {verificationMethods: 'name'}
    })
  }

  handleFetchGxps = async (text, callback) => {
    const { data: gxps } = await API.Gxps.index({
      options: {
        filter: {
          code: text
        }
      }
    })
    callback(gxps)
  }

  handleFetchOrganisationByType = type => async (text, callback) => {
    const { data: gxps } = await API.Organisations.indexType({
      type,
      options: {
        filter: {
          name: text
        }
      }
    })
    callback(gxps)
  }

  handleAvailabilitiesChanged = ({updates, hasErrors}) => {
    this.setState({availabilitiesUpdates: updates, availabilitiesHasErrors: hasErrors})
  }

  handleToggleHasAvailabilities = (event, checked) => {
    this.setState({ hasAvailabilities: checked })
  }

  get formObject(){
    let availabilitiesAttributes = {}
    if(this.state.hasAvailabilities === false) {
      availabilitiesAttributes = { availabilitiesAttributes: this.availabilities.map(({id}) => {
        return {id, _destroy: true}
      })}
    } else if(this.state.availabilitiesUpdates) {
      availabilitiesAttributes = { availabilitiesAttributes: this.state.availabilitiesUpdates.map(({weekDays, ...rest}) => {
        return {week_days: weekDays, ...rest}
      })}
    }

    return {
      organisationId: this.organisationId,
      status: 'active',
      flowDirection: 'X',
      meters: [{}],
      ...this.props.site,
      ...this.state.formAttributes,
      ...availabilitiesAttributes
    }
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.site.organisation || {}).id
  }

  get verificationMethods(){
    return this.reduceNameIdPairs(this.props.verificationMethods)
  }

  get hasAvailabilities() {
    return (this.state.hasAvailabilities === null) ? this.availabilities.length !== 0 : this.state.hasAvailabilities
  }

  get availabilities() {
    return this.props.site.availabilities || []
  }

  onSaveRedirect = ({data: {id}}) => `/sites/${id}`

  renderLoadInput = loadType =>
    <TextField
      member={`loads.${loadType}`}
      fullWidth
      key={loadType}
      label={loadType}
      type='number'
      defaultValue={0}
      InputProps={
        {
          inputProps: {
            min: 0
          },
          endAdornment: (
            <InputAdornment position="end">
              kW
            </InputAdornment>
          )
        }
      }/>

  addMeter = index => {
    this.setState(state => {
      let meters = (state.formAttributes || {}).meters || this.props.site.meters || [{}]
      let early = meters.slice(0,index)
      let late = meters.slice(index + 1)
      let {id, ...split} = meters[index]
      let splitDate = roundDateOrMoment(split.start ?
        (split.end ?
          moment(split.start).add(
            moment(split.start).diff(split.end, 'minutes') / 2,
            'minutes'
           ) : moment(split.start).add(30, 'minutes')) :
        (split.end ? moment(split.end).subtract(30, 'minutes') : moment()), 30)
      return {
        formAttributes: {
          ...state.formAttributes,
          meters: [
            ...early,
            {...split, end: splitDate},
            {...split, start: moment(splitDate).add(30, 'minutes')},
            ...late
          ]
        }
      }
    })
  }

  removeMeter = index => {
    this.setState(state => {
      let meters = (state.formAttributes || {}).meters || this.props.site.meters
      return {
        formAttributes: {
          ...state.formAttributes,
          meters: [
            ...meters.slice(0,index - 1),
            {...meters[index - 1], end: meters[index].end, id: undefined},
            ...meters.slice(index + 1)
          ]
        }
      }
    })
  }

  setMeterStart = index => ({target: {value}}) => this.setState(state => {
    let meters = (state.formAttributes || {}).meters || this.props.site.meters
    return { formAttributes: {
      ...state.formAttributes,
      meters: [
        ...meters.slice(0,index - 1),
        ...(index > 0 ? [{...meters[index - 1], end: moment(value).subtract(30, 'minutes').format()}] : []),
        {...meters[index], start: value},
        ...meters.slice(index + 1)
      ]
    }}
  })

  setMeterEnd = index => ({target: {value}}) => this.setState(state => {
    let meters = (state.formAttributes || {}).meters || this.props.site.meters
    return { formAttributes: {
      ...state.formAttributes,
      meters: [
        ...meters.slice(0,index),
        {...meters[index], end: value},
        ...(index < meters.length - 1 ? [{...meters[index + 1], start: moment(value).add(30, 'minutes').format()}] : []),
        ...meters.slice(index + 2)
      ]
    }}
  })

  renderMeter = (meter,index) => <TableRow key={String(index)}>
      <TableCell>{meter.start == null ? '-∞' :
        <DateTimePicker member={`meters.${index}.start`} onChange={this.setMeterStart(index)} maxDate={moment(meter.end)} minDate={moment(this.formObject.meters[index - 1].start).add(30, 'minutes')} minutesStep={30}/>
      }</TableCell>
      <TableCell><TextField member={`meters.${index}.icpNumber`}/></TableCell>
      <TableCell><TextField member={`meters.${index}.meterId`}/></TableCell>
      <TableCell>{meter.end == null ? '+∞' :
        <DateTimePicker member={`meters.${index}.end`} onChange={this.setMeterEnd(index)} minDate={moment(meter.start)} maxDate={moment(this.formObject.meters[index + 1].end).subtract(30, 'minutes')} minutesStep={30} />
      }</TableCell>
      <TableCell>
        <IconButton aria-label="Add" onClick={() => this.addMeter(index)}>
          <AddIcon />
        </IconButton>
        {
          index > 0 ?
            <IconButton aria-label="Delete" onClick={() => this.removeMeter(index)}>
              <RemoveIcon />
            </IconButton>:
            null
        }
      </TableCell>
    </TableRow>

  get availabilitiesErrors ()  {
    const errContext = this.errorContext || {}
    return errContext['availabilities']
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Site - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent className={this.props.classes.cardContent}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6' className={this.props.classes.headerFields}>
              Fields
            </Typography>
            <TextField  required fullWidth member='name'/>
            <TextField  required fullWidth member='address'/>
            <Table>
              {(meters => meters.length > 0 ? meters : [{}])(this.formObject.meters || [{}]).map(this.renderMeter)}
            </Table>
            <TextField  disabled required type='number' fullWidth member='kwAmount' label='kW Amount' value={Object.values(this.formObject.loads).reduce((agg, v) => agg + (parseFloat(v) || 0), 0)}/>
            <LabeledSelect
              required fullWidth
              member='verificationMethod.id'
              errorMember='verificationMethod'
              options={this.verificationMethods}
              label="Verification Methodology"
            />
            <AutoSuggest
              required
              fullWidth
              label='GXP'
              member='gxp'
              labelProvider={(({code}) => code)}
              onSuggestionsFetchRequested={this.handleFetchGxps}
            />
            <AutoSuggest
              required
              fullWidth
              member='distributor'
              labelProvider={(({name}) => name)}
              onSuggestionsFetchRequested={this.handleFetchOrganisationByType('distributors')}
            />
            <AutoSuggest
              required
              fullWidth
              member='retailer'
              labelProvider={(({name}) => name)}
              onSuggestionsFetchRequested={this.handleFetchOrganisationByType('retailers')}
            />
            <AutoSuggest
              required
              fullWidth
              member='meterOwner'
              labelProvider={(({name}) => name)}
              onSuggestionsFetchRequested={this.handleFetchOrganisationByType('meter_owners')}
            />
            <LabeledSelect
              required fullWidth member='status'
              options={{active: 'Active', inactive: 'Inactive'}}
            />
            <LabeledSelect
              required fullWidth member='flowDirection'
              options={{X: 'X', I: 'I', 'X-I': 'X-I'}}
            />
            <div className={this.props.classes.availabilities}>
              <div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={this.handleToggleHasAvailabilities} type='checkbox' checked={this.hasAvailabilities} />
                    }
                    label="Has availabililties"
                  />
                </div>
                <Availabilities open={this.hasAvailabilities} availabilities={this.availabilities} readonly={false} onChange={this.handleAvailabilitiesChanged}
                                error={!!this.availabilitiesErrors} helperText={this.availabilitiesErrors}/>
              </div>
            </div>
          </div>
          <div className={this.props.classes.rightPanel}>
            <Typography variant='h6'>Load Types</Typography>
            {
              this.props.loadTypes.map(this.renderLoadInput)
            }
            {
              Authorization.organisation.isRoot &&
              <Fragment>
                <hr/>
                <Typography variant='h6'>Registry settings</Typography>
                <TextField  fullWidth member='consumerAuthorisationCode'/>
                <TextField  fullWidth member='consumerNo'/>
                <TextField  fullWidth member='customerName'/>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <FormContext onChange={this.handleFormObjectChange} context={this.formObject}>
                        <Checkbox type='checkbox' member='registryReqconsEnabled' />
                      </FormContext>
                    }
                    label='Download readings from Registry'
                  />
                </FormControl>
              </Fragment>
            }
            <hr/>
            {
              Authorization.systemPermissions.writeTags &&
              <Fragment>
                <Typography variant='h6'>
                  Tags
                </Typography>
                <Tagger member='tags'/>
              </Fragment>
            }
          </div>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit' disabled={this.state.availabilitiesHasErrors}>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  headerFields: {
    color: 'white',
    flex: '1 0 100%',
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    padding: 20,
    margin: '0 auto',
    '& > form': {
      width: '100%'
    }
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    minWidth: 300,
    marginBottom:10,
    '& > div': {
      flex: '1 1 auto'
    }
  },
  rightPanel: {
    flex: 1,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  },
  availabilities: {
    marginTop: 24
  },
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({
    sites,
    gxps: { gxps },
    loadTypes: { loadTypes },
    organisations: { organisationsByType },
    verificationMethods: { verificationMethods }
  }) => ({...sites, gxps, organisationsByType, verificationMethods, loadTypes: loadTypes.map(({name}) => name)})),
)(Form)
