import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'

export class AmbiDeviceStateActionSet extends ActionSet {
  static initialState = {
    ambiDeviceStates: [],
    latest: {},
    requests: [],
    errors: {
      index: null
    },
    page: 1,
    totalPages: 0
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static index(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({deviceId, page: targetPage, clearExisting=false, params={}, fields=null, include=null, filter=null, order=null, pageSize=undefined }={}) => (dispatch, getState) => {
      let page = targetPage === undefined ? getState().ambiDeviceStates.page : targetPage
      return dispatch({
        type: constants.ACTION,
        promise: API.Devices.ambi_states({id: deviceId, ...params, options: {page: { number: page, size: pageSize }, ...order && {order}, ...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
        payload: { page, clearExisting }
      })
    })

    reducer({
      [constants.REQUEST]: (state, { requestPayload: { page, clearExisting }, request }) => {
        return {...state, page, ...clearExisting && {ambiDeviceStates:[]}, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data, meta: { totalPages, page }} }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, ambiDeviceStates: data, totalPages, page }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }

  static latest(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({deviceId, params={}, fields=null, include=null, filter=null, order=null, pageSize=undefined }={}) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: API.Devices.ambi_states({id: deviceId, ...params, options: {only_latest: true, ...order && {order}, ...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
        payload: {}
      })
    })

    reducer({
      [constants.REQUEST]: (state, { requestPayload: { page, clearExisting }, request }) => {
        return {...state, page, ...clearExisting && {ambiDeviceStates:[]}, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const latest = data.reduce((acc,s) => ({...acc, [s.category]:s}),{})
        return {...state, requests, latest }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }

  static setPage(creator, reducer, constants){
    creator(page => (dispatch,getState) => {
      return dispatch({
        type: constants.ACTION,
        payload: { page }
      })
    })

    reducer({
      [constants.ACTION]: (state, { page }) => ({...state, page})
    })
  }
}

export default new AmbiDeviceStateActionSet()