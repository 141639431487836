import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import WidgetContainer from './WidgetContainer'
import { compose } from 'utils'
import {withRouter} from 'react-router-dom'
import withStyles from 'styles'
import RightIcon from '@material-ui/icons/ChevronRight'
import {Cards} from 'containers/events'
import Button from '@material-ui/core/Button'
import { withPermissions } from 'containers/shared'
import {Authorization} from 'utils'
import Switch from '@material-ui/core/Switch'
import { connect } from 'react-redux'

export class UpcomingEventsWidget extends Component{
  state = {
    eventsType: null
  }

  get canWriteProgrammes() {
    const {permissions} = this.props
    return permissions.organisation && (permissions.organisation[Authorization.organisationId] || {}).writeProgrammes
  }

  get eventsType() {
    if(!!this.state.eventsType) {
      return this.state.eventsType;
    }

    if(!!this.canWriteProgrammes) {
      return 'parent'
    } else {
      return 'child'
    }
  }

  handleToggleViewChildEvents = () => {
    if(this.eventsType === 'child') {
      this.setState({eventsType: 'parent'})
    }

    if(this.eventsType === 'parent') {
      this.setState({eventsType: 'child'})
    }
  }

  render = () => {
    return (
      <WidgetContainer width='lg'>
        <div>
          <div style={{position: 'relative'}}>
            {
              this.canWriteProgrammes &&
              <div style={{position: 'absolute', top: '-9px', marginLeft: '5px'}}>
                <span className={this.props.classes.eventTypeToggle}>
                  <span className={this.props.classes.eventTypeLabel}>Parent</span>
                  <Switch checked={this.eventsType === 'child'} onChange={this.handleToggleViewChildEvents}/>
                  <span className={this.props.classes.eventTypeLabel}>Child</span>
                </span>
              </div>
            }
            <Typography variant='h6'>Events</Typography>
          </div>
          <Cards eventsType={this.eventsType} onEventsTypeChanged={(eventsType) => this.setState({eventsType})}/>
          <Button className={this.props.classes.eventButton} onClick={() => this.props.history.push({
            pathname: '/events', search: "?" + new URLSearchParams({'events.childEvents': this.state.eventsType === 'child'}).toString()
          })}>
            See All Events
            <RightIcon/>
          </Button>
        </div>
      </WidgetContainer>
    )
  }
}

const styles = theme => ({
  eventButton: {
    marginTop: 10,
    paddingBottom: '50px',
  },
  eventTypeLabel: {
    fontSize: 11,
    textTransform: "uppercase",
    background: "rgba(255,255,255,0.2)",
    padding: "5px",
    color: "white",
    borderRadius: "6px",
  },
})

export default compose(
  withStyles(styles),
  withPermissions(() => ({organisation: {[Authorization.organisationId]: ['writeProgrammes']}})),
  withRouter,
  connect(() => ({}))
)(UpcomingEventsWidget)
