import React, { Fragment } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip'
// import ExpandLess from '@material-ui/icons/ExpandLess'
// import ExpandMore from '@material-ui/icons/ExpandMore'

import MailIcon from '@material-ui/icons/Mail'
import PersonIcon from '@material-ui/icons/Person'
import PhoneIcon from '@material-ui/icons/Smartphone'
import QueueIcon from '@material-ui/icons/Queue'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import { compose, formatLocaleDateTime } from 'utils'
import withStyles from 'styles'
import NotificationDeliveriesList from './NotificationDeliveriesList'

const NotificationListItem = ({ notification, selected, onSelect, onCancel, onEnqueue, selectable, cancelable, enquable, classes, deliveryState, onClick, expended=false } ) => {
  const { message, title, notificationDeliveries, createdAt } = notification

  const selectedHandler = function(event, checked) {
    onSelect(checked)
  }

  function deliveriesCountOver(deliveryType) {
    const filtered = notificationDeliveries.filter(({type, state}) => (type === deliveryType) && (state === deliveryState))
    return filtered.length || 0
  }

  function handleOnClick(event) {
    if(typeof onClick === "function") {
      onClick()
    }
  }

  function onSelectedClicked(event) {
    event.stopPropagation()
  }

  function onCancelClicked(event) {
    event.stopPropagation()
    if(typeof onCancel === "function") {
      onCancel()
    }
  }

  function onEnqueueClicked(event) {
   event.stopPropagation()
    if(typeof onEnqueue === "function") {
      onEnqueue()
    }
  }

  const secondaryActions = []
  if(enquable) {
    secondaryActions.push(
      <Tooltip key='enqueue' title="Enqueue">
        <IconButton aria-label="Enqueue" onClick={ onEnqueueClicked }>
          <QueueIcon />
        </IconButton>
      </Tooltip>
    )
  }
  if(cancelable) {
    secondaryActions.push(
      <Tooltip key='cancel' title="Cancel">
        <IconButton aria-label="Cancel" onClick={ onCancelClicked }>
          <CancelIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Fragment>
    <ListItem button onClick={handleOnClick} classes={{
      secondaryAction: classes('itemComponent', `secondaryActions-${secondaryActions.length}`)
    }}>
      { selectable &&
        <Checkbox checked={selected} onClick={ onSelectedClicked } onChange={ selectedHandler }/>
      }
      <ListItemIcon>
        <Badge badgeContent={deliveriesCountOver('appDeliveries')} color="secondary">
          <PersonIcon/>
        </Badge>
      </ListItemIcon>
      <ListItemIcon>
        <Badge badgeContent={deliveriesCountOver('emailDeliveries')} color="secondary">
          <MailIcon/>
        </Badge>
      </ListItemIcon>
      <ListItemIcon>
        <Badge badgeContent={deliveriesCountOver('smsDeliveries')} color="secondary">
          <PhoneIcon/>
        </Badge>
      </ListItemIcon>
      <ListItemText secondary={message} classes={{ primary: classes.primary }}>
        <Fragment>
          <span>{title}</span>
          <span>{formatLocaleDateTime(createdAt)}</span>
        </Fragment>
      </ListItemText>
      <ListItemSecondaryAction>
        {
          // <IconButton>
          //   { !!expended ? <ExpandLess /> : <ExpandMore />}
          // </IconButton>
        }
        { secondaryActions }
      </ListItemSecondaryAction>
    </ListItem>
    <Collapse in={!!expended} timeout="auto" unmountOnExit className={classes.deliveriesList}>
      <NotificationDeliveriesList deliveries={ notificationDeliveries } />
    </Collapse>
    </Fragment>
  )
}

const styles = theme => ({
  primary: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deliveriesList: {
    paddingLeft: '24px',
    maxHeight: '300px',
    overflowY: 'auto',
  },
  'secondaryActions-2': {},
  itemComponent: {
    '&$secondaryActions-2': { paddingRight: '96px' }
  }
})

export default compose(
  withStyles(styles),
)(NotificationListItem)