import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import withStyles from 'styles'
import { compose } from 'utils'
import moment from 'moment'
import CollapsibleTable, { TableHead, TableBody, TableCell, TableRow } from 'components/CollapsibleTable';

export class CBLTable extends Component {

  get tradingPeriods(){
    return this.props.cbl.tradingPeriods
  }

  get cbl(){
    return this.props.cbl
  }

  timeFromDate = date => {
    return moment(date).format('HH:mm')
  }

  render(){
    return (
      <Fragment>
        <CollapsibleTable breakpoint={400} className={this.props.className}>
          <TableHead>
            <TableRow>
              <TableCell variant='head'>TP</TableCell>
              <TableCell align='right' variant='head'>Target DR</TableCell>
              <TableCell align='right' variant='head'>Actual DR</TableCell>
              <TableCell align='right' variant='head'>Performance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.tradingPeriods.map(({from, to}, index) =>
                <TableRow key={index}>
                  <TableCell mobileHeading mobileLabel='Trading Period'>{this.timeFromDate(from)} — {this.timeFromDate(to)}</TableCell>
                  <TableCell align='right' mobileLabel='Target DR'>{this.cbl.offered[index].toFixed(2)}kWh</TableCell>
                  <TableCell align='right' mobileLabel='Actual DR'>{this.cbl.reduction[index].toFixed(2)}kWh</TableCell>
                  <TableCell align='right' mobileLabel='Performance'>{this.cbl.performance[index].toFixed(2)}%</TableCell>
                </TableRow>
              )
            }
            <TableRow>
              <TableCell mobileHeading mobileLabel='performance' colSpan={1}>
                Performance
              </TableCell>
              <TableCell colSpan={3}>
                <p className={this.props.classes.totalPerformance}>
                  {(this.cbl.performance.reduce((agg, val) => agg + val, 0) / this.cbl.performance.length).toFixed(2)}%
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </CollapsibleTable>
      </Fragment>
    )
  }
}

const styles = theme => ({
  totalPerformance: {
    fontSize: 16,
    color: '#555',
    fontWeight: 'bold',
    textAlign: 'right',
    margin: 0
  }
})

export default compose(
  withRouter,
  withStyles(styles),
)(CBLTable)