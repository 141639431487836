import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'

export class SystemParametersActionSet extends ActionSet {

  static initialState = {
    quarantine: null,
    requests: [],
    errors: {}
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static loadParam(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((name) => {
      return {
        type: constants.ACTION,
        promise: API.SystemParameters.show({name})
      }
    })

    reducer({
      [constants.SUCCESS]: (state, { request, result: { data: {tag} } }) => {
        return { ...state, tag }
      }
    })
    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, loadParam: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const { name } = data
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, [name]: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, loadParam: error }
        return {...state, requests, errors }
      }
    })
  }

  static setParam(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((name, value) => {
      return {
        type: constants.ACTION,
        promise: API.SystemParameters.update({name, value})
      }
    })

    reducer({
      [constants.SUCCESS]: (state, { request, result: { data: {tag} } }) => {
        return { ...state, tag }
      }
    })
    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, setParam: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const { name } = data
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, [name]: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, setParam: error }
        return {...state, requests, errors }
      }
    })
  }
}

export default new SystemParametersActionSet()