import React, { Component } from 'react'
import { compose } from 'utils'
import { connect } from 'react-redux'
import * as API from 'api'
import {
  EarningsWidget,
  PerformanceWidget, VerticalGroup,
} from './'
import { Authorization } from 'utils'
import moment from 'moment'

export class ParticipantWidgets extends Component{

  state = {
    pastYear: null,
    allTime: null,
  }

  componentDidMount = async () => {
    const [{data: pastYear}, {data: allTime}] = await Promise.all([
      API.Reports.show({
        type: 'events',
        options: {
          filter: {
            organisation: Authorization.organisationName,
            from: moment().add(-12, 'month').toDate(),
            to: moment().toDate()
          },
        }
      }),
      API.Reports.show({
        type: 'events',
        options: {
          filter: {
            organisation: Authorization.organisationName,
            from: moment().add(-10, 'year').toDate(),
            to: moment().toDate()
          }
        }
      })
    ])
    this.setState({pastYear, allTime})
  }

  render = () =>
    this.state.pastYear && (this.state.allTime.averagePerformance + this.state.allTime.totalPayment) > 0 ?
      <VerticalGroup>
        <PerformanceWidget  averagePerformance={{allTime: this.state.allTime.averagePerformance, pastYear: this.state.pastYear.averagePerformance}}/>
        <EarningsWidget totalPayments={{allTime: this.state.allTime.totalPayment, pastYear: this.state.pastYear.totalPayment}}/>
      </VerticalGroup> :
      false

}

export default compose(
  connect(),
)(ParticipantWidgets)
