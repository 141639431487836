import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, Authorization, flatten, compact } from 'utils'
import { withPermissions } from 'containers/shared'
import { ParentEvent, ChildEvent } from 'containers'
import { DatePicker, LabeledSelect, MultiAutoSuggest } from 'components'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment'
import TabIcon from '@material-ui/icons/ExpandLess'
import IconButton from '@material-ui/core/IconButton'
import withStyles from 'styles'
import { connectQueryString } from 'containers/shared'
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import * as API from 'api'
import { ChildEventActions } from 'actionsets'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

export class List extends Component{

  static get defaultFilter(){
    return {
      from: moment().startOf('month').subtract(1, 'month').toDate(),
      to: moment().endOf('year').toDate(),
    }
  }

  state = {
    filterSectionCollapsed: false
  }

  constructor(props) {
    super(props)
    ChildEventActions.bindActions(this)
  }

  get filter(){
    return Object.values(this.props.filter).some(v => v) ? this.props.filter : List.defaultFilter
  }

  get canWriteProgrammes(){
    return (this.props.permissions.organisation[Authorization.organisationId] || {}).writeProgrammes
  }

  get statusOptions(){
    return this.shouldShowParentEvents ?
      {
        '': 'All',
        cancelled: 'Cancelled',
        completed: 'Completed',
        active:    'Active',
        draft:     'Draft',
        offer_window_closed: 'Offer Window Closed',
        offer_window_open: 'Offer Window Open',
        scheduled: 'Scheduled',
        submitted: 'Submitted',
      } :
      {
        '': 'All',
        completed: 'Completed',
        active:    'Active',
        meter_data_required: 'Meter Data Required',
        meter_data_submitted: 'Meter Data Submitted',
        offer_window_closed: 'Offer Window Closed',
        offer_window_open: 'Offer Window Open',
        payment_complete: 'Payment Complete',
        payment_pending: 'Payment Pending',
        declined: 'Declined',
        scheduled: 'Scheduled',
        withdrawn: 'Withdrawn',
        ineligible: 'Ineligible',
      }

  }

  get shouldShowParentEvents(){
    return this.canWriteProgrammes && this.filter.childEvents !== 'true'
  }

  get shouldShowChildEvents(){
    return !this.shouldShowParentEvents
  }

  handleToggleViewChildEvents = () => {
    this.props.onFilterChange({
      ...this.filter,
      status: '',
      childEvents: !(`${this.props.filter.childEvents}` === 'true')
    })
  }

  handleStatusFilterChanged = ({target: { value }}) => {
    this.props.onFilterChange({
      ...this.filter,
      status: value
    })
  }

  handleProgrammeFilterChanged = ({target: { value: programme }}) => {
    this.props.onFilterChange({
      ...this.filter,
      programme
    })
  }

  handleOrganisationFilterChanged = ({target: { value: organisation }}) => {
    this.props.onFilterChange({
      ...this.filter,
      organisation
    })
  }

  handleRegistrationFilterChanged = ({target: { value: registration }}) => {
    this.props.onFilterChange({
      ...this.filter,
      registration
    })
  }

  handleToggleCollapseFilter = () => {
    this.setState({
      filterSectionCollapsed: !this.state.filterSectionCollapsed
    })
  }

  handleProgrammesFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: programmes } = await API.Programmes.index(params)
    callback(programmes.map(({name}) => name).filter(name => !(this.filter.programme || []).includes(name)).slice(0, 5))
  }

  handleRegistrationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: registrations } = await API.Registrations.index(params)
    callback(registrations.map(({name}) => name).filter(name => !(this.filter.registration || []).includes(name)).slice(0, 5))
  }

  handleOrganisationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: organisations } = await API.Organisations.index(params)
    callback(organisations.map(({name}) => name).filter(name => !(this.filter.organisation || []).includes(name)).slice(0, 5))
  }

  handleSelectPresetDateRange = ({target: { value: selectedPreset }}) => {
    let [from, to] = [undefined,undefined]
    switch(selectedPreset){
    case 'this_week':
      from = moment().toDate()
      to = moment().add(7, 'day').toDate()
      break
    case 'this_month':
      from = moment().toDate()
      to = moment().add(1, 'month').toDate()
      break
    case 'next_week':
      from = moment().add(7, 'day').toDate()
      to   = moment().add(14, 'day').toDate()
      break
    case 'past_events':
      from = moment().startOf('year').toDate()
      to = moment().toDate()
      break
    default: {
      from = moment().subtract(2, 'years').startOf('year').toDate()
      to = moment().toDate()
      }
    }
    this.props.onFilterChange({
      ...this.filter,
      selectedPreset,
      from,
      to
    })
  }

  handleDateFilterFromChange = ({target: { value: date }}) =>
    this.props.onFilterChange({
      ...this.filter,
      from: moment(date).startOf('day').toDate()
    })

  handleDateFilterToChange = ({target: { value: date }}) =>
    this.props.onFilterChange({
      ...this.filter,
      to: moment(date).endOf('day').toDate()
    })

  handleDownloadChildEventsFile = async () => {
    await this.actions.downloadExport({
      filter: this.props.filter,
      order: this.props.order
    })
  }

  render = () => {
    const { permissions, classes, filter, ...props } = this.props
    if(!permissions.organisation)
      return false
    return (
      <Card className={classes.card}>
        <CardContent className={this.props.classes.cardContent}>
          <Typography variant='h5'>Events</Typography>
            <div className={this.props.classes('filters', 'grid', {collapsed: this.state.filterSectionCollapsed})}>
              <IconButton className={this.props.classes.collapseButton} onClick={this.handleToggleCollapseFilter}>
                <TabIcon/>
              </IconButton>

              {!this.state.filterSectionCollapsed &&
              <Fragment>
                <div className={this.props.classes.filterSection} style={{gridRow: 'span 2'}}>
                  <Typography variant='h6'>Dates</Typography>
                  <DatePicker
                    value={this.filter.from}
                    name='filterFrom'
                    onChange={this.handleDateFilterFromChange}
                    label='From Date'
                    />
                  <DatePicker
                    value={this.filter.to}
                    name='filterTo'
                    onChange={this.handleDateFilterToChange}
                    label='To Date' />
                  <RadioGroup
                    row
                    value={this.filter.selectedPreset}
                    onChange={this.handleSelectPresetDateRange}
                    style={{flex: '0 0 100%'}}
                  >
                    <FormControlLabel value="this_week"   control={<Radio />} label="This Week" />
                    <FormControlLabel value="this_month"  control={<Radio />} label="This Month" />
                    <FormControlLabel value="next_week"   control={<Radio />} label="Next Week" />
                    <FormControlLabel value="past_events" control={<Radio />} label="Past Events" />
                    <FormControlLabel value="all_events" control={<Radio />} label="All Events" />
                  </RadioGroup>
                  {
                    (this.filter.childEvents === 'true') &&
                    <Tooltip title="Export child events">
                      <IconButton onClick={this.handleDownloadChildEventsFile}>
                        <CloudDownloadIcon/>
                      </IconButton>
                    </Tooltip>
                  }
                </div>
                {
                  this.canWriteProgrammes &&
                  <div className={this.props.classes.filterSection}>
                    <Typography variant='h6'>Type</Typography>
                    <span className={this.props.classes.eventTypeToggle}>
                      Event Type:
                      &emsp;
                      <span className={this.props.classes.eventTypeLabel}>Parent</span>
                      <Switch checked={this.filter.childEvents === 'true'} onChange={this.handleToggleViewChildEvents}/>
                      <span className={this.props.classes.eventTypeLabel}>Child</span>
                    </span>
                  </div>
                }
                <div className={this.props.classes.filterSection}>
                  <Typography variant='h6'>Details</Typography>
                  <LabeledSelect
                    className={this.props.classes.statusSelect}
                    onChange={this.handleStatusFilterChanged}
                    options={this.statusOptions}
                    value={this.props.filter.status}
                    label="Status"
                  />
                  <MultiAutoSuggest
                    label="Programme"
                    classes={{wrapper: this.props.classes.programmesAutoSuggest, input: this.props.classes.programmesAutoSuggestInput}}
                    value={compact(flatten([this.filter.programme]))}
                    labelProvider={(name) => name}
                    onChange={this.handleProgrammeFilterChanged}
                    onSuggestionsFetchRequested={this.handleProgrammesFetchRequested}
                  />
                  {
                    this.shouldShowChildEvents &&
                    <Fragment>
                      <MultiAutoSuggest
                        label="Organisation"
                        classNames={{container: this.props.classes.multiAutoSuggest, input: this.props.classes.multiAutoSuggestInput}}
                        value={compact(flatten([this.filter.organisation]))}
                        labelProvider={(name) => name}
                        onChange={this.handleOrganisationFilterChanged}
                        onSuggestionsFetchRequested={this.handleOrganisationsFetchRequested}
                      />
                      <MultiAutoSuggest
                        label="Registration"
                        classNames={{container: this.props.classes.multiAutoSuggest, input: this.props.classes.multiAutoSuggestInput}}
                        value={compact(flatten([this.filter.registration]))}
                        labelProvider={(name) => name}
                        onChange={this.handleRegistrationFilterChanged}
                        onSuggestionsFetchRequested={this.handleRegistrationsFetchRequested}
                      />
                    </Fragment>
                  }
                </div>
              </Fragment>
              }
            </div>
          {
             this.shouldShowParentEvents?
              <ParentEvent.List {...props} filter={this.filter}/> :
              <ChildEvent.List {...props}  filter={this.filter}/>
          }
        </CardContent>
      </Card>
    )
  }
}

const styles = theme => ({
  statusSelect: {
    display: 'flex',
    flex: '1 0 auto',
    minWidth: 200,
    paddingRight: 10,
  },
  programmesAutoSuggest: {
    flex: '1 0 auto',
    marginTop: -2,
    minWidth: 200,
    maxWidth: 'calc(50% - 15px)'
  },
  multiAutoSuggestInput: {
    top: -4,
    marginTop: 4
  },
  filters: {
    flex: '1 0 auto',
    display: 'flex',
    flexWrap: 'wrap',

    '@media(min-width: 600px)': {
      gridTemplateColumns: '1fr 1fr',
    },

    background: "rgba(0, 27, 38, 0.5)",
    marginBottom: 20,
    position: 'relative',
    maxWidth: '100%',
    width: '100vw',
    minHeight: 48,
    border: '1px solid rgba(150, 254, 172, 0.1)',

    '&$grid': {
      display: 'grid /* autoprefixer: off */',
      gridTemplateColumns: '1fr',
      '@media(min-width: 600px)': {
        gridTemplateColumns: '1fr 1fr',
      },
    }
  },
  grid: {},
  radiosSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  filterSection: {
    paddingRight: 20,
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',

    marginRight: 10,
    padding: '15px 10px',
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    alignContent: 'flex-start',
    '& > h6': {
      fontSize: '1rem',
      flex: '0 0 100%'
    },
    '& > div': {
      flex: '1 0 auto',
      paddingRight: 10,
    }
  },
  collapsed: {
    '&::before': {
      content: "'Filters'",
      lineHeight: '48px',
      color: 'white',
      fontWeight: 900,
      fontSize: '1rem',
      paddingLeft: 10,
      borderLeft: `3px solid ${theme.palette.secondary.light}`,
      display: 'block',
    },

    '& $collapseButton': {
      transform: 'rotate(180deg)'
    }
  },
  cardContent: {
    position: 'relative',
  },
  eventTypeToggle: {
    color: '#a5a5a5'
  },
  eventTypeLabel: {
    fontSize: 11,
    textTransform: "uppercase",
    background: "rgba(255,255,255,0.2)",
    padding: "5px",
    color: "white",
    borderRadius: "6px",
  },
  collapseButton: {
    color: 'white',
    fontSize: 11,
    position: 'absolute',
    zIndex: 100,
    top: 0,
    right: 0,
    transition: 'transform 500ms',
  },
  card: {
    width: 'calc(100% - 20px)',
    '@media(min-width: 600px)': {
      width: 'calc(100% - 48px)',
    },
    maxWidth: theme.viewAreaMaxWidth + 32,
  }
})

export default compose(
  withStyles(styles),
  connectQueryString('events'),
  withPermissions(() => ({organisation: { [Authorization.organisationId]: ['writeProgrammes']}})),
  connect(() => ({}))
)(List)
