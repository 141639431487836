import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import RefreshIcon from '@material-ui/icons/Refresh'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { compose } from 'utils'
import IconTick from '@material-ui/icons/Check'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { formatLocaleDateTime } from 'utils'

export class JobListItem extends Component{
  static propTypes = {
    job: PropTypes.object.isRequired,
    canChange: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onReschedule: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      showErrorDialog: false
    }
  }

  get reschedulable() {
    const { status } = this.props.job
    return ['finished', 'cancelled', 'failed'].includes(status)
  }

  get cancellable() {
    const { status } = this.props.job
    return ['new', 'scheduled'].includes(status)
  }

  handleShowError = () => {
    this.setState({showErrorDialog: true})
  }

  renderErrorDialog = (exception) => {
    const lines = exception.split(/\r?\n/)
    const renderLine = (line, index) => <Typography key={index} variant='body2' className={this.props.classes('errorLine')}>{line}</Typography>

    return (
      <Fragment>
      { this.state.showErrorDialog &&
        <Dialog open={true} onClose={() => this.setState({showErrorDialog: false})}>
          <DialogTitle>Error backtrace</DialogTitle>
          <DialogContent>{ lines.map(renderLine) }</DialogContent>
        </Dialog>
      }
      </Fragment>
    )
  }

  renderFailedIndicator = (job) => {
    const { status, exception } = job

    if(status !== 'failed' && !exception) {
      return null
    }

    const statusIcon = (status === 'failed') ? <ErrorIcon className={this.props.classes('error')}/> :
                                               <WarningIcon className={this.props.classes('warn')}/>

    return (
      <Fragment>
        <IconButton onClick={this.handleShowError} disabled={!exception}>
          { statusIcon }
        </IconButton>
        { (!!exception) && this.renderErrorDialog(exception) }
      </Fragment>
    )
  }

  render = () => {
    const { id, name, runCount, runAt, status, providerJobId, finishedAt } = this.props.job

    return (
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{providerJobId}</TableCell>
        <TableCell>{runCount}</TableCell>
        <TableCell>{ (!!runAt) && formatLocaleDateTime(runAt, {includeSeconds: true}) }</TableCell>
        <TableCell>{status === 'running' ? <IconTick/> : false}</TableCell>
        <TableCell>{status === 'cancelled' ? <IconTick/> : false}</TableCell>
        <TableCell>{ (!!finishedAt) && formatLocaleDateTime(finishedAt, {includeSeconds: true}) }</TableCell>
        <TableCell>

          { (this.props.canChange && this.reschedulable) &&
            <Tooltip title="Reschedule" placement="bottom-end">
              <IconButton onClick={ (event) => {
                event.stopPropagation()
                this.props.onReschedule()
              } }>
                <RefreshIcon/>
              </IconButton>
            </Tooltip>
          }

          { (this.props.canChange && this.cancellable) &&
            <IconButton aria-label="Cancel" onClick={ (event) => {
                event.stopPropagation()
                this.props.onCancel()
              } }>
              <CancelIcon />
            </IconButton>
          }
        </TableCell>
        <TableCell>{ this.renderFailedIndicator(this.props.job) }</TableCell>
      </TableRow>
    )
  }
}

const styles = theme => ({
  error: {
    color: theme.palette.error.main
  },
  warn: {
    color: '#dede43'
  },
  errorLine: {
    whiteSpace: 'nowrap'
  }
})

export default compose(
  withStyles(styles)
)(JobListItem)