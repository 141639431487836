import React, { Component } from 'react'
import WidgetContainer from './WidgetContainer'
import { compose, humanize } from 'utils'
import { connect } from 'react-redux'
import withStyles from 'styles'
import Badge from '@material-ui/core/Badge'
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography'
import * as API from 'api'
import { withRouter } from 'react-router-dom'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Loader } from 'components'

export class TasksWidget extends Component{

  state = {
    tasks: [],
    urls: []
  }

  componentDidMount = async () => {
    const { data: { id, type, ...tasks } } = await API.Tasks.index({
      options: {
        include: ''
      }
    })
    this.setState({tasks: this.buildTasks(tasks.summary)})
  }

  urlFor = (key, value) => {
    if(typeof value === 'object'){
      return `/${value.type}/${value.id}`
    }
    switch(key){
    case 'childEventsPendingMeterData':
      return '/events?events.childEvents=true&events.status=meter_data_required'
    case 'childEventsPendingPayment':
      return '/events?events.childEvents=true&events.status=meter_data_submitted'
    case 'childEventsPendingInvoice':
      return '/events?events.childEvents=true&events.status=payment_pending'
    default: return undefined
    }
  }

  buildTasks = (tasks) => {
    return (
      Object.entries(tasks)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        return {
          key,
          count: value,
          label: humanize(key),
          url: this.urlFor(key, value)
        }
      })
    )
  }

  renderTaskItem = ({key, count, label, url}, index) =>
    <ListItem button onClick={this.handleTaskClick(url, key)} key={index} className={this.props.classes.task}>
      <ListItemIcon><Badge classes={{badge: this.props.classes.badge}} badgeContent={count}><div/></Badge></ListItemIcon>
      <ListItemText primary={label} className={this.props.classes.taskText}/>
    </ListItem>

  handleTaskClick = (url, key) => async ({currentTarget}) => {
    if(typeof url === 'string'){
      this.props.history.push(url)
      return
    }

    try {
      // By now we expect this List Item to open a dialog and render a collection of links
      this.setState({
        menuAnchorEl: currentTarget,
        menuLoading: true,
        urls: [],
      })
      let { data: tasks } = await API.Tasks.index({
        options: {
          attributes: key,
          include: key
        }
      })
      let urls = tasks[key].map(v => ({url: this.urlFor(key, v), value: v}))
      this.setState({urls})
    } finally {
      this.setState({menuLoading: false})
    }
  }

  handleTaskMenuClose = () => {
    this.setState({menuAnchorEl: undefined, urls: []})
  }

  render = () => {
    return (
      <WidgetContainer width='sm' className={this.props.classes.widgetContainer}>
        <Typography variant='h6'>Tasks</Typography>
        <List className={this.props.classes.tasksList} dense>
          {
            this.state.tasks.map(this.renderTaskItem)
          }
          {
            !this.state.tasks.length && <span className={this.props.classes.noTasksMessage}>No outstanding tasks</span>
          }
        </List>
        <Menu
          id="simple-menu"
          anchorEl={this.state.menuAnchorEl}
          open={Boolean(this.state.menuAnchorEl)}
          onClose={this.handleTaskMenuClose}
          PopoverClasses={{paper: this.props.classes.menu}}
        >
          {
            this.state.menuLoading ? <Loader size={100}/> :
            this.state.urls.map(({url, value: { name, code }}) =>
              <MenuItem key={url} onClick={() => this.props.history.push(url)}>{name || code}</MenuItem>
            )
          }
        </Menu>
      </WidgetContainer>
    )
  }
}

const styles = theme => ({
  tasksList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 0,
  },
  noTasksMessage: {
    flex: 1,
    textAlign: 'center',
    display: 'block',
    alignSelf: 'center',
    color: theme.palette.primary.main
  },
  widgetContainer: {
    textAlign: 'center',
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: 24,
    minWidth: 24,
    borderRadius: 12
  },
  task: {
    borderTop: '1px solid rgba(255,255,255,0.3)',
    minHeight: '42px',
    '&:last-child': {
      borderBottom: '1px solid rgba(255,255,255,0.3)',
    }
  },
  taskText: {
    fontSize: '0.9rem'
  },
  menu: {
    minWidth: 400,
    minHeight: 500,
  }
})

export default compose(
  connect(),
  withRouter,
  withStyles(styles)
)(TasksWidget)