import React from 'react'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { DoneMarker } from 'components'

const NotificationListItem = ({ notification, onSelect, onToggleDone } ) => {
  const { done, title, message, subjectUriPath, missingSubject} = notification
  const content = (missingSubject) ? <ListItemText primary={'ERROR: missing subject. You should not see this notification.'} />
    : <ListItemText primary={title} secondary={message} />
  return (
    <ListItem button={!!subjectUriPath} onClick={ (!!subjectUriPath) ? () => onSelect() : null } disabled={missingSubject}>
        <Tooltip title={`Mark as ${notification.done ? 'undone' : 'done'}`}  placement="bottom-end">
          <IconButton onClick={ (event) => {
            event.stopPropagation()
            onToggleDone()
          } }>
            <DoneMarker marked={ done } />
          </IconButton>
        </Tooltip>
        {content}
    </ListItem>
  )
}

export default NotificationListItem