import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const MeterData = ProtectedJsonAPI.extend(
  '',
  {
    index:    endpoint => endpoint.path(({type}) => `meter_data/${type}`),
    sum:      endpoint => endpoint.path(({type}) => `meter_data/${type}/sum`),
    min:      endpoint => endpoint.path(({type}) => `meter_data/${type}/min`),
    max:      endpoint => endpoint.path(({type}) => `meter_data/${type}/max`),
    download: endpoint => endpoint.method('post').useForm(true).path(({type}) => `meter_data/${type}/download`).paramsHandler(params => ({...params, Authorization: global.localStorage.auth})),
  }
)