import 'react-app-polyfill/ie11';
import './polyfills'
 // Test with IE11 if we really need these imports
import 'core-js/es/map'
import 'core-js/es/set'

import './index.css';
import './font-segoe.css'
import './font-inter.css'
import './font-mukta.css'
import './DevTools';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from './containers';
import { unregister } from './registerServiceWorker';

ReactDOM.render(<AppContainer/>, document.getElementById('root'));

unregister();
