import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Devices = ProtectedJsonAPI.extend(
  '',
  {
    index:         endpoint => endpoint.path(({siteId, organisationId}) => siteId ? `sites/${siteId}/devices` : `/organisations/${organisationId}/devices`),
    stats:         endpoint => endpoint.path(({siteId, organisationId}) => siteId ? `sites/${siteId}/devices/stats` : (organisationId ? `/organisations/${organisationId}/devices/stats` : "devices/stats")),
    deviceSignals: endpoint => endpoint.path('devices/device_signals'),
    create:        endpoint => endpoint.method('post').path(({siteId}) => `sites/${siteId}/devices`),
    update:        endpoint => endpoint.method('put').path(({id}) => `devices/${id}`),
    destroy:       endpoint => endpoint.method('delete').path(({id}) => `devices/${id}`),
    show:          endpoint => endpoint.path(({id}) => `devices/${id}`),
    ambi_states:   endpoint => endpoint.path(({id}) => `devices/${(id && `${id}/`) || ""}ambi_climate/device_states`),
    testEvent:     endpoint => endpoint.method('post').path(({id}) => `devices/${id}/test_event`)
  }
)