import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const VerificationMethods = ProtectedJsonAPI.extend(
  '/verification_methods',
  {
    index:    endpoint => endpoint,
    create:   endpoint => endpoint.method('post'),
    update:   endpoint => endpoint.method('put').path(({id}) => id),
    destroy:  endpoint => endpoint.method('delete').path(({id}) => id),
    baseline: endpoint => endpoint.method('post').path('/baseline'),
    show:     endpoint => endpoint.path(({id}) => id)
  }
)