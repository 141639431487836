import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LoadTypeActions, SnackbarActions } from 'actionsets'
import { Pagination, FABFixed, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList  from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import LoadTypeIcon from '@material-ui/icons/WbIncandescent'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { withPermissions } from 'containers/shared'

export class List extends Component{

  static requiredPermissions = () => ({
    system: ['manageLoadTypes']
  })

  constructor(props){
    super(props)
    LoadTypeActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadLoadTypes()
  }

  loadLoadTypes = () => {
    return this.actions.index({
      page: this.state.page,
      fields: {load_types: 'name'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showLoadType = id => () => {
    this.props.history.push(`/load_types/${id}`)
  }

  editLoadType = id => event => {
    this.props.history.push(`/load_types/${id}/edit`)
    event.stopPropagation()
  }

  deleteLoadType = id => event => {
    this.actions.destroy({id})
                .then(this.loadLoadTypes)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get loadTypes(){
    return this.props.loadTypes
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadLoadTypes())

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderLoadTypeListItem = ({id, name}) =>
    <ListItem button onClick={this.showLoadType(id)} key={id}>
      <ListItemIcon>
        <LoadTypeIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editLoadType(id)}><EditIcon/></IconButton>
        <IconButton onClick={this.deleteLoadType(id)}><DeleteIcon/></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Load Types</Typography>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.loadTypes.map(this.renderLoadTypeListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <FABFixed color='primary' onClick={() => this.props.history.push('/load_types/new')}>
          <AddIcon/>
        </FABFixed>
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  withPermissions(List.requiredPermissions, {
    unmountOnChange: true,
    checkPermissions: (permissions) => {
      return permissions.system.manageLoadTypes
    }
  }),
  connect(({loadTypes}) => loadTypes)
)(List)