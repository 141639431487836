import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ParentEventActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

export class Show extends Component{

  constructor(props){
    super(props)
    ParentEventActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id, {include: 'programme'} )
  }

  dependenciesMet(){
    return !!this.parentEvent.id
  }

  get id(){
    return this.props.match.params.id
  }

  get parentEvent(){
    return this.props.parentEvent
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>ParentEvent - {this.parentEvent.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/parent_events/${this.parentEvent.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell variant='head'>Start</TableCell>
                <TableCell>{String(this.parentEvent.start)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>End</TableCell>
                <TableCell>{String(this.parentEvent.end)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Target kw</TableCell>
                <TableCell>{String(this.parentEvent.targetKw)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Mwh price</TableCell>
                <TableCell>{String(this.parentEvent.mwhPrice)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Offer deadline</TableCell>
                <TableCell>{String(this.parentEvent.offerDeadline)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Programme</TableCell>
                <TableCell>{String(this.parentEvent.programme.name)}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
}


export default connect(({parentEvents}) => parentEvents)(Dependent({loader: true, clearOnLoad: true})(Show))