import React               from 'react'
import { CenteredCard } from 'components'
import CardContent from '@material-ui/core/CardContent';
import { TokenActions, SnackbarActions } from 'actionsets'

const StatusPageMixin = superclass => class extends superclass{

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  goBack = steps => {
    if(steps){
      return () => this.props.history.go(steps)
    }
    this.props.history.go(this.props.match.isExact ? -2 : -1)
  }

  goToRoot = () => {
    this.props.history.push('/')
  }

  render = () =>
    <CenteredCard centerVertically={true}>
      <CardContent>
        {this.body()}
      </CardContent>
    </CenteredCard>
}

export default StatusPageMixin