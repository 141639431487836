import React, { Component } from 'react'
import WidgetContainer from './WidgetContainer'
import { compose, formatLocaleNumeric } from 'utils'
import { connect } from 'react-redux'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import PastYearIcon from '@material-ui/icons/CalendarToday'
import AllTimeIcon from '@material-ui/icons/AccessTime'
import {lighten} from '@material-ui/core/styles/colorManipulator'

export class EarningsWidget extends Component{

  state = {
    mode: 'pastYear'
  }

  handleChangeMode = mode => () => {
    this.setState({mode})
  }

  render = () => {
    return (
      <WidgetContainer className={this.props.classes.widgetContainer}>
        <p className={this.props.classes.value}><span>$</span>{formatLocaleNumeric(this.props.totalPayments[this.state.mode])}</p>
        <small>Total Payments for Demand Response events {this.state.mode === 'pastYear' ? 'in the past year' : 'across all time'}</small>
        <div className={this.props.classes.toggleButtons}>
          <Tooltip title="Past Year">
            <Button className={this.props.classes({disabled: this.state.mode === 'pastYear'})} onClick={this.handleChangeMode('pastYear')}>
              <PastYearIcon/>
            </Button>
          </Tooltip>
          <Tooltip title="All Time">
            <Button className={this.props.classes({disabled: this.state.mode === 'allTime'})} onClick={this.handleChangeMode('allTime')}>
              <AllTimeIcon/>
            </Button>
          </Tooltip>
        </div>
      </WidgetContainer>
    )
  }
}

const styles = theme => ({
  toggleButtons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    lineHeight: '0px',
    '& > button': {
      color: '#000',
      padding: 0,
      minWidth: 30,
      minHeight: 23,
      background: theme.palette.primary.light,
      borderRadius: 0,
      '& svg': {
        fontSize: 15
      },
      '&:hover':{
        background: lighten(theme.palette.primary.light, 0.25),
      },
      '&$disabled': {
        background: lighten(theme.palette.primary.light, 0.5)
      },
    }
  },
  disabled: {},
  value: {
    fontSize: 60,
    textAlign: 'center',
    margin: 0,
    lineHeight: 1,
    '& span': {
      opacity: '0.8',
    }
  },
  widgetContainer: {
    textAlign: 'center',
    width: '100%',
    margin: '5px 0',
    justifyContent: 'center'
  }
})
export default compose(
  connect(),
  withStyles(styles)
)(EarningsWidget)