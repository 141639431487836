import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { DateTimePickerTabs } from '@material-ui/pickers/DateTimePicker/DateTimePickerTabs'
import { MuiPickersContext } from '@material-ui/pickers'
import { TextField, Typography } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import { createAutoCorrectedDatePipe } from 'text-mask-addons'
import withStyles from 'styles'
import { compose } from 'utils'

const TextMaskDate = (props) => {
  const { inputRef, ...other } = props;
  const pipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar={'_'}
      showMask
      keepCharPositions
      pipe = { pipe }
    />
  );
}
TextMaskDate.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const TextMaskHours = (props) => {
  const { inputRef, ...other } = props;
  const pipe = (conformedValue, config) => {
    const number = Number(conformedValue)
    if(!isNaN(number) && !(number >= 0 && number <=23)) {
      return false
    } else {
      return conformedValue
    }
  }

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/]}
      placeholderChar={'_'}
      showMask
      keepCharPositions
      pipe = { pipe }
    />
  );
}
TextMaskHours.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const TextMaskMinutes = (props) => {
  const { inputRef, ...other } = props;
  const pipe = (conformedValue, config) => {
    const number = Number(conformedValue)
    if(!isNaN(number) && !(number >= 0 && number <=59)) {
      return false
    } else {
      return conformedValue
    }
  }

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/]}
      placeholderChar={'_'}
      showMask
      keepCharPositions
      pipe = { pipe }
    />
  );
}
TextMaskMinutes.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const DateTimePickerToolbar = (props) => {
  const {
    ampm,
    date,
    dateRangeIcon,
    onChange,
    openView,
    setOpenView,
    timeIcon,
  } = props;
  const utils = React.useContext(MuiPickersContext);
  const [dateText, setDateText] = useState(null)
  const [hourText, setHourText] = useState(null)
  const [minuteText, setMinuteText] = useState(null)

  const handleDateChanged = ({target: { value }}) => {
    const newDate = utils.parse(value, 'DD/MM/YYYY')
    setDateText(value)
    if(newDate.isValid()) {
      setDateText(null)
      onChange(utils.mergeDateAndTime(newDate, date))
    }
  }

  const handleHoursChanged = ({target: { value }}) => {
    const number = Number(value)
    setHourText(value)
    if(!isNaN(number)) {
      let updatedDate = utils.setHours(date, value)
      if(updatedDate.isValid()) {
        setHourText(null)
        onChange(updatedDate)
      }
    }
  }

  const handleMinutesChanged = ({target: { value }}) => {
    const number = Number(value)
    setMinuteText(value)
    if(!isNaN(number)) {
      let updatedDate = utils.setMinutes(date, value)
      if(updatedDate.isValid()) {
        setMinuteText(null)
        onChange(updatedDate)
      }
    }
  }

  const getDateText = () => {
    if(dateText !== null) {
      return dateText
    }
    return utils.format(date, 'DD/MM/YYYY')
  }

  const getHourText = () => {
    if(hourText !== null) {
      return hourText
    }
    return utils.getHourText(date, ampm)
  }

  const getMinuteText = () => {
    if(minuteText !== null) {
      return minuteText
    }
    return utils.getMinuteText(date)
  }

  return (
    <React.Fragment>
      <div className={props.classes.toolbar}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <TextField id="hours" label="Date" variant="outlined" size="small"
            inputProps={{
              size: 10,
              onFocus: ({ target }) => {
                target.select()
                setOpenView('date')
              }
            }}
            //eslint-disable-next-line
            InputProps={{
              inputComponent: TextMaskDate
            }}
            onChange={ handleDateChanged }
            value={getDateText()}
          />
          <div style={{
            display: 'flex'
          }}>
            <TextField id="hours" label="Hours" variant="outlined" size="small"
              inputProps={{
                size: 4,
                onFocus: () => { setOpenView('hours') }
              }}
              //eslint-disable-next-line
              InputProps={{
                inputComponent: TextMaskHours
              }}
              onChange={ handleHoursChanged }
              value={getHourText()}
            />
            <Typography variant="h4">:</Typography>
            <TextField id="minutes" label="Minutes" variant="outlined" size="small"
              inputProps={{
                size: 4,
                onFocus: () => { setOpenView('minutes') }
              }}
              //eslint-disable-next-line
              InputProps={{
                inputComponent: TextMaskMinutes
              }}
              onChange={ handleMinutesChanged }
              value={getMinuteText()}
            />
          </div>
        </div>
      </div>
      <DateTimePickerTabs
        dateRangeIcon={dateRangeIcon}
        timeIcon={timeIcon}
        view={openView}
        onChange={setOpenView}
      />
    </React.Fragment>
  )
}

const styles = theme =>{
  return ({
    toolbar: {
      paddingTop: 10,
      paddingLeft: 16,
      paddingRight: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#0000004d'
    },
  })
}

export default compose(
  withStyles(styles)
)(DateTimePickerToolbar)