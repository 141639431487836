import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const LoadTypes = ProtectedJsonAPI.extend(
  '/load_types',
  {
    index:   endpoint => endpoint,
    create:  endpoint => endpoint.method('post'),
    update:  endpoint => endpoint.method('put').path(({id}) => id),
    destroy: endpoint => endpoint.method('delete').path(({id}) => id),
    show:    endpoint => endpoint.path(({id}) => id)
  }
)