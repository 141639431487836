import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as API from 'api'
import withStyles from 'styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import { compose } from 'utils'
import { MultiAutoSuggest } from 'components'
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

let restrictedTagTypes = []


export class Tagger extends Component{

  constructor(props){
    super(props)
    if(!restrictedTagTypes.length){
      API.TagTypes.index({
        options: {
          filter:  {
            includeRestricted: true
          },
        },
        count: 1000
      }).then(({data: tagTypes}) => {
        restrictedTagTypes = tagTypes.filter(({reserved}) => reserved).map(({name}) => name)
      })
    }
  }

  static propTypes = {
    onChange: PropTypes.func,
    classes: PropTypes.func,
    value: PropTypes.object
  }

  static defaultProps = {
    classes: () => {},
    value: {},
    onChange: () => false
  }

  state = {
    tagTypes: [],
    includeRestricted: false,
    canEditRestricted: false
  }

  componentDidMount = () => {
    this.loadTagTypes()
  }

  loadTagTypes = async () => {
    const {data: tagTypes} = await API.TagTypes.index({
      options: {
        filter: { ...this.props.includeRestricted && {includeRestricted: true } },
        page: this.state.page,
        order: 'name',
        count: 1000,
        fields: {tagTypes: 'name,tagValues', tagValues: 'value'},
        include: 'tagValues'
      }
    })
    this.setState({tagTypes})
  }

  get availableTagTypes(){
    return this.state.tagTypes.filter(({name}) => !this.props.value[name])
  }

  get groupedTagValues(){
    return Object.entries(this.props.value || {}).sort(([nameA], [nameB]) => {
      if(nameA > nameB) return 1
      if(nameA < nameB) return -1
      return 0
    })
  }

  canEditType = typeName =>
    this.props.canEditRestricted || !restrictedTagTypes.includes(typeName)

  handleTagValuesChanged = value => ({target: { value: values}}) => {
    this.props.onChange({
      target: {
        value: {
          ...this.props.value,
          [value]: values
        }
      }
    })
  }

  handleSuggestionsFetchRequested = tagTypeName => (searchText, callback) => {
    const selectedType    = this.state.tagTypes.find(tt => tt.name === tagTypeName)
    const existingValues  = this.props.value[tagTypeName] || []
    const availableValues = selectedType.tagValues.map(({value}) => value).concat(searchText.trim() ? [searchText] : [])
    const filteredValues  = availableValues.filter(tagValue => !existingValues.some(existingValue => existingValue.trim().toLowerCase() === tagValue.trim().toLowerCase()))
                                           .filter(tagValue => tagValue.toLowerCase().trim().match(searchText.toLowerCase().trim()))
    callback(filteredValues.filter((value, index, self) => self.indexOf(value) === index))
  }

  handleAddNewtagType = ({target: { value: { name }}}) => {
    this.props.onChange({
      target: {
        value: {
          ...this.props.value,
          [name]: []
        }
      }
    })
  }

  handleDeleteTagType = tagType => () => {
    const value = {...this.props.value}
    delete value[tagType]
    this.props.onChange({
      target: { value }
    })
  }

  renderTagValue = value => value

  renderTagTypeSelectButton = tagType =>
    <MenuItem key={tagType} value={tagType}>{tagType.name}</MenuItem>

  renderTagTypeValues = ([key, values]) =>
    <FormGroup classes={{row: this.props.classes.tagRow}} row key={key}>
      <MultiAutoSuggest
        classNames={{
          container: this.props.classes.tagRowSuggest,
          inputContainer: this.props.classes.tagRowSuggest
        }}
        readOnly={!this.canEditType(key)}
        label={key}
        deleteOnClick
        selectOnEnter
        debounceWait={1}
        showClearAll={false}
        showLoader={false}
        labelProvider={this.renderTagValue}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested(key)}
        onChange={this.handleTagValuesChanged(key)}
        value={values}
      />
      {
        this.canEditType(key) &&
        <IconButton onClick={this.handleDeleteTagType(key)}>
          <DeleteIcon/>
        </IconButton>
      }
    </FormGroup>

  render = () =>
    <div>
      {this.groupedTagValues.map(this.renderTagTypeValues)}
      {
        !!this.availableTagTypes.length &&
        <FormControl fullWidth>
          <InputLabel htmlFor="controlled-status-select">Add a Tag Type</InputLabel>
          <Select
            fullWidth
            label='Select Tag Type'
            placeholder='Add a tag type'
            onChange={this.handleAddNewtagType}
            value=''
            inputProps={{
              name: 'status',
              id: 'controlled-status-select',
            }}>
            {this.availableTagTypes.map(this.renderTagTypeSelectButton)}
          </Select>
        </FormControl>
      }
    </div>

}

const styles = theme => ({
  addButton: {
    float: 'right'
  },
  tagRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end'
  },
  tagRowSuggest: {
    flex: 1
  }
})


export default compose(
  withStyles(styles)
)(Tagger)