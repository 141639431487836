import React, { Component } from 'react'
import { compose, humanize } from 'utils'
import withStyles from 'styles'
import * as API from 'api'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse';
import { LabeledSelect, FormContext } from 'components'
import MenuItem from '@material-ui/core/MenuItem'
import AutoDRTypes from 'constants/AutoDRTypes'

export class ProgrammeSignalMappings extends Component{

  state = { deviceSignals: {}, visible: {}}

  componentDidMount = async () => {
    const { data: { id, type, ...deviceSignals }} = await API.Devices.deviceSignals()
    this.setState({deviceSignals})
  }

  get signalMappings(){
    return this.props.context.signalMappings
  }

  isTypeEnabled = type => {
    return this.state.visible[type] || Object.values(this.signalMappings[type] || {}).some(
      deviceClasses => Object.values(deviceClasses).some(
        levels => Object.values(levels || {}).some(x => x)
      )
    )
  }

  handleToggleMapping = type => () => {
    const isVisible = this.isTypeEnabled(type)
    if(isVisible){
      this.props.onChange({
        ...this.props.context,
        signalMappings: {
          ...this.props.context.signalMappings,
          [type]: {}
        }
      })
    }
    this.setState({
      visible: {
        ...this.state.visible, [type]: !isVisible
      }
    })
  }

  handleDeviceMode = (type, level) => {
    const labels = {
      'future_point' :
      {
        '0' : 'DRM0',
        '1' : 'DRM1',
        '2' : 'DRM2',
        '3' : 'DRM3',
        '4' : 'DRM4',
        '5' : 'DRM5',
        '6' : 'DRM6',
        '7' : 'DRM7',
        '8' : 'DRM8'
      },
      'oadr' :
      {
        '0' : 'DRM0',
        '1' : 'DRM1',
        '2' : 'DRM2',
        '3' : 'DRM3'
      },
      'ambi' :
      {
        '0' : 'DRM0',
        '1' : 'DRM1',
        '2' : 'DRM2',
        '3' : 'DRM3'
      }
    }

    if (labels[type]) {
      return (labels[type] && labels[type][level] && `${labels[type][level]}`) || level
    }
  }

  deviceTypeName = type => {
    if(AutoDRTypes[type])
      return AutoDRTypes[type]
    else
      return humanize(type)
  }

  renderDeviceClassSelects = type => ([deviceClass, levels]) =>
    <div className={this.props.classes.deviceClassSelectContainer} key={`${type}/${deviceClass}`}>
      {deviceClass !== 'default' ? <Typography variant='h6'>{humanize(deviceClass)}</Typography> : false}
      <div className={this.props.classes.deviceClassSelects}>
        {
          ['low', 'medium', 'high'].map(level =>
            <LabeledSelect member={`signalMappings.${type}.${deviceClass}.${level}`} label={humanize(level)} key={level}>
              <MenuItem value={undefined}>None</MenuItem>
              {levels.map(level =>
                <MenuItem
                  key={String(level)}
                  value={level}
                  >
                  {this.handleDeviceMode(type, level)}
                </MenuItem>
              )}
            </LabeledSelect>
          )
        }
      </div>
    </div>

  renderMappingTable = ([type, deviceClasses]) =>
    <div className={this.props.classes.mappingTable} key={type}>
      <div className={this.props.classes.mappingHeader}>
        <Typography color="inherit" variant="h6">
          {this.deviceTypeName(type)}
        </Typography>
        <FormControlLabel
          className={this.props.classes.enabledCheckbox}
          control={
            <Checkbox type='checkbox' checked={this.isTypeEnabled(type)} onChange={this.handleToggleMapping(type)}/>
          }
          label="Enabled"
        />
      </div>
       <Collapse in={this.isTypeEnabled(type)}>
          {Object.entries(deviceClasses).map(this.renderDeviceClassSelects(type))}
      </Collapse>
    </div>

  render = () => {
    return (
      <FormContext context={this.props.context} onChange={this.props.onChange}>
        <div className={this.props.classes.container}>
          {Object.entries(this.state.deviceSignals).map(this.renderMappingTable)}
        </div>
      </FormContext>
    )
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    padding: 10,
  },
  mappingHeader: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
  },
  deviceClassSelects: {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      flex: '1 0 auto'
    }
  },
  deviceClassSelectContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > h6': {
      fontSize: '1rem'
    }
  },
  mappingTable: {
    flex: '0 1 auto'
  },
  enabledCheckbox: {
    float: 'right'
  }
})

export default compose(
  withStyles(styles),
)(ProgrammeSignalMappings)
