import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import withStyles from 'styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {
  LabeledSelect,
  LivenessIndicator
} from 'components'
import MenuItem from '@material-ui/core/MenuItem'
import { underscore } from 'utils/StringUtils'
import Checkbox from '@material-ui/core/Checkbox'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

export class OfferInput extends Component{

  constructor(props){
    super(props)
    this.validationButtonRef     = React.createRef()
    this.validationFormRef       = React.createRef()
    this.offerKwRef              = React.createRef()
    this.offerPriceRef           = React.createRef()
    this.nonAutoDRKwRef          = React.createRef()
  }

  static getDerivedStateFromProps({offer, childEvent}, state){
    if(offer !== state.offer || childEvent !== state.childEvent) {
      return  {
        ...state,
        offer: offer,
        childEvent: childEvent,
        deviceCapacities: OfferInput.computeDeviceCapacities(
          childEvent.registration.targetedDevices,
          childEvent.programme.signalMappings
        ),
        status: offer.status,
        offerPrice: offer.offerPrice || childEvent.mwhPrice,
        offerKw: offer.offerKw || childEvent.targetKw,
        drLevel: offer.drLevel,
        nonAutoDRKw: offer.nonAutoDRKw,
        autoDREventsByDeviceId: OfferInput.computeAutoDREventsByDeviceId(offer)
      }
    }
    return null
  }

  static computeDeviceCapacities(devices, signalMappings) {
    const drLevels = ['none', 'low', 'medium', 'high']
    const capacitiesByDrLevel = drLevels.reduce((accumulator, drLevel) => {
      const devicesAndCapacities = devices.reduce((accumulator, device) => {
        let signal = OfferInput.deviceSignalForLevel(device, signalMappings, drLevel)
        if(signal) {
          let kwAmount = device.signalCapacities[signal.value]
          return {...accumulator, [device.id]: { signal, kwAmount }}
        }
        return accumulator
      }, {})
      return {...accumulator, [drLevel]: devicesAndCapacities}
    }, {})

    return capacitiesByDrLevel
  }

  static deviceSignalForLevel(device, signalMappings, drLevel) {
    // TODO: compute deviceType and deviceClass on the server
    let deviceType = underscore(device.type.split('::')[0])
    let deviceClass = device.dredsClass || 'default'
    if(signalMappings[deviceType]
      && signalMappings[deviceType][deviceClass]
      && signalMappings[deviceType][deviceClass][drLevel])
    {
      const signalValue = signalMappings[deviceType][deviceClass][drLevel]
      return device.deviceSignals.find(({ value }) => value === signalValue )
    }
    return null
  }

  static computeAutoDREventsByDeviceId(offer) {
    const autoDREvents = offer.autoDREvents || {}
    const byDeviceId = autoDREvents.reduce((accumulator, autoDREvent) => {
      return {...accumulator, [autoDREvent.device.id]: autoDREvent}
    }, {})

    return byDeviceId
  }

  state = {
    dirty: false
  }

  get eventDuration(){
    return moment(this.props.eventEnd).diff(moment(this.props.eventStart), 'minutes')/60
  }

  get offerValue(){
    return this.state.status !== 'opted_out' ? (this.eventDuration * this.offerPrice * this.offerMw).toFixed(2).replace('.00','') : 0
  }

  get offerPrice(){
    return this.state.offerPrice
  }

  get offerKw(){
    let value = parseFloat(this.state.offerKw) || 0
    if(this.props.locked) {
      return value
    }

    if(this.canOfferAutoDr) {
      value = this.offeredAutoDr() + this.nonAutoDRKw
    }
    return value
  }

  get offerMw(){
    return this.offerKw / 1000.0
  }

  get nonAutoDRKw(){
    return parseFloat(this.state.nonAutoDRKw) || 0.0
  }

  get programme(){
    return this.props.childEvent.programme
  }

  get targetedDevices(){
    return this.props.childEvent.registration.targetedDevices
  }

  get canOfferAutoDr(){
    return !!this.programme.autoDR && this.targetedDevices.length > 0
  }

  get signalMappings(){
    return this.programme.signalMappings
  }

  get selectableDevices(){
    return this.targetedDevices.filter((device) => {
      return this.deviceCapacityForLevel(device, this.state.drLevel)
    })
  }

  get allDevicesChecked(){
    return this.selectableDevices.every((device) => {
      const autoDREvent = this.state.autoDREventsByDeviceId[device.id]
      return autoDREvent && !autoDREvent._destroy
    }) && Object.values(this.state.autoDREventsByDeviceId).some((autoDREvent) => autoDREvent && !autoDREvent._destroy)
  }

  offeredAutoDr(drLevel = this.state.drLevel){
    const autoDREvents = Object.values(this.state.autoDREventsByDeviceId).filter( event => !!event)
    return autoDREvents.reduce(
      (accumulator, {device, estimatedKwAmount, _destroy}) => {
        if(_destroy) {
          return accumulator
        }

        const capacity = (this.props.locked) ? parseFloat(estimatedKwAmount) : (this.deviceCapacityForLevel(device, drLevel) || 0.0)
        return accumulator + capacity
      }, 0.0)
  }

  get drLevel(){
    return this.state.drLevel
  }

  deviceCapacityForLevel = (device, drLevel) => {
    let value = null
    if(drLevel && this.state.deviceCapacities[drLevel][device.id]) {
      value = this.state.deviceCapacities[drLevel][device.id].kwAmount
    }
    return parseFloat(value)
  }

  handleOpt = status => async () => {
    if(!this.validate()){
      return
    }
    const autoDrEventsAttributes = Object.values(this.state.autoDREventsByDeviceId).filter( event => !!event)
    .map(({id, device, offer, _destroy}) => {
      const deviceSignal = OfferInput.deviceSignalForLevel(device, this.signalMappings, this.drLevel)
      return {
        ...(id) && {id: id},
        ...(!_destroy) && {
          device_id: device.id,
          ...(this.state.offer.id) && {offer_id: this.state.offer.id},
          device_signal_id: deviceSignal.id,
          estimated_kw_amount: this.deviceCapacityForLevel(device, this.drLevel),
        },
        _destroy
      }
    })

    try{
      await this.props.onChange({
        target: {
          value: {
            id: this.props.offer.id,
            ...{
              status: status,
              offerPrice: this.offerPrice,
              offerKw: this.offerKw,
              drLevel: this.drLevel,
              nonAutoDRKw: this.nonAutoDRKw,
              autoDrEventsAttributes
            }
          }
        }
      })
    }catch(err){}
  }

  handleChange = field => ({target: { value }}) => {
    let nextState = {
      status: undefined,
      dirty: true,
      [field]: value
    }

    if(this.props.childEvent.targetKw - this.offeredAutoDr(nextState.drLevel) <= 0) {
      nextState = {...nextState, nonAutoDRKw: 0.0}
    }

    if(field === 'drLevel') {
      nextState = {...nextState, autoDREventsByDeviceId: this.computeNextAutoDREventsForLevel(value)}
    }

    this.setState(nextState)
  }

  computeNextAutoDREventsForLevel = (nextDrLevel) => {
    return Object.entries(this.state.autoDREventsByDeviceId).reduce((accumulator, [deviceId, autoDREvent]) => {
      if(!autoDREvent) {
        return accumulator
      } else {
        let {id, device} = autoDREvent
        let deviceSignal = OfferInput.deviceSignalForLevel(device, this.signalMappings, nextDrLevel)
        if(deviceSignal) {
          accumulator[deviceId] = autoDREvent
        } else if(id) {
          accumulator[deviceId] = {...autoDREvent, _destroy: true}
        }

        return accumulator
      }
    }, {})
  }

  deviceOptChangedHandler = (device) => (event, checked) => {
    const autoDREvent = this.state.autoDREventsByDeviceId[device.id] || { device: device, offer: this.state.offer }
    if(autoDREvent.id === undefined && !checked) {
      this.setState({
        autoDREventsByDeviceId: {
          ...this.state.autoDREventsByDeviceId,
          [device.id]: undefined
        }
      })
    } else {
      this.setState({
        autoDREventsByDeviceId: {
          ...this.state.autoDREventsByDeviceId,
          [device.id]: {
            ...autoDREvent,
            _destroy: !checked
          }
        }
      })
    }
  }

  handleSelectAllDevices = (event, checked) => {
    const nextAutoDREvents = {...this.state.autoDREventsByDeviceId}
    this.selectableDevices.forEach((device) => {
      const deviceId = device.id
      if(checked) {
        if(nextAutoDREvents[deviceId]) {
          nextAutoDREvents[deviceId] = {...nextAutoDREvents[deviceId], _destroy: false}
        } else {
          nextAutoDREvents[deviceId] = { device: device, offer: this.state.offer }
        }
      } else {
        if(nextAutoDREvents[deviceId] && nextAutoDREvents[deviceId].id) {
          nextAutoDREvents[deviceId] = {...nextAutoDREvents[deviceId], _destroy: true}
        } else if(nextAutoDREvents[deviceId]) {
          nextAutoDREvents[deviceId] = undefined
        }
      }
    })
    this.setState({autoDREventsByDeviceId: nextAutoDREvents})
  }

  validate = () => {
    if(!this.offerKwRef.current && this.offerPriceRef.current){
      return false
    }

    const mustBeValidFields = [
      this.offerPriceRef.current,
      this.offerKwRef.current,
      this.nonAutoDRKwRef.current,
    ].filter( current => !!current)

    if (mustBeValidFields.every( field => field.checkValidity())) {
      return true
    }

    this.validationButtonRef.current.click()
    return false
  }

  renderDeviceLine = (device, capacity) => {
    return (
      <div className={this.props.classes.device} key={device.id}>
        <div style={ {display: 'flex', justifyContent: 'flex-start'}}>
          <LivenessIndicator style={{ top: 16, left: 0 }} live={device.online} lastHeartbeatAt={device.lastHeartbeatAt}/>
          <Typography style={{ marginLeft: 10, marginTop: 12}} variant='subtitle1'>{device.name}</Typography>
        </div>
        <div style={ {display: 'flex', justifyContent: 'flex-end'}}>
          <Typography style={{ marginTop: '13px'}} variant='body1'>{(!isNaN(capacity)) ? `${capacity} kW` : ''}</Typography>
          { !this.props.locked &&
            <Checkbox checked={ !!this.state.autoDREventsByDeviceId[device.id] && !this.state.autoDREventsByDeviceId[device.id]._destroy }
                      onChange={ this.deviceOptChangedHandler(device) } disabled={!capacity}/>
          }
        </div>
      </div>
    )
  }

  renderAutoDREvents = () => {
    return (
      <Fragment>
      {
        Object.values(this.state.autoDREventsByDeviceId).filter( event => !!event).map(({device, estimatedKwAmount}) => {
          return this.renderDeviceLine(device, parseFloat(estimatedKwAmount))
        })
      }
      </Fragment>
    )
  }

  renderTargetedDevices = () => {
    if(!this.canOfferAutoDr || !this.state.drLevel ) {
      return null
    }

    return (
      (this.props.locked) ?
        this.renderAutoDREvents() :
        <Fragment>
          <div className={this.props.classes.device} key='all'>
            <div style={ {display: 'flex', justifyContent: 'flex-start'}}/>
            <div style={ {display: 'flex', justifyContent: 'flex-end'}}>
              <Typography style={{ marginTop: '13px'}} variant='body1'>Select all</Typography>
              <Checkbox checked={this.allDevicesChecked} onChange={this.handleSelectAllDevices}/>
            </div>
          </div>
          {
            this.targetedDevices.map((device) => {
              const deviceCapacity = this.deviceCapacityForLevel(device, this.state.drLevel)
              return this.renderDeviceLine(device, deviceCapacity)
            })
          }
        </Fragment>
    )
  }

  renderOfferKw = () => {
    return (
      (this.props.locked || this.canOfferAutoDr) ?
      <TextField
        label="Offered Amount"
        placeholder="Offered kW"
        value={this.offerKw}
        type='number'
        inputRef={this.offerKwRef}
        InputProps={
          {
            inputProps: {
              min: 1,
              max: this.props.childEvent.targetKw,
            },
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
          }
        }
        className={this.props.classes.readOnlyTextField}
        readOnly={true}
      /> :
      <TextField
        onChange={this.handleChange('offerKw')}
        label="Offered Amount"
        placeholder="Offered kW"
        defaultValue={this.offerKw}
        type='number'
        inputRef={this.offerKwRef}
        InputProps={
          {
            inputProps: {
              min: 1,
              max: this.props.childEvent.targetKw,
            },
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
          }
        }
      />
    )
  }

  render = () => {
    const isOptedOut = (this.state.status === 'opted_out' || (this.props.locked && this.state.status !== 'opted_in'))
    const isOptedIn = (this.state.status === 'opted_in')

    return (
      <div className={this.props.classes.container}>
        <div className={this.props.classes.requested}>
          <span className='label'>Requested</span>
          <span className='value'>{this.props.childEvent.targetKw}kW <strong>@</strong> ${this.props.childEvent.mwhPrice}/MWh</span>
        </div>
        <form ref={this.validationFormRef} onSubmit={event => event.preventDefault()}>
          <button style={{display: 'none'}} ref={this.validationButtonRef} type='submit' onClick={this.validate}/>
          {
            this.canOfferAutoDr &&
            <Fragment>
              <div className={this.props.classes.response}>
                <LabeledSelect fullWidth style={{maxWidth: '100%'}} label='Auto DR Level' value={this.state.drLevel} onChange={this.handleChange('drLevel')} disabled={this.props.locked}>
                  <MenuItem value={'none'}>None</MenuItem>
                  <MenuItem value={'low'}>Low</MenuItem>
                  <MenuItem value={'medium'}>Medium</MenuItem>
                  <MenuItem value={'high'}>High</MenuItem>
                </LabeledSelect>
              </div>
              <div className={this.props.classes.response}>
                <TextField label='Auto DR Offered' value={this.offeredAutoDr()}
                           InputProps={
                             {
                               endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                             }
                           }
                           disabled={ true }
                />
                {
                  ( (this.props.locked && this.programme.autoDR)
                    || this.props.childEvent.targetKw > this.offeredAutoDr()) &&
                  <TextField
                    onChange={this.handleChange('nonAutoDRKw')}
                    label="Non Auto DR Amount"
                    placeholder="Non Auto DR Amount"
                    defaultValue={this.nonAutoDRKw}
                    type='number'
                    inputRef={this.nonAutoDRKwRef}
                    InputProps={
                      {
                        inputProps: {
                          min: 0,
                          max: this.props.childEvent.targetKw - this.offeredAutoDr(),
                        },
                        endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                      }
                    }
                    disabled={this.props.locked}
                  />
                }
              </div>
            </Fragment>
          }
          <div className={this.props.classes.response}>
            { this.renderOfferKw() }
            <TextField
              onChange={this.handleChange('offerPrice')}
              label="Offered Price"
              placeholder="Offered Price"
              defaultValue={this.offerPrice}
              type='number'
              inputRef={this.offerPriceRef}
              InputProps={
                {
                  inputProps: {
                    min: 0,
                    max: this.props.childEvent.mwhPrice,
                  },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">/MWh</InputAdornment>,
                }
              }
              disabled={this.props.locked}
            />
            <div className={this.props.classes.offerValue}>
              Offer Value: ${this.offerValue}
            </div>
          </div>
          { this.renderTargetedDevices() }
        </form>
        {
          !this.props.locked &&
          <div className={this.props.classes.buttons}>
            <Button
              variant='contained'
              className={
                this.props.classes({
                  optOut: isOptedOut,
                  disabled: this.props.locked
                })
              }
              onClick={this.handleOpt('opted_out')}
              startIcon={isOptedOut ? <ThumbDownIcon/> : null}
            >
              {isOptedOut ? 'Opted Out' : 'Opt Out'}
            </Button>
            <Button
              variant='contained'
              className={
                this.props.classes({
                  optIn: isOptedIn,
                  disabled: this.props.locked
                })
              }
              onClick={this.handleOpt('opted_in')}
              startIcon={isOptedIn ? <ThumbUpIcon/> : null}
            >
              {isOptedIn ? 'Opted In' : 'Opt In'}
            </Button>
          </div>
        }
      </div>
    )
  }
}

const styles = theme => ({
  disabled: {
    pointerEvents: 'none',
    opacity: 0.7
  },
  optOut: {
    background: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.error.dark
    }
  },
  optIn: {
    background: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      background: theme.palette.secondary.dark
    }  },
  container: {
    overflow: 'hidden',
    margin: '5px -5px -5px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  requested: {
    flexDirection: 'column',
    display: 'flex',
    padding: 15,
    margin: '0 -20px',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.3)',
    color: "#333",
    fontSize: 20,
    borderBottom: `6px solid #A1C6CA`,
    boxShadow: 'inset 1px 1px 2px 5px rgba(0, 0, 0, 0.1)',
    '& .label': {
      color: 'white',
      lineHeight: 2,
      fontSize: 13,
    },
    '& .value': {
      color: 'rgba(255,255,255,0.7)'
    }
  },
  response: {
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& input': {
      // fontSize: 20,
      textAlign: 'center',
    },
    '& > div': {
      flex: '1 0 190px',
      maxWidth: 220,
      minHeight: 60
    },
    '& > div$offerValue': {
      minHeight: 0
    }
  },
  offerValue: {
    flex: "1",
    minWidth: "100%",
    textAlign: "right",
    marginTop: "15px",
    background: "rgba(0,0,0,0.07)",
    minHeight: "0",
    padding: "10px",
    color: "rgba(255,255,255,0.5)",
    fontSize: 14,
    boxSizing: 'border-box'
  },
  device: {
    paddingLeft: 15,
    paddingRight: 15,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  buttons: {
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between'
  },
  readOnlyTextField: {
    pointerEvents: 'none',
    '& div::before': {
      borderBottomStyle: 'dotted',
    },
    '& div::after': {
      borderBottomStyle: 'dotted',
    },
    '& div': {
      color: 'rgba(255,255,255,0.5)',
      cursor: 'default',
      // borderBottomStyle: 'dotted',
    },
    '& label': {
      color: 'rgba(255,255,255,0.5)'
    },
    '& input': {
      opacity: 1,
      cursor: 'default',
    }
  }
})

export default withStyles(styles)(OfferInput)