import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyle from 'styles'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import RefreshIcon from '@material-ui/icons/Refresh'
import MenuItem from '@material-ui/core/MenuItem'

import Dependent from 'containers/shared/Dependent'
import { compose } from 'utils'
import { SystemParametersActions, NotificationActions } from 'actionsets'
import { Notifications } from './'
import { withPermissions } from 'containers/shared'
import { Select, DateTimePicker } from 'components'
import moment from 'moment'

class List extends Component {
  static requiredPermissions = () => ({
    system: ['readSystemParams', 'writeSystemParams']
  })

  constructor(props){
    super(props)
    NotificationActions.bindActions(this)
    SystemParametersActions.bindActions(this, 'systemParameters')

    this.state = {
      filter: {
        state: 'delivered',
        from: moment().subtract(1, 'month').toDate(),
        to: moment().toDate(),
      }
    }
  }

  dependsOn(){
    return Promise.all([
      this.actions.systemParameters.loadParam('quarantine'),
      this.loadNotifications()
    ])
  }

  dependenciesMet(){
    return this.props.notifications.requests.length === 0
  }

  loadNotifications = async (page = this.currentPage) => {
    const filterByState = this.state.filter.state
    const filterFrom = this.state.filter.from
    const filterTo = this.state.filter.to
    return this.actions.index({
      page,
      include: 'users,notificationDeliveries,notificationDeliveries.user',
      fields: {
        users: 'name, email, mobileNumber',
        notificationDeliveries: 'user, state, deliveredAt'
      },
      filter: { state: filterByState, from: filterFrom, to: filterTo, },
    })
  }

  get notifications() {
    return this.props.notifications.notifications
  }

  get currentPage() {
    return this.props.notifications.page || 0
  }

  get systemQuarantine() {
    return !!this.props.systemParameters.quarantine.value
  }

  handleQuarantineChanged = async (event, checked) => {
    if(!checked) {
      await Promise.resolve()
      if(!global.confirm("Are you sure you want to turn System Quarantine off?")){
        return
      }
    }
    await this.actions.systemParameters.setParam('quarantine', !!checked)
    this.loadNotifications()
  }

  handleFilterChange = which => ({target: { value }}) => {
    this.setState({filter: {...this.state.filter, [which]: value}}, this.loadNotifications)
  }

  render = () => {
    const quarantineStatusLabel = <span>System quarantine (<span style={{fontWeight: 'bold'}}>{this.systemQuarantine ? 'ON' : 'OFF'}</span>)</span>

    return (
      <Card>
        <CardContent>
          <Typography variant='h4'>System notifications</Typography>
          <div className={this.props.classes.systemParameters}>
            <FormControlLabel label={quarantineStatusLabel}
              control={
                <Switch color="primary" onChange={ this.handleQuarantineChanged } checked={ this.systemQuarantine }/>
              }/>
            <Tooltip title="Refresh" placement="bottom-end">
              <IconButton onClick={ (event) => {
                event.stopPropagation()
                this.loadNotifications()
              } }>
                <RefreshIcon/>
              </IconButton>
            </Tooltip>
          </div>
          <div className={this.props.classes.filters}>
            <Select label='State' value={this.state.filter.state} onChange={this.handleFilterChange('state')}>
              <MenuItem value="quarantined">Quarantined</MenuItem>
              <MenuItem value="enqueued">Queued</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
            </Select>
            <DateTimePicker
              classes={this.props.classes('dateFilter', 'formControl')}
              minutesStep={30}
              member='beginning'
              label='Show from'
              value={this.state.filter.from}
              onChange={this.handleFilterChange('from')}
            />
            <DateTimePicker
              classes={this.props.classes('dateFilter', 'formControl')}
              minutesStep={30}
              member='beginning'
              label='to'
              value={this.state.filter.to}
              onChange={this.handleFilterChange('to')}
            />
          </div>
          <Notifications onRequestReloadNotifications={ this.loadNotifications } filter={ this.state.filter }/>
        </CardContent>
      </Card>
    )
  }
}

const styles = theme => ({
  systemParameters: {},
  filters: {},
  formControl:{
    paddingTop: '16px',
    paddingRight: '16px',
  },
  dateFilter: {
  paddingBottom: '28px',
  display: 'flex',
  flexWrap: 'wrap',
  },
})

export default compose(
  Dependent({loader: true}),
  withPermissions(List.requiredPermissions, {
    unmountOnChange: true,
    checkPermissions: (permissions) => {
      return permissions.system.readSystemParams && permissions.system.writeSystemParams
    }
  }),
  connect(({systemParameters, notifications}) => ({systemParameters, notifications})),
  withStyle(styles),
)(List)
