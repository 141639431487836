import CrudActionSetBase from './CrudActionSetBase'
import { UserNotifications } from 'api'

const shortPendingListFilter = {
  delivery_type: 'AppDelivery',
  delivery_state: 'delivered',
  done: false
}

class UserNotificationActionSet extends CrudActionSetBase('userNotification'){
  static initialState = {
    shortPendingList: [],
    shortPendingListCount: ''
  }

  static fetchShortPendingList(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((size = 0) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: UserNotifications.index({
          options: {
            page: { number: 0, size },
            filter: shortPendingListFilter
          }
        })
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, shortPendingList: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, fetchShortPendingList: error }
        return {...state, requests, errors }
      }
    })
  }

  static updateShortPendingListCount(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: UserNotifications.index({
          options: {
            page: { number: 0, size: 0 },
            filter: shortPendingListFilter
          }
        })
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { meta: { count } } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, shortPendingListCount: count }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, updateShortPendingListCount: error }
        return {...state, requests, errors }
      }
    })
  }

  static markDone(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator((notification, done) => {
      return {
        type: constants.ACTION,
        promise: UserNotifications.update({id: notification.id, done})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))

        const shortPendingList = state.shortPendingList.map((notification) => (notification.id === data.id) ? data : notification)
        const userNotifications = state.userNotifications.map((notification) => (notification.id === data.id) ? data : notification)

        return {...state, requests, userNotifications, shortPendingList }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, markDone: error }
        return {...state, requests, errors }
      }
    })
  }

  static markAllDone(creator,reducer,constants) {
    constants.defineRequestConstants()

    creator((notifications, done) => {
      return {
        type: constants.ACTION,
        promise: UserNotifications.bulkSave(notifications.map(n => ({...n, done})))
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, {request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const done = data.reduce((m,n) => ({...m, [n.id]:n.done}),{})
        const [userNotifications,shortPendingList] = ['userNotifications','shortPendingList'].map(argname => state[argname].map(notification => (
          notification.id in done ?
            {...notification, done: done[notification.id]} :
            notification
        )))
        return {...state, requests, userNotifications, shortPendingList }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, markAllDone: error }
        return {...state, requests, errors }
      }
    })
  }
}

export default new UserNotificationActionSet()