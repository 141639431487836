import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  TaggedSearchField, Pagination
} from 'components'
import Typography from '@material-ui/core/Typography'
import { compose, Availability } from 'utils'
import withStyles from 'styles'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TabIcon from '@material-ui/icons/ExpandLess'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import AddIcon from '@material-ui/icons/Add'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Tooltip from '@material-ui/core/Tooltip'

export class EventRegistrationSelection extends Component{

  static propTypes = {
    classes: PropTypes.func,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    onFilterChanged: PropTypes.func,
    filter: PropTypes.object,
    totalPages: PropTypes.number,
    page: PropTypes.number,
    onPageSelected: PropTypes.func,
    onSelectAll: PropTypes.func,
    onAddSelected: PropTypes.func,
    isRegistrationSelected: PropTypes.func,
    onSelectRegistration: PropTypes.func,
    onAddRegistration: PropTypes.func,
    eventAvailability: PropTypes.object,
    showAvailabilityWarning: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    isRegistrationSelected: () => {},
    onAddRegistration: () => () => {},
    showAvailabilityWarning: false,
  }

  constructor(props){
    super(props)
    this.state = { selected: {} }
  }

  get haveSelectedRegistrations(){
    return Object.values(this.state.selected).some(x => x) &&
           this.props.availableRegistrations.filter(registration => !this.props.isRegistrationSelected(registration)).length > 0
  }

  get allRegistrationsSelected(){
    return this.props.availableRegistrations.every(({id}) => this.state.selected[id]) &&
           this.props.availableRegistrations.filter(registration => !this.props.isRegistrationSelected(registration)).length > 0
  }

  handleSelectRegistration = registration => () => {
    this.setState({
      selected: {
        ...this.state.selected,
        [registration.id]: !this.state.selected[registration.id]
      }
    })
  }

  handleSelectAllRegistrations = () => {
    const selected = !this.allRegistrationsSelected
    const registrationSelections = this.props.availableRegistrations.reduce((agg, reg) => ({
      ...agg, [reg.id]: selected
    }), {})
    this.setState({ selected: registrationSelections })
  }

  handleAddSelected = () => {
    this.props.onAddSelected(this.state.selected)
    this.setState({ selected: {} })
  }

  renderAvailabilityWarning = (registration) => {
    const { weeklyAvailabilityBitmask } = registration
    const registrationAvailability = Availability.fromString(weeklyAvailabilityBitmask)
    const eventAvailability = this.props.eventAvailability
    const coversEvent = (!!eventAvailability) && registrationAvailability.covers(eventAvailability)

    return (
      (!coversEvent) &&
      <div style={{paddingTop: 12}}>
        <Tooltip title="Availabilities in this registration do not cover event's window.">
          <AccessTimeIcon color="error"/>
        </Tooltip>
      </div>
    )
  }

  renderAvailableRegistrationRow = (registration, idx) => {
    const isSelected = this.props.isRegistrationSelected(registration)
    const isChecked  = !!(this.state.selected[registration.id] && !isSelected)

    return (
      <ListItem dense key={idx} className={this.props.classes('listItem', {listItemSelected: isSelected})} button>
          <ListItemText>
            <FormControl fullWidth>
              <FormControlLabel className={this.props.classes.withWideSecondaryAction}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={this.handleSelectRegistration(registration)}
                    value={`checked-${registration.id}`}
                  />
                }
                onClick={this.handleSelectRegistration(registration)}
                label={registration.name}
              />
            </FormControl>
          </ListItemText>
          {
            !isSelected &&
            <ListItemSecondaryAction className={this.props.classes.secodnaryActions}>
                <Fragment>
                  {
                    this.props.showAvailabilityWarning && this.renderAvailabilityWarning(registration)
                  }
                  <IconButton onClick={this.props.onAddRegistration(registration)}><AddIcon/></IconButton>
                </Fragment>
            </ListItemSecondaryAction>
          }

      </ListItem>
    )
  }

  render = () =>
     <Fragment>
        <section className={this.props.classes('registrationSelectionSection', {collapsed: !!this.props.collapsed})}>
          <IconButton className={this.props.classes('toggleButton')} onClick={this.props.onCollapse}>
            <TabIcon/>
          </IconButton>
          <Typography variant='h6'>
            Select Registrations
          </Typography>
          {!this.props.collapsed && <>
            <TaggedSearchField member='filters' onChange={this.props.onFilterChanged} context={this.props.filter}/>
            <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.props.onPageSelected} style={{}} linkStyle={{}}/>
            <MuiList dense>
              <ListItem className={this.props.classes.listItem} dense>
                <ListItemText>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox checked={this.allRegistrationsSelected} />
                      }
                      label="Select All"
                      onClick={this.handleSelectAllRegistrations}
                    />
                    {
                      this.haveSelectedRegistrations &&
                      <Button variant='contained' color='secondary' endIcon={<AddIcon />}
                              className={this.props.classes.addSelectedButton} onClick={this.handleAddSelected}>
                        Add Selected
                      </Button>
                    }
                  </FormControl>
                </ListItemText>
              </ListItem>
              {this.props.availableRegistrations.map(this.renderAvailableRegistrationRow)}
            </MuiList>
            <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.props.onPageSelected} style={{}} linkStyle={{}}/>
          </>}
        </section>
      </Fragment>

}


const styles = theme => ({
  addSelectedButton: {
    position: 'absolute',
    top: 3,
    right: 10
  },
  toggleButton: {
    fontSize: 11,
    float: 'right',
    margin: '-8px 0',
    transition: 'transform 500ms'
  },
  listItem: {
    padding: 0,
    paddingLeft: 5,
    margin: 0
  },
  listItemSelected: {
    '& span': {
      color: '#a2a2a2'
    }
  },
  registrationSelectionSection: {
    position: 'relative',
    flex: '1 0 0%',
    minHeight: 100,
    minWidth: 300,
    paddingRight: 15,
    transition: 'min-width 500ms'
  },
  collapsed: {
    '& $toggleButton': {
      transform: 'rotate(180deg)'
    }
  },
  secodnaryActions: {
    display: 'flex',
  },
  withWideSecondaryAction: {
    marginRight: 64
  }
})

export default compose(
  withStyles(styles)
)(EventRegistrationSelection)