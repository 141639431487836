import React, { Component, Fragment } from 'react'
import { connect }          from 'react-redux'
import withStyles from 'styles'
import { SnackbarActions } from 'actionsets'
import { compose, eventKeyMatches, KEYS, decodeJSONApiResponse, humanize } from 'utils'
import * as API from 'api'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import TableBody  from '@material-ui/core/TableBody'
import TableHead  from '@material-ui/core/TableHead'
import Table  from '@material-ui/core/Table'
import TableRow  from '@material-ui/core/TableRow'
import TableCell  from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import { connectQueryString } from 'containers/shared'
import CircularProgress from '@material-ui/core/CircularProgress'
import { flatten } from 'utils'
import CreateVen20aDialog from './CreateVen20aDialog'

export class VenDetails extends Component {
  state = {}
  static defaultProps = {
    onChange: () => {}
  }

  constructor(props){
    super(props)
    SnackbarActions.bindActions(this, 'snackbar')

    this.state = {
      register20aVenDialogOpen: false
    }
  }

  componentDidMount = () => {
    this.loadVEN()
    global.setInterval(this.loadVEN, 5000)
  }

  loadVEN = async () =>  {
    try{
      const data = await API.OpenADRCertTest.ven(this.props.filter.venID)
      this.setState({ven: decodeJSONApiResponse(data).data})
    }catch(err){
      this.actions.snackbar.show(`Couldnt load VEN with id ${this.props.filter.venID}: ${err}`)
    }
  }

  handleSearchKeyUp = event => {
    if(eventKeyMatches(event, KEYS.enter)){
      this.loadVEN()
    }
  }

  handleVenIDChange = ({target: { value: venID }}) => {
    this.props.onFilterChange({venID})
    this.props.onChange(venID)
  }

  handleZapVen = async () => {
    this.setState({pendingZapVen: true})
    try{
      await API.OpenADRCertTest.zapVen({ven_id: this.props.filter.venID})
      await this.loadVEN()
      this.actions.snackbar.show(`Success !`)
    }catch(err){
      this.actions.snackbar.show(`Couldnt zap VEN with id ${this.props.filter.venID}: ${err}`)
    } finally {
      this.setState({pendingZapVen: false})
    }
  }

  handleProvisionVen = async () => {
    this.setState({pendingProvisionVen: true})
    try{
      await API.OpenADRCertTest.provisionVen({ven_id: this.props.filter.venID})
      await this.loadVEN()
      this.actions.snackbar.show(`Success !`)
    }catch(err){
      this.actions.snackbar.show(`Couldnt provision VEN with id ${this.props.filter.venID}: ${err}`)
    } finally {
      this.setState({pendingProvisionVen: false})
    }
  }

  handleRegister20aVenDialogOpen = () => {
    this.setState({ register20aVenDialogOpen: true })
  }

  handleRegister20aVenDialogClose = () => {
    this.setState({register20aVenDialogOpen: false})
  }

  handleRegister20aVenDialogCreate = async (props) => {
    this.setState({
      pendingRegister20aVen: true,
      register20aVenDialogOpen: false
    })

    try{
      await API.OpenADRCertTest.register20aVen(props)
      await this.loadVEN()
      this.actions.snackbar.show(`Success !`)
    }catch(err){
      this.actions.snackbar.show(`Couldnt register 2.0a VEN: ${err}`)
    } finally {
      this.setState({pendingRegister20aVen: false})
    }
  }

  get ven(){
    return this.state.ven
  }

  get registration(){
    return this.ven && this.ven.oadrRegistration
  }

  get resources(){
    return this.ven && this.ven.resources
  }

  get reportRegistrations(){
    return (this.ven && this.ven.reportRegistrations) || []
  }

  get vtnReports(){
    return (this.ven && this.ven.vtnReports) || []
  }

  get reports(){
    return (this.ven && this.ven.reports) || []
  }

  get opts(){
    const optSchedules = (this.ven && this.ven.optSchedules) || []
    const opts = flatten(optSchedules.map(({opts}) => opts))
    return opts
  }

  get events(){
    return (this.ven && this.ven.events) || []
  }

  renderRegister20aVenDialog = () => {
    return (
      this.state.register20aVenDialogOpen ? <CreateVen20aDialog onClose={ this.handleRegister20aVenDialogClose } onCreate={ this.handleRegister20aVenDialogCreate } /> : null
    )
  }

  renderReport = ({id, intervalDuration, intervalStart, reportBackDuration, granularity, reportRegistration: { reportName, reportSpecifierId }, reportIntervals, requestId, status}) =>
    <div key={id} className={this.props.classes.reportSection}>
      <Typography className={this.props.classes.reportSectionHeader} variant='h6'>REPORT: {reportName}({reportSpecifierId})<br/>
        Granularity: {granularity} – Report Back Duration: {reportBackDuration} <br/>
        {intervalDuration ? `Duration: ${intervalDuration} – ` : ''}{intervalStart ? `Start: ${intervalStart}` : ''} <br/>
        Request id: {requestId}<br/>
        Status: {status}
      </Typography>
      <Table className={this.props.classes.tableDense} dense='true' padding="dense">
        <TableHead>
          <TableRow>
            <TableCell variant='head'>Val</TableCell>
            <TableCell variant='head'>Acc</TableCell>
            <TableCell variant='head'>Cnf</TableCell>
            <TableCell variant='head'>Qual</TableCell>
            <TableCell variant='head'>Strt</TableCell>
            <TableCell variant='head'>Durn</TableCell>
            <TableCell variant='head'>Ovrd</TableCell>
            <TableCell variant='head'>Onln</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          reportIntervals.map(this.renderReportInterval)
        }
        </TableBody>
      </Table>
    </div>

  renderReportRegistration = ({reportSpecifierId, reportName, reportDescriptions}) =>
    <div key={reportSpecifierId} className={this.props.classes.reportSection}>
      <Typography className={this.props.classes.reportSectionHeader} variant='h6'>REPORT REGISTRATION: {reportName}({reportSpecifierId})</Typography>
      <Table className={this.props.classes.tableDense} dense='true' padding="dense">
        <TableHead>
          <TableRow>
            <Tooltip title="Is Active"><TableCell variant='head'>Actv</TableCell></Tooltip>
            <Tooltip title="Reading Type"><TableCell variant='head'>Rdtp</TableCell></Tooltip>
            <Tooltip title="Report Type"><TableCell variant='head'>Rptp</TableCell></Tooltip>
            <Tooltip title="rID"><TableCell variant='head'>Rid</TableCell></Tooltip>
            <Tooltip title="Sampling Rate Min"><TableCell variant='head'>Smin</TableCell></Tooltip>
            <Tooltip title="Sampling Rate Max"><TableCell variant='head'>Smax</TableCell></Tooltip>
            <Tooltip title="Sampling Rate on Change"><TableCell variant='head'>Schg</TableCell></Tooltip>
            <Tooltip title="SI Scale Code"><TableCell variant='head'>Sscd</TableCell></Tooltip>
            <Tooltip title="Pulse Factor"><TableCell variant='head'>Plsf</TableCell></Tooltip>
            <Tooltip title="Voltage"><TableCell variant='head'>Vltg</TableCell></Tooltip>
            <Tooltip title="AC"><TableCell variant='head'>ac</TableCell></Tooltip>
            <Tooltip title="Emix Base Type"><TableCell variant='head'>embt</TableCell></Tooltip>
            <Tooltip title="Hertz"><TableCell variant='head'>hz</TableCell></Tooltip>
            <Tooltip title="Item Description"><TableCell variant='head'>desc</TableCell></Tooltip>
            <Tooltip title="Item Units"><TableCell variant='head'>unit</TableCell></Tooltip>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            reportDescriptions.map(this.renderReportDescription)
          }
        </TableBody>
      </Table>
    </div>

  renderReportDescription = ({
      ac, emixItemBaseType, hertz, id, isActive, itemDescription, itemUnits,
      marketContext, oadrReportRegistrationId, pulseFactor, readingType,
      reportType, rid, samplingRateMaxPeriod, samplingRateMinPeriod,
      samplingRateOnChange, siScaleCode, type, voltage}
    ) =>
    <TableRow key={ id }>
      <Tooltip title={`Is Active(${isActive})`}><TableCell>{`${isActive}`}</TableCell></Tooltip>
      <Tooltip title={`Reading Type(${readingType})`}><TableCell>{readingType}</TableCell></Tooltip>
      <Tooltip title={`Report Type(${reportType})`}><TableCell>{reportType}</TableCell></Tooltip>
      <Tooltip title={`rID(${rid})`}><TableCell>{rid}</TableCell></Tooltip>
      <Tooltip title={`Sampling Rate Min(${samplingRateMinPeriod})`}><TableCell>{samplingRateMinPeriod}</TableCell></Tooltip>
      <Tooltip title={`Sampling Rate Max(${samplingRateMaxPeriod})`}><TableCell>{samplingRateMaxPeriod}</TableCell></Tooltip>
      <Tooltip title={`Sampling Rate on Change(${samplingRateOnChange})`}><TableCell>{samplingRateOnChange}</TableCell></Tooltip>
      <Tooltip title={`SI Scale Code(${siScaleCode})`}><TableCell>{siScaleCode}</TableCell></Tooltip>
      <Tooltip title={`Pulse Factor(${pulseFactor})`}><TableCell>{pulseFactor}</TableCell></Tooltip>
      <Tooltip title={`Voltage(${voltage})`}><TableCell>{voltage}</TableCell></Tooltip>
      <Tooltip title={`AC(${ac})`}><TableCell>{`${ac}`}</TableCell></Tooltip>
      <Tooltip title={`Emix Base Type(${emixItemBaseType})`}><TableCell>{emixItemBaseType}</TableCell></Tooltip>
      <Tooltip title={`Hertz(${hertz})`}><TableCell>{hertz}</TableCell></Tooltip>
      <Tooltip title={`Item Description(${itemDescription})`}><TableCell>{itemDescription}</TableCell></Tooltip>
      <Tooltip title={`Item Units(${itemUnits})`}><TableCell>{itemUnits}</TableCell></Tooltip>
    </TableRow>

  renderReportInterval = ({id, value, accuracy, confidence, dataQuality, dtstart, duration, manualOverride, online}) =>
    <TableRow key={id}>
      <Tooltip title={String(value)}><TableCell>{value}</TableCell></Tooltip>
      <Tooltip title={String(accuracy)}><TableCell>{accuracy}</TableCell></Tooltip>
      <Tooltip title={String(confidence)}><TableCell>{confidence}</TableCell></Tooltip>
      <Tooltip title={String(dataQuality)}><TableCell>{dataQuality}</TableCell></Tooltip>
      <Tooltip title={String(dtstart)}><TableCell>{dtstart}</TableCell></Tooltip>
      <Tooltip title={String(duration)}><TableCell>{duration}</TableCell></Tooltip>
      <Tooltip title={String(manualOverride)}><TableCell>{manualOverride}</TableCell></Tooltip>
      <Tooltip title={String(online)}><TableCell>{String(online)}</TableCell></Tooltip>
    </TableRow>

  renderVenFields = () =>
    <Fragment>
      <TableRow>
        <TableCell colSpan={2}>
          <Typography variant='h5'>{`Selected VEN (${this.registration ? 'registered' : 'unregistered'}):`}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Name</TableCell>
        <TableCell>{String(this.ven.name)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>VEN ID</TableCell>
        <TableCell>{String(this.ven.venId)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Status</TableCell>
        <TableCell>{String(this.ven.status)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Online</TableCell>
        <TableCell>{String(this.ven.online)}</TableCell>
      </TableRow>
    </Fragment>

  renderRegistrationFields = () =>
    <Fragment>
      <TableRow>
        <TableCell colSpan={2}>
          <Typography variant='h6'>Registration:</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>VEN Name</TableCell>
        <TableCell>{String(this.registration.venName)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Profile</TableCell>
        <TableCell>{String(this.registration.profileName)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Transport</TableCell>
        <TableCell>{humanize(this.registration.transportName)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>HTTP Pull</TableCell>
        <TableCell>{String(this.registration.httpPullModel)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Transport Address</TableCell>
        <TableCell>{String(this.registration.transportAddress || '')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Report Only</TableCell>
        <TableCell>{String(this.registration.reportOnly)}</TableCell>
      </TableRow>
    </Fragment>

  renderVenResources = () =>
    this.resources.map(({resourceId, groupId, groupName}) => {
      return (
        <Fragment key={resourceId}>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant='h6'>{`Resource ${resourceId}`}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={this.props.classes.head} variant='head'>Group ID</TableCell>
            <TableCell>{groupId || ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={this.props.classes.head} variant='head'>Group Name</TableCell>
            <TableCell>{groupName || ''}</TableCell>
          </TableRow>
        </Fragment>
      )
    })

  renderReportsSection = () =>
    <Fragment>
      {
        !!this.reportRegistrations.length &&
        <Fragment>
          <Typography variant='h5'>Report Registrations:</Typography>
          {this.reportRegistrations.map(this.renderReportRegistration)}
        </Fragment>
      }
      {
        !!this.reports.length &&
        <Fragment>
          <Typography variant='h5'>Reports:</Typography>
          {this.reports.map(this.renderReport)}
        </Fragment>
      }
      {
        !!this.vtnReports.length &&
        <Fragment>
          <Typography variant='h5'>VTN reports:</Typography>
          <Table className={this.props.classes.tableDense} dense='true' padding="dense">
            <TableHead>
              <TableRow>
                <Tooltip title="Report Request Id"><TableCell variant='head'>rID</TableCell></Tooltip>
                <Tooltip title="Granularity"><TableCell variant='head'>Granularity</TableCell></Tooltip>
                <Tooltip title="Report Back Duration"><TableCell variant='head'>RBk Duration</TableCell></Tooltip>
                <Tooltip title="Interval Start"><TableCell variant='head'>Int Start</TableCell></Tooltip>
                <Tooltip title="Interval Duration"><TableCell variant='head'>Int Duration</TableCell></Tooltip>
                <Tooltip title="Status"><TableCell variant='head'>Status</TableCell></Tooltip>
                <Tooltip title="Last Run"><TableCell variant='head'>Last run</TableCell></Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.vtnReports.map((report) => {
                  return (
                    <TableRow key={report.id}>
                      <Tooltip title={report.requestId}><TableCell>{report.requestId}</TableCell></Tooltip>
                      <TableCell>{report.granularity}</TableCell>
                      <TableCell>{report.reportBackDuration}</TableCell>
                      <TableCell>{report.intervalStart}</TableCell>
                      <TableCell>{report.intervalDuration}</TableCell>
                      <TableCell>{report.status}</TableCell>
                      <TableCell>{report.lastRun}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </Fragment>
      }
    </Fragment>

  renderOpts = () =>
    <Fragment>
      {
        !!this.opts.length &&
        <Fragment>
          <Typography variant='h5'>Opt Schedules:</Typography>
          <Table className={this.props.classes.tableDense} dense='true' padding="dense">
            <TableHead>
              <TableRow>
                <Tooltip title="Opt Id"><TableCell variant='head'>optID</TableCell></Tooltip>
                <Tooltip title="Type"><TableCell variant='head'>Type</TableCell></Tooltip>
                <Tooltip title="Reason"><TableCell variant='head'>Reason</TableCell></Tooltip>
                <Tooltip title="Market context"><TableCell variant='head'>Market Ctx.</TableCell></Tooltip>
                <Tooltip title="Availabilities"><TableCell variant='head'>Avts</TableCell></Tooltip>
                <Tooltip title="End device assets"><TableCell variant='head'>Assets</TableCell></Tooltip>
                <Tooltip title="Targets"><TableCell variant='head'>Targets</TableCell></Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.opts.map((opt) => {
                  return (
                    <TableRow key={opt.id}>
                      <Tooltip title={opt.optId}>
                        <TableCell>{opt.optId}</TableCell>
                      </Tooltip>
                      <TableCell>{opt.optType}</TableCell>
                      <TableCell>{opt.reason}</TableCell>
                      <Tooltip title={opt.marketContext}>
                        <TableCell>{opt.marketContext}</TableCell>
                      </Tooltip>
                      <TableCell>
                        {
                          (opt.availabilities || []).map(({dtstart, duration}) => {
                            return `dstart: ${dtstart}, duration: ${duration}`
                          }).join('\n')
                        }
                      </TableCell>
                      <TableCell>
                        {
                          (opt.endDeviceAssets || []).map(({name}) => {
                            return name
                          }).join('\n')
                        }
                      </TableCell>
                      <TableCell>
                        {
                          (opt.targets ||[]).map(({oadrType, value}) => {
                            return `${oadrType}:${value}`
                          }).join('\n')
                        }
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </Fragment>
      }
    </Fragment>

  renderEvents = () => {
    return !!this.events.length && (
      <Fragment>
        <Typography variant='h5'>Events:</Typography>
        <Table className={this.props.classes.tableDense} dense='true' padding="dense">
            <TableHead>
              <TableRow>
                <Tooltip title="Event Id"><TableCell variant='head'>Event id</TableCell></Tooltip>
                <Tooltip title="ResourceId"><TableCell variant='head'>ResId</TableCell></Tooltip>
                <Tooltip title="OptType"><TableCell variant='head'>Opt</TableCell></Tooltip>
                <Tooltip title="Dtstart"><TableCell variant='head'>Dtstrart</TableCell></Tooltip>
                <Tooltip title="Duration"><TableCell variant='head'>Duration</TableCell></Tooltip>
                <Tooltip title="ModificationNumber"><TableCell variant='head'>Mod #</TableCell></Tooltip>
                <Tooltip title="EventStatus"><TableCell variant='head'>Event status</TableCell></Tooltip>
                <Tooltip title="MarketContext"><TableCell variant='head'>Market context</TableCell></Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.events.map(({eventId, eventStatus, dtstart, duration, modificationNumber, optStatus, opts, optInResources, optOutResources, cancelled, marketContext}) => {
                  return (
                    <Fragment key={eventId}>
                      <TableRow key={eventId}>
                        <Tooltip title={eventId}>
                          <TableCell>{eventId}</TableCell>
                        </Tooltip>
                        <TableCell></TableCell>
                        <TableCell>{optStatus}</TableCell>
                        <TableCell>{dtstart}</TableCell>
                        <TableCell>{duration}</TableCell>
                        <TableCell>{modificationNumber}</TableCell>
                        <TableCell>{cancelled ? `cancelled/${eventStatus}`: eventStatus}</TableCell>
                        <Tooltip title={marketContext}>
                          <TableCell>{marketContext}</TableCell>
                        </Tooltip>
                      </TableRow>

                      {
                        optInResources.map((resource) =>
                          <TableRow key={`${eventId}_${resource}_in`}>
                            <TableCell></TableCell>
                            <TableCell>{resource}</TableCell>
                            <TableCell>optIn</TableCell>
                            <TableCell colSpan={4}></TableCell>
                          </TableRow>
                        )
                      }

                      {
                        optOutResources.map((resource) =>
                          <TableRow key={`${eventId}_${resource}_out`}>
                            <TableCell></TableCell>
                            <TableCell>{resource}</TableCell>
                            <TableCell>optOut</TableCell>
                            <TableCell colSpan={4}></TableCell>
                          </TableRow>
                        )
                      }

                    </Fragment>
                  )
                })
              }
            </TableBody>
          </Table>
      </Fragment>
    )
  }

  render = () =>
    <div className={this.props.classes.venDetails}>
      <TextField defaultValue={this.props.filter.venID} className={this.props.classes.venFilter} onChange={this.handleVenIDChange} onKeyUp={this.handleSearchKeyUp} fullWidth placeholder='Enter VEN ID or leave blank to use latest'
      InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.loadVEN}
              >
                <SearchIcon/>
              </IconButton>
            </InputAdornment>
        }}
      />
      <div className={this.props.classes.venButtons}>
        <Tooltip title="Cancel all pending reports and delete all pending VEN messages">
        { (this.state.pendingZapVen) ?
          <CircularProgress />  :
          <Button variant='contained' onClick={this.handleZapVen}>Zap</Button>
        }
        </Tooltip>
        <Tooltip title="Provision the VEN with test resources">
        { (this.state.pendingProvisionVen) ?
          <CircularProgress />  :
          <Button variant='contained' onClick={this.handleProvisionVen}>Provision</Button>
        }
        </Tooltip>
        <Tooltip title="Register new 2.0a VEN">
        { (this.state.pendingRegister20aVen) ?
          <CircularProgress />  :
          <Button variant='contained' onClick={this.handleRegister20aVenDialogOpen}>Register 2.0a VEN</Button>
        }
        </Tooltip>
      </div>
      <div className={this.props.classes.scrollContainer}>
        <Table className={this.props.classes.table}>
          <TableBody>
            {this.ven && this.renderVenFields()}
            {this.registration && this.renderRegistrationFields()}
            {this.resources && this.renderVenResources()}
          </TableBody>
        </Table>
        { this.registration &&
          <Fragment>
          { this.renderReportsSection() }
          { this.renderOpts() }
          { this.renderEvents() }
          </Fragment>
        }
      </div>
      { this.renderRegister20aVenDialog() }
    </div>
}

const styles = theme => ({
  scrollContainer: {
    maxHeight: 'calc(100vh - 350px)',
    overflowY: 'auto'
  },
  venFilter: {
    '& input': {
      fontSize: 20
    }
  },
  reportSection: {
    borderBottom: `2px solid #437187`
  },
  reportSectionHeader: {
    background: "#437187",
    color: 'white',
    fontSize: 15,
    padding: 5
  },
  head: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: "#FAFAFA",
    borderLeft: "1px solid #DEDEDE",
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderTop: '2px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    background: 'white',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    }
  },
  tableDense: {
    tableLayout: 'fixed',
    '& th': {
      cursor: 'pointer',
      textTransform: 'uppercase'
    },
    '& td': {
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  venDetails: {
    padding: 10,
    borderLeft: "2px solid #efefef",
    '& h1': {
      margin: '10px 0',
      fontSize: 23
    }
  },
  venButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '10px',
    paddingBottom: '10px'
  }
})
export default compose(
  withStyles(styles),
  connectQueryString('selectedVen'),
  connect(() => {})
)(VenDetails)
