import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Payments = ProtectedJsonAPI.extend(
   '',
  {
    index:   endpoint => endpoint.path(({childEventId, organisationId}) => {
      if(childEventId)
        return `/child_events/${childEventId}/payments`
      if(organisationId)
        return `/organisations/${organisationId}/payments`
    }),
    create:  endpoint => endpoint.method('post').path(({childEventId}) => `child_events/${childEventId}/payments`),
    update:  endpoint => endpoint.method('put').path(({id}) => `payments/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `payments/${id}`),
    show:    endpoint => endpoint.path(({id}) => `payments/${id}`),
  }
)