import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Users = ProtectedJsonAPI.extend(
  '',
  {
    index:    endpoint => endpoint.path(({organisationId}) =>  organisationId ? `organisations/${organisationId}/users` : '/users'),
    download: endpoint => endpoint.method('post').useForm(true).path(() => '/users/download').paramsHandler(params => ({...params, Authorization: global.localStorage.auth})),
    create:   endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/users`),
    update:   endpoint => endpoint.method('put').path(({id}) => `users/${id}`),
    destroy:  endpoint => endpoint.method('delete').path(({id}) => `users/${id}`),
    show:     endpoint => endpoint.path(({id}) => `users/${id}`),
    role:     endpoint => endpoint.path(({id}) => `users/${id}/role`),
  }
)
