const charsets = [
  {
    name: "lowercase characters",
    matcher: str => str.match(/[a-z]/) !== null
  },
  {
    name: "uppercase characters",
    matcher: str => str.match(/[A-Z]/) !== null
  },
  {
    name: "digits",
    matcher: str => str.match(/[0-9]/) !== null
  },
  {
    name: "punctuation and special characters",
    matcher: str => str.match(/[^A-Za-z0-9]/)
  }
]

const charsetVerify = str => charsets.map(({name,matcher}) => ({
  name,
  satisfied: matcher(str)
}))

const passwordFeedback = str => {
  const rl = str.length >= 7
  const cs = charsetVerify(str)
  const cm = cs.reduce((s,{satisfied}) => satisfied ? s + 1 : s, 0) >= 3
  return {
    accept: rl && cm,
    feedback: [
      {
        name: "Minimum length of 7 characters",
        satisfied: rl
      },
      {
        name: "Including at least 3 of the following character sets:",
        satisfied: cm,
        subclauses: cs
      }
    ]
  }
}

export default passwordFeedback