import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Registrations = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({organisationId}) =>
      organisationId ?
        `organisations/${organisationId}/registrations` :
        `/registrations`
      ),
    create:                  endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/registrations`),
    event:                   endpoint => endpoint.method('post').path(({id}) => `registrations/${id}/events`),
    update:                  endpoint => endpoint.method('put').path(({id}) => `registrations/${id}`),
    destroy:                 endpoint => endpoint.method('delete').path(({id}) => `registrations/${id}`),
    show:                    endpoint => endpoint.path(({id}) => `registrations/${id}`),
    availabilityForecast:    endpoint => endpoint.path(({organisationId}) => {
      return organisationId ? `organisations/${organisationId}/registrations/availability_forecast` : 'registrations/availability_forecast'
    }),
    availability:            endpoint => endpoint.path(({organisationId}) => {
      return organisationId ? `organisations/${organisationId}/registrations/availability` : 'registrations/availability'
    }),
  }
)