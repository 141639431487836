import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { CalendarActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, DatePicker }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import ZoneSelector from './ZoneSelector'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    CalendarActions.bindActions(this)
  }

  dependsOn() {
    if(this.editMode){
      return this.actions.show(this.objectId, {include: "scopes"})
    } else {
      return this.actions.set({})
    }
  }

  get formObject(){
    return {...this.props.calendar, ...this.state.formAttributes}
  }

  relationshipAttributes = ['scopes']

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Calendar - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth disabled={this.props.calendar.imported} member='name'/>
          <DatePicker fullWidth disabled={this.props.calendar.imported} member='date'/>
          <InputLabel htmlFor='eventType' style={{fontSize: "0.75rem"}}>Event Type</InputLabel>
          <Select fullWidth name='eventType' disabled={this.props.calendar.imported} member='eventType'>
            <MenuItem value="holiday">Holiday</MenuItem>
            <MenuItem value="excluded">Excluded</MenuItem>
          </Select>
          <InputLabel htmlFor='scopes' style={{fontSize: "0.75rem"}}>Region, etc</InputLabel>
          <ZoneSelector name='scopes' member='scopes'/>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth  variant='contained' type='submit' disabled={!this.formObject.date}>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto',
    overflow: 'visible'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({calendars}) => calendars),
)(Form)