import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { DeviceActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, FABAbsolute, TaggedSearchField, LabeledSwitch, LivenessIndicator } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeviceIcon from '@material-ui/icons/SettingsRemote'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import { withRouter } from 'react-router-dom'

export class List extends Component{

  constructor(props){
    super(props)
    DeviceActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  componentDidUpdate = prevProps => {
    if (this.props.organisationId !== prevProps.organisationId) {
      this.loadDevices()
    }
  }

  dependsOn(){
    return this.loadDevices()
  }

  loadDevices = () => {
    return this.actions.index({
      params: {
        ...this.siteId && {siteId: this.siteId},
        ...this.organisationId && {organisationId: this.organisationId}
      },
      clearExisting: true,
      pageSize: 10,
      page: this.props.page,
      filter: this.props.filter,
      fields: {devices: 'name,type,online,lastHeartbeatAt'}
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showDevice = id => () => {
    this.props.history.push(`/devices/${id}`)
  }

  handleFilterChanged = async rawFilter => {
    const filter = {...rawFilter, tags: {}};
    (rawFilter.tags || []).forEach(({value, tagType: { name }}) => {
      if(!filter.tags[name]) filter.tags[name] = []
      filter.tags[name].push(value)
    })
    await this.props.onFilterChange(filter)
    this.loadDevices()
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadDevices()
  }

  handleAddNewDevice = () =>
    this.listContext === 'site' ?
      this.props.history.push(`/sites/${this.siteId}/devices/new`) :
      this.props.history.push('/devices/new')

  get listContext(){
    return this.props.match.path.match(/^\/sites/) ? 'site' : 'organisation'
  }

  get siteId(){
    return this.listContext === 'site' ? this.props.match.params.id : null
  }

  get organisationId(){
    return this.listContext === 'organisation' ? this.props.organisationId || this.props.match.params.id : null
  }

  get devices(){
    return this.props.devices
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderDeviceListItem = ({id, name, type, online, lastHeartbeatAt}) =>
    <ListItem button onClick={this.showDevice(id)} key={id}>
      <ListItemIcon>
        <DeviceIcon/>
      </ListItemIcon>
      <ListItemText primary={<Fragment>{name}<LivenessIndicator live={online} lastHeartbeatAt={lastHeartbeatAt}/></Fragment>}  secondary={type}/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  Container = ({children, useCard=true}={}) => (
    useCard ?
      <Card><CardContent>{children}</CardContent></Card> :
      <Fragment>{children}</Fragment>
  )

  render = () =>
    <this.Container useCard={this.props.useCard}>
      {this.renderErrorMessages()}
        <TaggedSearchField onChange={this.handleFilterChanged} context={this.props.filter}>
        {this.organisationId &&
          <LabeledSwitch checked={!('includeDescendantOrganisations' in this.props.filter) || this.props.filter.includeDescendantOrganisations === 'true'} label="Include nested organisations" member="includeDescendantOrganisations" />
        }
      </TaggedSearchField>
      <MuiList dense>
        {this.devices.map(this.renderDeviceListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      { this.props.showAdd &&
        <FABAbsolute color='primary' variant='fab' onClick={this.handleAddNewDevice}>
          <AddIcon/>
        </FABAbsolute>
      }
    </this.Container>
}

export default compose(
  Dependent({loader: true}),
  withRouter,
  connectQueryString('devices'),
  connect(({devices}) => devices)
)(List)