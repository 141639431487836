import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  FormContext, DateTimePicker,
  AutoSuggest, MultiAutoSuggest,
  LabeledSelect
} from 'components'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import TabIcon from '@material-ui/icons/ExpandLess'
import IconButton from '@material-ui/core/IconButton'
import withStyles from 'styles'
import { compose } from 'utils'
import * as API from 'api'
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem'
import TradingPeriod from 'constants/TradingPeriod'

export class ParentEventParams extends Component{

  static propTypes = {
    context: PropTypes.object,
    errorContext: PropTypes.object,
    onChange: PropTypes.func,
    isPriceResponsive: PropTypes.bool,
    isAutoDRProgramme: PropTypes.bool,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    onOfferWindowEndChanged: PropTypes.func,
    defaults: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      offerWindowEndManuallyChanged: false
    }
  }

  handleGxpsFetchRequested = async (text, callback) => {
    const params = {
      ...this.props.region && {regionId: this.props.region.id},
      options: {
        filter: { code: text }
      }
    }

    const { data: gxps } = await API.Gxps.index(params)
    callback(gxps.filter(v => !this.props.gxps.some(t => t.id === v.id)).slice(0, 5))
  }

  handleOfferWindowEndChanged = (event) => {
    const {target: { value }} = event
    const newContext = {...this.props.context, offerWindowEnd: value}
    this.setState({offerWindowEndManuallyChanged: true})
    this.props.onChange(newContext)
  }

  handleStartChanged = (event) => {
    const {target: { value }} = event
    const newContext = {...this.props.context, start: value}

    this.props.onChange(this.updateOfferWindowEnd(newContext))
  }

  updateOfferWindowEnd = (context) => {
    if(context.start && context.minimumLeadTime) {
      const shouldUpdateOfferWindowEnd = this.props.isPriceResponsive
                                      && this.status === 'new'
                                      && !this.state.offerWindowEndManuallyChanged
      if(shouldUpdateOfferWindowEnd) {
        let { offerWindowBuffer } = this.props.defaults
        let offerWindowEnd = moment(context.start).subtract(context.minimumLeadTime + offerWindowBuffer, 'minutes')
        context.offerWindowEnd = offerWindowEnd
      }
    }
    return context
  }

  handleRequestRegionsFetch = async (text, callback) => {
    const { data: regions } = await API.Regions.index({
      options: {
        filter: { name: text }
      }
    })
    callback(regions)
  }

  isReadOnly = (member) => {
    return member === 'minimumLeadTime' || !['new', 'draft'].includes(this.status)
  }

  get status() {
    return this.props.context.status
  }

  render = () =>
    <FormContext context={this.props.context} errorContext={this.props.errorContext} onChange={this.props.onChange}>
      <section className={this.props.classes('parentEventParams', 'grid', {collapsed: !!this.props.collapsed})}>
        <IconButton className={this.props.classes.toggleButton} onClick={this.props.onCollapse}>
          <TabIcon/>
        </IconButton>
        {
          !this.props.collapsed &&
          <Fragment>
            <section className={this.props.classes.parentEventParamsSection}>
              <Typography variant='h6'>Event Time</Typography>
              <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} member='start' onChange={this.handleStartChanged} disabled={this.isReadOnly('start')}/>
              <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} member='end' disabled={this.isReadOnly('end')}/>
            </section>
            {
              this.props.isPriceResponsive &&
              <section className={this.props.classes.parentEventParamsSection}>
                <Typography variant='h6'>Offer Window Time</Typography>
                <DateTimePicker  member='offerWindowStart' disabled={true}/>
                <DateTimePicker  onChange={this.handleOfferWindowEndChanged} member='offerWindowEnd' disabled={this.isReadOnly('offerWindowEnd')}/>
              </section>
            }
            {
              (!this.props.isPriceResponsive && this.props.isAutoDRProgramme) &&
              <section className={this.props.classes.parentEventParamsSection}>
                <Typography variant='h6'>Auto DR</Typography>
                <LabeledSelect member='drLevel' label='Auto DR Level' disabled={this.isReadOnly('offerWindowEnd')}>
                  <MenuItem value={'none'}>None</MenuItem>
                  <MenuItem value={'low'}>Low</MenuItem>
                  <MenuItem value={'medium'}>Medium</MenuItem>
                  <MenuItem value={'high'}>High</MenuItem>
                </LabeledSelect>
              </section>
            }
            <section className={this.props.classes.parentEventParamsSection} style={{gridRow: 'span 2'}}>
              <Typography variant='h6'>Event Parameters</Typography>
              <TextField
                member='targetKw'
                label='Target kW'
                disabled={this.isReadOnly('targetKw')}
                type='number'
                InputProps={
                  {
                    endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                  }
                }
              />
              { this.props.isPriceResponsive &&
                <TextField
                  member='mwhPrice'
                  disabled={this.isReadOnly('mwhPrice')}
                  label='MWh Price'
                  type='number'
                  InputProps={
                    {
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      endAdornment: <InputAdornment position="end">/MWh</InputAdornment>
                    }
                }/>
              }
              <TextField
                member='minimumLeadTime'
                onChange={this.handleMinimumLeadTimeChanged}
                disabled={this.isReadOnly('minimumLeadTime')}
                type='number'
                InputProps={
                  {
                    endAdornment: <InputAdornment position="end">Min</InputAdornment>
                  }
              }/>
            </section>
            <section className={this.props.classes.parentEventParamsSection}  style={{gridColumn: 'span 2'}}>
              <Typography variant='h6'>Target Location</Typography>
              <AutoSuggest
                className={this.props.classes.regionAutoSuggest}
                member='region'
                disabled={this.isReadOnly('region')}
                labelProvider={({name}) => name}
                onSuggestionsFetchRequested={this.handleRequestRegionsFetch}
              />
              <MultiAutoSuggest
                member='gxps'
                label='GXPs'
                readOnly={this.isReadOnly('gxps')}
                labelProvider={({code}) => code}
                onSuggestionsFetchRequested={this.handleGxpsFetchRequested}
              />
            </section>
          </Fragment>
        }
      </section>
    </FormContext>

}

const styles = theme => ({
  parentEventParams: {
    '&$grid': {
      display: 'grid /* autoprefixer: off */',
      gridTemplateColumns: 'auto',
      '@media(min-width: 600px)': {
        gridTemplateColumns: 'auto auto',
      },
      '@media(min-width: 1024px)': {
        gridTemplateColumns: 'auto auto auto'
      },
    },
    display: 'flex',
    flexWrap: 'wrap',
    gridAutoColumns: '0px',
    flex: `1 1 ${theme.viewAreaMaxWidth}px`,
    background: "rgba(0, 27, 38, 0.5)",
    marginBottom: 20,
    position: 'relative',
    maxWidth: '100%',
    width: '100vw',
    minHeight: 48,
    border: '1px solid rgba(150, 254, 172, 0.1)',
  },
  grid: {},
  parentEventParamsSection: {
    marginRight: 10,
    padding: '15px 10px',
    display: 'flex',
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    '& > h6': {
      fontSize: '1rem',
      flex: '0 0 100%'
    },
    '& > div': {
      width: 'auto',
      margin: '0 10px 0 0',
      flex: '1 0 calc(50% - 10px)'
    },
    '& > $regionAutoSuggest': {
      flex: '0 1 150px'
    },
    '@media(max-width: 600px)': {
      gridColumn: 'span 2 !important'
    }
  },
  regionAutoSuggest:{

  },
  toggleButton: {
    color: theme.palette.primary.white,
    fontSize: 11,
    position: 'absolute',
    zIndex: 100,
    top: 0,
    right: 0,
    transition: 'transform 500ms',
  },
  collapsed: {
    '&::before': {
      content: "'Filters'",
      lineHeight: '48px',
      color: 'white',
      fontWeight: 900,
      fontSize: '1rem',
      paddingLeft: 10,
      borderLeft: `3px solid ${theme.palette.secondary.light}`,
      display: 'block',
    },

    '& $toggleButton': {
      transform: 'rotate(180deg)'
    }
  }
})


export default compose(
  withStyles(styles)
)(ParentEventParams)