import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import WidgetContainer from './WidgetContainer'
import { Notification } from 'containers'
import { withRouter } from 'react-router-dom'

export class NotificationWidget extends Component{

  static propTypes = {

  }


  handleViewAll = () => {
    this.props.history.push('/notifications')
  }

  render = () =>
    <WidgetContainer width='md'>
      <div>
        <Typography variant='h6'>Notifications</Typography>
        <Notification.ShortPendingList onViewAll={this.handleViewAll}/>
      </div>
    </WidgetContainer>

}

export default withRouter(NotificationWidget)