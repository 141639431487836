import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReportRegistrationActions, SnackbarActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { compose } from 'utils'
import withStyles from 'styles'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import CollapsibleTable, { TableHead, TableBody, TableCell, TableRow } from 'components/CollapsibleTable';

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { withRouter } from 'react-router-dom'

export class ReportRegistration extends Component{

  constructor(props){
    super(props)
    ReportRegistrationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    this.actions.show(this.id, {
      include: 'reportDescriptions,reportDescriptions.emixUnit,reports'
    })
  }

  dependenciesMet(){
    return !!this.props.reportRegistration.id
  }

  get reportRegistration(){
    return (this.props.reportRegistration || { reports: [], reportDescriptions: []})
  }

  get id(){
    return this.props.match.params.id
  }

  handleViewDataPoints = rid => () =>
    this.props.history.push(`/oadr/datapoints/${rid}/report_intervals`)

  renderReport = ({
    id,
    intervalDuration, intervalStart, reportBackDuration, granularity, status
  }) =>
    <TableRow key={ id }>
      <Tooltip title='Interval Duration'><TableCell mobileLabel='Interval Duration'>{intervalDuration}</TableCell></Tooltip>
      <Tooltip title='Interval Start'><TableCell mobileLabel='Interval Start'>{intervalStart}</TableCell></Tooltip>
      <Tooltip title='Report Back Duration'><TableCell mobileLabel='Report Back Duration'>{reportBackDuration}</TableCell></Tooltip>
      <Tooltip title='Granularity'><TableCell mobileLabel='Granularity'>{granularity}</TableCell></Tooltip>
      <Tooltip title='Status'><TableCell mobileLabel='Status'>{status}</TableCell></Tooltip>
    </TableRow>

  renderReportDescription = (reportDescription) => {
    const { emixUnit, id, readingType, reportType, rid, samplingRateMaxPeriod, samplingRateMinPeriod, samplingRateOnChange } = reportDescription
    const {
      emixItemBaseType, hertz, itemDescription, itemUnits, ac, isActive,
      pulseFactor, voltage, siScaleCode
    } = emixUnit || {}

    return (
      <TableRow key={ id }>
        <Tooltip title={`Is Active(${isActive})`}><TableCell mobileLabel="Is Active">{`${isActive}`}</TableCell></Tooltip>
        <Tooltip title={`Reading Type(${readingType})`}><TableCell mobileLabel="Reading Type">{readingType}</TableCell></Tooltip>
        <Tooltip title={`Report Type(${reportType})`}><TableCell mobileLabel="Report Type">{reportType}</TableCell></Tooltip>
        <Tooltip title={`rID(${rid})`}><TableCell mobileHeading mobileLabel="rID">{rid}</TableCell></Tooltip>
        <Tooltip title={`Sampling Rate Min(${samplingRateMinPeriod})`}><TableCell mobileLabel="Sampling Rate Min">{samplingRateMinPeriod}</TableCell></Tooltip>
        <Tooltip title={`Sampling Rate Max(${samplingRateMaxPeriod})`}><TableCell mobileLabel="Sampling Rate Max">{samplingRateMaxPeriod}</TableCell></Tooltip>
        <Tooltip title={`Sampling Rate on Change(${samplingRateOnChange})`}><TableCell mobileLabel="Sampling Rate on Change">{samplingRateOnChange}</TableCell></Tooltip>
        <Tooltip title={`SI Scale Code(${siScaleCode})`}><TableCell mobileLabel="SI Scale Code">{siScaleCode}</TableCell></Tooltip>
        <Tooltip title={`Pulse Factor(${pulseFactor})`}><TableCell mobileLabel="Pulse Factor">{pulseFactor}</TableCell></Tooltip>
        <Tooltip title={`Voltage(${voltage})`}><TableCell mobileLabel="Voltage">{voltage}</TableCell></Tooltip>
        <Tooltip title={`AC(${ac})`}><TableCell mobileLabel="AC">{`${ac  || ''}`}</TableCell></Tooltip>
        <Tooltip title={`Emix Base Type(${emixItemBaseType})`}><TableCell mobileLabel="Emix Base Type">{emixItemBaseType}</TableCell></Tooltip>
        <Tooltip title={`Hertz(${hertz})`}><TableCell mobileLabel="Hertz">{hertz}</TableCell></Tooltip>
        <Tooltip title={`Item Description(${itemDescription})`}><TableCell mobileLabel="Item Description">{itemDescription}</TableCell></Tooltip>
        <Tooltip title={`Item Units(${itemUnits})`}><TableCell mobileLabel="Item Units">{itemUnits}</TableCell></Tooltip>
        <TableCell>
          <Tooltip title="Show datapoints"><IconButton onClick={this.handleViewDataPoints(rid)}><ShowChartIcon/></IconButton></Tooltip>
        </TableCell>
      </TableRow>
    )
  }

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <Typography className={this.props.classes.title} variant='h4'>Report Registration – {this.reportRegistration.reportName}</Typography>

        <Typography className={this.props.classes.title} variant='h6'>Report Descriptions</Typography>
        <div className={this.props.classes.responsiveTableWrapper}>
          <CollapsibleTable breakpoint={700} className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                <Tooltip title="Is Active"><TableCell className={this.props.classes.head} variant='head'>Actv</TableCell></Tooltip>
                <Tooltip title="Reading Type"><TableCell className={this.props.classes.head} variant='head'>Rdtp</TableCell></Tooltip>
                <Tooltip title="Report Type"><TableCell className={this.props.classes.head} variant='head'>Rptp</TableCell></Tooltip>
                <Tooltip title="rID"><TableCell className={this.props.classes.head} variant='head'>Rid</TableCell></Tooltip>
                <Tooltip title="Sampling Rate Min"><TableCell className={this.props.classes.head} variant='head'>Smin</TableCell></Tooltip>
                <Tooltip title="Sampling Rate Max"><TableCell className={this.props.classes.head} variant='head'>Smax</TableCell></Tooltip>
                <Tooltip title="Sampling Rate on Change"><TableCell className={this.props.classes.head} variant='head'>Schg</TableCell></Tooltip>
                <Tooltip title="SI Scale Code"><TableCell className={this.props.classes.head} variant='head'>Sscd</TableCell></Tooltip>
                <Tooltip title="Pulse Factor"><TableCell className={this.props.classes.head} variant='head'>Plsf</TableCell></Tooltip>
                <Tooltip title="Voltage"><TableCell className={this.props.classes.head} variant='head'>Vltg</TableCell></Tooltip>
                <Tooltip title="AC"><TableCell className={this.props.classes.head} variant='head'>ac</TableCell></Tooltip>
                <Tooltip title="Emix Base Type"><TableCell className={this.props.classes.head} variant='head'>embt</TableCell></Tooltip>
                <Tooltip title="Hertz"><TableCell className={this.props.classes.head} variant='head'>hz</TableCell></Tooltip>
                <Tooltip title="Item Description"><TableCell className={this.props.classes.head} variant='head'>desc</TableCell></Tooltip>
                <Tooltip title="Item Units"><TableCell className={this.props.classes.head} variant='head'>unit</TableCell></Tooltip>
                <TableCell className={this.props.classes.head}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.reportRegistration.reportDescriptions.map(this.renderReportDescription) }
            </TableBody>
          </CollapsibleTable>
        </div>
        <br/>
        <hr/>

        <Typography className={this.props.classes.title} variant='h6'>Requested Reports</Typography>
        <div className={this.props.classes.responsiveTableWrapper}>
          <CollapsibleTable breakpoint={700} className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                <Tooltip title='Interval Duration'><TableCell variant='head' className={this.props.classes.head}>Interval Duration</TableCell></Tooltip>
                <Tooltip title='Interval Start'><TableCell variant='head' className={this.props.classes.head}>Interval Start</TableCell></Tooltip>
                <Tooltip title='Report Back Duration'><TableCell variant='head' className={this.props.classes.head}>Report Back Duration</TableCell></Tooltip>
                <Tooltip title='Granularity'><TableCell variant='head' className={this.props.classes.head}>Granularity</TableCell></Tooltip>
                <Tooltip title='Status'><TableCell variant='head' className={this.props.classes.head}>Status</TableCell></Tooltip>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.reportRegistration.reports.map(this.renderReport) }
              {(this.reportRegistration.reports.length === 0) && <Typography>No reports have been requested for this report registration.</Typography>}
            </TableBody>
          </CollapsibleTable>
        </div>
      </CardContent>
    </Card>
}


const styles = theme => ({
  table: {
    "& table.collapsed": {
      margin: 0,
      '& .mobile-heading': {
        background: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'right !important',
        paddingRight: '10px !important',
        paddingBottom: '10px !important',
        margin: '-10px -10px 0 -10px',
        width: 'calc(100% + 20px) !important'
      },
    }
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: theme.palette.primary.main,
    color: "#FAFAFA",
    borderLeft: "1px solid #DEDEDE",
  },
  responsiveTableWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
    flex: 1
  }
})


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withStyles(styles),
  withRouter,
  connect(({reportRegistrations}) => reportRegistrations)
)(ReportRegistration)