import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, flatten, errorStringsFromError, roundDateOrMoment } from 'utils'
import withStyles from 'styles'
import { MeterDataActions } from 'actionsets'
import { FormContext, DateTimePicker, MultiAutoSuggest, Pagination, LabeledSelect } from 'components'
import { connectQueryString } from 'containers/shared'
import moment from 'moment'
import * as API from 'api'
import { Loader, ErrorBanner } from 'components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Download from '@material-ui/icons/CloudDownload'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Switch from '@material-ui/core/Switch'
import TabIcon from '@material-ui/icons/ExpandLess'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TradingPeriod from 'constants/TradingPeriod'
import { LineChart } from 'components/charts'

export class Readings extends Component {

  constructor(props) {
    super(props)
    MeterDataActions.bindActions(this)
    this.state = { opacity: {}, filtersCollapsed: false}
  }

  componentDidMount(){
    this.loadMeterData()
  }

  get chartData(){
    const count = Math.max.apply(null, this.chartItems.map(({readings}) => readings.length))
    if(!count || count <= 0)
      return {data: [[0,0]], labels: ['Date', '']}
    const data  = new Array(count).fill({})
    const date = new Date(this.props.filter.from)
    return (
      {
        labels: ['Date', ...this.chartItems.map(ci => ci.name)],
        data: data.map((_, idx) => {
        return [
          new Date(date.getTime() + idx * TradingPeriod.length.inMilliseconds),
          ...this.chartItems.map(ci => ci.readings[idx])
        ]
      })
      }
    )
  }

  componentDidUpdate = (prevProps) => {
    if(
        this.props.sums !== prevProps.sums ||
        this.props[this.props.readingType] !== prevProps[prevProps.readingType] ||
        this.props.filter.stacked !== prevProps.filter.stacked
      ){
      this.setState({chartData: this.chartData})
    }
  }

  loadMeterData = () => {
    switch(this.filter.sum){
    case true:
      return this.actions.sum({page: this.props.page, filter: this.props.filter, params: { type: this.props.readingType }})
    default:
      return this.actions.index({page: this.props.page, filter: this.props.filter, params: { type: this.props.readingType }})
    }
  }

  handleDownload = (format) => {
    this.actions.download({page: this.props.page, filter: this.props.filter, params: { type: this.props.readingType, format: format }})
  }

  get filter(){
    return Object.entries(this.props.filter || {})
                 .reduce((agg, [key, value]) => ({...agg, [key]: this.parseFilter(key, value)}), {})
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.sum){
      errors = errors.concat(this.props.errors.sum)
    }
    return errors
  }

  parseFilter(key, value){
    switch(key){
    case 'from':
    case 'to':
      return roundDateOrMoment(moment(value), TradingPeriod.length.inMinutes).toDate()
    case 'gxps':
    case 'registrations':
    case 'sites':
      return flatten([value])
    case 'sum':
    case 'stacked':
    case 'inactive':
      return `${value}` === "true"
    default:
      return value
    }
  }

  handleFilterChange = ({from, to, ...others}) => {
    if(from && typeof from === 'object') from = from.toISOString()
    if(to && typeof to === 'object')     to = to.toISOString()
    const filter = {from: from && from, to: to && to, ...others}
    this.props.onFilterChange(filter, this.loadMeterData)
  }

  handleGxpsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { code: text } }}
    const { data: gxps } = await API.Gxps.index(params)
    callback(gxps.map(({code}) => code).filter(code => !(this.filter.gxps || []).includes(code)).slice(0, 5))
  }

  handleRegistrationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: registrations } = await API.Registrations.index(params)
    callback(registrations.map(({name}) => name).filter(name => !(this.filter.registrations || []).includes(name)).slice(0, 5))
  }

  handleSitesFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: sites } = await API.Sites.index(params)
    callback(sites.map(({name}) => name).filter(name => !(this.filter.sites || []).includes(name)).slice(0, 5))
  }

  handlePageChange = (page) => {
    this.props.onPageChange(page, this.loadMeterData)
  }

  handleCollapseFilters = () => {
    this.props.onFilterChange({...this.props.filter, collapsed:this.props.filter.collapsed !== 'true'},
      () => this.chartRef.resize()
    )
  }

  handleToggleDownloadMenu = ({currentTarget}) => {
    this.setState({formatMenuAnchor: Boolean(this.state.formatMenuAnchor) ? null : currentTarget})
  }

  traverse = (multiplier, unit) => () => {
    const filter = {...this.filter}
    filter.from = moment(filter.from).add(multiplier,unit)
    filter.to = moment(filter.to).add(multiplier,unit)
    this.handleFilterChange(filter)
  }

  get chartItems(){
    return (this.filter.sum ? this.props.sums[this.props.readingType] : this.props[this.props.readingType])
                        .filter(({readings}) => readings.length)
  }

  get timeSpan(){
    return moment(this.filter.to).diff(this.filter.from)
  }

  get spansMoreThanWeek(){
    return this.timeSpan >= moment.duration(1, 'week')
  }

  renderFormatSelectionMenu = () =>
    <Menu
      id="select-programme-menu"
      anchorEl={this.state.formatMenuAnchor}
      open={Boolean(this.state.formatMenuAnchor)}
      onClose={this.handleToggleDownloadMenu}
    >
      <MenuItem onClick={() => this.handleDownload('vertical')}>Vertical Format</MenuItem>
      <MenuItem onClick={() => this.handleDownload('horizontal')}>Horizontal Format</MenuItem>
    </Menu>

  renderFilters = () =>
    <section className={this.props.classes('filters', {collapsed: this.props.filter.collapsed === 'true'})}>
      {
        this.errors.length ?
        <ErrorBanner>
          {errorStringsFromError(this.errors)}
        </ErrorBanner> :
        false
      }
      <IconButton className={this.props.classes.collapseButton} onClick={this.handleCollapseFilters}>
        <TabIcon/>
      </IconButton>
      <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
        <IconButton onClick={this.traverse(-1, this.spansMoreThanWeek ? 'week' : 'day')}>
          <ChevronLeft/>
        </IconButton>
        <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('meterDataFilter', 'dateFilter')} member='from'/>
        <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('meterDataFilter', 'dateFilter')} member='to'/>
        <IconButton onClick={this.traverse(1, this.spansMoreThanWeek ? 'week' : 'day')}>
          <ChevronRight/>
        </IconButton>
        <MultiAutoSuggest
          classNames={{container: this.props.classes.meterDataFilter, input: this.props.classes.meterDataFilterInput}}
          member='gxps'
          labelProvider={(code) => code}
          onSuggestionsFetchRequested={this.handleGxpsFetchRequested}
        />
        {
          this.props.readingType === 'sites' &&
          <Fragment>
            <MultiAutoSuggest
              classNames={{container: this.props.classes.meterDataFilter, input: this.props.classes.meterDataFilterInput}}
              member='registrations'
              labelProvider={(name) => name}
              onSuggestionsFetchRequested={this.handleRegistrationsFetchRequested}
            />
            <MultiAutoSuggest
              classNames={{container: this.props.classes.meterDataFilter, input: this.props.classes.meterDataFilterInput}}
              member='sites'
              labelProvider={(name) => name}
              onSuggestionsFetchRequested={this.handleSitesFetchRequested}
            />
          </Fragment>
        }
        <LabeledSelect
          className={this.props.classes.xiFilter}
          member='flowDirection'
          options={{X: "X", I: "I", "both": "X - I"}}
          label="Flow"
        />
        <FormControlLabel
          control={
            <FormContext context={this.filter} onChange={this.handleFilterChange}>
              <Switch member='sum' value={`${!!this.filter.sum}`} checked={!!this.filter.sum}/>
            </FormContext>
          }
          label="Sum Results"
        />
        <FormControlLabel
          control={
            <FormContext context={this.filter} onChange={this.handleFilterChange}>
              <Switch member='inactive' value={`${!!this.filter.inactive}`} checked={!!this.filter.inactive}/>
            </FormContext>
          }
          label="Show Inactive"
        />
        {
          !this.filter.sum &&
          <Fragment>
            <FormControlLabel
              control={
                <FormContext context={this.filter} onChange={this.handleFilterChange}>
                  <Switch member='stacked' value={`${!!this.filter.stacked}`} checked={!!this.filter.stacked}/>
                </FormContext>
              }
              label="Stacked"
            />
            <IconButton onClick={this.handleToggleDownloadMenu}>
              <Download/>
            </IconButton>
          </Fragment>
        }
      </FormContext>
    </section>

  render = () =>
    <div className={this.props.classes.wrapper}>
      {this.renderFilters()}
      {
        !this.filter.sum &&
        <Pagination className={this.props.classes.pagination} totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageChange} style={{}} linkStyle={{}}/>
      }
      {
        this.props.requests.length > 0 && !this.chartRef?
          <Loader/> :
          <LineChart stacked={this.props.filter.stacked === 'true'} chartRef={ref => this.chartRef = ref} chartData={this.state.chartData}/>
      }
      {this.renderFormatSelectionMenu()}
    </div>
}

const styles = theme => ({
  toggleButton:{
    position: 'absolute',
    right: 10,
    color: theme.palette.primary.main
  },
  pagination: {
    flex: '0 0 auto',
  },
  filters: {
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: "rgba(0, 27, 38, 0.5)",
    padding: '5px 49px 5px 10px',
    alignItems: 'self-start',
    '& $meterDataFilter': {
      margin: 5
    }
  },
  dateFilter: {
    paddingTop: 2
  },
  meterDataFilter: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350
  },
  xiFilter: {
    alignSelf: 'center'
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    height: 'calc(100vh - 230px)',
    '@media(max-width: 600px)': {
      height: 'auto'
    }
  },
  collapsed: {
    '& > *': {
      display: 'none'
    },

    '&::before': {
      content: "'Filters'",
      lineHeight: '38px',
      color: 'white',
      fontWeight: 900,
      fontSize: '1rem',
      display: 'block',
    },

    '& $collapseButton': {
      transform: 'rotate(180deg)',
      display: 'block'
    }
  },
  collapseButton: {
    color: 'white',
    fontSize: 11,
    position: 'absolute',
    zIndex: 100,
    top: 0,
    right: 0,
    transition: 'transform 500ms',
  },
})

export default compose(
  withStyles(styles),
  connect(({meterData}, {filter}) =>  ({...meterData, filter: {
    from: moment().add(-1, 'week').startOf('day').toISOString(),
    to: moment().hours(23).minutes(30).seconds(0).milliseconds(0).toISOString(),
    sum: true,
    inactive: true,
    stacked: true,
    flowDirection: 'both',
    ...filter
  }})),
  connectQueryString('meterData'),
)(Readings)
