import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import MuiList from '@material-ui/core/List';
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { UserNotificationActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import NotificationListItem from './NotificationListItem'
import { compose, errorStringsFromError } from 'utils'

export class List extends Component{

  constructor(props){
    super(props)
    UserNotificationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')

    this.state = {
      showAll: false
    }
  }

  dependsOn(){
    return this.loadNotifications()
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  loadNotifications = page => {
    const filter = {
      delivery_type: 'AppDelivery',
      delivery_state: 'delivered',
      ...(!this.state.showAll && { done: false })
    }

    return this.actions.index({
      ...(page && {page}), filter
    });
  }

  markAllDone = async (done=true) => {
    await this.actions.markAllDone(this.props.userNotifications,done)
    await this.actions.updateShortPendingListCount()
    await this.loadNotifications(this.props.page)
  }

  get notifications(){
    return this.props.userNotifications.filter(({ missingSubject }) => !missingSubject)
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  toggleDoneHandler = (notification) => async () => {
    try{
      await this.actions.markDone(notification, !notification.done)
      await this.actions.updateShortPendingListCount()
    } catch(error) {
      this.actions.snackbar.show(errorStringsFromError(error).join(', '))
    }
  }

  selectHandler = (notification) => () => {
    const { subjectUriPath } = notification
    this.props.history.push(subjectUriPath)
  }

  doneFilterChangeHandler = (event, checked) => {
    this.setState({ showAll: checked }, this.loadNotifications)
  }

  renderNotificationListItem = (notification) => {
    return (
      <NotificationListItem key={notification.id} notification={notification}
        onSelect={ this.selectHandler(notification) } onToggleDone={ this.toggleDoneHandler(notification) }/>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderDoneFilter = () =>
    <FormControlLabel label="Show all"
      control={
        <Switch color="primary" onChange={ this.doneFilterChangeHandler } checked={ this.state.showAll }/>
      }/>

  renderMarkAllDone = () =>
    <Button onClick={() => this.markAllDone(true)} color="secondary">Mark all done</Button>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <Typography variant='h4'>Notifications</Typography>

        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadNotifications}
          startAdornment={ this.renderDoneFilter() } style={{}} linkStyle={{}}/>
        <MuiList dense>
          {
            this.notifications.map(this.renderNotificationListItem)
          }
          { this.notifications.length === 0 &&
            <ListItem className={this.props.classes.subHeader}>
              <ListItemText className={this.props.classes.subHeaderContent} primary='There are no notifications!' />
            </ListItem>
          }
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadNotifications} startAdornment={this.renderMarkAllDone()} style={{}} linkStyle={{}}/>
      </CardContent>
    </Card>
}

const styles = theme => ({
  subHeader: {
    textAlign: 'center'
  },
  subHeaderContent: {
    width: '100%'
  }
})

export default compose(
  Dependent({loader: true}),
  connect(({userNotifications}) => userNotifications),
  withStyles(styles)
)(List)