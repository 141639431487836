import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CenteredCard } from 'components'
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'

export class AcceptedNoLogin extends Component {
  render = () =>
    <CenteredCard>
      <CardContent>
        <Typography variant='h6'>Subscription Confirmed</Typography>
      </CardContent>
    </CenteredCard>
}

export default connect(({tokens}) => tokens)(AcceptedNoLogin)