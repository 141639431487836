import React, { Component } from 'react'
import withStyles from 'styles'
import { Authorization, compose, userFriendlyDate } from 'utils'
import * as API from 'api'
import { BarChart } from 'components/charts'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import {lighten} from '@material-ui/core/styles/colorManipulator'

export class RcpdPeaksWidget extends Component{

  state = { loads: [], mode: 'UNI', peaks: {} }

  handleChangeMode = mode => () => {
    this.setState({mode}, this.computeChartData)
  }

  componentDidMount = () => {
    this.loadPeaks()
  }

  componentWillUnmount = () => {
    global.clearInterval(this.resizeInterval)
  }

  componentDidUpdate = prevProps => {
    if(prevProps.organisationId !== this.props.organisationId) {
      this.loadPeaks()
    }
  }

  loadPeaks = async () => {
    const { data: { peaks } } = await API.Rcpd.peaks({options: { organisation_id: this.organisationId }})
    this.resizeInterval = global.setInterval(() => {this.chartRef && this.chartRef.resize()}, 1000)
    const mode = Object.keys(peaks)[0]
    this.setState({peaks, mode, ready: true}, this.computeChartData)
  }

  computeChartData = () => {
    if(!this.state.ready)
      return
    const labels = ['Time', 'Peak', 'Own Load']
    const peaks = this.peaksForMode
    if(!peaks)
      return
    const data = peaks.map(({from, load, distributor_load}, i) => ([i, load, distributor_load]))
    this.setState({ chartData: { labels, data }})
  }

  get peaksForMode(){
    return this.state.peaks[this.state.mode]
  }

  get organisationId(){
    return this.props.organisationId || Authorization.organisationId
  }


  handleFormatPeakLegend = () => {
    const getPeaks = () => this.peaksForMode
    const isReady = () => this.state.ready
    return function(data){
     if (data.x == null || !isReady()) {
        // This happens when there's no selection and {legend: 'always'} is set.
        return '<br>' + data.series.map(function(series) { return series.dashHTML + ' ' + series.labelHTML }).join('<br>')
      }
      const peaks = getPeaks()
      const selectedPeriod = peaks[parseInt(data.xHTML)].from
      let html = this.getLabels()[0] + ': ' + userFriendlyDate(selectedPeriod)
      data.series.forEach(function(series) {
          if (!series.isVisible) return;
          var labeledData = `<span style="color: ${series.color}; font-weight: bold;">${series.labelHTML}</span>: ${parseFloat(series.y.toFixed(2))} MW`
          html += '<br>' + series.dashHTML + ' ' + labeledData;
        });
      return html;
    }
  }

  render(){
    return (
      <div className={this.props.classes.wrapper}>
        <Typography className={this.props.classes.typography} variant='h6'>Current Peaks</Typography>
        <BarChart
          className={this.props.classes.charts}
          chartRef={ref => this.chartRef = ref}
          chartData={this.state.chartData}
          legendFormatter={this.handleFormatPeakLegend()}
          colors={["#2892ea","#30FF00"]}
          axes={{
            y: {
              axisLabelFormatter: function(v) {
                return parseFloat(v.toFixed(2), 10) + '&nbsp;MW';  // controls formatting in the legend/mouseover
              },
              valueFormatter: function(v) {
                return parseFloat(v.toFixed(2), 10) + '&nbsp;MW';  // controls formatting in the legend/mouseover
              }
            }
          }}
          stackedGraph
        />
        <div className={this.props.classes.toggleButtons}>
          <div className={this.props.classes.toggleButtonSection}>
             {
              ["UNI", "LNI", "USI", "LSI"].map(mode =>
                <Tooltip title={mode} key={mode}>
                  <Button key={mode} className={this.props.classes({disabled: this.state.mode === mode})} onClick={this.handleChangeMode(mode)}>
                    {mode}
                  </Button>
                </Tooltip>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

const styles = theme => ({
  wrapper: {
    position: 'relative',
    padding: '15px 0 30px',
    borderRadius: 2,
    overflow: 'hidden'
  },
  typography: {
  },
  charts: {
    flex: 1,
    height: 0,
    minHeight: 300,
    background: 'rgba(0, 19, 27, 0.6)',
    color: 'white',
    overflow: 'hidden',
    borderRadius: 2,
    '@media(max-width: 600px)': {
      height: 600
    }
  },
  toggleButtons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    lineHeight: 0,
    background: 'rgba(0, 19, 27, 0.6)',
    paddingTop: 7
  },
  disabled: {
  },
  toggleButtonSection: {
    flex: '0 1 auto',
    '& > button': {
      minWidth: 30,
      minHeight: 23,
      lineHeight: 0,
      padding: '0 3px',
      borderRadius: 0,
      color: '#000',
      background: theme.palette.primary.light,
      '&:hover':{
        background: lighten(theme.palette.primary.light, 0.25),
      },
      '&$disabled': {
        background: lighten(theme.palette.primary.light, 0.5)
      },
    }
  }
})

export default compose(
  withStyles(styles),
)(RcpdPeaksWidget)