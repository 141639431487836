import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { RegistrationActions, ProgrammeActions, SubstationActions }    from 'actionsets'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, LabeledSelect, AutoSuggest,
         Tagger, MultiAutoSuggest, DatePicker, AvailabilitiesSummary } from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Chip from '@material-ui/core/Chip'
import { compose, Authorization } from 'utils'
import withStyles from 'styles'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import * as API from 'api'
import qs from 'qs'
import InputAdornment from '@material-ui/core/InputAdornment'
import { withPermissions } from 'containers/shared'
import AutoDRTypes from 'constants/AutoDRTypes'
import moment from 'moment'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CheckIcon from '@material-ui/icons/CheckCircle'
import HelpIcon from '@material-ui/icons/Help'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

export class Form extends InstanceFormMixin(Component){

  static requiredPermissions = props => {
    const organisationId = props.match.params.organisationId || (props.registration.organisation && props.registration.organisation.id)
    return organisationId ? {
      organisation: {
        [organisationId]: ['writeProgrammes']
      }
    } : {}
  }

  constructor(props){
    super(props)
    this.relationshipAttributes = ['sites', 'programme', 'substation', 'verificationMethod']

    this.state = {
      showOnlyEligible: true,
      registrationType: this.registrationType,
      formAttributes: {},
      rejectDialogOpen: false
    }
    RegistrationActions.bindActions(this)
    ProgrammeActions.bindActions(this, 'programmes')
    SubstationActions.bindActions(this, 'substations')
  }

  componentDidUpdate(nextProps, prevState){
    if(
      (prevState.formAttributes.programme || {}).id &&
       prevState.formAttributes.programme.id !== (this.state.formAttributes.programme || {}).id
    ){
      this.setState({
        formAttributes: {
          ...this.state.formAttributes,
          sites: [],
          substation: undefined
        }
      })
    }
  }

  dependsOn(){
    const actions = [
      this.loadRegistration()//.then(this.props.loadPermissions)
    ]
    actions.push(this.actions.programmes.index({
      pageSize: 100,
      filter: {
        active: true,
      },
      fields: {
        programmes: 'name,type,tags,startDate,endDate,availabilities,signalMappings'
      },
      include: 'availabilities'
    }))
    actions.push(
      this.actions.substations.index({
        organisationId: this.organisationId,
        pageSize: 100,
        fields: { substations: 'name' },
        filter: {
          active: true
        }
      })
    )
    return Promise.all(actions)
  }

  dependenciesMet(){
    return super.dependenciesMet() && !!this.props.permissions
  }

  loadRegistration = async () => {
    this.editMode ?
      await this.actions.show(this.objectId, {
        fields: {
          registrations: "name,startDate,endDate,minimumOfferWindowPeriodLength,"+
                          "fixedPrice,availabilityFee,prepurchasedHours,"+
                          "indicativePrice,status,rejectionReason,readOnly,"+
                          "sites,organisation,programme,tags,gxp,substation,"+
                          "useAggregateCbl,verificationMethod,initialEstablishmentFee,finalEstablishmentFee",
          gxps: "name,code",
          substations: "name,gxp",
          sites: "name,gxp",
          programme: "name",
          organisation: "name",
          verificationMethod: "name"
        },
        include: 'sites,substation,substation.gxp,gxp,sites.gxp,programme,organisation,verificationMethod,initialEstablishmentFee,finalEstablishmentFee'
      }) :
      this.actions.set({})
  }

  handleSuggestionsFetchSites = async (value, callback) => {
    const { data: results } = await API.Sites.index({
      organisationId: this.organisationId,
      options: {
        include: 'gxp',
        fields: {organisations: 'name', gxps: 'code'},
        filter: {
          name: value,
          active: true,
          ...((!!this.selectedProgramme.id && this.isNonPriceResponsive) && {covering_programme_availability: this.selectedProgramme.id}),
          ...(this.state.showOnlyEligible && {tags: this.selectedProgramme.tags ||{}}),
        }
      }
    })
    callback(results.filter(site1 => !this.formObject.sites.some(site2 => `${site1.id}` === `${site2.id}`)))
  }

  handleSuggestionsFetchGxps = async (value, callback) => {
    const { data: substations } = await API.Substations.index({
      organisationId: this.organisationId,
      options: {
        filter: {
          name: value,
          ...(this.state.showOnlyEligible && {tags: this.selectedProgramme.tags ||{}})
        }
      }
    })
    callback(substations)
  }

  handleSuggestionsFetchVerificationMethods = async (value, callback) => {
    const { data: verificationMethods } = await API.VerificationMethods.index({
       options: {
         fields: { verificationMethods: 'name' },
         ...(value && {filter: {name: value}})
       }
    })
    callback(verificationMethods)
  }

  handleChangeEligibleSitesVisibility = () => {
    this.setState({showOnlyEligible: !this.state.showOnlyEligible})
  }

  handleChangeUseAggregateCbl = () => {
    this.setState(({formAttributes},{registration}) =>
      ({formAttributes: {...formAttributes, useAggregateCbl:
        formAttributes.useAggregateCbl === undefined ?
          !registration.useAggregateCbl :
          !formAttributes.useAggregateCbl
      }})
    )
  }

  handleRegistrationTypeChange = (event) => {
    const { target: { value }} = event
    const newState = {registrationType: value, formAttributes: {...this.state.formAttributes, ...((value === 'substation') && {sites: []}), ...((value === 'sites') && {substation: undefined})}}
    this.setState(newState)
  }

  get formObject(){
    const selectedProgrammeId = (this.state.formAttributes.programme || {}).id
    const selectedProgramme = this.props.programmes.find(prg => `${prg.id}` === `${selectedProgrammeId}`) || {}
    const {initialEstablishmentFee=this.props.registration.initialEstablishmentFee || {}, finalEstablishmentFee=this.props.registration.finalEstablishmentFee || {}} = this.state.formAttributes
    return {
      organisationId: this.organisationId,
      programme: {id: this.programmeId },
      sites: [],
      ...this.props.registration,
      ...this.state.formAttributes,
      startDate: this.state.formAttributes.startDate || this.props.registration.startDate || moment(),
      endDate: this.state.formAttributes.endDate || this.props.registration.endDate || selectedProgramme.endDate,
      initialEstablishmentFee: {
        ...initialEstablishmentFee,
        date: initialEstablishmentFee.date || this.state.formAttributes.startDate || this.props.registration.startDate || moment()
      },
      finalEstablishmentFee: {
        ...finalEstablishmentFee,
        date: finalEstablishmentFee.date || this.state.formAttributes.endDate || this.props.registration.endDate || selectedProgramme.endDate,
      },
    }
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.registration.organisation && this.props.registration.organisation.id)
  }

  get programmeId(){
    this._query = (this._query ||  qs.parse(window.location.search.slice(1)))
    return this._query.programmeId
  }

  get selectedProgramme(){
    const programmeId = this.programmeId || (this.formObject.programme && this.formObject.programme.id)
    return this.props.programmes.find(prg => `${prg.id}` === `${programmeId}`) || {}
  }

  get isPriceResponsive(){
    return this.selectedProgramme.type === 'PriceResponsiveProgramme'
  }

  get isNonPriceResponsive(){
    return this.selectedProgramme.type === 'NonPriceResponsiveProgramme'
  }

  get requiresFixedPrice(){
    return this.isNonPriceResponsive && this.selectedProgramme.requiresFixedPrice
  }

  get requiresAvailabilityFee(){
    return this.isNonPriceResponsive && this.selectedProgramme.requiresAvailabilityFee
  }

  get requiresPrepurchasedHours(){
    return this.isNonPriceResponsive && this.selectedProgramme.requiresPrepurchasedHours
  }

  get allowsEstablishmentFee(){
    return this.selectedProgramme.id && this.selectedProgramme.allowsEstablishmentFee
  }

  get status(){
    return this.formObject.status || 'draft'
  }

  get permissionsLoaded(){
    return this.props.permissions.organisation && this.props.permissions.organisation[this.organisationId]
  }

  get canWriteProgramme(){
    try{
      return this.props.permissions.organisation[this.organisationId].writeProgrammes
    }catch(err){
      return false
    }
  }

  get registrationType(){
    return (this.props.registration && this.props.registration.substation) ? 'substation' : 'sites'
  }

  get gxpOptions(){
    return this.props.substations.reduce((agg, substation) => ({...agg, [substation.id]: substation.name}),{})
  }

  get error(){
    return this.props.errors[this.editMode ? 'update' : 'create'] || this.props.errors.event
  }

  get hasValidParticipationStatus(){
    return ['submitted', 'offer_window_open', 'offer_window_closed', 'scheduled', 'active', 'completed'].includes(this.status)
  }

  get totalEstablishmentFee(){
    try{
      return parseFloat(this.formObject.initialEstablishmentFee.amount) + parseFloat(this.formObject.finalEstablishmentFee.amount)
    }catch(err){
      return this.state.totalEstablishmentFee
    }
  }

  handleTotalEstablishmentFeeChanged = ({target: { value: totalEstablishmentFee }}) => {
    this.setState({
      totalEstablishmentFee,
      formAttributes: {
        ...this.state.formAttributes,
        initialEstablishmentFee: {
          amount: totalEstablishmentFee / 2,
          date: this.formObject.initialEstablishmentFee.date
        },
        finalEstablishmentFee: {
          amount: totalEstablishmentFee / 2,
          date: this.formObject.finalEstablishmentFee.date
        }
      }
    })
  }

  handleFormObjectChange(changes){
    if(`${changes.programme.id}` !== `${this.formObject.programme.id}`){
      delete changes.startDate
      delete changes.endDate
      if(changes.initialEstablishmentFee)
        delete changes.initialEstablishmentFee.date
      if(changes.finalEstablishmentFee)
        delete changes.finalEstablishmentFee.date
    }
    super.handleFormObjectChange(changes)
  }

  onSaveRedirect = ({data: {id}}) => `/registrations/${id}`

  handleTriggerEvent = status => async () => {
    const saveAction = this.editMode ?
      this.actions.update(this.attributesToSave,{include: 'sites.gxp,programme,organisation'}) :
      this.actions.create(this.attributesToSave,{include: 'sites.gxp,programme,organisation'})
    await this.save((async () => {
      const result = await saveAction
      const {data: { id }} = result
      await this.actions.event({id}, status)
      return result
    })())
  }

  renderRejectDialog = () =>
    <Dialog
      open={this.state.rejectDialogOpen}
      onClose={() => this.setState({rejectDialogOpen: false})}
    >
      <DialogTitle>Reject registration</DialogTitle>
      <DialogContent>
        <TextField fullWidth multiline
          member='rejectionReason'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({rejectDialogOpen: false})} color="primary">
          Cancel
        </Button>
        <Button onClick={this.handleTriggerEvent('reject')} color="primary">
          Reject
        </Button>
      </DialogActions>
    </Dialog>

  renderButtons(){
    switch(true){
    case this.canWriteProgramme:
      return (
        <Fragment>
          {['submitted','draft'].includes(this.status) && <Button className={this.props.classes.rejectBtn} variant='contained' onClick={() => this.setState({rejectDialogOpen: true})}>Reject</Button>}
          {this.status !== 'draft' && <Button color='secondary' variant='contained' onClick={this.save}>Save</Button>}
          {['submitted','draft'].includes(this.status) && <Button color='primary' variant='contained' onClick={this.handleTriggerEvent('approve')}>Approve</Button>}
        </Fragment>
      )
    case this.status !== 'draft':
      return false
    default:
      return (
        <Fragment>
          {
            this.status !== 'submitted' &&
            <Button color='secondary' variant='contained' onClick={this.handleTriggerEvent('submit')}>Submit</Button>
          }
        </Fragment>
      )
    }
  }

  renderSelection = () => {
    return (
      <Fragment>
        <Typography variant='h6'>Sites/Substation</Typography>
      { this.canWriteProgramme &&
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox checked={this.state.showOnlyEligible} value="eligibleOnly" onChange={this.handleChangeEligibleSitesVisibility}/>
            }
            label={`Show Only Eligible ${this.state.registrationType === 'substation' ? "Substations" : "Sites"}`}
          />
        </FormControl>
      }
      { this.canWriteProgramme &&
        <FormControl fullWidth>
          <FormLabel>Registration for</FormLabel>
          <RadioGroup value={this.state.registrationType} onChange={this.handleRegistrationTypeChange} row>
            <FormControlLabel value="sites" control={<Radio />} label="Site" />
            <FormControlLabel value="substation" control={<Radio />} label="Substation" />
          </RadioGroup>
        </FormControl>
      }
      { this.state.registrationType === 'sites' &&
        <Fragment>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.formObject.useAggregateCbl}
                  onChange={this.handleChangeUseAggregateCbl}
                />
              }
              label="Use Aggregated Baseline"
            />
          </FormControl>
          {this.formObject.useAggregateCbl &&
            <AutoSuggest
              fullWidth
              member='verificationMethod'
              labelProvider={({name}) => name}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchVerificationMethods}
              shouldRenderSuggestions={() => true}
            />
          }
        </Fragment>
      }
      {
        this.selectedProgramme.id &&
        <Fragment>
          { this.state.registrationType === 'sites' &&
            <MultiAutoSuggest
              labelProvider={({name, gxp: { code }}) => `${name} (${code})`}
              debounceWait={200}
              member='sites'
              onSuggestionsFetchRequested={this.handleSuggestionsFetchSites}
            />
          }
          { (this.state.registrationType === 'substation' && this.canWriteProgramme) &&
            <AutoSuggest
              required
              fullWidth
              member='substation'
              labelProvider={(({name}) => name)}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchGxps}
              className={this.hasValidParticipationStatus ? this.props.classes.readOnlyTextField : false}
            />
          }
        </Fragment>
      }
      {
        this.state.registrationType === 'sites' &&
        this.hasValidParticipationStatus &&
        <Tooltip title={'Sites selection is disabled when registration is ' + this.status}>
          <span>
            <IconButton disabled>
              <HelpIcon />
            </IconButton>
          </span>
        </Tooltip>
      }
      </Fragment>
    )
  }

  renderProgrammeMenu = () => {
    if(this.hasValidParticipationStatus) {
      // Render it readonly
      return (
        <Fragment>
          <TextField label="Programme" disabled member="programme.name" errorMember='programme' className={this.props.classes.readOnlyTextField} style={{ width: 500}}/>
          <Tooltip title={'Programme selection is disabled when registration is ' + this.status}>
            <span>
              <IconButton disabled>
                <HelpIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Fragment>
      )
    }

    return(
      <Fragment>
        <LabeledSelect
          required
          style={{ width: 500}}
          disabled={!!this.programmeId}
          member='programme.id'
          errorMember='programme'
          options={this.reduceNameIdPairs(this.props.programmes)}
          label="Programme"
          {...this.programmeId && {value: String(this.programmeId)}}          
        />
      </Fragment>
    )
  }

  render = () => {
    if(!this.permissionsLoaded) {
      return false
    }

    if(this.registrationType === 'substation' && !this.canWriteProgramme) {
      return <Redirect to="/unauthorized"/>
    }

    return (
      <Card className={this.props.classes.card}>
        <Typography variant='h5'>Edit Registration - {this.formObject.name}</Typography>
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange}>
          <div className={this.props.classes.statusLine}><Typography variant='body2'><strong>Status: </strong></Typography><Chip label={this.status}/></div>
          {this.renderErrorMessages()}
          <CardContent className={this.props.classes.cardContent}>
            <div className={this.props.classes.formFields}>
              <Typography variant='h6' className={this.props.classes.headerFields}>
                Fields
              </Typography>
              <TextField fullWidth member='name'/>
              <DatePicker required member='startDate'/>
              <DatePicker required member='endDate'/>
              {this.renderProgrammeMenu()}
              {
                this.selectedProgramme.id &&
                <div className={this.props.classes.programmeAttributes}>
                  {
                    this.selectedProgramme.signalMappings && Object.keys(this.selectedProgramme.signalMappings).length ?
                    <div className={this.props.classes.supportedAutoDrTypes}>
                      <Typography variant='h6'>Auto DR Types</Typography>
                      <List>
                      {
                        Object.entries(AutoDRTypes).map(([type, displayName]) =>
                          this.selectedProgramme.signalMappings && this.selectedProgramme.signalMappings[type] ?
                            <ListItem>
                              <ListItemIcon><CheckIcon/></ListItemIcon>
                              <ListItemText primary={displayName}/>
                            </ListItem> :
                            false
                        )
                      }
                      </List>
                    </div> : false
                  }
                  {
                    this.selectedProgramme.availabilities && this.selectedProgramme.availabilities.length ?
                      <AvailabilitiesSummary availabilities={this.selectedProgramme.availabilities}/> :
                      false
                  }
                </div>
              }
              {
                this.isPriceResponsive &&
                <Fragment>
                  <TextField fullWidth
                    type='number'
                    member='indicativePrice'
                    InputProps={
                      {
                        startAdornment: <InputAdornment position="start">$/MWh</InputAdornment>,
                      }
                    }
                  />
                </Fragment>
              }
              {
                this.isNonPriceResponsive &&
                <Fragment>
                  {
                    this.requiresFixedPrice &&
                    <TextField fullWidth
                      type='number'
                      member='fixedPrice'
                      InputProps={
                        {
                          startAdornment: <InputAdornment position="start">$/MWh</InputAdornment>,
                        }
                      }
                    />
                  }
                  {
                    this.requiresAvailabilityFee &&
                    <TextField fullWidth
                      type='number'
                      member='availabilityFee'
                      InputProps={
                        {
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }
                      }
                    />
                  }
                  {
                    this.requiresPrepurchasedHours &&
                    <TextField fullWidth
                    type='number'
                    member='prepurchasedHours'
                    InputProps={
                      {
                        startAdornment: <InputAdornment position="start">Hours</InputAdornment>,
                      }
                    }
                    />
                  }
                </Fragment>
              }
              {
                this.allowsEstablishmentFee ?
                <Fragment>
                  <Typography variant='h6'>Establishment Fee</Typography>
                  <TextField style={{fontWeight: 'bold'}} fullWidth type='number' label='Establishment Fee (Total)' value={this.totalEstablishmentFee || undefined} placeholder="0" onChange={this.handleTotalEstablishmentFeeChanged}
                    InputProps={
                      {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }
                    }
                  />
                  <TextField style={{flex: '1 1 50%'}} type='number' label='Establishment Fee (Initial  Payment)' placeholder="0" member='initialEstablishmentFee.amount'
                    InputProps={
                      {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }
                    }
                  />
                  <DatePicker disabled={!this.formObject.initialEstablishmentFee.amount} style={{flex: '1 1 50%'}} required label='Due Date (Initial Payment)' member='initialEstablishmentFee.date'/>

                  <TextField style={{flex: '1 1 50%'}} type='number' label='Establishment Fee (Final  Payment)' placeholder="0" member='finalEstablishmentFee.amount'
                    InputProps={
                      {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }
                    }
                  />
                  <DatePicker  disabled={!this.formObject.finalEstablishmentFee.amount} style={{flex: '1 1 50%'}} required label='Due Date (Final  Payment)' member='finalEstablishmentFee.date'/>
                </Fragment> : false
              }
              <div style={{flex: '1 0 100%'}}/>
              { this.renderSelection() }
              { this.canWriteProgramme && ['submitted','draft'].includes(this.status) &&
                this.renderRejectDialog()
              }
            </div>
            {
              Authorization.systemPermissions.writeTags &&
              <div className={this.props.classes.tags}>
                <Typography variant='h6'>
                  Tags
                </Typography>
                <Tagger member='tags'/>
              </div>
            }

          </CardContent>
          <CardActions className={this.props.classes.actions}>
            { this.renderButtons() }
          </CardActions>
        </FormContext>
      </Card>
    )
  }
}

const styles = theme => ({
  rejectBtn: {
    background: theme.palette.error.main,
    justifySelf: 'flex-start',
    color: 'white'
  },
  actions: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    padding: 20,
    margin: '0 auto',
    '& > form': {
      width: '100%'
    }
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    minWidth: 300,
    marginBottom:10,
    '& > div': {
      flex: '1 1 auto'
    }
  },
  tags: {
    flex: 1,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  },
  statusLine: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: '5px'
    }
  },
  programmeAttributes: {
    flex: '1 1 100% !important',
    marginTop: 10,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  supportedAutoDrTypes: {
    display: 'flex',
    flexDirection: 'column'
  },
  readOnlyTextField: {
    pointerEvents: 'none',
    '& div::before': {
      borderBottomStyle: 'dotted',
    },
    '& div::after': {
      borderBottomStyle: 'dotted',
    },
    '& div': {
      cursor: 'default',
    },
    '& label': {
    },
    '& input': {
      opacity: 1,
      cursor: 'default',
    }
  }
})

export default compose(
  Dependent({clearOnLoad: true, loader: true}),
  withPermissions(Form.requiredPermissions),
  withStyles(styles),
  connect(({registrations, programmes: { programmes }, substations: { substations }}) => ({...registrations, programmes, substations } )),
)(Form)
