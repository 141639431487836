import React, { Component } from 'react'
import { connect } from 'react-redux'
import { OptActions, SnackbarActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { compose, userFriendlyDate } from 'utils'
import withStyles from 'styles'
import { withRouter } from 'react-router-dom'
import TimeIcon from '@material-ui/icons/AccessTime'
import DeviceIcon from '@material-ui/icons/Devices'
import TargetIcon from '@material-ui/icons/CropFree'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import WidgetContainer from 'containers/landing/widgets/WidgetContainer'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment'

export class OptSchedule extends Component{

  constructor(props){
    super(props)
    OptActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    this.actions.show(this.id, {
      include: 'deviceClasses.endDeviceAsset,availabilities,targets'
    })
  }

  dependenciesMet(){
    return !!this.props.opt.id
  }

  get opt(){
    return (this.props.opt || { endDeviceAssets: [], availabilities: [], targets: []})
  }

  get id(){
    return this.props.match.params.id
  }

  get endDeviceAssets(){
    const result = this.opt.deviceClasses.map(({endDeviceAsset}) => endDeviceAsset)
    return result
  }

  get availabilities(){
    return this.opt.availabilities
  }

  get targets(){
    return this.opt.targets
  }

  availabilityTimeSpan = ({dtstart, duration}) => {
    const dtfinish = moment(dtstart).add(moment.duration(duration))
    return `${userFriendlyDate(dtstart)} – ${userFriendlyDate(dtfinish)}`
  }

  renderEndDeviceAsset = ({id, name}) =>
    <ListItem key={id}>
      <ListItemIcon>
        <DeviceIcon/>
      </ListItemIcon>
      <ListItemText primary={name}/>
    </ListItem>

  renderAvailability = ({id, dtstart, duration}) =>
    <ListItem key={id}>
      <ListItemIcon>
        <TimeIcon/>
      </ListItemIcon>
      <ListItemText primary={this.availabilityTimeSpan({dtstart, duration})} secondary={moment.duration(duration).humanize()}/>
    </ListItem>

  renderTarget = ({id, oadrType, value}) =>
    <ListItem key={id}>
      <ListItemIcon>
        <TargetIcon/>
      </ListItemIcon>
      <ListItemText primary={value} secondary={oadrType}/>
    </ListItem>

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <Typography className={this.props.classes.title} variant='h4'>Opt – {this.opt.optId}</Typography>
        <br/>
        <Typography variant='h6'>
          Opted {this.opt.optType === 'optIn' ? 'in' : 'out'}&emsp;
        {this.opt.optType === 'optIn' ? <ThumbUpIcon style={{marginBottom: -5}}/> : <ThumbDownIcon style={{marginBottom: -5}}/>}
        </Typography>
        <hr/>
        <div className={this.props.classes.widgetContainer}>
          <WidgetContainer>
            <Typography variant='h6'>Availabilities</Typography>
            <MuiList dense>
              {this.availabilities.map(this.renderAvailability)}
            </MuiList>
          </WidgetContainer>
          <WidgetContainer>
            <Typography variant='h6'>Targets</Typography>
            <MuiList dense>
              {this.targets.map(this.renderTarget)}
            </MuiList>
          </WidgetContainer>
          <WidgetContainer>
            <Typography variant='h6'>End Device Assets</Typography>
            <MuiList dense>
              {this.endDeviceAssets.map(this.renderEndDeviceAsset)}
            </MuiList>
          </WidgetContainer>
        </div>
      </CardContent>
    </Card>
}


const styles = theme => ({
  widgetContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  }
})


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withStyles(styles),
  withRouter,
  connect(({opts}) => opts)
)(OptSchedule)