import moment from 'moment'

export function userFriendlyDate(date){
  return moment(date).format('MMM Do YY, h:mm:ss a')
}

export function roundDateOrMoment(date, nearestMins, floor=true){
  const duration = moment.duration(nearestMins, 'minute')
  return moment(Math[floor ? 'floor' : 'round']((+date) / (+duration)) * (+duration))
}

export function formatLocaleDate(date, {includeYear=true}={}){
  return moment(date).format(`DD/MM${includeYear ? '/YYYY' : ''}`)
}

export function formatLocaleDateTime(date, {includeYear=true, includeSeconds=false}={}){
  return moment(date).format(`DD/MM${includeYear ? '/YYYY' : ''} HH:mm${includeSeconds ? ':ss' : ''}`)
}