import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { BCTIActions, OrganisationActions, PaymentActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, MultiAutoSuggest }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Authorization , compose } from 'utils'
import withStyles from 'styles'
import { withPermissions } from 'containers/shared'
import TextField from '@material-ui/core/TextField'

export class Form extends InstanceFormMixin(Component){

  static requiredPermissions = () => ({
    organisation: {
      [Authorization.organisationId]: ['createPayments']
    }
  })

  constructor(props){
    super(props)
    this.relationshipAttributes = ['payments']
    BCTIActions.bindActions(this)
    PaymentActions.bindActions(this, 'payments')
    OrganisationActions.bindActions(this, 'organisations')
  }

  dependsOn(){
    if(this.editMode){
      return this.actions.show(this.objectId, {
        fields: {
          organisations: 'name'
        },
        include: 'organisation'
      })
    }else{
      return this.actions.set()
    }
  }

  get formObject(){
    return {organisationId: this.organisationId, ...this.props.bcti, ...this.state.formAttributes}
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.organisationRole && this.props.organisationRole.organisation.id)
  }

  handleInvoiceSelected = ({target: { files: { "0": invoice }}}) => {
    this.handleFormObjectChange({...this.formObject, invoice })
  }

  handleFetchPayments = async (text, callback) => {
    const { data: payments } = await this.actions.payments.index( {
      params: {
        organisationId: this.organisationId,
      },
      filter: {
        unpaid: true,
        active: true,
        excludedIds: this.formObject.payments ? this.formObject.payments.map(({id}) => id) : [],
        ...text && {name: text}
      }
    })
    callback(payments.filter(({id}) => !(this.formObject.payments || []).some(({id: pId}) => `${pId}` === `${id}`)))
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Invoice - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField type='file' InputProps={{inputProps: {accept: "application/pdf"}}} onChange={this.handleInvoiceSelected}/>
          <MultiAutoSuggest
            label='Payments'
            fullWidth
            member='payments'
            onSuggestionsFetchRequested={this.handleFetchPayments}
          />
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  withPermissions(Form.requiredPermissions, {
    checkPermissions: (permissions) =>
       permissions.organisation &&
       permissions.organisation[Authorization.organisationId].createPayments
  }),
  connect(({bctis}) => bctis),
)(Form)
