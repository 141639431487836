import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const ChildEvents = ProtectedJsonAPI.extend(
  '',
  {
    index:       endpoint => endpoint.path(({parent_event_id}) => parent_event_id ? `parent_events/${parent_event_id}/child_events` : '/child_events'),
    create:      endpoint => endpoint.method('post').path(({parent_event_id}) => `parent_events/${parent_event_id}/child_events`),
    update:      endpoint => endpoint.method('put').path(({id}) => `child_events/${id}`),
    destroy:     endpoint => endpoint.method('delete').path(({id}) => `child_events/${id}`),
    showCBL:     endpoint => endpoint.method('get').path(({id}) => `child_events/${id}/cbl`),
    downloadCBL: endpoint => endpoint.method('post').useForm(true).path(({id}) => `child_events/${id}/cbl/download`).paramsHandler(params => ({...params, Authorization: global.localStorage.auth})),
    show:        endpoint => endpoint.path(({id}) => `child_events/${id}`),
    event:       endpoint => endpoint.method('post').path(({id}) => `child_events/${id}/events`),
    export:      endpoint => endpoint.method('post').useForm(true).path(() => `child_events/export`).paramsHandler(params => ({...params, Authorization: global.localStorage.auth})),
  }
)