import ActionSet from './ActionSet'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'
import * as API from 'api'

export class MonthlyReportActionSet extends ActionSet {
  static initialState = {
    monthlyReport: {
      numberEmailsOrTextsSent: {},
      dbStorageInfo: {}
    },
    requests: [],
    errors: {
      show: null
    }
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]

  static show(creator, reducer, constants) {

    constants.defineRequestConstants()

    creator(({params = {}, fields=null, include=null, filter=null }={}) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: API.MonthlyReports.show({...params, options: {...filter && {filter}, ...fields && {fields}, ...include && {include} }}),
        payload: { type: params.type }
     })
   })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, monthlyReport: this.initialState.monthlyReport, requests: [...state.requests, request], errors: this.initialState.errors}
      },
      [constants.SUCCESS]: (state, { request, result: { data
      } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, monthlyReport: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = { ...state.errors, show: error }
        return { ...state, requests, errors }
      }
    })
  }
}

export default new MonthlyReportActionSet()
