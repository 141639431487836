import React, { Component } from 'react'
import WidgetContainer from 'containers/landing/widgets/WidgetContainer'
import Typography from '@material-ui/core/Typography'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { compose } from 'utils'
import { withRouter } from 'react-router-dom'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { flatten } from 'utils'

export class DeviceOpts extends Component{


  renderOpt = ({optId, optType, id}) =>
    <ListItem button onClick={this.handleShowOpt(id)} key={id}>
      <ListItemIcon>
        {optType === 'optIn' ? <ThumbUpIcon /> : <ThumbDownIcon/>}
      </ListItemIcon>
      <ListItemText primary={optId}/>
    </ListItem>


  handleShowOpt = id => () => {
    this.props.history.push(`/oadr/opts/${id}`)
  }

  render = () => {
    const {device: { optSchedules }} = this.props
    const opts = flatten(optSchedules.map(({opts}) => opts))

    return (
      <WidgetContainer>
        <Typography variant='h6'>Opt Schedules</Typography>
         <Card>
          <CardContent>
            <MuiList dense>
              {opts.map(this.renderOpt)}
            </MuiList>
          </CardContent>
        </Card>
      </WidgetContainer>
    )
  }
}

export default compose(
  withRouter
)(DeviceOpts)