import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { OrganisationActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import {User, Organisation, Role, BCTIs, Programme, Site, Registration, Device, Substation} from 'containers'
import { compose } from 'utils'
import { withPermissions } from 'containers/shared'
import withStyles from 'styles'
import { Show } from '.'
import { LoadMapWidget } from 'containers/landing/widgets'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import MenuIcon from '@material-ui/icons/Menu'
import InfoIcon from '@material-ui/icons/Info'
import UserIcon from '@material-ui/icons/Person'
import RoleIcon from '@material-ui/icons/People'
import ProgrammeIcon from '@material-ui/icons/Assignment'
import RegistrationIcon from '@material-ui/icons/AssignmentTurnedIn'
import DeviceIcon from '@material-ui/icons/SettingsRemote'
import GxpIcon from '@material-ui/icons/LocationCity'
import SubstationIcon from '@material-ui/icons/BrokenImage'
import SiteIcon from '@material-ui/icons/LocationOn'
import InvoiceIcon from '@material-ui/icons/AttachMoney';
import RightIcon from '@material-ui/icons/ChevronRight'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import {capitalize, Authorization} from 'utils'

const requiredPermissions = [
  'readOrganisation', 'createOrganisations',
  'readUsers', 'readOrganisation', 'readSites', 'readDevices',
  'writeUsers', 'writeOrganisation', 'writeSites', 'writeDevices',
  'readSubstations', 'writeSubstations','createRegistrations',
  'readControlRoom', 'readPayments', 'readProgrammes','accessRcpd'
]

export class Dashboard extends Component{
  state = {
    organisationsOpen: false,
    permissions: {}
  }

  static requiredPermissions = ({match: { params: { id, childId }}}) => ({
    organisation: {
      [id]: requiredPermissions,
      [childId]: requiredPermissions
    }
  })

  constructor(props){
    super(props)
    OrganisationActions.bindActions(this)
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.childId !== prevProps.match.params.childId || this.props.match.params.id !== prevProps.match.params.id) {
      this.loadOrganisation()
    }

    if (this.props.permissions !== prevProps.permissions) {
      this.updatePermissions()
    }
  }

  componentDidMount = () => {
    if (this.props.permissions) {
      this.updatePermissions()
    }
  }

  updatePermissions = () => {
    this.setState({
      permissions: {
        ...this.props.permissions,
        organisation: {...this.state.permissions.organisation, ...this.props.permissions.organisation}
      }
    })
  }

  async dependsOn(){
    try {
      await this.loadOrganisation()
    } catch (ex) {}
  }

  loadOrganisation = async() => {
    if (!this.parentOrganisation || `${this.parentOrganisation.id}` !== this.parentId) {
      await this.actions.show(this.parentId, {include: 'roles,primaryContact,secondaryContact,ancestors'})
    }
    if (this.parentId !== this.id) {
      await this.actions.show(this.id, {include: 'roles,primaryContact,secondaryContact,ancestors', name: 'childOrganisation'})
    }
  }

  dependenciesMet(){
    return (this.organisation.id === this.id) || !this.props.permissions.readOrganisation
  }

  handleTabChange = (event, tab) =>
    this.props.history.push(`/organisations/${this.parentId}/${tab}/${this.id}`)

  get parentId(){
    return this.props.match.params.id
  }

  get id() {
    return this.props.match.params.childId || this.parentId
  }

  get parentOrganisation(){
    return this.props.organisation
  }

  get organisation(){
    return (this.id !== this.parentId ? this.props.childOrganisation : this.parentOrganisation) || {}
  }

  get organisationPermissions(){
    return (this.state.permissions.organisation || {})[this.id] || {}
  }

  get parentOrganisationPermissions(){
    return (this.state.permissions.organisation || {})[this.parentId] || {}
  }

  get ancestors() {
    if (this.organisation.ancestors && this.organisation.ancestors.length) {
      const ancestors = [...this.organisation.ancestors]
      let root
      while (ancestors.length && (root = ancestors.pop()).id !== Authorization.organisationId) {
        root = null
      }
      if (root)
        ancestors.push(root)

      return ancestors.length ? [this.organisation, ...ancestors].reverse() : []
    }
    else {
      return []
    }
  }

  get systemPermissions(){
    return this.state.permissions.system || {}
  }

  get permissions(){
    return {
      ...this.organisationPermissions,
      ...this.systemPermissions,
    }
  }

  get parentPermissions(){
    return {
      ...this.parentOrganisationPermissions,
      ...this.systemPermissions,
    }
  }

  get tab() {
    return this.props.match.params.tab || 'details'
  }

  canView = tab => {
    switch(tab){
    case 'users':         { return this.permissions.readUsers }
    case 'organisations': { return this.parentPermissions.createOrganisations }
    case 'details':       { return this.permissions.readOrganisation }
    case 'substations':   { return this.permissions.readSubstations }
    case 'sites':         { return this.permissions.readSites }
    case 'registrations': { return this.permissions.readSites }
    case 'devices':       { return this.permissions.readDevices }
    case 'roles':         { return this.permissions.readUsers || this.permissions.readOrganisation}
    case 'gxps':          { return (this.organisation.participantCodes || []).length > 0 && this.permissions.readControlRoom }
    case 'invoices':      { return this.permissions.readPayments }
    case 'programmes':    { return this.permissions.readProgrammes }
    default: return false
    }
  }

  handleShowOrganisations = event => {
    this.setState({organisationsOpen: true})
    event.stopPropagation()
  }

  renderDetailsTab = () =>
    <>
      <div className={this.props.classes.firstRow}>
        <Show permissions={this.props.permissions} organisation={this.organisation}/>
        <LoadMapWidget organisationId={this.id} widgetContainerClasses={{widgetContainerBorder: this.props.classes.mapWidgetContainerBorder}}/>
      </div>
    </>


  renderUsersTab = () =>
    <>
      <Typography variant='h5'>Users</Typography>
      <User.List organisationPermissions={this.permissions} organisationId={this.id}/>
    </>

  renderOrganisations = () =>
    <>
      <Typography variant='subtitle2'>Child Organisations</Typography>
      <Organisation.List organisationPermissions={this.permissions} active={this.id} organisationId={this.parentId} uncontained classes={{addButton: this.props.classes.addButton}}/>
    </>

  renderSitesTab = () =>
    <>
      <Typography variant='h5'>Sites</Typography>
      <Site.List permissions={this.props.permissions} organisationId={this.id}/>
    </>

  renderRegistrationsTab = () =>
    <>
      <Typography variant='h5'>Registrations</Typography>
      <Registration.List permissions={this.props.permissions} organisationId={this.id}/>
    </>

  renderDevicesTab = () =>
    <>
      <Typography variant='h5'>Devices</Typography>
      <Device.List permissions={this.props.permissions} organisationId={this.id}/>
    </>

  renderRolesTab = () =>
    <Role.List borderless organisationId={this.id} permissions={this.props.permissions}/>

  renderGxpsTab = () =>
    <>
      <Typography variant='h5'>GXPs</Typography>
      <Organisation.OwnedGxps organisationId={this.id} permissions={this.props.permissions}/>
    </>

  renderSubstationsTab = () =>
    <>
      <Typography variant='h5'>Substations</Typography>
      <Substation.List organisationId={this.id} permissions={this.props.permissions}/>
    </>

  renderInvoicesTab = () =>
    <>
      <Typography variant='h5'>Invoices</Typography>
      <BCTIs.List organisationId={this.id} permissions={this.props.permissions}/>
    </>

  renderProgrammesTab = () =>
    <>
      <Typography variant='h5'>Programmes</Typography>
      <Programme.List organisationId={this.id} pageSize={10}/>
    </>

  renderParentOrganisationName = () => {
    return this.parentOrganisation.logoUrlPath ?
      <img alt={this.parentOrganisation.name} src={this.parentOrganisation.logoUrlPath}/>
      :
      <Typography variant='h5' className={this.props.classes.nameHeader}>
        {this.parentOrganisation.name}
      </Typography>
  }

  render = () =>
    <Card width='xl' className={this.props.classes('card', {withOrganisations: this.canView('organisations')})} onClick={() => this.setState({organisationsOpen: false})}>
      {this.canView('organisations') &&
      <div className={this.props.classes.leftPane} style={{...this.state.organisationsOpen && {display: 'block'}}}>
        <Link className={this.props.classes('myOrganisation', {active: this.id === this.parentId})}
            to={`/organisations/${this.parentId}/${this.tab}`}>
          { this.renderParentOrganisationName() }
        </Link>
        {this.renderOrganisations()}
      </div>
      }
      <div className={this.props.classes.rightPane}>
        <Tabs value={this.tab} onChange={this.handleTabChange} variant='fullWidth' className={this.props.classes.tabs}>
          { this.canView('details') && <Tab value='details' icon={<InfoIcon/>} label='Details'/>}
          { this.canView('users') && <Tab value='users' icon={<UserIcon/>} label='Users'/>}
          { this.canView('roles') && <Tab value='roles' icon={<RoleIcon/>} label='Roles'/>}
          { this.canView('programmes') && <Tab value='programmes' icon={<ProgrammeIcon/>} label='Programmes'/>}
          { this.canView('details') && <Tab value='registrations' icon={<RegistrationIcon/>} label='Registrations'/>}
          { this.canView('sites') && <Tab value='sites' icon={<SiteIcon/>} label='Sites'/>}
          { this.canView('devices') && <Tab value='devices' icon={<DeviceIcon/>} label='Devices'/>}
          { this.canView('invoices') && <Tab value='invoices' icon={<InvoiceIcon/>} label='Invoices'/>}
          { this.canView('gxps') && <Tab value='gxps' icon={<GxpIcon/>} label='GXPs'/>}
          { this.canView('substations') && <Tab value='substations' icon={<SubstationIcon/>} label='Substations'/>}
        </Tabs>
        <CardContent className={this.props.classes.cardContent}>
          {this.canView('organisations') &&
            <Button onClick={this.handleShowOrganisations}
                    className={this.props.classes.showOrganisation}>
              <MenuIcon/> Organisation
            </Button>
          }
          <ul className={this.props.classes.breadcrumbs}>
            {this.ancestors.map(({name, id}, index, items) =>
              <li key={id}>
                <Link to={`/organisations/${id}`}>
                  <Button>{name}</Button>
                </Link>
                {index < items.length - 1 && <RightIcon />}
              </li>
            )}
          </ul>
          { this[`render${capitalize(this.tab)}Tab`]() }
        </CardContent>
      </div>
    </Card>
}

const styles = ({palette}) => ({
  wrapper: {
    // borderBottom: "1px solid #efefef"
  },
  tabs: {
    minHeight: 72,
    '& button': {
      flex: 1,
      minWidth: 0,
      flexBasis: 10,
      '& > span > span': {
        paddingLeft: 6,
        paddingRight: 6,
        '& > span': {
          width: '100%',
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '0.66rem',
          '@media(min-width: 600px)': {
            fontSize: '0.8125rem'
          }
        }
      }
    }
  },
  card: {
    width: 'calc(100% - 10px)',
    textAlign: 'left',
    display: 'flex',
    '&$withOrganisations': {
      maxWidth: 'none',
      minHeight: 'calc(100vh - 75px)',
    }
  },
  withOrganisations: {},
  cardContent: {
    '@media(min-width: 1024px)': {
      padding: 28,
    }
  },
  leftPane: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: 'rgba(10, 55, 78, 0.97)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media(min-width: 1024px)': {
      position: 'relative',
      display: 'block',
      backgroundColor: 'rgba(0, 27, 38, 0.5)',
      overflow: 'visible'
    },
    flex: '0 0 340px',
    width: 360,
    maxWidth: 360,
    padding: '0 12px',
    '& ul': {
      margin: '0 -12px',
      padding: '0 0 16px',
      '& > div': {
        padding: '13px',
      }
    },
  },
  rightPane: {
    flex: '1 1 auto',
    '& div': {
      maxWidth: 'none'
    },
    overflow: 'hidden',
  },
  mainDetails: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  },
  additionalDetails: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  },
  dashboard: {
    padding: 5,
    flex: '1 1 auto',
    background: 'linear-gradient(to bottom, #118FCB 64px, #062D44 100%)',
    backgroundAttachment: 'fixed',
  },
  dashboardInner: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  table: {
    marginTop: 25,
    borderTop: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    background: 'white'
  },
  head: {
    background: "#FAFAFA",
    borderRight: '2px solid #DEDEDE',
    borderLeft: '2px solid #DEDEDE',
    color: palette.secondary.dark,
    margin: '10px 0',
    fontWeight: 'bold'
  },
  orgTitle:{
    margin: '0 20px',
  },
  widgetContainer: {
    paddingBottom: 40
  },
  addButton: {
    position: 'fixed',
    bottom: 6,
    left: 278
  },
  active: {
    background: 'rgba(255,255,255,0.15) !important',
    '&::after': {
      content: "''",
      position: 'absolute',
      right: -25,
      top: 0,
      borderLeft: '25px solid rgba(255,255,255,0.15)',
      borderTop: '35px solid transparent',
      borderBottom: '35px solid transparent',
    },
  },
  myOrganisation: {
    display: 'block',
    padding: '0',
    lineHeight: 0,
    margin: '0 -12px 5px',
    '&:hover': {
      background: 'rgba(255,255,255,0.1)',
    },
    '& > img': {
      width: 360,
      height: 72
    },
    '& h5': {
      lineHeight: 1,
      fontSize: 22,
      margin: 0,
    }
  },
  breadcrumbs: {
    margin: '0 0 12px -8px',
    padding: 0,
    listStyle: 'none',
    '& li': {
      display: 'inline-block',
    }
  },
  showOrganisation: {
    marginLeft: -8,
    '& svg': {
      marginRight: 10
    },
    '@media(min-width: 1024px)': {
      display: 'none'
    }
  },
  firstRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  mapWidgetContainerBorder: {
    border: `1px solid ${palette.primary.light}`
  },
  iconHeaderLeft: {
    width: 32,
    height: 32,
    paddingRight: 18,
    boxSizing: 'content-box',
  },
  nameHeader: {
    margin: '5px 0',
    paddingRight: 18,
    display: 'inline-flex',
    alignItems: 'center',
  },
})
export default compose(
  Dependent({loader: true, clearOnLoad: false}),
  withPermissions(Dashboard.requiredPermissions, {unmountOnChange: false}),
  withStyles(styles),
  connect(({organisations}) => organisations)
)(Dashboard)
