import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const OrganisationRoles = ProtectedJsonAPI.extend(
  '',
  {
    index:           endpoint => endpoint.path(({organisationId}) => `organisations/${organisationId}/organisation_roles`),
    create:          endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/organisation_roles`),
    update:          endpoint => endpoint.method('put').path(({id}) => `organisation_roles/${id}`),
    destroy:         endpoint => endpoint.method('delete').path(({id}) => `organisation_roles/${id}`),
    show:            endpoint => endpoint.path(({id}) => `organisation_roles/${id}`)
  }
)