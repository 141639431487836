import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class JobActionSet extends CrudActionSetBase('job'){
  static event(creator, reducer, constants){

  constants.defineRequestConstants()

  creator(({id}, event) => {
    return {
      type: constants.ACTION,
      promise: API.Jobs.event({id, name: event})
    }
  })

  reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, index: error }
        return {...state, requests, errors }
      }
    })
  }
}

export default new JobActionSet()