import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LoginFailureActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, DateTimePicker } from 'components'
import Dependent from 'containers/shared/Dependent'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import MuiList from '@material-ui/core/List';
import WarningIcon from '@material-ui/icons/Warning'
import { compose, errorStringsFromError } from 'utils'
import { FormContext } from 'components'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

export class List extends Component{

  constructor(props){
    super(props)
    LoginFailureActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    return this.loadLoginFailures()
  }

  loadLoginFailures = page => {
    return this.actions.index({
      ...(page && {page}),
      duration: this.props.duration,
      fields: {
        login_failures: 'email,block_begin,block_end,count,origin,user',
        users: 'name,email'
      },
      include: 'user'
    });
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  get loginFailures(){
    return this.props.loginFailures
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  showUser = id => event => {
    this.props.history.push()
    event.stopPropagation()
  }

  setDuration = async duration => {
    await this.actions.setDuration(duration)
    this.loadLoginFailures()
  }

  renderLoginFailureListItem = ({id,blockBegin,blockEnd,count,email,origin,user}) =>
    <ListItem key={id}>
      <ListItemIcon>
        <WarningIcon style={{color: 'rgb(222, 53, 11)'}} />
      </ListItemIcon>
      <ListItemText primary={
        <React.Fragment>
          {count + " failed login attempt" + (count > 1 ? "s" : "") + " for "}
          {user ? <Link to={`/users/${id}`} style={underlined}>{user.name}</Link> : email}
          {" from " + origin + (blockBegin === blockEnd ? " at " + new Date(blockBegin) : " between " + new Date(blockBegin) + " and " + new Date(blockEnd))}
        </React.Fragment>
      }/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h6'>
          Login Failures
        </Typography>
        {this.renderErrorMessages()}
        <CardActions>
          <FormContext context={this.props.duration} onChange={this.setDuration}>
            <DateTimePicker
              member='beginning'
              label='Show from'
            />
            <DateTimePicker
              member='ending'
              label='... to'
            />
          </FormContext>
        </CardActions>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadLoginFailures} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.loginFailures.map(this.renderLoginFailureListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadLoginFailures} style={{}} linkStyle={{}}/>
      </CardContent>
    </Card>
}

const underlined = {'textDecoration': 'underline'}

export default compose(
  Dependent({loader: true}),
  connect(({loginFailures}) => loginFailures)
)(List)
