import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BCTIActions } from 'actionsets'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton'
import { compose } from 'utils'
import withStyles from 'styles'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

export class Show extends Component{

  constructor(props){
    super(props)
    BCTIActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id, {
      fields: {
        bctis: 'name,payments,invoiceUrlPath',
        payments: 'childEvent,settlementAmount',
        childEvents: 'registration,parentEvent',
        registrations: 'name',
        parentEvents: 'start,end,programme',
        programmes: 'name'
      },
      include: 'payments.childEvent.registration,payments.childEvent.parentEvent.programme'
    })
  }

  handleDownloadFile = url => event => {
    event.preventDefault()
    event.stopPropagation()
    window.open(url)
  }

  dependenciesMet(){
    return !!this.bcti.id
  }

  get id(){
    return this.props.match.params.id
  }

  get bcti(){
    return this.props.bcti
  }

  renderPayment = ({id, settlementAmount, childEvent: { id: childEventId, registration: { name: registrationName}, parentEvent: { start, end, programme: { name: programmeName} }}}) =>
    <ListItem key={id} button onClick={() => this.props.history.push(`/child_events/${childEventId}`)}>
      <ListItemText
        primary={`Payment of $${settlementAmount} for ${registrationName}`}
        secondary={`${programmeName} event pm date ${moment(start).format('DD/MM')} between ${moment(start).format('HH:mm')}—${moment(end).format('HH:mm')}`}
      />
    </ListItem>

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <div className={this.props.classes.formFields}>
          <Typography variant='h4'>Invoice - {this.bcti.name}</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
                <TableCell>
                  <IconButton onClick={this.handleDownloadFile(this.bcti.invoiceUrlPath)}>
                    <CloudDownloadIcon/>
                  </IconButton>
                </TableCell>
              </TableRow>
                <TableRow>
                  <TableCell variant='head'>Name</TableCell>
                  <TableCell>{String(this.bcti.name)}</TableCell>
                </TableRow>
            </TableBody>
          </Table>
          <MuiList>
            {(this.props.bcti.payments || []).map(this.renderPayment)}
          </MuiList>
        </div>
      </CardContent>
    </Card>
}

const styles = {
  formFields: {
    alignContent: 'baseline',
    flex: '2 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: "10px 10px 0 0",
    minWidth: 300,
    marginBottom: 10
  }
}


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withStyles(styles),
  connect(({bctis}) => bctis)
)(Show)