export function debounce(fnc, wait, context){
  let timer, promise, resolve
  return function () {
    promise = promise || new Promise(r => resolve = r)
    clearTimeout(timer)
    timer = setTimeout(() => {
      resolve(fnc.bind(context)(...arguments))
      promise = null
    }, wait || 250)
    return promise
  }
}

export function throttle(fnc, wait, context){
  let isQueued = false
  let promise, resolve, result

  return function () {
    if(isQueued) return promise
    isQueued = true
    promise = new Promise(r => resolve = r)
    result = fnc.bind(context)(...arguments)
    setTimeout(() => {
      resolve(result)
      isQueued = false
      promise = null
    }, wait || 250)
    return promise
  }
}

// Returns a function which will only run one instance of an asynchronous task
// at any given time. It will also only enqueue one additional instance;
// subequent calls will replace the arguments used to create the next instance.
export function deparallelize(fnc, context){
  let isQueued = false
  let promise, pending, nextPromise, resolve, reject, pendingArgs

  return function() {
    pendingArgs = arguments
    pending = () => {
      pending = null
      isQueued = false
      promise = fnc.bind(context)(...pendingArgs)
      if(resolve) {
        promise.then(resolve, reject)
      }
      nextPromise = null
      resolve = null
      reject = null
    }
    if(promise) {
      if(!isQueued){
        promise.then(pending, pending)
        isQueued = true
      }
      if(!nextPromise) {
        nextPromise = new Promise((rs,rj) => {
          resolve = rs
          reject  = rj
        })
      }
      return nextPromise
    } else {
      pending()
      return promise
    }
  }
}

export function timeout(time=0){
  return new Promise(resolve => setTimeout(resolve, time))
}
