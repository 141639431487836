import React, { Component } from 'react'
import { connect } from 'react-redux'
import { VerificationMethodActions, SnackbarActions } from 'actionsets'
import { Pagination, FABFixed, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'

export class List extends Component{

  constructor(props){
    super(props)
    VerificationMethodActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    return this.loadVerificationMethods()
  }

  loadVerificationMethods = page => {
    return this.actions.index({
      ...(page && {page}),
      fields: {verification_methods: 'name'}
    });
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showVerificationMethod = id => () => {
    this.props.history.push(`/verification_methods/${id}`)
  }

  editVerificationMethod = id => event => {
    this.props.history.push(`/verification_methods/${id}/edit`)
    event.stopPropagation()
  }

  deleteVerificationMethod = id => event => {
    this.actions.destroy({id})
                .then(() => this.actions.index())
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get verificationMethods(){
    return this.props.verificationMethods
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderVerificationMethodListItem = ({id, name}) =>
    <ListItem button onClick={this.showVerificationMethod(id)} key={id}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editVerificationMethod(id)}><EditIcon/></IconButton>
        <IconButton onClick={this.deleteVerificationMethod(id)}><DeleteIcon/></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadVerificationMethods} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.verificationMethods.map(this.renderVerificationMethodListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadVerificationMethods} style={{}} linkStyle={{}}/>
        <FABFixed color='primary' onClick={() => this.props.history.push('/verification_methods/new')}>
          <AddIcon/>
        </FABFixed>
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  connect(({verificationMethods}) => verificationMethods)
)(List)