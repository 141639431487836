import React, { Component } from 'react'
import { compose } from 'utils'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { uuid } from 'utils'

export class CreateVen20aDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      venId: `${uuid()}`,
      httpPullModel: false,
      transportAddress: 'http://127.0.0.1:8081'
    }
  }

  handleClose = event => {
    this.props.onClose()
  }

  handleCancel = event => {
    this.props.onClose()
  }

  handleCreate = event => {
    this.props.onCreate(this.state)
  }

  onChangeHandler = (propName) => event => {
    this.setState({[propName]: event.target.value})
  }

  render = () => {
    return (
      <Dialog open={true} onClose={this.handleClose }>
        <DialogTitle>Register Ven 2.0a</DialogTitle>
        <DialogContent className={this.props.classes.dialogPaper}>
          <TextField
            id="venId"
            label="VEN ID"
            value={this.state.venId}
            onChange={this.onChangeHandler('venId')}
            margin="normal"
            className={this.props.classes.field}
          />
          <TextField
            id="httpPullModel"
            select
            label="Pull"
            value={this.state.httpPullModel}
            onChange={this.onChangeHandler('httpPullModel')}
            margin="normal"
            className={this.props.classes.field}
          >
            <MenuItem key={'true'} value={true}>{'true'}</MenuItem>
            <MenuItem key={'false'} value={false}>{'false'}</MenuItem>
          </TextField>
          <TextField
            id="transportAddress"
            label="Transport Address"
            value={this.state.transportAddress}
            onChange={this.onChangeHandler('transportAddress')}
            margin="normal"
            className={this.props.classes.field}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCreate} color="primary" autoFocus>Create</Button>
          <Button onClick={this.handleCancel} color="primary" autoFocus>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = theme => ({
  dialogPaper:{
    minWidth: '400px'
  },
  field: {
    width: '100%'
  }
})

export default compose(
  withStyles(styles)
)(CreateVen20aDialog)
