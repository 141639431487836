import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, capitalize, userFriendlyDate } from 'utils'
import withStyles from 'styles'
import Dependent from 'containers/shared/Dependent'
import Dropzone from 'react-dropzone'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { DateTimePicker, MultiAutoSuggest, Pagination } from 'components'
import { MeterDataFileActions, SnackbarActions } from 'actionsets'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import MeterDataImportResultDialog from './MeterDataImportResultDialog'
import UploadsOptions from 'constants/UploadsOptions'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import * as API from 'api'

export class Uploads extends Component {

  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      processingUpload: false,
      importResultDialogOpen: false,
      filter:  {
        source: 'user'
      },
      meterDataFilesPresent: {
        hub: false,
        ems: false,
      },
      names: {}
    }
    MeterDataFileActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    return this.loadMeterDataFiles()
  }

  componentDidMount() {
    this.checkMeterDataFilesPresent('hub')
    this.checkMeterDataFilesPresent('ems')
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  get files(){
    return this.props.meterDataFiles
  }

  get processingUpload(){
    return this.state.processingUpload
  }

  get endpointOptions() {
    return {
      fields: {
        meterDataFiles: 'createdAt,filename,dataFileUrlPath,importResults'
      }
    }
  }

  get maxFileSizeMB(){
    return UploadsOptions.meterDataUpload.maxFileSize / (2 ** 20)
  }

  loadMeterDataFiles = async () => {
    await this.actions.index({
      page: this.state.page, pageSize: 5,
      filter: this.state.filter
    })
  }

  checkMeterDataFilesPresent = async (source) => {
    const {meta: { count } } = await API.MeterDataFiles.index({
      options: {
        page: { number: 1, size:0 },
        filter: { source }
      }
    })

    if(count > 0) {
      this.setState({
        meterDataFilesPresent: {...this.state.meterDataFilesPresent, [source]: true}
      })
    }
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadMeterDataFiles)

  handleFileDrop = async (acceptedFiles, rejectedFiles) => {
    if(acceptedFiles.length !== 1 || rejectedFiles.length !== 0) {
      return
    }

    const file = acceptedFiles[0]
    if(file.size > UploadsOptions.meterDataUpload.maxFileSize) {
      this.actions.snackbar.show(`Maximum allowed file size is ${this.maxFileSizeMB} MB`)
      return
    }

    // Process first file
    try{
      this.setState({ processingUpload: true })
      await this.actions.create({dataFile: file}, this.endpointOptions)
      await this.loadMeterDataFiles()
    } catch(err) {
      this.actions.snackbar.show('Import failed!')
    } finally {
      this.setState({ processingUpload: false, importResultDialogOpen: true })
    }
  }

  handleCloseResultDialog = () => {
    this.setState({importResultDialogOpen: false})
  }

  handleDownloadDataFile = (id) => async () => {
    const {data: {dataFileUrlPath}} = await this.actions.show(id, { fields: { meterDataFiles: 'dataFileUrlPath' }})
    window.open(dataFileUrlPath, "_blank")
  }

  handleDisplayFileItemImportResultsDialog = (fileItem) => () => {
    this.setState({ fileItem, fileItemImportResultsDialogOpen: true })
  }

  handleCloseFileItemImportResultsDialog = () => {
    this.setState({ fileItemImportResultsDialogOpen: false })
  }

  handleMultiFilterChange = field => (event) => {
    const { target: { value }} = event
    this.setState(
      state => ({
        filter: {...state.filter, [field]: value.length > 0 ? value.join(",") : undefined }
      }), () => {
        this.loadMeterDataFiles()
      })
  }

  handleFilterChange = field => (event) => {
    const { target: { value }} = event
    this.setState(
      state => ({
        filter: {...state.filter, [field]: value}
      }), () => {
        this.loadMeterDataFiles()
      }
    )
  }

  preloadNames = async () => {
    const { source, ...others } = this.state.filter
    const types = Object.keys(others)
    for(let t = 0; t < types.length; t++) {
      let type = types[t]
      let ids = others[type].split(",")
      for(let i = 0; i < ids.length; i++) {
        let {data: {id, name, code}} = await API[capitalize(type)].show({id: ids[i], options: {fields: {[type]: type === 'gxps' ? "code" : "name"}}})
        this.setState(({names, ...state}) => ({...state, names: {...names, [type]: {...names[type], [id]: name || code}}}))
      }
    }
  }

  handleFetchRequested = (type,field) => async (text, callback) => {
    const params = {options: { filter: { [field]: text, active: true, permission: `${this.props.userID}:download_meter_data` }, fields: {[type]: field || "name"} }}
    const { data } = await API[capitalize(type)].index(params)
    this.setState(({names, ...state}) => ({...state, names: {...names, [type]: data.reduce((a,r) => ({...a, [r.id]:r[field || "name"]}),names[type])}}))
    const already = (this.state.filter[type] || "").split(",").filter(id => id !== "")
    callback(data.map(({id}) => id).filter(id => !already.includes(id)).slice(0, 5))
  }

  renderFilterSelect = (type,field) =>
    <MultiAutoSuggest
      label={capitalize(type)}
      className={this.props.classes('filterField', 'formControl')}
      labelProvider={id => (this.state.names[type] || {})[id]}
      debounceWait={200}
      value={(this.state.filter[type] || "").split(",").filter(id => id !== "")}
      onChange={this.handleMultiFilterChange(type)}
      onSuggestionsFetchRequested={this.handleFetchRequested(type,field)}
     />

  renderImportResultDialog = () => {
    return <MeterDataImportResultDialog onClose={this.handleCloseResultDialog} open={this.state.importResultDialogOpen}
          importResults={this.props.meterDataFile.importResults} errors={this.props.errors.create} />
  }

  renderFileItemImportResultsDialog = () => {
    return <MeterDataImportResultDialog onClose={this.handleCloseFileItemImportResultsDialog} open={this.state.fileItemImportResultsDialogOpen}
          importResults={this.state.fileItem.importResults} errors={this.state.fileItem.importErrors} />
  }

  renderFileItem = (fileItem) => {
    const {id, filename, createdAt, importErrors, importResults} = fileItem
    let dialogIcon = null

    if(importErrors) {
      dialogIcon = <ErrorIcon style={{color: 'red'}}/>
    } else if(importResults && importResults.warnings && importResults.warnings.length !== 0) {
      dialogIcon = <WarningIcon style={{color: 'orange'}}/>
    } else if(importResults && importResults.importedRows) {
      dialogIcon = <InfoIcon/>
    }

    return (
      <ListItem key={id} classes={{
        secondaryAction: this.props.classes('itemComponent', 'secondaryActions-2')
      }}>
        <ListItemText primary={filename} secondary={userFriendlyDate(createdAt)} style={{paddinRight: 100}}/>
        <ListItemSecondaryAction>
          <div>
            {
              dialogIcon &&
              <IconButton onClick={this.handleDisplayFileItemImportResultsDialog(fileItem)}>{dialogIcon}</IconButton>
            }
            <IconButton onClick={this.handleDownloadDataFile(id)}><CloudDownloadIcon/></IconButton>
          </div>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderFileList = () => {
    const sourceFilters = [
      <FormControlLabel value="user" key='user' control={<Radio />} label="User" />
    ]
    if (this.state.meterDataFilesPresent.hub) {
      sourceFilters.push(
        <FormControlLabel value="hub"  key='hub'  control={<Radio />} label="Registry" />
      )
    }
    if (this.state.meterDataFilesPresent.ems) {
      sourceFilters.push(
        <FormControlLabel value="ems"  key='ems'  control={<Radio />} label="EMS" />
      )
    }

    const filter_group = (sourceFilters.length > 1) ? (
      <RadioGroup value={this.state.filter.source} onChange={this.handleFilterChange('source')} row>
        { sourceFilters }
      </RadioGroup>
    ) : null
    const filter_contents = <Fragment>
       {this.renderFilterSelect('organisations','name')}
       <div>
       {['from','to'].map(field => <DateTimePicker
         key={field}
         value={this.state.filter[field]}
         onChange={this.handleFilterChange(field)}
         label={capitalize(field)}
         className={this.props.classes('filterField','formControl','halfWidth')}
         />)}
       </div>
       {filter_group}
     </Fragment>

    return (
      <div id='list' className={this.props.classes.filesListWidget}>
        <div style={{width: '100%'}}>
          {filter_contents}
          <Pagination totalPages={this.props.totalPages} page={this.props.page}
                      onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}
                      />
          <List> {this.files.map(this.renderFileItem)} </List>
        </div>
      </div>
    )
  }

  renderDropZone = () => {
    return (
      <div id='dropzone' className={this.props.classes.uploadsWidget}>
        <Dropzone disabled={ false /* this.state.processingUpload */ }
                  onDrop={this.handleFileDrop}
                  multiple={false}
                  acceptClassName={this.props.classes('acceptDrop')}
                  rejectClassName={this.props.classes('rejectDrop')}
                  className={this.props.classes.dropZone}>
              {
                (options) => {
                  const { isDragActive, isDragAccept, isDragReject } = options
                  let label = (
                    <Fragment>
                      <Typography>Drag a file here, or click to select it from your computer.</Typography>
                      <Typography>{`Maximum allowed file size is ${this.maxFileSizeMB} MB.`}</Typography>
                    </Fragment>
                  )
                  if(isDragActive) {
                    label = <Typography>{(isDragAccept && !isDragReject) ? 'Drop to upload.' : 'Not accepted!'}</Typography>
                  }
                  return (
                    <div className={this.props.classes('dropzoneMessage')}> {label} </div>
                  )
                }
              }
        </Dropzone>
      </div>
    )
  }

  render = () => {
    return (
      <section className={this.props.classes.tabSection}>
        <Typography variant='h6'>Meter data uploads</Typography>
        <div className={this.props.classes.uploadsRoot}>
          { this.renderFileList() }
          {
            (this.state.filter.source === 'user') &&
            this.renderDropZone()
          }
        </div>
        { this.renderImportResultDialog() }
        { this.state.fileItem && this.renderFileItemImportResultsDialog() }
      </section>
    )
  }
}

const styles = theme => ({
  uploadsRoot: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  halfWidth: {
    width: '50%'
  },
  filesListWidget: {
    display: 'flex',
    flex: 1,
  },
  uploadsWidget: {
    display: 'flex',
    flex: 1,
    marginLeft: '10px',
  },
  dropZone: {
    minHeight: '400px',
    width: '100%',
    height: '100%',
    borderWidth: '2px',
    borderColor: 'rgb(102, 102, 102)',
    borderStyle: 'dashed',
    borderRadius: '5px',
    textAlign: 'center',
    paddingTop: 10,
    flex: 1,
  },
  acceptDrop:{
    borderColor: 'green',
    borderWidth: '4px',
  },
  rejectDrop:{
    borderColor: 'red',
    borderWidth: '4px',
  },
  dropzoneMessage: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  'secondaryActions-2': {},
  itemComponent: {
    '&$secondaryActions-2': { paddingRight: '96px' }
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({meterDataFiles, tokens: {currentUser: {id: userID}}}) => {return { ...meterDataFiles, userID }}),
)(Uploads)
