import ActionSet from './ActionSet'
import AppVersionActions from './AppVersionActionSet'
import BCTIActions from './BCTIActionSet'
import CBLActions from './CBLActionSet'
import CalendarActions from './CalendarActionSet'
import ChildEventActions from './ChildEventActionSet'
import DeviceActions from './DeviceActionSet'
import AmbiDeviceStateActions from './AmbiDeviceStateActionSet'
import GxpActions from './GxpActionSet'
import JobActions from './JobActionSet'
import LoadTypeActions from './LoadTypeActionSet'
import LoginFailureActions from './LoginFailureActionSet'
import MeterDataActions from './MeterDataActionSet'
import MeterDataFileActions from './MeterDataFileActionSet'
import MonthlyReportActions from './MonthlyReportActionSet'
import NotificationActions from './NotificationActionSet'
import OfferActions from './OfferActionSet'
import OptActions from './OptActionSet'
import OrganisationActions from './OrganisationActionSet'
import OrganisationRoleActions from './OrganisationRoleActionSet'
import ParentEventActions from './ParentEventActionSet'
import PaymentActions from './PaymentActionSet'
import PermissionActions from './PermissionActionSet'
import ProgrammeActions from './ProgrammeActionSet'
import RegionActions from './RegionActionSet'
import RegistrationActions from './RegistrationActionSet'
import ReportActions from './ReportActionSet'
import ReportRegistrationActions from './ReportRegistrationActionSet'
import ReportDescriptionActions from './ReportDescriptionActionSet'
import ResetActions from './ResetActionSet'
import SiteActions from './SiteActionSet'
import SnackbarActions from './SnackbarActionSet'
import SubstationActions from './SubstationActionSet'
import SystemParametersActions from './SystemParametersActionSet'
import TagActions from './TagActionSet'
import TagTypeActions from './TagTypeActionSet'
import TagValueActions from './TagValueActionSet'
import TaskActions from './TaskActionSet'
import TokenActions from './TokenActionSet'
import UserActions from './UserActionSet'
import UserNotificationActions from './UserNotificationActionSet'
import UserRoleActions from './UserRoleActionSet'
import VerificationMethodActions from './VerificationMethodActionSet'
import VersionActions from './VersionActionSet'

export const reducers = {
  appVersion:  AppVersionActions.reducers,
  bctis:       BCTIActions.reducers,
  calendars:   CalendarActions.reducers,
  cbl:         CBLActions.reducers,
  childEvents: ChildEventActions.reducers,
  devices:     DeviceActions.reducers,
  ambiDeviceStates: AmbiDeviceStateActions.reducers,
  gxps:        GxpActions.reducers,
  jobs: JobActions.reducers,
  loadTypes:   LoadTypeActions.reducers,
  loginFailures: LoginFailureActions.reducers,
  meterData:    MeterDataActions.reducers,
  meterDataFiles: MeterDataFileActions.reducers,
  monthlyReport:  MonthlyReportActions.reducers,
  notifications: NotificationActions.reducers,
  offers:      OfferActions.reducers,
  opts:        OptActions.reducers,
  organisationRoles: OrganisationRoleActions.reducers,
  organisations: OrganisationActions.reducers,
  parentEvents: ParentEventActions.reducers,
  payments:    PaymentActions.reducers,
  permissions: PermissionActions.reducers,
  programmes:  ProgrammeActions.reducers,
  regions:     RegionActions.reducers,
  registrations: RegistrationActions.reducers,
  reports:     ReportActions.reducers,
  reportRegistrations: ReportRegistrationActions.reducers,
  reportDescriptions: ReportDescriptionActions.reducers,
  sites:       SiteActions.reducers,
  snackbar:    SnackbarActions.reducers,
  substations: SubstationActions.reducers,
  systemParameters: SystemParametersActions.reducers,
  tagTypes:    TagTypeActions.reducers,
  tagValues:   TagValueActions.reducers,
  tags:        TagActions.reducers,
  tasks:       TaskActions.reducers,
  tokens:   TokenActions.reducers,
  userNotifications: UserNotificationActions.reducers,
  userRoles:   UserRoleActions.reducers,
  users:    UserActions.reducers,
  verificationMethods:  VerificationMethodActions.reducers,
  versions: VersionActions.reducers,
}

export {
  ActionSet,
  AppVersionActions,
  BCTIActions,
  CBLActions,
  CalendarActions,
  ChildEventActions,
  DeviceActions,
  AmbiDeviceStateActions,
  GxpActions,
  JobActions,
  LoadTypeActions,
  LoginFailureActions,
  MeterDataActions,
  MeterDataFileActions,
  MonthlyReportActions,
  NotificationActions,
  OfferActions,
  OptActions,
  OrganisationActions,
  OrganisationRoleActions,
  ParentEventActions,
  PaymentActions,
  PermissionActions,
  ProgrammeActions,
  RegionActions,
  RegistrationActions,
  ReportActions,
  ReportRegistrationActions,
  ReportDescriptionActions,
  ResetActions,
  SiteActions,
  SnackbarActions,
  SubstationActions,
  SystemParametersActions,
  TagActions,
  TagTypeActions,
  TagValueActions,
  TaskActions,
  TokenActions,
  UserActions,
  UserNotificationActions,
  UserRoleActions,
  VerificationMethodActions,
  VersionActions,
}
