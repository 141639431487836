import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, roundDateOrMoment, flatten, debounce } from 'utils'
import withStyles from 'styles'
import { FormContext, DateTimePicker, MultiAutoSuggest } from 'components'
import { connectQueryString } from 'containers/shared'
import moment from 'moment'
import { Loader } from 'components'
import IconButton from '@material-ui/core/IconButton'
import Download from '@material-ui/icons/CloudDownload'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import TabIcon from '@material-ui/icons/ExpandLess'
import { ReportActions } from 'actionsets'
import EventsResults from './EventsResults'
import { Authorization } from 'utils'
import * as API from 'api'
import TradingPeriod from 'constants/TradingPeriod'

export class Events extends Component{

  constructor(props){
    super(props)
    ReportActions.bindActions(this)
  }

  get errors(){
    return flatten([this.props.errors.show || []])
  }

  get filter(){
    return Object.entries(this.props.filter || {})
                 .reduce((agg, [key, value]) => ({...agg, [key]: this.parseFilter(key, value)}), {})
  }

  parseFilter(key, value){
    switch(key){
    case 'from':
    case 'to':
      return roundDateOrMoment(moment(value), TradingPeriod.length.inMinutes).toDate()
    case 'programmeName':
    case 'registration':
    case 'organisation':
    case 'name':
      return flatten([value])
    default:
      return value
    }
  }

  componentDidMount(){
    this.loadReport()
  }

  loadReport = debounce(() => {
    this.actions.show({
      type: 'events',
      filter: this.props.filter,
      include: 'parentEvents.programme'
    }).then(results => results)
      .catch(errors => {})
  })

  handleCollapseFilters = () => {
    this.props.onFilterChange({...this.props.filter, collapsed: this.props.filter.collapsed !== 'true'})
  }

  handleFilterChange = ({from, to, ...others}) => {
    if(from && typeof from === 'object') from = from.toISOString()
    if(to && typeof to === 'object')     to = to.toISOString()
    const filter = {from: from && from, to: to && to, ...others}
    this.props.onFilterChange(filter, this.loadReport)
  }

  handleRegistrationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: registrations } = await API.Registrations.index(params)
    callback(registrations.map(({name}) => name).filter(name => !(this.filter.registration || []).includes(name)).slice(0, 5))
  }

  handleProgrammesFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: programmes } = await API.Programmes.index(params)
    callback(programmes.map(({name}) => name).filter(name => !(this.filter.programmeName || []).includes(name)).slice(0, 5))
  }

  handleOrganisationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: organisations } = await API.Organisations.index(params)
    callback(organisations.map(({name}) => name).filter(name => !(this.filter.organisation || []).includes(name)).slice(0, 5))
  }

  handleParentEventsFetchRequested = async (text, callback) => {
    const { data: events } = await API.Reports.parentEvents({options: { filter: { name: text } }})
    callback(events.map(({name}) => name).filter(name => !(this.filter.parentEvent || []).includes(name)).slice(0, 5))
  }

  handleTagsFetchRequested = async (value, callback) => {
    const { data: values } = await API.TagValues.index({
      options: {
        page: { size: 50, number: 1 },
        filter: { value, ...this.props.filter },
        include: 'tagType'
      }
    })
    callback(values.filter(v => !((this.filter.tags || {})[v.tagType.name] || []).includes(v.value)).slice(0, 5))
  }

  handleDownload = (format) => {
    this.actions.download({type: 'events', filter: this.props.filter})
  }

  traverse = (multiplier, unit) => () => {
    const filter = {...this.filter}
    filter.from = moment(filter.from).add(multiplier,unit)
    filter.to   = moment(filter.to).add(multiplier,unit)
    this.handleFilterChange(filter)
  }

  renderTagLabel = ({value, tagType: { name }}) =>
    <span><strong>{name}</strong>: {value}</span>

  handleTagsChanged = ({target: { value }}) => {
    const tags = {};
    (value || []).forEach(({value, tagType: { name }}) => {
      if(!tags[name]) tags[name] = []
      tags[name].push(value)
    })
    this.props.onFilterChange({...this.props.filter, tags }, this.loadReport)
  }

  get tags(){
    return flatten(Object.entries(this.props.filter.tags || {}).map(([type, values]) =>
      flatten([values]).map(value => ({
        value,
        tagType: {
          name: type
        }
      }))
    ))
  }

  renderFilters = () =>
    <section className={this.props.classes('filters', {collapsed: this.props.filter.collapsed === 'true'})}>
      <IconButton className={this.props.classes.collapseButton} onClick={this.handleCollapseFilters}>
        <TabIcon/>
      </IconButton>
      <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
        <IconButton onClick={this.traverse(-1, 'month')}>
          <ChevronLeft/>
        </IconButton>
        <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('reportsFilter', 'dateFilter')} member='from'/>
        <DateTimePicker minutesStep={TradingPeriod.length.inMinutes} className={this.props.classes('reportsFilter', 'dateFilter')} member='to'/>
        <IconButton onClick={this.traverse(1, 'month')}>
          <ChevronRight/>
        </IconButton>
         <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='registration'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleRegistrationsFetchRequested}
          />
          <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='programmeName'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleProgrammesFetchRequested}
          />
          <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='organisation'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleOrganisationsFetchRequested}
          />
          <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='parentEvent'
            label='Event'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleParentEventsFetchRequested}
          />
          {
            Authorization.systemPermissions.readTags &&
            <MultiAutoSuggest
              classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
              labelProvider={this.renderTagLabel}
              debounceWait={200}
              label='Tags'
              onChange={this.handleTagsChanged}
              value={this.tags}
              onSuggestionsFetchRequested={this.handleTagsFetchRequested}
            />
          }
        <IconButton onClick={this.handleDownload}>
          <Download/>
        </IconButton>
      </FormContext>
    </section>

  render = () =>
    <div className={this.props.classes.wrapper}>
      {this.renderFilters()}
      {this.props.requests.length > 0 ?
        <Loader/> :
        <>
          {this.errors.length === 0 ?
            <EventsResults report={this.props.report.reportsEvents}/> :
            <p className={this.props.classes.subHeader}>There are no reports matching the filter!</p>
          }
        </>
      }
    </div>

}

const styles = theme => ({
  cardContent: {
    paddingBottom: 0
  },
  toggleButton:{
    position: 'absolute',
    right: -10,
    top: -40,
    color: theme.palette.primary.main
  },
  pagination: {
    flex: '0 0 auto',
    height: 30,
    marginTop: -10,
  },
  filters: {
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: "rgba(0, 27, 38, 0.5)",
    padding: '5px 48px 5px 10px',
    alignItems: 'self-end',
    '& $reportsFilter': {
      margin: 5
    }
  },
  charts: {
    flex: 1,
    height: 0,
    overflow: 'hidden',
    '@media(max-width: 600px)': {
      height: 600
    }
  },
  dateFilter: {
    paddingTop: 2
  },
  reportsFilter: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    height: 'calc(100vh - 230px)',
    '@media(max-width: 620px)': {
      height: 'auto'
    }
  },
  collapsed: {
    '& > *': {
      display: 'none'
    },

    '&::before': {
      content: "'Filters'",
      lineHeight: '38px',
      color: 'white',
      fontWeight: 900,
      fontSize: '1rem',
      display: 'block',
    },

    '& $collapseButton': {
      transform: 'rotate(180deg)',
      display: 'block'
    }
  },
  collapseButton: {
    color: 'white',
    fontSize: 11,
    position: 'absolute',
    zIndex: 100,
    top: 0,
    right: 0,
    transition: 'transform 500ms',
  },
  subHeader: {
    textAlign: 'center',
    width: '100%'
  }
})

export default compose(
  withStyles(styles),
  connect(({reports}, { filter }) => ({
    filter: {
      from: moment().add(-1, 'year').toISOString(),
      to: moment().toISOString(),
      ...filter
    },
    ...reports
  })),
  connectQueryString('reports'),
)(Events)
