import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'utils'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { OrganisationActions } from 'actionsets'
import { AutoSuggest } from 'components'

export class Merge extends Component{
  constructor(props){
    super(props)
    this.state = {mergeTarget: null}
    OrganisationActions.bindActions(this)
  }

  get id() {
    return this.props.match.params.id
  }

  dependsOn(){
    return this.actions.show(this.id, {fields: {organisations: 'name,status,parent'}, include: 'parent'})
  }

  dependenciesMet = () => this.props.organisation.id

  get organisation() {
    return this.props.organisation
  }

  handleRequestOrganisationSuggestions = async (text, callback) => {
    const response = await this.actions.index({filter: {name: text}})
    callback(response.data)
  }

  onMerge = async () => {
    await this.actions.destroy({id: this.id, options: {_merge_target: this.state.mergeTarget.id}})
    this.props.history.push(`/organisations/${this.organisation.parentId}/organisations`)
  }

  render = () =>
    <Card style={{overflow: "visible"}}>
      <CardContent style={{overflow: "visible"}}>
        <Typography variant='h4'>Merge duplicate organisation - {this.organisation.name}</Typography>
        <AutoSuggest
          fullWidth
          label="Merge to..."
          onSuggestionsFetchRequested={this.handleRequestOrganisationSuggestions}
          labelProvider={x => x.name}
          onChange={event => this.setState({mergeTarget: event.target.value})}
          value={this.state.mergeTarget}
        />
        <Button
          color="primary"
          disabled={!this.state.mergeTarget}
          onClick={this.onMerge}
        >Merge</Button>
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  connect(({organisations: {organisations, ...params}}) => params)
)(Merge)