import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { RegistrationActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import WarnIcon from '@material-ui/icons/Warning'
import CheckIcon from '@material-ui/icons/Check'
import BlankIcon from '@material-ui/icons/Remove'
import LinkIcon from '@material-ui/icons/Link'
import EyeIcon from '@material-ui/icons/RemoveRedEye'
import SiteIcon from '@material-ui/icons/LocationOn'
import SubstationIcon from '@material-ui/icons/BrokenImage'
import IconButton from '@material-ui/core/IconButton'
import withStyles from 'styles'
import Chip from '@material-ui/core/Chip'
import { compose, formatLocaleDate, formatLocaleNumeric, errorStringsFromError, humanize, Authorization, multiLine } from 'utils'
import { MultiAutoSuggest, AutoSuggest } from 'components'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withPermissions } from 'containers/shared'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as API from 'api'
import Tooltip from '@material-ui/core/Tooltip'


export class Show extends Component{

  static requiredPermissions = props => {
    const organisationId = (props.registration.organisation && props.registration.organisation.id) || null
    return organisationId ? {
      organisation: {
        [organisationId]: ['writeProgrammes', 'readPayments', 'readProgrammes', 'readOrganisation']
      }
    } : {}
  }

  constructor(props){
    super(props)
    SnackbarActions.bindActions(this, 'snackbar')
    RegistrationActions.bindActions(this)
    this.state = { showBCTISelector: false }
  }

  dependsOn(){
    return this.loadRegistration()
  }

  dependenciesMet(){
    return !!this.registration.id && (this.props.requests.length === 0)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.match.params.id !== this.id) {
      this.props.onReloadDependent()
    }
  }

  loadRegistration = async () => {
    await this.actions.show(this.id, {
      include: 'organisation,programme,sites,substation,verificationMethod,initialEstablishmentFee.bcti,finalEstablishmentFee.bcti'
    })
  }

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this registration? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.id})
      this.props.history.goBack()
      this.actions.snackbar.show('Registration deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete registration.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  get id(){
    return this.props.match.params.id
  }

  get registration(){
    return this.props.registration
  }

  get registrationType(){
    return this.registration.substation ? 'substation' : 'sites'
  }

  get organisationPermissions(){
    return (this.props.permissions && (this.props.permissions.organisation || {})[this.organisationId]) || {}
  }

  get canWriteProgramme(){
    return this.organisationPermissions.writeProgrammes
  }

  get canReadPayments(){
    return this.organisationPermissions.readPayments
  }

  get initialEstablishmentFee(){
    return this.props.registration.initialEstablishmentFee || {}
  }

  get finalEstablishmentFee(){
    return this.props.registration.finalEstablishmentFee || {}
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.registration.organisation && this.props.registration.organisation.id)
  }

  get registrationIsEditable(){
    return (
      (
        this.registrationType !== 'gxp' &&
        ['active', 'submitted','inactive','discarded'].indexOf(this.registration.status) === -1
      ) ||
      (
        this.canWriteProgramme
      )
    )
  }

  handleHideBCTISelector = () => {
    this.setState({showBCTISelector: false})
  }

  handleShowBCTISelector = feeId => () => {
    this.setState({showBCTISelector: true, feeId: feeId})
  }

  handleInvoicesSuggestionsFetchRequested = async (text, callback) => {
    const { data: bctis } = await API.Bctis.index({
      organisationId: this.organisationId,
      options: {
        filter: {
          name: text
        }
      }
    })
    callback(bctis)
  }

  handleInvoiceSelected = async ({target: {value: invoice}}) => {
    if(!invoice)
      return
    try{
      await API.Fees.update({
        id: this.state.feeId,
        bctiId: invoice.id
      })
      this.loadRegistration()
    }catch(err){
      this.actions.snackbar.show(`The following error occurred while attempting to save: ${err}`)
    }
  }

  handleShowBCTI = ({id}) => () => {
    this.props.history.push(`/bctis/${id}`)
  }

  renderSiteListItem = ({name, id}) =>
    <ListItem key={id} button onClick={() => this.props.history.push(`/sites/${id}`)}>
      <ListItemIcon>
        <SiteIcon/>
      </ListItemIcon>
      <ListItemText primary={name} secondary='site'/>
    </ListItem>


  renderSubstationListItem = ({name, id}) =>
    <ListItem key={id} button onClick={() => this.props.history.push(`/substations/${id}`)}>
      <ListItemIcon>
        <SubstationIcon/>
      </ListItemIcon>
      <ListItemText primary={name} secondary='substation'/>
    </ListItem>

  renderPriceResponsiveFields = () =>
    <Fragment>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Indicative Price</TableCell>
        <TableCell>${formatLocaleNumeric(this.registration.indicativePrice)}/MWh</TableCell>
      </TableRow>
    </Fragment>

  renderNonPriceResponsiveFields = () =>
    <Fragment>
      <TableRow>
        <TableCell className={this.props.classes.head} variant='head'>Fixed Price</TableCell>
        <TableCell>${formatLocaleNumeric(this.registration.fixedPrice)}/MWh</TableCell>
      </TableRow>
      {
        this.registration.availabilityFee &&
        <TableRow>
          <TableCell className={this.props.classes.head} variant='head'>Availability Fee</TableCell>
          <TableCell>${String(this.registration.availabilityFee)}</TableCell>
        </TableRow>
      }
      {
        this.registration.prepurchasedHours &&
        <TableRow>
          <TableCell className={this.props.classes.head} variant='head'>Pre-purchased hours</TableCell>
          <TableCell>{String(this.registration.prepurchasedHours)}</TableCell>
        </TableRow>
      }
    </Fragment>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Registration - {this.registration.name} &emsp; <Chip label={humanize(this.registration.status)}/></Typography>
        { this.registration.rejectionReason &&
          <section className={this.props.classes.registrationSection}>
            <Typography variant='h6'>Rejection reason</Typography>
            <p style={{width: "100%"}}>{multiLine(this.registration.rejectionReason)}</p>
          </section>
        }
        <section className={this.props.classes.registrationSection}>
          <div className={this.props.classes.formFields}>

            <Typography variant='h6'>
              Fields
            </Typography>
            { this.registrationIsEditable &&
              <span>
                Actions:
                <Link to={`/registrations/${this.registration.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
                <IconButton onClick={this.handleDelete}>
                  <DeleteIcon/>
                </IconButton>
              </span>
            }
            <div className={this.props.classes.tables}>
              <Table className={this.props.classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Name</TableCell>
                    <TableCell>{String(this.registration.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Programme</TableCell>
                    <TableCell>
                      {
                        this.organisationPermissions.readProgrammes ?
                          <Link to={`/programmes/${String(this.registration.programme.id)}`} className={this.props.classes.tableCellLink}>
                            {String(this.registration.programme.name)}
                          </Link> :
                          String(this.registration.programme.name)
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Start Date</TableCell>
                    <TableCell>{formatLocaleDate(this.registration.startDate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>End Date</TableCell>
                    <TableCell>{formatLocaleDate(this.registration.endDate)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Organisation</TableCell>
                    <TableCell>
                      {
                        this.organisationPermissions.readOrganisation ?
                          <Link to={`/organisations/${this.registration.organisation.id}`} className={this.props.classes.tableCellLink}>
                            {String(this.registration.organisation.name)}
                          </Link> :
                          String(this.registration.organisation.name)
                      }
                    </TableCell>
                  </TableRow>
                  {this.props.registration.programme.type === 'priceResponsiveProgrammes' && this.renderPriceResponsiveFields()}
                  {this.props.registration.programme.type === 'nonPriceResponsiveProgrammes' && this.renderNonPriceResponsiveFields()}
                  { this.registrationType === 'sites' &&
                    <TableRow>
                      <TableCell className={this.props.classes.head} variant='head'>Aggregate CBL</TableCell>
                      <TableCell>{this.props.registration.useAggregateCbl ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  }
                  { this.registrationType === 'sites' &&
                    this.props.registration.useAggregateCbl &&
                    this.props.registration.verificationMethod &&
                    <TableRow>
                      <TableCell className={this.props.classes.head} variant='head'>Verification Method</TableCell>
                      <TableCell>{this.props.registration.verificationMethod.name}</TableCell>
                    </TableRow>
                  }
                  {
                    this.initialEstablishmentFee.amount ?
                      <TableRow>
                        <TableCell className={this.props.classes.head} variant='head'>Establishment Fee<br/>{formatLocaleDate(this.initialEstablishmentFee.date)}</TableCell>
                        <TableCell className={this.props.classes.establishmentFeeCell}>
                          {
                            this.initialEstablishmentFee.bcti ?
                              <Tooltip title='Status: Paid'>
                                <CheckIcon color='primary'/>
                              </Tooltip>:
                              <Tooltip title='Status: Unpaid'>
                                {
                                  !this.initialEstablishmentFee.bcti && new Date(this.initialEstablishmentFee.date) < new Date() ?
                                    <WarnIcon color='error'/> :
                                    <BlankIcon/>
                                  }
                              </Tooltip>
                          }
                          <em>${this.initialEstablishmentFee.amount}</em>
                          {
                            this.canReadPayments && (
                              this.registrationIsEditable ?
                                <Tooltip title="Link payment to invoice">
                                  <IconButton onClick={this.handleShowBCTISelector(this.initialEstablishmentFee.id)}>
                                    <LinkIcon/>
                                  </IconButton>
                                </Tooltip> : false
                            )
                          }
                          {
                            this.canReadPayments && (
                              this.initialEstablishmentFee.bcti ?
                                <Tooltip title="Show Linked Invoice">
                                  <IconButton onClick={this.handleShowBCTI(this.initialEstablishmentFee.bcti)}>
                                    <EyeIcon/>
                                  </IconButton>
                                </Tooltip>: false
                            )
                          }
                        </TableCell>
                      </TableRow>
                    : false
                  }
                  {
                    this.finalEstablishmentFee.amount ?
                      <TableRow>
                        <TableCell className={this.props.classes.head} variant='head'>Establishment Fee<br/>{formatLocaleDate(this.finalEstablishmentFee.date)}</TableCell>
                        <TableCell className={this.props.classes.establishmentFeeCell}>
                          {
                            this.finalEstablishmentFee.bcti ?
                            <Tooltip title='Status: Paid'>
                              <CheckIcon color='primary'/>
                            </Tooltip> :
                            <Tooltip title='Status: Unpaid'>
                              {
                                !this.finalEstablishmentFee.bcti && new Date(this.finalEstablishmentFee.date) < new Date() ?
                                  <WarnIcon color='error'/> :
                                  <BlankIcon/>
                              }
                            </Tooltip>
                          }
                          <em>${this.finalEstablishmentFee.amount}</em>
                          {
                            this.canReadPayments && (
                              this.registrationIsEditable ?
                                <Tooltip title="Link payment to invoice">
                                  <IconButton onClick={this.handleShowBCTISelector(this.finalEstablishmentFee.id)}>
                                    <LinkIcon/>
                                  </IconButton>
                                </Tooltip> : false
                            )
                          }
                          {
                            this.canReadPayments && (
                              this.finalEstablishmentFee.bcti ?
                                <Tooltip title="Show Linked Invoice">
                                  <IconButton onClick={this.handleShowBCTI(this.finalEstablishmentFee.bcti)}>
                                    <EyeIcon/>
                                  </IconButton>
                                </Tooltip> : false
                            )
                          }
                        </TableCell>
                      </TableRow>
                    : false
                  }
                </TableBody>
              </Table>
            </div>
          </div>
          {
            Authorization.systemPermissions.readTags &&
            <div className={this.props.classes.tags}>
              <Typography variant='h6'>
                Tags
              </Typography>
              {
                Object.entries(this.registration.tags).map(([type, values], tIdx) =>
                  <MultiAutoSuggest
                    readOnly
                    showClearAll={false}
                    key={tIdx}
                    label={type}
                    value={values}
                    labelProvider={value => value}
                  />
                )
              }
            </div>
          }
        </section>
        <br/>
        <section>
          { this.registrationType === 'sites' &&
            <Fragment>
            <Typography variant='h6'>Enrolled Sites:</Typography>
            <MuiList>
              {this.registration.sites.map(this.renderSiteListItem)}
            </MuiList>
            </Fragment>
          }
          { this.registrationType === 'substation' &&
            <MuiList>
              {this.renderSubstationListItem(this.registration.substation) }
            </MuiList>
          }
        </section>
      </CardContent>
      <Dialog classes={{paper: this.props.classes.showOverflow}} open={this.state.showBCTISelector} onClose={this.handleHideBCTISelector}>
        <DialogTitle>Select Invoice</DialogTitle>
        <DialogContent className={this.props.classes.showOverflow}>
          <AutoSuggest
            labelProvider={({name}) => name}
            onSuggestionsFetchRequested={this.handleInvoicesSuggestionsFetchRequested}
            onChange={this.handleInvoiceSelected}
          />
        </DialogContent>
      </Dialog>
    </Card>
}


const styles = theme => ({
  showOverflow: {
    overflowY: 'visible'
  },
  establishmentFeeCell: {
    '& > svg':{
      marginBottom: -5
    }
  },
  tables: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    },
    '& td': {
      background: 'rgba(255,255,255,0.03)',
      padding: '10px 15px',
      '&:last-child': {
        borderRight: '1px solid rgba(255,255,255,0.05)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(255,255,255,0.05)'
      },
      borderTop: '1px solid rgba(255,255,255,0.05)',
      borderBottom: '1px solid rgba(255,255,255,0.05)'
    },
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: 'rgba(255,255,255,0.06) !important',
    borderRight: '1px solid rgba(255,255,255,0.02)'
  },
  registrationSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: '2 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: "10px 10px 0 0",
    minWidth: 300,
    marginBottom: 10,
    color: 'white',
  },
  tags: {
    flex: '1 1 0%',
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300,
    margin: '10px 0',
  },
  tableCellLink: {
    '&:hover':{
      textDecoration: 'underline'
    }
  },
})

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(Show.requiredPermissions),
  withStyles(styles),
  connect(({registrations}) => registrations)
)(Show)