import React, { Component } from 'react'
import { connect } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { MultiAutoSuggest } from 'components'
import { RegionActions, GxpActions, SiteActions } from 'actionsets'

class ZoneSelector extends Component {
  static defaultProps = {
    names: {},
    readOnly: false
  }

  constructor(props) {
    super(props)
    RegionActions.bindActions(this, 'regions')
    GxpActions.bindActions(this, 'gxps')
    SiteActions.bindActions(this, 'sites')
    this.state = { extraNames: {} }
  }

  suggestions = async (text, callback) => {
    const promises = [
      this.actions.regions.index(text ? {filter: {name: text}} : {}),
      this.actions.gxps.index(text ? {filter: {code: text}} : {}),
      this.actions.sites.index(text ? {filter: {name: text}} : {})
    ]
    const responses = await Promise.all(promises)
    const suggestions = responses.map(r => r.data).reduce((acc,add) => [...acc, ...add], [])
    const newNames = suggestions.filter(item => ![...(this.props.names[item.type] || []),...(this.state.extraNames[item.type] || [])].find(target => target.id === item.id && target.name))
    this.setState({
      extraNames: newNames.reduce((acc,n) => ({
        ...acc,
        [n.type]: [...(acc[n.type] || []), n]
      }), this.state.extraNames)
    })
    this.props.onNewNames && this.props.onNewNames(newNames)
    callback(suggestions.map(({id,type}) => ({type,id})))
  }

  nameFor = scope => {
    const ownName = scope.code || scope.name
    if(ownName) { return ownName }
    const lookup = [...(this.props.names[scope.type] || []), ...(this.state.extraNames[scope.type] || [])].find(target => target.id === scope.id && (target.name || target.code))
    return lookup ? lookup.code || lookup.name : `${scope.type} #${scope.id}`
  }

  render = () => this.props.readOnly ?
    this.props.value.map(scope => <Chip
      key={`${scope.type}#${scope.id}`}
      label={this.nameFor(scope)}
    />) :
    <MultiAutoSuggest
      showClearAll={false}
      labelProvider={this.nameFor}
      value={this.props.value}
      onSuggestionsFetchRequested={this.suggestions}
      onChange={this.props.onChange}
    />

}

export default connect(() => ({}))(ZoneSelector)