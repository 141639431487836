import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class SiteActionSet extends CrudActionSetBase('site'){
  static initialState = {
    site: { tags: {}, loads: {} }
  }

  static requestMeterReading(creator, reducer, constants) {
   constants.defineRequestConstants()

   creator((id, {startMonth, endMonth}) => (dispatch, getState) => {
     return dispatch({
       type: constants.ACTION,
       promise: API.Sites.requestMeterReading({id, startMonth, endMonth}),
       // payload: { type: params.type }
     })
   })

   reducer({
     [constants.REQUEST]: (state, { request }) => {
       return {...state, requests: [...state.requests, request]}
     },
     [constants.SUCCESS]: (state, { request, result: { data
     } }) => {
       const requests = state.requests.filter(existingRequest => (existingRequest !== request))
       return {...state, requests, requestMeterReading: data }
     },
     [constants.FAILURE]: (state, { request, error }) => {
       const requests = state.requests.filter(existingRequest => (existingRequest !== request))
       const errors = { ...state.errors, requestMeterReading: error }
       return { ...state, requests, errors }
     }
   })
  }
}

export default new SiteActionSet()
