import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, flatten, debounce, errorStringsFromError } from 'utils'
import withStyles from 'styles'
import { connectQueryString } from 'containers/shared'
import { ReportActions } from 'actionsets'
import { Loader, ErrorBanner } from 'components'
import { FormContext, MultiAutoSuggest } from 'components'
import IconButton from '@material-ui/core/IconButton'
import Download from '@material-ui/icons/CloudDownload'
import TabIcon from '@material-ui/icons/ExpandLess'
import { Authorization } from 'utils'
import RegistrationsResults from './RegistrationsResults'
import * as API from 'api'

export class Registrations extends Component {

  constructor(props){
    super(props)
    ReportActions.bindActions(this)
  }

  get errors(){
    return flatten([this.props.errors.index || []])
  }

  get filter(){
    return Object.entries(this.props.filter || {})
                 .reduce((agg, [key, value]) => ({...agg, [key]: this.parseFilter(key, value)}), {})
  }

  get tags(){
    return flatten(Object.entries(this.props.filter.tags || {}).map(([type, values]) =>
      flatten([values]).map(value => ({
        value,
        tagType: {
          name: type
        }
      }))
    ))
  }

  parseFilter(key, value){
    switch(key){
    case 'programmeName':
    case 'registration':
    case 'organisation':
      return flatten([value])
    default:
      return value
    }
  }

  componentDidMount(){
    this.loadReport()
  }

  loadReport = debounce(() => {
    return this.actions.show({
      type: 'registrations',
      filter: {live: true, status: 'active', ...this.props.filter},
      include: 'registrations, registrations.sites'
    })
  })

  handleCollapseFilters = () => {
    this.props.onFilterChange({...this.props.filter, collapsed: this.props.filter.collapsed !== 'true'})
  }

  handleFilterChange = (filter) => {
    this.props.onFilterChange(filter, this.loadReport)
  }

  handleRegistrationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: registrations } = await API.Registrations.index(params)
    callback(registrations.map(({name}) => name).filter(name => !(this.filter.registration || []).includes(name)).slice(0, 5))
  }

  handleProgrammesFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: programmes } = await API.Programmes.index(params)
    callback(programmes.map(({name}) => name).filter(name => !(this.filter.programmeName || []).includes(name)).slice(0, 5))
  }

  handleOrganisationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: organisations } = await API.Organisations.index(params)
    callback(organisations.map(({name}) => name).filter(name => !(this.filter.organisation || []).includes(name)).slice(0, 5))
  }

  handleTagsFetchRequested = async (value, callback) => {
    const { data: values } = await API.TagValues.index({
      options: {
        page: { size: 50, number: 1 },
        filter: { value, ...this.props.filter },
        include: 'tagType'
      }
    })
    callback(values.filter(v => !((this.filter.tags || {})[v.tagType.name] || []).includes(v.value)).slice(0, 5))
  }

  handleTagsChanged = ({target: { value }}) => {
    const tags = {};
    (value || []).forEach(({value, tagType: { name }}) => {
      if(!tags[name]) tags[name] = []
      tags[name].push(value)
    })
    this.props.onFilterChange({...this.props.filter, tags }, this.loadReport)
  }

  handleDownload = (format) => {
    this.actions.download({type: 'registrations', filter: this.props.filter})
  }

  renderTagLabel = ({value, tagType: { name }}) =>
    <span><strong>{name}</strong>: {value}</span>

  renderFilters = () =>
    <section className={this.props.classes('filters', {collapsed: this.props.filter.collapsed === 'true'})}>
      {
        this.errors.length ?
        <ErrorBanner>
          {errorStringsFromError(this.errors)}
        </ErrorBanner> : false
      }
      <IconButton className={this.props.classes.collapseButton} onClick={this.handleCollapseFilters}>
        <TabIcon/>
      </IconButton>
      <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
         <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='registration'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleRegistrationsFetchRequested}
          />
          <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='programmeName'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleProgrammesFetchRequested}
          />
          <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            member='organisation'
            labelProvider={(name) => name}
            onSuggestionsFetchRequested={this.handleOrganisationsFetchRequested}
          />
          {
            Authorization.systemPermissions.readTags &&
            <MultiAutoSuggest
              classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
              labelProvider={this.renderTagLabel}
              debounceWait={200}
              label='Tags'
              onChange={this.handleTagsChanged}
              value={this.tags}
              onSuggestionsFetchRequested={this.handleTagsFetchRequested}
            />
          }
        <IconButton onClick={this.handleDownload}>
          <Download/>
        </IconButton>
      </FormContext>
    </section>

  render = () => {
    return(
      <div className={this.props.classes.wrapper}>
        {this.renderFilters()}
        {
          this.props.requests.length > 0?
            <Loader/> : <RegistrationsResults report={this.props.report.reportsRegistrations}/>
        }
      </div>
    )
  }
}

const styles = theme => ({
  toggleButton:{
    position: 'absolute',
    right: -10,
    top: -40,
    color: theme.palette.primary.main
  },
  filters: {
    borderLeft: `3px solid ${theme.palette.secondary.light}`,
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: "rgba(0, 27, 38, 0.5)",
    padding: '5px 10px',
    alignItems: 'self-end',
    '& $reportsFilter': {
      margin: 5
    }
  },
  reportsFilter: {
    flex: '1 0 250px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    height: 'calc(100vh - 230px)',
    '@media(max-width: 620px)': {
      height: 'auto'
    }
  },
  collapsed: {
    '& > *': {
      display: 'none'
    },

    '&::before': {
      content: "'Filters'",
      lineHeight: '38px',
      color: 'white',
      fontWeight: 900,
      fontSize: '1rem',
      display: 'block',
    },

    '& $collapseButton': {
      transform: 'rotate(180deg)',
      display: 'block'
    }
  },
  collapseButton: {
    color: 'white',
    fontSize: 11,
    position: 'absolute',
    zIndex: 100,
    top: 0,
    right: 0,
    transition: 'transform 500ms',
  },
})

export default compose(
  withStyles(styles),
  connect(({reports}, { filter }) => ({
    filter,
    ...reports
  })),
  connectQueryString('reports')
)(Registrations)
