import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class ReportActionSet extends CrudActionSetBase('report'){

  static initialState = {
    report: {
      reportsEvents: {
        eventBreakdown: []
      },
      reportsRegistrations: {
        available_kw_by_load_type: {},
        registrations: [],
      }
    },
    missingMeterData: []
  }
  static show(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({type, params={}, fields=null, include=null, filter=null, order=null}={}) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: API.Reports.show({type, ...params, options: {
          ...order && {order},
          ...filter && {filter},
          ...fields && {fields},
          ...include && {include}
        }})
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request  }) => {
        return {...state,  requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, report: { ...state.report, [data.type]: data } }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, show: error }
        return {...state, requests, errors }
      }
    })
  }

  static download(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({type, params={}, fields=null, include=null, filter=null, order=null}={}) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: API.Reports.downloadReport({type, ...params, options: {
          ...order && {order},
          ...filter && {filter},
          ...fields && {fields},
          ...include && {include}
        }})
      })
    })

    reducer({})
  }

  static missingMeterData(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({params={}, from, to, filter=null, ...rest}={}) => (dispatch, getState) => {
      const promise = API.Reports.missingData({...params, options: {
        from,
        to,
        ...filter && {filter},
        ...rest
      }})
      promise.category = "missingMeterData"
      return dispatch({
        type: constants.ACTION,
        promise
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, missingMeterData: data}
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, missingMeterData: error }
        return {...state, requests, errors }
      }
    })
  }
}

export default new ReportActionSet()