import React, { Component } from 'react'
import { WEEK_DAYS_LABELS } from 'components/Availabilities'
import withStyles from 'styles'
import Tooltip from '@material-ui/core/Tooltip'

export class AvailabilitiesSummary extends Component {

  getDayMask = day => new Array(48).fill({}).reduce((agg, _, idx) => {
      const active = this.props.availabilities.some(av => av.weekDays.includes(day) && av.from <= idx * 1800 && av.to > idx * 1800 )
      const [from, to] = [idx * 1800, (idx + 1) * 1800]
      const current = agg[agg.length - 1] || (agg[0] = { active, from, to})
      if(current.active === active){
        current.to = to
      }else{
        agg = agg.concat({active, from, to})
      }
      return agg
    },[]

  ).map(({active, from, to}) => ({
    active,
    percentWidth: ((to - from)/(24 * 3600)) * 100,
    from,
    to,
    fromTime: (from / 3600).toFixed(2).replace('.5', ':3').replace('.',':'),
    toTime: (to / 3600).toFixed(2).replace('.5', ':3').replace('.',':')
  }))

  getGroupedRows = () => {
    const byMask = {}
    Object.entries(WEEK_DAYS_LABELS).forEach(([day, label]) => {
      const mask = this.getDayMask(day)
      const maskKey = mask.map(({active,percentWidth}) => `${active}${percentWidth}`).join(',')
      byMask[maskKey] = (byMask[maskKey] || []).concat(label)
    })
    return Object.entries(WEEK_DAYS_LABELS).reduce((agg, [day, label]) => {
      const mask = this.getDayMask(day)
      const maskKey = mask.map(({active,percentWidth}) => `${active}${percentWidth}`).join(',')
      if(byMask[maskKey]){
        agg = agg.concat([[byMask[maskKey].join(', ')].concat(mask)])
        delete byMask[maskKey]
      }
      return agg
    }, [])
    .filter(([label, ...parts]) => parts.length > 1 || parts[0].active)
  }

  render = () => {
    return this.props.availabilities.length ?
      <div className={this.props.classes.availabilitiesTable}>
        <div className={this.props.classes.availabilitiesLabels}>
          <span>00:00 am</span>
          <span>Availabilities</span>
          <span>23:59 pm</span>
        </div>
        {
           this.getGroupedRows().map(([days, ...chunks]) =>
            <div className={this.props.classes.availabilitiesRow}>
              {
                chunks.map(({active, percentWidth, fromTime, toTime}) =>
                  <Tooltip title={`${active ? 'Available' : 'Unavailable'}: ${fromTime} – ${toTime}`}>
                    <div style={{width: `${percentWidth}%`}} className={this.props.classes('availabilitiesChunk', {filled: active, empty: !active})}/>
                  </Tooltip>
                )
              }
              <div className={this.props.classes.availabilitiesLabel}>
                {days}
              </div>
            </div>

          )
        }
      </div> :
      false
  }
}

const styles = {
  availabilitiesTable: {
    fontSize: 15,
    width: 300
  },
  availabilitiesChunk: {
    height: 19,
  },
  availabilitiesLabels: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '500',
    '& span:last-child, span:first-child': {
      color: 'rgba(255,255,255,0.8)',
    },
  },
  availabilitiesRow: {
    height: 19,
    width: 300,
    display: 'flex',
    position: 'relative',
    marginBottom: 1
  },
  availabilitiesLabel: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
    overflow: 'visible',
    fontWeight: '500',
    lineHeight: 1.3
  },
  filled: {
    background: '#9cd4c6',
    opacity: 0.7
  },
  empty: {
    background: '#98b7ae',
    opacity: 0.2
  }
}
export default withStyles(styles)(AvailabilitiesSummary)
