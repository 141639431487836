import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LineChart } from 'components/charts'
import withStyles from 'styles'
import TradingPeriod from 'constants/TradingPeriod'


export class EventCblChart extends Component{

  static propTypes = {
    status: PropTypes.string,
    className: PropTypes.string
  }

  state = { chartData: { data: [[0,0]], labels: ['','']}}

  componentDidMount = () => {
    this.setState({
      chartData: {
        data: this.buildChartData(),
        labels: ['Date', 'Baseline', 'Actual']
      }
    })
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.baseline !== this.props.baseline || prevProps.actual !== this.props.actual){
      this.setState({
        chartData: {
          data: this.buildChartData(),
          labels: ['Date', 'Baseline', 'Actual']
        }
      })
    }
  }

  buildChartData = () => {
    const startTimestamp = this.props.startTime.getTime()
    return this.props.baseline.map((val, idx) => (
      [
        new Date(startTimestamp + (idx + 1) * TradingPeriod.length.inMilliseconds),
        this.props.baseline[idx],
        this.props.actual[idx]
      ]
    ))
  }

  handleUnderlayCallback = (canvas, area, g) => {
    if(this.props.highlightRange){
      const highlightMinTime = this.props.startTime.getTime() + TradingPeriod.length.inMilliseconds * (this.props.highlightRange.min - 1)
      const highlightMaxTime = this.props.startTime.getTime() + TradingPeriod.length.inMilliseconds * this.props.highlightRange.max
      const x1 = g.toDomXCoord(highlightMinTime)
      const x2 = g.toDomXCoord(highlightMaxTime)
      canvas.fillStyle = "rgba(255, 255, 102, 0.4)";
      canvas.fillRect(
        x1,
        0,
        x2 - x1,
        1000
      )
    }
  }

  render = () =>
    <div className={this.props.classes.chartWrapper}>
      <LineChart chartData={this.state.chartData} underlayCallback={this.handleUnderlayCallback} />
    </div>

}

const styles = theme => ({
  chartWrapper: {
    height: 320,
    position: 'relative',
    '& > div:last-child': {
      flex: 1,
      height: 320,
      backgroundColor: '#3b3b3b',
      boxShadow: 'inset 1px 1px 5px 7px rgba(0, 0, 0, 0.2)',
      color: 'white',
      overflow: 'hidden'
    }
  }
})
export default withStyles(styles)(EventCblChart)