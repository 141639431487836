import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Pagination } from 'components'
import Dependent from 'containers/shared/Dependent'
import { compose, debounce, flatten, formatLocaleDateTime } from 'utils'
import { withRouter } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { VersionActions, SnackbarActions } from 'actionsets'
import withStyles from 'styles'

const FILTERED_CHANGE_KEYS = {
  updated_at: true,
  created_at: true
}

export class Results extends Component{

  static propTypes = {
    onPageSelected: PropTypes.func.isRequired,
    filter: PropTypes.object
  }

  static defaultProps = {
    filter: {}
  }

  constructor(props){
    super(props)
    VersionActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    this.state = {
      page: 0
    }
  }

  dependsOn(){
    this.loadVersions()
  }

  dependenciesMet = () => {
    return this.props.requests.length === 0
  }

  componentDidUpdate(prevProps) {
    if (this.hasChanged(prevProps.filter, this.props.filter)) {
      this.loadVersions()
    }
  }

  hasChanged = (prevFilter, filter) => {
    const keys = Object.keys(prevFilter).concat(Object.keys(filter))
                  .filter((item, index, self) => self.indexOf(item) === index)
    return Array.from(keys).some((key) => prevFilter[key] !== filter[key])
  }

  get errors(){
    return flatten([this.props.errors.index || []])
  }

  loadVersions = debounce(() => {
    this.actions.index({
      page: this.state.page,
      include: 'user',
      filter: {...this.props.filter},
      params: {
        changesetFormat: 'raw',
      }
    }).catch(() => {})
  })

  handlePageSelected = page =>
    this.setState({page}, this.loadVersions)

  renderTableHeaders = () => {
    return (
      <TableRow>
        <TableCell>Resource</TableCell>
        <TableCell>Resource id</TableCell>
        <TableCell>Action</TableCell>
        <TableCell>Who</TableCell>
        <TableCell>Changes</TableCell>
        <TableCell>When</TableCell>
      </TableRow>
    )
  }

  renderChanges = (changeset) => {
    return(
      <ul className={this.props.classes.changeset}>
        {
          Object.keys(changeset).filter((key) => !FILTERED_CHANGE_KEYS[key]).map((attrName) => {
            const [fromValue, toValue] = changeset[attrName]
            return <li key={attrName} className={this.props.classes.change}>{`${attrName}: ${fromValue} -> ${toValue}`}</li>
          })
        }
      </ul>
    )
  }

  renderVersionItem = (version) => {
    const { id, itemId, itemType, event, user, changeset, createdAt } = version
    const whodunnitLabel = (!!user) ? `${user.name} (${user.email})` : ''

    return (
      <TableRow key={id}>
        <TableCell>{itemType}</TableCell>
        <TableCell>{itemId}</TableCell>
        <TableCell>{event}</TableCell>
        <TableCell>{whodunnitLabel}</TableCell>
        <TableCell>
          { this.renderChanges(changeset) }
        </TableCell>
        <TableCell>{formatLocaleDateTime(createdAt)}</TableCell>
      </TableRow>
    )
  }

  render = () => {
    return (
      <div className={this.props.classes.results}>
        {
          this.errors.length ?
            <p className={this.props.classes.subHeader}>There are no logs matching the filter!</p> :
            <>
              <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected}/>
              <Table>
                <TableHead>
                  { this.renderTableHeaders() }
                </TableHead>
                <TableBody>
                  { this.props.versions.map(this.renderVersionItem) }
                </TableBody>
              </Table>
              <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected}/>
            </>
        }
      </div>
    )
  }
}

const styles = theme => ({
  changeset: {
    maxHeight: '115px',
    overflowX: 'auto'
  },
  results: {
    position: 'relative',
    minHeight: '200px',
  },
  change: {
    // workaround for Firefox issue with showing scrollbar even when there is no overflow
    paddingBottom: '2px'
  },
  subHeader: {
    textAlign: 'center',
    width: '100%'
  }
})

export default compose(
  Dependent({loader: true}),
  withRouter,
  withStyles(styles),
  connect(({versions}) => versions)
)(Results)
