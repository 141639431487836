import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose, uuid } from 'utils'
import withStyles from 'styles'
import moment from 'moment'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { TimePicker } from 'components'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import FormHelperText from '@material-ui/core/FormHelperText'
import TradingPeriod from 'constants/TradingPeriod'

export const WEEK_DAYS_LABELS = {
  monday:     'Mo',
  tuesday:    'Tu',
  wednesday:  'We',
  thursday:   'Th',
  friday:     'Fr',
  saturday:   'Sa',
  sunday:     'Su',
}

export class Availabilities extends Component {
  static propTypes = {
    availabilities: PropTypes.array.isRequired,
    readonly: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    helperText: PropTypes.string,
  }

  static defaultProps = {
    readonly: true,
    onChange: () => {},
    open: true,
    error: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      updates: {},
      availabilitiesById: this.props.availabilities.reduce((accumulator, availability) => {
        accumulator[availability.id] =  availability
        return accumulator
      },{}),
      availabilitiesErrors: {},
      availabilitiesDates: this.props.availabilities.reduce((accumulator, availability) => {
        const {id, from, to} = availability
        const startOfDay = moment().startOf('day')
        const fromDate  = moment(startOfDay).add(moment.duration(from, 'seconds'))
        const toDate    = moment(startOfDay).add(moment.duration(  to, 'seconds'))

        accumulator[id] =  {fromDate, toDate}
        return accumulator
      },{}),
    }

    this.state = {...this.state, ...this.mergeAvailabilityUpdates({})}
  }

  get isReadonly() {
    return this.props.readonly
  }

  mergeAvailabilityUpdates = (updates) => {
    const availabilitiesById = {...this.state.availabilitiesById, ...updates}
    const availabilitiesErrors = {}
    const availabilitiesDates = {...this.state.availabilitiesDates}

    Object.entries(updates).forEach(([id, update]) => {
      let {from, to} = update
      let startOfDay = moment().startOf('day')
      let fromDate  = moment(startOfDay).add(moment.duration(from, 'seconds'))
      let toDate    = moment(startOfDay).add(moment.duration(  to, 'seconds'))

      availabilitiesDates[id] = {fromDate, toDate}
    })

    Object.entries(availabilitiesById).filter(([id, {_destroy}]) => (!_destroy)).forEach(([id, availability]) => {
      let { weekDays } = availability
      let errors = { weekDaysErrors: [], fromDateErrors: [], toDateErrors:[] }

      if(weekDays.length === 0) {
        errors.weekDaysErrors.push('At least one weekday needs to be checked')
      }

      let {fromDate, toDate} = availabilitiesDates[id]
      if(toDate.diff(fromDate) <= 0 && (toDate.hour() + toDate.minute() !== 0)) {
        errors.fromDateErrors.push(`Has to be before ${toDate.format('HH:mm')} date`)
        errors.toDateErrors.push(`Has to be after ${fromDate.format('HH:mm')} date`)
      }

      Object.keys(errors).forEach(key => {
        if(errors[key].length === 0) {
          delete(errors[key])
        }
      })
      if(Object.keys(errors).length !== 0) {
        availabilitiesErrors[id] = errors
      }
    })

    return { availabilitiesById, updates: {...this.state.updates, ...updates}, availabilitiesErrors, availabilitiesDates }
  }

  nextUpdates = (updates) => {
    this.setState(this.mergeAvailabilityUpdates(updates), () => {
      const updates = Object.values(this.state.updates).filter(({isNew, _destroy}) => !(isNew && _destroy)).map(({id, isNew, ...rest}) => {
        return isNew ? rest : {id, ...rest}
      })
      const hasErrors = Object.keys(this.state.availabilitiesErrors).length !== 0

      this.props.onChange({updates, hasErrors})
    })
  }

  dayChangedHandler = (availability) => {
    if(this.isReadonly) {
      return
    }

    return (event, checked) => {
      const day = event.target.value
      const udpatedWeekDays = (checked) ? [...availability.weekDays, day] : [...availability.weekDays].filter(e => e !== day)

      this.nextUpdates({[availability.id]: {...availability, weekDays: udpatedWeekDays}})
    }
  }

  timeChangedHandler = (field, availability) => ({target: {value}}) => {
    const newTime     = moment(value)
    const startOfDay  = moment(newTime).startOf('day')
    const duration    = moment.duration(newTime.diff(startOfDay)).as('seconds')

    this.nextUpdates({[availability.id]: {...availability, [field]: duration}})
  }

  availabilityDeletedHandler = (availability) => () => {
    this.nextUpdates({[availability.id]: {...availability, _destroy: true}})
  }

  handleDayClicked = (event) => {
    if(this.isReadonly) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  handleAddAvailaility = (event) => {
    const newId = uuid()
    this.nextUpdates({[newId]: {id: newId, isNew: true, from: 0, to: 0, weekDays: []}})
  }

  renderTimeInterval = (availability) => {
    const {fromDate, toDate} = this.state.availabilitiesDates[availability.id]

    if(this.isReadonly) {
      return <Typography variant='subtitle1'>{fromDate.format("HH:mm")}&mdash;{toDate.format("HH:mm")}</Typography>
    } else {
      let {fromDateErrors, toDateErrors} = this.state.availabilitiesErrors[availability.id] || {}

      return (
        <Fragment>
          <TimePicker className={this.props.classes.timePicker } minutesStep={TradingPeriod.length.inMinutes} value={fromDate}
            maxDate={toDate}
            maxDateMessage='There is something wrong'
            onChange={this.timeChangedHandler('from', availability)}
            required={true}
            error={!!fromDateErrors} {...fromDateErrors && { helperText: fromDateErrors.join('\n') }}
          />
          <span style={{marginTop: 8, display: 'inline-block'}}>&mdash;</span>
          <TimePicker className={this.props.classes('timePicker', 'timePickerTo') } minutesStep={TradingPeriod.length.inMinutes} value={toDate}
            minDate={fromDate}
            minDateMessage='There is something wrong'
            onChange={this.timeChangedHandler('to', availability)}
            required={true}
            error={!!toDateErrors} {...toDateErrors && { helperText: toDateErrors.join('\n') }}
          />
        </Fragment>
      )
    }
  }

  renderDays = (availability) => {
    const {id, weekDays} = availability
    const {weekDaysErrors} = this.state.availabilitiesErrors[id] || {}

    return (
      <Fragment>
        {Object.entries(WEEK_DAYS_LABELS).map(([dayKey, label]) => {
          const checked = weekDays.includes(dayKey)
          return <FormControlLabel key={dayKey}
                    control={<Checkbox className={this.props.classes('selectCondensed', {'selectReadonly': this.isReadonly})} inputProps={
                      {
                        onMouseDown: this.handleDayClicked,
                      }
                    } />}
                    onChange={this.dayChangedHandler(availability)}
                    checked={checked}
                    label={label} labelPlacement='bottom'
                    value={dayKey}
                    className={this.props.classes('condensed', {'selectReadonly': this.isReadonly})}/>
        })}
        {
          (!!weekDaysErrors) &&
          <FormHelperText error={true}>{weekDaysErrors.join('\n')}</FormHelperText>
        }
      </Fragment>
    )
  }

  render = () => {
    if(!this.props.open) {
      return null
    }

    return (
      <Fragment>
        {
          (this.props.error) &&
          <FormHelperText error={true}>{this.props.helperText}</FormHelperText>
        }
        {
          Object.entries(this.state.availabilitiesById).filter(([_, {_destroy}]) => !_destroy).map(([id, availability], index) => {
            const isLast = ((1 + index) === this.props.availabilities.length)
            return(
              <div key={availability.id} className={this.props.classes('row',{rowLast: isLast})}>
                <div className={this.props.classes('availability')}>
                  <div className={this.props.classes('cell', 'cellSpaceAfter')}>{this.renderTimeInterval(availability)}</div>
                  <div className={this.props.classes('cell', 'cellSpaceAfter')}>{this.renderDays(availability)}</div>
                </div>
                {
                  (!this.isReadonly) &&
                  <div className={this.props.classes('cell')}>
                    <IconButton onClick={this.availabilityDeletedHandler(availability)}>
                      <DeleteIcon/>
                    </IconButton>
                  </div>
                }
              </div>
            )
          })
        }
        {
          (!this.isReadonly) &&
          <div className={this.props.classes('newRecord')}>
            <Fab onClick={this.handleAddAvailaility}>
              <AddIcon/>
            </Fab>
          </div>
        }
      </Fragment>
    )
  }
}

const styles = theme => ({
  condensed: {
    marginRight: 0,
    marginLeft: 0
  },
  selectCondensed: {
    padding: 0
  },
  selectReadonly: {
    cursor: 'default'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  },
  availability: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cell: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  cellSpaceAfter: {
    marginRight: 12
  },
  rowLast: {
    borderBottom: 'none'
  },
  timePicker: {
    width: 100,
  },
  timePickerTo: {
    marginLeft: 10
  },
  newRecord: {
    marginTop: 24,
    display: 'flex',
    flexFlow: 'row-reverse'
  }
})

export default compose(
  withStyles(styles)
)(Availabilities)