import React, { Component } from 'react'
import { PermissionActions } from 'actionsets'
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom'

export class WithPermissions extends Component{

  static propTypes = {
    unmountOnChange: PropTypes.bool
  }

  constructor(props){
    super(props)
    this.state = {}
    PermissionActions.bindActions(this)
  }

  static getDerivedStateFromProps(nextProps, nextState){
    const nextPermissions = nextProps.permissions || nextProps.requiredPermissions(nextProps)
    if(JSON.stringify(nextPermissions) !== nextState.permissionsJSON && nextState.permissions){
      return {...nextState, permissions: null}
    }
    return null
  }

  componentDidMount(){
    this._isMounted = true
    this.loadPermissions()
  }

  componentWillUnmount(){
    this._isMounted = false
  }

  componentDidUpdate(){
    this.loadPermissions()
  }

  loadPermissions = async () => {
    if(!this.state.permissions){
      if(this.props.permissions){
        this.setState({permissions: this.props.permissions, mounted: true, permissionsJSON: JSON.stringify(this.props.permissions)})
      }
      else{
        const permissionSpec = this.props.requiredPermissions(this.props)
        try{
          const {data: permissions} = await this.actions.getPermissions(permissionSpec)
          if(this._isMounted){
            this.setState({permissions, mounted: true, permissionsJSON: JSON.stringify(permissionSpec)})
          }
        }catch(err){
          console.error(err)
        }
      }
    }
  }

  render = () => {
    const { component: Component, permissions: _, ...rest} = this.props
    if(this.state.permissions) {
      let check = this.props.checkPermissions(this.state.permissions, rest)
      if((typeof check) === 'string') {
        return <Redirect to={check}/>
      } else if(!check) {
        return <Redirect to="/unauthorized"/>
      }
    }

    return (
      this.state.permissions || (
        this.state.mounted && !this.props.unmountOnChange
      ) ? <Component {...rest} permissions={this.state.permissions || {}}/> : false
    )
  }
}

export default (requiredPermissions, {
  unmountOnChange=false,
  checkPermissions=() => { return true }
}={}) => {
  return component => props => <WithPermissions {...props} unmountOnChange={unmountOnChange} checkPermissions={checkPermissions} requiredPermissions={requiredPermissions} component={component} />
}
