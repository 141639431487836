import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import withStyles from 'styles'
import PropTypes from 'prop-types'

import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Pagination } from 'components'
import { NotificationListItem } from './'
import { compose } from 'utils'
import { NotificationActions } from 'actionsets'

export class Notifications extends Component {
  static propTypes = {
    filter:  PropTypes.object.isRequired,
    onRequestReloadNotifications: PropTypes.func.isRequired,
    singleExpension: PropTypes.bool.isRequired
  }

  static defaultProps = {
    singleExpension: true
  }

  constructor(props) {
    super(props)
    NotificationActions.bindActions(this)

    this.state = {
      selection: {},
      expendedNotifications: {},
      showNotificationDeliveries: false,
    }
  }

  loadNotifications = async (page = (this.props.page | 0)) => {
    this.props.onRequestReloadNotifications(page)
  }

  renderFilter = () => {
    return null
  }

  selectHandler = (notification) => (selected) => {
    if(selected) {
      this.setState({ selection: {...this.state.selection, [notification.id]: notification} })
    } else {
      let newSelection = {...this.state.selection }
      delete newSelection[notification.id]
      this.setState({ selection: newSelection })
    }
  }

  cancelHandler = (notification) => async () => {
    if(await this.confirm("Are you sure you want to cancel this notification?")) {
      await this.cancelNotifications([notification.id])
    }
  }

  enqueueHandler = (notification) => async () => {
    if(await this.confirm("Are you sure you want enqueue this notification?")) {
      await this.enqueueNotifications([notification.id])
    }
  }

  onClickHandler = (notification) => async () => {
    const { id } = notification
    const expended = this.state.expendedNotifications[id]

    this.setState({
      expendedNotifications: { ...(!this.props.singleExpension) && this.state.expendedNotifications, [id]: !expended }
    })
  }

  cancelSelectedHandler = async () => {
    const ids = Object.keys(this.state.selection)
    if(await this.confirm(`Are you sure you want to cancel ${ids.length} notifications?`)) {
      await this.cancelNotifications( ids )
      this.setState({ selection:{} })
    }
  }

  enqueueSelectedHandler = async () => {
    const ids = Object.keys(this.state.selection)
    if(await this.confirm(`Are you sure you want to enqueue ${ids.length} notifications?`)) {
      await this.enqueueNotifications( ids )
      this.setState({ selection:{} })
    }
  }

  cancelNotifications = async (notificationsIds) => {
    await this.actions.cancelNotifications(notificationsIds)
    await this.loadNotifications()
  }

  enqueueNotifications = async (notificationsIds) => {
    await this.actions.enqueueNotifications(notificationsIds)
    await this.loadNotifications()
  }

  confirm = async (message) => {
    await Promise.resolve()
    return global.confirm(message)
  }

  get selectionCount() {
    return Object.keys(this.state.selection).length
  }

  get filterByState() {
    const { state } = this.props.filter || {}
    return state
  }

  renderNotificationListItem = (notification) => {
    const state = this.filterByState
    const selectable = (state === 'quarantined')
    const cancelable = (state === 'quarantined')
    const enquable = (state === 'quarantined')
    const { id } = notification

    return (
      <NotificationListItem key={notification.id} notification={notification}
        selected={ !!this.state.selection[notification.id] }
        onSelect={ this.selectHandler(notification) }
        onCancel={ this.cancelHandler(notification) }
        onEnqueue={ this.enqueueHandler(notification) }
        onClick={ this.onClickHandler(notification) }
        selectable={ selectable }
        cancelable={ cancelable }
        enquable={ enquable }
        deliveryState={ state }
        expended={ this.state.expendedNotifications[id] } />
    )
  }

  renderNotificationDeliveriesDialog = () => {
    if(!this.state.showNotificationDeliveries) {
      return false
    }

    return (
      <Dialog open={true} onClose={() => this.setState({showNotificationDeliveries: null})} classes={{paper: this.props.classes.dialogPaper}}>
        <DialogTitle>Deliveries</DialogTitle>
        <DialogContent>
          Deliveries
        </DialogContent>
      </Dialog>
    )
  }

  render = () => {
    return (
      <Fragment>
        { this.renderNotificationDeliveriesDialog() }
        { this.selectionCount !== 0 && (this.filterByState === 'quarantined') &&
          <Fragment>
            <Badge badgeContent={ this.selectionCount } color="secondary">
              <Button onClick={ this.cancelSelectedHandler } size='small'>Cancel selected</Button>
            </Badge>
            <Badge badgeContent={ this.selectionCount } color="secondary">
              <Button onClick={ this.enqueueSelectedHandler } size='small'>Enqueue selected</Button>
            </Badge>
          </Fragment>
        }
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadNotifications}
          startAdornment={ this.renderFilter() } style={{}} linkStyle={{}}/>
        <MuiList dense>
          {
            this.props.notifications.map(this.renderNotificationListItem)
          }
          { this.props.notifications.length === 0 &&
            <ListItem className={this.props.classes.subHeader}>
              <ListItemText className={this.props.classes.subHeaderContent} primary='There are no notifications matching the filter!' />
            </ListItem>
          }
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadNotifications} style={{}} linkStyle={{}}/>
      </Fragment>
    )
  }
}

const styles = theme => ({
  subHeader: {
    textAlign: 'center'
  },
  subHeaderContent: {
    width: '100%'
  },
  dialogPaper: {
    width: '90vw'
  },
})

export default compose(
  connect(({notifications}) => ({ ...notifications })),
  withStyles(styles),
)(Notifications)
