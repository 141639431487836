import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import WidgetContainer from './WidgetContainer'

export class ManagementWidget extends Component{

  static propTypes = {

  }

  render = () =>
    <WidgetContainer>
      <div>
        <Typography variant='h6'>Management</Typography>
      </div>
    </WidgetContainer>

}

export default ManagementWidget