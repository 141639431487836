import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const ParentEvents = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({programmeId}) => programmeId ? `programmes/${programmeId}/parent_events` : '/parent_events'),
    create:  endpoint => endpoint.method('post').path(({programmeId}) => `programmes/${programmeId}/parent_events`),
    event:   endpoint => endpoint.method('post').path(({id}) => `parent_events/${id}/events`),
    update:  endpoint => endpoint.method('put').path(({id}) => `parent_events/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `parent_events/${id}`),
    show:    endpoint => endpoint.path(({id}) => `parent_events/${id}`),
    defaults:endpoint => endpoint.path(() => `parent_events/defaults`),
  }
)