import React, { Component } from 'react'
import { connect } from 'react-redux'
import { OrganisationActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, TaggedSearchField, LabeledSwitch } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import BusinessIcon from '@material-ui/icons/Business'
import { compose, errorStringsFromError, debounce } from 'utils'
import { withRouter } from 'react-router-dom'
import { connectQueryString } from 'containers/shared'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import withStyles from 'styles'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FABAbsolute } from 'components'

const defaultFilter = { active: true, nested: true }
const taggedSearchFieldFilter = {tagType: 'Organisation Type'}

export class List extends Component{

  constructor(props){
    super(props)
    OrganisationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return this.loadOrganisations()
  }

  loadOrganisations = debounce(() => {
    return this.actions.index({
      params: { organisationId: this.organisationId },
      page: this.props.page,
      pageSize: 12,
      filter: this.filter,
      include: 'primaryContact,secondaryContact',
      fields: {organisations: 'name,tags,status,primaryContact,readyForDelete,logoUrlPath,iconUrlPath', users: 'name'}
    })
  })

  componentDidUpdate = prevProps => {
    if (this.props.organisationId !== prevProps.organisationId) {
      this.loadOrganisations()
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showOrganisation = id => () => {
    this.props.history.push(`/organisations/${this.organisationId}/${this.tab}/${id}?organisations.page=${this.props.page}`)
  }

  editOrganisation = id => event => {
    this.props.history.push(`/organisations/${id}/edit`)
    event.stopPropagation()
  }

  deleteOrganisation = id => event => {
    this.actions.destroy({id})
                .then(this.loadOrganisations)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }


  handleFilterChanged = async rawFilter => {
    const filter = {...rawFilter, tags: {}};
    (rawFilter.tags || []).forEach(({value, tagType: { name }}) => {
      if(!filter.tags[name]) filter.tags[name] = []
      filter.tags[name].push(value)
    })
    await this.props.onFilterChange(filter)
    this.loadOrganisations()
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadOrganisations()
  }

  get tab() {
    return this.props.match.params.tab || 'details'
  }

  get defaultFilter(){
    return defaultFilter
  }

  get filter(){
    return {...this.defaultFilter, ...this.props.filter }
  }

  get organisationId(){
    return this.props.organisationId || this.props.match.params.id
  }

  get organisations(){
    return this.props.organisations
  }

  get organisationPermissions(){
    return this.props.organisationPermissions || {}
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  handleGoToUser = id => event => {
    event.stopPropagation()
    this.props.history.push(`/users/${id}`)
  }

  renderOrganisationListItem = ({id, name, status, tags, primaryContact, readyForDelete, logoUrlPath, iconUrlPath }) => {
    const imgUrlPath = iconUrlPath || logoUrlPath
    return (
      <ListItem button className={this.props.classes('pointer', {active: id === this.props.active})} onClick={this.showOrganisation(id)} key={id}>
        <ListItemIcon>
          {(status === 'active') ? (imgUrlPath ? <img alt="" className={this.props.classes.display1Logo} src={imgUrlPath}/> : 
                                                 <BusinessIcon className={this.props.classes.display1Icon}/>) : 
                                   <NotInterestedIcon className={this.props.classes.display1Icon}/>}
        </ListItemIcon>
        <ListItemText primary={name}/>
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  wrap = children =>
    this.props.uncontained ? children : <Card><CardContent>{children}</CardContent></Card>


  render = () =>
    this.wrap(
      <>
        <TaggedSearchField defaultFilter={this.defaultFilter} filter={taggedSearchFieldFilter}  onChange={this.handleFilterChanged} context={this.props.filter}>
          <LabeledSwitch checked={this.props.filter.active !== 'false'} label='Hide Inactive' member='active'/>
          <LabeledSwitch checked={!('nested' in this.props.filter) || this.props.filter.nested === 'true'} label='Show Nested' member='nested'/>
        </TaggedSearchField>
        <MuiList>
          {this.organisations.map(this.renderOrganisationListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        {
          this.organisationPermissions.writeOrganisation &&
          <FABAbsolute color='primary' onClick={() => this.props.history.push(`/organisations/${this.organisationId}/organisations/new`)}
                       className={this.props.classes.addButton}>
            <AddIcon/>
          </FABAbsolute>
        }
      </>
    )

}

const styles = {
  pointer: {
    cursor: 'pointer'
  },
  display1Logo: {
    width: "40px",
    height: "40px",
    marginTop: "-8px",
    marginBottom: "-8px",
    objectPosition: '0 0',
    background: "url(/organisation-placeholder.png) no-repeat center/95%",
  },
  addButton: {},
  active: {
    background: 'rgba(255,255,255,0.15) !important',
    '&::after': {
      content: "''",
      position: 'absolute',
      right: -20,
      top: 0,
      borderLeft: '20px solid rgba(255,255,255,0.1)',
      borderTop: '25px solid transparent',
      borderBottom: '25px solid transparent',
    },
  },
}

export default compose(
  Dependent({loader: false}),
  connectQueryString('organisations'),
  withStyles(styles),
  withRouter,
  connect(({organisations}) => organisations)
)(List)
