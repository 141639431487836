import React, { Component } from 'react'
import WidgetContainer from 'containers/landing/widgets/WidgetContainer'
import Typography from '@material-ui/core/Typography'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ReportIcon from '@material-ui/icons/Assignment'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { compose } from 'utils'
import { withRouter } from 'react-router-dom'
import { humanize } from 'utils'

const reportTypeLabels = {
  'x-resourceStatus': 'status'
}

export class DeviceReports extends Component{

  reportTypeLabel = (reportType) => {
    return reportTypeLabels[reportType] || reportType
  }

  renderStream = ({reportType, rid, emixUnit, lastestReportRegistrationId}) =>
    <ListItem button onClick={this.handleShowReports(lastestReportRegistrationId)} key={rid}>
      <ListItemIcon>
        <ReportIcon />
      </ListItemIcon>
      <ListItemSecondaryAction>
        <Tooltip title={'Show datapoints'}>
          <IconButton aria-label="Datapoints" onClick={this.handleShowDataPoint(rid)}>
            <ShowChartIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
      <ListItemText primary={humanize(this.reportTypeLabel(reportType))} secondary={humanize(emixUnit || '....')}/>
    </ListItem>


  handleShowReports = reportRegistrationId => () => {
    this.props.history.push(`/oadr/report_registrations/${reportRegistrationId}`)
  }

  handleShowDataPoint = rid => (event) => {
    this.props.history.push(`/oadr/datapoints/${rid}/report_intervals`)
    event.stopPropagation()
  }

  render = () => {
    const {device: { streamIdentifiers }} = this.props

    return (
      <WidgetContainer>
        <Typography variant='h6'>Reports</Typography>
        <Card>
          <CardContent>
            <MuiList dense>
              {streamIdentifiers.map(this.renderStream)}
            </MuiList>
          </CardContent>
        </Card>

      </WidgetContainer>
    )
  }
}


export default compose(
  withRouter
)(DeviceReports)