import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, humanize, formatLocaleDateTime, errorStringsFromError } from 'utils'
import Dependent from 'containers/shared/Dependent'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Pagination, ErrorBanner } from 'components'
import { AmbiDeviceStateActions } from 'actionsets'

const expectedFields = [
  'category',
  'createdOn',
  'requestedOn',
  'temperature',
  'humidity',
  'power',
  'mode',
  'fan',
  'swing',
  'louver'
]

const formatTime = str => (!!str) && formatLocaleDateTime(str, {includeSeconds: true})

const fieldTransformations = {
  category: humanize,
  createdOn: formatTime,
  requestedOn: formatTime
}

export class AmbiDeviceStates extends Component {
  constructor(props) {
    super(props)
    AmbiDeviceStateActions.bindActions(this)
  }

  dependsOn() {
    this.loadStates()
  }

  loadStates = () => {
    this.actions.index({
      deviceId: this.props.match.params.deviceId,
      page: this.props.page
    })
  }

  dependenciesMet() {
    return this.props.requests.length === 0
  }

  handlePageSelected = async page =>{
    await this.actions.setPage(page)
    this.loadStates()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderContents = () => this.dependenciesMet() &&
    this.props.ambiDeviceStates.map(record =>
      <TableRow key={record.id}>
        {expectedFields.map(field =>
          <TableCell key={field}>{fieldTransformations[field] ? fieldTransformations[field](record[field]) : record[field]}</TableCell>
        )}
      </TableRow>
    )

  renderTable = () =>
    <Table>
      <TableHead>
        <TableRow>
        {expectedFields.map(name =>
          <TableCell key={name}>{humanize(name)}</TableCell>
        )}
        </TableRow>
      </TableHead>
      <TableBody>{this.renderContents()}</TableBody>
    </Table>

  renderErrorBanner = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () => {
    return (
      <Card>
        <CardContent>
          {this.renderErrorBanner()}
          {this.renderTable()}
          <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        </CardContent>
      </Card>
    )
  }
}

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  connect(({ambiDeviceStates}) => ambiDeviceStates),
)(AmbiDeviceStates)