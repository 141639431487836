import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { TokenActions, AppVersionActions } from 'actionsets'
import { connect } from 'react-redux'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography';
import OrganisationIcon from '@material-ui/icons/Work';
import TagIcon from '@material-ui/icons/LocalOffer';
import RegionIcon from '@material-ui/icons/MyLocation';
import GxpIcon from '@material-ui/icons/LocationCity'
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People'
import ProgrammeIcon from '@material-ui/icons/Assignment'
import RegistrationIcon from '@material-ui/icons/AssignmentTurnedIn'
import SiteIcon from '@material-ui/icons/Room'
import DeviceIcon from '@material-ui/icons/SettingsRemote'
import InvoiceIcon from '@material-ui/icons/AttachMoney'
import SubstationIcon from '@material-ui/icons/BrokenImage'
import JobIcon from '@material-ui/icons/Cached'
import PersonIcon from '@material-ui/icons/Person'
import DocumentIcon from '@material-ui/icons/Description'
import InfoIcon from '@material-ui/icons/Info'
import VerificationIcon from '@material-ui/icons/Timeline'
import CalendarIcon from '@material-ui/icons/DateRange'
import HomeIcon from '@material-ui/icons/Dashboard'
import EventIcon from '@material-ui/icons/EventAvailable'
import MailIcon from '@material-ui/icons/Mail'
import LoadTypeIcon from '@material-ui/icons/WbIncandescent'
import ReportIcon from '@material-ui/icons/LibraryBooks'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'
import HelpIcon from '@material-ui/icons/Help'
import MeterDataIcon from '@material-ui/icons/ShowChart'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, Authorization } from 'utils'
import withStyles from 'styles'
import { withPermissions } from 'containers/shared'
import Chip from '@material-ui/core/Chip'
import { EnvironmentColors } from '../../../constants'

import { NotificationsBadge } from 'containers/notifications'

export class Nav extends Component{

  state = {
    atTop: true
  }

  static requiredPermissions = () => ({
    system: [
      'readSystemParams', 'writeSystemParams', 'readBackgroundJobs',
      'readNetworkTopology', 'readCalendars', 'writeCalendars', 'readVerificationTypes',
      'readApiDocs', 'readAccessAttempts', 'readTags','writeTags', 'manageLoadTypes'
    ],
    organisation: {
      [Authorization.organisationId]: [
        'readOrganisation','createOrganisations','readUsers', 'readProgrammes',
        'readSites', 'readDevices', 'readPayments', 'createPayments', 'viewHelp',
        'readSubstations', 'readControlRoom'
      ]
    }
  })

  constructor(props){
    super(props)
    TokenActions.bindActions(this, 'tokens')
    AppVersionActions.bindActions(this)
    this.actions.getTag()
    this.state = {drawerOpen: false}
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    this.setState({atTop: window.scrollY === 0})
  }

  handleRequestOpen = event => {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }

  handleRequestClose = () => {
    this.setState({open: false})
  }

  get permissions(){
    return this.props.permissions || {}
  }

  get systemPermissions(){
    return this.permissions.system || {}
  }

  get organisationPermissions(){
    return (this.permissions.organisation || {})[Authorization.organisationId] || {}
  }

  render = () =>
    <Fragment>
      <AppBar position="sticky" className={this.props.classes('header', {atTop: this.state.atTop})}>
        <Toolbar className={this.props.classes.headerContainer}>
          <Typography className={this.props.classes.title} variant="h6" color="inherit">
            <Link to='/'>
              <span>FlexPoint</span>
            </Link>
            {
              process.env.REACT_APP_ENVIRONMENT_TAG !== 'production' &&
              <Chip className={this.props.classes.statusChip} label={process.env.REACT_APP_ENVIRONMENT_TAG}/>
            }
          </Typography>
          <NotificationsBadge/>
          <IconButton color="inherit" onClick={this.handleRequestOpen}>
            <PersonIcon/>
          </IconButton>
          <IconButton color="inherit" aria-label="Menu" onClick={() => this.setState({drawerOpen: true})} style={{marginRight: -12}}>
            <MenuIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={!!this.state.open}
        onClose={this.handleRequestClose}
      >
        <Link to="/profile"><MenuItem>My Profile</MenuItem></Link>
        <MenuItem onClick={this.actions.tokens.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        anchor="right"
        classes={{paper: this.props.classes.drawer}}
        open={this.state.drawerOpen}
        onClose={() => this.setState({drawerOpen: false})}
        onClick={() => this.setState({drawerOpen: false})}
      >
        {!this.props.needChangePassword &&
          <div>
            <Link to='/'>
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon/>
                </ListItemIcon>
                <ListItemText primary='Dashboard'/>
              </ListItem>
            </Link>
            {
              <Link to={`/organisations/${Authorization.organisationId}`}>
                <ListItem button>
                  <ListItemIcon>
                    <OrganisationIcon/>
                  </ListItemIcon>
                  <ListItemText primary="My Organisation" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readUsers &&
              <Link to={`/organisations/${Authorization.organisationId}/users`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readUsers &&
              <Link to={`/organisations/${Authorization.organisationId}/roles`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Roles" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readProgrammes &&
              <Link to={`/organisations/${Authorization.organisationId}/programmes`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <ProgrammeIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Programmes" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readSites &&
              <Link to={`/organisations/${Authorization.organisationId}/registrations`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <RegistrationIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Registrations" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readSites &&
              <Link to={`/organisations/${Authorization.organisationId}/sites`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <SiteIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Sites" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readDevices &&
              <Link to={`/organisations/${Authorization.organisationId}/devices`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <DeviceIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Devices" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readPayments &&
              <Link to={`/organisations/${Authorization.organisationId}/invoices`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <InvoiceIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Invoices" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readControlRoom &&
              <Link to={`/organisations/${Authorization.organisationId}/gxps`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <GxpIcon/>
                  </ListItemIcon>
                  <ListItemText primary="GXPs" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.readSubstations &&
              <Link to={`/organisations/${Authorization.organisationId}/substations`} className={this.props.classes.subItem}>
                <ListItem button>
                  <ListItemIcon>
                    <SubstationIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Substations" />
                </ListItem>
              </Link>
            }
            {
              <Link to={`/events`}>
                <ListItem button>
                  <ListItemIcon>
                    <EventIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Events" />
                </ListItem>
              </Link>
            }
            {
              (this.organisationPermissions.readSites || this.systemPermissions.readNetworkTopology) &&
              <Link to='/meter_data'>
                <ListItem button>
                  <ListItemIcon>
                    <MeterDataIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Meter Data" />
                </ListItem>
              </Link>
            }
            {
              <Link to='/reports'>
                <ListItem button>
                  <ListItemIcon>
                    <ReportIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
              </Link>
            }
            {
              this.organisationPermissions.viewHelp &&
              <a href={process.env.REACT_APP_HELP_URL} target="_blank" rel="noopener noreferrer">
                <ListItem button>
                  <ListItemIcon>
                    <HelpIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Help" />
                </ListItem>
              </a>
            }
            {
              <ListItem className={this.props.classes.admin} component='div'>
                <ListItemText primary="System" />
              </ListItem>
            }
            {
              this.systemPermissions.readVerificationTypes &&
              <Link to='/verification_methods' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <VerificationIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Verification Methods" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readNetworkTopology &&
              <Link to='/regions' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <RegionIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Regions" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readNetworkTopology &&
              <Link to='/gxps/orphaned' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <GxpIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Orphan GXPs" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readSystemParams &&
              <Link to={`/monthly_reports`} className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <FileCopyIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Monthly Reports" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readApiDocs &&
              <Link to='/apidocs' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <DocumentIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Api Docs" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readAccessAttempts &&
              <Link to='/login_failures' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <InfoIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Failed Accesses" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readTags &&
              <Link to='/tags' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <TagIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Tags" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.writeSystemParams &&
              <Link to='/notifications_control' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <MailIcon/>
                  </ListItemIcon>
                  <ListItemText primary="System Notifications" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readBackgroundJobs &&
              <Link to='/jobs' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <JobIcon/>
                  </ListItemIcon>
                  <ListItemText primary="System Jobs" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readCalendars &&
              <Link to='/calendars' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Calendar" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.manageLoadTypes &&
              <Link to='/load_types' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <LoadTypeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Load Types" />
                </ListItem>
              </Link>
            }
            {
              this.systemPermissions.readSystemParams &&
              <Link to='/versions' className={this.props.classes.adminItem}>
                <ListItem button>
                  <ListItemIcon>
                    <ChangeHistoryIcon />
                  </ListItemIcon>
                  <ListItemText primary="Audit log view" />
                </ListItem>
              </Link>
            }
          </div>
        }
        <span className={this.props.classes.tag}>Build {process.env.REACT_APP_VERSION}/API {this.props.tag}</span>
      </Drawer>
    </Fragment>
  }

const styles = theme =>  ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    transition: 'box-shadow 500ms, border 500ms',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    boxShadow: '0px 20px 80px 20px rgba(0,0,0,0.1)',
    background: 'rgba(17, 143, 203, 0.97)',
    '&$atTop': {
      borderBottom: '1px solid rgba(0,0,0,0)',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& $headerContainer': {
      width: '100%'
    },
  },
  atTop: {},
  headerContainer: {
    boxSizing: 'content-box',
    width: theme.viewAreaMaxWidth,
    transition: 'width 500ms',
  },
  drawer: {
    background: 'rgb(10, 55, 78, 0.97)',
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    paddingTop: 20,
    '& div > span': {
      color: 'white'
    },
    '& h3': {
      color: 'white'
    },
    '& div[role=button]': {
      paddingLeft: 40,
      paddingRight: 60,
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderTop: '1px solid rgba(255,255,255,0)',
      borderBottom: '1px solid rgba(255,255,255,0)',
      marginBottom: -1,
    },
    '& div[role=button]:hover': {
      background: 'rgb(28, 173, 43)',
      borderTop: '1px solid rgba(255,255,255,0.75)',
      borderBottom: '1px solid rgba(255,255,255,0.75)',
    },
    '& svg': {
      color: 'white'
    }
  },
  title: {
    flex: 1,
    '& img': {
      width: 100,
      marginBottom: -10,
      marginRight: 20,
      boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.3)'
    },
    '@media(max-width: 600px)': {
      '& img': {
        width: 50,
        marginBottom: 0,
        marginRight: 5,
        boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.3)'
      },
      'span': {
        fontSize: 12
      }
    }
  },
  statusChip: {
    display: 'inline-flex',
    margin: '0 10px',
    textTransform: "uppercase",
    backgroundColor: `${EnvironmentColors[process.env.REACT_APP_ENVIRONMENT_TAG]}`,
    '@media(max-width: 700px)': {
      display: "inline-flex",
      fontSize: "8px",
      padding: "0",
      margin: "0",
      marginLeft: "10px",
    }
  },
  tag: {
    textAlign: 'right',
    padding: 10,
    fontSize: 9,
    color: "#efefef"
  },
  subItem: {
    '& > div[role=button]': {
      padding: '5px 60px',
      '& > div': {
        marginRight: 5
      }
    },
    '& + a:not($subItem) > div': {
      marginTop: 10
    }
  },
  admin: {
    padding: '15px 40px 5px',
    '& > div': {
      borderBottom: '1px solid rgba(255,255,255,0.5)',
      paddingBottom: '5px'
    },
    '&:last-child': {
      display: 'none'
    }
  },
  adminItem: {
    '& > div[role=button]': {
      padding: '5px 40px',
    },
  }
})

export default compose(
  withPermissions(Nav.requiredPermissions),
  connect(({tokens: {needChangePassword}, appVersion: {tag}}) => ({needChangePassword, tag})),
  withStyles(styles)
)(Nav)
