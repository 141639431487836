import React, { Component, Fragment } from 'react'
import Dygraph from 'dygraphs';
import withStyles from 'styles'
import { legendFormatter } from 'utils/ChartUtils'

export class LineChart extends Component{

  static defaultProps = {
    fillGraph: true,
    chartData: {data: [[0,0]], labels: ['', '']},
    colors: [
      "#e6194b","#3cb44b","orange","#0082c8",
      "#f58231","#911eb4","#46f0f0","#f032e6",
      "#d2f53c","#008080","#aa6e28","#800000",
      "#aaffc3","#808000","#ffd8b1"
    ],
    colorValue: 0.9,
    fillAlpha: 0.2,
    stacked: false,
    strokeBorderWidth: 0,
    legendFormatter: legendFormatter('kWh', 2),
    axes: {
      y: {
        axisLabelFormatter: function(v) {
          return parseFloat(v.toFixed(2), 10) + '&nbsp;kWh';  // controls formatting in the legend/mouseover
        },
        valueFormatter: function(v) {
          return parseFloat(v.toFixed(2), 10) + '&nbsp;kWh';  // controls formatting in the legend/mouseover
        }
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.chartData !== this.props.chartData || prevProps.stacked !== this.props.stacked){
     if(this.chartRef){
        const { data, labels } = this.props.chartData
        this.chartRef.updateOptions({
          file: data,
          labels,
          stackedGraph: this.props.stacked
        })
      }
    }
  }

  handleSetChartRef = chartRef => {
    if(chartRef){
      const { chartRef: chartRefFun, chartData, classes, className, stacked, ...opts } = this.props
      this.chartRef = chartRef && new Dygraph(
        chartRef,
        this.props.chartData.data,
        {
          ...opts,
          labels: this.props.chartData.labels,
          labelsDiv: this.legendRef
        }
      )
      this.props.chartRef && this.props.chartRef(this.chartRef)
    }
  }

  handleSetLegendRef = legendRef => {
    this.legendRef = legendRef
    if(this.chartRef){
      this.chartRef.updateOptions({
        labelsDiv: this.legendRef
      })
    }
  }

  render = () =>
    <Fragment>
      <div className={this.props.classes.legend} ref={this.handleSetLegendRef} />
      <div className={this.props.classes('charts', this.props.className)} ref={this.handleSetChartRef} />
    </Fragment>

}

const styles = theme => ({
  charts: {
    flex: 1,
    height: 0,
    backgroundColor: 'rgba(0, 19, 27, 0.6)',
    color: 'white',
    overflow: 'hidden',
    '@media(max-width: 600px)': {
      height: 600
    }
  },
  legend: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    background: 'rgba(0, 0, 0, 0.65)',
    borderRadius: 15,
    color: 'white',
    padding: 10,
    zIndex: 100
  }
})
export default withStyles(styles)(LineChart)