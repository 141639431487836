import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Organisations = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({organisationId}) =>
      organisationId ?
        `organisations/${organisationId}/organisations` :
        `/organisations`
    ),
    indexType:       endpoint => endpoint.path(({type}) => `organisations/${type}`),
    permissionNames: endpoint => endpoint.path(({organisationId}) => `organisations/${organisationId}/permission_names`),
    create:          endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/organisations`),
    update:          endpoint => endpoint.method('put').path(({id}) => `organisations/${id}`),
    destroy:         endpoint => endpoint.method('delete').path(({id}) => `organisations/${id}`),
    show:            endpoint => endpoint.path(({id}) => `organisations/${id}`),
    attach:          endpoint => endpoint.useFormData(true).method('put').path(({id}) => `organisations/${id}/attach`),
  }
)