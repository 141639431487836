import React, { Component, Fragment} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { compose } from 'utils'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

class UploadButton extends Component{

  static propTypes = {
    label: PropTypes.string,
    accept: PropTypes.string,
    onUpload: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.any,
  }

  static defaultProps = {
    label: 'File',
    accept: '.csv,.text'
  }

  get fileName() {
    return (this.props.value && this.props.value.name) || ''
  }

  handleFileChanged = ({target: { files: [file] }}) => {
    this.props.onUpload({target: {value: file}})
    this.fileInput.value = ''
  }

  handleButtonClicked = (event) => {
    this.fileInput.click()
  }

  render = () => {
    return (
      <Fragment>
        <input className={this.props.classes.fileInput} type="file" ref={fileInput => this.fileInput = ReactDOM.findDOMNode(fileInput)}
              accept={this.props.accept}
              onChange={this.handleFileChanged} />
        <Button color='secondary' className={this.props.className} variant='contained' disabled={ this.props.disabled } onClick={this.handleButtonClicked}>
          <CloudUploadIcon/>&emsp;
          Submit Meter Data
        </Button>
      </Fragment>
    )
  }
}

const styles = theme => ({
  fileInput: {
    display: 'none'
  },
})

export default compose(
  withStyles(styles)
)(UploadButton)