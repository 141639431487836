import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Tokens = ProtectedJsonAPI.extend(
  '/tokens',
  {
    create:                  endpoint => endpoint.method('post'),
    refresh:                 endpoint => endpoint.method('put').paramsHandler(() => {}).headers((headers, auth) => {return {...headers, ...(auth ? {Authorization: auth, "X-Authorization": auth} : {}) }}),
    destroy:                 endpoint => endpoint.method('delete'),
    forgot:                  endpoint => endpoint.method('post').path('forgot'),
    reset:                   endpoint => endpoint.method('post').path('reset'),
    unlock:                  endpoint => endpoint.method('post').path('unlock'),
    resendConfirmation:      endpoint => endpoint.method('post').path('resend_confirmation'),
    confirm:                 endpoint => endpoint.method('post').path('confirm'),
    acceptInvite:            endpoint => endpoint.method('post').path('accept_invite'),
    acknowledgeNotification: endpoint => endpoint.method('post').path('acknowledge_notification')
  }
)