import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'
import { compose, errorStringsFromError } from 'utils'
import { withRouter } from 'react-router-dom'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { FABAbsolute } from 'components'

export class List extends Component{

  constructor(props){
    super(props)
    UserActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    this.loadUsers()
  }

  state = {
    page: 1
  }

  loadUsers = () => {
    return this.actions.index({
      params: { organisationId: this.organisationId },
      page: this.state.page,
      pageSize: 10,
      fields: {organisation_roles: 'name'}
    })
  }

  componentDidUpdate = prevProps => {
    if (this.props.organisationId !== prevProps.organisationId) {
      this.loadUsers()
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showUser = id => () => {
    this.props.history.push(`/users/${id}`)
  }

  editUser = id => event => {
    this.props.history.push(`/users/${id}/edit`)
    event.stopPropagation()
  }

  deleteUser = id => event => {
    this.actions.destroy({id})
                .then(this.loadUsers)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadUsers)


  get users(){
    return this.props.users
  }

  get organisationId(){
    return this.props.organisationId || this.props.match.params.id
  }

  get organisationPermissions(){
    return this.props.organisationPermissions || {}
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderUserListItem = ({id, name, email, active}) =>
    <ListItem button onClick={this.showUser(id)} key={id}>
      <ListItemIcon>
        { (active === true) ? <PersonIcon/> : <NotInterestedIcon/> }
      </ListItemIcon>
      <ListItemText primary={name} secondary={email}/>
    </ListItem>

    renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <MuiList dense>
          {this.users.map(this.renderUserListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        {
          this.organisationPermissions.writeUsers &&
          <FABAbsolute color='primary' onClick={() => this.props.history.push(`/organisations/${this.organisationId}/users/new`)}>
            <AddIcon/>
          </FABAbsolute>
        }
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  withRouter,
  connect(({users}) => users)
)(List)