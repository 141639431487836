import React, { Component } from 'react'
import PropTypes            from 'prop-types'
import * as API             from 'api'
import withStyles           from 'styles'
import { compose, formatLocaleDateTime }          from 'utils'
import {
  Loader, EnhancedTable, EnhancedTableHead
}                           from 'components'
import TableBody            from '@material-ui/core/TableBody'
import TableCell            from '@material-ui/core/TableCell'
import TableRow             from '@material-ui/core/TableRow'

class OfferChangesTable extends Component{
  static propTypes = {
    offers: PropTypes.array.isRequired
  }

  constructor(props){
    super(props)
    this.state = {
      loading: false,
      versions: [],
      rowsPerPage: 10,
      page: 0
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    this.loadVersions()
  }

  componentWillUnmount = () => {
    this._isMounted = false
  }

  loadVersions = async () => {
    this.setState({loading: true})

    try {
      const result = await API.OfferChanges.index({
        options:{
          filter: {offer_ids: this.offers.map(({id}) => id)},
          fields: { users: 'name,email' },
          include: 'user',
        }
      })
      const {data: versions} = result
      this._isMounted && this.setState({ versions })
    } finally {
      this._isMounted && this.setState({ loading: false })
    }
  }

  handleChangePage = (page) => {
    this.setState({page})
  }

  handleChangeRowsPerPage = ({target: { value: rowsPerPage}}) => {
    this.setState({rowsPerPage})
  }

  get offers(){
    return this.props.offers
  }

  get versions(){
    return this.state.versions
  }

  get currentPageContent(){
    return this.versions.slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    )
  }

  renderLoader = () => <Loader size={100}/>

  deltaIndicator = (valueChange) => {
    if(valueChange && valueChange[0]) {
      let delta = valueChange[1] - valueChange[0]
      return (delta > 0) ? "↑" : "↓"
    } else {
      return ""
    }
  }

  renderVersionRow = (version) => {
    const { id, registrationName, changeset: { offerPrice, offerKw, status}, user, createdAt } = version

    const offerKwStr = offerKw ? `${offerKw[1]} kW` : "\u2014"
    const offerPriceStr = offerPrice ? `$${offerPrice[1]} / MWh` : "\u2014"

    const statusStr = status ? status[1] : "\u2014"
    const userStr = user ? user.name : "\u2014"

    return (
      <TableRow key={`${id}`}>
        <TableCell>{registrationName}</TableCell>
        <TableCell>{offerPriceStr} {this.deltaIndicator(offerPrice)}</TableCell>
        <TableCell>{offerKwStr} {this.deltaIndicator(offerKw)}</TableCell>
        <TableCell>{statusStr}</TableCell>
        <TableCell>{userStr}</TableCell>
        <TableCell>{formatLocaleDateTime(createdAt)}</TableCell>
      </TableRow>
    )
  }

  renderContent = () => {
    return (
      <EnhancedTable
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
        count={this.versions.length}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.page}
        displayToolbar={false}
      >
        <EnhancedTableHead
          title='Offer history'
          rowCount={this.versions.length}
        >
          <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='reg'>Reg</TableCell>
          <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='price'>Price</TableCell>
          <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='kw'>kW</TableCell>
          <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='status'>Status</TableCell>
          <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='whodunnit'>Update by</TableCell>
          <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='when'>When</TableCell>
        </EnhancedTableHead>
        <TableBody>
          {this.currentPageContent.map(this.renderVersionRow)}
        </TableBody>
      </EnhancedTable>
    )
  }

  render = () => {
    return (
      <div className={this.props.classes.root}>
        { !!this.state.loading ? this.renderLoader() : this.renderContent() }
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    minHeight: 100,
    minWidth: 300
  },
  tableHeaderCell: {
    float: 'left'
  },
  tableCellLink: {
    '&:hover':{
      textDecoration: 'underline'
    }
  },
})

export default compose(
  withStyles(styles)
)(OfferChangesTable)