import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { CalendarActions, SnackbarActions } from 'actionsets'
import { Pagination, DatePicker, FABFixed, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InputLabel from '@material-ui/core/InputLabel'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import ZoneSelector from './ZoneSelector'
import moment from 'moment'

export class List extends Component{


  constructor(props){
    super(props)
    CalendarActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1,
    filters: {
      from: moment().startOf('month').toDate(),
      to: moment().endOf('year').add(2, 'y').toDate(),
    }
  }

  dependsOn(){
    return this.loadCalendars()
  }

  loadCalendars = () => {
    return this.actions.index({
      page: this.state.page,
      ...(['from','to'].find(key => this.state.filters[key]) ?
        {filter: {
          ...(this.state.filters.from ? {from: this.state.filters.from} : {}),
          ...(this.state.filters.to ? {to: this.state.filters.to} : {})
        }} :
        {}
      ),
      fields: {calendars: 'name,date,imported'},
      include: 'scopes'
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  onDateChange = end => event =>
    this.setState(
      {filters: {...this.state.filters, [end]: event.target.value}},
      this.loadCalendars
    )

  showCalendar = id => () => {
    this.props.history.push(`/calendars/${id}`)
  }

  editCalendar = id => event => {
    this.props.history.push(`/calendars/${id}/edit`)
    event.stopPropagation()
  }

  deleteCalendar = id => event => {
    this.actions.destroy({id})
                .then(this.loadCalendars)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get calendars(){
    return this.props.calendars
  }

  handlePageSelected = page => {
    this.setState({page}, this.loadCalendars)
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderCalendarListItem = ({id, name, date, scopes, imported}) =>
    <TableRow onClick={this.showCalendar(id)} key={id}>
      <TableCell>{name}</TableCell>
      <TableCell>{date}</TableCell>
      <TableCell><ZoneSelector readOnly value={scopes}/></TableCell>
      <TableCell numeric>
      { this.props.permissions.system.writeCalendars &&
        <Fragment>
          <IconButton onClick={this.editCalendar(id)}><EditIcon/></IconButton>
          {!imported && <IconButton onClick={this.deleteCalendar(id)}><DeleteIcon/></IconButton>}
        </Fragment>
      }
      </TableCell>
    </TableRow>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <div>
          <InputLabel htmlFor='filterFrom'>Events from</InputLabel>
          <DatePicker value={this.state.filters.from} name='filterFrom' onChange={this.onDateChange('from')}/>
          <InputLabel htmlFor='filterTo'>... to</InputLabel>
          <DatePicker value={this.state.filters.to} name='filterTo' onChange={this.onDateChange('to')} />
        </div>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <Table>
          <TableBody>
            {this.calendars.map(this.renderCalendarListItem)}
          </TableBody>
        </Table>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <FABFixed color='primary' onClick={() => this.props.history.push('/calendars/new')}>
          <AddIcon/>
        </FABFixed>
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  connect(({calendars}) => calendars)
)(List)
