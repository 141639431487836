import React               from 'react'
import { SnackbarActions, TokenActions } from 'actionsets'
import { ErrorBanner }     from 'components'
import { errorStringsFromError, Authorization } from 'utils'

const InstanceFormMixin = superclass => class extends superclass{

  constructor(props){
    super(props)
    SnackbarActions.bindActions(this, 'snackbar')
    TokenActions.bindActions(this, 'tokens')
    this.handleFormObjectChange = this.handleFormObjectChange.bind(this)
    this.state = {
      formAttributes: {}
    }
  }

  dependsOn(){
    if(this.editMode){
      return this.actions.show(this.objectId)
    }else{
      return this.actions.set()
    }
  }

  dependenciesMet(){
    return (this.createMode || this.formObject.id)
  }

  get editMode(){
    return !this.createMode
  }

  get createMode(){
    return this.props.match.path.match(/\/new(?:\/:[^/]+)?$/)
  }

  get objectId(){
    return this.props.match.params.id
  }

  get attributesToSave(){
    const attributesToSave = {...this.formObject, relationships: {}}
    ;(this.relationshipAttributes || []).forEach(attribute => {
        const relationship = attributesToSave[attribute]
        delete attributesToSave[attribute]
        if(relationship !== undefined)
          attributesToSave.relationships[attribute] = {data: relationship}
      }
    )
    return attributesToSave
  }

  get saveEndpointOptions(){
    return {}
  }

  handleFormObjectChange(formAttributes, callback){
    this.setState({formAttributes}, typeof callback === 'function' ? callback : undefined)
  }

  reduceNameIdPairs = collection =>
    collection.reduce((agg, entity) => ({...agg, [entity.id]: entity.name}),{})

  errorFor = (name) => {
    try{
      return this.errorContext[name]
    }catch(err){
      return ''
    }
  }

  get error(){
    return this.props.errors[this.editMode ? 'update' : 'create']
  }

  get errorContext(){
    const {meta} = this.error || {}
    if(meta){
      return Object.entries(meta).reduce((acc, [name, value]) => {acc[name] = value.join(', '); return acc},{})
    }
    return null
  }

  renderErrorMessages = () => this.error ?
    <ErrorBanner>
      {errorStringsFromError(this.error)}
    </ErrorBanner> :
    false

  hasError = errorName =>
    Object.entries((this.error || {}).meta || {}).find(([attribute, value]) => attribute === errorName)

  save = async (saveAction, options = {afterSave: this.afterSave, onSaveRedirect: this.onSaveRedirect}) => {
    const { afterSave, onSaveRedirect } = options

    try{
      if(!(saveAction && saveAction instanceof Promise)){
        saveAction = this.editMode ?
          this.actions.update(this.attributesToSave, this.saveEndpointOptions) :
          this.actions.create(this.attributesToSave, this.saveEndpointOptions)
      }
      const result = await saveAction

      if(typeof afterSave === 'function') {
        afterSave(result)
      }

      let redirectTo = null
      if(onSaveRedirect) {
        redirectTo = (typeof onSaveRedirect === 'function') ? onSaveRedirect(result) : redirectTo = onSaveRedirect
      }
      console.log(`redirectTo: ${redirectTo}`)
      if(redirectTo) {
        this.props.history.push(redirectTo)
      } else {
        if(window.location.pathname !== Authorization.store.getState().tokens.savedLocation){
          this.props.history.goBack()
        }else{
          this.props.history.push('/')
          this.actions.tokens.clearSavedWindowLocation()
        }
      }

      this.actions.snackbar.show(`Saved ${this.attributesToSave.name ? `"${this.attributesToSave.name}"` : '' }`)

      return result
    }catch(err){
      this.actions.snackbar.show(`Error saving: ${this.attributesToSave.name ? `"${this.attributesToSave.name}"` : '' }`)
      console.log(err)
      return err
    }
  }
}

export default InstanceFormMixin