import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Bctis = ProtectedJsonAPI.extend(
  '/',
  {
    index:   endpoint => endpoint.path(({organisationId}) => `organisations/${organisationId}/bctis`),
    create:  endpoint => endpoint.path(({organisationId}) => `organisations/${organisationId}/bctis`).useFormData(true).method('post'),
    show:    endpoint => endpoint.path(({id}) => `bctis/${id}`)
  }
)
