import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { RegionActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { compose } from 'utils'
import withStyles from 'styles'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    RegionActions.bindActions(this)
  }

  get formObject(){
    return {programmeId: this.programmeId, ...this.props.region, ...this.state.formAttributes}
  }

  get programmeId(){
    return this.props.match.params.programmeId
  }


  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Region - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField fullWidth member='name'/>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = {
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    margin: '0 auto'
  }
}

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({regions}) => regions),
)(Form)