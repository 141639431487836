import { palette } from 'styles/theme'

const EventStatusColors = {
  draft: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_DRAFT               || "#999",
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_DRAFT               || "#333",
    width: 160
  },
  submitted: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_DRAFT               || "#999",
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_DRAFT               || "#333",
    width: 160
  },
  offer_window_open: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_OFFER_WINDOW_OPEN   || palette.primary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_OFFER_WINDOW_OPEN   || "white",
    width: 160
  },
  offer_window_closed: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_OFFER_WINDOW_CLOSED || palette.primary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_OFFER_WINDOW_CLOSED || "white",
    width: 160
  },
  scheduled: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_SCHEDULED           || palette.secondary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_SCHEDULED           || "white",
    width: 160
  },
  active: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_ACTIVE              || palette.secondary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_ACTIVE              || "white",
    width: 160
  },
  completed: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_COMPLETED           || palette.secondary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_COMPLETED           || "white",
    width: 160
  },
  cancelled: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_SCHEDULED           || palette.error.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_SCHEDULED           || "white",
    width: 160
  },
  meter_data_required: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_SCHEDULED           || palette.primary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_SCHEDULED           || "white",
    width: 160
  },
  meter_data_submitted: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_SCHEDULED           || palette.primary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_SCHEDULED           || "white",
    width: 160
  },
  payment_pending: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_SCHEDULED           || palette.primary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_SCHEDULED           || "white",
    width: 160
  },
  payment_complete: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_SCHEDULED           || palette.secondary.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_SCHEDULED           || "white",
    width: 160
  },
  declined: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_WITHDRAWND           || palette.error.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_WITHDRAWND           || "white",
    width: 160
  },
  withdrawn: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_WITHDRAWND           || palette.error.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_WITHDRAWND           || "white",
    width: 160
  },
  ineligible: {
    background: process.env.REACT_APP_EVENT_STATUS_COLOR_INELIGIBLE           || palette.error.main,
    color: process.env.REACT_APP_EVENT_STATUS_TEXT_COLOR_INELIGIBLE           || "white",
    width: 160
  },
}
export default EventStatusColors