import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BCTIActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { compose, errorStringsFromError } from 'utils'
import BCTIIcon from '@material-ui/icons/AttachMoney';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { FABAbsolute } from 'components'

export class List extends Component{

  constructor(props){
    super(props)
    BCTIActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    return this.loadBCTIs()
  }

  componentDidUpdate = prevProps => {
    if (this.props.organisationId !== prevProps.organisationId) {
      this.loadBCTIs()
    }
  }

  loadBCTIs = page => {
    return this.actions.index({
      params: { organisationId: this.props.organisationId },
      ...(page && {page})
    });
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  get bctis(){
    return this.props.bctis
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  showBCTI = id => () => {
    this.props.history.push(`/bctis/${id}`)
  }

  renderBCTIListItem = ({id, name, createdAt}) =>
    <ListItem button onClick={this.showBCTI(id)} key={id}>
      <ListItemIcon>
        <BCTIIcon/>
      </ListItemIcon>
      <ListItemText primary={name} secondary={moment(createdAt).format('MMM Do YY, h:mm:ss a')}/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadBCTIs} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.bctis.map(this.renderBCTIListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadBCTIs} style={{}} linkStyle={{}}/>
        <FABAbsolute color='primary' onClick={() => this.props.history.push(`/bctis/${this.props.organisationId}/new`)}>
          <AddIcon/>
        </FABAbsolute>
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  withRouter,
  connect(({bctis}) => bctis)
)(List)