export function shallowMemoize(fn, length=1) {
  let cache = []
  return (...args) => {
    const entry = cache.find(e => e.args.length === args.length &&
      !e.args.find((arg,i) => arg !== args[i])
    )
    if(entry) {
      return entry.value
    } else {
      const value = fn(...args)
      cache = [{args, value}, ...cache].slice(0,length)
      return value
    }
  }
}