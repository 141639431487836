import React, { Component, Children } from 'react'
import withStyles from 'styles'
export class ErrorBanner extends Component{

  static defaultProps = {
    errorType: 'error'
  }

  get hasChildren() {
    const {children} = this.props
    return Array.isArray(children) ? children.length > 0 : children || ''
  }

  render = () =>
    this.hasChildren && <div className={
      this.props.classes({
        banner: !!Children.count(this.props.children),
        [this.props.errorType]: true
      })}>
      {
        Children.map(this.props.children, (child, idx) => <p key={idx}>{child}</p>
        )
      }
    </div>
}

const styles = theme => ({
  banner: {
    padding: '10px 5px',
    display: 'block',
    margin: '10px 0',
    fontSize: '0.85rem',
  },
  error: {
    textAlign: 'left',
    color: theme.palette.error.dark,
    border: `1px solid rgba(255, 65, 51, 0.38)`,
    borderLeft: `3px solid ${theme.palette.error.main}`,
    background: theme.palette.error.background,
  },
  warning: {
    background: "#ffffec",
    borderLeft: "3px solid #ffd486",
    color: "#695e00",
  }
})

export default withStyles(styles)(ErrorBanner)