import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dependent from 'containers/shared/Dependent'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { CalendarActions, SnackbarActions } from 'actionsets'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import SaveIcon from '@material-ui/icons/Save'
import { FABFixed } from 'components'
import ZoneSelector from './ZoneSelector'

export class HolidayZones extends Component {

  constructor(props){
    super(props)
    this.state = {
      changedMappings: {},
      extraNames: {}
    }
    CalendarActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    return this.actions.getHolidayZones()
  }

  dependenciesMet() {
    return true
  }

  handleSave = async () => {
    try{
      await this.actions.saveHolidayZones({options: {
        meta: {mapping: Object.keys(this.state.changedMappings)
          .map(holiday => ({holiday, scope: this.state.changedMappings[holiday]}))
        }
      }})
      this.actions.snackbar.show('Holiday Regions Saved')
    }catch(err){
      this.actions.snackbar.show('Error saving holiday regions')
    }
  }

  handleNewNames = newNames => this.setState({
    extraNames: newNames.reduce((acc,n) => ({
      ...acc,
      [n.type]: [...(acc[n.type] || []), n]
    }), this.state.extraNames)
  })

  scopeFor = mapping => mapping.holiday in this.state.changedMappings ?
    this.state.changedMappings[mapping.holiday] :
    mapping.scope

  setChips = holiday => ({target: {value}}) => this.setState((oldState, props) => ({
    changedMappings: {...oldState.changedMappings,
      [holiday]: value
    }
  }))

  renderOneMapping = mapping => {
    const names = Object.keys({...(this.props.names || {}), ...this.state.extraNames}).reduce((acc,key) => ({...acc, [key]: [...(this.props.names[key] || []), ...(this.state.extraNames[key] || [])]}), {})
    return (
      <TableRow key={mapping.holiday}>
        <TableCell>{mapping.holiday}</TableCell>
        <TableCell>
          <ZoneSelector
            value={this.scopeFor(mapping)}
            names={names}
            onNewNames={this.handleNewNames}
            onChange={this.setChips(mapping.holiday)}
          />
        </TableCell>
      </TableRow>
    )
  }

  render = () =>
    <Card>
      <CardContent>
        <Table>
          <TableBody>
            {this.props.holidayMapping.map(this.renderOneMapping)}
          </TableBody>
        </Table>
        <FABFixed color='secondary' onClick={this.handleSave}>
          <SaveIcon/>
        </FABFixed>
      </CardContent>
    </Card>
}

export default connect(({calendars}) => calendars)(Dependent({loader: true, clearOnLoad: true})(HolidayZones))