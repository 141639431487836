import React, { Component, Fragment } from 'react'
import moment from 'moment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

export default class TestEventDialog extends Component {
  static formatDuration(seconds) {
    if(seconds === 0) { return '0' }
    const duration = moment.duration(seconds, 'seconds')
    let components = [
      ['hour',Math.floor(duration.asHours())],
      ['minute',duration.minutes()],
      ['second',duration.seconds()]
    ].filter(([unit,number]) => number !== 0)
     .map(([unit,number]) => `${number} ${unit}${number === 1 ? '' : 's'}`)
    if(components.length > 1) {
      components[components.length - 1] = `and ${components[components.length - 1]}`
    }
    return components.join(components.length > 2 ? ', ' : ' ')
  }

  render = () =>
    <Fragment>
      <Table className={this.props.classes.table}>
        <TableBody>
          { this.props.minLead !== 0 &&
            <TableRow>
              <TableCell className={this.props.classes.head}>Initial wait time</TableCell>
              <TableCell>{TestEventDialog.formatDuration(this.props.minLead)}</TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell className={this.props.classes.head}>Time per signal</TableCell>
            <TableCell>{TestEventDialog.formatDuration(this.props.minLength)}</TableCell>
          </TableRow>
          { this.props.signals.length > 1 && this.props.bufferTime &&
            <Fragment>
              <TableRow>
                <TableCell className={this.props.classes.head}>Buffer time after each signal</TableCell>
                <TableCell>{TestEventDialog.formatDuration(this.props.bufferTime)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={this.props.classes.head}>Total buffer time</TableCell>
                <TableCell>{TestEventDialog.formatDuration(this.props.bufferTime * (this.props.signals.length - 1))}</TableCell>
              </TableRow>
            </Fragment>
          }
          <TableRow>
            <TableCell className={this.props.classes.head}>All test events</TableCell>
            <TableCell>{TestEventDialog.formatDuration(this.props.minLength * this.props.signals.length + (this.props.bufferTime || 0) * (this.props.signals.length - 1))}</TableCell>
          </TableRow>
          { this.props.minLead !== 0 &&
            <TableRow>
              <TableCell className={this.props.classes.head}>Total</TableCell>
              <TableCell>{TestEventDialog.formatDuration(this.props.minLength * this.props.signals.length + this.props.minLead + (this.props.bufferTime || 0) * (this.props.signals.length - 1))}</TableCell>
            </TableRow>
          }
          <TableRow>
            <TableCell className={this.props.classes.head}>Signals</TableCell>
            <TableCell>{this.props.signals.map(({value}) => `DRM${value}`).join(', ')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
}
