import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GxpActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, TaggedSearchField, LabeledSwitch } from 'components'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GxpIcon from '@material-ui/icons/LocationCity'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { compose, errorStringsFromError, debounce } from 'utils'
import { withRouter } from 'react-router-dom'
import { connectQueryString } from 'containers/shared'

export class List extends Component{

  constructor(props){
    super(props)
    GxpActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  dependsOn(){
    return this.loadGxps()
  }

  loadGxps = debounce(() => {
    return this.actions.index({
      ...(this.regionId ? {params: { regionId: this.regionId }} : {}),
      page: this.props.page,
      pageSize: this.props.pageSize,
      filter: {...this.filter, ...(this.props.renderOrphaned ? {orphaned: "true"}: {})},
      fields: {gxps: 'name,code,active'}
    })
  })

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadGxps)

  showGxp = id => () => {
    this.props.history.push(`/gxps/${id}`)
  }

  handleFilterChanged = async rawFilter => {
    const filter = {...rawFilter, tags: {}};
    (rawFilter.tags || []).forEach(({value, tagType: { name }}) => {
      if(!filter.tags[name]) filter.tags[name] = []
      filter.tags[name].push(value)
    })
    await this.props.onFilterChange(filter)
    this.loadGxps()
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadGxps()
  }

  get defaultFilter(){
    return { active: true }
  }

  get filter(){
    return {...this.defaultFilter, ...this.props.filter }
  }

  get regionId(){
    return /regions/.test(this.props.match.path) ? this.props.match.params.id : undefined
  }

  get gxps(){
    return this.props.gxps
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderGxpListItem = ({id, code, name, active}) =>
    <ListItem button onClick={this.showGxp(id)} key={id}>
      <ListItemIcon>
        { active ?
          <GxpIcon /> :
          <NotInterestedIcon />
        }
      </ListItemIcon>
      <ListItemText primary={code} secondary={name}/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        {this.props.renderOrphaned && <Typography variant='h4'>Orphan GXPs</Typography>}
        <TaggedSearchField  defaultFilter={this.defaultFilter}  textFieldName='code' onChange={this.handleFilterChanged} context={this.props.filter}>
          <LabeledSwitch checked={this.props.filter.active !== 'false'} label='Hide Inactive' member='active'/>
        </TaggedSearchField>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        <MuiList dense>
          {this.gxps.map(this.renderGxpListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('gxp'),
  withRouter,
  connect(({gxps}) => gxps)
)(List)