import ActionSet from './ActionSet'
import * as API from 'api'
import requestServiceConstantsMiddleware from 'middleware/requestServiceConstantsMiddleware'

export class AppVersionActionSet extends ActionSet{

  static initialState = {
    tag: ''
  }

  static constantsMiddleware = [
    requestServiceConstantsMiddleware
  ]


  static getTag(creator, reducer, constants){
    constants.defineRequestConstants()
    creator(() => {
      return {
        type: constants.ACTION,
        promise: API.AppVersion.tag()
      }
    })

    reducer({
      [constants.SUCCESS]: (state, { request, result: { data: {tag} } }) => {
        return { ...state, tag }
      }
    })
  }

}

export default new AppVersionActionSet()