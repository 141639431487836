import React, { Component } from 'react'
import { TimePicker as TPicker } from '@material-ui/pickers'
import moment from 'moment'

export class TimePicker extends Component{

  constructor(props) {
    super(props)
    this.state = { key: 0 }
  }

  handleChange = date => {
    this.props.onChange({
      target: {
        value: date ? this.round(date, this.props.minutesStep).format('YYYY-MM-DDTHH:mm:ssZ') : date
      }
    })
    this.setState({key: (this.state.key + 1)})
  }

  round = (date, nearest) => {
    const duration = moment.duration(nearest, 'minute')
    return moment(Math.floor((+date) / (+duration)) * (+duration))
  }

  get value(){
    return this.props.value ? new Date(this.props.value) : null
  }

  openOnFocus = () =>{
    if(this._picker && !this.value){
      this._picker.open()
      this._picker = null
    }
  }

  render = () => {
    const { minutesStep, ...props } = this.props

    return (
        <TPicker
          key={this.state.key}
          format="HH:mm"
          {...props}
          ref={_picker => this._picker = _picker}
          onChange={this.handleChange}
          value={this.value}
          onFocus={this.openOnFocus}
          keyboard
          mask={[/\d/, /\d/, ":", /\d/, /\d/]}
          disableOpenOnEnter
          KeyboardButtonProps = { {tabIndex: -1} }
        />
    )
  }

}

export default TimePicker