import React, { Component } from 'react'
import { connect }          from 'react-redux'
import { OrganisationActions, UserActions, OrganisationRoleActions, TagValueActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose } from 'utils'
import withStyles from 'styles'
import { MultiAutoSuggest, AutoSuggest, LabeledSelect, UploadPictureField } from 'components'
import { Authorization, unique } from 'utils'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    this.relationshipAttributes = ['primaryContact', 'secondaryContact']
    OrganisationActions.bindActions(this)
    UserActions.bindActions(this, 'users')
    OrganisationRoleActions.bindActions(this, 'organisationRoles')
    TagValueActions.bindActions(this, 'tagValues')
  }

  dependsOn(){
    const requests = [
      this.actions.users.index({
        params: {organisationId: this.objectId},
        fields: {users: 'name'},
        page: 0,
        pageSize: 100
      })
    ]
    requests.push(this.editMode ?
      this.actions.show(this.objectId, {include: 'roles,parent,primaryContact,secondaryContact'}) :
      this.actions.set({})
    )
    return Promise.all(requests)
  }

  get formObject(){
    return {organisationId: this.organisationId, tags: {}, status: 'active', ...this.props.organisation, ...this.state.formAttributes}
  }

  get organisationId(){
    return this.props.match.params.organisationId || this.props.organisation.id || Authorization.organisationId
  }

  get parentId(){
    return this.props.match.params.organisationId || (this.props.organisation.parentId)
  }

  get organisationRoles(){
    return this.state.roles || this.props.organisation.roles || []
  }

  get isActive(){
    return this.formObject.status === 'active'
  }

  get organisationTypes() {
    return (this.formObject.tags || {})["Organisation Type"] || []
  }

  get isDrParticipant() {
    return this.organisationTypes.includes("Demand Participant")
  }

  get attributesToSave(){
    const {logo, logoUrlPath, icon, iconUrlPath, ...attributes} = super.attributesToSave
    return attributes
  }

  fullSave = async (saveAction, options) => {
    if(!(saveAction && saveAction instanceof Promise)) {
      saveAction = this.actions[this.editMode ? 'update' : 'create'](this.attributesToSave, this.saveEndpointOptions)
    }
    saveAction = saveAction.then(async result => {
      const {data: {id} = {}} = result

      if(id && (this.formObject.logo || this.formObject.icon)) {
        result = await this.actions.attach({
          id,
          logo: this.formObject.logo,
          icon: this.formObject.icon,
        })
      }

      return result
    })
    await this.save(saveAction, options)
  }

  get errorContext() {
    return {...super.errorContext, ...Object.entries((this.props.errors.attach || {}).meta || {}).reduce((acc, [name,value]) => {acc[name] = value.join(', '); return acc},{})}
  }

  onSaveRedirect = ({data: {id}}) => `/organisations/${id}`

  handleRoleSuggestionsFetchRequested = async (text, callback) => {
    const { data: suggestions } = await this.actions.organisationRoles.index({
      params: { organisationId: this.parentId },
      filter: {name: text}
    })
    callback(suggestions.filter(s => !this.organisationRoles.some(role => role.name === s.name)))
  }

  handleOrganisationTypeFetchRequested = async (text, callback) => {
    const { data: results } = await this.actions.tagValues.index({
      filter: { tag_type: 'Organisation Type', value: text }
    })
    const values = results.map(({value}) => value)
    const suggestions = values.filter(s => !(this.formObject.tags['Organisation Type'] || []).some(value => value === s))
    callback(suggestions)
  }

  handleRolesChange = ({target: { value: roles}}) =>{
    this.setState({roles, formAttributes: {
      ...this.state.formAttributes,
      roleIds: roles.map(({id}) => id)
    }})
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Organisation - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.fullSave}>
        {this.renderErrorMessages()}
        <CardContent className={this.props.classes.cardContent}>
          <div className={this.props.classes.formFields}>
            <TextField fullWidth required member='name'/>
            <UploadPictureField fullWidth member='logo' initialUrl={this.formObject.logoUrlPath} label="Logo (aspect ratio: 5:1)" />
            <UploadPictureField fullWidth member='icon' initialUrl={this.formObject.iconUrlPath} label="Icon (aspect ratio: 1:1)" />
            <TextField fullWidth required={this.isActive} member='address'/>
            <TextField fullWidth required={this.isActive} member='phoneNumber'/>
            <TextField fullWidth member='gstNumber'/>
            <TextField fullWidth member='bankAccountNumber'/>
            <TextField fullWidth member='bankAndBranchName'/>
            <TextField fullWidth member='contractId'/>
            <TextField fullWidth member='vendorId'/>
            <MultiAutoSuggest
              label='Participant Codes'
              fullWidth
              onSuggestionsFetchRequested={(text,callback) => callback(text ? [text] : [])}
              labelProvider={code => code}
              member="participantCodes"
            />
            <AutoSuggest
              label='EIEP Participant Code'
              fullWidth
              onSuggestionsFetchRequested={
                (text,callback) => {
                  const participantCodes = this.formObject.participantCodes || []
                  const currentText = (text ? [text] : [])
                  const suggestions = unique(currentText.concat(participantCodes))
                  const regExp = new RegExp(text)

                  callback(suggestions.filter((aSugestion) => aSugestion.match(regExp)))
                }
              }
              labelProvider={code => code}
              member="eiepParticipantCode"
            />
            <LabeledSelect
              required fullWidth
              member='primaryContact.id'
              errorMember='primaryContact'
              options={this.reduceNameIdPairs(this.props.users)}
              label="Primary Contact"
            />
            <LabeledSelect
              required fullWidth
              member='secondaryContact.id'
              errorMember='secondaryContact'
              options={this.reduceNameIdPairs(this.props.users)}
              label="Secondary Contact"
            />
            <LabeledSelect
              required fullWidth member='status'
              options={{active: 'Active', inactive: 'Inactive'}}
            />
          </div>
          <div className={this.props.classes.tags}>
            <MultiAutoSuggest
              label='Roles'
              fullWidth
              readOnly={this.organisationId === this.props.currentUser.organisation.id && this.editMode}
              onSuggestionsFetchRequested={this.handleRoleSuggestionsFetchRequested}
              value={this.organisationRoles}
              onChange={this.handleRolesChange}
            />
            <MultiAutoSuggest
              label='Organisation Type'
              fullWidth
              labelProvider={label => label}
              onSuggestionsFetchRequested={this.handleOrganisationTypeFetchRequested}
              member={`tags.Organisation Type`}
            />
          </div>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    padding: 20,
    margin: '0 auto',
    '& > form': {
      width: '100%'
    }
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: '1 1 45%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    minWidth: 300,
    marginBottom:10,
    '& > div': {
      flex: '1 1 auto'
    }
  },
  tags: {
    flex: '1 1 45%',
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({organisations, users: { users }, tokens: { currentUser }}) => ({...organisations, users, currentUser})),
)(Form)
