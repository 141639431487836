import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Programmes = ProtectedJsonAPI.extend(
    '',
  {
    index:           endpoint => endpoint.path(({organisationId}) =>  organisationId ? `organisations/${organisationId}/programmes` : '/programmes'),
    create:          endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/programmes`),
    update:          endpoint => endpoint.method('put').path(({id}) => `programmes/${id}`),
    destroy:         endpoint => endpoint.method('delete').path(({id}) => `programmes/${id}`),
    show:            endpoint => endpoint.path(({id}) => `programmes/${id}`)
  }
)