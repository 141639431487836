import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { TagTypeActions, SnackbarActions } from 'actionsets'
import { ErrorBanner } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError, uuid } from 'utils'
import TagIcon from '@material-ui/icons/LocalOffer';
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import withStyles from 'styles'
import { FormContext }      from 'components'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import { MultiAutoSuggest } from 'components'
import { withPermissions } from 'containers/shared'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

export class List extends Component{
  constructor(props){
    super(props)
    TagTypeActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1,
    removalWarnings: {}
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.tagTypes.length && !prevState.tagTypes){
      return {...prevState, tagTypes: nextProps.tagTypes }
    }
    return null
  }

  dependsOn(){
    return this.loadTagTypes()
  }

  loadTagTypes = page => {
    return this.actions.index({
      page: this.state.page,
      filter: { includeRestricted: true },
      order: 'name',
      pageSize: 1000,
      fields: {tags: 'name'},
      include: 'tagValues'
    })
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  deleteTagType = id => event => {
    this.actions.destroy({id})
                .then(this.loadTagTypes)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  handlePageSelected = page =>
    this.setState({page}, this.loadTagTypes)

  get tagTypes(){
    const types = this.state.tagTypes || this.props.tagTypes || []
    const type = this.props.match.params.tab
    return types.filter(({reserved}) =>
      (type === 'user' && !reserved) || (type === 'system' && reserved)
    )
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    if(this.props.errors.bulkSave){
      errors = errors.concat(this.props.errors.bulkSave)
    }
    return errors
  }

  handleTabChange = (_, tab) => {
    this.props.history.push(`/tags/${tab}`)
  }

  handleSave = async () => {
    try{
      if(!this.hasRemovalWarnings || global.confirm(this.removalWarningMessage)){
        const { data: tagTypes } = await this.actions.bulkSave(this.tagTypes)
        this.setState({
          removalWarnings: {},
          tagTypes: this.state.tagTypes.filter(tt => tt.reserved).concat(tagTypes)
        })
        this.actions.snackbar.show(`Saved Tags`)
      }
    }catch({body: error}){
      this.actions.snackbar.show(
        <p>
          {errorStringsFromError(error).map((err, idx) => <Fragment key={idx}>{err}<br/></Fragment>)}
        </p>
      )
    }
  }

  handleSuggestionsFetchRequested = existingValues => (text, callback) => {
    if(!text.trim() || existingValues.some(({value}) => value.trim().toLowerCase() === text.trim().toLowerCase())){
      return callback([])
    }
    return callback([{value: text, type: 'tagValues'}])
  }

  handleDeleteTagType = name => () => {
    const idx     = this.state.tagTypes.findIndex(({name: needle}) => needle === name)
    const tagType = this.state.tagTypes[idx]
    const useCount = tagType.tagValues.reduce((total, {useCount}) => total+ useCount, 0)
    this.setState({
      removalWarnings: {
        ...this.state.removalWarnings,
        [tagType.name]: useCount > 0 ? [`This Tag Type is in use in ${useCount} places`] : []
      },
      tagTypes: [
        ...this.state.tagTypes.slice(0, idx),
        ...((tagType.id ? [{...tagType, _destroy: true}] : [])),
        ...this.state.tagTypes.slice(idx+1)
      ]
    })
  }

  handleTagValuesChanged = name => ({target: { value: updatedValues }}) => {
    const idx       = this.state.tagTypes.findIndex(({name: needle}) => needle === name)
    const tagType   = this.state.tagTypes[idx]
    const oldValues = tagType.tagValues
    const toRemove  = oldValues.filter(tagValue => tagValue.id && !updatedValues.find(tv => tv.value === tagValue.value))
    const removalWarnings = toRemove.filter(tv => !!tv.useCount).map(tv => `${tv.value} is in use in ${tv.useCount} places`)

    this.setState({
      removalWarnings: {
        ...this.state.removalWarnings,
        [tagType.name]: removalWarnings
      },
      tagTypes: [
        ...this.state.tagTypes.slice(0, idx),
        {...this.state.tagTypes[idx], tagValuesAttributes: toRemove.map(tr => ({...tr, _destroy: true})).concat(updatedValues)},
        ...this.state.tagTypes.slice(idx+1)
      ]
    })
  }

  handleAddTagType = () => {
    const tagTypes = [...(this.state.tagTypes || []), {name: '', tagValues: [], uuid: uuid()}]
    this.setState({tagTypes})
  }

  handleTagTypesChanged = ({tagTypes: changedTypes}) => {
    this.setState({tagTypes: this.state.tagTypes.map(tt =>
      changedTypes.find(changed => `${changed.id}` === `${tt.id}` && `${changed.uuid}` === `${tt.uuid}`) || tt
    )})
  }

  get hasRemovalWarnings(){
    return Object.values(this.state.removalWarnings).some(value => value && value.length)
  }

  get removalWarningMessage(){
    const message = Object.entries(this.state.removalWarnings).reduce((message, [type, values]) => {
      if(values && values.length){
        return `${message}\n\t${values.map(v => `${type}: ${v}`).join("\n\t")}`
      }
      return message
    }, '')
    return `Warning, you are removing tags that are currently in use. ${message}\nAre you sure you want to continue?`
  }

  get canEditTags(){
    return this.props.match.params.tab === 'user' && this.props.permissions.system.writeTags
  }

  get errorContext(){
    if(!this.props.errors.bulkSave || !this.props.errors.bulkSave.body)
      return {}
    return {
      tagTypes: this.props.errors.bulkSave.body.map(error => error ? error.meta : error)
    }
  }

  renderTagValue =
    ({value}, type, onSelect) =>  type === 'suggestion' ? <Chip label={value} onDelete={onSelect} deleteIcon={<AddIcon />}/>  : value

  renderTagListItem = ({id, name, tagValues, tagValuesAttributes, _destroy}, idx) =>  {
    if(_destroy)
      return false
    return (
      <ListItem key={idx}>
        <ListItemIcon>
          <TagIcon className={this.props.classes.tagIcon} />
        </ListItemIcon>
        <ListItemText disableTypography primary={
          <Fragment>
            <FormContext errorContext={this.errorContext} context={{tagTypes: this.tagTypes}} onChange={this.handleTagTypesChanged}>
              <TextField disabled={!this.canEditTags} fullWidth type='text' label='Tag Type' member={`tagTypes[${idx}].name`}/>
            </FormContext>
            <MultiAutoSuggest
              classNames={{
                container: this.props.classes.autoSuggest,
                suggestionsContainer: this.props.classes.suggestionsContainer,
                suggestedItem: this.props.classes.suggestedItem
              }}
              label='Tags'
              debounceWait={1}
              readOnly={!this.canEditTags}
              deleteOnClick
              selectOnEnter
              showClearAll={false}
              showLoader={false}
              labelProvider={this.renderTagValue}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested(tagValuesAttributes || tagValues)}
              onChange={this.handleTagValuesChanged(name)}
              value={tagValuesAttributes ? tagValuesAttributes.filter(tv => !tv._destroy) : tagValues}
            />
          </Fragment>
        }/>
        <Divider/>
        {
          this.canEditTags &&
          <ListItemIcon>
            <IconButton onClick={this.handleDeleteTagType(name)}><DeleteIcon/></IconButton>
          </ListItemIcon>
        }
      </ListItem>
    )
  }

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        {this.renderErrorMessages()}
        <Typography variant='h5'>
          Tag Types:
        </Typography>
        <Tabs onChange={this.handleTabChange} value={this.props.match.params.tab}>
          <Tab value="user" label='User Defined'/>
          <Tab value="system" label='System'/>
        </Tabs>
        {
          this.canEditTags &&
          <Fab color='secondary' className={this.props.classes.saveButton} onClick={this.handleSave}>
            <SaveIcon/>
          </Fab>
        }
        <br/>
        <MuiList className={this.props.classes.tagList} dense>
          <FormContext context={this.state} errorContext={this.errorContext} onChange={this.handleTagTypesChanged}>
            <Fragment>
              {this.tagTypes.map(this.renderTagListItem)}
            </Fragment>
          </FormContext>
          <Fab onClick={this.handleAddTagType} className={this.props.classes.addButton} color='primary'>
            <AddIcon/>
          </Fab>
        </MuiList>
      </CardContent>
      {
        this.tagTypes.filter(tt => !tt._destroy).length > 2 &&
        <CardActions className={this.props.classes.cardActions}>
          <Fab color='secondary' className={this.props.classes.saveButton} onClick={this.handleSave}>
            <SaveIcon/>
          </Fab>
        </CardActions>
      }
    </Card>
}


const styles = theme => ({
  tagIcon: {
    color: theme.palette.primary.light
  },
  autoSuggest: {
    "& ul":{
      marginTop: 10
    },
  },
  suggestedItem: {
    background: theme.palette.primary.background,
    '&:hover': {
      background: theme.palette.primary.background,
    },
    marginTop: 10,
    padding: '10px 0',
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    margin: '0 auto',
    '& $saveButton': {
      marginTop: -50
    }
  },
  tagList: {
    marginTop: 5
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  saveButton: {
    alignSelf: 'flex-end',
    marginBottom: -30,
    zIndex: 1
  },
  addButton: {
    float: 'right',
    right: 60,
    bottom: 30
  },
  tagValuesBox: {
    display: 'block',
    marginTop: 10
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  withPermissions(() => ({system: ['readTags','writeTags']}), {
    unmountOnChange: true,
    checkPermissions: (permissions) => {
      return permissions.system.readTags && permissions.system.writeTags
    }
  }),
  connect(({tagTypes}) => tagTypes)
)(List)