import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'
import { history } from 'utils'

export class ParentEventActionSet extends CrudActionSetBase('parentEvent'){
  static clearErrors(creator, reducer, constants){
    creator(() => ({
      type: constants.ACTION,
      payload: 'CLEAR'
    }))

    reducer({
      [constants.ACTION]: state => ({
        ...state,
        errors: this.initialState.errors
      })
    })
  }

  static refresh(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((id,{fields=null, include=null }={}) => {
      return {
        type: constants.ACTION,
        promise: API.ParentEvents.show({id, options: { ...fields && {fields}, ...include && {include} }})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, refresh: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, parentEvent: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, refresh: error }
        return {...state, requests, errors }
      }
    })
  }

  static event(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({id}, event) => {
      return {
        type: constants.ACTION,
        promise: API.ParentEvents.event({id, name: event})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, event: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, event: error }
        return {...state, requests, errors }
      }
    })
  }

  static loadDefaults(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(() => {
      return {
        type: constants.ACTION,
        promise: API.ParentEvents.defaults()
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, defaults: {}, requests: [...state.requests, request ], errors: {...state.errors, loadDefaults: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, defaults: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, loadDefaults: error }
        return {...state, requests, errors }
      }
    })
  }

  static showEdit(creator, reducer, constants){
    creator((parentEventId) => {
      const refferer = window.location.pathname
      history.push(`/parent_events/${parentEventId}/edit`)
      return {
        type: constants.ACTION,
        payload: refferer
      }
    })

    reducer({
      [constants.ACTION]: (state, refferer) => ({
        ...state, refferer
      })
    })
  }

  static showNew(creator, reducer, constants){
    creator((programmeId) => {
      const refferer = window.location.pathname
      history.push(`/programmes/${programmeId}/parent_events/new`)
      return {
        type: constants.ACTION,
        payload: refferer
      }
    })

    reducer({
      [constants.ACTION]: (state, refferer) => ({
        ...state, refferer
      })
    })
  }
}
export default new ParentEventActionSet()