import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TokenActions, SnackbarActions } from 'actionsets'
import { FormContext, CenteredCard, ErrorBanner } from 'components'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'

export class ResendConfirmation extends Component{

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  submit = async () => {
    if(this.state.submitting)
      return
    this.setState({submitting: true})
    try{
      await this.actions.resendConfirmation(this.state)
      this.actions.snackbar.show("Request Sent")
      this.props.history.push('/confirmation_resent')
    }finally{
      this.setState({submitting: false})
    }
  }

  renderError = () =>
    <ErrorBanner>
      {this.props.error.title}
    </ErrorBanner>

  render = () =>
    <CenteredCard>
      <FormContext context={this.state} onChange={state => this.setState(state)} onSubmit={this.submit}>
        <CardContent>
          <Typography variant='h6'>Didn't receive your confirmation email?</Typography>
          <Typography variant='body1'>Enter your email address to resend the confirmation email</Typography>
          {this.props.error && <p>{this.renderError()}</p>}
          <TextField member='email' fullWidth/>
        </CardContent>
        <CardActions>
          <Button fullWidth variant='contained' color="primary" onClick={this.submit}>Submit</Button>
        </CardActions>
      </FormContext>
    </CenteredCard>
}

export default connect(({tokens}) => tokens)(ResendConfirmation)