import Dygraph from 'dygraphs'


export const legendFormatter = (unit, precision=2) => function(data){
  if (data.x == null) {
      // This happens when there's no selection and {legend: 'always'} is set.
      return '<br>' + data.series.map(function(series) { return series.dashHTML + ' ' + series.labelHTML }).join('<br>');
    }
  var html = this.getLabels()[0] + ': ' + data.xHTML;
  data.series.forEach(function(series) {
      if (!series.isVisible) return;
      var labeledData = `<span style="color: ${series.color}; font-weight: bold;">${series.labelHTML}</span>: ${parseFloat((series.y || 0).toFixed(precision))} ${unit}`
      html += '<br>' + series.dashHTML + ' ' + labeledData;
    });
  return html;
}

// A plotter which uses splines to create a smooth curve.
// See tests/plotters.html for a demo.
// Can be controlled via smoothPlotter.smoothing
export function smoothPlotter(e) {
  var ctx = e.drawingContext,
      points = e.points;

  ctx.beginPath();
  ctx.moveTo(points[0].canvasx, points[0].canvasy);

  // right control point for previous point
  var lastRightX = points[0].canvasx, lastRightY = points[0].canvasy;

  for (var i = 1; i < points.length; i++) {
    var p0 = points[i - 1],
        p1 = points[i],
        p2 = points[i + 1];
    p0 = p0 && isOK(p0.canvasy) ? p0 : null;
    p1 = p1 && isOK(p1.canvasy) ? p1 : null;
    p2 = p2 && isOK(p2.canvasy) ? p2 : null;
    if (p0 && p1) {
      var controls = getControlPoints({x: p0.canvasx, y: p0.canvasy},
                                      {x: p1.canvasx, y: p1.canvasy},
                                      p2 && {x: p2.canvasx, y: p2.canvasy},
                                      1/3);
      // Uncomment to show the control points:
      // ctx.lineTo(lastRightX, lastRightY);
      // ctx.lineTo(controls[0], controls[1]);
      // ctx.lineTo(p1.canvasx, p1.canvasy);
      lastRightX = (lastRightX !== null) ? lastRightX : p0.canvasx;
      lastRightY = (lastRightY !== null) ? lastRightY : p0.canvasy;
      ctx.bezierCurveTo(lastRightX, lastRightY,
                        controls[0], controls[1],
                        p1.canvasx, p1.canvasy);
      lastRightX = controls[2];
      lastRightY = controls[3];
    } else if (p1) {
      // We're starting again after a missing point.
      ctx.moveTo(p1.canvasx, p1.canvasy);
      lastRightX = p1.canvasx;
      lastRightY = p1.canvasy;
    } else {
      lastRightX = lastRightY = null;
    }
  }

  ctx.stroke();
}



export function darkenColor(colorStr) {
  // Defined in dygraph-utils.js
  var color = Dygraph.toRGB_(colorStr);
  return color && 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
}

// This function draws bars for a single series. See
// multiColumnBarPlotter below for a plotter which can draw multi-series
// bar charts.
export function barChartPlotter(e) {
  var ctx = e.drawingContext;
  var points = e.points;
  var y_bottom = e.dygraph.toDomYCoord(0);

  ctx.fillStyle = e.color;
  ctx.strokeStyle = darkenColor(e.color);

  const seriesOptions = e.dygraph.attributes_.series_[e.setName].options
  const fillGraph = seriesOptions.fillGraph !== undefined ? seriesOptions.fillGraph : e.dygraph.attributes_.user_.fillGraph
  // Find the minimum separation between x-values.
  // This determines the bar width.
  var min_sep = Infinity;
  var i;
  for (i = 1; i < points.length; i++) {
    var sep = points[i].canvasx - points[i - 1].canvasx;
    if (sep < min_sep) min_sep = sep;
  }
  var bar_width = Math.floor(2.0 / 3 * min_sep);

  // Do the actual plotting.
  for (i = 0; i < points.length; i++) {
    var p = points[i];
    var center_x = p.canvasx;

    ctx.fillRect(center_x - bar_width / 2, p.canvasy,
        bar_width, y_bottom - p.canvasy);

    ctx.strokeWidth = fillGraph ? 1 : 2
    ctx.strokeRect(center_x - bar_width / 2, p.canvasy,
        bar_width, y_bottom - p.canvasy);
  }
}

function getControlPoints(p0, p1, p2, opt_alpha, opt_allowFalseExtrema) {
  var alpha = (opt_alpha !== undefined) ? opt_alpha : 1/3;  // 0=no smoothing, 1=crazy smoothing
  var allowFalseExtrema = opt_allowFalseExtrema || false;

  if (!p2) {
    return [p1.x, p1.y, null, null];
  }

  // Step 1: Position the control points along each line segment.
  var l1x = (1 - alpha) * p1.x + alpha * p0.x,
      l1y = (1 - alpha) * p1.y + alpha * p0.y,
      r1x = (1 - alpha) * p1.x + alpha * p2.x,
      r1y = (1 - alpha) * p1.y + alpha * p2.y;

  // Step 2: shift the points up so that p1 is on the l1â€“r1 line.
  if (l1x !== r1x) {
    // This can be derived w/ some basic algebra.
    var deltaY = p1.y - r1y - (p1.x - r1x) * (l1y - r1y) / (l1x - r1x);
    l1y += deltaY;
    r1y += deltaY;
  }

  // Step 3: correct to avoid false extrema.
  if (!allowFalseExtrema) {
    if (l1y > p0.y && l1y > p1.y) {
      l1y = Math.max(p0.y, p1.y);
      r1y = 2 * p1.y - l1y;
    } else if (l1y < p0.y && l1y < p1.y) {
      l1y = Math.min(p0.y, p1.y);
      r1y = 2 * p1.y - l1y;
    }

    if (r1y > p1.y && r1y > p2.y) {
      r1y = Math.max(p1.y, p2.y);
      l1y = 2 * p1.y - r1y;
    } else if (r1y < p1.y && r1y < p2.y) {
      r1y = Math.min(p1.y, p2.y);
      l1y = 2 * p1.y - r1y;
    }
  }

  return [l1x, l1y, r1x, r1y];
}

// i.e. is none of (null, undefined, NaN)
function isOK(x) {
  return !!x && !isNaN(x);
}
