import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as API from 'api'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { compose } from 'utils'
import { withRouter } from 'react-router-dom'
import { SnackbarActions } from 'actionsets'
import withStyles from 'styles'
import TabIcon from '@material-ui/icons/Tab'
import { DateTimePicker, LabeledSelect, AutoSuggest } from 'components'
import { connectQueryString } from 'containers/shared'
import { IconButton, TextField } from '@material-ui/core'
import Results from './Results'
import moment from 'moment'

export class List extends Component{

  constructor(props){
    super(props)
    SnackbarActions.bindActions(this, 'snackbar')

    this.state = {
      filtersCollapsed: false,
      currentUserFilter: null,
    }
  }

  componentDidMount = () => {
    const { user } = this.props.filter || {}
    if(!!user) {
      this.loadFilterUser(user)
    }
  }

  loadFilterUser = async (user) => {
    const params = {
      id: user,
      options: {
        fields: { users: 'name' }
      },
    }
    try {
      const { data: user } = await API.Users.show(params)
      this.setState({currentUserFilter: user})
    } catch (err) {
      console.log(err)
    }
  }

  static get defaultFilter(){
    return {
      from: moment().subtract(1, 'M').startOf('day').format(),
      to: moment().endOf('day').format()
    }
  }

  get filter(){
    return Object.values(this.props.filter).some(v => v) ? this.props.filter : List.defaultFilter
  }

  get resourceTypes(){
    return {
      '':                     'All',
      Offer:                  'Offer',
      Organisation:           'Organisation',
      SystemPermission:       'SystemPermission',
      OrganisationPermission: 'OrganisationPermission',
      RegistrationPermission: 'RegistrationPermission',
      Registration:           'Registration',
      Site:                   'Site',
    }
  }

  handleCollapseFilters = () => {
    const { filtersCollapsed } = this.state
    this.setState({ filtersCollapsed: !filtersCollapsed})
  }

  handleDateFilterChange = which => ({target: { value }}) => {
    this.props.onFilterChange({...this.filter, [which]: value})
  }

  handleResourceTypeFilterChanged = ({target: { value }}) => {
    this.props.onFilterChange({...this.filter, resourceType: value})
  }

  handleTextFilterChange = ({target: { value }}) => {
    this.props.onFilterChange({ ...this.filter, text: value})
  }

  handleUSerFilterChanged = ({target: { value }}) => {
    const { id } = value || {}
    this.setState({currentUserFilter: value}, () => {
      this.props.onFilterChange({ ...this.filter, user: id})
    })
  }

  handleUserSuggestionsFetchRequested = async (text, callback) => {
    const params = {
      options: {
        filter: { name: text },
        fields: { users: 'name' },
        page: { number: 0, size: 10 },
      },
    }

    const { data: users } = await API.Users.index(params)
    const usersById = users.reduce((acc, user) => {
      acc[user.id] = user
      return acc
    }, {})

    this.setState({userSuggestions: usersById}, () => callback(users))
  }

  renderFilters = () => {
    return (
      <div className={this.props.classes.filterSection}>
        <IconButton className={this.props.classes.collapseButton} onClick={this.handleCollapseFilters}>
          <TabIcon/>
        </IconButton>
        {
          (!this.state.filtersCollapsed) &&
          <div className={this.props.classes.filters}>
            <div className={this.props.classes.filterGroup}>
              <DateTimePicker
                className={this.props.classes('filterField', 'formControl', 'dateFilter')}
                minutesStep={30}
                value={this.filter.from}
                name='filterFrom'
                onChange={this.handleDateFilterChange('from')}
                placeholder='From Date'
                label='From'
              />
              <DateTimePicker
                className={this.props.classes('filterField', 'formControl', 'dateFilter')}
                minutesStep={30}
                value={this.filter.to}
                name='filterTo'
                onChange={this.handleDateFilterChange('to')}
                placeholder='To Date'
                label='To'
              />
            </div>
            <div className={this.props.classes('filterGroup', 'filterField')}>
              <LabeledSelect
                style={{width: 300}}
                onChange={this.handleResourceTypeFilterChanged}
                options={this.resourceTypes}
                value={this.props.filter.resourceType}
                label="Resource type"
              />
            </div>
            <div className={this.props.classes('filterField')}>
              <TextField
                label="Free text search"
                value={this.props.filter.text || ''}
                onChange={this.handleTextFilterChange}
              />
            </div>
            <AutoSuggest className={this.props.classes.filterField}
              fullWidth={false}
              label='User'
              labelProvider={({name}) => name}
              value={this.state.currentUserFilter}
              onSuggestionsFetchRequested={this.handleUserSuggestionsFetchRequested}
              onChange={this.handleUSerFilterChanged}
            />
          </div>
        }
      </div>
    )
  }

  render = () => {
    return (
      <Card>
        <CardContent>
          <Typography variant='h5'>Audit log</Typography>
          { this.renderFilters() }
          <Results filter={this.filter} onPageSelected={this.props.onPageChange}/>
        </CardContent>
      </Card>
    )
  }
}

const styles = theme => ({
  filterSection: {
    position: 'relative',
    minHeight: '48px',
    marginBottom: '10px',
    display: 'flex'
  },
  collapseButton: {
    position: 'absolute',
    top: 0,
    right: 20,
    color: theme.palette.primary.main
  },
  filters: {
    paddingTop: '28px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterGroup: {
    display: 'flex',
    flex: '1 0 auto'
  },
  formControl:{
    paddingTop: '16px',
  },
  filterField: {
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'flex'
  },
  dateFilter: {
    paddingTop: 2
  },
})

export default compose(
  withRouter,
  withStyles(styles),
  connectQueryString('versions'),
  connect(({versions}) => versions)
)(List)
