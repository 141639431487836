import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class DeviceActionSet extends CrudActionSetBase('device'){
  static initialState = {
    stats: {}
  }

  static testEvent(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({id}) => {
      return {
        type: constants.ACTION,
        promise: API.Devices.testEvent({id})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request], errors: {...state.errors, testEvent: null}}
      },
      [constants.SUCCESS]: (state, { request, result: { data }}) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests}
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, testEvent: error }
        return {...state, requests, errors }
      }
    })
  }

  static stats(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({params={}, filter=null}={}) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: API.Devices.stats({...params}),
        payload: {}
      })
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: this.initialState.errors }
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, stats: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, stats: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new DeviceActionSet()