import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { uuid } from 'utils'

class UploadPictureField extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.spanId = uuid()
  }

  static propTypes = {
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func
  }

  static getDerivedStateFromProps(props,state) {
    if(props.value !== state.value) {
      if(state.pictureURL) {
        URL.revokeObjectURL(state.pictureURL)
      }
      return {
        pictureUrl: props.value && URL.createObjectURL(props.value),
        value: props.value
      }
    } else {
      return state
    }
  }

  get pictureUrl() {
    if(this.state.pictureUrl) {
      return this.state.pictureUrl
    } else  {
      return this.props.initialUrl
    }
  }

  componentWillUnmount = () => {
    if(this.state.pictureUrl) {
      URL.revokeObjectURL(this.state.pictureUrl)
    }
  }

  handleButtonClicked = (event) => {
    this.fileInput.click()
  }

  handleFileChanged = ({target: {files: [file]}}) =>
    this.props.onChange({target: {value: file}})

  render = () => {
    console.log(this.props.classes({'fullWidth': this.props.fullWidth}))
    return (
      <div className={this.props.classes({'fullWidth': this.props.fullWidth})}>
        <input className={this.props.classes.fileInput} type="file" ref={fileInput => this.fileInput = ReactDOM.findDOMNode(fileInput)}
          onChange={this.handleFileChanged}
          accept='.jpg,.png'
        />
        {this.props.label &&
          <InputLabel htmlFor={this.spanId} error={this.props.error}>{this.props.label}</InputLabel>
        }
        <div id={this.spanId}>
          <Button
            className={this.pictureUrl ? this.props.classes.button : undefined}
            variant='contained'
            disabled={ this.props.disabled }
            onClick={this.handleButtonClicked}
          ><AttachFileIcon/></Button>
          {this.pictureUrl &&
            <img alt="logo" src={this.pictureUrl} className={this.props.classes.view}/>
          }
          {this.props.helperText &&
            <FormHelperText error={this.props.error}>{this.props.helperText}</FormHelperText>
          }
        </div>
      </div>
    )
  }
}

const styles = theme => ({
  fileInput: {
    display: 'none'
  },
  view: {
    height: "40px",
    backgroundImage: `linear-gradient(45deg, #333 25%, transparent 25%, transparent 75%, #333 75%, #333 100%),linear-gradient(45deg, #333 25%, black 25%, black 75%, #333 75%, #333 100%)`,
    backgroundSize: '20px 20px',
    backgroundPosition: '0px 0px, 10px 10px',
    marginLeft: 5
  },
  button: {
    top: "-16px"
  },
  fullWidth: {
    width: '100%'
  }
})

export default withStyles(styles)(UploadPictureField)