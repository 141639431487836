import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { UserActions, OrganisationActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Card from '@material-ui/core/Card';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import FaceIcon from '@material-ui/icons/Face'
import PhoneIcon from '@material-ui/icons/Smartphone'
import SmsIcon from '@material-ui/icons/Sms'
import RoleIcon from '@material-ui/icons/AccountBox'
import KeyIcon from '@material-ui/icons/VpnKey'
import BlockIcon from '@material-ui/icons/Block'
import { compose } from 'utils'
import { withPermissions } from 'containers/shared'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { PermissionsTable } from 'components'
import BusinessIcon from '@material-ui/icons/Business'
import RegistrationIcon from '@material-ui/icons/Assignment'
import withStyles from 'styles'
import { flatten, errorStringsFromError } from 'utils'
import DeleteIcon from '@material-ui/icons/Delete'

export class Show extends Component{

  static requiredPermissions = ({user: { organisation }}) => {
    return organisation ?
      {
        organisation: {
          [organisation.id]: [
            'readUsers', 'writeUsers', 'readOrganisation', 'writeOrganisation'
          ]
        }
      } : {}
  }

  constructor(props){
    super(props)
    OrganisationActions.bindActions(this, 'organisations')
    SnackbarActions.bindActions(this, 'snackbar')
    UserActions.bindActions(this)
  }

  async dependsOn(){
    return this.actions.show(this.id, {
      include: `roles,organisation,
                roles.systemPermission,
                roles.organisationPermissions.organisations,
                roles.registrationPermissions.organisations,
                roles.registrationPermissions.registrations`
    })
  }

  componentDidMount(){
    if(this.organisationPermissions.readUsers || this.organisationPermissions.readOrganisation){
      this.actions.organisations.getPermissionNames(this.props.user.organisation.id)
    }
  }

  dependenciesMet(){
    return !!this.user.id && (this.props.permissions && this.props.permissions.organisation)
  }

  get selectedTab(){
    return this.props.match.params.tab || 'system'
  }

  get userRole(){
    return this.props.userRole
  }

  get id(){
    return this.props.match.params.id || this.props.currentUser.id
  }

  get user(){
    return this.props.user
  }

  get organisationPermissions(){
    return this.props.permissions.organisation[this.user.organisation.id]
  }

  handleTabChange = (event, tab) =>
    this.props.history.push(`/users/${this.id}/${tab}`)

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this user? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.id})
      this.props.history.goBack()
      this.actions.snackbar.show('User deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete user.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  renderSuggestionLabel = ({type, name}) =>
    <p>{
      type === 'organisations' ?
        <BusinessIcon/> :
        <RegistrationIcon/>
      }
      {name}
    </p>


  renderScopeChips = ({roleName, organisations=[],registrations=[]}={}) =>
    <Fragment>
      <Typography variant='subtitle1'>{roleName}</Typography>
      {organisations.concat(registrations).map((entity, idx) =>
        <Chip label={this.renderSuggestionLabel(entity)} key={idx}/>
      )}
    </Fragment>

  renderScopedPermissionColumn = (title, type) => {
    const permissions = flatten(this.user.roles.map(({name: roleName, ...r}) =>
      flatten([r[type === 'system' ? `${type}Permission` : `${type}Permissions`]]).map(
        permission => ({...permission, roleName})
      )
    ))
    return this.props.permissionNames[type] && <PermissionsTable
      className={this.props.classes.permissionsTable}
      title={title}
      rowNames={this.props.permissionNames[type]}
      colNames={permissions.map(this.renderScopeChips)}
      columns={permissions}
    />
  }

  render = () =>
    <div className={this.props.classes.userCard}>
      <Card className={this.props.classes.userDetails}>
        <Typography variant='h6'>User - {this.user.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                {
                  (this.organisationPermissions.writeUsers || this.user.id === this.props.currentUser.id )&&
                  <Fragment>
                    <Link to={`/users/${this.user.id}/edit`}>
                      <IconButton>
                        <EditIcon/>
                      </IconButton>
                    </Link>
                    <IconButton onClick={this.handleDelete}>
                      <DeleteIcon/>
                    </IconButton>
                  </Fragment>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <List>
          <ListItem>
            <ListItemIcon><PersonIcon/></ListItemIcon>
            <ListItemText primary={this.user.name} secondary='name'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><EmailIcon/></ListItemIcon>
            <ListItemText primary={this.user.email} secondary='email'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><PhoneIcon/></ListItemIcon>
            <ListItemText primary={this.user.phoneNumber} secondary='phone'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><PhoneIcon/></ListItemIcon>
            <ListItemText primary={this.user.mobileNumber} secondary='mobile'/>
          </ListItem>
          <ListItem>
            <ListItemIcon><SmsIcon/></ListItemIcon>
            <ListItemText primary={this.user.acceptsSms ? 'Accepts SMS notifications' : 'Does not accept SMS notifications'}/>
          </ListItem>
          <ListItem>
            {this.user.login ?
              <Fragment>
                <ListItemIcon><KeyIcon/></ListItemIcon>
                <ListItemText>Can log in</ListItemText>
              </Fragment> :
              <Fragment>
                <ListItemIcon><BlockIcon/></ListItemIcon>
                <ListItemText>Cannot log in</ListItemText>
              </Fragment>
            }
          </ListItem>
          <ListItem>
            <ListItemIcon><FaceIcon/></ListItemIcon>
            <ListItemText primary={this.user.roles.map(user => user.name).join(', ')} secondary='Roles'/>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <RoleIcon />
            </ListItemIcon>
            <ListItemText primary={
              <Fragment>
                <span>User Role</span>
                {
                  this.organisationPermissions.writeUsers &&
                  <ListItemIcon>
                    <IconButton onClick={() => this.props.history.push(`/users/${this.user.id}/user_role/edit`)}>
                      <EditIcon/>
                    </IconButton>
                  </ListItemIcon>
                }
              </Fragment>}
            />
          </ListItem>
        </List>
      </Card>
      {
        this.organisationPermissions.readUsers &&
        <Card className={this.props.classes.userRoles}>
          <Typography variant='h6'>
            Permissions
          </Typography>
           <Tabs onChange={this.handleTabChange} value={this.selectedTab} variant="scrollable" scrollButtons="off">
            <Tab value='system'       label="System" />
            <Tab value='organisation' label="Organisations" />
            <Tab value='registration' label="Registrations"/>
          </Tabs>
          <div className={this.props.classes.permissionsContainer}>
            {
              this.selectedTab === 'system' && !!this.user.roles.length &&
              this.renderScopedPermissionColumn('System Permissions', 'system')
            }
            {
              this.selectedTab === 'organisation' && !!this.user.roles.length &&
              this.renderScopedPermissionColumn('Organisation Permissions', 'organisation')
            }
            {
              this.selectedTab === 'registration' && !!this.user.roles.length &&
              this.renderScopedPermissionColumn('Registration Permissions', 'registration')
            }
          </div>
        </Card>
      }
    </div>
}


const styles = theme => ({
  userCard: {
    maxWidth: theme.viewAreaMaxWidth,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '15px auto'
  },
  userRoles: {
    flex: 2,
    padding: 20,
    minWidth: 300,
  },
  userDetails: {
    flex: 1,
    padding: 20,
    minWidth: 300,
    '@media(min-width: 780px)': {
      marginRight: 15
    }
  },
  permissionsContainer: {
    width: '100%',
    overflow: 'auto'
  },
  permissionsTable: {
    width: 0
  }
})


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withStyles(styles),
  withPermissions(Show.requiredPermissions),
  connect(({users, organisations: { permissionNames}, tokens: { currentUser }}) => ({...users, permissionNames, currentUser})),
)(Show)