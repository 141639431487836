import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const UserRoles = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({organisationId}) => `organisations/${organisationId}/user_roles`),
    create:  endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/user_roles`),
    update:  endpoint => endpoint.method('put').path(({id}) => `user_roles/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `user_roles/${id}`),
    show:    endpoint => endpoint.path(({id}) => `user_roles/${id}`),
  }
)