import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import {ParentEventActions, ChildEventActions, ProgrammeActions, OfferActions, SnackbarActions} from 'actionsets'
import {formatLocaleDateTime, formatLocaleNumeric} from 'utils'
import { compose } from 'utils'
import { connect } from 'react-redux'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import withStyles from 'styles'
import {humanize} from 'utils'
import EventStatusColors from 'constants/EventStatusColors'
import {alpha} from '@material-ui/core/styles/colorManipulator'
import EventIcon from '@material-ui/icons/Event'
import Dependent from 'containers/shared/Dependent'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import {ChildEvents as ChildEventsAPI} from 'api'
import BCTIIcon from '@material-ui/icons/AttachMoney'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { OfferInput, FABAbsolute, Loader } from 'components'
import AddIcon from '@material-ui/icons/Add'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

const filter = {
  to: moment().add(1, 'month').endOf('day').toDate()
}

const pageSize = 6

const inactiveStates = ['cancelled', 'declined', 'withdrawn', 'ineligible']
const statusesWhichRequireAttention = ['scheduled','active','completed','cancelled']

export class Cards extends Component{

  constructor(props) {
    super(props)
    ParentEventActions.bindActions(this, 'parentEvents')
    ChildEventActions.bindActions(this, 'childEvents')
    ProgrammeActions.bindActions(this, 'programmes')
    SnackbarActions.bindActions(this, 'snackbar')
    OfferActions.bindActions(this, 'offers')
  }

  state = {}

  eventValue(childEvent){
    const {
      offer,
      optedIn,
      mwhPrice,
      targetKw,
      status,
      parentEvent: {
        start,
        end
      }
    } = childEvent
    const { settlementAmount } = (childEvent.lastPayment || {})
    const eventLength = moment(end).diff(moment(start), 'minutes')/60
    if(inactiveStates.indexOf(status) !== -1)
      return 0
    if(settlementAmount)
      return settlementAmount
    if(offer && optedIn)
      return offer.offerPrice * (offer.offerKw / 1000.0) * eventLength
    return mwhPrice * (targetKw / 1000.0) * eventLength
  }

  eventRequiresAttention = ({optedIn, status}) => optedIn && statusesWhichRequireAttention.indexOf(status) !== -1

  showEvent = event => () => {
    this.props.history.push(`/${this.shouldRenderParentEvents ? 'parent' : 'child'}_events/${event.id}`)
  }

  dependsOn() {
    return [
      this.loadEvents()
    ]
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.eventsType !== this.props.eventsType) {
      this.props.onReloadDependent()
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  loadEvents = () => {
    return this.shouldRenderParentEvents ? this.loadParentEvents() : this.loadChildEvents()
  }

  loadProgrammes = () => {
    return this.actions.programmes.index({
      fields: { programme: 'name'},
      filter: { withCreateEventPermissions: true },
      pageSize: 50
    })
  }

  loadChildEvents = () => {
    return this.actions.childEvents.index({
      pageSize: pageSize,
      filter: filter,
      order: '-start',
      fields: {
        childEvents: 'targetKw,mwhPrice,programme,parentEvent,registration,offer,optedIn,status,lastPayment',
        programmes: 'name,type',
        payments: 'performancePercentage,bcti,settlementAmount',
        parentEvents: 'start,end,offerWindowStart,offerWindowEnd',
        registrations: 'name',
        offers: 'offerKw,offerPrice,status',
        bctis: 'invoiceUrlPath',
        autoDREvents: 'estimatedKwAmount,device'
      },
      include: 'programme,parentEvent,registration,offer,lastPayment,lastPayment.bcti,devices,offer.autoDREvents,offer.autoDREvents.deviceSignal,offer.autoDREvents.device'
    })
  }

  loadParentEvents = () => {
    return this.actions.parentEvents.index({
      filter: filter,
      order: '-start',
      pageSize: pageSize,
      fields: { programme: 'name,active'},
      include: 'programme'
    })
  }

  handleShowOfferInput = offeredChildEvent => async event => {
    event.preventDefault()
    event.stopPropagation()
    const {
      data: {
        registration: { targetedDevices },
        offer: { autoDREvents }
      }
    } = await ChildEventsAPI.show({
      id: offeredChildEvent.id,
      options: {
        include: 'registration,registration.targetedDevices,registration.targetedDevices.deviceSignals,offer,offer.autoDREvents,offer.autoDREvents.deviceSignal,offer.autoDREvents.device',
        fields: {
          childEvents: 'registration,offer',
          registrations: 'targetedDevices',
          offers: 'autoDREvents',
          autoDREvents: 'estimatedKwAmount,device'
        }
      }
    })
    this.setState({offeredChildEvent: {
        ...offeredChildEvent,
        registration: {
          ...offeredChildEvent.registration,
          targetedDevices
        },
        offer: {
          ...offeredChildEvent.offer,
          autoDREvents
        }
      }})
  }

  handleHideOfferInput = () =>
    this.setState({offeredChildEvent: undefined})

  handleUpdateOffer = async ({target: { value: offer }}) => {
    try{
      await this.actions.offers.update(offer, { include: 'autoDREvents,autoDREvents.device,autoDREvents.deviceSignal' })
      this.actions.snackbar.show("Offer updated")
      this.handleHideOfferInput()
    }catch(err){
      this.actions.snackbar.show("Failed to update offer: "+err.message)
      throw(err)
    }finally{
      await this.loadChildEvents()
    }
  }

  handleDownloadFile = url => event => {
    event.preventDefault()
    event.stopPropagation()
    window.open(url)
  }

  handleNewEvent = async ({currentTarget}) => {
    try {      
      this.setState({programmeMenuAnchor: currentTarget, loadingProgrammes: true})
      await this.loadProgrammes()              
    } finally {
      this.setState({loadingProgrammes: false})
    }
  }

  handleCloseProgrammeMenu = () => {
    this.setState({programmeMenuAnchor: null})
  }

  get events() {
    return this.shouldRenderParentEvents ? this.props.parentEvents : this.props.childEvents
  }

  get shouldRenderParentEvents() {
    return this.props.eventsType === 'parent'
  }

  offerStatusIcon({status, offer}) {
    if(status === 'withdrawn' || status === 'ineligible') {
      return <CloseIcon/>
    }

    switch(offer && offer.status) {
      case 'opted_in':
        return <Tooltip title="You've opted in"><ThumbUpIcon/></Tooltip>
      case 'opted_out':
        return <Tooltip title="You've opted out"><ThumbDownIcon/></Tooltip>
      default:
        return <Tooltip title="No action yet"><ThumbsUpDownIcon/></Tooltip>
    }
  }

  renderOfferInputDialog = () => {
    if(!this.state.offeredChildEvent) {
      return false
    }

    const { status } = this.state.offeredChildEvent
    const withdrawn = !!(status === 'withdrawn')
    const ineligible = !!(status === 'ineligible')

    return (
      <Dialog open={true} onClose={this.handleHideOfferInput}>
        <DialogTitle>Offer</DialogTitle>
        <DialogContent className={this.props.classes.dialogPaper}>
          <OfferInput
            onChange={this.handleUpdateOffer}
            offer={this.state.offeredChildEvent.offer}
            childEvent={this.state.offeredChildEvent}
            eventStart={this.state.offeredChildEvent.parentEvent.start}
            eventEnd={this.state.offeredChildEvent.parentEvent.end}
            locked={this.state.offeredChildEvent.status !== 'offer_window_open' || withdrawn || ineligible}
          />
        </DialogContent>
      </Dialog>
    )
  }

  renderSelectProgrammeMenu = () =>
    <Menu
      id="select-programme-menu"
      anchorEl={this.state.programmeMenuAnchor}
      open={Boolean(this.state.programmeMenuAnchor)}
      onClose={this.handleCloseProgrammeMenu}
      PopoverClasses={{paper: this.props.classes.menu}}
    >
      {
        this.state.loadingProgrammes ? <Loader size={100}/> :
        this.props.programmes.map(({id, name}) =>
          <MenuItem key={id} onClick={() => this.actions.parentEvents.showNew(id)}>{name}</MenuItem>
        )
      }
    </Menu>

  render = () => {
    const { classes } = this.props
    const {shouldRenderParentEvents, events} = this
    return (
      <div className={classes.eventContainer}>
        {this.renderOfferInputDialog()}
        {events.map(event =>
          <div className={classes({event: true, highlight: !!this.eventRequiresAttention(event)})} onClick={this.showEvent(event)} key={event.id}>
            <div className={classes.eventBody}>
              <Typography variant='subtitle1' className={classes.eventTitle}>
                <EventIcon/>
                {event.programme.name}
              </Typography>
              <dl className={classes.eventDetails}>
                {!shouldRenderParentEvents &&
                <div style={{flexBasis: '100%'}}>
                  <dt>Registration</dt>
                  <dd>{event.registration.name}</dd>
                </div>
                }
                <div>
                  <dt>Start</dt>
                  <dd>{formatLocaleDateTime(event.start || event.parentEvent.start)}</dd>
                </div>
                <div>
                  <dt>End</dt>
                  <dd>{formatLocaleDateTime(event.end || event.parentEvent.end)}</dd>
                </div>
                {shouldRenderParentEvents ?
                  <>
                    <div>
                      <dt>Target</dt>
                      <dd>{formatLocaleNumeric(event.targetKw / 1000.0)}<span className={classes.unit}>MW</span></dd>
                    </div>
                    <div>
                      <dt>Scheduled</dt>
                      <dd>{formatLocaleNumeric(event.scheduledKw / 1000.0)}<span className={classes.unit}>MW</span>
                      </dd>
                    </div>
                  </>
                  :
                  <>
                    <div>
                      <dt>Value</dt>
                      <dd>
                        {
                          (inactiveStates.indexOf(event.status) !== -1) ?
                            '--' :
                            `$${formatLocaleNumeric(this.eventValue(event))}`
                        }
                      </dd>
                    </div>
                    <div>
                      <dt>Performance</dt>
                      <dd>
                        {event.performancePercentage ? `${event.performancePercentage.toFixed(1)}%` : '--'}
                      </dd>
                    </div>
                  </>
                }
              </dl>
            </div>
            <div className={classes.eventStatus} style={{background: alpha(EventStatusColors[event.status].background, 0.67)}}>
              {!shouldRenderParentEvents &&
                <div className={classes.statusButtonWrapper}>
                  {event.lastPayment && event.lastPayment.bcti && event.lastPayment.bcti.invoiceUrlPath &&
                    <IconButton onClick={this.handleDownloadFile(event.lastPayment.bcti.invoiceUrlPath)}>
                      <BCTIIcon/>
                    </IconButton>
                  }
                </div>
              }
              <span>{humanize(event.status)}</span>
              {!shouldRenderParentEvents &&
                <div className={classes.statusButtonWrapper}>
                  {event.programme.type === 'PriceResponsiveProgramme' &&
                    <IconButton onClick={this.handleShowOfferInput(event)}>
                      {this.offerStatusIcon(event)}
                    </IconButton>
                  }
                </div>
              }
            </div>
          </div>
        )}
        {
          shouldRenderParentEvents &&
          <FABAbsolute color='primary' onClick={this.handleNewEvent}>
            <AddIcon/>
          </FABAbsolute>          
        }
        {this.renderSelectProgrammeMenu()}
      </div>
    )
  }
}

const styles = theme => ({
  eventContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -5,
    '& > button': {
      bottom: '8px',
    },
  },
  event: {
    position: 'relative',
    flex: '1 1 245px',
    minWidth: 'calc(33.333% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid rgba(150, 254, 172, 0.2)',
    margin: 5,
    borderRadius: 16,
    overflow: 'hidden',
    cursor: 'pointer',
    background: 'rgba(0, 27, 38, 0.5)',
    boxShadow: '0 10px 40px 10px rgba(0,0,0,0.1)',
    '&:hover': {
      filter: 'brightness(120%)'
    },
    '&::before,&::after': {
      content: "''",
      width: 5,
      height: 11,
      background: 'rgba(150, 254, 172, 0.2)',
      display: 'block',
      position: 'absolute',
      top: 85,
      zIndex: 100
    },
    '&::before': {
      left: 0,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5
    },
    '&::after': {
      right: 0,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5
    },
    '&$highlight': {
      background: 'rgba(70, 70, 70, 0.5)'
    },
    '@media(min-width: 768px)': {
      minHeight: 240,
    }
  },
  highlight: {},
  eventBody: {
    flex: '1 1 auto',
    borderBottom: '1px solid rgba(255,255,255,0.15)',
  },
  eventTitle: {
    textAlign: 'left',
    margin: '12px 10px 10px',
    lineHeight: '1.2',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& svg': {
      marginRight: 10,
      marginBottom: 1,
      color: theme.palette.secondary.main
    }
  },
  eventDetails: {
    textAlign: 'left',
    margin: 5,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& div': {
      padding: 5,
      lineHeight: 1.3,
      flex: '1 1 50%'
    },
    '& dt': {
      fontSize: '0.8em',
      fontWeight: 700,
      color: 'rgba(255,255,255,0.8)'
    },
    '& dd': {
      margin: 0
    },
  },
  unit: {
    opacity: 0.8,
    fontSize: '0.7em',
  },
  eventStatus: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    '& span': {
      paddingBottom: 2,
      flex: 1,
    },
  },
  statusButtonWrapper: {
    width: '48px'
  },
  menu: {
    minWidth: 400,
    minHeight: 500,
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  withRouter,
  connect(({childEvents, parentEvents, programmes: { programmes }}) => ({...childEvents, ...parentEvents, programmes, requests: [...parentEvents.requests, ...childEvents.requests]})),
)(Cards)
