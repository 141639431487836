import React, {Component, Fragment, lazy, Suspense} from 'react'
import { connect } from 'react-redux'
import { RegionActions, SnackbarActions } from 'actionsets'
import { Pagination, FABFixed, ErrorBanner, LabeledSelect, LabeledSwitch } from 'components'
import Dependent from 'containers/shared/Dependent'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import RegionIcon from '@material-ui/icons/MyLocation'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Segments } from 'containers/load_map'
import withStyles from 'styles'
import { FormContext, DateTimePicker, MultiAutoSuggest, Loader } from 'components'
import { Authorization, roundDateOrMoment, flatten, compose, errorStringsFromError } from 'utils'
import * as API from 'api'
import { connectQueryString } from 'containers/shared'
import moment from 'moment'
import TabIcon from '@material-ui/icons/Tab'
import TradingPeriod from 'constants/TradingPeriod'

const LoadMap = lazy(() => import('containers/load_map'))

export class List extends Component{

  constructor(props){
    super(props)
    RegionActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    this.state = { groupedAvailabilities: {} }
  }

  dependsOn(){
    return Promise.all([
      this.loadRegions(),
    ])
  }

  componentDidMount = () => {
    this.loadAvailability()
  }

  loadAvailability = async () => {
    const groupedAvailabilities = {}
    try{
      const { data: groupedAvailability } = await API.Registrations.availability({options: {
          filter: this.props.filter
        }
      })
      groupedAvailabilities[this.props.filter.segmentBy] = flatten([groupedAvailability])
    }catch(err){
      groupedAvailabilities[this.props.filter.segmentBy] = []
    }
     try{
      const { segmentBy, ...filter } = this.props.filter
       const {  data: groupedAvailabilityByGxp } = await API.Registrations.availability({
        options: {
          filter: {...filter, segment_by: 'gxp' }
        }
      })
      groupedAvailabilities.gxp = groupedAvailabilityByGxp
    }catch(err){
      groupedAvailabilities.gxp = []
    }
    this.setState({groupedAvailabilities})
  }

  loadRegions = page => {
    return this.actions.index({
      ...(page && {page}),
      fields: {regions: 'name'}
    });
  }

  get selectedTab(){
    return this.props.match.params.tab || 'map'
  }

  get tags(){
    return flatten(Object.entries(this.props.filter.tags || {}).map(([type, values]) =>
      flatten([values]).map(value => ({
        value,
        tagType: {
          name: type
        }
      }))
    ))
  }


  handleTagsChanged = ({target: { value }}) => {
    const tags = {};
    (value || []).forEach(({value, tagType: { name }}) => {
      if(!tags[name]) tags[name] = []
      tags[name].push(value)
    })
    this.props.onFilterChange({...this.props.filter, tags }, this.loadAvailability)
  }

  handleProgrammesFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: programmes } = await API.Programmes.index(params)
    callback(programmes.map(({name}) => name).filter(name => !(this.filter.programmeName || []).includes(name)).slice(0, 5))
  }

  handleOrganisationsFetchRequested = async (text, callback) => {
    const params = {options: { filter: { name: text } }}
    const { data: organisations } = await API.Organisations.index(params)
    callback(organisations.map(({name}) => name).filter(name => !(this.filter.organisation || []).includes(name)).slice(0, 5))
  }

  handleTagsFetchRequested = async (value, callback) => {
    const { data: values } = await API.TagValues.index({
      options: {
        page: { size: 50, number: 1 },
        filter: { value, ...this.props.filter },
        include: 'tagType'
      }
    })
    callback(values.filter(v => !((this.filter.tags || {})[v.tagType.name] || []).includes(v.value)).slice(0, 5))
  }

  renderTagLabel = ({value, tagType: { name }}) =>
    <span><strong>{name}</strong>: {value}</span>

  get filter(){
    return Object.entries(this.props.filter || {})
                 .reduce((agg, [key, value]) => ({...agg, [key]: this.parseFilter(key, value)}), {})
  }

  parseFilter(key, value){
    switch(key){
    case 'time':
      return roundDateOrMoment(moment(value), 30).toDate()
    case 'programmeName':
    case 'registration':
    case 'organisation':
    case 'name':
      return flatten([value])
    default:
      return value
    }
  }

  handleFilterChange = ({time, ...others}) => {
    if(time && typeof time === 'object') time = time.toISOString()
    const filter = {time: time && time, ...others}
    this.props.onFilterChange(filter, this.loadAvailability)
  }

  handleCollapseFilters = () => {
    this.setState({filtersCollapsed: !this.state.filtersCollapsed})
  }

  handleTabChange = (event, tab) => {
    this.props.history.push(`/regions/${tab}`)
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showRegion = id => () => {
    this.props.history.push(`/regions/${id}`)
  }

  editRegion = id => event => {
    this.props.history.push(`/regions/${id}/edit`)
    event.stopPropagation()
  }

  deleteRegion = id => event => {
    this.actions.destroy({id})
                .then(() => this.actions.index())
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get hideZeroLoadFeatures(){
    return `${this.props.filter.showZeroLoadFeatures}` !== 'true'
  }

  get showGXPs(){
    return `${this.props.filter.showGXPs}` !== 'false'
  }

  get regions(){
    return this.props.regions
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderRegionListItem = ({id, name}) =>
    <ListItem button onClick={this.showRegion(id)} key={id}>
      <ListItemIcon>
        <RegionIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
      <ListItemSecondaryAction>
        <IconButton onClick={this.editRegion(id)}><EditIcon/></IconButton>
        <IconButton onClick={this.deleteRegion(id)}><DeleteIcon/></IconButton>
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>


  renderFilters = () =>
    <section className={this.props.classes.filters}>
      <FormContext context={this.filter} errorContext={this.props.errorContext} onChange={this.handleFilterChange}>
        <DateTimePicker fullWidth minutesStep={TradingPeriod.length.inMinutes} member='time'/>
        <LabeledSelect fullWidth member='segmentBy'>
          <MenuItem value={null}>None</MenuItem>
          {
            Segments.filter(seg => seg.selectable).map(
              segment =>
                <MenuItem key={segment.id} value={segment.id}>
                  {segment.name}
                </MenuItem>
            )
          }
        </LabeledSelect>
        <LabeledSwitch member='showGXPs' label='Show GXPs'  checked={`${this.props.filter.showGXPs}` === 'true'}/>
        <LabeledSwitch member='showZeroLoadFeatures' checked={`${this.props.filter.showZeroLoadFeatures}` === 'true'}/>
        <MultiAutoSuggest
          classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
          member='programmeName'
          labelProvider={(name) => name}
          onSuggestionsFetchRequested={this.handleProgrammesFetchRequested}
        />
        <MultiAutoSuggest
          classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
          member='organisation'
          labelProvider={(name) => name}
          onSuggestionsFetchRequested={this.handleOrganisationsFetchRequested}
        />
        {
          Authorization.systemPermissions.readTags &&
          <MultiAutoSuggest
            classNames={{container: this.props.classes.reportsFilter, input: this.props.classes.reportsFilterInput}}
            labelProvider={this.renderTagLabel}
            debounceWait={200}
            label='Tags'
            onChange={this.handleTagsChanged}
            value={this.tags}
            onSuggestionsFetchRequested={this.handleTagsFetchRequested}
          />
        }
      </FormContext>
    </section>

  renderRegionsMap = () =>
    <div className={this.props.classes.loadMapContainer}>
      <IconButton style={{top: -50, right: 0}} className={this.props.classes.toggleButton} onClick={this.handleCollapseFilters}>
        <TabIcon/>
      </IconButton>
      {
        !this.state.filtersCollapsed &&
        this.renderFilters()
      }
      <Suspense fallback={<Loader size={100}/>}>
        <LoadMap showGXPs={this.showGXPs} scrollWheelZoom={true} hideZeroLoadFeatures={this.hideZeroLoadFeatures} segmentBy={this.props.filter.segmentBy} groupedAvailabilities={this.state.groupedAvailabilities}/>
      </Suspense>
    </div>

  renderRegionsList = () =>
    <Fragment>
     {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadRegions} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.regions.map(this.renderRegionListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadRegions} style={{}} linkStyle={{}}/>
      <FABFixed color='primary' onClick={() => this.props.history.push('/regions/new')}>
        <AddIcon/>
      </FABFixed>
    </Fragment>

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Regions</Typography>

        <Tabs value={this.selectedTab} onChange={this.handleTabChange}>
          <Tab value='map' label='Map'/>
          <Tab value='list' label='List'/>
        </Tabs>
        {this.selectedTab === 'map' && this.renderRegionsMap()}
        {this.selectedTab === 'list' && this.renderRegionsList()}
      </CardContent>
    </Card>
}

const styles = theme => ({
  toggleButton: {
    position: 'absolute',
    color: theme.palette.primary.main
  },
  loadMapContainer: {
    position: 'relative',
    height: 'calc(100vh - 230px)',
    display: 'flex',
    '@media(max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  filters: {
    flex: '0 1 300px',
    marginRight: 20,
    height: 'calc(100vh - 230px)',
    '@media(max-width: 600px)': {
      flex: "1 1 auto",
      marginBottom: "20px",
      position: "relative",
      zIndex: "5000",
    }
  }
})
export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({regions}, {filter}) => ({
    filter: {
      time: moment().toISOString(),
      showZeroLoadFeatures: 'false',
      'showGXPs': 'true',
      ...filter
    },
    ...regions
  })),
  connectQueryString('regions'),
)(List)
