import React, { Component, Fragment } from 'react'
import { connect }          from 'react-redux'
import { UserActions, TokenActions, UserRoleActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, GradedPassword }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { compose } from 'utils'
import withStyles from 'styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import { MultiAutoSuggest, LabeledSelect } from 'components'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    UserActions.bindActions(this)
    TokenActions.bindActions(this, 'tokens')
    UserRoleActions.bindActions(this, 'userRoles')
    this.state = {}
  }

  dependsOn(){
    if(this.editMode){
      return this.actions.show(this.objectId, {include: 'roles,organisation'})
    }else{
      return this.actions.set({})
    }
  }

  get saveEndpointOptions() {
    return {
      include: 'organisation'
    }
  }

  maybeSave = async () => {
    const compulsory = this.props.needChangePassword
    if(this.state.acceptPassword || !this.formObject.password) {

      const saveAction = (
        this.editMode ?
          this.actions.update(this.attributesToSave, this.saveEndpointOptions) :
          this.actions.create(this.attributesToSave, this.saveEndpointOptions)
      ).then(async result => {
        if(!result.status && this.formObject.password && this.ourself){
          await this.actions.tokens.create({email: this.formObject.email, password: this.formObject.password})
        }
        return result
      })

      const result = await this.save(saveAction)
      if(!result.status) {
        this.actions.tokens.unexpirePassword()
        if(compulsory) {
          this.props.history.push('/')
        }
      }
    }
  }

  get formObject(){
    let value = {organisationId: this.organisationId, login: true, ...this.props.user, ...this.state.formAttributes}
    if(!value.oldPassword) {
      delete value.password
      delete value.passwordConfirmation
    }
    return value
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.user.organisation && this.props.user.organisation.id)
  }

  get userRoles(){
    return this.state.roles || this.props.user.roles || []
  }

  get ourself() {
    return String(this.formObject.id) === String(this.props.currentUserId)
  }

  onSaveRedirect = ({data: {id} = {}} = {}) => {
    if(this.organisationId) {
      return `/organisations/${this.organisationId}`
    } else {
      return `/users/${id}`
    }
  }

  handleSuggestionsFetchRequested = async (text, callback) => {
    const { data: suggestions } = await this.actions.userRoles.index({
      params: { organisationId: this.organisationId },
      filter: {name: text}
    })
    callback(suggestions.filter(s => !this.userRoles.some(role => role.name === s.name)))
  }

  handleRolesChange = ({target: { value: roles}}) =>{
    this.setState({roles, formAttributes: {
      ...this.state.formAttributes,
      roleIds: roles.map(({id}) => id)
    }})
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit User - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.maybeSave}>
        {this.renderErrorMessages()}
        <CardContent>
          <TextField member='name'         fullWidth required />
          <TextField member='email'        fullWidth required type='email'/>
          <TextField member='mobileNumber' fullWidth required={this.formObject.acceptsSms} type='tel'/>
          <FormControlLabel
            control={
              <FormContext onChange={this.handleFormObjectChange} context={this.formObject}>
                <Checkbox type='checkbox' member='acceptsSms' />
              </FormContext>
            }
            label="Accepts SMS notifications"
          />
          <TextField type='tel' required fullWidth  member='phoneNumber'/>
          {
            !this.ourself &&
            <Fragment>
              {this.editMode &&
                <LabeledSelect
                  required fullWidth member='status'
                  options={{active: 'Active', inactive: 'Inactive'}}
                />
              }
              <FormControlLabel
                control={
                  <FormContext onChange={this.handleFormObjectChange} context={this.formObject}>
                    <Checkbox type='checkbox' member='login' />
                  </FormContext>
                }
                label="Can log in"
              />
            </Fragment>
          }
          <MultiAutoSuggest
            label='Roles'
            fullWidth
            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
            value={this.userRoles}
            onChange={this.handleRolesChange}
          />
          <br/>
          {
            (this.editMode && this.ourself) &&
            <CardContent className={this.props.classes.passwords}>
              <Typography variant='body2'>Password {this.editMode && !this.props.needChangePassword && '(Optional)'}</Typography>
              <TextField type='password' fullWidth member='oldPassword' label='Current Password' />
              <GradedPassword disabled={!this.formObject.oldPassword} type='password' onAcceptanceChange={acceptPassword => this.setState({acceptPassword})} fullWidth member='password'/>
              <TextField disabled={!this.formObject.password} type='password' fullWidth member='passwordConfirmation'/>
            </CardContent>
          }
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit' disabled={(this.formObject.password && !(this.state && this.state.acceptPassword)) || (this.props.needChangePassword && !this.formObject.password)}>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    overflow: 'visible',
    margin: '0 auto'
  },
  passwords: {
    marginTop: 5,
    background: "#5b5b5b80"
  }
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({users, tokens: {currentUser: {id: currentUserId}, needChangePassword}}) => ({...users, currentUserId, needChangePassword})),
)(Form)
