import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class ChildEventActionSet extends CrudActionSetBase('childEvent'){
  static event(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(({id}, event) => {
      return {
        type: constants.ACTION,
        promise: API.ChildEvents.event({id, name: event})
      }
    })

    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, event: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, event: error }
        return {...state, requests, errors }
      }
    })
  }

  static downloadExport(creator, reducer, constants){
    constants.defineRequestConstants()

    creator(({filter=null, order=null}={}) => (dispatch, getState) => {
      return dispatch({
        type: constants.ACTION,
        promise: API.ChildEvents.export({options: {...order && {order}, ...filter && {filter} }}),
      })
    })

    reducer({})
  }
}
export default new ChildEventActionSet()