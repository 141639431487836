import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Calendars = ProtectedJsonAPI.extend(
  '/',
  {
    index:   endpoint => endpoint.path(() => '/calendars'),
    create:  endpoint => endpoint.method('post').path(() => 'calendars'),
    update:  endpoint => endpoint.method('put').path(({id}) => `calendars/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `calendars/${id}`),
    show:    endpoint => endpoint.path(({id}) => `calendars/${id}`),
    getHolidayZones: endpoint => endpoint.path(() => 'calendars/holiday_zones'),
    saveHolidayZones: endpoint => endpoint.method('PATCH').path(() => 'calendars/holiday_zones'),
    getNames: endpoint => endpoint.path(({options: {fields}}) => Object.keys(fields)[0])
  }
)