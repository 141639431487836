import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class NotificationActionSet extends CrudActionSetBase('notification'){
  static cancelNotifications(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator((ids) => {
      return {
        type: constants.ACTION,
        promise: API.Notifications.cancel({ ids })
      }
    })

    reducer({
      [constants.SUCCESS]: (state, { request, result: { data: {tag} } }) => {
        return { ...state, tag }
      }
    })
    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, cancelNotifications: null }}
      },
      [constants.SUCCESS]: (state, { request }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, cancelNotifications: error }
        return {...state, requests, errors }
      }
    })
  }

  static enqueueNotifications(creator, reducer, constants) {
    constants.defineRequestConstants()

    creator((ids) => {
      return {
        type: constants.ACTION,
        promise: API.Notifications.enqueue({ ids })
      }
    })

    reducer({
      [constants.SUCCESS]: (state, { request, result: { data: {tag} } }) => {
        return { ...state, tag }
      }
    })
    reducer({
      [constants.REQUEST]: (state, { request }) => {
        return {...state, requests: [...state.requests, request ], errors: {...state.errors, enqueueNotifications: null }}
      },
      [constants.SUCCESS]: (state, { request }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, enqueueNotifications: error }
        return {...state, requests, errors }
      }
    })
  }
}

export default new NotificationActionSet()