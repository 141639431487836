import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { SiteActions, LoadTypeActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { compose, errorStringsFromError, formatLocaleNumeric, Authorization } from 'utils'
import withStyles from 'styles'
import { MultiAutoSuggest } from 'components'
import Chip from '@material-ui/core/Chip'
import { withPermissions } from 'containers/shared'
import DeleteIcon from '@material-ui/icons/Delete'
import { Device } from 'containers'
import EyeIcon from '@material-ui/icons/RemoveRedEye'
import { AvailabilitiesSummary, DatePicker } from 'components'
import moment from 'moment'
import Button from '@material-ui/core/Button'

export class Show extends Component{

  static requiredPermissions = ({site}) => {
    return {
      system: ['readNetworkTopology'],
      organisation: {
        ...site && site.programmes && site.programmes.reduce((agg, {organisation: { id }}) => ({...agg, [id]: ['writeProgrammes']}) ,{}),
        ...site && site.organisation && {[site.organisation.id]: ['writeSites','writeProgrammes','readVerificationMethods','readOrganisation']},
      }
    }
  }

  constructor(props){
    super(props)
    SiteActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    LoadTypeActions.bindActions(this, 'loadTypes')
    this.state = {
      readingRequestInterval: {
        startMonth: moment().startOf('month').toDate(),
        endMonth: moment().endOf('month').toDate(),
      },
    }
  }

  dependsOn(){
    return Promise.all([
      this.actions.loadTypes.index(),
      this.actions.show(this.id, {
        include:
          'meterOwner,distributor,retailer,' +
          'verificationMethod,gxp,organisation,' +
          'programmes.organisation,registrations,availabilities,' +
          'consumerAuthorisationCode,consumerNo,customerName,registryReqconsEnabled'
      })
    ])
  }

  handleDateFilterChange = which => ({target: { value }}) => {
    this.setState({readingRequestInterval: {...this.state.readingRequestInterval, [which]: value}})
  }

  handleRequestReading = async () => {
    try {
      await this.actions.requestMeterReading(this.id, this.state.readingRequestInterval)
      this.actions.snackbar.show(`SUCCESS: Meter data request has been submitted.`)
    } catch(err) {
      console.error(err)
      this.actions.snackbar.show(`ERROR: Failed to submit meter data request.\n${err.message}`)
    }
  }

  renderRequestMeterReadingsSection = () => {
    if(!this.site.registryReqconsEnabled) {
      return <p>Cannot request readings because DOWNLOAD READINGS FROM REGISTRY is not enabled for this site.</p>
    }

    return (
      <>
        <br />
        <DatePicker
          value={this.state.readingRequestInterval.startMonth}
          label='From'
          onChange={this.handleDateFilterChange('startMonth')}
          className={this.props.classes('filterField', 'formControl')}
          monthOnly={true}
        />
        <DatePicker
          value={this.state.readingRequestInterval.endMonth}
          label='To'
          onChange={this.handleDateFilterChange('endMonth')}
          className={this.props.classes('filterField', 'formControl')}
          monthOnly={true}
        />
        <br />
        <Button color='secondary' fullWidth variant='contained' onClick={this.handleRequestReading}>Request reading</Button>
        <br />
      </>
    )
  }

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this site? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.id})
      this.props.history.goBack()
      this.actions.snackbar.show('Site deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete site.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  dependenciesMet(){
    return !!this.site.id
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.match.params.id !== this.id) {
      this.props.onReloadDependent()
    }
  }

  get organisationId(){
    return (this.site.organisation || {}).id
  }

  get id(){
    return this.props.match.params.id
  }

  get site(){
    return this.props.site || {}
  }

  get permissions(){
    return this.organisationPermissions[this.organisationId] || {}
  }

  get organisationPermissions(){
    return ((this.props.permissions || {}).organisation || {})
  }

  get systemPermissions(){
    return ((this.props.permissions || {}).system || {})
  }

  get canWriteSite(){
    return this.permissions.writeSites && this.siteIsInEditableState
  }

  get canReadVerificationMethods(){
    return this.permissions.readVerificationMethods
  }

  // We can edit a site either if we are an admin for all programmes OR
  // the site is not active or submitted for any programmes yet
  get siteIsInEditableState(){
    return Object.entries(this.organisationPermissions).every(([orgId, permissions]) => permissions.writeProgrammes) ||
          (this.site.registrations || []).every(({status}) => status !== 'active' && status !== 'submitted')
  }

  handleRedirectToBaseline = () => {
    this.props.history.push(`/meter_data/baseline_tester?baseline.sites[0].name=${this.site.name}`+
      `&baseline.sites[0].id=${this.site.id}`+
      `&baseline.verificationMethodId=${this.site.verificationMethod.id}`+
      `&baseline.from=${moment().add(-1, 'month').startOf('month').toISOString()}`+
      `&baseline.to=${moment().add(-1, 'month').endOf('month').toISOString()}`+
      `&baseline.filtersCollapsed=true`
    )
  }

  renderLoadTypeRow = loadType =>
    !!this.site.loads[loadType] && (
      <Typography variant='body1' className={this.props.classes.load} key={loadType}>
        <span className={this.props.classes.loadTypeKey}>{loadType}:</span>&emsp;
        {formatLocaleNumeric(this.site.loads[loadType])}kW
      </Typography>
    )

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <Typography variant='h4'>Site - {this.site.name}</Typography>
        <section className={this.props.classes.siteSection}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6'>
              Fields
            </Typography>
            {
              this.canWriteSite &&
              <span>
                Actions:
                <Link to={`/sites/${this.site.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
                <IconButton onClick={this.handleDelete}>
                  <DeleteIcon/>
                </IconButton>
              </span>
            }
            <div className={this.props.classes.tables}>
              <Table className={this.props.classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Name</TableCell>
                    <TableCell>{String(this.site.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Address</TableCell>
                    <TableCell>{String(this.site.address)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>ICP number</TableCell>
                    <TableCell>{String(this.site.icpNumber)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Meter ID</TableCell>
                    <TableCell>{String(this.site.meterId)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>kW Amount</TableCell>
                    <TableCell>{formatLocaleNumeric(this.site.kwAmount)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table className={this.props.classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>GXP</TableCell>
                    <TableCell>
                      {
                        this.systemPermissions.readNetworkTopology ?
                          <Link to={`/gxps/${this.site.gxp.id}`} className={this.props.classes.tableCellLink}>
                            {String(this.site.gxp.code)}
                          </Link> :
                          String(this.site.gxp.code)
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Organisation</TableCell>
                    <TableCell>
                      {
                        this.organisationPermissions[this.site.organisation.id]
                        && this.organisationPermissions[this.site.organisation.id].readOrganisation ?
                          <Link to={`/organisations/${this.site.organisation.id}`} className={this.props.classes.tableCellLink}>
                            {String(this.site.organisation.name)}
                          </Link> :
                          String(this.site.organisation.name)
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Meter Owner</TableCell>
                    <TableCell>{String(this.site.meterOwner.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Distributor</TableCell>
                    <TableCell>{String(this.site.distributor.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Retailer</TableCell>
                    <TableCell>{String(this.site.retailer.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Verification method</TableCell>
                    <TableCell>
                      {String(this.site.verificationMethod.name)}
                      {
                        this.canReadVerificationMethods &&
                        <IconButton onClick={this.handleRedirectToBaseline}>
                          <EyeIcon/>
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Status</TableCell>
                    <TableCell>{String(this.site.status)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Flow direction</TableCell>
                    <TableCell>{String(this.site.flowDirection)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Programmes</TableCell>
                    <TableCell>{this.site.programmes.map(({id, name}) => <Chip key={id} label={name} onClick={() => this.props.history.push(`/programmes/${id}`)} />)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Availabilities</TableCell>
                    <TableCell><AvailabilitiesSummary availabilities={this.props.site.availabilities}/></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
            <div className={this.props.classes.rightPanel}>
              <Typography variant='h6'>Load Types</Typography>
              {this.props.loadTypes.map(this.renderLoadTypeRow)}
              {
                Authorization.organisation.isRoot &&
                <Fragment>
                  <br/>
                  <Typography variant='h6'>Registry settings</Typography>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={this.props.classes.head} variant='head'>Consumer Authorisation Code</TableCell>
                        <TableCell>{this.site.consumerAuthorisationCode}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={this.props.classes.head} variant='head'>Consumer No</TableCell>
                        <TableCell>{this.site.consumerNo}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={this.props.classes.head} variant='head'>Customer Name</TableCell>
                        <TableCell>{this.site.customerName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={this.props.classes.head} variant='head'>Download readings from Registry</TableCell>
                        <TableCell>{!!this.site.registryReqconsEnabled ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  { this.renderRequestMeterReadingsSection() }
                </Fragment>
              }
              {
                Authorization.systemPermissions.readTags &&
                <Fragment>
                  <br/>
                  <Typography variant='h6'>Tags</Typography>
                  {
                    Object.entries(this.site.tags).map(([type, values], tIdx) =>
                      <MultiAutoSuggest
                        readOnly
                        showClearAll={false}
                        key={tIdx}
                        label={type}
                        value={values}
                        labelProvider={value => value}
                      />
                    )
                  }
                </Fragment>
              }
            </div>
        </section>
        <br/>
        <section className={this.props.classes.sites}>
          <Typography variant='h6'>
            Fields
          </Typography>
          <Device.List useCard={false} site={this.site} showAdd={true}/>
        </section>
      </CardContent>
    </Card>
}


const styles = theme => ({
  load: {
    fontSize: "13px",
    padding: "10px 0",
    borderBottom: "1px solid silver",
  },
  loadTypeKey:{
    fontWeight: 'bold'
  },
  tables: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    },
    '& td': {
      background: 'rgba(255,255,255,0.03)',
      padding: '10px 15px',
      '&:last-child': {
        borderRight: '1px solid rgba(255,255,255,0.05)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(255,255,255,0.05)'
      },
      borderTop: '1px solid rgba(255,255,255,0.05)',
      borderBottom: '1px solid rgba(255,255,255,0.05)'
    },
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: 'rgba(255,255,255,0.06) !important',
    borderRight: '1px solid rgba(255,255,255,0.02)'
  },
  siteSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: '2 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: "10px 10px 0 0",
    minWidth: 300,
    marginBottom: 10,
    color: 'white'
  },
  rightPanel: {
    flex: '1 1 0%',
    marginBottom: 10,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  },
  sites: {
    position: 'relative'
  },
  formControl:{
    paddingTop: '16px',
  },
  filterField: {
    paddingLeft: '10px',
    paddingRight: '10px',
    justifyContent: 'center',
  },
  tableCellLink: {
    '&:hover':{
      textDecoration: 'underline'
    }
  },
})

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(Show.requiredPermissions),
  withStyles(styles),
  connect(({sites, loadTypes: { loadTypes }}) => ({...sites, loadTypes: loadTypes.map(({name}) => name)}))
)(Show)
