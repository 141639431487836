import ActionSet from './ActionSet'
import * as Actions from '.'

const resetExceptions = [
  'TokenActions',
  'ResetActions',
  'AppVersionActions'
]
export const actionSetsToReset = () => Object.keys(Actions)
        .filter(key => key.match(/Actions$/))
        .filter(actionSet => !resetExceptions.includes(actionSet))
        .map(as => Actions[as] )

export class ResetActionSet extends ActionSet{

  static initialState = {}

  static resetState(creator, reducer, constants){
    creator(() => dispatch => {
      dispatch({type: constants.ACTION})
      actionSetsToReset().forEach(as => dispatch(as.resetState()))
    })

    reducer({})
  }
}

export default new ResetActionSet()