import React, { Component, Fragment } from 'react'
import { compose, capitalize, deepEqual, deparallelize, formatLocaleDateTime, flatten } from 'utils'
import { connect } from 'react-redux'
import moment from 'moment'
import { connectQueryString, withPermissions } from 'containers/shared'
import withStyles from 'styles'
import { ReportActions } from 'actionsets'
import qs from 'qs'
import { TokenStore } from 'services'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { DatePicker, MultiAutoSuggest, Loader, Pagination } from 'components'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Link from '@material-ui/core/Link'
import * as API from 'api'

import BusinessIcon from '@material-ui/icons/Business'
import SiteIcon from '@material-ui/icons/LocationOn'
import Button from '@material-ui/core/Button'

export class MissingData extends Component{
  constructor(props){
    super(props)
    ReportActions.bindActions(this)
    this.state = {
      names: {},
      expandedLocations: {},
      page:        1,
      totalPages:  1,
    }
  }

  static requiredPermissions = props => ({
    system: ['readTags']
  })

  componentDidMount(){
    this.loadReport()
    this.preloadNames()
  }

  componentDidUpdate(prevProps, prevState) {
    if(!deepEqual(this.props.filter, prevProps.filter)) {
      this.loadReport()
      this.clearExpandedLocations()
    }
  }

  clearExpandedLocations = () => {
    this.setState({expandedLocations: {}})
  }

  get filter() {
    return this.props.filter
  }

  get canReadTags() {
    return (this.props.permissions.system || {}).readTags
  }

  get errors(){
    return flatten([this.props.rest.errors.missingMeterData || []])
  }

  handleFilterChange = member => {
    const modifier = member.split(".").reduceRight(
      (r,field) => (current,event) => ({...current, [field]: r((current || {})[field],event)}),
      (_,{target:{value}}) => value
    )
    return event =>
      this.props.onFilterChange(modifier(this.filter,event))
  }

  handleMultiFilterChange = member => {
    const inner = this.handleFilterChange(member)
    return ({target:{value}}) => inner({target:{value: value.join(",")}})
  }

  handlePageSelected = async page =>{
    this.setState({page}, () => {
      this.loadReport()
    })
  }

  locationKey = (locationType, locationId) => {
    return `${locationType}_${locationId}`
  }

  locationClickHandler = (locationType, locationId) => async (event) => {
    event.preventDefault()
    event.stopPropagation()
    const locationKey = this.locationKey(locationType, locationId)

    if(!this.state.expandedLocations[locationKey]) {
      let result = await API['Reports'].missingMeterDataForLocation(
        {
          meterableType: locationType,
          meterableId: locationId,
          from: this.filter.from,
          to: this.filter.to,
          options: {
            include: 'stream,ranges'
          }
        }
      )
      this.setState({
        expandedLocations: {
          ...this.state.expandedLocations,
          [locationKey]: result.data
        }
      })
    } else {
      this.setState({
        expandedLocations: {
          ...this.state.expandedLocations,
          [locationKey]: undefined
        }
      })
    }
  }

  loadReport = deparallelize(async () => {
    const { from, to, ...others } = this.filter
    await this.actions.missingMeterData({
      from, to,
      filter: others,
      fields: {
        gxps: 'code',
        sites: 'icpNumber,meterId',
      },
      include: 'meterable',
      page: {
        size: 1500,
        number: this.state.page
      }
    }).then(result => {
      const {page, totalPages} = result.meta
      this.setState({page, totalPages})
      return result
    }).catch(error => {
      this.setState({page: 0, totalPages: 0})
    })
  }, this)

  buildReportURL = () => {
    const { from, to, ...others } = this.props.filter
    const params = {
      from: from,
      to: to,
      as: 'csv',
      filter: others,
      Authorization: TokenStore.auth
    }
    const queryString = qs.stringify(params, { arrayFormat: 'brackets' })
    return `/api/reports/missing_meter_data?${queryString}`
  }

  downloadCSV = event => {
    event.preventDefault()
    event.stopPropagation()
    window.open(this.buildReportURL())
  }

  preloadNames = async () => {
    const { from, to, ...others } = this.filter
    const types = Object.keys(others)
    for(let t = 0; t < types.length; t++) {
      let type = types[t]
      let ids = others[type].split(",")
      for(let i = 0; i < ids.length; i++) {
        let {data: {id, name, code}} = await API[capitalize(type)].show({id: ids[i], options: {fields: {[type]: type === 'gxps' ? "code" : "name"}}})
        this.setState(({names, ...state}) => ({...state, names: {...names, [type]: {...names[type], [id]: name || code}}}))
      }
    }
  }

  handleFetchRequested = (type,field) => async (text, callback) => {
    const params = {options: { filter: { [field || "name"]: text, active: true }, fields: {[type]: field || "name"} }}
    const { data } = await API[capitalize(type)].index(params)
    this.setState(({names, ...state}) => ({...state, names: {...names, [type]: data.reduce((a,r) => ({...a, [r.id]:r[field || "name"]}),names[type])}}))
    const already = (this.filter[type] || "").split(",").filter(id => id !== "")
    callback(data.map(({id}) => id).filter(id => !already.includes(id)).slice(0, 5))
  }

  handleTagFetchRequested = async (value, callback) => {
    const { data: values } = await API.TagValues.index({
      options: {
        page: { size: 50, number: 1 },
        filter: { value  },
        include: 'tagType'
      }
    })
    callback(values.filter(v => !(this.filter.tags || []).some(t => t.value === v.value && t.tagType.name === v.tagType.name)).slice(0, 5))
  }

  renderTagLabel = ({value, tagType: { name }}) =>
    <span><strong>{name}</strong>: {value}</span>

  renderStream = stream => {
    const {meterable} = stream
    const {type, id} = meterable || {}

    if(!meterable) {
      return null
    }

    const RowIcon = (type === 'sites') ? SiteIcon : BusinessIcon
    const locationKey = this.locationKey(type, id)
    const missingData = this.state.expandedLocations[locationKey] || []
    const expended = missingData.length !== 0

    const description =
      <Fragment>
        <RowIcon style={{float: 'left', marginRight: 5}}/>
        <div style={{float: 'left'}}>
          {`${meterable.icpNumber || meterable.code}${(meterable.meterId ? ` - ${meterable.meterId}` : '')}`}
        </div>
      </Fragment>
    return(
      <div key={locationKey} className={this.props.classes.row}>
        <Button onClick={this.locationClickHandler(type, id)} variant='contained' color="primary" className={this.props.classes('rowButton')}>
          {description}
        </Button>
        {
          expended &&
          <div className={this.props.classes('buttonInner', 'expanded')}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Flow</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                missingData.map(({stream, ranges}) => {
                  return ranges.map(({id, from, to}) => {
                    return (
                      <TableRow key={`${locationKey}_${id}`}>
                        <TableCell>{stream.flowDirection}</TableCell>
                        <TableCell>{formatLocaleDateTime(from)}</TableCell>
                        <TableCell>{formatLocaleDateTime(to)}</TableCell>
                      </TableRow>
                    )
                  })
                })
              }
              </TableBody>
            </Table>
          </div>
        }
      </div>
    )
  }

  renderSites = site => <Fragment>
    <TableCell>
      <Link href='#' onClick={this.locationClickHandler(site.type, site.id)}>{site.icpNumber}</Link>
    </TableCell>
    <TableCell>{site.meterId}</TableCell>
   </Fragment>

  renderGxps = gxp => <Fragment>
    <TableCell>
      <Link href='#' onClick={this.locationClickHandler(gxp.type, gxp.id)}>{gxp.code}</Link>
    </TableCell>
    <TableCell/>
   </Fragment>

  renderFilterSelect = (type,field) =>
    <MultiAutoSuggest
      label={capitalize(type)}
      className={this.props.classes('filterField', 'formControl')}
      labelProvider={id => (this.state.names[type] || {})[id]}
      debounceWait={200}
      value={(this.filter[type] || "").split(",").filter(id => id !== "")}
      onChange={this.handleMultiFilterChange(type)}
      onSuggestionsFetchRequested={this.handleFetchRequested(type,field)}
     />

  render = () => {
    return (
      <Fragment>
        <section className={this.props.classes.filters}>
          <div className={this.props.classes.filterGroup}>
            <DatePicker
              className={this.props.classes('filterField', 'formControl')}
              value={this.filter.from}
              name='filterFrom'
              onChange={this.handleFilterChange('from')}
              placeholder='From Date'
            />
            <DatePicker
              className={this.props.classes('filterField', 'formControl')}
              value={this.filter.to}
              name='filterTo'
              onChange={this.handleFilterChange('to')}
              placeholder='To Date'
            />
          </div>
          <div className={this.props.classes.filterGroup}>
            { this.canReadTags &&
              <MultiAutoSuggest
                labelProvider={this.renderTagLabel}
                label={"Tags"}
                value={this.filter.tags}
                onChange={this.handleFilterChange('tags')}
                debounceWait={200}
                onSuggestionsFetchRequested={this.handleTagFetchRequested}
              />
            }
          </div>
          <div className={this.props.classes.filters}>
            <Tooltip title="Download report CSV">
              <IconButton onClick={this.downloadCSV}>
                <CloudDownloadIcon/>
              </IconButton>
            </Tooltip>
          </div>
          <div className={this.props.classes.filterGroup}>
            {this.renderFilterSelect("organisations")}
            {this.renderFilterSelect("registrations")}
            {this.renderFilterSelect("sites")}
            {this.renderFilterSelect("gxps","code")}
          </div>
        </section>
        <section>
          {
            this.props.requests.length > 0 ?
              <Loader /> :
              <>
                {this.errors.length > 0 ?
                  <p className={this.props.classes.subHeader}>There are no reports matching the filter!</p> :
                  <>
                    <Pagination totalPages={this.state.totalPages}
                                page={this.state.page}
                                onPageSelected={this.handlePageSelected}
                                style={{}}
                                linkStyle={{}}/>
                    <div className={this.props.classes.rowsContainer}>
                      { this.props.missingData.map(this.renderStream) }
                    </div>
                  </>
                }
              </>
          }
        </section>
      </Fragment>
    )
  }
}

const styles = theme => ({
  filters: {
    paddingBottom: '28px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterGroup: {
    flex: '1 1 auto',
    display: 'flex',
  },
  formControl:{
    paddingTop: '16px',
  },
  filterField: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  rangeList: {
    paddingLeft: '45px'
  },
  locationList: {
  },
  rowsContainer: {
  },
  row: {
    overflow: 'hidden',
    flex: 1,
    marginBottom: 4,
    background: 'rgba(0, 27, 38, 0.5)',
  },
  rowButton: {
    padding: 10,
    whiteSpace: 'pre',
    width: '100%',
    display: 'block',
    textAlign: 'left',
    borderRadius: 0,
  },
  buttonInner: {
    transition: 'max-height 1s ease',
    height: 'auto',
    position: 'relative',
    maxHeight: 0,
    overflow: 'auto',
  },
  expanded: {
    transition: 'max-height 1s ease',
    maxHeight: 1500
  },
  subHeader: {
    textAlign: 'center',
    width: '100%'
  }
})

export default compose(
  withStyles(styles),
  withPermissions(MissingData.requiredPermissions),
  connect(({reports: {missingMeterData, requests, ...rest}}, { filter }) => ({
    filter: {
      from: moment().startOf('month').add(-1, 'month').toISOString(true),
      to: moment().startOf('month').toISOString(true),
      ...filter
    },
    missingData: missingMeterData,
    rest: rest,
    requests
  })),
  connectQueryString('missingData')
)(MissingData)