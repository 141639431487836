import React, {Component, lazy, Suspense} from 'react'
import WidgetContainer from './WidgetContainer'
import withStyles from 'styles'
import { compose, Authorization } from 'utils'
import * as API from 'api'
import moment from 'moment'
import {Loader} from 'components'

const LoadMap = lazy(() => import('containers/load_map'))

export class LoadMapWidget extends Component{

  state = { groupedAvailabilities: {} }

  componentDidMount = () => {
    this.loadAvailabilities()
  }

  loadAvailabilities = async () => {
    const groupedAvailabilities = {}
     try{
       const {  data: groupedAvailabilityByGxp } = await API.Registrations.availability({
        organisationId: this.organisationId,
        options: {
          filter: { 
            time: moment().toISOString(), 
            segmentBy: 'gxp'
          }
        }
      })
      groupedAvailabilities.gxp = groupedAvailabilityByGxp
    }catch(err){
      groupedAvailabilities.gxp = []
    }
    this.setState({groupedAvailabilities})
  }

  componentDidUpdate = prevProps => {
    if(prevProps.organisationId !== this.props.organisationId) {
      this.loadAvailabilities()
    }
  }

  get organisationId(){
    return this.props.organisationId || Authorization.organisationId
  }

  render(){
    return (
      <WidgetContainer classes={this.props.widgetContainerClasses || {}} className={this.props.classes.widgetContainer} width='md'>
        <Suspense fallback={<Loader size={100}/>}>
          <LoadMap scrollWheelZoom={false} groupedAvailabilities={this.state.groupedAvailabilities} showGXPs={true} hideZeroLoadFeatures={true}/>
        </Suspense>
      </WidgetContainer>
    )
  }
}

const styles = theme => ({
  widgetContainer: {
  }
})
export default compose(
  withStyles(styles),
)(LoadMapWidget)
