import React, { Component }                from 'react'
import Switch                              from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

export class LabeledSwitch extends Component{

  render = () => {
    const {fullWidth, label, error, className, helperText, ...props } = this.props
    return (
      <FormControl className={className} fullWidth={!!fullWidth} error={!!error}>
        <FormControlLabel
            label={label}
            control={
              <Switch type='checkbox' {...props}/>
            }
          />
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default LabeledSwitch