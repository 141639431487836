import React, { Component } from 'react'
import { PaymentActions } from 'actionsets'
import { connect } from 'react-redux'
import { compose, formatLocaleNumeric } from 'utils'
import { connectQueryString } from 'containers/shared'
import { Pagination, AutoSuggest } from 'components'
import MuiList from '@material-ui/core/List';
import PaymentIcon from '@material-ui/icons/Payment';
import BCTIIcon from '@material-ui/icons/AttachMoney';
import TickIcon from '@material-ui/icons/Check';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import withStyles from 'styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import Dependent from 'containers/shared/Dependent'
import LinkIcon from '@material-ui/icons/Link'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as API from 'api'

export class List extends Component{

  static propTypes = {

  }

  state = {
    showBCTISelector: false
  }

  dependsOn(){
    return this.loadPayments()
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  constructor(props){
    super(props)
    PaymentActions.bindActions(this)
  }

  componentDidMount = () => {
    this.loadPayments()
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.page !== this.props.page){
      this.loadPayments()
    }
  }

  loadPayments = () => {
    this.actions.index({
      params: { childEventId: this.props.childEvent.id},
      filter: this.props.filter,
      page: this.props.page,
      include: 'bcti'
    })
  }

  get canCreatePayments(){
    return true
  }

  handleDownloadFile = (url) => () =>  window.open(url, "_blank")

  handleShowBCTISelector = paymentId => () => {
    this.setState({showBCTISelector: true, paymentId: paymentId})
  }

  handleHideBCTISelector = () => {
    this.setState({showBCTISelector: false, paymentId: undefined, selectedInvoiceId: undefined})
  }

  handleInvoicesSuggestionsFetchRequested = async (text, callback) => {
    const { data: bctis } = await API.Bctis.index({
      organisationId: this.props.childEvent.registration.organisation.id,
      options: {
        filter: {
          name: text
        }
      }
    })
    callback(bctis)
  }

  handleInvoiceSelected = async ({target: {value: invoice}}) => {
    if(!invoice || invoice.id === this.state.selectedInvoiceId)
      return
    try{
      this.setState({selectedInvoiceId: invoice})
      await API.Payments.update({
        id: this.state.paymentId,
        bctiId: invoice.id
      })
      this.props.onChange && this.props.onChange()
      this.loadPayments()
      this.handleHideBCTISelector()
    }catch(err){
      this.actions.snackbar.show(`The following error occurred while attempting to save: ${err}`)
    }
  }



  renderPaymentListItem = ({id, settlementAmount, performancePercentage, createdAt, active, bcti, cblResultsUrlPath}) =>
    <ListItem className={this.props.classes({active, inactive: !active})}>
      <ListItemIcon>
        {
          bcti ?
            <TickIcon/> :
            <PaymentIcon/>
        }
      </ListItemIcon>
      <ListItemText primary={`$${formatLocaleNumeric(settlementAmount)} - ${formatLocaleNumeric(performancePercentage)}%`} secondary={moment(createdAt).format('DD/MM/YY HH:mm')}/>
      <ListItemSecondaryAction>
        {
          cblResultsUrlPath &&
          <Tooltip title="Download Verification Results">
            <IconButton onClick={this.handleDownloadFile(cblResultsUrlPath)}>
              <CloudDownloadIcon/>
            </IconButton>
          </Tooltip>
        }
        {
          this.canCreatePayments &&
          <Tooltip title="Link payment to invoice">
            <IconButton onClick={this.handleShowBCTISelector(id)}>
              <LinkIcon/>
            </IconButton>
          </Tooltip>
        }
        {
          bcti &&
          <Tooltip title="Download Invoice">
            <IconButton onClick={this.handleDownloadFile(bcti.invoiceUrlPath)}>
              <BCTIIcon/>
            </IconButton>
          </Tooltip>
        }
      </ListItemSecondaryAction>
    </ListItem>

  render = () =>
    <div className={this.props.classes.wrapper}>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
      <MuiList dense>
        {this.props.payments.map(this.renderPaymentListItem)}
      </MuiList>
       <Dialog classes={{paper: this.props.classes.showOverflow}} open={this.state.showBCTISelector} onClose={this.handleHideBCTISelector}>
        <DialogTitle>Select Invoice</DialogTitle>
        <DialogContent className={this.props.classes.showOverflow}>
          <AutoSuggest
            labelProvider={({name}) => name}
            onSuggestionsFetchRequested={this.handleInvoicesSuggestionsFetchRequested}
            onChange={this.handleInvoiceSelected}
          />
        </DialogContent>
      </Dialog>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.props.onPageChange} style={{}} linkStyle={{}}/>
    </div>

}

const styles = theme => ({
  wrapper: {
    minWidth: 300,
    '& > ul li div div': {
      padding: 0
    }
  },
  showOverflow: {
    overflowY: 'visible'
  },
  active: {
    background: 'rgba(0, 255, 0, 0.18)'
  },
  inactive: {
    background: 'rgba(255, 135, 0, 0.22)'
  }
})

export default compose(
  Dependent({loader: true}),
  connectQueryString('payments'),
  withStyles(styles),
  connect(({payments}) => payments)
)(List)