import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import qs from "qs"
import Dependent from 'containers/shared/Dependent'
import { compose, errorStringsFromError } from 'utils'
import { TokenActions } from 'actionsets'
import Typography from '@material-ui/core/Typography'
import { CenteredCard } from 'components'
import CardContent from '@material-ui/core/CardContent';
import { ErrorBanner } from 'components'

export class AcknowledgeNotification extends Component{
  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    this.state = {}
  }

  async dependsOn(){
    this.actions.acknowledgeNotification({token: this.token})
  }

  dependenciesMet(){
    return "acknowledgedNotification" in this.props || this.errors.length > 0
  }

  get errors(){
    return this.props.errors.acknowledge || []
  }

  get qsObject() {
    return this._qsObject || (this._qsObject = qs.parse(this.props.location.search.slice(1), {arrayFormat: 'repeat', allowDots: true}))
  }

  get token() {
    return this.qsObject.token
  }

  render = () =>
    <CenteredCard>
      <CardContent>
        {this.errors.length > 0 &&
          <ErrorBanner>{errorStringsFromError(this.errors)}</ErrorBanner>
        }
        {this.props.acknowledgedNotification &&
          <Fragment>
            <Typography variant='h6'>Notification Acknowledged</Typography>
            <p>{this.props.acknowledgedNotification.title}</p>
            <p>{this.props.acknowledgedNotification.message}</p>
          </Fragment>
        }
      </CardContent>
    </CenteredCard>
}

export default compose(
  Dependent({loader: true}),
  connect(({tokens}) => tokens)
)(AcknowledgeNotification)