import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import MuiTable from '@material-ui/core/Table';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import withStyles from 'styles'

export const CollapsedContext = React.createContext('collapsedState')

export class Table extends Component{

  state = {
    collapsed: false
  }

  static defaultProps = {
    breakpoint: 600
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate = prevProps => {
    try{
      if(this.props.children[1].props.children.length !== prevProps.children[1].props.children.length){
        this.handleResize()
      }
    }catch(err){
      //Pass
    }
  }

  refHandler = (reactNode) => {
    this.domNode = reactNode && ReactDOM.findDOMNode(reactNode)
    this.handleResize()
  }

  handleResize = () => {
    if (this.domNode && this.props.breakpoint < this.domNode.offsetWidth + 50) {
      this.setState({
        collapsed: false
      })
    }
    if (this.domNode && (this.props.breakpoint > this.domNode.offsetWidth || this.domNode.offsetWidth > window.innerWidth - 40)) {
      this.setState({
        collapsed: true
      })
    }
  }


  render = () => {
    const {breakpoint, classes,...props} = this.props
    return (
        <div className={this.props.className} ref={this.refHandler}>
          <CollapsedContext.Provider value={this.state}>
            <MuiTable
              {...props}
              className={
                this.props.classes(
                  'table',
                  {
                    collapsed: this.state.collapsed
                  }
                )
              }
            />
          </CollapsedContext.Provider>
        </div>
    )
  }
}

export const styles = {
  table: {
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: 3,
    '& > tbody > tr': {
      transition: 'backgroundColor 250ms',
      backgroundColor: 'rgba(255,255,255,0.03)',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.06)'
      },
      '&  > td': {
        padding: 10,
        '&:last-child': {
          borderRight: '1px solid rgba(255,255,255,0.05)'
        },
        '&:first-child': {
          borderLeft: '1px solid rgba(255,255,255,0.05)'
        },
        borderTop: '1px solid rgba(255,255,255,0.05)',
        borderBottom: '1px solid rgba(255,255,255,0.05)'
      },
    },
    '& > thead > tr': {
      height: 27,
      '& > th': {
        border: 'none',
        padding: 10,
        wordBreak: 'keep-all',
        '&:last-child': {
          paddingRight: 30
        }
      },
    },
    '&.collapsed': {
      width: 'auto',
      flex: '1',
      marginLeft: -16,
      marginRight: -16,
      '& > tbody': {
        display: 'block',
        width: '100%',
        '& > tr': {
          width: '100%',
          height: 'inherit',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          padding: '10px 10px 20px 10px',
          boxSizing: 'border-box',
          marginBottom: 3,
          borderTop: '1px solid rgba(255,255,255,0.05)',
          borderBottom: '1px solid rgba(255,255,255,0.05)',
          '&:last-child': {
            marginBottom: 0
          },
          '& > td': {
            display: 'block',
            border: 'none',
            width: '100%',
            textAlign: 'right',
            boxSizing: 'border-box',
            paddingBottom: '0 !important',
            paddingRight: '10px !important',
            order: 100,
            '&[data-mobile-label]::before': {
              content: "attr(data-mobile-label)':'",
              display: 'inline-block',
              float: 'left'
            },
            '&.mobile-heading': {
              order: 1,
              '&:first-child': {
                flex: 1,
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '14px',
              },
              width: 'auto',
              flexGrow: 0,
              paddingLeft: '10px !important',
              paddingRight: '10px !important',
            }
          },
        },
      },
      '& thead': {
        display: 'none'
      }
    },
  },
  secondary: {
    '&.collapsed': {
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
    '&:not(.collapsed)': {
      marginBottom: 20,
      '& > thead > tr': {
        height: 35,
        '& > th': {
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.87)'
        }
      },
      '& > tbody > tr': {
        height: 28,
        '& > td': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }
    },
    '& > tbody > tr': {
      backgroundColor: 'transparent',
      borderTop: 'none',
    }
  }
}

export const TableHead = (props) => <MuiTableHead {...props}/>
export const TableBody = (props) => <MuiTableBody {...props}/>
export const TableRow  = (props) => <MuiTableRow  {...props}/>
export const TableCell = (props) => {
  const {className, mobileHeading, mobileLabel, ...rest} = props
  return (
    <MuiTableCell {...rest} className={[className, mobileHeading ? 'mobile-heading' : ''].join(' ')} data-mobile-label={mobileLabel} />
  )
}
TableCell.propTypes = {
  mobileHeading: PropTypes.bool,
  mobileLabel: PropTypes.string,
}

export default withStyles(styles)(Table)