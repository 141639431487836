import EarningsWidget from './EarningsWidget'
import CapacityWidget from './CapacityWidget'
import Em6Widget from './Em6Widget'
import ManagementWidget from './ManagementWidget'
import NotificationWidget from './NotificationWidget'
import OrganisationWidget from './OrganisationWidget'
import ParticipantWidgets from './ParticipantWidgets'
import PerformanceWidget from './PerformanceWidget'
import RegistrationsWidget from './RegistrationsWidget'
import TasksWidget from './TasksWidget'
import UpcomingEventsWidget from './UpcomingEventsWidget'
import WidgetContainer from './WidgetContainer'
import VerticalGroup from './VerticalGroup'
import AvailabilityForecastWidget from './AvailabilityForecastWidget'
import RcpdLoadsWidget from './RcpdLoadsWidget'
import RcpdPeaksWidget from './RcpdPeaksWidget'
import LoadMapWidget from './LoadMapWidget'
import DeviceChartWidget from './DeviceChartWidget'


export {
  EarningsWidget,
  CapacityWidget,
  Em6Widget,
  ManagementWidget,
  NotificationWidget,
  OrganisationWidget,
  ParticipantWidgets,
  PerformanceWidget,
  RegistrationsWidget,
  TasksWidget,
  UpcomingEventsWidget,
  AvailabilityForecastWidget,
  LoadMapWidget,
  RcpdLoadsWidget,
  RcpdPeaksWidget,
  WidgetContainer,
  VerticalGroup,
  DeviceChartWidget,
}