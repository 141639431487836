import React, { Component, Fragment } from 'react'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import { compose, formatLocaleNumeric } from 'utils'
import withStyles from 'styles'
import AvailableDRByLoadType from './AvailableDRByLoadTypeChart'
import RegistrationIcon from '@material-ui/icons/AssignmentTurnedIn'
import Button from '@material-ui/core/Button'

export class RegistrationsResults extends Component{
  state = {
    expanded: {},
    mode: {}
  }

  get report(){
    return this.props.report
  }

  handleToggleExpansion = index => () => {
    this.setState({expanded: {...this.state.expanded, [index]: !this.state.expanded[index]}})
  }

  renderSite = (site) => {
    return (
      <ListItem key={site.id} style={{width: '100%'}}>
        <ListItemText
          primary={`${site.name} - ${formatLocaleNumeric(site.kwAmount / 1000)} MW`}
          secondary={
            <Fragment>
              <span style={{width: 150, display: 'inline-block'}}>ICP: {site.icpNumber}</span> <span>Meter ID: {site.meterId}</span>
            </Fragment>
          }
        />
      </ListItem>
    )
  }

  renderRegistrations = () => {
    return this.report.registrations.map((registration, index) => {
      const expended = !!this.state.expanded[index]
      const description =
        <Fragment>
          <RegistrationIcon style={{float: 'left', marginRight: 5}}/>
          {`${registration.name} — ${(registration.kwAmount  / 1000).toFixed(1)}MW\n`}
        </Fragment>
      return (
        <div key={registration.id} className={this.props.classes.row}>
          <Button onClick={this.handleToggleExpansion(index)}
            variant='contained' color="primary"
            disabled={registration.sites.length === 0}
            className={this.props.classes('rowButton')}>
            {description}
          </Button>
          {
            expended &&
            <div className={this.props.classes('buttonInner', 'expanded')}>
              <List style={{width: '100%'}}>
                { registration.sites.map(this.renderSite) }
              </List>
            </div>
          }
        </div>
      )
    })
  }

  render = () =>
    <div className={this.props.classes.wrapper}>
      <div className={this.props.classes.summarySection} >
        <List dense>
          <ListItem>
            <ListItemText secondary="Registered MW Amount" primary={`${formatLocaleNumeric(this.report.registeredMwAmount)} MW`}/>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Available MW Amount" primary={`${formatLocaleNumeric(this.report.availableMwAmount)} MW`}/>
          </ListItem>
        </List>
        <div style={{maxWidth: 350}}>
          <AvailableDRByLoadType availableKwByLoadType={this.report.availableKwByLoadType}/>
        </div>
      </div>
      <div className={this.props.classes.detailesTable}>
        { this.renderRegistrations() }
      </div>
    </div>
}

const styles = theme => ({
  row: {
    overflow: 'hidden',
    flex: 1
  },
  buttonInner: {
    flex: 1,
    transition: 'max-height 1s ease',
    height: 'auto',
    position: 'relative',
    maxHeight: 0,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& li': {
      width: 200,
    }
  },
  expanded: {
    transition: 'max-height 1s ease',
    maxHeight: 1500
  },
  rowButton: {
    padding: 10,
    width: '100%',
    display: 'block',
    textAlign: 'left',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(0, 27, 38, 0.5)'
    },
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '@media(max-width: 620px)': {
      flexDirection: 'column'
    },
    height: '100%',
    overflow: 'hidden',
  },
  summarySection: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 0 350px',
    overflowY: 'auto',
    '@media(max-width: 620px)': {
      overflowY: 'visible',
    },
    background: "rgba(0, 27, 38, 0.5)",
    borderLeft: `3px solid ${theme.palette.secondary.light}`
  },
  detailesTable: {
    height: '100%',
    flex: 40,
    overflowY: 'auto',
    boxShadow: 'inset 1px -1px 5px rgba(0, 0, 0, 0.15)'
  }
})

export default compose(
  withStyles(styles)
)(RegistrationsResults)