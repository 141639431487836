import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { PasswordPolicy } from 'utils'

const GradePassword = Field => class extends Component {

  static propTypes = {
    value:    PropTypes.string,
    onChange: PropTypes.func,
    style:    PropTypes.object,
    error:    PropTypes.bool,
    onAcceptanceChange: PropTypes.func
  }

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    if("value" in nextProps && nextProps.value !== prevState.password) {
      return { password: nextProps.value, feedback: PasswordPolicy(nextProps.value) }
    } else {
      return null
    }
  }

  componentDidMount = () => {
    if(this.props.onAcceptanceChange) {
      this.props.onAcceptanceChange(this.state.feedback.accept)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.onAcceptanceChange && this.state.feedback.accept !== prevState.feedback.accept) {
      this.props.onAcceptanceChange(this.state.feedback.accept)
    }
  }

  setPassword = password => this.setState({password, feedback: PasswordPolicy(password)})

  renderHelperTextRow = (item, reqd) => (
    <li style={{
      color: item.satisfied ? "green" : (reqd ? "red" : "grey"),
      listStyleType: item.satisfied ? "\"" + String.fromCodePoint(0x2713) + "\"" : (reqd ? "\"" + String.fromCodePoint(0x274C) + "\"": "circle")
    }}>
      {item.name}
      {item.subclauses &&
        <ul>
          {item.subclauses.map(reqd && (!item.satisfied) ?
            s => this.renderHelperTextRow(s,true) :
            s => this.renderHelperTextRow(s,false)
          )}
        </ul>
      }
    </li>
  )

  get helperText() {
    return (
      <Fragment>
        {this.props.helperText}
        <ul>
          {this.state.feedback.feedback.map(this.state.feedback.accept ?
            item => this.renderHelperTextRow(item, false) :
            item => this.renderHelperTextRow(item, true)
          )}
        </ul>
      </Fragment>
    )
  }

  render = () => {
    const { error, onChange, onAcceptanceChange,...props } = this.props
    return (
      <Field
        type="password"
        {...props}
        value={this.state.password}
        onChange={ event => {
          if(typeof(props.value) !== "string") {
            this.setPassword(event.target.value)
          }
          if(onChange) {
            onChange(event)
          }
        }}
        error={!!(this.state.password && (error || !(this.state.feedback.accept)))}
        helperText={this.state.password && this.helperText}
      />
    )
  }
}

export { GradePassword }
export default GradePassword(TextField)