import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import Tooltip from '@material-ui/core/Tooltip'
import { UserNotificationActions, SnackbarActions } from 'actionsets'
import { compose, errorStringsFromError } from 'utils'
import NotificationListItem from './NotificationListItem'

const NOTIFICATIONS_TOP_N = parseInt(process.env.REACT_APP_NOTIFICATIONS_TOP_N, 10) || 6

export class ShortPendingList extends Component {

  static propTypes = {
    onViewAll: PropTypes.func.isRequired
  }

  static defaultProps = {
    onViewAll: () => {}
  }

  constructor(props) {
    super(props)
    UserNotificationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')

    this.state = {
      failed: false,
      loading: false,
    }
  }

  componentDidMount = () => {
    this.loadNotifications()
  }

  loadNotifications = async () => {
    try {
      this.setState({failed: false, loading: true})
      await this.actions.fetchShortPendingList(NOTIFICATIONS_TOP_N)
    } catch(error) {
      this.setState({failed: true})
      this.actions.snackbar.show(errorStringsFromError(error).join(', '))
    } finally {
      this.setState({loading: false})
    }
  }

  get notifications(){
    return this.props.notifications.filter(({ missingSubject }) => !missingSubject)
  }

  toggleDoneHandler = (notification) => async () => {
    try{
      await this.actions.markDone(notification, !notification.done)
      await this.actions.updateShortPendingListCount()
    } catch(error) {
      this.actions.snackbar.show(errorStringsFromError(error).join(', '))
    }
  }

  selectHandler = (notification) => () => {
    const { subjectUriPath } = notification
    this.props.history.push(subjectUriPath)
  }

  renderNotification = (notification) =>
    <NotificationListItem key={notification.id} notification={notification}
      onSelect={ this.selectHandler(notification) } onToggleDone={ this.toggleDoneHandler(notification) }/>

  render = () => {
    return (
      <List>
        { this.notifications.map(this.renderNotification) }
        { (this.notifications.length === 0 && !this.state.loading) &&
          <ListItem className={this.props.classes.subHeader}>
            <ListItemText className={this.props.classes.subHeaderContent} primary='There are no pending notifications!' />
          </ListItem>
        }
        <ListItem button className={this.props.classes.subHeader} onClick={(event) => this.props.onViewAll()}>
          <Tooltip title="Refresh" placement="bottom-end">
            <IconButton onClick={ (event) => {
              event.stopPropagation()
              this.loadNotifications()
            } }>
              <RefreshIcon/>
            </IconButton>
          </Tooltip>
          <ListItemText className={this.props.classes.subHeaderContent} primary='See all notifications' />
        </ListItem>
      </List>
    )
  }
}

const styles = theme => ({
  subHeader: {
    textAlign: 'center'
  },
  subHeaderContent: {
    width: '100%'
  },
})

export default compose(
  connect(({userNotifications: { shortPendingList }}) => ({ notifications: shortPendingList }) ),
  withRouter,
  withStyles(styles)
)(ShortPendingList)