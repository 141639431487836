import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubstationActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, TaggedSearchField, LabeledSwitch } from 'components'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SubstationIcon from '@material-ui/icons/BrokenImage'
import { compose, errorStringsFromError, throttle } from 'utils'
import { withRouter } from 'react-router-dom'
import { connectQueryString } from 'containers/shared'
import { withPermissions } from 'containers/shared'
import { FABAbsolute } from 'components'

export class List extends Component{

  static requiredPermissions = props => {
    const organisationId = props.organisationId || props.match.params.id
    return {
      organisation: {
        ...organisationId && { [organisationId]: ['writeSubstations'] }
      }
    }
  }

  constructor(props){
    super(props)
    SubstationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  componentDidMount(){
    return this.loadSubstations()
  }

  componentDidUpdate = prevProps => {
    if (this.props.organisationId !== prevProps.organisationId) {
      this.loadSubstations()
    }
  }

  loadSubstations = throttle(() => {
    return this.actions.index({
      params: { organisationId: this.organisationId },
      clearExisting: true,
      pageSize: 10,
      page: this.props.page,
      filter: this.props.filter,
      fields: {substations: 'name,kwAmount'}
    })
  })

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  handleShowSubstation = id => () => {
    this.props.history.push(`/substations/${id}`)
  }

  handleEditSubstation = id => event => {
    this.props.history.push(`/substations/${id}/edit`)
    event.stopPropagation()
  }

  handleDeleteSubstation = id => event => {
    this.actions.destroy({id})
                .then(this.loadSubstations)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  handleFilterChanged = async rawFilter => {
    const filter = {...rawFilter, tags: {}};
    (rawFilter.tags || []).forEach(({value, tagType: { name }}) => {
      if(!filter.tags[name]) filter.tags[name] = []
      filter.tags[name].push(value)
    })
    await this.props.onFilterChange(filter)
    this.loadSubstations()
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadSubstations()
  }

  get organisationId(){
    return this.props.organisationId || this.props.match.params.id
  }

  get substations(){
    return this.props.substations
  }

  get permissions(){
    return (((this.props.permissions || {}).organisation || {})[this.organisationId] || {})
  }


  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  renderSubstationListItem = ({id, name, kwAmount}) =>
    <ListItem button onClick={this.handleShowSubstation(id)} key={id}>
      <ListItemIcon>
        <SubstationIcon />
      </ListItemIcon>
      <ListItemText primary={name} secondary={`${kwAmount}kW`}/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <TaggedSearchField onChange={this.handleFilterChanged} context={this.props.filter}>
          {this.organisationId &&
              <LabeledSwitch checked={!('includeDescendantOrganisations' in this.props.filter) || this.props.filter.includeDescendantOrganisations === 'true'} label="Include nested organisations" member="includeDescendantOrganisations" />
          }
        </TaggedSearchField>
        <MuiList dense>
          {this.substations.map(this.renderSubstationListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        {
          this.permissions.writeSubstations &&
          <FABAbsolute color='primary' onClick={() => this.props.history.push(`/organisations/${this.organisationId}/substations/new`)}>
            <AddIcon/>
          </FABAbsolute>
        }
      </CardContent>
    </Card>
}

export default compose(
  withPermissions(List.requiredPermissions),
  connectQueryString('substations'),
  withRouter,
  connect(({substations}) => substations)
)(List)