import React, { Component, Fragment }      from 'react'
import Button                              from '@material-ui/core/Button'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Dependent                           from 'containers/shared/Dependent'
import InstanceFormMixin                   from 'containers/shared/InstanceFormMixin'
import TextField                           from '@material-ui/core/TextField'
import Typography                          from '@material-ui/core/Typography'
import InputAdornment                      from '@material-ui/core/InputAdornment'
import withStyles                          from 'styles'
import { compose, Authorization }          from 'utils'
import { connect }                         from 'react-redux'
import { FormContext, Tagger,
         LabeledSelect, AutoSuggest }      from 'components'
import { SubstationActions, GxpActions,
         VerificationMethodActions,
         LoadTypeActions }                 from 'actionsets'
import { Availabilities } from 'components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import * as API from 'api'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    this.relationshipAttributes = ['gxp', 'retailer', 'distributor', 'meterOwner', 'verificationMethod','availabilities']
    SubstationActions.bindActions(this)
    GxpActions.bindActions(this, 'gxps')
    VerificationMethodActions.bindActions(this, 'verificationMethods')
    LoadTypeActions.bindActions(this, 'loadTypes')
    this.state = {
      hasAvailabilities: null,
      availabilitiesUpdates: null
    }
  }

  dependsOn(){
    const required = [
      this.actions.loadTypes.index()
    ]
    required.push(this.editMode ?
      this.actions.show(this.objectId, {include: 'gxp,distributor,meterOwner,verificationMethod,retailer,flowDirection,organisation,availabilities'}) :
      this.actions.set()
    )
    return Promise.all(required)
  }

  componentDidMount = () => {
    this.actions.verificationMethods.index({
      pageSize: 300,
      page: 0,
      filter: { applicableTo: this.organisationId  },
      fields: {verificationMethods: 'name'}
    })
  }

  handleFetchGxps = async (text, callback) => {
    const { data: gxps } = await API.Gxps.index({
      options: {
        filter: {
          code: text
        }
      }
    })
    callback(gxps)
  }

  onSaveRedirect = ({data: {id}}) => `/substations/${id}`

  handleAvailabilitiesChanged = ({updates, hasErrors}) => {
    this.setState({availabilitiesUpdates: updates, availabilitiesHasErrors: hasErrors})
  }

  handleToggleHasAvailabilities = (event, checked) => {
    this.setState({ hasAvailabilities: checked })
  }

  get formObject(){
    let availabilitiesAttributes = {}
    if(this.state.hasAvailabilities === false) {
      availabilitiesAttributes = { availabilitiesAttributes: this.availabilities.map(({id}) => {
        return {id, _destroy: true}
      })}
    } else if(this.state.availabilitiesUpdates) {
      availabilitiesAttributes = { availabilitiesAttributes: this.state.availabilitiesUpdates.map(({weekDays, ...rest}) => {
        return {week_days: weekDays, ...rest}
      })}
    }
    return {
      organisationId: this.organisationId,
      status: 'active',
      ...this.props.substation,
      ...this.state.formAttributes,
      ...availabilitiesAttributes
    }
  }

  get organisationId(){
    return this.props.match.params.organisationId || (this.props.substation.organisation || {}).id
  }

  get verificationMethods(){
    return this.reduceNameIdPairs(this.props.verificationMethods)
  }


  get hasAvailabilities() {
    return (this.state.hasAvailabilities === null) ? this.availabilities.length !== 0 : this.state.hasAvailabilities
  }

  get availabilities() {
    return this.props.substation.availabilities || []
  }

  handleFetchOrganisationByType = type => async (text, callback) => {
    const { data: gxps } = await API.Organisations.indexType({
      type,
      options: {
        filter: {
          name: text
        }
      }
    })
    callback(gxps)
  }

  get availabilitiesErrors ()  {
    const errContext = this.errorContext || {}
    return errContext['availabilities']
  }

  renderLoadInput = loadType =>
    <TextField
      member={`loads.${loadType}`}
      fullWidth
      key={loadType}
      label={loadType}
      type='number'
      defaultValue={0}
      InputProps={
        {
          inputProps: {
            min: 0
          },
          endAdornment: (
            <InputAdornment position="end">
              kW
            </InputAdornment>
          )
        }
      }/>

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Substation - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent className={this.props.classes.cardContent}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6' className={this.props.classes.headerFields}>
              Fields
            </Typography>
            <TextField  required fullWidth member='name'/>
            <TextField  disabled required type='number' fullWidth member='kwAmount' value={Object.values(this.formObject.loads).reduce((agg, v) => agg + (parseFloat(v) || 0), 0)}/>
            <LabeledSelect
              required fullWidth
              member='verificationMethod.id'
              errorMember='verificationMethod'
              options={this.verificationMethods}
              label="Verification Methodology"
            />
            <AutoSuggest
              required
              fullWidth
              member='gxp'
              labelProvider={(({code}) => code)}
              onSuggestionsFetchRequested={this.handleFetchGxps}
            />
            <AutoSuggest
              required
              fullWidth
              member='distributor'
              labelProvider={(({name}) => name)}
              onSuggestionsFetchRequested={this.handleFetchOrganisationByType('distributors')}
            />
            <AutoSuggest
              required
              fullWidth
              member='meterOwner'
              labelProvider={(({name}) => name)}
              onSuggestionsFetchRequested={this.handleFetchOrganisationByType('meter_owners')}
            />
            <LabeledSelect
              required fullWidth member='status'
              options={{active: 'Active', inactive: 'Inactive'}}
            />
            <LabeledSelect
              required fullWidth member='flowDirection'
              options={{X: 'X', I: 'I', 'X-I': 'X-I'}}
            />
            <div className={this.props.classes.availabilities}>
              <div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={this.handleToggleHasAvailabilities} type='checkbox' checked={this.hasAvailabilities} />
                    }
                    label="Has availabililties"
                  />
                </div>
                <Availabilities open={this.hasAvailabilities} availabilities={this.availabilities} readonly={false} onChange={this.handleAvailabilitiesChanged}
                                error={!!this.availabilitiesErrors} helperText={this.availabilitiesErrors}/>
              </div>
            </div>
          </div>
          <div className={this.props.classes.tags}>
            <Typography variant='h6'>
              Load Types
            </Typography>
            {
              this.props.loadTypes.map(this.renderLoadInput)
            }
            <hr/>
            {
              Authorization.systemPermissions.writeTags &&
              <Fragment>
                <Typography variant='h6'>
                  Tags
                </Typography>
                <Tagger member='tags'/>
              </Fragment>
            }
          </div>
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit' disabled={this.state.availabilitiesHasErrors}>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  headerFields: {
    color: 'white',
    flex: '1 0 100%',
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    padding: 20,
    margin: '0 auto'
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 10,
    minWidth: 300,
    marginBottom:10,
    '& > div': {
      flex: '1 1 auto'
    }
  },
  tags: {
    flex: 1,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  },
  availabilities: {
    marginTop: 24
  },
})

export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({
    substations,
    gxps: { gxps },
    loadTypes: { loadTypes },
    organisations: { organisationsByType },
    verificationMethods: { verificationMethods }
  }) => ({...substations, gxps, organisationsByType, verificationMethods, loadTypes: loadTypes.map(({name}) => name)})),
)(Form)