import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'
import { UserRoleActions } from '.'

export class UserActionSet extends CrudActionSetBase('user'){

  static role(creator, reducer, constants){
    constants.defineRequestConstants()

    creator((id, { fields=null, include=null, filter=null }={}) => async dispatch => {
      const { data: role } = await dispatch({
        type: constants.ACTION,
        promise: API.Users.role({id, options: { ...filter && {filter}, ...fields && {fields}, ...include && {include} }})
      })
      return dispatch(UserRoleActions.set(role))
    })

    reducer({
    })

  }
}
export default new UserActionSet()