import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RegistrationActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, TaggedSearchField, LabeledSwitch, StatusFilter } from 'components'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RegistrationIcon from '@material-ui/icons/AssignmentTurnedIn'
import { compose, errorStringsFromError, Authorization, throttle, formatLocaleNumeric } from 'utils'
import { withRouter } from 'react-router-dom'
import { connectQueryString } from 'containers/shared'
import Tooltip from '@material-ui/core/Tooltip'
import * as API from 'api'
import { withPermissions } from 'containers/shared'
import { FABAbsolute } from 'components'
import withStyles from 'styles'

const defaultFilter = {
  live: true,
  status: 'active,submitted'
}

export class List extends Component{

  static requiredPermissions = (props) => {
    const inOrganisationScope = !!props.match.url.match(/organisation/)
    const organisationId = props.organisationId || (inOrganisationScope ? props.match.params.id : null) || props.programme.organisation.id
    return {
      organisation: {
        ...organisationId && {[organisationId]: ['createRegistrations','writeProgrammes']}
      }
    }
  }

  static defaultProps = {
    canCreate: true
  }

  constructor(props){
    super(props)
    RegistrationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    this.loadRegistrations()
  }

  state = {
    page: 1
  }

  async componentDidMount(){
    const [
      { meta: { count: sitesCount } },
      { meta: { count: subsCount } }
    ] = await Promise.all([
      API.Sites.index({organisationId: this.organisationId, options: { page: { number: 0, size: 0 }}}),
      API.Substations.index({organisationId: this.organisationId, options: { page: { number: 0, size: 0 }}}),
      this.loadRegistrations()
    ])
    this.setState({registerablesCount: subsCount + sitesCount})
  }

  componentDidUpdate = prevProps => {
    if (this.props.organisationId !== prevProps.organisationId) {
      this.loadRegistrations()
    }
  }

  loadRegistrations = throttle(() => {
    return this.actions.index({
      params: this.params,
      clearExisting: true,
      page: this.props.page,
      filter: {
        ...this.filter,
        ...this.programmeId && {programme: this.programmeId}
      },
      pageSize: 10,
      include: 'sites,programme',
      fields: {
        registrations: 'name,status,readOnly,programme,sites,kwAmount,weeklyAvailabilityBitmask',
        programmes: 'name',
        sites: 'kwAmount'
      }
    })
  })

  get params(){
    if(this.inProgrammeScope && this.organisationPermission.writeProgrammes)
      return {}
    return { organisationId: this.organisationId }
  }

  get filter(){
    return {...defaultFilter, ...this.props.filter }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  handleFilterChanged = async rawFilter => {
    const filter = {...rawFilter, tags: {}};
    (rawFilter.tags || []).forEach(({value, tagType: { name }}) => {
      if(!filter.tags[name]) filter.tags[name] = []
      filter.tags[name].push(value)
    })
    await this.props.onFilterChange(filter)
    this.loadRegistrations()
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadRegistrations()
  }

  showRegistration = id => () => {
    this.props.history.push(`/registrations/${id}`)
  }

  editRegistration = id => event => {
    this.props.history.push(`/registrations/${id}/edit`)
    event.stopPropagation()
  }

  deleteRegistration = id => event => {
    this.actions.destroy({id})
                .then(this.loadRegistrations)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get inProgrammeScope(){
    return !!this.props.match.url.match(/programme/)
  }

  get inOrganisationScope(){
    return !!this.props.match.url.match(/organisation/)
  }

  get programmeId(){
    return this.props.programmeId || (this.inProgrammeScope ? this.props.match.params.id : null)
  }

  get organisationId(){
    return this.props.organisationId || (this.inOrganisationScope ? this.props.match.params.id : null) || this.props.programme.organisation.id
  }

  get registrations(){
    return this.props.registrations
  }

  get canAddRegistrations(){
    return this.props.canCreate && this.organisationPermission.createRegistrations && (!this.props.programme || this.props.programme.active)
  }

  get organisationPermission(){
    return ((this.props.permissions.organisation || {})[this.organisationId] || {})
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  handleNewRegistration = () => {
    const query = this.programmeId ? `programmeId=${this.programmeId}` : ''
    this.inOrganisationScope ?
      this.props.history.push(`/organisations/${this.organisationId}/registrations/new`) :
      this.props.history.push(`/organisations/${Authorization.organisationId}/registrations/new?${query}`)
  }

  renderRegistrationListItem = ({id, name, status, endDate, readOnly, kwAmount, programme: { name: programmeName }={name: ''}, sites}) =>
    <ListItem button onClick={this.showRegistration(id)} key={id}>
      <ListItemIcon>
        <RegistrationIcon className={this.props.classes(status)}/>
      </ListItemIcon>
      <ListItemText primary={name} secondary={`${programmeName} - ${formatLocaleNumeric(kwAmount)}kW`}/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderAddButton = () =>
    <Tooltip title={this.state.registerablesCount ? '' : 'You need to create a Site in order to create a Registration.'}>
      <FABAbsolute disabled={!this.state.registerablesCount} color='primary' onClick={this.handleNewRegistration}>
        <AddIcon/>
      </FABAbsolute>
    </Tooltip>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <TaggedSearchField defaultFilter={defaultFilter} onChange={this.handleFilterChanged} context={this.props.filter}>
          <LabeledSwitch checked={this.props.filter.live !== 'false'} label='Hide Expired' member='live'/>
          {this.organisationId &&
            <LabeledSwitch checked={!('includeDescendantOrganisations' in this.props.filter) || this.props.filter.includeDescendantOrganisations === 'true'} label="Include nested organisations" member="includeDescendantOrganisations" />
          }
          <StatusFilter member='status' statuses="submitted,active,inactive" />
        </TaggedSearchField>
        <MuiList dense>
          {this.registrations.map(this.renderRegistrationListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        {
          this.canAddRegistrations && this.renderAddButton()
        }
      </CardContent>
    </Card>
}

const styles = {
  expired: {
    color: 'rgb(222, 53, 11)'
  },
  draft: {
    color: 'grey'
  },
  submitted: {
    color: 'orange'
  },
  inactive: {
    color: 'red'
  }
}

export default compose(
  connectQueryString('registrations'),
  withPermissions(List.requiredPermissions),
  withStyles(styles),
  withRouter,
  connect(({registrations}) => registrations)
)(List)