import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { humanize, compose } from 'utils'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'

export class PermissionsRow extends Component{

  static propTypes = {
    values: PropTypes.array,
    value: PropTypes.array,
    onChange: PropTypes.func
  }

  static defaultProps = {
    value: {}
  }

  handlePermissionToggled = permission => () => {
    if(this.isPermissionEnabled(permission)){
      this.props.onChange({target: { value: {...this.props.value, [permission]: false }}})
    }else{
      this.props.onChange({target: { value: {...this.props.value, [permission]: true }}})
    }
  }

  isPermissionEnabled = permission => {
    return !!this.props.value[permission]
  }

  render = () =>
    <div>
      {this.props.values.map(permission => {
        return (
          <Button
            variant='contained'
            className={this.props.classes('button', {enabled: this.isPermissionEnabled(permission)})}
            onClick={this.handlePermissionToggled(permission)}
          >
            {humanize(permission)}
          </Button>
        )
      })}
    </div>

}

const styles = theme => ({
  button: {
    boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.1)',
    border: '1px solid white',
    borderRadius: 0,
    color: 'white',
    textShadow: '1px 1px #7070704d',
    fontWeight: 'bold',
    width: '25%',
    height: 50,
    fontSize: 10,
    background: '#a39f9f',
    '&:hover':{
      background: '#b2bdc0'
    }
  },
  enabled: {
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.dark
    }
  }
})

export default compose(
  withStyles(styles)
)(PermissionsRow)