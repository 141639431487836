import React from 'react'
import store from 'store'
import * as Containers from 'containers'

import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider }         from 'react-redux'
import MomentUtils          from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Router }           from 'react-router-dom';
import { history }          from 'utils';
import { theme }            from 'styles'

export const AppContainer = () =>
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <Containers.App/>
        </MuiThemeProvider>
      </Router>
    </MuiPickersUtilsProvider>
  </Provider>

export default AppContainer