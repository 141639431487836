import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GxpActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import { withPermissions } from 'containers/shared'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { compose, Authorization } from 'utils'
import withStyles from 'styles'
import { MultiAutoSuggest } from 'components'

export class Show extends Component{
  static requiredPermissions = () => ({
    system: ['writeNetworkTopology']
  })

  constructor(props){
    super(props)
    GxpActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id, {include: 'region', fields: {gxps: 'name,code,tags,region,participantCode,active',regions: 'name'}})
  }

  dependenciesMet(){
    return !!this.gxp.id
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.match.params.id !== this.id) {
      this.actions.show(this.id, {include: 'region', fields: {gxps: ['name','code','tags','region'], regions: ['name']}})
    }
  }

  get id(){
    return this.props.match.params.id
  }

  get gxp(){
    return this.props.gxp
  }

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <Typography variant='h4'>GXP - {this.gxp.code}&nbsp;<small>{this.gxp.name}</small></Typography>
        <section className={this.props.classes.gxpSection}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6'>
              Fields
            </Typography>
            {this.props.permissions.system.writeNetworkTopology &&
              <Link to={`/gxps/${this.gxp.id}/edit`}>
                Actions:
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
            }
            <div className={this.props.classes.tables}>
              <Table className={this.props.classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Name</TableCell>
                    <TableCell>{String(this.gxp.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Code</TableCell>
                    <TableCell>{String(this.gxp.code)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Status</TableCell>
                    <TableCell>{this.gxp.active ? 'active' : 'inactive'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Region</TableCell>
                    <TableCell>{String(this.gxp.region ? this.gxp.region.name : '-')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Participant Code</TableCell>
                    <TableCell>{this.gxp.participantCode}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
          {
            Authorization.systemPermissions.readTags &&
            <div className={this.props.classes.tags}>
              <Typography variant='h6'>
                Tags
              </Typography>
              {
                Object.entries(this.gxp.tags).map(([type, values], tIdx) =>
                  <MultiAutoSuggest
                    readOnly
                    showClearAll={false}
                    key={tIdx}
                    label={type}
                    value={values}
                    labelProvider={value => value}
                  />
                )
              }
            </div>
          }
        </section>
      </CardContent>
    </Card>
}


const styles = theme => ({
  tables: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    },
    '& td': {
      background: 'rgba(255,255,255,0.03)',
      padding: '10px 15px',
      '&:last-child': {
        borderRight: '1px solid rgba(255,255,255,0.05)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(255,255,255,0.05)'
      },
      borderTop: '1px solid rgba(255,255,255,0.05)',
      borderBottom: '1px solid rgba(255,255,255,0.05)'
    },
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: 'rgba(255,255,255,0.06) !important',
    borderRight: '1px solid rgba(255,255,255,0.02)'
  },
  gxpSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: '2 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: "10px 10px 0 0",
    minWidth: 300,
    marginBottom: 10
  },
  tags: {
    flex: '1 1 0%',
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300,
    margin: '10px 0'
  }
})


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(Show.requiredPermissions),
  connect(({gxps}) => gxps),
  withStyles(styles),
)(Show)