import React, { Component } from 'react'
import ReactAutosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from 'styles'
import {debounce, eventKeyMatches, KEYS} from 'utils'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import InputAdornment from '@material-ui/core/InputAdornment';
import { compose, uuid, flatten } from 'utils'

export class MultiAutoSuggest extends Component {
  static defaultProps = {
    inputProps:                  {},
    onSuggestionsFetchRequested: () => {},
    onChange:                    () => {},
    labelProvider:         ({name}) => name || '',
    valueProvider:            value => [value],
    loadWhenEmpty:               true,
    showClearAll:                true,
    readOnly:                    false,
    showLoader:                  true,
    value:                       [],
    debounceWait:                300,
    deleteOnClick:               false,
    expandsUpwards:              false
  }

  constructor(props) {
    super(props)
    this.uniqueId = `mas-${uuid()}`
    this.state = {
      value: '',
      suggestions: [],
      loading: 0
    }
  }

  get value(){
    return flatten([this.props.value || []])
  }

  handleChange = (event, { newValue: value }) => {
    if(typeof value === 'string')
      this.setState({value})
  }

  handleFocus = () => {
    this.setState({focussed: true})
    this.props.loadWhenEmpty && this.onSuggestionsFetchRequested()
  }

  handleBlur = event => {
    this.setState({focussed: false})
    this.setState({value: '', suggestions: []})
  }

  handleSuggestionSelected = (event, {suggestion}) => {
    const value = this.value.concat(this.props.valueProvider(suggestion))
    const nextSuggestions = this.state.suggestions.filter(s => s !== suggestion)
    this.setState({
      value: '',
      suggestions: nextSuggestions
    })
    this.props.onChange({target: { value }})
  }

  handleRequestDelete = (toDelete) => event => {
    if(this.props.readOnly) return
    const value = this.value.filter(suggestion => suggestion !== toDelete)
    this.props.onChange({target: { value }})
    this.setState(
      this._focus ? this.onSuggestionsFetchRequested : null
    )
  }

  handleClearValues = event => {
    this.props.onChange({target: { value: [] }})
    event.preventDefault()
  }

  finishLoad = (loadCounter=this.state.loading - 1) =>
    setTimeout(() => this.setState({loading: Math.max(0, loadCounter)}), 100)

  preventDefault = event =>
    event.preventDefault()

  onSuggestionsFetchRequested = debounce(() => {
    this.props.onSuggestionsFetchRequested(this.state.value, (newValues) => {
        this.finishLoad()
        this.setState({
          suggestions: newValues
        })
      },
      this.finishLoad
    )
    this.setState({loading: this.state.loading + 1})
  }, this.props.debounceWait)

  onSuggestionsClearRequested = () => {
    this.setState({suggestions: []})
  }

  getSectionSuggestions = ({suggestions, className}) =>
    suggestions.map(s => ({...s, className}))

  highlightMatches = (elm, query) => {
    if(typeof elm === 'string'){
      return parse(elm, match(elm, query)).map((part, index) => part.highlight ?
        <span key={index} className={this.props.classes.match}>{part.text}</span>:
        <span key={index}>{part.text}</span>
      )
    }
    if(!(elm && elm.type)) {
      return elm
    }
    return React.cloneElement(elm, {
      children: React.Children.map(elm.props.children, child => this.highlightMatches(child, query))
    })
  }

  renderSuggestion = (suggestion, { query, isHighlighted })  => {
    const label = this.props.labelProvider(suggestion, 'suggestion', this.handleSuggestionSelected.bind(null, {}, {suggestion}))
    const highlightedMatches = this.highlightMatches(label, query)

    return (
      <MenuItem
        className={this.props.classes('suggestedItem', this.props.classes.suggestedItem, {highlighted: isHighlighted})}
        selected={isHighlighted}>
        <div>
          {highlightedMatches}
        </div>
      </MenuItem>
    )
  }

  renderSuggestionsContainer = ({ containerProps, children }) =>
    {
      return (
        this.state.focussed &&
        <div {...containerProps} className={this.props.classes('container', 'suggestionsContainer', this.props.classes.suggestionsContainer, { expandsUpwards: this.props.expandsUpwards})}>
          {(this.state.loading && this.props.showLoader) ? <LinearProgress style={{marginBottom: -4, zIndex: 500}} /> : false}
          {children}
        </div>
      )
    }

  renderSectionTitle = ({title}) =>
    <Typography className={this.props.classes.sectionTitle} variant='subtitle1'>{title}</Typography>

  renderInput = ({ref, classes, ...props}) => {
    const autoCompleteSink = {opacity: 0, float: 'left', border: 'none', height: '0', width: '0'}
    return (
      <div className={this.props.classes('inputContainer', this.props.classes.inputContainer)}>
        <input type="password" name='autoCompleteSink' autoComplete='new-password' tabIndex='-1' style={autoCompleteSink} />
        <TextField
          inputRef={inputRef =>{
            this._inputRef = inputRef
            ref(inputRef)
          }}
          {...props}
          disabled={this.props.readOnly}
          className={this.props.classes('autoSuggestInput', this.props.classes.input)}
          InputProps={{
            name: this.uniqueId,
            autoComplete: this.uniqueId,
            disabled:this.props.readOnly,
            endAdornment: this.props.showClearAll && (
              <InputAdornment position="end">
                <IconButton disabled={this.props.readOnly} onMouseDown={this.preventDefault} onClick={this.handleClearValues} color="primary" aria-label="Clear values" style={{margin: -6}}>
                  <CloseIcon/>
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    )
  }

  render = () => {
    const {
        className, valueProvider, labelProvider, classNames,
        onSuggestionsFetchRequested, onChange, multiSection,
        classes, loadWhenEmpty, showLoader, showClearAll, debounceWait,
        selectOnEnter, deleteOnClick, expandsUpwards, ...inputProps } = this.props
    const { onKeyDown } = inputProps
    const onKeyDownWrapper = (event) => {
      if(eventKeyMatches(event, KEYS.enter)){
        if(this.props.selectOnEnter && this.state.suggestions.length){
          this.handleSuggestionSelected({}, {suggestion: this.state.suggestions[0]})
        }
        event.preventDefault()
        event.stopPropagation()
      }
      onKeyDown && onKeyDown(event)
    }

    return (
      <div className={this.props.classes.wrapper}>
        <div className={this.props.classes.inner}>
          <ReactAutosuggest
            renderInputComponent={this.renderInput}
            inputProps={{
              ...inputProps,
              name: this.uniqueId,
              autoComplete: this.uniqueId,
              readOnly: this.props.readOnly,
              onKeyDown: onKeyDownWrapper,
              value: this.state.value || '',
              onFocus: this.handleFocus,
              onBlur: this.handleBlur,
              onChange: this.handleChange
            }}
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.handleSuggestionSelected}
            alwaysRenderSuggestions={!!this.state.suggestions.length}
            getSuggestionValue={this.props.labelProvider}
            getSectionSuggestions={this.getSectionSuggestions}
            renderSuggestion={this.renderSuggestion}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            renderSectionTitle={this.renderSectionTitle}
            multiSection={multiSection}
            containerProps={{className}}
          />
          <div className={this.props.classes.chips}>
            {this.value.map((suggestion, idx) =>
              <Chip
                label={this.props.labelProvider(suggestion, 'value')}
                key={idx}
                disabled={this.props.readOnly}
                onClick={this.props.deleteOnClick ? this.handleRequestDelete(suggestion) : this.preventDefault}
                onMouseDown={this.preventDefault}
                onDelete={this.props.readOnly ? null : this.handleRequestDelete(suggestion)}
                className={this.props.classes.chip}
              />
            )}
          </div>
          <div style={{clear: 'both'}}/>
        </div>
      </div>
    )
  }
}

const styles = theme => ({
  match: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  inputContainer: {
  },
  wrapper: {
  },
  inner: {
    position: 'relative'
  },
  container: {
    position: 'absolute',
    minWidth: 'calc(100% - 2px)',
    zIndex: 100,
    borderRadius: 2,
    background: 'rgb(10, 55, 78, 0.9)',
    border: '1px solid #a2d8f1',
    boxShadow: '0px 10px 40px 10px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    margin: 0,
    '& ul': {
      listStyle: 'none',
      padding: 0
    }
  },
  autoSuggestInput: {
    width: '100%',
    marginBottom: 0
  },
  chip: {
    margin: '4px 4px 0 0',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  chips: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    maxWidth: '100%'
  },
  suggestedItem: {
    color: "white",
    fontWeight: '400',
    transition: 'color 100ms, background 200ms',
    '&:hover': {
      color: 'white !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
      '& $match': {
        color: 'yellow !important',
        fontWeight: 700
      }
    },
  },
  highlighted: {
    '& $match': {
      color: 'yellow !important',
      fontWeight: 700
    }
  },
  suggestionsContainer: {
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    }
  },
  expandsUpwards: {
    bottom: 50,
  },
  sectionTitle: {
    display: 'none',
    color: "rgba(255, 255, 255, 1)",
    fontSize: 16,
    background: "rgba(93, 93, 93, 0.7)",
    textAlign: "center",
    borderBottom: "3px solid rgba(232, 197, 109, 0.45)",
    marginBottom: -20
  }
})

export default compose(
  withStyles(styles)
)(MultiAutoSuggest)