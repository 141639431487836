import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import withStyle from 'styles'
import PropTypes from 'prop-types'
import { compose, formatLocaleDateTime } from 'utils'
import { NotificationActions } from 'actionsets'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import ReceiptIcon from '@material-ui/icons/Receipt'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { TokenStore } from 'services'

const STATUS_LABELS = {
  emailDeliveries: 'E-mail',
  appDeliveries: 'In app',
  smsDeliveries: 'SMS',
}

class NotificationDeliveriesList extends Component {
  static propTypes = {
    deliveries:PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)
    NotificationActions.bindActions(this)

    this.state = {
      viewContentDialogOpen: false
    }
  }

  statusLabelFor = (delivery) => {
    const { type } = delivery
    return STATUS_LABELS[type] || 'unknown'
  }

  viewContentClickedHandler = (delivery) => () => {
    this.setState({ viewContentDialogOpen: true, currentDelivery: delivery })
  }

  handleHideViewContentDialog = () => {
    this.setState({ viewContentDialogOpen: false })
  }

  renderViewContentDialog = () => {
    const { id, type, destination, title, message } = this.state.currentDelivery
    const body = (type === 'emailDeliveries') ?
      <iframe
        title='email body content'
        className={this.props.classes.bodyFrame}
        src={`/api/notification_deliveries/${id}/raw_email?Authorization=${TokenStore.auth}`}/> :
      <section>{message}</section>

    return (
      <Dialog open={true} onClose={this.handleHideViewContentDialog }>
        <DialogTitle>Content</DialogTitle>
        <DialogContent className={this.props.classes.dialogPaper}>
          <section className={this.props.classes.subject}>
            <Typography variant="subtitle1" className={this.props.classes.sectionHeading}>{destination}</Typography>
            <Typography variant="body2">{title}</Typography>
          </section>
          <section className={this.props.classes.message}>
            <Typography variant="subtitle1" className={this.props.classes.sectionHeading}>Message</Typography>
            { body }
          </section>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleHideViewContentDialog} color="primary" autoFocus>Dismiss</Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderDelivery = (delivery) => {
    const { id, state, deliveredAt, destination } = delivery
    const recipient = `${delivery.user.name} <${destination}>`

    return (
      <TableRow key={id}>
        <TableCell>{recipient}</TableCell>
        <TableCell>{this.statusLabelFor(delivery)}</TableCell>
        <TableCell>{state}</TableCell>
        <TableCell>{!!deliveredAt && formatLocaleDateTime(deliveredAt)}</TableCell>
        <TableCell>
          <Tooltip title="View content">
            <IconButton onClick={this.viewContentClickedHandler(delivery)}><ReceiptIcon/></IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }

  render = () => {
    return (
      <Fragment>
        { this.state.viewContentDialogOpen && this.renderViewContentDialog() }
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Recipient</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Delivered at</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { this.props.deliveries.map(this.renderDelivery) }
          </TableBody>
        </Table>
      </Fragment>
    )
  }
}

const styles = theme => ({
  dialogPaper: {
    padding: '0 5px 5px',
    minHeight: 100,
    minWidth: 300,
    paddingLeft: 24,
    paddingRight: 24,
  },
  sectionHeading: {
    fontWeight: 'bold',
  },
  subject: {

  },
  message: {
    marginTop: 14,
  },
  bodyFrame: {
    width: 500,
    height: 300,
    backgroundColor: 'white',
  }
})

export default compose(
  connect(() => ({})),
  withStyle(styles),
)(NotificationDeliveriesList)