import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Substations = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({organisationId}) => organisationId ? `organisations/${organisationId}/substations` : 'substations'),
    create:  endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/substations`),
    update:  endpoint => endpoint.method('put').path(({id}) => `substations/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `substations/${id}`),
    show:    endpoint => endpoint.path(({id}) => `substations/${id}`),
  }
)