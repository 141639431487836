import React, { Component } from 'react'
import { SnackbarActions, OrganisationActions } from 'actionsets'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {compose, errorStringsFromError, humanize} from 'utils'
import { Link } from 'react-router-dom'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import EditIcon from '@material-ui/icons/Edit'
import MergeIcon from '@material-ui/icons/MergeType'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import withStyles from 'styles'
import { withPermissions } from 'containers/shared'
import { withRouter } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem';
import BusinessIcon from '@material-ui/icons/Business'
import RoleIcon from '@material-ui/icons/Group'
import AddressIcon from '@material-ui/icons/Place'
import AccountIcon from '@material-ui/icons/AccountBalance'
import TypeIcon from '@material-ui/icons/GroupWork'
import CodeIcon from '@material-ui/icons/Code'
import Typography from '@material-ui/core/Typography'


export class Show extends Component{

  static requiredPermissions = ({organisation: { id }}) => ({
    organisation: {
      [id]: ['writeOrganisation']
    }
  })

  static propTypes = {
    organisation: PropTypes.object.isRequired
  }

  constructor(props){
    super(props)
    SnackbarActions.bindActions(this, 'snackbar')
    OrganisationActions.bindActions(this)
  }

  get permissions(){
    return (this.props.permissions.organisation || {})[this.organisation.id] || {}
  }

  get organisation(){
    return this.props.organisation || {}
  }

  get tags(){
    return this.organisation.tags || {}
  }

  get roles(){
    return this.organisation.roles || []
  }

  get parcticipantCodes(){
    return this.organisation.participantCodes || []
  }

  handleGoToUser = id => event => {
    event.stopPropagation()
    this.props.history.push(`/users/${id}`)
  }

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this organisation? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.organisation.id})
      this.props.history.goBack()
      this.actions.snackbar.show('Organisation deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete organisation.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  render = () =>
    <div>
      <div className={this.props.classes.mainDetails}>
        <div className={this.props.classes.actionHeader}>
          {this.organisation.logoUrlPath ?
            <img alt={this.organisation.name} src={this.organisation.logoUrlPath} className={this.props.classes.logoHeader}/>
            :
            <Typography variant='h5' className={this.props.classes.nameHeader}>
              {this.organisation.iconUrlPath && <img alt={this.organisation.name} src={this.organisation.iconUrlPath} className={this.props.classes.iconHeaderLeft}/> }
              {this.organisation.name}
            </Typography>
          }
          <div className={this.props.classes.actions}>
            {
              this.permissions.writeOrganisation && (this.props.location.pathname === `/organisations/${this.organisation.id}/` ||
              this.props.location.pathname === `/organisations/${this.organisation.id}`) ?
              <>
                <IconButton onClick={this.handleDelete}>
                  <DeleteIcon/>
                </IconButton>
                <Link to={`/organisations/${this.organisation.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
                {this.organisation.status === 'inactive' &&
                <Link to={`/organisations/${this.organisation.id}/merge`}>
                  <IconButton>
                    <MergeIcon/>
                  </IconButton>
                </Link>
                }
              </>
              :
              <>
              <Link to={`/organisations/${this.organisation.id}/`}>
                <IconButton>
                  <SubdirectoryArrowRightIcon/>
                </IconButton>
              </Link>
              <IconButton onClick={this.handleDelete}>
                <DeleteIcon/>
              </IconButton>
              <Link to={`/organisations/${this.organisation.id}/edit`}>
                <IconButton>
                  <EditIcon/>
                </IconButton>
              </Link>
              {this.organisation.status === 'inactive' &&
              <Link to={`/organisations/${this.organisation.id}/merge`}>
                <IconButton>
                  <MergeIcon/>
                </IconButton>
              </Link>
              }
            </>
            }
          </div>
        </div>
        <List className={this.props.classes.list}>
          <ListItem>
            <ListItemIcon><BusinessIcon/></ListItemIcon>
            <ListItemText primary={<span>{this.organisation.name}</span>} secondary='Name'/>
          </ListItem>
          { this.organisation.address &&
            <ListItem>
              <ListItemIcon><AddressIcon/></ListItemIcon>
              <ListItemText primary={this.organisation.address} secondary='Address'/>
            </ListItem>
          }
          { this.organisation.bankAccountNumber && this.organisation.bankAndBranchName &&
            <ListItem>
              <ListItemIcon><AccountIcon/></ListItemIcon>
              <ListItemText primary={this.organisation.bankAccountNumber} secondary={this.organisation.bankAndBranchName}/>
            </ListItem>
          }
          <li className={this.props.classes.status}>
            <Chip label={humanize(this.organisation.status)}/>
          </li>
        </List>
      </div>
      <div className={this.props.classes.extraDetails}>
        <div>
          <Typography variant='subtitle1' className={this.props.classes.subtitle}>
            <CodeIcon/>Codes
          </Typography>
          <List className={this.props.classes.list}>
            {
              !!this.parcticipantCodes.length &&
              <ListItem>
                <ListItemText primary={this.parcticipantCodes.join(", ")} secondary='Participant Codes'/>
              </ListItem>
            }
            { this.organisation.contractId &&
              <ListItem>
                <ListItemText primary={this.organisation.contractId} secondary='Contract ID'/>
              </ListItem>
            }
            { this.organisation.vendorId &&
              <ListItem>
                <ListItemText primary={this.organisation.vendorId} secondary='Vendor ID'/>
              </ListItem>
            }
            { this.organisation.gstNumber &&
              <ListItem>
                <ListItemText primary={this.organisation.gstNumber} secondary='GST Number'/>
              </ListItem>
            }
          </List>
        </div>
        <div>
          <div>
            <Typography variant='subtitle1' className={this.props.classes.subtitle}><RoleIcon/>Roles</Typography>
            {
              (this.organisation.roles || []).map((role,idx) => <Chip label={role.name} key={idx}/>)
            }
          </div>
          <div>
            <Typography variant='subtitle1' className={this.props.classes.subtitle}><TypeIcon/>Type</Typography>
            {
              (this.tags['Organisation Type'] || []).map((tag,idx) => <Chip label={tag} key={idx}/>)
            }
          </div>
        </div>
      </div>
    </div>

}

const styles = theme => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 0,
    '& li': {
      display: 'inline-flex',
      width: 'auto',
      paddingLeft: 0,
      paddingRight: 50,
    }
  },
  wrapper: {
    // borderBottom: "1px solid #efefef"
  },
  pointer: {
    cursor: 'pointer'
  },
  container: {
    flex: 1,
    overflow: 'auto'
  },
  icnBtn: {
    margin: '-10px 0'
  },
  table: {
    marginTop: 25,
    borderTop: '1px solid #DEDEDE',
    borderBottom: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    background: 'white'
  },
  head: {
    background: "#FAFAFA",
    borderRight: '2px solid #DEDEDE',
    borderLeft: '2px solid #DEDEDE',
    color: theme.palette.secondary.dark,
    margin: '10px 0',
    fontWeight: 'bold'
  },
  status: {
    '& span': {
      fontWeight: 'bold'
    },
  },
  subtitle: {
    textAlign: 'left',
    '& svg': {
      color: theme.palette.secondary.main,
      marginRight: 12,
      marginBottom: 2,
    }
  },
  mainDetails: {
    paddingBottom: 8
  },
  extraDetails: {
    marginTop: 30,
    paddingRight: 16,
    paddingBottom: 20,
    textAlign: 'left',
    '& > div': {
      flex: '1 1 200px',
      '& > div': {
        marginBottom: 20
      }
    }
  },
  actionHeader:{
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap'
  },
  actions: {
    display: 'inline-block',
    '& button': {
      margin: '0 -3px'
    },
    paddingRight: 16
  },
  logoHeader: {
    maxWidth: 200,
    maxHeight: 100,
    paddingBottom: 14,
    paddingRight: 18
  },
  iconHeaderLeft: {
    width: 32,
    height: 32,
    paddingRight: 18
  },
  nameHeader: {
    margin: '5px 0',
    paddingRight: 18,
    display: 'inline-flex',
    alignItems: 'center',
  },
})

export default compose(
  withStyles(styles),
  withRouter,
  withPermissions(Show.requiredPermissions, {unmountOnChange: true}),
  connect(({organisations}, prevProps) => ({...organisations, ...prevProps}))
)(Show)
