import React, { Component } from 'react'
import { connect } from 'react-redux'
import { VerificationMethodActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'

export class Show extends Component{

  constructor(props){
    super(props)
    VerificationMethodActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id)
  }

  dependenciesMet(){
    return !!this.verificationMethod.id
  }

  get id(){
    return this.props.match.params.id
  }

  get verificationMethod(){
    return this.props.verificationMethod
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Verification Method - {this.verificationMethod.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/verification_methods/${this.verificationMethod.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell variant='head'>Name</TableCell>
                <TableCell>{String(this.verificationMethod.name)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Method type</TableCell>
                <TableCell>{String(this.verificationMethod.methodType)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant='head'>Weekday flags</TableCell>
                <TableCell>{String(this.verificationMethod.weekdayFlags)}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <br/>
      </CardContent>
    </Card>
}


export default connect(({verificationMethods}) => verificationMethods)(Dependent({loader: true, clearOnLoad: true})(Show))