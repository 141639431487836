import React, { Component } from 'react'
import { connect } from 'react-redux'
import { LoadTypeActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import EditIcon from '@material-ui/icons/Edit'
import { withPermissions } from 'containers/shared'
import { compose } from 'utils'

export class Show extends Component{

  static requiredPermissions = () => ({
    system: ['manageLoadTypes']
  })

  constructor(props){
    super(props)
    LoadTypeActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id)
  }

  dependenciesMet(){
    return !!this.loadType.id
  }

  get id(){
    return this.props.match.params.id
  }

  get loadType(){
    return this.props.loadType
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>Load Type - {this.loadType.name}</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Link to={`/load_types/${this.loadType.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(this.loadType.name)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br/>
      </CardContent>
    </Card>
}


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(Show.requiredPermissions, {
    unmountOnChange: true,
    checkPermissions: (permissions) => {
      return permissions.system.manageLoadTypes
    }
  }),
  connect(({loadTypes}) => loadTypes)
)(Show)