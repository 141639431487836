import React from 'react'
import jss from 'jss'
import preset from 'jss-preset-default'
import { withStyles } from '@material-ui/core/styles'
import buildClasses from './buildClasses'

jss.setup(preset())

export * from './theme'

export default (stylesheet, options) => Wrapped => withStyles(stylesheet, {...options, name: Wrapped.name})(props =>
  <Wrapped {...props} classes={buildClasses(props.classes)}/>
)

