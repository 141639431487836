/**
 * Devices TODO
 * Show online status for devices
 * Show OpenADR registration/info
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { DeviceActions, SnackbarActions, SystemParametersActions, AmbiDeviceStateActions } from 'actionsets'
import { Link } from 'react-router-dom'
import { withPermissions } from 'containers/shared'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { Authorization, compose, errorStringsFromError } from 'utils'
import { MultiAutoSuggest, LivenessIndicator } from 'components'
import DeleteIcon from '@material-ui/icons/Delete'
import withStyles from 'styles'
import SiteIcon from '@material-ui/icons/LocationOn'
import DeviceStatesIcon from '@material-ui/icons/Assignment'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AmbiStateWidget from './AmbiStateWidget'
import { RegistrationShow, DeviceReports, DeviceOpts } from './oadr/fragments'
import TestEventDialog from './TestEventDialog'

export class Show extends Component{

  constructor(props){
    super(props)
    this.state = {
      testEventDialogOpen: false,
      sendingTestEvent: false,
    }
    DeviceActions.bindActions(this)
    AmbiDeviceStateActions.bindActions(this, 'ambiDeviceStates')
    SnackbarActions.bindActions(this, 'snackbar')
    SystemParametersActions.bindActions(this, 'systemParameters')
  }

  async dependsOn(){
    let result = await this.actions.show(this.id, {
      include: 'site,site.organisation,oadrRegistration,reportRegistrations,optSchedules,optSchedules.opts'
    })
    if(!(result.site && result.site.organisation)) {
      this.actions.systemParameters.loadParam('root_organisation')
    }
    if(result.data.type === "Ambi::Device") {
      this.actions.ambiDeviceStates.latest({deviceId: this.id})
    }
  }

  dependenciesMet(){
    return this.device.id && !!(
      this.props.systemParameters.root_organisation || (
        this.props.device.site && this.props.device.site.organisation
      )
    )
  }

  get id(){
    return this.props.match.params.id
  }

  get device(){
    return this.props.device
  }

  get canSendTestEvent() {
    const device = this.props.device || {}
    const site = device.site || {}
    const organisation = site.organisation || {}
    const organisationId = organisation.id ||
      (this.props.systemParameters.root_organisation && String(this.props.systemParameters.root_organisation.value))
    return (((this.props.permissions || {}).organisation || {})[organisationId] || {}).deviceTestEvents || false
  }

  get isOpenAdrDevice(){
    return this.device.type === 'Oadr::Device'
  }

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this device? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.id})
      this.props.history.goBack()
      this.actions.snackbar.show('Device deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete device.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  sendTestEvent = () => {
    this.setState({sendingTestEvent: true})
    let eventPromise = this.actions.testEvent({id: this.id})
    eventPromise.then(() => this.setState({testEventDialogOpen: false}))
    eventPromise.finally(() => this.setState({sendingTestEvent: false}))
    return eventPromise
  }

  renderTestEventDialog = () =>
   <Dialog
     open={this.state.testEventDialogOpen}
     onClose={() => this.setState({testEventDialogOpen: false})}
    >
     <DialogTitle>Test event for {this.props.device.name}</DialogTitle>
     <DialogContent>
       <TestEventDialog {...this.props.device.testEventParams} classes={this.props.classes}/>
     </DialogContent>
     <DialogActions>
       <Button onClick={() => this.setState({testEventDialogOpen: false})} color="primary">Cancel</Button>
       <Button onClick={this.sendTestEvent} disabled={this.state.sendingTestEvent} color="secondary">Send</Button>
     </DialogActions>
   </Dialog>

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <LivenessIndicator style={{top: 10, left: 0}} live={this.device.online} lastHeartbeatAt={this.device.lastHeartbeatAt}/>
        <Typography className={this.props.classes.title} variant='h4'>Device - {this.device.name}</Typography>
        <section className={this.props.classes.deviceSection}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6'>
              Fields
            </Typography>
            {
              true &&
              <span>
                Actions:
                <Link to={`/devices/${this.device.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
                <IconButton onClick={this.handleDelete}>
                  <DeleteIcon/>
                </IconButton>
                {this.device.type === 'Ambi::Device' &&
                  <Link to={`/devices/${this.device.id}/ambi_climate/device_states`}>
                    <IconButton>
                      <DeviceStatesIcon />
                    </IconButton>
                  </Link>
                }
              </span>
            }
            <div className={this.props.classes.tables}>
              <Table className={this.props.classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Name</TableCell>
                    <TableCell>{String(this.device.name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Device type</TableCell>
                    <TableCell>{String(this.device.type)}</TableCell>
                  </TableRow>
                  {this.device.venId ?
                    <TableRow>
                      <TableCell className={this.props.classes.head} variant='head'>Ven ID</TableCell>
                      <TableCell>{String(this.device.venId)}</TableCell>
                    </TableRow>
                    : undefined
                  }
                  { this.isOpenAdrDevice &&
                    <Fragment>
                      { this.device.useOadrCreateReport != null ?
                        <TableRow>
                          <TableCell className={this.props.classes.head} variant='head'>Oadr report method</TableCell>
                          <TableCell>{this.device.useOadrCreateReport ? 'oadrCreateReport' : 'oadrRegisterReport'}</TableCell>
                        </TableRow>
                        : undefined
                      }
                      <TableRow>
                        <TableCell className={this.props.classes.head} variant='head'>Event notification interval</TableCell>
                        <TableCell>{this.device.eventNotificationIntervalMinutes} minutes</TableCell>
                      </TableRow>
                    </Fragment>
                  }
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Status</TableCell>
                    <TableCell>{String(this.device.status)}</TableCell>
                  </TableRow>
                  {
                    this.device.oadrRegistration && this.device.oadrRegistration.id &&
                    <RegistrationShow classes={this.props.classes} registration={this.device.oadrRegistration}/>
                  }
                  {
                    Object.entries(this.device.signalCapacities || {}).map(([type, value]) =>
                    <TableRow key={type}>
                      <TableCell className={this.props.classes.head} variant='head'>DRM{type}</TableCell>
                      <TableCell>{value ? value : '0.0'} kW</TableCell>
                    </TableRow>
                  )
                  }
              </TableBody>
            </Table>
            { this.canSendTestEvent &&
              <Fragment>
                <Button onClick={() => this.setState({testEventDialogOpen: true})} color="secondary">Send Test Event</Button>
                {this.renderTestEventDialog()}
              </Fragment>
            }
          </div>
        </div>
        { this.device.type === 'Ambi::Device' &&
          Object.keys(this.props.deviceState).length > 0 &&
          <AmbiStateWidget className={this.props.classes.ambiState} {...this.props.deviceState} />
        }
        {
          Authorization.systemPermissions.readTags &&
          <div className={this.props.classes.tags}>
            <Typography variant='h6'>
              Tags
            </Typography>
            {
              Object.entries(this.device.tags).map(([type, values], tIdx) =>
                <MultiAutoSuggest
                  readOnly
                  showClearAll={false}
                  key={tIdx}
                  label={type}
                  value={values}
                  labelProvider={value => value}
                />
              )
            }
          </div>
        }
        </section>
        <br/>
        {
          this.device.site && this.device.site.id &&
          <section>
            <Fragment>
              <Typography variant='h6'>Parent Site:</Typography>
              <ListItem button onClick={() => this.props.history.push(`/sites/${this.device.site.id}`)}>
                <ListItemIcon>
                  <SiteIcon/>
                </ListItemIcon>
                <ListItemText primary={this.device.site.name} secondary='site'/>
              </ListItem>
            </Fragment>
          </section>
        }
        <div className={this.props.classes.widgetContainer}>
          {this.device.streamIdentifiers   && <DeviceReports device={this.device} />}
          {this.device.optSchedules        && <DeviceOpts    device={this.device} />}
        </div>
    </CardContent>
  </Card>
}


const styles = theme => ({
  widgetContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '1 1 auto',
  },
  load: {
    fontSize: "13px",
    padding: "10px 0",
    borderBottom: "1px solid silver",
    color: "#333",
  },
  loadTypeKey:{
    fontWeight: 'bold'
  },
  tables: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  title: {
    marginLeft: 35
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    },
    '& td': {
      background: 'rgba(255,255,255,0.03)',
      padding: '10px 15px',
      '&:last-child': {
        borderRight: '1px solid rgba(255,255,255,0.05)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(255,255,255,0.05)'
      },
      borderTop: '1px solid rgba(255,255,255,0.05)',
      borderBottom: '1px solid rgba(255,255,255,0.05)'
    },
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: 'rgba(255,255,255,0.06) !important',
    borderRight: '1px solid rgba(255,255,255,0.02)'
  },
  deviceSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: '2 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: "10px 10px 0 0",
    minWidth: 300,
    marginBottom: 10,
    color: 'white',
  },
  tags: {
    flex: '1 1 0%',
    marginBottom: 10,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  },
  ambiState: {
    padding: 10
  }
})


export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(({device, systemParameters}) => {
    const site = device.site || {}
    const organisation = site.organisation || {}
    const organisationId = organisation.id || (systemParameters.root_organisation && String(systemParameters.root_organisation.value))
    if(organisationId) {
      return {organisation: {[String(organisationId)]: ['deviceTestEvents']}}
    } else {
      return {}
    }
  }),
  withStyles(styles),
  connect(({devices, systemParameters, ambiDeviceStates: {latest: deviceState} = {}}) => ({...devices, systemParameters, deviceState}))
)(Show)
