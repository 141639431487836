import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { withRouter } from 'react-router-dom'
import { Authorization, compose } from 'utils'

class Routes extends Component{

  get defaultRedirect(){
    switch(true){
    case this.props.needChangePassword: { return `/tokens/update` }
    case !Authorization.organisationId: { return '/organisations' }
    default: { return `/home` }
    }
  }

  rootRedirect = () =>{
    return <Redirect to={this.defaultRedirect}/>
  }

  render = () => this.props.needChangePassword ?
    this.renderPasswordExpiredRoutes() :
    this.renderRoutes()

  renderPasswordExpiredRoutes = () =>
    <Switch>
      <Route exact path="/load_types"                    component={Containers.LoadType.List}/>
      <Route exact path="/load_types/new"                component={Containers.LoadType.Form}/>
      <Route exact path="/load_types/:id"                component={Containers.LoadType.Show}/>
      <Route exact path="/load_types/:id/edit"           component={Containers.LoadType.Form}/>
      <Route exact path="/" component={this.rootRedirect}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/unlock"              component={Containers.Token.Unlock}/>
      <Route exact path="/tokens/update"              component={Containers.Token.Update}/>
      <Route exact path="/profile"                    component={Containers.User.Show}/>
      <Route exact path="/tokens/acknowledge_notification" component={Containers.Token.AcknowledgeNotification}/>
      <Route component={Containers.Status.NotFound}/>
    </Switch>

  renderRoutes = () =>
    <Switch>
      <Route exact path="/load_types"                    component={Containers.LoadType.List}/>
      <Route exact path="/load_types/new"                component={Containers.LoadType.Form}/>
      <Route exact path="/load_types/:id"                component={Containers.LoadType.Show}/>
      <Route exact path="/load_types/:id/edit"           component={Containers.LoadType.Form}/>
      <Route exact path="/"       component={this.rootRedirect}/>
      <Route exact path="/events"                       component={Containers.Events.List}/>
      <Route exact path="/home"                         component={Containers.Landing.Dashboard}/>
      <Route exact path="/calendars"                    component={Containers.Calendar.Dashboard}/>
      <Route exact path="/calendars/:tab(list|holiday_zones)" component={Containers.Calendar.Dashboard}/>
      <Route exact path="/calendars/new"                component={Containers.Calendar.Form}/>
      <Route exact path="/calendars/:id"                component={Containers.Calendar.Show}/>
      <Route exact path="/calendars/:id/edit"           component={Containers.Calendar.Form}/>

      <Route exact path="/verification_methods"          component={Containers.VerificationMethod.List}/>
      <Route exact path="/verification_methods/new"      component={Containers.VerificationMethod.Form}/>
      <Route exact path="/verification_methods/:id"      component={Containers.VerificationMethod.Show}/>
      <Route exact path="/verification_methods/:id/edit" component={Containers.VerificationMethod.Form}/>

      <Route exact path="/roles"          component={Containers.Role.List}/>
      <Route exact path="/roles/:tab"     component={Containers.Role.List}/>
      <Route exact path="/tags"                   component={() => <Redirect to="/tags/user"/>}/>
      <Route exact path="/tags/:tab(user|system)" component={Containers.Tag.List}/>

      <Route exact path="/notifications"          component={Containers.Notification.List}/>

      <Route exact path="/programmes"          component={Containers.Programme.List}/>
      <Route exact path="/programmes/new"      component={Containers.Programme.Form}/>
      <Route exact path="/organisations/:organisationId/programmes/new" component={Containers.Programme.Form}/>
      <Route exact path="/programmes/:id"      component={Containers.Programme.Show}/>
      <Route exact path="/programmes/:id/edit" component={Containers.Programme.Form}/>
      <Route exact path="/programmes/:id/:tab(details|registrations|events)"
                                              component={Containers.Programme.Show}/>

      <Route exact path="/programmes/:programmeId/parent_events/new"  component={Containers.ParentEvent.Form}/>
      <Route exact path="/parent_events/:id"                          component={Containers.ParentEvent.Form}/>
      <Route exact path="/parent_events/:id/edit"                     component={Containers.ParentEvent.Form}/>

      <Route exact path="/child_events/:id"                          component={Containers.ChildEvent.Show}/>

      <Route exact path='/meter_data' component={Containers.MeterData.Dashboard}/>
      <Route exact path='/meter_data/:tab(sites|substations|gxps|baseline_tester|uploads)' component={Containers.MeterData.Dashboard}/>

      <Route exact path="/organisations/:organisationId/registrations/new" component={Containers.Registration.Form}/>
      <Route exact path="/registrations/:id"                               component={Containers.Registration.Show}/>
      <Route exact path="/registrations/:id/edit"                          component={Containers.Registration.Form}/>

      <Route exact path="/regions"                component={Containers.Region.List}/>
      <Route exact path='/regions/:tab(map|list)' component={Containers.Region.List}/>

      <Route exact path="/regions/new"      component={Containers.Region.Form}/>
      <Route exact path="/regions/:id"      component={Containers.Region.Show}/>
      <Route exact path="/regions/:id/edit" component={Containers.Region.Form}/>

      <Route exact path="/bctis/:organisationId/new" component={Containers.BCTIs.Form}/>
      <Route exact path="/bctis/:id"                 component={Containers.BCTIs.Show}/>

      <Route exact path="/reports"                                            component={Containers.Reports.Dashboard}/>
      <Route exact path="/reports/:tab(event_reports|registration_reports|missing_meter_data)"     component={Containers.Reports.Dashboard}/>

      <Route exact path="/regions/:regionId/gxps"     component={Containers.Gxp.List}/>
      <Route exact path="/gxps/orphaned"              component={props => <Containers.Gxp.List {...props} renderOrphaned={true}/>}/>
      <Route exact path="/gxps/:id"                    component={Containers.Gxp.Show}/>
      <Route exact path="/gxps/:id/edit"               component={Containers.Gxp.Form}/>

      <Route exact path="/organisations/:organisationId/organisation_roles"     component={Containers.OrganisationRole.List}/>
      <Route exact path="/organisations/:organisationId/organisation_roles/new" component={Containers.OrganisationRole.Form}/>
      <Route exact path="/organisation_roles/:id"                                component={Containers.OrganisationRole.Show}/>
      <Route exact path="/organisation_roles/:id/edit"                           component={Containers.OrganisationRole.Form}/>

      <Route exact path="/organisations/:organisationId/user_roles/new/:tab(registration|organisation|system)?"
                                                                                        component={Containers.UserRole.Form}/>
      <Route exact path="/user_roles/:id"                                               component={Containers.UserRole.Show}/>
      <Route exact path="/user_roles/:id/edit/:tab(registration|organisation|system)?"  component={Containers.UserRole.Form}/>
      <Route exact path="/user_roles/:id/:tab(registration|organisation|system)?"       component={Containers.UserRole.Show}/>

      <Route exact path="/organisations/:organisationId/sites/new" component={Containers.Site.Form}/>
      <Route exact path="/sites/:id"                                component={Containers.Site.Show}/>
      <Route exact path="/sites/:id/edit"                           component={Containers.Site.Form}/>

      <Route exact path="/organisations/:organisationId/substations/new" component={Containers.Substation.Form}/>
      <Route exact path="/substations/:id"                                component={Containers.Substation.Show}/>
      <Route exact path="/substations/:id/edit"                           component={Containers.Substation.Form}/>

      <Route exact path="/sites/:siteId/devices"                          component={Containers.Device.List}/>
      <Route exact path="/sites/:siteId/devices/new"                      component={Containers.Device.Form}/>
      <Route exact path="/devices/new"                                    component={Containers.Device.Form}/>
      <Route exact path="/devices/:id"                                    component={Containers.Device.Show}/>
      <Redirect exact sensitive from="/(.*)Devices/:id" to="/devices/:id" />
      <Route exact path="/devices/:id/edit"                               component={Containers.Device.Form}/>
      <Route exact path="/oadr/report_registrations/:id"                  component={Containers.Device.ReportRegistration}/>
      <Route exact path="/oadr/opts/:id"                                  component={Containers.Device.OptSchedule}/>
      <Route exact path="/oadr/datapoints/:rid/report_intervals"  component={Containers.Device.ReportIntervals}/>
      <Route exact path="/devices/:deviceId/ambi_climate/device_states" component={Containers.Device.AmbiDeviceStates} />
      <Route exact path="/login_failures"                                 component={Containers.LoginFailure.List}/>
      <Route exact path="/profile"                                        component={Containers.User.Show}/>
      <Route exact path="/organisations/:organisationId/users/new" component={Containers.User.Form}/>
      <Route exact path="/users/:id/:tab(registration|organisation|system)?" component={Containers.User.Show}/>

        <Route exact path="/organisations/:id/edit"                            component={Containers.Organisation.Form}/>
        <Route exact path="/organisations/:organisationId/organisations/new"   component={Containers.Organisation.Form}/>
        <Route exact path="/organisations/:id"                                 component={Containers.Organisation.Dashboard}/>
        <Route exact path="/organisations/:id/merge" component={Containers.Organisation.Merge}/>
        <Route exact path="/organisations/:id/:tab/:childId?" component={Containers.Organisation.Dashboard}/>

      <Route exact path="/users/:user_id/user_role/edit/:tab(registration|organisation|system)?"
                                          component={Containers.UserRole.Form}/>

      <Route exact path="/users/:id/edit" component={Containers.User.Form}/>
      <Route exact path="/tokens/sign_in"             component={this.rootRedirect}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/tokens/reset"               component={Containers.Token.Reset}/>
      <Route exact path="/tokens/unlock"              component={Containers.Token.Unlock}/>
      <Route exact path="/tokens/update"               component={Containers.Token.Update}/>
      <Route exact path="/tokens/accept_invitation"   component={Containers.Token.AcceptInvitation}/>
      <Route exact path="/tokens/invitation_accepted" component={Containers.Token.AcceptedNoLogin}/>
      <Route exact path="/tokens/acknowledge_notification" component={Containers.Token.AcknowledgeNotification}/>
      <Route exact path="/tokens/confirm"             component={Containers.Token.Confirm}/>
      <Route exact path="/tokens/resend_confirmation" component={Containers.Token.ResendConfirmation}/>

      <Route exact path="/not_found"           component={Containers.Status.NotFound}/>
      <Route exact path="/timed_out"           component={Containers.Status.TimedOut}/>
      <Route exact path="/inactive"            component={Containers.Status.Inactive}/>
      <Route exact path="/confirmation_resent" component={Containers.Status.ConfirmationResent}/>
      <Route exact path="/unauthorized"        component={Containers.Status.Unauthorized}/>

      <Route exact path="/apidocs"    component={Containers.Docs.ApiDocs}/>
      <Route exact path="/notifications_control" component={Containers.NotificationsControlRoom.List}/>
      <Route exact path="/jobs"       component={Containers.Jobs.List}/>
      <Route exact path="/versions"   component={Containers.Versions.List}/>
      <Route exact path="/monthly_reports"    component={Containers.MonthlyReports.Show}/>
      {
        !!process.env.REACT_APP_MOUNT_OADR_TEST_PROMPTS &&
        <Route exact path="/OpenADRCertTest/prompts20b"     component={Containers.OpenADRCertTest.Prompts20b}/>
      }
      <Route component={Containers.Status.NotFound}/>
    </Switch>

}

export default compose(
  connect(({tokens: {needChangePassword}}) => ({needChangePassword})),
  withRouter
)(Routes)
