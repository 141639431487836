import CrudActionSetBase from './CrudActionSetBase'
import * as API from 'api'

export class OrganisationActionSet extends CrudActionSetBase('organisation'){
  static initialState = {
    permissionNames: [],
    organisationsByType: {
      retailers: [],
      meter_owners: [],
      distributors: []
    }
  }

  static getPermissionNames(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((organisationId) => {
      return {
        type: constants.ACTION,
        promise: API.Organisations.permissionNames({organisationId})
      }
    })

    reducer({
      [constants.SUCCESS]: (state, {result: { data: permissionNames } }) => {
        return {...state, permissionNames }
      }
    })
  }

  static getOrganisationsByType(creator, reducer, constants){

    constants.defineRequestConstants()

    creator(types => {
      const typeList = types.split(',')
      return {
        type: constants.ACTION,
        payload: typeList,
        promise: Promise.all(
          typeList.map(type => API.Organisations.indexType({type}))
        )
      }
    })

    reducer({
      [constants.SUCCESS]: (state, { requestPayload, result }) => {
        const organisationsByType = requestPayload.reduce((orgs, type, index) => {
          orgs[type] = result[index].data
          return orgs
        }, {...state.organisationsByType})
        return {...state, organisationsByType }
      }
    })
  }

  static attach(creator, reducer, constants){

    constants.defineRequestConstants()

    creator((item, {clearExisting, ...options}={ clearExisting: false}) => {
      return {
        type: constants.ACTION,
        promise: API.Organisations.attach({...item, options}),
        payload: { clearExisting }
      }
    })

    reducer({
      [constants.REQUEST]: (state,{ requestPayload: { clearExisting }={}, request }) => {
        return {...state, ...clearExisting && {organisation: this.initialState.organisation }, requests: [...state.requests, request ], errors: {...state.errors, create: null }}
      },
      [constants.SUCCESS]: (state, { request, result: { data } }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        return {...state, requests, organisation: data }
      },
      [constants.FAILURE]: (state, { request, error }) => {
        const requests = state.requests.filter(existingRequest => (existingRequest !== request))
        const errors = {...state.errors, attach: error }
        return {...state, requests, errors }
      }
    })
  }
}
export default new OrganisationActionSet()