import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ProgrammeActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import { ParentEvent, Registration } from 'containers'
import { compose, humanize, Authorization, errorStringsFromError } from 'utils'
import withStyles from 'styles'
import { MultiAutoSuggest, LabeledCheckbox } from 'components'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withPermissions } from 'containers/shared'
import { capitalize, formatLocaleDate } from 'utils'
import { AvailabilitiesSummary } from 'components'

export class Show extends Component{

  static requiredPermissions = props => {
    const permissions =  {
      organisation: {
        [Authorization.organisationId]: ['createRegistrations']
      }
    }
    if(props.programme.organisation && props.programme.organisation.id){
      const owningOrgId = props.programme.organisation.id
      permissions.organisation[owningOrgId] = permissions.organisation[owningOrgId] || []
      permissions.organisation[owningOrgId] = permissions.organisation[owningOrgId].concat([
        'createEvents', 'requestEvents','writeProgrammes'
      ])
    }
    return permissions
  }

  constructor(props){
    super(props)
    ProgrammeActions.bindActions(this)
    SnackbarActions.bindActions(this,'snackbar')
  }

  dependsOn(){
    return this.actions.show(this.id, { include: 'owner,organisation,availabilities' })
  }

  dependenciesMet(){
    return !!this.programme.id
  }

  handleTabChange = (event, tab) =>
    this.props.history.push(`/programmes/${this.id}/${tab}`)

  isActiveTab = tab =>{
    const activeTest = `canShow${capitalize(tab)}`
    return this.selectedTab === tab && this[activeTest]
  }

  get organisationId(){
    return this.programme.organisation.id
  }

  get selectedTab(){
    return this.props.match.params.tab || 'details'
  }

  get id(){
    return this.props.match.params.id
  }

  get ownOrgPermissions(){
    if(!this.props.permissions.organisation) {
      return {}
    }
    return this.props.permissions.organisation[Authorization.organisationId]  || {}
  }

  get programmeOrgPermissions(){
    if(!this.props.permissions.organisation){
      return {}
    }
    return this.props.permissions.organisation[this.organisationId] || {}
  }

  get programme(){
    return this.props.programme
  }

  get canShowDetails(){
    return true
  }

  get canShowRegistrations(){
    return this.ownOrgPermissions.createRegistrations
  }

  get canShowEvents(){
    return this.programmeOrgPermissions.createEvents || this.programmeOrgPermissions.requestEvents
  }

  get signalMappings(){
    return this.props.formObject.signalMappings
  }

  get isPriceResponsive(){
    return this.programme.type === 'PriceResponsiveProgramme'
  }

  deleteProgramme = event => {
    this.actions.destroy({id: this.id})
                .then(() => this.props.history.push(`/organisations/${this.organisationId}`))
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  renderDetailsTab = () =>
    <section className={this.props.classes.programmeSection}>
      <div className={this.props.classes.formFields}>
        <Typography variant='h6'>
          Fields
        </Typography>
        {
          this.programmeOrgPermissions.writeProgrammes &&
          <span>
            <Link to={`/programmes/${this.programme.id}/edit`}>
              Actions:
              <IconButton>
                <EditIcon/>
              </IconButton>
            </Link>
            <IconButton onClick={this.deleteProgramme}>
              <DeleteIcon/>
            </IconButton>
          </span>
        }
        <div className={this.props.classes.tables}>
          <Table className={this.props.classes.table}>
            <TableBody>
              <TableRow>
                <TableCell className={this.props.classes.head} variant='head'>Name</TableCell>
                <TableCell>{String(this.programme.name)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={this.props.classes.head} variant='head'>Programme URI</TableCell>
                <TableCell>{String(this.programme.uri || '')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={this.props.classes.head} variant='head'>Start date</TableCell>
                <TableCell>{formatLocaleDate(this.programme.startDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={this.props.classes.head} variant='head'>End date</TableCell>
                <TableCell>{formatLocaleDate(this.programme.endDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={this.props.classes.head} variant='head'>Minimum lead time</TableCell>
                <TableCell>{this.programme.minimumLeadTime} minutes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={this.props.classes.head} variant='head'>Type</TableCell>
                <TableCell>{humanize(this.programme.type)}</TableCell>
              </TableRow>
              {
                this.programme.allowsEstablishmentFee ?
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Allows Establishment Fee</TableCell>
                    <TableCell>True</TableCell>
                  </TableRow> :
                  false
              }
              {
                (!this.isPriceResponsive) &&
                <Fragment>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Requirements</TableCell>
                    <TableCell>
                      <LabeledCheckbox readOnly label='Fixed Price' value={this.programme.requiresFixedPrice}/>
                      <LabeledCheckbox readOnly label='Availability Fee' value={this.programme.requiresAvailabilityFee}/>
                      <LabeledCheckbox readOnly label='Pre-purchased Hours' value={this.programme.requiresPrepurchasedHours}/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={this.props.classes.head} variant='head'>Availabilities</TableCell>
                    <TableCell><AvailabilitiesSummary availabilities={this.programme.availabilities}/></TableCell>
                  </TableRow>
                </Fragment>
              }
            </TableBody>
          </Table>
        </div>
      </div>
      {
        Authorization.systemPermissions.readTags &&
        <div className={this.props.classes.tags}>
          <Typography variant='h6'>
            Eligibility Criteria
          </Typography>
          {
            Object.entries(this.programme.tags).map(([type, values], tIdx) =>
              <MultiAutoSuggest
                readOnly
                showClearAll={false}
                key={tIdx}
                label={type}
                value={values}
                labelProvider={value => value}
              />
            )
          }
          <br/>
          <Typography variant='h6'>
            Devices Filter
          </Typography>
          {
            Object.entries(this.programme.deviceFilter || {}).map(([type, values], tIdx) =>
              <MultiAutoSuggest
                readOnly
                showClearAll={false}
                key={tIdx}
                label={type}
                value={values}
                labelProvider={value => value}
              />
            )
          }
        </div>
      }
    </section>

  renderRegistrationsTab = () =>
    <Fragment>
      <br/>
      <Typography variant='h6'>Registrations</Typography>
      <br/>
      <Registration.List programme={this.programme} canCreate={false}/>
    </Fragment>

  renderEventsTab = () =>
    <Fragment>
      <br/>
      <Typography variant='h6'>Events</Typography>
      <br/>
      <ParentEvent.List programme={this.programme}/>
    </Fragment>

  render = () =>
    <Card>
      <CardContent className={this.props.classes.cardContent}>
        <Typography variant='h4'>Programme - {this.programme.name}&emsp;{this.programme.active ? '' : <Chip label='Inactive'/>}</Typography>
        <Tabs value={this.selectedTab} onChange={this.handleTabChange}>
          {this.canShowDetails       && <Tab value='details' label='Details'/>}
          {this.canShowRegistrations && <Tab value='registrations' label='Registrations'/>}
          {this.canShowEvents        && <Tab value='events' label='Events'/>}
        </Tabs>
        {this.isActiveTab('details') && this.renderDetailsTab()}
        {this.isActiveTab('registrations') && this.renderRegistrationsTab()}
        {this.isActiveTab('events') && this.renderEventsTab()}
      </CardContent>
    </Card>
}


const styles = theme => ({
  tables: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    },
    '& td': {
      background: 'rgba(255,255,255,0.03)',
      padding: '10px 15px',
      '&:last-child': {
        borderRight: '1px solid rgba(255,255,255,0.05)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(255,255,255,0.05)'
      },
      borderTop: '1px solid rgba(255,255,255,0.05)',
      borderBottom: '1px solid rgba(255,255,255,0.05)'
    },
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: 'rgba(255,255,255,0.06) !important',
    borderRight: '1px solid rgba(255,255,255,0.02)'
  },
  programmeSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: '2 1 0%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: "10px 10px 0 0",
    minWidth: 300,
    marginBottom: 10
  },
  tags: {
    flex: '1 1 0%',
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300,
    margin: '10px 0'
  }
})

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withPermissions(Show.requiredPermissions),
  withStyles(styles),
  connect(({programmes}) => programmes)
)(Show)
