import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { compose } from 'utils'
import Dependent from 'containers/shared/Dependent'
import { JobActions, SnackbarActions } from 'actionsets'
import { JobListItem } from './'
import { withPermissions } from 'containers/shared'
import { Pagination, AutoSuggest, DatePicker } from 'components'
import * as API from 'api'
import moment from 'moment'

const stateFilterValues = {
  all: 'All' ,
  failed: 'Failed',
  completed: 'Completed',
  active: 'Active'
}

export class List extends Component{
  static requiredPermissions = () => ({
    system: ['readBackgroundJobs', 'writeBackgroundJobs']
  })

  constructor(props){
    super(props)
    JobActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')

    this.state = {
      filter: {
        state: 'all',
        from:  moment().startOf('month').subtract(3, 'month').toDate(),
        to: moment().toDate(),
      }
    }
  }

  dependsOn(){
    return this.loadJobs()
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  loadJobs = async (page = this.currentPage) => {
    const filter = {
      ...this.state.filter,
      ...(!!this.state.filter.type) && {type: this.state.filter.type.id}
    }
    Object.keys(filter).forEach((key) => {
      if (filter[key] === null || filter[key] === undefined) {
        delete filter[key]
      }
    })
    this.actions.index({ page, filter: filter })
  }

  get currentPage() {
    return this.props.page || 0
  }

  cancelHandler = (job) => async () => {
    await this.actions.event({id: job.id}, 'cancel')
    this.loadJobs()
  }

  rescheduleHandler = (job) => async () => {
    await this.actions.event({id: job.id}, 'schedule')
    this.loadJobs()
  }

  handleStateFilterChange = (event) => {
    const {target: { value } } = event
    this.setState({filter: {...this.state.filter, state: value}}, this.loadJobs)
  }

  handleRequestJobTypesFetch = async (text, callback) => {
    const { data: types } = await API.Jobs.types({
      options: {
        filter: { name: text }
      }
    })
    callback(types)
  }

  handleJobTypeFilterChanged = ({target: { value: type}}) => {
    this.setState({filter: {...this.state.filter, type }}, this.loadJobs)
  }

  handleDateFilterChange = which => ({target: { value }}) => {
    this.setState({ filter: {...this.state.filter, [which]: value}}, this.loadJobs)
  }

  renderJobListItem = (job) => {
    return (
      <JobListItem key={job.id} job={job}
        onCancel={ this.cancelHandler(job) }
        onReschedule={ this.rescheduleHandler(job) }
        canChange={ !!this.props.permissions.system.writeBackgroundJobs } />
    )
  }

  renderFilter = () => {
    return(
      <FormControl>
        <Select value={this.state.filter.state}
                onChange={this.handleStateFilterChange}
                inputProps={{ name: 'state', id: 'job-filter'}}>
          { Object.entries(stateFilterValues).map(([k, v]) => <MenuItem key={k} value={k}>{v}</MenuItem>) }
        </Select>
      </FormControl>
    )
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>System Jobs</Typography>
        <Tooltip title="Refresh" placement="bottom-end">
          <IconButton onClick={ (event) => {
            event.stopPropagation()
            this.loadJobs()
          } }>
            <RefreshIcon/>
          </IconButton>
        </Tooltip>
        <section className={this.props.classes.filters}>
          <AutoSuggest
            className={this.props.classes.jobAutoSuggest}
            label='Job type'
            value={ this.state.filter.type }
            labelProvider={({name}) => name}
            onSuggestionsFetchRequested={this.handleRequestJobTypesFetch}
            onChange={this.handleJobTypeFilterChanged}
            fullWidth={false}
          />
          <div className={this.props.classes.filterGroup}>
            <DatePicker
              className={this.props.classes('filterField', 'formControl')}
              value={this.state.filter.from}
              name='filterFrom'
              onChange={this.handleDateFilterChange('from')}
              placeholder='From Date'
            />
            <DatePicker
              className={this.props.classes('filterField', 'formControl')}
              value={this.state.filter.to}
              name='filterTo'
              onChange={this.handleDateFilterChange('to')}
              placeholder='To Date'
            />
          </div>
        </section>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadJobs}
          startAdornment={ this.renderFilter() } style={{}} linkStyle={{}}/>
        <Table>
          <TableHead className={this.props.classes.tableHead}>
            <TableRow>
              <TableCell className={this.props.classes.tableHeader} variant='head'>ID</TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Type</TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Native Job ID</TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Run Count</TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Run At</TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Running</TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Cancelled</TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Finished</TableCell>
              <TableCell variant='head'></TableCell>
              <TableCell className={this.props.classes.tableHeader} variant='head'>Failed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={this.props.classes.tableBody}>
            {
              this.props.jobs.map(this.renderJobListItem)
            }
            { this.props.jobs.length === 0 &&
              <TableRow>
                <TableCell colSpan={9} className={this.props.classes.subHeaderContent}>0 jobs</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.loadJobs} style={{}} linkStyle={{}}/>
      </CardContent>
    </Card>
}

const styles = theme => ({
  tableHeader: {
    width: 0
  },
  tableBody: {
    '& td': {
      padding: 3,
      width: 0
    },
  },
  tableHead: {
    '& th': {
      padding: 3,
      width: 0
    }
  },
  subHeader: {
    textAlign: 'center'
  },
  subHeaderContent: {
    width: '100%'
  },
  filters: {
    // paddingTop: '28px',
    paddingBottom: '28px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterGroup: {
    flex: '1 1 auto',
    display: 'flex',
  },
  formControl:{
    paddingTop: '16px',
  },
  filterField: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
})

export default compose(
  Dependent({loader: true}),
  withPermissions(List.requiredPermissions, {
    unmountOnChange: true,
    checkPermissions: (permissions) => permissions.system.readBackgroundJobs
  }),
  connect(({jobs}) => jobs),
  withStyles(styles)
)(List)
