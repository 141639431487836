import React, { Component } from 'react'
import WidgetContainer from './WidgetContainer'
import withStyles from 'styles'
import { compose } from 'utils'
import * as API from 'api'
import { LineChart } from 'components/charts'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {lighten} from '@material-ui/core/styles/colorManipulator'
import moment from 'moment'

const BLANK_TRADING_PERIODS_COUNT = 12

export class RcpdLoadsWidget extends Component{

  state = { loads: [], mode: 'All' }

  handleChangeMode = mode => () => {
    this.setState({mode}, this.computeChartData)
  }

  componentDidMount = async () => {
    const { data: { loads } } = await API.Rcpd.loads()
    this.resizeInterval = global.setInterval(() => {this.chartRef && this.chartRef.resize()}, 1000)
    this.setState({...loads, ready: true }, this.computeChartData)
  }

  paddWithBlankTradingPeriods = (arrayToPad, from, blankGenerator) => {
    let fromMoment = moment(from)
    for(let i = 1; i <= BLANK_TRADING_PERIODS_COUNT; i++) {
      let tpStart = fromMoment.clone().add(30 * i, 'minutes')
      arrayToPad.push(blankGenerator(tpStart.toDate()))
    }
  }

  handleUnderlayCallback = (ctx, area, g) => {
    if(!this.state.ready) {
      return
    }

    let hairlineDate = new Date(this.state.loads[this.state.loads.length - 1].from)
    let hairlineX = g.toDomXCoord(hairlineDate)
    let lineDash = ctx.getLineDash()
    let strokeStyle = ctx.strokeStyle
    let lineWidth = ctx.lineWidth

    let {h} = g.getArea()
    
    ctx.beginPath()
    ctx.setLineDash([10, 12])
    ctx.moveTo(hairlineX, 0)
    ctx.lineTo(hairlineX, h)
    ctx.strokeStyle = 'green'
    ctx.lineWidth = 3
    ctx.stroke()

    ctx.setLineDash(lineDash)
    ctx.strokeStyle = strokeStyle
    ctx.lineWidth = lineWidth
  }

  computeChartData = () => {
    if(!this.state.ready)
      return
    let labels, data;
    let lastFrom = this.state.loads[this.state.loads.length - 1].from
    switch(this.state.mode.toLowerCase()){
    case 'all':
      labels = ['TP', 'UNI', 'LNI', 'USI', 'LSI']
      data = this.state.loads.map(({from, UNI, LNI, USI, LSI}) => ([new Date(from), UNI, LNI, USI, LSI]))
      this.paddWithBlankTradingPeriods(data, lastFrom, (date) => {
        return [date, null, null, null, null]
      })
      break;
    case 'uni':
    case 'lni':
    case 'usi':
    case 'lsi':
    default:
      const peaks = this.state.peaks[this.state.mode]
      labels = ['TP', this.state.mode, 'Min Peak', 'Avg Peak','Max Peak']
      data = this.state.loads.map(load => ([new Date(load.from), load[this.state.mode], parseFloat(peaks['min_highest_peak'],10),parseFloat(peaks['avg_highest_peak'],10),parseFloat(peaks['max_highest_peak'],10)]))
      this.paddWithBlankTradingPeriods(data, lastFrom, (date) => {
        return [date, null, null, null, null]
      })
    }
    this.setState({ chartData: { labels, data }})
  }

  componentWillUnmount = () => {
    global.clearInterval(this.resizeInterval)
  }

  render(){
    return (
      <WidgetContainer className={this.props.classes.widgetContainer} width='md'>
        <Typography className={this.props.classes.typography} variant='h6'>RCPD</Typography>
        <LineChart
          className={this.props.classes.chart}
          fillGraph={false}
          chartRef={ref => this.chartRef = ref}
          chartData={this.state.chartData}
          axes={{
            y: {
              axisLabelFormatter: function(v) {
                return parseFloat(v.toFixed(2), 10) + '&nbsp;MW';  // controls formatting in the legend/mouseover
              },
              valueFormatter: function(v) {
                return parseFloat(v.toFixed(2), 10) + '&nbsp;MW';  // controls formatting in the legend/mouseover
              }
            }
          }}
          series={{
            "UNI": { fillGraph: true},
            "LNI": { fillGraph: true},
            "USI": { fillGraph: true},
            "LSI": { fillGraph: true},
          }}
          underlayCallback={this.handleUnderlayCallback}
        />
        <div className={this.props.classes.toggleButtons}>
          {
            ["All", "UNI", "LNI", "USI", "LSI"].map(mode =>
              <Tooltip title={mode} key={mode}>
                <Button key={mode} className={this.props.classes({disabled: this.state.mode === mode})} onClick={this.handleChangeMode(mode)}>
                  {mode}
                </Button>
              </Tooltip>
            )
          }
        </div>
      </WidgetContainer>
    )
  }
}

const styles = theme => ({
  widgetContainer: {
    padding: '0 0 30px',
  },
  disabled: {},
  toggleButtons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    lineHeight: 0,
    height: 30,
    paddingTop: 7,
    background: 'rgba(0, 19, 27, 0.6)',
    textAlign: 'right',
    width: '100%',
    '& > button': {
      color: '#000',
      padding: 0,
      minWidth: 30,
      minHeight: 23,
      background: theme.palette.primary.light,
      '&:hover':{
        background: lighten(theme.palette.primary.light, 0.25),
      },
      '&$disabled': {
        background: lighten(theme.palette.primary.light, 0.5)
      },
      borderRadius: 0,
    }
  },
  typography: {
    margin: 0,
    padding: '15px 0',
    borderBottom: '1px solid rgba(255,255,255,0.3)',
    boxShadow: '0px 10px 40px 10px rgba(0,0,0,0.2)'
  }
})
export default compose(
  withStyles(styles),
)(RcpdLoadsWidget)