import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Sites = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({organisationId}) => organisationId ? `organisations/${organisationId}/sites` : 'sites'),
    create:  endpoint => endpoint.method('post').path(({organisationId}) => `organisations/${organisationId}/sites`),
    update:  endpoint => endpoint.method('put').path(({id}) => `sites/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `sites/${id}`),
    show:    endpoint => endpoint.path(({id}) => `sites/${id}`),
    total_capacity: endpoint => endpoint.path('sites/total_capacity'),
    requestMeterReading: endpoint => endpoint.method('post').path(({id}) => `sites/${id}/request_meter_reading`)
  }
)
