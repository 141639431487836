import React, { Component, Fragment } from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { humanize } from 'utils'

export class RegistrationShow extends Component{

  render = () => {
    const {registration, classes} = this.props
    return (
      <Fragment>
        <TableRow>
          <TableCell className={classes.head} variant='head'>VEN Name</TableCell>
          <TableCell>{String(registration.venName)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.head} variant='head'>Profile</TableCell>
          <TableCell>{String(registration.profileName)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.head} variant='head'>Transport</TableCell>
          <TableCell>{humanize(registration.transportName)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.head} variant='head'>HTTP Pull</TableCell>
          <TableCell>{String(registration.httpPullModel)}</TableCell>
        </TableRow>
        {
          registration.transportAddress &&
          <TableRow>
            <TableCell className={classes.head} variant='head'>Transport Address</TableCell>
            <TableCell>{String(registration.transportAddress)}</TableCell>
          </TableRow>
        }
        <TableRow>
          <TableCell className={classes.head} variant='head'>Report Only</TableCell>
          <TableCell>{String(registration.reportOnly)}</TableCell>
        </TableRow>
      </Fragment>
    )
  }
}

export default RegistrationShow
