import React, { Component } from 'react'
import withStyles from 'styles'
import Typography from '@material-ui/core/Typography'

export class Nav extends Component{

  render = () =>
    <nav className={this.props.classes.root}>
      <img alt='' src='/logo.png' className={this.props.classes.logo}/>
      <Typography variant='h6' className={this.props.classes.appTitle}>FlexPoint</Typography>
    </nav>

}

const styles = theme => ({
  root: {
    zIndex: 100,
    width: '100%',
    background: theme.palette.primary.light,
    borderTop: '1px solid rgba(255,255,255,0.5)',
    borderBottom: '1px solid rgba(255,255,255,0.5)',
    boxShadow: '0px 20px 80px 20px rgba(0,0,0,0.05)',
    position: 'absolute',
    top: 17,
    lineHeight: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '17px 12px',
    '@media(min-width: 600px)': {
      padding: '17px 26px',
    }
  },
  logo: {
    width: 250,
    height: 28
  },
  appTitle: {
    color: '#0b374f'
  }
})

export default withStyles(styles)(Nav)