import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { OrganisationRoleActions, OrganisationActions, SnackbarActions } from 'actionsets'
import { Link } from 'react-router-dom'
import { PermissionsTable } from 'components'
import Dependent from 'containers/shared/Dependent'
import { compose, errorStringsFromError } from 'utils'
import withStyles from 'styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import RoleIcon from '@material-ui/icons/AccountBox'
import DeleteIcon from '@material-ui/icons/Delete'

export class Show extends Component{

  constructor(props){
    super(props)
    OrganisationActions.bindActions(this, 'organisations')
    SnackbarActions.bindActions(this, 'snackbar')
    OrganisationRoleActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id, {
      include: 'registrationPermission,organisationPermission,systemPermission,organisation'
    })
  }

  dependenciesMet(){
    return !!this.organisationRole.id
  }

  componentDidMount(){
    this.actions.organisations.getPermissionNames(this.organisationId)
  }

  get id(){
    return this.props.match.params.id
  }

  get organisationId(){
    return (this.props.organisationRole.organisation && this.props.organisationRole.organisation.id)
  }

  get organisationRole(){
    return this.props.organisationRole
  }

  handleDelete = async () => {
    if(!global.confirm("Are you sure you want to delete this organisation role? This action cannot be undone."))
      return
    try{
      await this.actions.destroy({id: this.id})
      this.props.history.goBack()
      this.actions.snackbar.show('Organisation role deleted')
    }catch(err){
      this.actions.snackbar.show(`Could not delete organisation role.\n${errorStringsFromError(err).join("\n")}`)
    }
  }

  renderPermissionColumn = (title, {type, id, ...permissions}) => {
    const scope = type.replace(/Permissions?/,'')
    return this.props.permissionNames[scope] && <PermissionsTable
      className={this.props.classes.permissionsTable}
      title={title}
      rowNames={this.props.permissionNames[scope]}
      colNames={[]}
      columns={[permissions]}
    />
  }

  render = () =>
    <Card>
      <CardContent>
        <Typography variant='h4'>
          <RoleIcon style={{width: 45, height: 45, margin: '0 10px -10px 0'}}/>
          Organisation Role - {this.organisationRole.name}
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{width: 30}} variant='head'>Actions</TableCell>
              <TableCell>
                <Fragment>
                  <Link to={`/organisation_roles/${this.organisationRole.id}/edit`}>
                    <IconButton>
                      <EditIcon/>
                    </IconButton>
                  </Link>
                  <IconButton onClick={this.handleDelete}>
                    <DeleteIcon/>
                  </IconButton>
                </Fragment>
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell variant='head'>Name</TableCell>
                <TableCell>{String(this.organisationRole.name)}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <div className=''>
          {
            this.organisationRole.systemPermission &&
            this.renderPermissionColumn('System Permissions', this.organisationRole.systemPermission)
          }
          {
            this.organisationRole.organisationPermission &&
            this.renderPermissionColumn('Organisation Permissions', this.organisationRole.organisationPermission)
          }
          {
            this.organisationRole.registrationPermission &&
            this.renderPermissionColumn('Registration Permissions', this.organisationRole.registrationPermission)
          }
        </div>
        <div style={{clear: 'both'}}/>
        <br/>
      </CardContent>
    </Card>
}

const styles = {
  permissionsTable: {
    width: '33%',
    float: 'left',
    '@media(max-width: 680px)': {
      width: '100%',
      float: 'none'
    }
  }
}

export default compose(
  Dependent({loader: true, clearOnLoad: true}),
  withStyles(styles),
  connect(({organisationRoles, organisations: { permissionNames}}) => ({...organisationRoles, permissionNames}))
)(Show)