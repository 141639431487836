import React, { Component, Fragment } from 'react'
import { connect }          from 'react-redux'
import { ProgrammeActions, UserActions }    from 'actionsets'
import InstanceFormMixin    from 'containers/shared/InstanceFormMixin'
import Dependent            from 'containers/shared/Dependent'
import { FormContext, DatePicker,
         Tagger, LabeledSelect }      from 'components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { compose, Authorization } from 'utils'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from 'styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ProgrammeSignalMappings from './ProgrammeSignalMappings'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Availabilities, LabeledCheckbox } from 'components'

export class Form extends InstanceFormMixin(Component){

  constructor(props){
    super(props)
    ProgrammeActions.bindActions(this)
    UserActions.bindActions(this, 'users')

    this.state = {
      hasAvailabilities: null,
      availabilitiesUpdates: null
    }
  }

  dependsOn(){
    return this.editMode ?
      this.actions.show(this.objectId, {include: 'availabilities'}) :
      this.actions.set({})
  }

  get organisationId() {
    return this.props.organisationId || this.props.match.params.organisationId || Authorization.organisationId
  }

  get formObject(){
    let availabilitiesAttributes = {}

    if(this.isNonPriceResponsive) {
      if(this.state.hasAvailabilities === false) {
        availabilitiesAttributes = { availabilitiesAttributes: this.availabilities.map(({id}) => {
          return {id, _destroy: true}
        })}
      } else if(this.state.availabilitiesUpdates) {
        availabilitiesAttributes = { availabilitiesAttributes: this.state.availabilitiesUpdates.map(({weekDays, ...rest}) => {
          return {week_days: weekDays, ...rest}
        })}
      }
    }

    return { signalMappings: {}, ...this.props.programme, organisationId: this.organisationId, ...this.state.formAttributes, ...availabilitiesAttributes}
  }

  get programmeTypes(){
    return {
      "PriceResponsiveProgramme": "Price Responsive",
      "NonPriceResponsiveProgramme": "Non Price Responsive",
    }
  }

  get hasAvailabilities() {
    return (this.state.hasAvailabilities === null) ? this.availabilities.length !== 0 : this.state.hasAvailabilities
  }

  get availabilities() {
    return this.props.programme.availabilities || []
  }

  handleToggleProgrammeSignalMappings = () =>{
    if(this.usesAutoDR){
      this.setState({formAttributes: {...this.state.formAttributes, signalMappings: {}}, usesAutoDR: false})
    }else{
      this.setState({usesAutoDR: true})
    }
  }

  handleToggleHasAvailabilities = (event, checked) => {
    this.setState({ hasAvailabilities: checked })
  }

  handleAvailabilitiesChanged = ({updates, hasErrors}) => {
    this.setState({availabilitiesUpdates: updates, availabilitiesHasErrors: hasErrors})
  }

  get hasSignalMappings(){
    return Object.values(this.formObject.signalMappings).some(classes =>
      Object.values(classes).some(levels =>
        !!Object.values(levels).length
      )
    )
  }

  get usesAutoDR() {
    return this.state.usesAutoDR || this.hasSignalMappings
  }

  get isNonPriceResponsive() {
    const { type } = { ...this.props.programme, ...this.state.formAttributes}
    return type === 'NonPriceResponsiveProgramme'
  }

  get availabilitiesErrors ()  {
    const errContext = this.errorContext || {}
    return errContext['availabilities']
  }

  render = () =>
    <Card className={this.props.classes.card}>
      <Typography variant='h5'>Edit Programme - {this.formObject.name}</Typography>
      <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
        {this.renderErrorMessages()}
        <CardContent className={this.props.classes.cardContent}>
          <div className={this.props.classes.formFields}>
            <Typography variant='h6' className={this.props.classes.headerFields}>
              Fields
            </Typography>
            <TextField  required fullWidth member='name'/>
            <TextField fullWidth member='uri' label='Programme URI'/>
            <DatePicker required clearable fullWidth member='startDate'/>
            <DatePicker required clearable fullWidth member='endDate'/>
              <TextField fullWidth
                type='number'
                member='minimumLeadTime'
                InputProps={
                  {
                    min: 0,
                    startAdornment: <InputAdornment position="start">Minutes</InputAdornment>,
                  }
                }
            />
            <LabeledCheckbox label='Allows Establishment Fee' member='allowsEstablishmentFee'/>
            <LabeledSelect
              required fullWidth
              member='type'
              options={this.programmeTypes}
              label="Programme Type"
            />
            {
              this.isNonPriceResponsive &&
              <Fragment>
                <Typography variant='h6'>
                  Requirements
                </Typography>
                <div className={this.props.classes.npProgrammeRequirements}>
                  <LabeledCheckbox label='Fixed Price' member='requiresFixedPrice'/>
                  <LabeledCheckbox label='Availability Fee' member='requiresAvailabilityFee'/>
                  <LabeledCheckbox label='Pre-purchased Hours' member='requiresPrepurchasedHours'/>
                </div>
              </Fragment>
            }
            <FormControlLabel
              control={
                <Checkbox onChange={this.handleToggleProgrammeSignalMappings} type='checkbox' checked={this.usesAutoDR} />
              }
              label="Use AutoDR"
            />

            {
              this.usesAutoDR &&
              <ProgrammeSignalMappings context={this.formObject} onChange={this.handleFormObjectChange}/>
            }
            {
              (this.isNonPriceResponsive) &&
              <Fragment>
                <Typography variant='h6'>
                  Availabilities
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox onChange={this.handleToggleHasAvailabilities} type='checkbox' checked={this.hasAvailabilities} />
                  }
                  label="Has availabilities"
                />
                <Availabilities open={this.hasAvailabilities} availabilities={this.availabilities} readonly={false} onChange={this.handleAvailabilitiesChanged}
                                error={!!this.availabilitiesErrors} helperText={this.availabilitiesErrors}/>
              </Fragment>
            }
          </div>
          {
            Authorization.systemPermissions.writeTags &&
            <Fragment>
              <div className={this.props.classes.tags}>
                <Typography variant='h6'>
                  Eligibility Criteria
                </Typography>
                <Tagger canEditRestricted includeRestricted member='tags'/>
                <br />
                <Typography variant='h6'>
                  Devices Filter
                </Typography>
                <Tagger canEditRestricted includeRestricted member='deviceFilter'/>
                <br />
                <Typography variant='h6'>All Mode Descriptions</Typography>
                <Typography variant='subtitle1'>OpenADR</Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>DRM0</TableCell>
                      <TableCell>Normal service</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM1</TableCell>
                      <TableCell>Moderate</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM2</TableCell>
                      <TableCell>High</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM3</TableCell>
                      <TableCell>Special</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <br />
                <Typography variant='subtitle1'>Ambi Climate</Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>DRM0</TableCell>
                      <TableCell>Event pending</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM1</TableCell>
                      <TableCell>Low</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM2</TableCell>
                      <TableCell>Medium</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM3</TableCell>
                      <TableCell>High</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <br />
                <Typography variant='subtitle1'>FuturePoint</Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>DRM0</TableCell>
                      <TableCell>Operate disconnected device</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM1</TableCell>
                      <TableCell>Do not consume</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM2</TableCell>
                      <TableCell>Consume &lt; 50%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM3</TableCell>
                      <TableCell>Consume &lt; 75%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM4</TableCell>
                      <TableCell>Increase power consumption</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM5</TableCell>
                      <TableCell>Do not generate</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM6</TableCell>
                      <TableCell>Generate &lt; 50%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM7</TableCell>
                      <TableCell>Generate &lt; 75%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>DRM8</TableCell>
                      <TableCell>Increase power generation</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Fragment>
          }
        </CardContent>
        <CardActions>
          <Button color='secondary' fullWidth variant='contained' type='submit' disabled={this.state.availabilitiesHasErrors}>Save</Button>
        </CardActions>
      </FormContext>
    </Card>
}

const styles = theme => ({
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.viewAreaMaxWidth,
    padding: 20,
    margin: '0 auto',
    '& > form': {
      width: '100%'
    }
  },
  npProgrammeRequirements: {
    display: 'flex',
    flex: 1
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formFields: {
    alignContent: 'baseline',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: 10,
    minWidth: 300,
    marginBottom:10,
    '& > div': {
      flex: '0 1 auto'
    }
  },
  tags: {
    flex: 1,
    background: theme.palette.primary.background,
    padding: 10,
    minWidth: 300
  }
})


export default compose(
  Dependent({loader: true}),
  withStyles(styles),
  connect(({programmes, users: { users }}) => ({...programmes, users })),
)(Form)
