import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const Tags = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({tag_value_id}) => `tag_values/${tag_value_id}/tags`),
    create:  endpoint => endpoint.method('post').path(({tag_value_id}) => `tag_values/${tag_value_id}/tags`),
    update:  endpoint => endpoint.method('put').path(({id}) => `tags/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `tags/${id}`),
    show:    endpoint => endpoint.path(({id}) => `tags/${id}`),
  }
)