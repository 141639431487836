import React, { Component } from 'react'
import Dependent from 'containers/shared/Dependent'
import { connect } from 'react-redux'
import { withPermissions } from 'containers/shared'
import { compose } from 'utils'
import { MonthlyReportActions, SnackbarActions } from 'actionsets'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import withStyles from 'styles'
import { DatePicker, Pagination } from 'components'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import Download from '@material-ui/icons/CloudDownload'
import * as API from 'api'
import Tooltip from '@material-ui/core/Tooltip'

export class Show extends Component{

  constructor(props){
    super(props)
    MonthlyReportActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
    this.state = {
      userState: {
        users: [],
        count: 0,
        page: 1,
        totalPages: 1,
      },
      filter: {
        from:  moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate(),
      }
    }
  }

  static requiredPermissions = () => ({
    system: ['readSystemParams']
  })

  dependsOn(){
    return this.loadReports()
  }

  dependenciesMet(){
    return !!this.props.monthlyReport.id
  }

  loadReports = async () => {
    this.actions.show({filter: this.state.filter })
  }

  get databaseStorageInfo(){
    return this.props.monthlyReport.dbStorageInfo
  }

  handleDateFilterChange = which => ({target: { value }}) => {
    this.setState({filter: {...this.state.filter, [which]: value}}, this.loadReports)
  }

  handleMoveLeft = () => {
    this.traverse(-1)
  }

  handleMoveRight = () => {
    this.traverse(1)
  }

  handleUserPageChange = page => {
    this.setState({userState: {...this.state.userState, page}}, this.loadUsers)
  }

  componentDidMount = async () => {
    this.loadUsers()
  }

  handleDownload = (format) => {
    API.Users.download({
      options: {
        filter: {
          active: true,
        },
      },
    })
  }

  loadUsers = async () => {
    const { data: users, meta: { count, totalPages, page } } = await API.Users.index({
      options: {
        page: {
          number: this.state.userState.page,
        },
        filter: {
          active: true,
        },
        include: 'organisation',
        fields: { users: 'name,email,organisation,last_sign_in_at', organisations: 'name'}
      },
    })
    this.setState({userState: { count, page, totalPages, users }})
  }

  traverse = (numberOfMonths) => {
    const currentFrom = this.state.filter.from
    const currentTo = this.state.filter.to

    let nextFrom = undefined
    let nextTo = undefined

    if (currentFrom) {
      nextFrom = moment(currentFrom).add(numberOfMonths, 'months').toDate()
    }

    if (currentTo) {
      nextTo = moment(currentTo).add(numberOfMonths, 'months').toDate()
    }

    this.setState(
      {
        filter: {
          from: nextFrom,
          to: nextTo,
        }
      }, this.loadReports
    )
  }

  render = () =>
  <Card>
    <CardContent className={this.props.classes.typographyFormat}>
      <div className={this.props.classes.monthlyReportsWrapper}>
        <section className={this.props.classes.sectionHalfWidth}>
          <Typography variant='h4' className={this.props.classes.typographyFormat}>Monthly report</Typography>
          <Typography variant='h6' className={this.props.classes.typographyFormat}>Notifications filter</Typography>
          <section className={this.props.classes.filters}>
            <div className={this.props.classes.filterGroup}>
              <IconButton onClick={this.handleMoveLeft}>
                <ChevronLeft/>
              </IconButton>
              <DatePicker
                 className={this.props.classes('filterField', 'formControl')}
                 onChange={this.handleDateFilterChange('from')}
                 name='filterFrom'
                 value={this.state.filter.from}
                 label='From'
               />
               <DatePicker
                 className={this.props.classes('filterField', 'formControl')}
                 onChange={this.handleDateFilterChange('to')}
                 name='filterTo'
                 value={this.state.filter.to}
                 label='To'
               />
              <IconButton onClick={this.handleMoveRight}>
                <ChevronRight/>
              </IconButton>
           </div>
         </section>
         <div className={this.props.classes.tables}>
           <Table className={this.props.classes.table}>
             <TableBody>
              <TableRow>
               <TableCell className={this.props.classes.head} variant='head'>App</TableCell>
               <TableCell>{String(this.props.monthlyReport.numberOfEmailsOrTextsSent.app) === 'undefined' ? 0 : String(this.props.monthlyReport.numberOfEmailsOrTextsSent.app)}</TableCell>
              </TableRow>
              <TableRow>
               <TableCell className={this.props.classes.head} variant='head'>Emails sent</TableCell>
               <TableCell>{String(this.props.monthlyReport.numberOfEmailsOrTextsSent.email) === 'undefined' ? 0 : String(this.props.monthlyReport.numberOfEmailsOrTextsSent.email)}</TableCell>
              </TableRow>
              <TableRow>
               <TableCell className={this.props.classes.head} variant='head'>SMS sent</TableCell>
               <TableCell>{String(this.props.monthlyReport.numberOfEmailsOrTextsSent.sms) === 'undefined' ? 0 : String(this.props.monthlyReport.numberOfEmailsOrTextsSent.sms)}</TableCell>
              </TableRow>
              </TableBody>
            </Table>
            <Typography variant='h6' className={this.props.classes.typographyFormat}>Database storage</Typography>
            <Typography variant='subtitle1' className={this.props.classes.typographyFormat}>Top 10 Largest Tables</Typography>
            <Table className={this.props.classes.table}>
              <TableBody>
                {Object.entries(this.databaseStorageInfo).map(([key, value]) =>
                 <TableRow key={key.toString()}>
                  <TableCell className={this.props.classes.head}>
                    {String(key).replace(/_/g, " ")}
                  </TableCell>
                  <TableCell>
                    {value === 'undefined' ? 0 + " kB" : value}
                  </TableCell>
                 </TableRow>
                  )}
              </TableBody>
            </Table>
          </div>
        </section>
        <section className={this.props.classes.sectionHalfWidth}>
          <Typography variant='h4' className={this.props.classes.typographyFormat}>Active users</Typography>
          <Typography variant='h6'>Total: {this.state.userState.count} active users</Typography>
          <Tooltip title='Download Users list as a CSV file'>
            <IconButton onClick={this.handleDownload}>
              <Download/>
            </IconButton>
          </Tooltip>
          <Pagination totalPages={this.state.userState.totalPages} page={this.state.userState.page} onPageSelected={this.handleUserPageChange} style={{}} linkStyle={{}}/>
          <Table className={this.props.classes.table}>
            <TableHead className={this.props.classes.head}>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email address</TableCell>
                <TableCell>Organisation</TableCell>
                <TableCell>Last signed in</TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
            {this.state.userState.users && Object.entries(this.state.userState.users).map(([key, value]) =>
            <TableRow key={String(key)}>
              <TableCell>{value.name}</TableCell>
              <TableCell>{value.email}</TableCell>
              <TableCell>{value.organisation.name}</TableCell>
              <TableCell>{value.lastSignInAt ? moment(value.lastSignInAt).format('DD/MM/YYYY, h:mm:ss A') : "User hasn't signed in"}</TableCell>
            </TableRow>
            )}
          </TableBody>
         </Table>
        </section>
      </div>

    </CardContent>
  </Card>
}

const styles = theme => ({
  monthlyReportsWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'space-between'
  },
  sectionHalfWidth: {
    flex: '0 1 49%',
    minWidth:'400px',
    '@media(max-width: 850px)': {
      flex: '1 1 100%'
    },
  },
  typographyFormat: {
    marginTop: 10,
    marginBottom: 10,
    minWidth: '75%',
  },
  tables: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  table: {
    flex: 1,
    minWidth: '100%',
    borderSpacing: '0 3px',
    borderCollapse: 'separate',
    marginBottom: -2,
    marginTop: 0,
    '@media(max-width: 975px)': {
      width: '100%'
    },
    '& td': {
      background: 'rgba(255,255,255,0.03)',
      padding: '10px 15px',
      '&:last-child': {
        borderRight: '1px solid rgba(255,255,255,0.05)'
      },
      '&:first-child': {
        borderLeft: '1px solid rgba(255,255,255,0.05)'
      },
      borderTop: '1px solid rgba(255,255,255,0.05)',
      borderBottom: '1px solid rgba(255,255,255,0.05)'
    },
  },
  head: {
    textTransform: 'uppercase',
    width: "100px",
    margin: "10px 0",
    padding: "0 10px",
    background: 'rgba(255,255,255,0.06) !important',
    borderRight: '1px solid rgba(255,255,255,0.02)'
  },
  filters: {
    paddingBottom: '28px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterGroup: {
    flex: '1 1 auto',
    display: 'flex',
  },
  formControl:{
    paddingTop: '16px',
  },
  filterField: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
})

export default compose(
  Dependent({loader: true}),
  withPermissions(Show.requiredPermissions, {
    unmountOnChange: true,
    checkPermissions: (permissions) => {
      return permissions.system.readSystemParams
    }
  }),
  withStyles(styles),
  connect(({monthlyReport}) => monthlyReport)
)(Show)
