import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import {
  EnhancedTable, EnhancedTableHead, FormContext
} from 'components'
import { compose, Authorization, timeout, formatLocaleNumeric } from 'utils'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import { Link } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import InfoIcon from '@material-ui/icons/Info'
import ReceiptIcon from '@material-ui/icons/Receipt'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Switch from '@material-ui/core/Switch'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import { ErrorBanner }     from 'components'

import OfferChangesTable from './OfferChangesTable'
import moment from 'moment'

const REACT_APP_OFFERS_REFRESH_INTERVAL = parseInt(process.env.REACT_APP_OFFERS_REFRESH_INTERVAL, 10) || 10000

export class OffersTable extends Component{

  static propTypes = {
    context: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    errorContext: PropTypes.object,
    collapsed: PropTypes.bool,
    childEvents: PropTypes.array,
    defaultMwhPrice: PropTypes.number,
    onRequestRefreshOffers: PropTypes.func.isRequired,
    onAcceptAll: PropTypes.func.isRequired,
    parentEventStatus: PropTypes.string.isRequired,
    minimumLeadTime: PropTypes.number.isRequired,
    targetKw: PropTypes.number.isRequired,
    eventStart: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    selectionDisabled: PropTypes.bool,
    onShowChildEvent: PropTypes.func.isRequired
  }

  static defaultProps = {
    selectionDisabled: false,
    isPriceResponsive: true
  }

  constructor(props){
    super(props)
     this.state = {
      collapsed: false,
      orderBy: 'registration.name',
      orderDir: 'asc',
      selected: {},
      rowsPerPage: 10,
      page: 0,
      showChangesForOffers: null,
      hideOptedOutOffers: true,
      expiredLeadTime: false
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    this.pollOffers()
    this.pollMinimumLeadTime()
  }

  componentWillUnmount = () => {
    this._isMounted = false
    this.cancelPollMinimumLeadTime()
  }

  pollOffers = async () => {
    await timeout(REACT_APP_OFFERS_REFRESH_INTERVAL)
    if(this._isMounted && Authorization.user) {
      try {
        await this.props.onRequestRefreshOffers()
      } finally {
        this.pollOffers()
      }
    }
  }

  cancelPollMinimumLeadTime = () => {
    if(this._pollMinimumLeadTime) {
      clearTimeout(this._pollMinimumLeadTime)
    }
  }

  pollMinimumLeadTime = async () => {
    this._pollMinimumLeadTime = undefined
    if(!this._isMounted || !Authorization.user) {
      return
    }

    const now = moment()
    const start = moment(this.props.eventStart)

    if(!start.isValid()) {
      console.error(`${this.props.eventStart} is not a valid date.`)
      return
    }

    const leadTimeRemaining = start.diff(now, 'minutes')

    if(leadTimeRemaining < this.props.minimumLeadTime) {
      this.setState({
        expiredLeadTime: true
      })
    }

    let nextTimeout = start.add(- this.props.minimumLeadTime, 'minutes')
    let delayMilliseconds = nextTimeout.diff(now, 'milliseconds')
    if(delayMilliseconds > 0) {
      this._pollMinimumLeadTime = setTimeout(this.pollMinimumLeadTime, delayMilliseconds)
    }
  }

  handleShowOfferChanges = (offer) => () => {
    this.setState({ showChangesForOffers: [offer] })
  }

  handleShowChildEvent = (childEvent) => () => {
    this.props.onShowChildEvent(childEvent)
  }

  handleShowAllOfferChanges = () => {
    const offers = this.childEvents.map(({offer}) => offer).filter(offer => offer)
    this.setState({ showChangesForOffers: offers })
  }

  handleSelectChildEvent = (childEvent) => (event, checked) => {
    this.setState({
      selected: { ...this.state.selected, [childEvent.id]: checked }
    })
  }

  handleSelectAllChildEvents  = (event, checked) => {
    this.props.onAcceptAll(checked)
  }

  handleChangePage = page => {
    this.setState({page})
  }

  handleChangeRowsPerPage = ({target: { value: rowsPerPage}}) => {
    this.setState({rowsPerPage})
  }

  handleRequestSort =  (event, orderBy) => {
    const order = (this.state.orderBy === orderBy && this.state.orderDir === 'desc') ?
      'asc' :
      'desc'
    this.setState({orderBy,orderDir: order})
  }

  handleChangeHideOptedOutOffers = (event, checked) => {
    this.setState({ hideOptedOutOffers: checked })
  }

  get selectedChildEvents(){
    return this.childEvents.filter(ce => !!ce['accepted'])
  }

  get selectedCount(){
    return this.selectedChildEvents.length
  }

  get contributingChildEvents() {
    if(this.props.isPriceResponsive) {
      return this.selectedChildEvents.filter(ce => ce.offer && ce.offer.status === 'opted_in')
    } else {
      return this.selectedChildEvents
    }
  }

  get selectedMw(){
    if(this.props.isPriceResponsive) {
      return this.contributingChildEvents
                  .reduce((agg, {offer:{offerKw}}) => agg + offerKw || 0, 0) / 1000.0
    } else {
      return this.contributingChildEvents.reduce((agg, {targetKw}) => agg + targetKw || 0, 0) / 1000.0
    }
  }

  get offeredAutoDR(){
    const total = this.contributingChildEvents.reduce((agg, {offeredAutoDRKw}) => agg + parseFloat(offeredAutoDRKw || 0), 0)
    return total / 1000.0
  }

  get availableAutoDR(){
    return this.contributingChildEvents
        .map(ce => {
          let drLevel = this.props.isPriceResponsive ? ce.drLevel : this.props.drLevel
          try{ return parseFloat(ce.registration.availableDR[drLevel].kw || 0) }
          catch{ return 0 }
        })
        .reduce((agg, kw) => agg + kw || 0, 0) / 1000.0
  }

  get selectedCost(){
    if(this.props.isPriceResponsive) {
      return this.contributingChildEvents.reduce((agg, {offer: {offerKw, offerPrice}}) => {
        return agg + offerPrice * (offerKw / 1000.0) * this.props.eventDuration
      }, 0)
    } else {
      return this.contributingChildEvents.reduce((agg, {targetKw, registration: { fixedPrice }}) => {
        return agg + fixedPrice * (targetKw / 1000.0) * this.props.eventDuration
      }, 0)
    }
  }

  get childEvents(){
    return this.props.childEvents || []
  }

  get refinedChildEvents(){
    const asc = this.state.orderDir === 'asc'

    const extractSortAttribute = element => {
      try{
        const parts = this.state.orderBy.split('.')
        return parts.reduce((elm, part) => elm[part], element)
      }catch(err){
        return null
      }
    }

    const collection = this.childEvents.filter(this.shouldShowChildEvent)

    const selection = collection.sort((eventA,eventB) => {
      const [sortAttributeA, sortAttributeB] = [eventA, eventB].map(extractSortAttribute)
      if(sortAttributeA < sortAttributeB) return asc ? -1 : 1
      if(sortAttributeB < sortAttributeA) return asc ?  1 : -1
      return 0
    }).slice(
      this.state.page * this.state.rowsPerPage,
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
    )

    return {data: selection, count: collection.length}

  }

  get isOfferWindowOpen(){
    return this.props.parentEventStatus === 'offer_window_open'
  }

  get isOfferWindowClosed(){
    return this.props.parentEventStatus === 'offer_window_closed'
  }

  get isCompleted(){
    return this.props.parentEventStatus === 'completed'
  }

  get isPendingScheduled(){
    return ['draft', 'submitted', 'offer_window_open', 'offer_window_closed'].includes(this.props.parentEventStatus)
  }

  get isToggleShown(){
    return ['offer_window_closed', 'scheduled', 'active', 'cancelled', 'completed'].includes(this.props.parentEventStatus)
  }

  shouldShowChildEvent = (childEvent) => {
    return !(this.state.hideOptedOutOffers && ['cancelled', 'declined', 'withdrawn', 'ineligible'].includes(childEvent.status))
  }

  statusIcon = (childEvent) => {
    const { offer } = childEvent
    if(childEvent.status === 'ineligible' || childEvent.status === 'declined') {
      return <CloseIcon/>
    }

    if(!offer) {
      return null
    }

    const { status } = offer

    switch(status) {
      case 'opted_in':
        return <ThumbUpIcon/>
      case 'opted_out':
        return <ThumbDownIcon/>
      default:
        return null
    }
  }

  renderStatistics = () => {
    const offTargetPercentage = (this.selectedMw / (this.props.targetKw / 1000)) * 100
    const critical = Math.abs(offTargetPercentage - 100) >= 20
    const offTargetLabel = `(${offTargetPercentage.toFixed(2)}%)`

    return (
      <Table className={this.props.classes('statsSection', {hasSelection: !!this.selectedCount})}>
        <TableBody>
          <TableRow className={this.props.classes.statsItem}>
            <TableCell variant='head'>Accepted MW:</TableCell>
            <TableCell className={this.props.classes.statsValue}>
              {formatLocaleNumeric(this.selectedMw)}MW
              <Typography className={this.props.classes('offValue',{
                slightlyOffValue: !critical,
                criticalOffValue: critical
              })} variant='body2'>{offTargetLabel}</Typography>
            </TableCell>
            {
              (this.props.isAutoDRProgramme && this.props.isPriceResponsive) &&
              <Fragment>
                <TableCell variant='head'>Accepted Auto DR MW:</TableCell>
                <TableCell className={this.props.classes.statsValue}>
                  {formatLocaleNumeric(this.offeredAutoDR)}MW
                </TableCell>
              </Fragment>
            }
            {
              (this.props.isAutoDRProgramme && !this.props.isPriceResponsive) &&
              <Fragment>
                <TableCell variant='head'>Auto DR</TableCell>
                <TableCell className={this.props.classes.statsValue}>
                  {
                    this.isPendingScheduled ? formatLocaleNumeric(this.availableAutoDR) : formatLocaleNumeric(this.offeredAutoDR)
                  }MW
                </TableCell>
              </Fragment>
            }
            <TableCell variant='head'>Total Cost:</TableCell>
            <TableCell className={this.props.classes.statsValue}>${formatLocaleNumeric(this.selectedCost)}</TableCell>
            { this.props.isPriceResponsive &&
              <Fragment>
                <TableCell variant='head'>Changes:</TableCell>
                <TableCell className={this.props.classes.statsValue}>
                  <IconButton onClick={this.handleShowAllOfferChanges}><InfoIcon/></IconButton>
                </TableCell>
              </Fragment>
            }
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  renderOfferChangesDialog = () => {
    if(!this.state.showChangesForOffers) {
      return false
    }

    return (
      <Dialog open={true} onClose={() => this.setState({showChangesForOffers: null})} classes={{paper: this.props.classes.dialogPaper}}>
        <DialogTitle>Changes for offer</DialogTitle>
        <DialogContent>
          <OfferChangesTable offers={this.state.showChangesForOffers} />
        </DialogContent>
      </Dialog>
    )
  }

  renderChildEventRow = (childEvent) => {
    const {
      id,
      registration: {
        name: regName,
        id: regId,
        organisation: {
          name: orgName,
          id: orgId
        },
        indicativePrice,
        fixedPrice,
        availableDR,
        availabilityFee,
        prepurchasedHours,
      },
      index,
      targetKw,
      offeredAutoDRKw,
      mwhPrice,
      accepted,
      offer: {
        offerPrice, offerKw, drLevel, autoDRKw
      } = {}
    } = childEvent
    const offerKwStr = offerKw ? `${formatLocaleNumeric(offerKw)} kW` : "\u2014"
    const offerPriceStr = offerPrice ? `$${formatLocaleNumeric(offerPrice)} / MWh` : "\u2014"

    const selectionDisabled = (
      (this.isOfferWindowOpen || this.isOfferWindowClosed)
      && (childEvent.status === 'ineligible' || childEvent.status === 'declined')
      && !accepted
    ) || (
      this.isOfferWindowClosed
        && (!childEvent.offer || childEvent.offer.status !== 'opted_in')
        && !accepted
    )

    return (
      <TableRow key={`${id}-${index}`} className={ this.props.classes({childEventError: false}) }>
        { !this.props.selectionDisabled &&
          <TableCell>
            <div style={{marginLeft: -14}}>
              <Checkbox disabled={ selectionDisabled } type='checkbox' member={`childEventsAttributes[${index}].accepted`}/>
            </div>
          </TableCell>
        }
        <TableCell>
          <Link className={this.props.classes.tableCellLink} target="_blank" to={`/organisations/${orgId}`}>
            {orgName}
          </Link>
        </TableCell>
        <TableCell>
          <Link className={this.props.classes.tableCellLink} target="_blank" to={`/registrations/${regId}`}>
            {regName}
          </Link>
        </TableCell>
        { this.props.isPriceResponsive &&
          <Fragment>
            <TableCell>${formatLocaleNumeric(indicativePrice)}</TableCell>
            <TableCell>{formatLocaleNumeric(targetKw)} kW</TableCell>
            <TableCell>${formatLocaleNumeric(mwhPrice)} / MWh</TableCell>
            <TableCell>{offerKwStr}</TableCell>
            { this.props.isAutoDRProgramme && <TableCell>{drLevel}</TableCell> }
            { this.props.isAutoDRProgramme && <TableCell>{autoDRKw}</TableCell> }
            <TableCell>{offerPriceStr}</TableCell>
            <TableCell>{this.statusIcon(childEvent)}</TableCell>
            <TableCell>
              { childEvent.offer &&
                <Tooltip title="Offer change history">
                  <IconButton onClick={this.handleShowOfferChanges(childEvent.offer)}><InfoIcon/></IconButton>
                </Tooltip>
              }
            </TableCell>
            { this.isCompleted &&
              <TableCell>
                { accepted &&
                  <Tooltip title="Settlement">
                    <IconButton onClick={this.handleShowChildEvent(childEvent)}><ReceiptIcon/></IconButton>
                  </Tooltip>
                }
              </TableCell>
            }
          </Fragment>
        }
        { !this.props.isPriceResponsive &&
          <Fragment>
            <TableCell>${fixedPrice}</TableCell>
            <TableCell>${availabilityFee}</TableCell>
            <TableCell>{prepurchasedHours} Hours</TableCell>
            <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='targetKw'>{targetKw}</TableCell>
            { this.props.isAutoDRProgramme &&
              <TableCell>
                {
                  this.isPendingScheduled ? formatLocaleNumeric(availableDR[this.props.drLevel].kw || 0) : formatLocaleNumeric(offeredAutoDRKw)
                }
              </TableCell>
            }
          </Fragment>
        }
      </TableRow>
    )
  }

  renderErrorBanner = () => {
    const acceptedEventsWithOptedOutOffer = this.childEvents.filter(({accepted, offer}) => accepted && (!offer || offer.status !== 'opted_in'))
    const ineligibleAcceptedEvents = this.childEvents.filter(({accepted, status}) => accepted && (status === 'ineligible' || status === 'declined'))

    const notOptedInRegistrations = acceptedEventsWithOptedOutOffer.map(({registration: { name }}) => name)
    const ineligibleRegistrations = ineligibleAcceptedEvents.map(({registration: { name }}) => name)

    const eventError = this.props.errors.event

    const errorLines = []
    if(this.state.expiredLeadTime && this.isPendingScheduled) {
      errorLines.push('The minimum lead time for this programme has been exceeded')
    }

    if(this.isOfferWindowOpen || this.isOfferWindowClosed) {
      if(ineligibleRegistrations.length !== 0) {
        errorLines.push(`The events for the following registrations ${ineligibleRegistrations.join(', ')} are not eligible anymore.`)
      }
    }

    if(this.isOfferWindowClosed) {
      if(notOptedInRegistrations.length !== 0) {
        errorLines.push(`Participants for the following registrations ${notOptedInRegistrations.join(', ')} have not opted in.`)
      }
    }


    if(eventError) {
      let {title, message, meta: {childEvents} = {childEvents: []}} = eventError
      let overlappingIds = childEvents.map((id) => `${id}`)
      let overlappingEvents = this.childEvents.filter(({id, accepted}) => accepted && overlappingIds.indexOf(id) !== -1)
      let overlappingRegistrations = overlappingEvents.map(({registration: {name}}) => name)

      if(overlappingRegistrations.length !== 0){
        errorLines.push(`${title}: ${message}. Check registrations: ${overlappingRegistrations.join(', ')}`)
      }
    }

    if(errorLines.length === 0) {
      return
    }

    return (
      <ErrorBanner> { errorLines } </ErrorBanner>
    )
  }

  render = () => {
    const {data: childEventsToRender, count} = this.refinedChildEvents

    return (
      <section className={this.props.classes.registrationConfigSection}>
        { this.isToggleShown &&
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.hideOptedOutOffers}
                  onChange={this.handleChangeHideOptedOutOffers}
                />
              }
              label="Hide opted out Offers"
            />
          </FormControl>
        }
        { this.renderErrorBanner() }
        <FormContext context={this.props.context} errorContext={this.props.errorContext} onChange={this.props.onChange}>
          {
            !this.props.collapsed &&
            <EnhancedTable
              className={this.props.classes.offersTable}
              numSelected={this.selectedCount}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              count={count}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              toolbarContent={ this.renderStatistics() }
              selectedLabel='accepted'
            >
              <EnhancedTableHead
                title='Child Events'
                onRequestSort={this.handleRequestSort}
                onSelectAllClick={ !this.props.selectionDisabled && this.handleSelectAllChildEvents}
                orderBy={this.state.orderBy}
                order={this.state.orderDir}
                numSelected={this.selectedCount}
                rowCount={count}
              >
                <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='registration.organisation.name'>Organisation</TableCell>
                <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='registration.name'>Registration</TableCell>
                { this.props.isPriceResponsive &&
                  <Fragment>
                    <TableCell id='registration.indicativePrice'>Indicative Price</TableCell>
                    <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='targetKw'>Target kW</TableCell>
                    <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='mwhPrice'>MWh Price</TableCell>
                    <TableCell id='offer.offerKw'>Offered Amount</TableCell>
                    { this.props.isAutoDRProgramme && <TableCell id='offer.drLevel'>Offered DR Level</TableCell> }
                    { this.props.isAutoDRProgramme && <TableCell id='offer.drKW'>Offered DR kW</TableCell> }
                    <TableCell id='offer.offerPrice'>Offered price</TableCell>
                    <TableCell id='offer.status'>Offer status</TableCell>
                    <TableCell id='changes'>Changes</TableCell>
                    { this.isCompleted &&
                      <TableCell id='settlements'>Settlements</TableCell>
                    }
                  </Fragment>
                }
                { !this.props.isPriceResponsive &&
                  <Fragment>
                    <TableCell id='registration.fixedPrice'>Fixed Price</TableCell>
                    <TableCell id='registration.availabilityFee'>Availability Fee</TableCell>
                    <TableCell id='registration.prepurchasedHours'>Prepurchased Hours</TableCell>
                    <TableCell classes={{head: this.props.classes.tableHeaderCell}} id='targetKw'>Target kW</TableCell>
                    { this.props.isAutoDRProgramme && <TableCell id='offer.drKW'>Offered DR kW</TableCell> }
                  </Fragment>
                }
              </EnhancedTableHead>
              <TableBody>
                {childEventsToRender.map(this.renderChildEventRow)}
              </TableBody>
            </EnhancedTable>
          }
        </FormContext>
        { this.renderOfferChangesDialog() }
      </section>
    )
  }
}

const styles = theme => ({
  tableHeaderCell: {
    float: 'left'
  },
  tableCellLink: {
    '&:hover':{
      textDecoration: 'underline'
    }
  },
  dialogPaper: {
    width: '90vw'
  },
  offersTable: {
    overflow: 'hidden',
    border: `2px solid ${theme.palette.primary.main}`
  },
  registrationConfigSection: {
    position: 'relative',
    flex: '2 0 0%',
    minHeight: 100,
    minWidth: 300,
  },
  hasSelection: {

  },
  statsSection: {
    flex: '1 1 100%',
    flexWrap: 'wrap',
    '& td': {
      color: 'white',
      borderBottom: 'none !important'
    },
    '&$hasSelection': {
      '& td': {
        color: 'white'
      }
    }
  },
  statsItem: {
    flex: 1,
  },
  statsValue: {
    color: "#eaeaea",
    fontSize: "1.3em",
    textAlign: 'center'
  },
  childEventError: {
    backgroundColor: 'pink'
  },
  offValue: {
    fontSize: 13
  },
  criticalOffValue: {
    color: "white",
    background: "#b86363",
    borderRadius: "5px",
    padding: "0 10px",
    textAlign: "center",
  },
  slightlyOffValue: {
    color: "white",
    background: "#1b7927",
    borderRadius: "5px",
    padding: "0 10px",
    textAlign: "center"
  }
})

export default compose(
  withStyles(styles)
)(OffersTable)
