import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import qs from "qs"
import { TokenActions } from 'actionsets'
import { FormContext, CenteredCard, ErrorBanner, GradedPassword } from 'components'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography'

export class AcceptInvitation extends Component{

  constructor(props){
    super(props)
    TokenActions.bindActions(this)
    this.state = {}
  }

  componentDidMount() {
    if(!this.login) {
      setTimeout(this.submit, 0)
    }
  }

  get qsObject() {
    return this._qsObject || (this._qsObject = qs.parse(this.props.location.search.slice(1), {arrayFormat: 'repeat', allowDots: true}))
  }

  get token(){
    return this.qsObject.token
  }

  get login(){
    return !('login' in this.qsObject) || (this.qsObject.login !== 'false' && this.qsObject.login)
  }

  submit = async () => {
    const { acceptPassword, ...state } = this.state
    if(acceptPassword || !this.login) {
      await this.actions.acceptInvite({...state, token: this.token})
      if(this.login) {
        await this.actions.verify()
        window.location = "/"
      } else {
        this.props.history.push('/tokens/invitation_accepted')
      }
    }
  }

  errorFor = key => {
    try{
      return this.props.errors.acceptInvite.meta[key].join(', ')
    }catch(err){}
  }

  render = () =>
    <CenteredCard>
      <FormContext context={this.state} errorContext={{
        password:             this.errorFor('password'),
        passwordConfirmation: this.errorFor('passwordConfirmation'),
      }} onChange={state => this.setState(state)}
        onSubmit={this.submit}
      >
        <CardContent>
          {this.login ?
            <Fragment>
              <Typography variant='h6'>Accept your invitation</Typography>
              {this.props.errors.acceptInvite && <ErrorBanner>{this.props.errors.acceptInvite.title}</ErrorBanner>}
              <FormGroup>
                <GradedPassword fullWidth member='password' onAcceptanceChange={acceptPassword => this.setState({acceptPassword})} type='password' autoFocus/>
                <TextField fullWidth member='passwordConfirmation' type='password' label='Password'/>
              </FormGroup>
            </Fragment> :
            <Fragment>
              {this.props.errors.acceptInvite ?
                <ErrorBanner>{this.props.errors.acceptInvite.title}</ErrorBanner> :
                <Typography variant='h6'>Confirming your subscription</Typography>
              }
            </Fragment>
          }
        </CardContent>
        {this.login &&
          <CardActions>
            <Button variant='contained' color='secondary' fullWidth type="submit" disabled={this.login && !(this.state && this.state.acceptPassword)}>Submit</Button>
          </CardActions>
        }
      </FormContext>
    </CenteredCard>
}

export default connect(({tokens}) => tokens)(AcceptInvitation)