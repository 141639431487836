import React, { Component } from 'react'
import WidgetContainer from './WidgetContainer'
import { compose, formatLocaleNumeric } from 'utils'
import { connect } from 'react-redux'
import withStyles from 'styles'
import * as API from 'api'

export class CapacityWidget extends Component{
  state = {}

  async componentDidMount() {
    const result = await API.Reports.show(
    {
      type: 'registrations',
      options: {
        fields: {
          reportsRegistrations: 'availableMwAmount'          
        }
      }
    })    
    this.setState({capacity: result.data.availableMwAmount})
  }

  render = () => {
    return (
      <WidgetContainer className={this.props.classes.widgetContainer}>
        <p className={this.props.classes.value}>{this.state.capacity ? formatLocaleNumeric(this.state.capacity) : '...'}<small>MW</small></p>
        <small>Total DR Capacity</small>
      </WidgetContainer>
    )
  }
}

const styles = {
  value: {
    fontSize: 60,
    textAlign: 'center',
    margin: 0,
    lineHeight: 1,
    '& small': {
      opacity: '0.8',
      fontSize: '0.6em'
    }
  },
  disabled: {
    '& svg': {
      color: '#cacaca'
    }
  },
  widgetContainer: {
    textAlign: 'center',
    width: '100%',
    margin: '5px 0',
    justifyContent: 'center'
  }
}
export default compose(
  connect(),
  withStyles(styles)
)(CapacityWidget)