import React, { Component, Fragment } from 'react'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import { compose, formatLocaleNumeric, formatLocaleDateTime } from 'utils'
import withStyles from 'styles'
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import EventIcon from '@material-ui/icons/EventAvailable'
import EnergyIcon from '@material-ui/icons/ShowChart'
import BoltIcon from '@material-ui/icons/OfflineBolt'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import WalletIcon from '@material-ui/icons/AccountBalance'
import MuiTooltip from '@material-ui/core/Tooltip'
import TableChartIcon from '@material-ui/icons/TableChart'
import BarChartIcon from '@material-ui/icons/BarChart'
import { BarChart, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer } from 'recharts'
import {alpha} from '@material-ui/core/styles/colorManipulator'


const colors = [
  "#e6194b",
  "#3cb44b",
  "orange",
  "#0082c8",
  "#139EF5"
]

export class EventsResults extends Component{

  state = {
    expanded: {},
    mode: {}
  }

  get report(){
    return this.props.report
  }

  handleToggleExpansion = index => () => {
    this.setState({expanded: {...this.state.expanded, [index]: !this.state.expanded[index]}})
  }

  handleChangeMode = (parentEventId, mode) => () => {
    this.setState({mode: {...this.state.mode, [parentEventId]: mode}})
  }

  modeFor = (parentEventId) => {
    return this.state.mode[parentEventId] || 'numbers'
  }

  renderNumbersList = ({
    mwRequested,mwOffered,mwScheduled,mwDelivered,mwhRequested,mwhOffered,
    mwhScheduled,mwhDelivered,avgPriceRequested,avgPriceOffered,
    avgPriceScheduled,avgPriceDelivered,totalPaymentRequested,totalPaymentOffered,
    totalPaymentScheduled,totalPaymentDelivered
  }) =>
    <Fragment>
      <ListItem><ListItemIcon><BoltIcon/></ListItemIcon><ListItemText primary={`${formatLocaleNumeric(mwOffered)} MW`} secondary={"MW Offered"}/></ListItem>
      <ListItem><ListItemIcon><BoltIcon/></ListItemIcon><ListItemText primary={`${formatLocaleNumeric(mwScheduled)} MW`} secondary={"MW Scheduled"}/></ListItem>
      <ListItem><ListItemIcon><BoltIcon/></ListItemIcon><ListItemText primary={`${formatLocaleNumeric(mwDelivered)}/${formatLocaleNumeric(mwScheduled)} MW`} secondary={"MW Delivered"}/></ListItem>
      <ListItem><ListItemIcon><EnergyIcon/></ListItemIcon><ListItemText primary={`${formatLocaleNumeric(mwhRequested)} MWh`} secondary={"MWh Requested"}/></ListItem>
      <ListItem><ListItemIcon><EnergyIcon/></ListItemIcon><ListItemText primary={`${formatLocaleNumeric(mwhOffered)} MWh`} secondary={"MWh Offered"}/></ListItem>
      <ListItem><ListItemIcon><EnergyIcon/></ListItemIcon><ListItemText primary={`${formatLocaleNumeric(mwhScheduled)} MWh`} secondary={"MWh Scheduled"}/></ListItem>
      <ListItem><ListItemIcon><EnergyIcon/></ListItemIcon><ListItemText primary={`${formatLocaleNumeric(mwhDelivered)}/${formatLocaleNumeric(mwhScheduled)} MWh`} secondary={"MWh Delivered"}/></ListItem>
      <ListItem><ListItemIcon><MoneyIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(avgPriceRequested)}/MWh`} secondary={"VWAP Requested"}/></ListItem>
      <ListItem><ListItemIcon><MoneyIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(avgPriceOffered)}/MWh`} secondary={"VWAP Offered"}/></ListItem>
      <ListItem><ListItemIcon><MoneyIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(avgPriceScheduled)}/MWh`} secondary={"VWAP Scheduled"}/></ListItem>
      <ListItem><ListItemIcon><MoneyIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(avgPriceDelivered)}/MWh`} secondary={"VWAP Delivered"}/></ListItem>
      <ListItem><ListItemIcon><WalletIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(totalPaymentRequested)}`} secondary={"Total Payment for Requested"}/></ListItem>
      <ListItem><ListItemIcon><WalletIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(totalPaymentOffered)}`} secondary={"Total Payment for Offered"}/></ListItem>
      <ListItem><ListItemIcon><WalletIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(totalPaymentScheduled)}`} secondary={"Total Payment for Scheduled"}/></ListItem>
      <ListItem><ListItemIcon><WalletIcon/></ListItemIcon><ListItemText primary={`$${formatLocaleNumeric(totalPaymentDelivered)}`} secondary={"Total Payment for Delivered"}/></ListItem>
    </Fragment>


  renderCharts =({
    mwRequested,mwOffered,mwScheduled,mwDelivered,mwhRequested,mwhOffered,
    mwhScheduled,mwhDelivered,avgPriceRequested,avgPriceOffered,
    avgPriceScheduled,avgPriceDelivered,totalPaymentRequested,totalPaymentOffered,
    totalPaymentScheduled,totalPaymentDelivered
  }) =>
    <div className={this.props.classes.charts}>
      <div className={this.props.classes.chartPair}>
        {
          this.renderBarChart('Event MW', value => `${formatLocaleNumeric(value)} MW`,{
            'MW Requested': parseFloat(mwRequested),
            'MW Offered'  : parseFloat(mwOffered),
            'MW Scheduled': parseFloat(mwScheduled),
            'MW Delivered': [parseFloat(mwDelivered),parseFloat(mwScheduled)]
          })
        }
        {
          this.renderBarChart('Event MWh', value => `${formatLocaleNumeric(value)} MWh`,{
            'MWh Requested': parseFloat(mwhRequested),
            'MWh Offered'  : parseFloat(mwhOffered),
            'MWh Scheduled': parseFloat(mwhScheduled),
            'MWh Delivered': [parseFloat(mwhDelivered),parseFloat(mwhScheduled)]
          })
        }
      </div>
      <div className={this.props.classes.chartPair}>
        {
          this.renderBarChart('VWAP', value => `$${formatLocaleNumeric(value)} / MWh`,{
            'VWAP Requested': parseFloat(avgPriceRequested),
            'VWAP Offered'  : parseFloat(avgPriceOffered),
            'VWAP Scheduled': parseFloat(avgPriceScheduled),
            'VWAP Delivered': [parseFloat(avgPriceDelivered),parseFloat(avgPriceScheduled)]
          })
        }
        {
          this.renderBarChart('Total Payment', value => `$${formatLocaleNumeric(value)}`,{
            'Payment Requested': parseFloat(totalPaymentRequested),
            'Payment Offered'  : parseFloat(totalPaymentOffered),
            'Payment Scheduled': parseFloat(totalPaymentScheduled),
            'Payment Delivered': [parseFloat(totalPaymentDelivered),parseFloat(totalPaymentScheduled)]
          })
        }
      </div>
    </div>

  renderBarChart = (title, formatter, data) =>
    <div className={this.props.classes.barChart}>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart maxBarSize={25} data={[{name: title, ...Object.entries(data).reduce((data, [key, value]) =>
            (
              Array.isArray(value) ?
                {...data, [key]: value[0], [`${key}(Potential)`]: value[1] - value[0]} :
                {...data, [key]: value}
            ), {}
          )}]}>
           <XAxis dataKey="name"/>
           <YAxis type="number"/>
           <Tooltip itemStyle={{width: 'auto'}} formatter={formatter} cursor={false}/>
           {
             Object.entries(data).map(([key, value], index) =>
              Array.isArray(value) ?
                [
                  <Bar key={key} dataKey={key} stackId={key} fill={colors[index]}  stroke={colors[index]}  />,
                  <Bar key={`${key}_potential`} stackId={key} dataKey={`${key}(Potential)`} stroke={colors[index]} fill={'rgba(0, 0, 0, 0)'} />
                ] :
                <Bar key={key} dataKey={key} fill={colors[index]} />
              )
           }
        </BarChart>
      </ResponsiveContainer>
    </div>

  render = () =>
    <div className={this.props.classes.wrapper}>
      <List className={this.props.classes.statsSection} dense>
        <ListItem>
          <ListItemText secondary="Registered MW Amount" primary={`${formatLocaleNumeric(this.report.registeredMwAmount)} MW`}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Number of Events Called" primary={formatLocaleNumeric(this.report.numberOfEventsCalled)}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Number of Events Participated" primary={formatLocaleNumeric(this.report.numberOfEventsParticipated)}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Average Event Length" primary={`${formatLocaleNumeric(this.report.averageEventLength)} Min`}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Average Offer Price" primary={`$${formatLocaleNumeric(this.report.averageOfferPrice)}`}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Average Offer MW" primary={`${formatLocaleNumeric(this.report.averageOfferMw)} MW`}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Average Requested MW" primary={`${formatLocaleNumeric(this.report.averageRequestedMw)} MW`}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Average Performance" primary={`${formatLocaleNumeric(this.report.averagePerformance)}%`}/>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Total Payments" primary={`$${formatLocaleNumeric(this.report.totalPayment)}`}/>
        </ListItem>
      </List>
      <div className={this.props.classes.statsTable}>
          {
            this.report.eventBreakdown.map(({
              parentEventId, ...props
            }, index) => {
              const expended = !!this.state.expanded[index]
              const parentEvent = this.report.parentEvents.find(pe => `${pe.id}` === `${parentEventId}`)
              const description =
                <Fragment>
                  <EventIcon style={{float: 'left', marginRight: 5}}/>
                  <div style={{float: 'left'}}>
                    {`${parentEvent.programme.name} — ${props.mwDelivered || props.mwScheduled || props.mwRequested}MW\n${formatLocaleDateTime(parentEvent.start)} - ${formatLocaleDateTime(parentEvent.end)}`}</div>
                </Fragment>
              return (
                <div key={index} className={this.props.classes.eventRow}>
                  <Button onClick={this.handleToggleExpansion(index)} variant='contained' color="primary" className={this.props.classes('eventRowButton', parentEvent.status)}>
                    {description}
                  </Button>
                  {
                    expended &&
                    <div className={this.props.classes('buttonInner', 'expanded')}>
                      {
                        this.modeFor(parentEventId) === 'numbers' ?
                          this.renderNumbersList(props) :
                          this.renderCharts(props)
                      }
                      <div className={this.props.classes.toggleButtons}>
                        <MuiTooltip title="Summary">
                          <Button className={this.props.classes({disabled: this.modeFor(parentEventId) === 'numbers'})} onClick={this.handleChangeMode(parentEventId, 'numbers')}>
                            <TableChartIcon/>
                          </Button>
                        </MuiTooltip>
                        <MuiTooltip title="Charts">
                          <Button className={this.props.classes({disabled: this.modeFor(parentEventId) === 'charts'})} onClick={this.handleChangeMode(parentEventId, 'charts')}>
                            <BarChartIcon/>
                          </Button>
                        </MuiTooltip>
                      </div>
                    </div>
                  }
                </div>
              )
            })
          }
      </div>
    </div>

}

const styles = theme => ({
  barChart: {
    position: 'relative',
    flex: 1,
    height: 180,
    maxWidth: '100%',
    minWidth: 300,
    overflow: 'hidden'
  },
  chartPair: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '48%',
    flex: 1,
  },
  charts: {
    padding: 15,
    display: 'flex',
    flex: 1,
    minHeight: 100,
    flexWrap: 'wrap',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  toggleButtons: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    '& > button': {
      padding: 0,
      minWidth: 30,
      minHeight: 23,
      borderRadius: 0,
      '& svg': {
        fontSize: 15
      }
    }
  },
  disabled: {
    '& svg': {
      color: '#cacaca'
    }
  },
  buttonInner: {
    flex: 1,
    transition: 'max-height 1s ease',
    height: 'auto',
    position: 'relative',
    maxHeight: 0,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& li': {
      width: 200,
      '& span, & p': {
        fontSize: 13
      }
    }
  },
  expanded: {
    transition: 'max-height 1s ease',
    maxHeight: 1500
  },
  completed: {},
  eventRow: {
    overflow: 'hidden',
    flex: 1,
    marginBottom: 4,
    background: 'rgba(0, 27, 38, 0.5)',
  },
  eventRowButton: {
    padding: 10,
    whiteSpace: 'pre',
    width: '100%',
    display: 'block',
    textAlign: 'left',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(0, 27, 38, 0.5)'
    },
    borderRadius: 0,
    '&$completed': {
      background: alpha(theme.palette.secondary.main, 0.5),
      '&:hover': {
        background: alpha(theme.palette.secondary.dark, 0.5),
      },
    },
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
    overflow: 'hidden',
  },
  statsSection: {
    height: '100%',
    flex: '1 0 270px',
    background: "rgba(0, 27, 38, 0.5)",
    overflowY: 'auto',
    borderLeft: `3px solid ${theme.palette.secondary.light}`
  },
  statsTable: {
    height: '100%',
    flex: 25,
    minWidth: 320,
    overflowY: 'auto',
    marginTop: 4,
    marginLeft: 4
  }
})

export default compose(
  withStyles(styles)
)(EventsResults)
