import React, { Component } from 'react'
import withStyles from 'styles'
import { compose, eventKeyMatches, KEYS} from 'utils'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import * as API from 'api'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TableHead  from '@material-ui/core/TableHead'
import Table  from '@material-ui/core/Table'
import TableBody  from '@material-ui/core/TableBody'
import TableCell  from '@material-ui/core/TableCell'
import TableRow   from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { SnackbarActions } from 'actionsets'
import { connect } from 'react-redux'
import VenDetails from './VenDetails'

const PROMPTS = {
  // Profile 2.0a
  'A_E*_0420_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event that will start in one minute (dtstart=current time +1 minutes, one interval of 5 minutes, response required set to always). After configuring the event, wait at least 1.5  minutes before clicking the  resume button on this prompt. Please click 'Resume Test Execution' to continue" },
  'A_E*_0430_01': { description: "The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, three intervals of 1 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'E*_0430_01_PUSH_2.0a': { description: "Test execution has paused to allow for manual intervention. The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },

  'A_E*_0432_01': { description: "First Prompt: The VTN should be configured with so that there is a single pending event(dtstart=current time + 2 minutes, three intervals of 5 minutes). If supported by the VTN GUI, configure the following event characteristics:\n1)x-eiRecovery = 1 minute\n2)x-eiRamplUp = 1 minute\n3)startafter (random period) = 1 minute" },
  'A_E*_0435_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event that will start in one minute (dtstart=current time +1 minutes, three intervals of 1 minutes). The VTN should should also be configured such that it sends one or more eiTarget values such as resourceID or venID that match the test system properties configuration. If the DUT does not support configuration of eiTarget sub elements, skip this test case. Press continue when the event has been added. Please click 'Resume Test Execution' to continue" },
  'E*_0435_01_2.0a': { description: "The VTN should be configured so that there is a single pending event that will start in one minute (dtstart=current time +1 minutes, one interval of 5 minutes). The VTN should should also be configured such that it sends one or more eiTarget values such as partyID, resourceID, GroupID, or venID that match the test system properties configuration. If the DUT does not support configuration of eiTarget sub elements, skip this test case. Press continue when the event has been added. Please click 'Resume Test Execution' to continue" },

  'A_E*_0440_01': { description: "First Prompt: The VTN should be configured so that there are two non-overlapping pending events (two 1 hour events, 4 hours apart, response required set to always). Click resume when events have been added to the VTN. Please click 'Resume Test Execution' to continue"},
  'A_E*_0450_01': { description: "First Prompt: The VTN should be configured so that there are two non-overlapping pending events (two 1 hour events, 4 hours apart, response required set to always). Click resume when events have been added to the VTN. Please click 'Resume Test Execution' to continue"},
  'A_E*_0452_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 5 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0454_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 5 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0470_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 5 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0470_02': { description: "Second Prompt: The VTN should be configured such that the previously sent event is modified to extend the start time of the event by 1 minute, response required set to always.  Click resume when the modified event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0474_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 5 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0474_02': { description: "Second Prompt: The VTN should be configured such that the previously sent event is modified to extend the start time of the event by 1 minute, response required set to always.  Click resume when the modified event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0484_01': { description: "First Prompt: The VTN should be configured so that there is a single active event (dtstart=current time minus 1 minute, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0490_01': { description: "First Prompt: The VTN should be configured so that there is a single active event (dtstart=current time minus 1 minute, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0490_02': { description: "Second Prompt: The VTN should be configured such that the previously sent event is modified to extend the overall duration and last interval duration by one minute, response required set to always.  Click resume when the modified event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0492_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 5 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0492_02': { description: "Second Prompt: The VTN should be configured such that the previously sent event is cancelled, response required set to always.  Click resume when the modified event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0494_01': { description: "First Prompt: The VTN should be configured so that there is a single active event (dtstart=current time minus 1 minute, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0494_02': { description: "Second Prompt: The VTN should be configured such that the previously sent event is cancelled, response required set to always.  Click resume when the modified event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0496_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 5 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0496_02': { description: "Second Prompt: The VTN should be configured such that the previously sent event is cancelled, response required set to always.  Click resume when the modified event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0500_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0510_01': { description: "First Prompt: The VTN should be configured with three events. The configuration of events is as follows:\n1)MarketContext1, dtstart = current time (in active state), duration = 10 minutes, priority =2\n2)MarketContext1, dtstart = current time + 20 minutes, duration = 10 minutes. Priority=0\n3)MarketContext2, dtstart = current time + 60 minutes, duration = 10 minutes. Priority=0\nAll with response required set to always." },
  'A_E*_0525_01': { description: "First Prompt:  Please add the following events to the VTN:\n1)MarketContext1, dtstart= current time + 40 minutes, duration = 10 minutes, response required set to always. Priority=0\n2)MarketContext1, dtstart= current time, duration = 30 minutes, response required set to always. Priority=0\nAfter configuring event, wait 1 minute to ensure second configured event is active.\nClick resume when the events have been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0525_02': { description: "Second Prompt: Please modify the previously sent events as follows:\n1)Cancel event with earliest start time\nClick resume when the events have been added to the VTN" },
  'A_E*_0527_PULL_01': { description: "Test execution has paused to allow for manual intervention. The VTN should be configured so that there is a single pending event that will start in 3 minutes (dtstart=current time +3 minutes, one interval of 5 minutes). Set x-RampUp time to 4 minutes. If the VTN does not support configuration of rampUp, skip this test case. After configuring the event, wait at least 1.5  minutes before clicking the  resume button on this prompt. Please click 'Resume Test Execution' to continue" },
  'A_E*_0527_PUSH_01': { description: "First Push Prompt:Test execution has paused to allow for manual intervention. The VTN should be configured so that there is a single pending event that will start in 5 minutes (dtstart=current time +5 minutes, one interval of 5 minutes). Set x-RampUp time to 4 minutes. If the VTN does not support configuration of rampUp, skip this test case. Click the  resume button to continue after adding event. Please click 'Resume Test Execution' to continue" },
  'A_E*_0527_PUSH_02': { description: "Second Push Prompt: Test execution has paused to allow for manual intervention. The VTN should be configured with a new pending event that will start in 10 minutes(dtstart=current time +10 minutes, one interval of 5 minutes). Please click 'Resume Test Execution' to continue" },

  'A_E*_0655_01': { description: "First Prompt: The VTN should be configured so that there are three non-overlapping pending events (three 1 hour events, each 2 hours apart, response required set to always). The first event's dtstart should be set to current time + 10 minutes.  Click resume when events have been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0657_01': { description: "First Prompt: Please add the following event to the VTN:\n1)MarketContext1, dtstart= current time + 1 minute, duration = 3 minutes\nClick resume when the events have been added to the VTN.\nPlease click 'Resume Test Execution' to continue" },
  'A_E*_0657_02': { description: "Second Prompt: Please modify the previously sent events as follows:\n1)Cancel event\nClick resume when the events have been added to the VTN. Please click 'Resume Test Execution' to continue" },

  'E*_0660_01_2.0a': { description: "Test execution has paused to allow for manual intervention. The VTN should be configured for one event starting in 1 hour with a duration of 1 hour, response required set to always.   Click resume when the  event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'E*_0660_02_2.0a': { description: "Test execution has paused to allow for manual intervention. The VTN should be configured for one CPP event starting in 1 minute with a duration of 30 minutes, response required set to always. Click resume when the  event has been added to the VTN. Please click 'Resume Test Execution' to continue" },

  'E2_0670_01_2.0a': { description: "The push VTN should be able to concurrently communicate via a pull model. This test case sends one event to the VEN, then the Test Harness VEN will poll the VTN for events. The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },

  'A_E*_0680_01': { description: "First Prompt: Please add the following events to the VTN:\n1)dtstart= current time + 41 minutes, duration = 10 minutes.\n2) dtstart= current time + 10 minutes, duration = 30 minutes\nClick resume when the events have been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0680_PUSH_02': { description: "Second Push Prompt: Please add one additional event to the VTN as follows:\ndtstart= current time + 2 hours, duration = 10 minutes, response required set to always\nClick resume when the events have been added to the VTN. Please click 'Resume Test Execution' to continue" },

  'A_E*_0685_01': { description: "First Prompt: Please add the following event to the VTN:\n1)MarketContext1, dtstart= current time + 1 minute, duration = 1 minutes, with startafter randomization equal to 2 \nThe test case will pause for approximately 3 minutes after sending the first event." },
  'A_E*_0685_02': { description: "Second Prompt: Please add a new event to the VTN to send it in approximately 30 seconds (dtstart = current time plus 1 minute, duration = 1 minute). Click resume when the events have been added to VTN. Please click 'Resume Test Execution' to continue" },

  'A_E*_0710_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue"},
  'A_E*_0720_01': { description: "First Prompt:The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0730_01': { description: "First Prompt:The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0750_01': { description: "First Prompt: The VTN should be configured for two non-overlapping  CPP events  (dtstart at current time plus 1 hour and 2 hours respectively, both with 30 minute durations, response required set to always).  Click resume when the  event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'A_E*_0780_01': { description: "First Prompt: The VTN should be configured so that there is a single pending event (dtstart=current time + 2 minutes, one interval of 5 minutes, response required set to always). Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },

  // Profile 2.0b
  // 'Prompt_001': 'Prompt_001: Force the VEN DUT to send a oadrQueryRegistrationPayload. Press Resume on this prompt when this action has been initiated. ',
  // 'Prompt_002': 'Prompt_002: Please make sure that the DUT is in a unregistered state with respect to the Test Harness.',
  'Prompt_002_01': { description: 'Preallocate VEN ID (Will remove existing VEN with the same ID if present).', params: { ven_id: 'TH_VEN'} },
  // 'Prompt_003': 'Prompt_003: Cycle the power or reset the DUT VEN to initiate a device and report registration sequence.',
  // 'Prompt_004': 'Prompt_004: Please initiate a registration cancellation on the VEN DUT',
  // 'Prompt_005': 'Prompt_005: Please note that this test case leaves the relationship between the DUT and the TH in an unregistered state.',
  // 'Prompt_006': 'Prompt_006: Please note that this test case assumes the DUT and the Test Harness are in a registered state.',
  'Prompt_007': { description: 'Please initiate a registration cancellation on the VTN DUT' },
  'Prompt_008': { description: 'Please initiate a re-registration request on the VTN DUT' },
  'Prompt_009': { description: 'If possible, cause the VTN DUT to send an empty oadrDistributeEvent payload. Resume test execution whether or not the VTN DUT can be so configured.' },
  'Prompt_010': { description: 'The VTN DUT should be configured so that there is a single pending ELECTRICITY_PRICE event (dtstart=current time + 10 minutes, one interval of 5 minutes) with two resources configured. Click resume when event has been added to the VTN.' },
  'Prompt_010_2.0a': { description: "Prompt_010: The VTN DUT should be configured so that there is a single pending event (dtstart=current time + 10 minutes, one interval of 5 minutes) with two resources configured. Click resume when event has been added to the VTN. Please click 'Resume Test Execution' to continue" },
  'Prompt_011': { description: 'Modify the currently pending event on the VTN DUT such that the duration of the first interval is 10 minutes longer. Click resume when event has been modified.' },
  'Prompt_012': { description: 'The VTN DUT should be configured with two events:\n\nEvent_001 \n dtstart= CurrentTime + 1 minutes, duration = 5 minutes \n SIMPLE, signalType = level \n Interval #1, 5 minutes, signalPayload = 2 \n\nEvent_002 \n dtstart= CurrentTime + 10 minutes, duration = 5 minutes\n ELECTRICITY_PRICE, signalType = price, units = currencyPerKWh\n Interval #1, 5 minutes, signalPayload = 3.14\n Two Resources defined\n\nClick resume when events have been added to the VTN.' },
  'Prompt_019': { description: 'Configure the VTN DUT such that it will send a report request as follows: TELEMETRY_STATUS Report, granularity = PT0M, reportBackDuration = PT0M.' },
  'Prompt_020': { description: 'Configure the VTN DUT such that it will send a report request as follows: TELEMETRY_STATUS Report, granularity = 1 minute or oadrMin sampling rate, whichever is greater, reportBackDuration = granularity, reportInterval:dtstart = CurrentTime, reportInterval:duration = 5 minutes.' },
  'Prompt_021': { description: 'Configure the VTN DUT such that it will send a report request as follows: TELEMETRY_STATUS Report, granularity = 1 minute or oadrMin sampling rate, whichever is greater, reportBackDuration = granularity, reportInterval:dtstart = CurrentTime+15 seconds, reportInterval:duration=10 minutes.' },
  'Prompt_022': { description: 'Configure the VTN DUT such that it will send a report cancellation request.'},
  'Prompt_023_Event_003': { description: '\nFirst Prompt: The VTN DUT should be configured so that there is a single event configured as follows:\ndtstart= CurrentTime + 1 minute, duration = 5 minutes\nSignal 1 : SIMPLE, signalType = level\nInterval #1, 5 minutes, signalPayload = 2\nSignal 2 ELECTRICITY_PRICE, signalType = price, units  = currencyPerKWh\nInterval #1, 5 minutes, signalPayload = 3.14.' },
  'E*_*055_01': { description: '\nFirst Prompt: The VTN DUT should be configured so that there is a single event configured as follows:\ndtstart= CurrentTime + 1 minute, duration = 5 minutes\nSignal 1 : SIMPLE, signalType = level\nInterval #1, 5 minutes, signalPayload = 2\nSignal 2 ELECTRICITY_PRICE, signalType = price, units  = currencyPerKWh\nInterval #1, 5 minutes, signalPayload = 3.14.' },
  'Prompt_038': { description: 'The VTN DUT should be configured so that there is a single pending event configured as follows: dtstart = current time + 1 minute, duration = 5 minutes, LOAD_DISPATCH, signalType = setpoint, units = powerReal, interval #1, 5 minutes, signalPayload = 40, device class target defined (eiSignal:eiTarget). Click resume when event has been added to the VTN.' },
  'Prompt_041': { description: 'The VTN DUT should be configured so that there is a single pending event configured as follows:\ndtstart = current time + 10 minutes, duration = 2 hours\nELECTRICITY_PRICE, signalType = price, units  = currencyPerKWh\nInterval #1, 1 hour, signalPayload = 3.14\nInterval #2, 1 hour, signalPayload = 3.17\nTwo Resources defined\n\nBaseline\ndtstart = current time minus- one day, duration = 2 hours\nUnit=Currency/KWh\nInterval #1, 1 hour, signalPayload = 3.05\nInterval #2, 1 hour, signalPayload = 3.08.\nNote that this event includes a baseline. Click resume when event has been added to the VTN.' },
  'Prompt_043': { description: "The DUT VTN should be configured to request data point(s) with at least one of EnergyReal or PowerReal or PulseCount for a HISTORY_USAGE report containing all the available data from current time to the end of its buffer (60 minutes).\nHISTORY_USAGE Report (Full Report)\n-granularity = 0\n-reportBackDuration = PT0M\n-reportInterval:dtstart = CurrentTime\n-reportInterval:duration = PT0M (for all data)\n-rID values with at least one of EnergyReal or PowerReal or PulseCount\n"},
  'Prompt_044': { description: "The DUT VTN should be configured to request energyReal data point for a HISTORY_USAGE report containing all the available data from current time minus 30 minutes  to the end of its buffer (30 minutes).\nHISTORY_USAGE Report (Partial Report)\n-granularity = 0\n-reportBackDuration = PT0M\n-reportInterval:dtstart = CurrentTime minus 30 minutes\n-reportInterval:duration = PT30M \n-rID value - for energyReal\n" },
  'Prompt_045': { description: "The DUT VTN should be configured to request powerReal and energyReal data point for a one-shot TELEMETRY_USAGE report containing one data sample. Granularity should be set to 1 minute and reportBackDuration to zero.\nTELEMETRY_USAGE Report\n-granularity = minimum sampling rate\n-reportBackDuration = PT0M\n-rID values for powerReal and energyReal\n"},
  'Prompt_046': { description: "The DUT VTN should be configured to request powerReal  data point for a periodic TELEMETRY_USAGE report containing one data sample.\nTELEMETRY_USAGE Report\n-granularity = 1 minute\n-reportBackDuration = 2 minutes\n-reportInterval:dtstart = CurrentTime + 15 seconds\n-reportInterval:duration = 10 minutes\n-rID value - for powerReal\n" },
  'Prompt_052': { description: 'Cause the VTN to republish its metadata report.'},
  'Prompt_055': { description: 'Configure the VTN DUT such that it will send a report request as follows: TELEMETRY_STATUS Report, Granularity = PT0M (on change), reportBackDuration = PT1M, reportInterval:dtstart = CurrentTime + 2 minute, reportInterval:duration = 5 minutes'},
  'Prompt_056': { description: 'Configure the VTN DUT such that it will send a report request as follows:\n\n-TELEMETRY_STATUS Report\n-granularity = 1 minute \n-reportBackDuration = granularity\n-reportInterval:dtstart = CurrentTime\n-reportInterval:duration = 0 minutes\n' },
  'Prompt_057': { description: 'Configure the VTN DUT such that it will send a report cancellation request with reporToFollow set to true.' },
  'Prompt_058': { description: 'Configure the VTN DUT such that it will send a two report request as follows:\n\n-TELEMETRY_STATUS Report\n-Granularity = PT0M - will be Ignored\n-reportBackDuration = PT0M\n-Do not include report Interval as this is a one shot report\n\nTELEMETRY_USAGE Report\n-granularity = minimum sampling rate\n-reportBackDuration = PT0M\n-Do not include report Interval as this is a one shot report\n-rID values for powerReal and energyReal\n' },
  // 'Prompt_059': { description: 'This test case is for self test only and should not be run against the DUT.' },
  // 'Prompt_060': { description: 'The VEN should issue 2 reports, one immediately and another in 1 minute. The text case will continue to run for approximately 3 minutes after pressing Resume.' },
  // 'Prompt_061': { description: 'The test case sends a payload to the DUT, but expects either no response or oadrRegisteredReport/oadrResponse with Error ResponseCode 463. If running in self test, only one side of the conversation can initiate the attempt to send a payload. Press Yes if testing a real device or initiating the payload in Self Test, otherwise answer No.' },
  // 'Prompt_062': { description: "B profile VTN must support concurrent interoperation with A and B profile VENs. Please configure the A and B test harness such that they can communicate with VTN using non-conflicting VEN ID's and one of the supported transports and exchange models (push or pull) on each test harness. Run the registration test case on the B profile test harness (Nx0020_TH_VEN). Then alternately run 2 randomly selected test cases from each test harness on the device under test. Answer this prompt Yes if the alternately run  test cases passed without needing to configure the DUT VTN between executions, Answer No if any test cases failed or configuration was required when switching execution between test harnesses." },
  // 'Prompt_063': { description: "" },
  'Prompt_064': { description: 'Configure the VTN DUT such that it will send a report request as follows: ,TELEMETRY_STATUS Report ,Granularity = PT1M ,reportBackDuration = PT1M ,reportInterval:dtstart = CurrentTime ,reportInterval:duration = 2 minutes'},
  'Prompt_065': { description: 'The VTN DUT should be configured so that there is a single pending event configured as follows:\n\ndtstart = CurrentTime + 1 minutes, duration = 5 minutes\nSIMPLE, signalType = level\nInterval #1, 5 minutes, signalPayload = 2\nClick resume when event has been added to the VTN.' },
  'Prompt_073': { description: 'The VTN DUT should be configured to add an additional ELECTRITY_PRICE event configured as follows: : A pending event (dtstart=current time + 25 minutes, one interval of 5 minutes) with two resources configured. Click resume when event has been added to the VTN.' },
}

export class Prompts20b extends Component {

  constructor(props) {
    super(props)
    SnackbarActions.bindActions(this, 'snackbar')

    let params = Object.entries(PROMPTS).reduce((acc, [prompt, {description, params={}}]) => {
      acc[prompt] = params
      return acc
    }, {})

    this.state = {
      pendingPrompt: null,
      params: params,
      drawerOpen: false
    }
  }

  handleVenSelected = venID => {
    this.setState({venID})
  }

  promptHandler = (prompt) => {
    return async () => {
      this.setState({pendingPrompt: prompt})
      try {
        let callParams = {prompt, venID: this.state.venID, ...this.state.params[prompt]}
        await API.OpenADRCertTest.prompt(callParams)
        this.actions.snackbar.show(`Success !`)
      } catch({body: {error}}) {
        console.log(error)
        global.alert(`ERROR: ${error.message}`)
      } finally {
        this.setState({pendingPrompt: null, drawerOpen: false})
      }
    }
  }

  paramChangedHandler = (prompt, param) => {
    return (event) => {
      const {target: { value }} = event
      console.log(value)
      this.setState({params: {...this.state.params, [prompt]: {...this.state.params[prompt], [param]: value}}})
    }
  }

  paramValue = (prompt, param) => {
    return this.state.params[prompt][param]
  }

  shouldShowPrompt = ([prompt, {description, params={}}]) =>{
    const haystack = `${prompt} ${description}`.toLowerCase()
    return !this.state.filter || this.state.filter.toLowerCase().split(/\s+/).every(part => haystack.match(part))
  }

  handleFilterKeyDown = event => {
    if(eventKeyMatches(event, KEYS.enter)){
      if(this.prompts[0]){
        this.promptHandler(this.prompts[0][0])()
      }else{
        this.actions.snackbar.show("No prompt selected")
      }
    }
  }

  handleFilterChange = ({target: { value: filter }}) =>
    this.setState({filter})

  get prompts(){
    return Object.entries(PROMPTS).filter(this.shouldShowPrompt)
  }

  renderPrompts = () =>
    <div className={this.props.classes.promptsTable}>
      <TextField fullWidth className={this.props.classes.promptFilter} onChange={this.handleFilterChange} placeholder='Type to Filter, Enter to execute' autoFocus onKeyDown={this.handleFilterKeyDown}/>
      <Table classes={{table: this.props.classes.table}}>
        <TableHead>
          <TableRow>
            <TableCell variant='head' style={{width: 145}}>Prompt</TableCell>
            <TableCell variant='head' style={{width: 145}}>Params</TableCell>
            <TableCell variant='head'>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          this.prompts.map(([prompt, {description, params={}}], index) => (
            <TableRow key={prompt} className={this.props.classes({selected: index === 0})}>
              <TableCell  className={this.props.classes.prompt}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={this.promptHandler(prompt)}
                    disabled={!!this.state.pendingPrompt}
                  >
                    {(prompt === this.state.pendingPrompt) ? <CircularProgress className={this.props.classes.progress}/>  : prompt}
                  </Button>
              </TableCell>
               <TableCell className={this.props.classes.prompt}>
                  {
                    Object.entries(params).map(([param, defaultValue]) => {
                      return  (
                        <TextField key='param' label={param} value={this.paramValue(prompt, param)} onChange={this.paramChangedHandler(prompt, param)}/>
                      )
                    })
                  }
              </TableCell>
              <TableCell>
                <span className={this.props.classes.description}>{description}</span>
              </TableCell>
            </TableRow>
          ))
        }
        </TableBody>
      </Table>
    </div>

  render = () => {
    return (
      <Card className={this.props.classes.root}>
        <CardContent className={this.props.classes.content}>
          <div className={this.props.classes.header}>
            <Typography variant='h5'>Prompts for OpenADR20bCertTest_v1_1_2</Typography>
            <IconButton color="inherit" aria-label="Menu" onClick={() => this.setState({drawerOpen: true})}>
              <MenuIcon/>
            </IconButton>
          </div>
          <section className={this.props.classes.promptsContainer}>
            <div className={this.props.classes.venDetails}>
              <VenDetails onChange={this.handleVenSelected}/>
            </div>
          </section>
        </CardContent>
        <Drawer
          anchor="right"
          classes={{paper: this.props.classes.drawer}}
          open={this.state.drawerOpen}
          onClose={() => this.setState({drawerOpen: false})}
          // onClick={() => this.setState({drawerOpen: false})}
        >
          { this.renderPrompts() }
        </Drawer>
      </Card>
    )
  }
}

const styles = theme => ({
  content: {

  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  venDetails: {
    flex: '1 1 400px',
    minWidth: 300,
    minHeight: 200
  },
  promptsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  promptFilter: {
    flex: 1,
    minWidth: '100%',
    '& input': {
      fontSize: 20,
      textAlign: 'center',
      marginTop: 20,
      padding: 15
    }
  },
  promptsTable: {
    maxWidth: '600px',
    minWidth: 300,
    flex: '1 1 400px',
    '& table': {
      tableLayout: 'fixed'
    },
    '@media(max-width: 860px)': {
      maxHeight: 500,
      overflowY: 'auto'
    }
  },
  root: {
    paddingTop: '50px'
  },
  selected: {
    background: 'yellow'
  },
  progress: {
    marginLeft: '10px',
  },
  prompt: {
    '& button': {
      width: 140
    }
  },
  description: {
    color: "#555",
    lineHeight: 2,
    fontSize: 14,
    padding: '2px 10px'
  },
  table: {
    '& th, & td': {
      width: 'auto',
      border: 'none !important'
    }
  }
})

export default compose(
  connect(),
  withStyles(styles),
)(Prompts20b)
