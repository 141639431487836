import React, { Component, Fragment } from 'react'
import { UserRole, OrganisationRole } from 'containers'
import { Authorization } from 'utils'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import Tab from '@material-ui/core/Tab'
import { withPermissions } from 'containers/shared'
import { compose } from 'utils'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import withStyles from 'styles'

export class List extends Component{

  static requiredPermissions = ({organisationId}) => ({
    organisation: {
      [organisationId || Authorization.organisationId]: [
        'readOrganisation', 'writeOrganisation',
        'readUsers', 'writeUsers'
      ]
    }
  })

  static propTypes = {

  }

  state = {}

  get organisationId(){
    return this.props.organisationId || Authorization.organisationId
  }

  get selectedTab(){
    if(this.props.match) {
      return this.props.match.params.tab || (this.permissions.readUsers ? 'user' : 'organisation')
    }else{
      return this.state.tab || (this.permissions.readUsers ? 'user' : 'organisation')
    }
  }

  get permissions(){
    return {
      ...(this.props.permissions.organisation || {})[this.organisationId],
    }
  }

  isTabVisible = (value) => {
    const hasPermission = value === 'user' ? this.permissions.readUsers : this.permissions.readOrganisation
    return this.selectedTab === value && hasPermission
  }

  handleTabChange = (event, tab) => {
    if(this.props.match) {
      this.props.history.push(`/roles/${tab}`)
    }else{
      this.setState({tab})
    }
  }

  render = () => {
    return this.props.borderless ?
      this.renderBody() :
      <div className={this.props.classes.wrapper}>
        <Card>
          <CardContent>
            {this.renderBody()}
          </CardContent>
        </Card>
      </div>
  }

  renderBody = () =>
    <Fragment>
      <Typography variant='h5'>Roles</Typography>
      <Tabs onChange={this.handleTabChange} value={this.selectedTab}>
        { this.permissions.readUsers && <Tab value='user' label='User Roles'/>}
        { this.permissions.readOrganisation && <Tab value='organisation' label='Organisation Roles'/>}
      </Tabs>
      { this.isTabVisible('user') && <UserRole.List  organisationPermissions={this.permissions} organisationId={this.organisationId}/> }
      { this.isTabVisible('organisation') && <OrganisationRole.List  organisationPermissions={this.permissions} organisationId={this.organisationId}/> }
    </Fragment>

}

const styles = {
  wrapper: {

  }
}
export default compose(
  withStyles(styles),
  withPermissions(List.requiredPermissions),
  connect(),
)(List)