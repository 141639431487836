import React, { Component } from 'react'
import { LabeledSwitch } from 'components'
import { capitalize, shallowMemoize } from 'utils'
import Typography from '@material-ui/core/Typography'

export default class StatusFilter extends Component {
  valuesCache = shallowMemoize((selected,available) => {
    const [selectedList,availableList] = [selected,available].map(s => s.split(","))
    return selectedList.reduce((acc,str) => ({...acc, [str]: true}), availableList.reduce((acc,str) => ({...acc, [str]: false}), {}))
  })

  onToggle = which => event => {
    const values = this.valuesCache(this.props.value, this.props.statuses)
    this.props.onChange({target: {
      type: 'text',
      value: Object.keys(values).filter(status => status === which ? event.target.checked : values[status]).join(",")
    }})
  }

  render = () => {
    const values = this.valuesCache(this.props.value,this.props.statuses)
    return <div>
      <Typography>{this.props.title || "Status:"}</Typography>
      {Object.keys(values).filter(k => k).map(status => 
        <LabeledSwitch
          checked={values[status]}
          key={status}
          label={capitalize(status)}
          onChange={this.onToggle(status)}
        />
      )}
    </div>
  }
}