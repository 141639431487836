import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import withStyles from 'styles'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { ErrorBanner } from 'components'
import { compose, formatLocaleNumeric } from 'utils'
import { withPermissions } from 'containers/shared'
import { connect } from 'react-redux'
import { UploadButton, Loader } from 'components'
import { Payments } from 'containers'
import { MeterDataFileActions, CBLActions, PaymentActions, SnackbarActions, ChildEventActions } from 'actionsets'
import CBLTable from './CBLTable'
import { WidgetContainer } from 'containers/landing/widgets'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import EyeIcon from '@material-ui/icons/RemoveRedEye'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'


export class SettlementWidget extends Component {

  static propTypes = {
    childEvent: PropTypes.object.isRequired
  }

  static requiredPermissions = ({childEvent: {registration, parentEvent}}) => ({
    organisation: {
      [parentEvent.organisation.id]: ['create_payments', 'read_payments', 'write_programmes', 'submit_meter_data']
    },
  })

  constructor(props){
    super(props)
    this.state = {
      creatingPayment: false,
      processingUpload: false,
      paymentsDialogOpen: false,
    }
    MeterDataFileActions.bindActions(this, 'meterData')
    CBLActions.bindActions(this)
    ChildEventActions.bindActions(this, 'childEvents')
    PaymentActions.bindActions(this, 'payments')
    SnackbarActions.bindActions(this, 'snackbar')
  }

  componentDidMount = () => {
    this.loadCBL()
  }

  componentDidUpdate = prevProps=> {
    if((this.props.childEvent || {}).id !== (prevProps.childEvent || {}).id){
      this.loadCBL()
    }
  }

  loadCBL = async () => {
    if(this.meterDataSubmitted){
      try{
        await this.actions.show(this.childEvent)
      } catch (err) {
        this.actions.snackbar.show(`Failed to load CBL: ${err.message}`)
      }
    }
  }

  handleDownloadCBL = () => {
    this.actions.download(this.childEvent)
  }

  handleCreatePayment = async () => {
    if(
      !this.paymentExists ||
      global.confirm("A payment already exists for this child event. Are you sure want to generate a new payment and invalidate the old one?")
    ){
      this.setState({creatingPayment: true})
      try{
        await this.actions.payments.create({childEventId: this.childEvent.id})
        await this.actions.childEvents.show(this.childEvent.id, {
          include: 'parentEvent,parentEvent.organisation,programme,registration.sites.gxp,registration.organisation,offer, registration.targetedDevices,registration.targetedDevices.deviceSignals,offer.autoDREvents,offer.autoDREvents.deviceSignal,offer.autoDREvents.device',
          fields: {
            childEvents: 'targetKw,mwhPrice,status,programme,parentEvent,registration,offer,optedIn,offerKw,offerPrice',
            programmes: 'name,type',
            parentEvents: 'start,end,offerWindowStart,offerWindowEnd,status,organisation',
            registrations: 'name,sites,organisation,minimumLeadTime,indicativePrice,fixedPrice,availabilityFee,prepurchasedHours,targetedDevices',
            offers: 'offerKw,nonAutoDRKw,offerPrice,status,drLevel,autoDREvents',
            organisations: 'name',
            gxps: 'name',
            autoDREvents: 'estimatedKwAmount,device'
          }
        })
        this.actions.snackbar.show('Payment created')
      }catch(err){
        this.actions.snackbar.show('Failed to save payment: '+err.message)
      }finally{
        this.setState({creatingPayment: false})
      }
    }
  }

  handleSubmitDataClick = () => {
    this.props.history.push('/meter_data/uploads')
  }

  handleDataFileChanged = (event) => {
    const {target: { value: dataFile }} = event
    this.setState({ dataFile })
  }

  get meterDataSubmitted(){
    return ['meter_data_submitted','payment_pending','payment_complete','withdrawn'].includes(this.childEvent.status)
  }

  get paymentExists(){
    return ['payment_pending','payment_complete'].includes(this.childEvent.status)
  }

  get cbl(){
    return this.props.cbl
  }

  get childEvent() {
    return this.props.childEvent
  }

  get parentEvent(){
    return this.props.childEvent.parentEvent
  }

  get eventDuration(){
    const {start, end} = this.parentEvent
    return moment(end).diff(moment(start), 'minutes')/60
  }

  get projectedAmount(){
    const {
      targetKw,
      offerKw,
      offerPrice,
      registration: { fixedPrice }
    } = this.childEvent

    if(this.isPriceResponsive) {
      return offerPrice * (offerKw / 1000.0) * this.eventDuration
    } else {
      return fixedPrice * (targetKw / 1000.0) * this.eventDuration
    }
  }

  get calculatedAmount(){
    const {
      offerPrice,
      registration: { fixedPrice }
    } = this.childEvent

    const actualDr = this.props.cbl.reduction.reduce((agg, val, idx) => agg + Math.max(Math.min(val,this.props.cbl.offered[idx]),0), 0)
    if(this.isPriceResponsive) {
      return offerPrice * (actualDr / 1000.0)
    } else {
      return fixedPrice * (actualDr / 1000.0)
    }
  }

  get programme(){
    return this.props.childEvent.programme
  }

  get isPriceResponsive(){
    return this.programme.type === 'PriceResponsiveProgramme'
  }

  get isMeterDataRequired(){
    return this.childEvent.status === 'meter_data_required'
  }

  get canSubmit(){
    return this.organisationPermission['submit_meter_data'] && (this.childEvent.status !== 'withdrawn')
  }

  get canCreatePayments(){
    return this.organisationPermission['create_payments'] && (this.childEvent.status !== 'withdrawn')
  }

  get canReadPayments(){
    return this.organisationPermission['read_payments']
  }

  get canWithdrawEvent(){
    return this.organisationPermission['write_programmes'] && (this.childEvent.status !== 'withdrawn')
  }

  get cblOk(){
    return (this.meterDataSubmitted && this.cbl.ok)
  }

  get organisationPermission(){
    const {organisation: {id}} = this.childEvent.parentEvent
    return this.props.permissions['organisation'][id]
  }

  get processingUpload(){
    return this.state.processingUpload
  }

  handleTogglePaymentsDialog = () => {
    this.setState({paymentsDialogOpen: !this.state.paymentsDialogOpen})
  }

  handleWithdrawEvent = async () => {
    await Promise.resolve()
    const confirmation = global.confirm(`This will withdraw this child event and its offer. This action cannot be undone, are you sure you want to proceed?`)
    if(!confirmation) return

    this.props.onWithdraw()
  }

  handleSubmitMeterData = async (event) => {
    const {target: {value: file}} = event
    try{
      this.setState({processingUpload: true})
      const {data: {importResults}} = await this.actions.meterData.create({dataFile: file})
      if(this.props.onMeterDataUploaded) {
        this.props.onMeterDataUploaded(importResults)
      }
    } catch(err) {
      this.actions.snackbar.show('Import failed!')
      if(this.props.onMeterDataUploadFailed) {
        this.props.onMeterDataUploadFailed(err)
      }
    } finally {
      this.setState({ processingUpload: false })
    }
  }

  handlePaymentsChange = () => {
    if(this.props.onPaymentsUpdated) {
      this.props.onPaymentsUpdated()
    }
  }

  renderPaymentsListDialog = () => {
    return (
      <Dialog open={!!this.state.paymentsDialogOpen} onClose={this.handleTogglePaymentsDialog}>
        <DialogTitle>Payments</DialogTitle>
        <DialogContent style={{minHeight: 190, minWidth: 330, padding: 0}} >
          <Payments.List childEvent={this.props.childEvent} onChange={this.handlePaymentsChange}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleTogglePaymentsDialog} color="primary" autoFocus>Dismiss</Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderCblFailureReasons = () => {
    if(this.cblOk || !this.meterDataSubmitted || !this.cbl.failureReasons.length) return false

    return (
      <ErrorBanner errorType='warning'>
        <p>Unable to calculate CBL</p>
        {
          this.cbl.failureReasons.map(({siteId, siteName, reason}) => <p key={siteId}>{siteName}: {reason}</p>)
        }
      </ErrorBanner>
    )
  }

  render = () => {
    return (
      <WidgetContainer minHeight={125}>
        {
        this.processingUpload &&
          <div className={this.props.classes('overlay')}>
            <Loader size={100}/>
          </div>
        }
        <div className={this.props.classes.widgetTitle}>
          {
            this.cblOk ?
              <Typography variant='h6' className={this.props.classes.title}>
                Settlement: <span className={this.props.classes.settlementDollarAmount}>${formatLocaleNumeric(this.calculatedAmount)}</span>
              </Typography> :
              <Typography variant='h6' className={this.props.classes.title}>
                Projected Settlement: <span className={this.props.classes.settlementDollarAmount}>${formatLocaleNumeric(this.projectedAmount)}</span>
              </Typography>
          }
        </div>
        { this.renderCblFailureReasons() }
        {
          this.cblOk &&
            <div style={{position: 'relative'}}>
                <Tooltip title='Download Verification Method Calculation Results'>
                  <IconButton className={this.props.classes.downloadButton} onClick={this.handleDownloadCBL}><CloudDownloadIcon/></IconButton>
               </Tooltip>
                <CBLTable
                  className={this.props.classes.cblTable}
                  childEvent={this.childEvent}
                  cbl={this.cbl}
                />
            </div>
        }
        <div className={this.props.classes.settlementActions}>
          {
            this.canWithdrawEvent &&
            <Button onClick={this.handleWithdrawEvent} variant='contained'><PriorityHighIcon/>&emsp;Withdraw</Button>
          }
          {
            this.canSubmit &&
            <UploadButton value={this.state.dataFile} label='Submit Meter Data' onUpload={this.handleSubmitMeterData}/>
          }
          {
            this.canCreatePayments && this.cblOk &&
            <Button color={!this.paymentExists ? 'primary' : undefined} style={{...this.paymentExists && {background: '#ff5e5e', color: 'white'}}}
              onClick={this.handleCreatePayment} variant='contained' disabled = {this.state.creatingPayment}>
              {this.paymentExists && <PriorityHighIcon/>}{ this.paymentExists ? 'Replace' :'Create'} Payment
            </Button>
          }
          {
            this.canReadPayments && this.paymentExists &&
            <Button color='primary' onClick={this.handleTogglePaymentsDialog} variant='contained'>
              <EyeIcon/>&emsp;
              Payment
            </Button>
          }
        </div>
        { this.renderPaymentsListDialog() }
      </WidgetContainer>
    )
  }
}

const styles = theme => ({
  title: {
    '@media(max-width: 600px)': {
      textAlign: 'left !important'
    }
  },
  cblTable: {
    overflowX: 'auto',
    padding: 10,
    '& .mobile-heading:first-child': {
      background: theme.palette.primary.main,
      color: 'white',
      fontWeight: 'bold',
      width: 115,
      textAlign: 'right !important',
      paddingRight: '10px !important',
      paddingBottom: '10px !important'
    },
    '& > tr': {
      padding: '0 10px'
    }
  },
  downloadButton: {
    position: 'absolute',
    right: 0,
    top: -35
  },
  settlementActions: {
    display: 'flex',
    marginTop: 15,
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxHeight: 120,
    '& > button':{
      flex: 1,
      minWidth: 140,
      margin: '2px 10px'
    },
    '@media(max-width: 900px)':{
      maxHeight: 'none',
      flexFlow: 'column'
    }
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '100'
  },
  settlementDollarAmount: {
    color: 'rgba(255,255,255,0.8)'
  }
})

export default compose(
  withRouter,
  withPermissions(SettlementWidget.requiredPermissions),
  withStyles(styles),
  connect(({cbl, meterDataFiles}) => ({...cbl, meterDataFiles })),
)(SettlementWidget)
