import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ProgrammeActions, SnackbarActions } from 'actionsets'
import { Pagination, FABAbsolute, ErrorBanner, TaggedSearchField, LabeledSwitch } from 'components'
import Dependent from 'containers/shared/Dependent'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import MuiList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ProgrammeIcon from '@material-ui/icons/Assignment'
import { compose, errorStringsFromError, debounce } from 'utils'
import { connectQueryString } from 'containers/shared'
import { withPermissions } from 'containers/shared'
import { Authorization } from 'utils'

export class List extends Component{

  static requiredPermissions = props => ({
    organisation: {
      [props.organisationId || props.match.params.organisationId || Authorization.organisationId]: ['writeProgrammes']
    }
  })
  constructor(props){
    super(props)
    ProgrammeActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')
  }

  componentDidUpdate = prevProps => {
    if (this.props.organisationId !== prevProps.organisationId) {
      this.loadProgrammes()
    }
  }

  dependsOn(){
    return this.loadProgrammes()
  }

  loadProgrammes = debounce(() => {
    const page = this.props.page
    return this.actions.index({
      params: {organisationId: this.organisationId},
      page,
      pageSize: this.props.pageSize,
      filter: this.filter,
      fields: {
        nonPriceResponsiveProgrammes: 'name,readOnly',
        priceResponsiveProgrammes: 'name,readOnly',
        programmes: 'readOnly'
      }
    })
  })

  get filter(){
    return {
      active: true,
      ...this.props.filter
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showProgramme = id => () => {
    this.props.history.push(`/programmes/${id}`)
  }

  editProgramme = id => event => {
    this.props.history.push(`/programmes/${id}/edit`)
    event.stopPropagation()
  }

  deleteProgramme = id => event => {
    this.actions.destroy({id})
                .then(() => this.actions.index())
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get programmes(){
    return this.props.programmes
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  get organisationId() {
    return this.props.organisationId || this.props.match.params.organisationId
  }

  get permissions(){
    return ((this.props.permissions || {}).organisation || {})[this.organisationId] || {}
  }

  handleFilterChanged = async rawFilter => {
    const filter = {...rawFilter, tags: {}};
    (rawFilter.tags || []).forEach(({value, tagType: { name }}) => {
      if(!filter.tags[name]) filter.tags[name] = []
      filter.tags[name].push(value)
    })
    await this.props.onFilterChange(filter)
    this.loadProgrammes()
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadProgrammes()
  }

  renderProgrammeListItem = ({id, name, readOnly}) =>
    <ListItem button onClick={this.showProgramme(id)} key={id}>
      <ListItemIcon>
        <ProgrammeIcon />
      </ListItemIcon>
      <ListItemText primary={name}/>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  render = () =>
    <Card>
      <CardContent>
        {this.renderErrorMessages()}
        <TaggedSearchField  onChange={this.handleFilterChanged} context={this.props.filter}>
          {this.organisationId &&
              <LabeledSwitch checked={!('includeDescendantOrganisations' in this.props.filter) || this.props.filter.includeDescendantOrganisations === 'true'} label="Include nested organisations" member="includeDescendantOrganisations" />
          }
          <LabeledSwitch checked={!('active' in this.props.filter) || this.props.filter.active === 'true'} label="Active Only" member="active" />
        </TaggedSearchField>
        <MuiList dense>
          {this.programmes.map(this.renderProgrammeListItem)}
        </MuiList>
        <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
        {
          this.permissions.writeProgrammes &&
          <FABAbsolute color='primary' onClick={() => this.props.history.push(this.organisationId ? `/organisations/${this.organisationId}/programmes/new` : '/programmes/new')}>
            <AddIcon/>
          </FABAbsolute>
        }
      </CardContent>
    </Card>
}

export default compose(
  Dependent({loader: true}),
  withPermissions(List.requiredPermissions),
  connectQueryString('programmes'),
  connect(({programmes}) => programmes)
)(List)