import { createTheme } from '@material-ui/core/styles'
import { loadAsyncCSS } from 'utils'

loadAsyncCSS('https://fonts.googleapis.com/icon?family=Material+Icons')

const viewAreaMaxWidth = 1400

export const palette = {
  primary: {
    light: '#a2d8f1',
    main: '#118FCB',
    dark: '#00629a',
    background: 'hsla(200, 83%, 11%, 0.5)'
  },
  secondary: {
    light: '#43fd68',
    main: '#14b739',
    dark: '#008600',
    contrastText: '#fff',
    background: 'rgb(196, 228, 182)'
  },
  error: {
    background: '#ffc0bf',
    light: '#ff8680',
    main: '#e63c30',
    dark: '#b30900'
  },
  type: 'dark'
}

export const theme = createTheme({
  palette,
  typography: {
    useNextVariants: true,
    fontFamily: '"Mukta", sans-serif',
    h5: {
      fontSize: '32px',
      fontWeight: 900,
      color: 'white',
      lineHeight: 1.2,
      marginBottom: 15,
    },
    h4: {
      fontSize: '32px',
      fontWeight: 900,
      color: 'white',
      marginBottom: 18,
    },
    body2: {
      fontSize: '14px',
      color: 'white'
    },
    body1: {
      color: 'white'
    },
    h6: {
      fontWeight: 900,
      color: 'white',
      marginBottom: 15,
      fontSize: '1.6rem',
      lineHeight: 1.2,
      'nav &, header &': {
        marginBottom: 0,
        fontSize: '1.25rem'
      }
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '18px',
      color: 'white'
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: 2
    }
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: palette.secondary.main
      }
    },
    MuiMenuItem: {
      root: {
        color: 'white',
        '&$selected': {
          backgroundColor: palette.primary.main,
          color: 'white'
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 2
      },
      contained: {
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiFab: {
      root: {
        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiIconButton: {
      root: {
        overflow: 'visible'
      },
      colorPrimary: {
        color: '#FFF'
      }
    },
    MuiChip: {
      root: {
        borderRadius: 25,
        boxShadow: '0 0 6px #0001',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        margin: 1
      }
    },
    MuiTableRow: {
      root: {
        height: 40
      }
    },
    MuiTableCell: {
      root: {
        padding: 5,
        borderBottom: '1px solid #a2d8f1'
      }
    },
    MuiTab: {
      root: {
        transition: 'background-color 250ms',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.05)'
        }
      }
    },
    MuiBottomNavigation: {
      root: {
        '@media(max-width: 600px)': {
          marginBottom: 50
        }
      }
    },
    MuiPaper: {
      root: {
        color: '#FFF',
        backgroundColor: 'hsla(200, 83%, 11%, 0.5)', /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        backdropFilter: 'blur(3px)',
        '&$elevation2': {
          '& $elevation1':{
            boxShadow: 'none',
          },
          boxShadow: 'none',
        },
        '& .link-small': {
          margin: 5,
          marginBottom: 10,
          textAlign: 'center',
          display: 'block',
          color: '#FFF',
          fontSize: '0.8rem',
          '&:hover': {
            textDecoration: 'underline'
          }
        },
        '& hr': {
          border: '2px dashed silver'
        },
        '& form': {
          maxWidth: '100%',
          width: 'auto'
        }
      },
      rounded: {
        borderRadius: 2
      }
    },
    MuiCard: {
      root: {
        border: `1px solid #91c0d6`,
        borderTop: `6px solid #91c0d6`,
        overflow: 'visible',
        position: 'relative',
        maxWidth: viewAreaMaxWidth + 32,
        margin: '5px auto',
        boxShadow: '0px 20px 80px 20px rgba(0,0,0,0.05)'
      }
    },
    MuiCardContent: {
      root: {
        margin: '0 auto',
        boxSizing: 'content-box'
      }
    },
    MuiCardActions: {
      root: {
        padding: '8px 16px'
      }
    },
    MuiFormControl: {
      root: {
        marginBottom: 10,
      }
    },
    MuiFormHelperText: {
      root: {maxWidth: 'calc(100vw - 50px)'}
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 4
      }
    },
    MuiSvgIcon: {
      root: {
        verticalAlign: 'middle'
      }
    },
    MuiPopover: {
      paper: {
        background: 'rgb(10, 55, 78, 0.97)',
        border: '1px solid #a2d8f1',
        boxShadow: '0px 20px 80px 20px rgba(0,0,0,0.2)'
      }
    },
    MuiDialog: {
      paper: {
        background: 'rgb(10, 55, 78, 0.97)',
        border: '1px solid #a2d8f1',
        boxShadow: '0px 20px 80px 20px rgba(0,0,0,0.2)'
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: 'transparent',
        '& > *': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgba(0,0,0,0.3) !important'
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: 'rgba(0,0,0,0.3)'
      }
    },
    MuiPickersModal: {
      dialog: {
        '& > div': {
          backgroundColor: 'transparent'
        }
      }
    }
  },
}, {viewAreaMaxWidth: viewAreaMaxWidth})
