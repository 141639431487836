import { ProtectedJsonAPI } from './ProtectedJsonAPI'

export const TagValues = ProtectedJsonAPI.extend(
  '',
  {
    index:   endpoint => endpoint.path(({parent_event_id}) => parent_event_id ? `tag_types/${parent_event_id}/tag_values` : `/tag_values`),
    create:  endpoint => endpoint.method('post').path(({parent_event_id}) => `tag_types/${parent_event_id}/tag_values`),
    update:  endpoint => endpoint.method('put').path(({id}) => `tag_values/${id}`),
    destroy: endpoint => endpoint.method('delete').path(({id}) => `tag_values/${id}`),
    show:    endpoint => endpoint.path(({id}) => `tag_values/${id}`),
  }
)