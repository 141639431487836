import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withStyles from 'styles'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import MailIcon from '@material-ui/icons/Mail'
import Popover from '@material-ui/core/Popover'
import { compose, Authorization, timeout } from 'utils'
import { UserNotificationActions, SnackbarActions } from 'actionsets'
import { ShortPendingList } from './'

const NOTIFICATIONS_REFRESH_INTERVAL = parseInt(process.env.REACT_APP_NOTIFICATIONS_REFRESH_INTERVAL, 10) || 30000

export class NotificationsBadge extends Component {
  constructor(props) {
    super(props)
    UserNotificationActions.bindActions(this)
    SnackbarActions.bindActions(this, 'snackbar')

    this.state = {
      anchorEl: null
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    this.pollNotifications()
  }

  componentWillUnmount = () => {
    this._isMounted = false
    this.handleRequestClose()
  }

  pollNotifications = async () => {
    if(this._isMounted && Authorization.user) {
      try {
        await this.actions.updateShortPendingListCount()
      } catch(err) {
        this.actions.snackbar.show('Failed polling for new notifications!')
      }
      await timeout(NOTIFICATIONS_REFRESH_INTERVAL)
      this.pollNotifications()
    }
  }

  handleRequestOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleRequestClose = () => {
    this.setState({ anchorEl: null })
  }

  handleViewAll = () => {
    this.handleRequestClose()
    this.props.history.push('/notifications')
  }

  render = () => {
    return (
      <Fragment>
        <IconButton color="inherit" aria-label="Notifications" onClick={ this.handleRequestOpen }>
          { this.props.count ?
              <Badge badgeContent={this.props.count} color="primary" classes={{colorPrimary: this.props.classes.colorPrimary}}>
                <MailIcon/>
              </Badge> : <MailIcon/>
          }
        </IconButton>
        <Popover
          anchorEl={this.state.anchorEl}
          open={ Boolean(this.state.anchorEl) }
          onClose={this.handleRequestClose}
          classes={{paper: this.props.classes.paperListStyle}}
        >
          <ShortPendingList onViewAll={ this.handleViewAll }/>
        </Popover>
      </Fragment>
    )
  }
}

const styles = theme => ({
  colorPrimary: {
    background: theme.palette.error.main
  },
  paperListStyle: {
    minWidth: '320px'
  }
})

export default compose(
  connect(({userNotifications: { shortPendingListCount }}) => ({ count: shortPendingListCount }) ),
  withRouter,
  withStyles(styles)
)(NotificationsBadge)